<div
	class="chat-box"
	[class.direct-message]="isDirectPopUp"
	*ngIf="hasAccess || preLoad"
>
	<p class="box-message" *ngIf="(!privateCheck || preLoad) && !isViewer">
		Loading Messenger
	</p>

	<p class="box-message" *ngIf="isViewer">Chat Disabled</p>
	<p
		class="box-message"
		*ngIf="isPrivate && privateCheck && !preLoad && messages.length < 1"
	>
		You have entered a private conversation
	</p>
	<p
		class="box-message"
		*ngIf="!isPrivate && privateCheck && !preLoad && messages.length < 1"
	>
		You have entered a public conversation
	</p>
	<div
		class="messages scrollbar-new"
		id="messages{{ channelId }}"
		(scroll)="scrollCheck()"
	>
		<div class="bottom-align">
			<!-- should reverse array in .ts file as this may being over checked -->
			<ng-container *ngFor="let message of messages?.reverse(); let i = index">
				<p class="chat-date" *ngIf="!message.sameDate && message.when">
					<time-difference [orderTime]="message.when"></time-difference>
				</p>
				<div
					class="message"
					[ngClass]="{
						'sent':
							message.channelUserKeyNavigation?.channelUserId
							| compareStrings: _authService._user.profile.sub,
						'same-id': message.sameId && message.sameDate && message.closeTime
					}"
				>
					<div 
						class="user-img">
						<img
							*ngIf="!!message.channelUserKeyNavigation.hash && message.channelUserKeyNavigation.hash !== '1'; else defaultImg"
							src="https://profile-images.azureedge.us/{{message.channelUserKeyNavigation.hash}}"
							alt="profile picture"
							(error)="handleBrokenImage(i)"
						/>
						<ng-template #defaultImg>
							<img
								*ngIf="message.channelUserKeyNavigation.isGuest; else notGuest"
								src="https://profile-images.azureedge.us/1"
								alt="profile picture"
							/>
							<ng-template #notGuest>
								<div
									class="default-user-img circle flex-center-all me-2"
									[class.every-first]="i % 4 === 0"
									[class.every-second]="i % 4 === 1"
									[class.every-third]="i % 4 === 2"
									[class.every-fourth]="i % 4 === 3"
								>
									{{ message.channelUserKeyNavigation.username | defaultImageOverlay }}
								</div>
							</ng-template>
						</ng-template>
					</div>
					<div class="chat-text">
						<p class="chat-title">
							<span class="chat-name text-ellipsis">
								{{ message.channelUserKeyNavigation.displayName }}
							</span>
							<i class="fas fa-circle"></i>
							<!-- <span
                                class="chat-time"
                                *ngIf="!message.welcomeMessage"
                                >{{ message.when | date: "hh:mm a" }}</span
                            > -->
							<span
								class="chat-time"
								*ngIf="!message.welcomeMessage"
								matTooltip="{{ message.when | date: 'short' }}"
                                matTooltipClass="tooltip-default"
								>{{ message.when | date: "hh:mm a" }}</span
							>
							<span
								class="chat-welcome"
								*ngIf="message.welcomeMessage"
								matTooltip="{{ message.when | date: 'short' }}"
                                matTooltipClass="tooltip-default"
								>Welcome Message</span
							>
						</p>
						<div
							class="chat-content"
							[innerHTML]="message.content | messageWithLinks"
						></div>
					</div>
					<div class="chat-options">
						<div class="options-button">
							<i class="fas fa-ellipsis-v"></i>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="chat-box-info">
				<p *ngIf="typing && typers.length < 4" class="name-dots">
					<span *ngFor="let user of typers; let i = index" class="name">
						{{ user.displayName
						}}<span *ngIf="typers.length == 3 && i < typers.length - 1">,</span>
						<span *ngIf="i == typers.length - 2"> &</span>
					</span>
					<span *ngIf="typers.length > 1"> are typing </span>
					<span *ngIf="typers.length === 1"> is typing </span>
				</p>
				<p *ngIf="typing && typers.length > 3" class="name-dots">
					<span class="name"> Many Users are typing </span>
				</p>
			</div>
		</div>
	</div>
	<div class="pt-2 new-message-container">
		<div class="chat-input">
			<form
				id="emoji-trigger{{ channelId }}"
				(submit)="initiateSendMessage()"
				class="message-form"
				[formGroup]="newMessage"
			>
				<div class="username-select-list" *ngIf="userSearchActivated">
					<p
						class="list-item-initial text-ellipsis"
						*ngFor="let user of userSearchList; let i = index"
						(click)="addUser(i)"
					>
						@{{ user.username | lowercase }} - {{ user.displayName }} -
						{{ user.email ? user.email : "(no email)" }}
					</p>
					<p
						class="empty-list"
						*ngIf="userSearchList.length < 1 && userSearchString.length > 0"
					>
						Username Not Found
					</p>
					<p
						class="empty-list"
						*ngIf="userSearchList.length < 1 && userSearchString.length < 1"
					>
						Continue Typing a Username
					</p>
				</div>
				<!-- <input type="text" class="form-control" id="message"  placeholder="Type Message Here" name="message" [(ngModel)]="message" (keypress)="onKeyPress($event)" required> -->
				<ngx-editor
					id="message{{ channelId }}"
					[editor]="editor"
					[placeholder]="'Type Message Here'"
					formControlName="content"
					maxlength="10000"
					(keydown)="onKeyPress($event)"
					(keyup)="onKeyPressUp($event)"
					required
				>
				</ngx-editor>
				<!-- <textarea
                    rows="0"
                    class="form-control scrollbar-new"
                    id="message{{ channelId }}"
                    placeholder="Type Message Here"
                    name="message"
                    [(ngModel)]="message"
                    #chmessage="ngModel"
                    maxlength="10000"
                    (keydown)="onKeyPress($event)"
                    required
                >
                </textarea> -->
				<div class="chat-send">
					<button
						matTooltip="send message"
						matTooltipClass="tooltip-default"
						[disabled]="!newMessage.valid"
						class="message"
						[ngClass]="{ invalid: !newMessage.valid }"
					>
						<img
							src="{{ logoIcon2.logogreen }}"
							alt="via-logo"
							class="nav-logo circle"
						/>
						
					</button>
				</div>
			</form>
		</div>
		<div class="mb-2 chat-buttons">
			<emoji-button
				[channelId]="channelId"
				[isDM]="isDirectPopUp"
				(sendEmoji)="addStringToMessage($event)"
			>
			</emoji-button>
			<!-- <button class="at-button" type="button" (click)="startAtUser()">
                <i class="fas fa-at"></i>
            </button> -->
			<!-- <file-storage add new here if needed ... 
                *ngIf="!isQuickMeeting"
                [hasAccess]="hasAccess"
                [componentType]="'chat'"
                [channelInfo]="channelInfo"
                (uploadStatusChange)="uploadChange($event)"
            >
            </file-storage add new here if needed ... >
            <div class="file-button" *ngIf="!isQuickMeeting">
                <i class="fa fas-upload" *ngIf="!fileIsUploading"></i>
            </div> -->
			<ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
		</div>
	</div>
</div>

<div *ngIf="!hasAccess && !preLoad" class="chat-box unauthorized">
	<h3>You do not have permission to access this content</h3>
</div>

<!--  -->
