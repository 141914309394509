<!--  -->
<div class="officer-selection" *ngIf="!sessionReady && showPage">
    <div class="selection-message my-4">
        <h3>
            {{ _customizations.officerSelectMessage.value }}
        </h3>
    </div>
    <div class="session-options">
        <h4>Start a New Session</h4>
        <div class="notifications">
            <h5>Please enter a desired name</h5>
            <form class="form-no-labels">
                <div class="form-inputs">
                    <input
                        type="text"
                        name="fname"
                        class="ao-input-x"
                        placeholder="First Name"
                        [(ngModel)]="notificationForm.firstName"
                        maxlength="15"
                        pattern="[a-zA-Z ]*"
                        #fname="ngModel"
                    />
                    <input
                        type="text"
                        name="lname"
                        class="ao-input-x"
                        placeholder="Last Name"
                        [(ngModel)]="notificationForm.lastName"
                        maxlength="15"
                        pattern="[a-zA-Z ]*"
                        #lname="ngModel"
                    />
                    <span class="validation-errors">
                        <p *ngIf="lname.invalid || fname.invalid">
                            Please only use letters in your name
                        </p>
                    </span>
                </div>
                <div class="form-button-x flex">
                    <button
                        *ngIf="fname.valid && lname.valid"
                        class="primary-button mx-auto"
                        [disabled]="!fname.valid || !lname.valid"
                        type="button"
                        (click)="_postIntakeUserSession()"
                    >
                        Begin Session
                    </button>
                    <!-- <button
                        *ngIf="fname.valid && lname.valid"
                        (click)="startSession()">
                        Begin Session
                    </button> -->
                    <!-- <button
                        class="not-valid"
                        *ngIf="!fname.valid || !lname.valid"
                    >
                        Begin Session
                    </button> -->
                </div>
                <!-- <p class="note">*note these inputs will be forgotten once the session has ended</p> -->
            </form>
        </div>
        <!--<div class="notifications" *ngIf="isMedical">
            <form class="form-no-labels">
                <div class="form-button-x flex">
                    <button (click)="_postIntakeUserSession()" class="primary-button mx-auto">
                        Begin Session
                    </button>
                </div>
            </form>
        </div> -->
    </div>
    <div class="officer-list">
        <h4 *ngIf="!isMedical">Available Intake Officers</h4>
        <h4 *ngIf="isMedical">Available Staff</h4>
        <div class="available-officer" *ngFor="let officer of officerList">
            <div class="officer-img">
                <img
                    src="https://profile-images.azureedge.us/{{
                        officer.intakeUserKeyNavigation.chatUserKeyNavigation
                            .hash
                    }}"
                    alt="Profile Picture"
                />
            </div>
            <div class="officer-info">
                <h4>
                    {{
                        officer.intakeUserKeyNavigation.chatUserKeyNavigation
                            .displayName
                    }}
                </h4>
                <p>
                    Shift Starts: {{ officer.startTime | date: "h:mm aaa, E" }}
                </p>
                <p>Shift Ends: {{ officer.endTime | date: "h:mm aaa, E" }}</p>
            </div>
        </div>
        <p class="empty-list" *ngIf="officerList.length < 1 && !isMedical">
            No Intake Officers Have Input Schedules.
        </p>
        <p class="empty-list" *ngIf="officerList.length < 1 && isMedical">
            No Staff Have Input Schedules.
        </p>
    </div>
</div>
<div
    class="video-stream"
    stream-type="a-officer"
    id="video-stream"
    *ngIf="sessionReady && showPage"
>
    <jitsi-video
        [channelId]="channelId"
        [userName]="_officerSessionData.displayName"
        [forMedicalEMT]="false"
        [preventList]="videoPreventList"
        (openFilesEvent)="showFilesModal()"
        *ngIf="videoType === 'jitsi'"
    >
    </jitsi-video>
    <!-- ❔❔ do we still use rtc??? -->
    <app-rtc-video *ngIf="videoType === 'webrtc'"></app-rtc-video>
</div>
<div class="intake-chat-messaging" *ngIf="sessionReady && showPage">
    <div class="officer-instructions" [class.show-block]="chatShow">
        <h2>Instruction List</h2>
        <div class="check-list">
            <ol>
                <li
                    *ngFor="
                        let custom of _customizations.instructionList;
                        let i = index
                    "
                >
                    {{ custom.value }}
                    <span
                        *ngIf="
                            _customizations.instructionListSubs[i].length > 0
                        "
                    >
                        <ul
                            *ngIf="
                                _customizations.instructionListSubs[i][0]
                                    .displayType === 'ulli'
                            "
                        >
                            <li
                                *ngFor="
                                    let sub of _customizations
                                        .instructionListSubs[i]
                                "
                            >
                                {{ sub.value }}
                            </li>
                        </ul>
                        <ol
                            *ngIf="
                                _customizations.instructionListSubs[i][0]
                                    .displayType === 'olli'
                            "
                        >
                            <li
                                *ngFor="
                                    let sub of _customizations
                                        .instructionListSubs[i]
                                "
                            >
                                {{ sub.value }}
                            </li>
                        </ol>
                    </span>
                </li>
            </ol>
            <p
                class="no-messages"
                *ngIf="_customizations.instructionList.length < 1"
            >
                Welcome to the {{ _app.name }}.
            </p>
            <!-- <ol>
                <li>Use the message box to provide the Intake Officer with the necessary information to:
                    <span>
                        <ul>
                            <li>Determine probable cause for each offense</li>
                            <li>Determine if the juvenile needs to be removed from the home</li>
                            <li>Begin Referral Complaint Form</li>
                        </ul>
                    </span>
                </li>
                <li>Request and Download the referral complaint form</li>
                <li> Fill out the form then submit via upload or fax, the fax # can be found by hovering over the Officers profile card</li>
                <li>Schedule a video call time with an Officer</li>
                <li>When prompt, accept the request to begin the video call</li>
            </ol> -->
            <button (click)="switchChat()" class="primary-button">Chat Message Box</button>
        </div>
    </div>
    <!-- ❌❌❌ dont think we use this ... formShow always false ... -->
    <div class="officer-instructions"  [class.show-block]="formShow">
        <h2>Forms</h2>
        <p
            class="no-messages"
            *ngIf="_customizations.instructionList.length < 1"
        >
            Welcome to the {{ _app.name }}.
        </p>
        <div class="check-list">
            <h3 *ngIf="!formSelected">Avaliable Forms:</h3>
            <h4 *ngIf="onlineForms.length < 1">No Forms Currently Available</h4>
            <div
                class="formLinks"
                *ngFor="
                    let form of onlineForms;
                    let i = index;
                "
            >
                <p
                    *ngIf="!form.isList && (show || !formSelected)"
                    (click)="formSelected = !formSelected"
                >
                    <span *ngIf="!formSelected">{{ form.formName }}</span>
                    <button
                        type="button"
                        *ngIf="formSelected"
                        (click)="getOnlineForms()"
                    >
                        Avaliable Forms
                    </button>
                </p>
                <!-- show is always false, was in ngFor, was prob for testing -->
                <div id="onlineFormFor" *ngIf="show">
                    <!-- <app-form
                        [passedInFormTemplateId]="form.formTemplateId"
                        [exitAfterSubmit]="true"
                        [channelId]="channelId"
                    ></app-form> -->
                </div>
            </div>
            <br />
            <button (click)="switchChatForms()">Chat Message Box</button>
        </div>
    </div>
    <div>
        
    </div>
    <app-chat
        [class.hide-block]="formShow || chatShow"
        [channelId]="channelId"
        [teamProjectId]="teamProjectId"
        [currentIntakeUser]="_officer"
    >
    </app-chat>
    <div class="chat-users-ao">
        <p class="box-message" *ngIf="!isMedical">Officers in Channel</p>
        <p class="box-message" *ngIf="isMedical">Users in Channel</p>
        
        <!-- 🚧 new mat-accord here -->
        <mat-accordion class="example-headers-align" multi>
            <ng-container *ngFor="let officer of chatList">
                <mat-expansion-panel 
                    class="intake-officer-card {{ officer.userStatus }}" 
                    [disabled]="_officer.chatUserKeyNavigation.channelUserId === officer.chatUserKeyNavigation.channelUserId"
                    (opened)="officer.isOpen = true"(closed)="officer.isOpen = false">
                    <mat-expansion-panel-header [class.mt-3]="officer.isOpen">
                        <mat-panel-title>
                            <div class="officer-img flex">
                                <img
                                    src="https://profile-images.azureedge.us/{{
                                        officer.chatUserKeyNavigation.hash
                                    }}"
                                    class="circle"
                                    alt="profile picture"
                                />
                                <div 
                                    class="status-bar"
                                    [matTooltip]="!officer.isOpen ? officer.userStatus : ''" 
                                    matTooltipClass="tooltip-default"></div>
                            </div>
                            <div 
                                class="chat-name"
                                [matTooltip]="!officer.isOpen ? officer.chatUserKeyNavigation.displayName : ''" 
                                matTooltipClass="tooltip-default">
                                <p 
                                    [class.text-ellipsis]="!officer.isOpen"
                                    [class.open]="officer.isOpen">
                                    {{ officer.chatUserKeyNavigation.displayName }}
                                </p>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- content -->
                    <div 
                        class="status-wrap flex align-center mt-3 mb-3">
                        <div class="status-circle-sm me-1"></div>
                        <div class="status-text">{{ officer.userStatus | statusDisplay}}</div>
                    </div>
                    <div class="chat-info">
                        <!-- <p>Schedule:</p> -->
                        <p *ngIf="!officer.startTime || !officer.endTime">
                            No Schedule Available
                        </p>
                        <p *ngIf="officer.startTime && officer.endTime">
                            Shift Starts:
                            {{ officer.startTime | date: "h:mm aaa, E" }}
                        </p>
                        <p *ngIf="officer.startTime && officer.endTime">
                            Shift Ends: {{ officer.endTime | date: "h:mm aaa, E" }}
                        </p>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </div>
    <div class="tempFileBtn vdjj-tempFileBtn flex">
        <!-- 💡 need output event to show files.length in this button ... -->
        <!-- ({{ fileList.length }}) -->
        <button (click)="showFilesModal()" class="secondary-button w-100">
            Shared Files
        </button>
    </div>
    <!-- instruction list -->
    <div class="box-switch flex">
        <button (click)="switchChat()" class="primary-button w-100">{{ showText }}</button>
    </div>
</div>


<!-- 🚧🚧🚧 converting to new global modal && fs2.0! -->
<div
	*ngIf="_showFiles"
	class="modal"
	id="intake-files-modal"
	(click)="clickOffModalEvent($event)"
>
	<div
		class="modal-container">
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="showFilesModal()"
		>
			<i class="fas fa-times"></i>
		</button>
        <file-storage
            [componentId]="'intake-ao'"
            [channelContainerName]="channelInfo.blobContainerId"
            [channelName]="'Temporary Intake Files'"
            [channelId]="channelId"
            [isPortable]="true">
        </file-storage>
	</div>
</div>
