import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VdjjVideoIntakeHomepageComponent } from './vdjj-video-intake-homepage/vdjj-video-intake-homepage.component';

//import { AuthGuard } from '../core/authguard';

const routes: Routes = [];

@NgModule({
    imports: [RouterModule.forRoot([
    { path: 'vdjjvideointakeapp', component: VdjjVideoIntakeHomepageComponent },
    { path: 'intakeapp/:tenant', component: VdjjVideoIntakeHomepageComponent },
    { path: 'medicalapp/:tenant/:type', component: VdjjVideoIntakeHomepageComponent },
]
// ,
//     { onSameUrlNavigation: 'reload' }
, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class VdjjVideoIntakeAppRoutingModule { }
