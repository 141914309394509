import {
    Component,
    Inject,
    Input,
    OnInit,
} from "@angular/core";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelFull } from "../../interface/channel-full";



@Component({
    selector: "channel-info",
    templateUrl: "./channel-info.component.html",
    styleUrls: ["./channel-info.component.scss"]
})
export class ChannelInfoComponent implements OnInit {

    @Input('channelId') channelId: string;

    public channelInfo: ChannelFull;
    public isLoadingChannelInfo: boolean = true;
    
    constructor(private _apiCalls: ApiCallsService) {}
    
    ngOnInit(): void {
        this.getChannel();
    }

    // 🚧🚧🚧 need to get channel info by id here ... 
    getChannel() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe(data => {
                if(data.status === 200) {
                    this.channelInfo = data.body;
                    this.isLoadingChannelInfo = false;
                    console.info('channel info?', this.channelInfo)
                } else {
                    console.error("error getting channel info by channel id", data)
                    this.isLoadingChannelInfo = false;
                }
            });
    }

    handleBrokenImage(isOwner?:boolean,userIdx?:number) {
        // console.info('yeeeeeeeeeeeeeee')
        if(isOwner) {
            this.channelInfo.chatRoomChatUsers[userIdx].chatUserKeyNavigation.hash = '1';
        } else {
            this.channelInfo.chatRoomChatUser1[userIdx].chatUserKeyNavigation.hash = '1';
        }
        if(!isOwner && !userIdx) this.channelInfo.chatUserKeyNavigation.hash = '1';
    }
}

