import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'channelFolderPipe'
})
export class ChannelFolderPipe implements PipeTransform {
  transform(value: string, channelName: any): any {
    if (value === "/") {
      if(channelName) {
        return channelName;
      } else {
        return 'Quick meeting';
      }
    } else {
      return value.slice(0, -1);
    }
  }
}
