import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "statusDisplay",
})
export class StatusDisplayPipe implements PipeTransform {
    transform(status: string, isTooltip?:boolean): string {
        // console.error("") // should only check once 
        if (status === "online" || status === "active") {
            return isTooltip ? 'online' : "Online";
        } else if (status === "hidden") {
            return isTooltip ? 'invisible' : "Invisible";
        } else if (status === "offline") {
            return isTooltip ? 'offline' : "Offline";
        } else if (status === "dnd") {
            return isTooltip ? 'do not disturb' : "Do Not Disturb";
        } else if (status === "brb") {
            return isTooltip ? 'be right back' : "Be Right Back";
        } else if (status === "away" || status === "none") {
            return isTooltip ? 'away' : "Away";
        }
    }
}

// statusDisplay(status) {
//     console.error("status logged")// runs non stop
//     if (status === "online" || status === "active" || status === "away" || status === "none") {
//         return "Online";
//     } else if (status === "hidden") {
//         return "Invisible";
//     } else if (status === "offline") {
//         return "Offline";
//     } else if (status === "dnd") {
//         return "Do Not Disturb";
//     } else if (status === "brb") {
//         return "Be Right Back";
//     }
// }
