<div class="side-container">
	<div class="side-container-top">
		<button 
			class="app-button" 
			(click)="closeSideBar()"
			matTooltip="close sidebar"
            matTooltipClass="tooltip-default">
			<i
				class="{{
					_channelService._isDesktop ? appObject.icon : 'fas fa-arrow-left'
				}}"
			></i>
		</button>
		<h5 class="container-top-title">
			{{ appObject.title }}
		</h5>
		<a
			*ngIf="appObject.redirectLink"
			class="me-1 flex-center-all redirect-side-button"
			[routerLink]="appObject.redirectLink"
			(click)="closeSideBar()"
			routerLinkActive="active"
			matTooltip="open {{ appObject.appType }} page"
			matTooltipClass="tooltip-default"
		>
			<i class="far fa-chevron-circle-right"></i>
		</a>
		<!-- <button
            class="me-1 flex-center-all pin-side-button"
            [class.pinned]="pinnedSideBar"
            (click)="pinToggle()"
            matTooltip="pin sidebar"
            matTooltipClass="tooltip-default"
        >
            <i *ngIf="!pinnedSideBar" class="far fa-thumbtack"></i>
            <i *ngIf="pinnedSideBar" class="fas fa-thumbtack"></i>
        </button> -->
	</div>

	<div class="side-container-body">
		<ng-container *ngIf="showSidebar">
			<ng-container *ngIf="appType === 'apps'">
				<div class="default-background">
					<p>Coming soon</p>
				</div>
			</ng-container>

			<ng-container *ngIf="appType === 'alerts'">
				<alerts [componentId]="'sidebar-alerts'" [userInfo]="userInfo"> </alerts>
			</ng-container>

			<ng-container *ngIf="appType === 'channels'">
				<channels-history [userInfo]="userInfo"> </channels-history>
			</ng-container>

			<ng-container *ngIf="appType === 'contacts'">
				<contacts [userInfo]="userInfo"> </contacts>
			</ng-container>

			<ng-container *ngIf="appType === 'events'">
				<events [userInfo]="userInfo"> </events>
			</ng-container>

			<ng-container *ngIf="appType === 'faxes'">
				<fax-homepage [isSideBar]="true"> </fax-homepage>
			</ng-container>

			<ng-container *ngIf="appType === 'files'">
				<file-storage
					[isPortable]="true"
					[isSidebar]="true"
					[componentId]="'sidebar'"
				></file-storage>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!showSidebar">
			<div class="default-background"></div>
		</ng-container>
	</div>
</div>

<!--  -->
<!-- 
<div class="redirect-container">
    <a
        *ngIf="appObject.redirectLink"
        class="me-1 flex-center-all redirect-button"
        [routerLink]="appObject.redirectLink"
        (click)="closeSideBar()"
        routerLinkActive="active"
        matTooltip="open {{appObject.appType}} page"
        matTooltipClass="tooltip-default"
        class="redirect-button"
    >
        <i class="far fa-angle-right"></i>
    </a>
</div> -->
