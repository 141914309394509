import { 
    Component, 
    Inject, 
    Input, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import {
    listAnimationWrap,
    listAnimationItem,
    customExitTransition
} from "src/app/animations/global-animations";
import { AuthService } from "src/app/core/auth.service";
// import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelService } from "src/app/services/channel-service";
import { ContactsService } from "src/app/services/contacts.service";
import { SubscriptionPlan, SubscriptionService } from "src/app/services/subcription.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { ChannelUser } from "../../interface/channel-user";
import { Contact } from "../../interface/contact";
import { consoleLogStyle } from '../../varaibles';
import { ApiCallsService } from "src/app/services/api-calls.service";



@Component({
    selector: "contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"],
    animations: [listAnimationWrap, listAnimationItem],
})
export class ContactsComponent implements OnInit, OnChanges, OnDestroy {
    // public contacts: any[] = []; // sorting on front-end ... 
    // public contactsPag: any = {}; // pagination removed due to sorting issues ... 

    // for testing ... 
    // public hasPopulatedContacts: boolean = false; // set true if reset was clicked ... 

    @Input("userInfo") userInfo: ChannelUser;
    @Input('isViewer') isViewer: boolean;

    public isLoadingContacts: boolean = true;
    public contactsFiltered: Array<Contact> = [];
    public hasNoContacts: boolean;

    public displayOptionModal: boolean = false;
    public displayEmailInviteModal: boolean = false;
    public displayEmailInviteWarning: boolean = false;

    public displayCreateContactModal: boolean = false;
    public displayCreateContactWarning: boolean = false;

    public isEditContact: boolean = false;
    public displayContactInfoModal: boolean = false;
    public displayAddNoteWarning: boolean = false;
    public selectedContact: Contact;
    
    public sortOptions: any[] = ['Activity', 'Favorites', 'A - Z'];
    public isLoadingSortOptions: boolean = true;
    // public isSortingContacts: boolean;

    public contactToRemove: any = {
        contactId: null,
        name: null,
        contactIdx: null,
    };

    public isLoadingSubscription: boolean;
    public subscriptionPlan: SubscriptionPlan;
    
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject('rippleColor') public rippleColor: string,
        // private _apiCallsService: ApiCallsService,
        private _contactsService: ContactsService,
        private _channelService: ChannelService,
        private _toastService: ToastNotificationService,
        private _subscriptionService: SubscriptionService,
        private _authService: AuthService,
        private _apiCallService: ApiCallsService
    ) {
        this.subscriptions.push(this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            //console.log("?: channelUserStatusList: ", channelUserStatusList) ////CG
            this.contactsFiltered.forEach(contact => {
                //console.log("?: contact.contactChatUserKeyNavigation: ", contact.contactChatUserKeyNavigation); ////CG 
                channelUserStatusList.forEach(channelUser => { 
                    if (!!contact.contactChatUserKeyNavigation && contact.contactChatUserKeyNavigation.username.toLowerCase() === channelUser.username.toLowerCase()) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                contact.userStatus = "offline";
                                contact.userStatusIdx = 4;
                            } else if (channelUser.presenceStatus === 1) {
                                contact.userStatus = "idle"; // was 'away' ... 
                                contact.userStatusIdx = 2;
                            } else if (channelUser.presenceStatus === 2) {
                                contact.userStatus = "active";
                                contact.userStatusIdx = 0;
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            contact.userStatus = "offline";
                            contact.userStatusIdx = 4;
                        } else if (channelUser.selectedStatus === 2) {
                            contact.userStatus = "dnd";
                            contact.userStatusIdx = 3;
                        } else if (channelUser.selectedStatus === 3) {
                            contact.userStatus = "brb";
                            contact.userStatusIdx = 1;
                        }
                    }
                });
            });
            if(this.sortOptions[0] === 'Activity' || 'Favorites') {
                this.sortBy();
            }
            // console.log("%c this._channelService.onUpdateUserStatusEvent$.sub ", consoleLogStyle)
        }));
        // 
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "closeCreateContactModal") {
                this.closeCreateContactModal();
            }
            if (data.type === "closeContactInfoModal") {
                this.closeContactInfoModal();
            }
            if (data.type === "closeEmailInviteModal") {
                this.closeEmailInviteModal();
            }
            if (data.type === "removeContact") {
                // console.log("%c contact to remove?", consoleLogStyle, this.contactToRemove)
                this.removeContact(
                    this.contactToRemove.contactId, 
                    this.contactToRemove.name, 
                    this.contactToRemove.contactIdx
                );
            }
        }));

        // ❌❌❌ When new direct message is created ❔❔❔ NO UN-READ MESSAGE BADGE IS SHOWN IN THIS COMPONENT  // 
        // this.subscriptions.push(this._channelService.onUpdateDirectMessageListEvent$.subscribe(() => {
        //     this.getAllContacts();
        // }));
    }

    ngOnInit(): void {
        // console.log("%c contacts init ", consoleLogStyle, this.contactsFiltered)
        this.isLoadingContacts = true;
        this.isLoadingSortOptions = true;
        this._getSubscription();
    }

    // gets your current plan ... 
    _getSubscription() {
        this.isLoadingSubscription = true;
        this._subscriptionService.getSubscriptionById(this._authService._userInfo.subscriptionId)
            .subscribe((data) => {
                if (data.status === 200) {
                    this.subscriptionPlan = data.body;
                    // console.info("%c user subscription plan > ", consoleLogStyle, this.subscriptionPlan)
                    this.isLoadingSubscription = false;
                    // console.info("%c user info from auth ? ", this.consoleStyle, this._authService._userInfo)
                } else {
                    console.error("error getting subcriptions", data)
                    this.isLoadingSubscription = false;
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            if (!!this.userInfo) {
                let sortOrder = localStorage.getItem('contactsOrder');
                // console.log("%c user info input changes CONTACTS ", consoleLogStyle, sortOrder);
                if(!!sortOrder && sortOrder !== '') {
                    this.sortOptions = this.sortOptions.sort((a,b) => {
                        return a === sortOrder ? -1 : b === sortOrder ? 1 : 0; // this sets this.sortOrder[0] to Local Storage order ... 
                    });
                    this.isLoadingSortOptions = false;
                    this.getAllContacts();
                    // console.log('%c sort order ', consoleLogStyle, this.sortOptions);
                } else {
                    this.isLoadingSortOptions = false;
                    this.getAllContacts();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // get all contacts 
    getAllContacts() {
        // this.isLoadingContacts = true;
        // this.contacts = [];
        // this.contactsFiltered = [];
        // console.log("User Role: " + this._authService._user.profile.role);
        // console.log("%c api call with userinfo CONTACTS ", consoleLogStyle, this.userInfo);
        if (!this.isViewer) {
            this._contactsService.getAllContactsNoPage(this.userInfo.channelUserId)
                .subscribe(data => {
                    if(data.status === 200) {
                        //console.log("%c contacts ", consoleLogStyle, data.body) ////CG
                        this.contactsFiltered = data.body;
                        // this.contactsPag = JSON.parse(data.headers.get('X-Pagination'));
                        if(!!this.contactsFiltered && this.contactsFiltered.length > 0) {
                            this.sortBy();
                        } else {
                            this.isLoadingContacts = false;
                            this.hasNoContacts = true;
                        }
                    } else {
                        this._toastService.error("There was an error getting your contacts.");
                        this.isLoadingContacts = false;
                    }
                });
        } else {
            this._contactsService.getAllContactsViewer(this.userInfo.channelUserId)
                .subscribe(data => {
                    if(data.status === 200) {
                        // console.log("%c contacts ", consoleLogStyle, data.body)
                        this.contactsFiltered = data.body;
                        // this.contactsPag = JSON.parse(data.headers.get('X-Pagination'));
                        if(!!this.contactsFiltered && this.contactsFiltered.length > 0) {
                            this.sortBy();
                        } else {
                            this.isLoadingContacts = false;
                        }
                    } else {
                        this._toastService.error("There was an error getting your contacts.");
                        this.isLoadingContacts = false;
                    }
                });
        }
    }

    setSortBy() {
        this.sortOptions.push(this.sortOptions.shift());
        localStorage.setItem('contactsOrder', this.sortOptions[0]);
        this.sortBy();
    }

    sortBy() {
        // this.isSortingContacts = true;
        
        // status only ... 
        if(this.sortOptions[0] === 'Activity') { 
            this.contactsFiltered = this.contactsFiltered.sort((a,b) => a.userStatusIdx - b.userStatusIdx);
            // ... 
            if(this.contactsFiltered.length > 0) {
                this.isLoadingContacts = false;
            }
        }
        
        // favs first then by status ... 
        if(this.sortOptions[0] === 'Favorites') { 
            let favs = [];
            let notFavs = [];
            this.contactsFiltered.forEach(contact => {
                if(contact.isFavorite) {
                    favs.push(contact);
                } else {
                    notFavs.push(contact);
                }
            });
            let favsSorted = favs.sort((a,b) => a.userStatusIdx - b.userStatusIdx);
            let notFavsSorted = notFavs.sort((a,b) => a.userStatusIdx - b.userStatusIdx);
            let result = favsSorted.concat(notFavsSorted);
            this.contactsFiltered = result;
            // ... 
            if(this.contactsFiltered.length > 0) {
                this.isLoadingContacts = false;
            }
        }
        
        // just a - z ... 
        if(this.sortOptions[0] === 'A - Z') { 
            this.contactsFiltered = this.contactsFiltered.sort((a,b) => {
                if(a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
                    return -1;
                }
                if(a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
            // ... 
            if(this.contactsFiltered.length > 0) {
                this.isLoadingContacts = false;
            }
        }
        // 💡 
        // w/o the length check the 'you have no contacts' message will flash a sec, after navigating home from a channel ... 
        // i think this happens during sorting if the array is 0 length for < a sec and flashes wrong message ... 
        // i think this fix works because if it checks the length while it is still sorting it will wait til sort is done? ... 
        
        // moved to end of each if(sort option) ... 
        // if(this.contactsFiltered.length > 0) {
        //     this.isLoadingContacts = false;
        // }
    }

    // sync contacts from tennant 
    populateContacts() {
        // this.isPopulatedContacts = true;
        this.contactsFiltered = [];
        this.isLoadingContacts = true;
        this._contactsService.populateContactsFromTennant(this.userInfo.channelUserId)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("%c contact sync success ", consoleLogStyle)
                    this.getAllContacts();
                } else {
                    this._toastService.error("There was an error syncing your contacts.")
                }
            })
    }

    sendDirectMessage( user: Contact) {

        // 
        // console.log("Open Direct Messages: ", user);
        this._channelService.directMessageEmitEvent();
        // if dm does not exsist pass there id ...
        //console.log("User DM: "+ user.dmChannelId);

        if (user.dmChannelId) {
            this._channelService.redirectDirectMessage(user.dmChannelId, user.displayName);
        } else {
            this._channelService.redirectDirectMessage('composeNewMessage', user.displayName, user.contactChannelUserId);
        }
    }
    
    /*
    sendDirectMessage(e: Event, user: Contact) {
        e.stopPropagation();
        // 
        this._channelService.directMessageEmitEvent();
        // if dm does not exsist pass there id ...
        if (user.dmChannelId) {
            this._channelService.redirectDirectMessage(user.dmChannelId, user.displayName);
        } else {
            this._channelService.redirectDirectMessage('composeNewMessage', user.displayName, user.contactChannelUserId);
        }
    }
    */

    // favs
    favoriteContact(contactId: string, contactIdx: number, e: Event) {
        e.stopPropagation();
        let patch = {
            "op": "replace",
            "path": "/isFavorite",
            "value": true
        };
        this._contactsService.patchContact(contactId, patch)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("contact favorited")
                    this.contactsFiltered[contactIdx].isFavorite = true;
                    this.sortBy();
                } else {
                    this._toastService.error("There was an error favoriting this contact.");
                }
            });
    }

    unFavoriteContact(contactId:string, contactIdx: number, e: Event) {
        e.stopPropagation();
        let patch = {
            "op": "replace",
            "path": "/isFavorite",
            "value": false
        };
        this._contactsService.patchContact(contactId, patch)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("contact un-favorited")
                    this.contactsFiltered[contactIdx].isFavorite = false;
                    this.sortBy();
                } else {
                    this._toastService.error("There was an error un-favoriting this contact.");
                }
            });
    }

    public removeContactPrompt(contactId: string, name: string, contactIdx: number) {
        this.contactToRemove = {
            "contactId": contactId,
            "name": name,
            "contactIdx": contactIdx
        };
        this._toastService.continue(`Are you sure you want to remove ${name}?`, "removeContact");
    }

    private removeContact(contactId: string, name: string, contactIdx: number) {
        // this.isRemovingContact = true;
        let element = <HTMLElement>document.getElementsByClassName('contact-container')[contactIdx];
        let element2 = <HTMLElement>document.getElementsByClassName('contact-container')[contactIdx + 1] || null;
        element.style.opacity = '0.5';
        this._contactsService.deleteContact(contactId)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("contact removed", contactId)
                    // this._toastService.success(`${name} has been removed from your contacts.`);
                    let updatedContacts = this.contactsFiltered.filter(contact => contact.contactId !== contactId);
                    customExitTransition(element, element2);
                    setTimeout(() => {
                        this.contactsFiltered = updatedContacts;
                        // this.isRemovingContact = false;
                        this.contactToRemove = {
                            contactId: null,
                            name: null,
                            contactIdx: null,
                        };
                    }, 262);
                } else {
                    this._toastService.error(`There was an error deleting ${name}.`);
                    element.style.opacity = '1';
                    // this.isRemovingContact = false;
                    this.contactToRemove = {
                        contactId: null,
                        name: null,
                        contactIdx: null,
                    };
                }
            });
    }

    // to close all modals | add switch if needed to close milti ... 
    clickOffModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'create-contact-modal': {
                if(this.displayCreateContactWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateContactModal");
                } else {
                    this.closeCreateContactModal();
                }
                break;
            }
            case 'contact-info-modal': {
                if(this.displayAddNoteWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeContactInfoModal");
                } else {
                    this.closeContactInfoModal();
                }
                break;
            }
            case 'email-invite-modal': {
                // this.closeEmailInviteModal();
                this.closeEmailInviteModalConfirm();
                break;
            }
            default: return;
        }
    }

    // contact info modal
    showContactInfoModal(idx: number) {
        this.selectedContact = this.contactsFiltered[idx];
        this.displayContactInfoModal = true;
    }
    closeContactInfoModal() {
        this.displayContactInfoModal = false;
        this.selectedContact = null;
    }
    closeContactInfoModalConfirm() {
        if(this.displayAddNoteWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeContactInfoModal");
        } else {
            this.closeContactInfoModal();
        }
    }

    // 🚧 show edit contact from contact info ...
    editContactFromInfo() {
        this.displayContactInfoModal = false;
        this.isEditContact = true;
        this.displayCreateContactModal = true;
    }

    // create contact modal
    showCreateContactModal(isEdit?: boolean, idx?: number) {
        this.closeOptionModal();
        if(isEdit) {
            this.isEditContact = true;
            this.selectedContact = this.contactsFiltered[idx];
            this.displayCreateContactModal = true;
        } else {
            this.displayCreateContactModal = true;
        }
    }
    // 💡 this also updates the UI w/o calling api again ... 
    closeCreateContactModal(newContactObj?:Contact) {
        // console.log("1 new contact obj created | updated", newContactObj)
        this.displayCreateContactModal = false;
        this.displayCreateContactWarning = false;
        if(newContactObj) {
            newContactObj.displayName = `${newContactObj.firstName} ${newContactObj.lastName}`;
            if(this.isEditContact) {
                this.isEditContact = false;
                this._toastService.success(`${newContactObj.displayName} has been updated.`);
                // console.log("2 selected Contact from list", this.contactsFiltered[this.contactsFiltered.indexOf(this.selectedContact)])
                // console.log("3 selected Contact from list index", this.contactsFiltered.indexOf(this.selectedContact))
                this.contactsFiltered[this.contactsFiltered.indexOf(this.selectedContact)] = newContactObj;
                // console.log("4 Updated selected Contact from list", this.contactsFiltered[this.contactsFiltered.indexOf(this.selectedContact)])
                this.selectedContact = null;
                this.sortBy();
            } else {
                this._toastService.success(`${newContactObj.displayName} has been added to your Contacts.`);
                this.contactsFiltered.push(newContactObj);
                this.sortBy();
            }
        } else {
            if(this.isEditContact) {
                this.isEditContact = false;
                this.selectedContact = null;
            }
        }
    }
    closeCreateContactModalConfirm() {
        if(this.displayCreateContactWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateContactModal");
        } else {
            this.closeCreateContactModal();
        }
    }

    addNoteTouched(isFormTouched: boolean) {
        if(isFormTouched) {
            this.displayAddNoteWarning = true;
        } else {
            this.displayAddNoteWarning = false;
        }
    }

    prevDefStopProp(e: Event) {
        // console.log('oi')
        e.preventDefault();
        e.stopPropagation();
    }
    stopProp(e: Event) {
        e.stopPropagation();
    }

    // contact options dropdown ... 
    showContactOptionsDropdown(i: number ,e?: Event) {
        e.stopPropagation();
        const options = document.getElementById(`contact-options-dropdown-${i}`);
        options.classList.add('active');
        
        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('contactsScrollbox');
        scrollBox.classList.add('hidden');
    }

    hideContactOptionsDropdown(i: number ,e?: Event) {
        const options = document.getElementById(`contact-options-dropdown-${i}`);
        
        if (options == null){
            console.error(`Error getting contact-options-dropdown-${i}`);
            return;
        }
            
        options.classList.remove('active');
        
        // also resume scrolling ... 
        let scrollBox = <HTMLElement>document.getElementById('contactsScrollbox');
        scrollBox.classList.remove('hidden');
    }

    handleBrokenImage(contactIdx:number ) {
        this.contactsFiltered[contactIdx].hash = '1';
    }

    showOptionModal() {
        this.displayOptionModal = true;
    }

    closeOptionModal() {
        this.displayOptionModal = false;
    }

    showEmailInviteModal() {
        this.closeOptionModal();
        this.displayEmailInviteModal = true;
    }

    closeEmailInviteModal() {
        this.displayEmailInviteWarning = false;
        this.displayEmailInviteModal = false;
    }

    closeEmailInviteModalConfirm() {
        if (this.displayEmailInviteWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeEmailInviteModal");
        } else {
            this.closeEmailInviteModal();
        }
    }

    getDmChannel(e: Event, user: Contact){
        e.stopPropagation();

        //console.log("Contact Info: " + user.contactChannelUserId);
        //this._apiCallService.getDirectChannelByTwoUsers(this._authService._userInfo.sub)
        this._apiCallService.getDirectChannelByTwoUsers(user.contactChannelUserId)
        .subscribe(res => {
            if(res.status === 200) {
                // console.log("contact un-favorited")
                let data = res.body;
                user.dmChannelId = data.channelId;
                this.sendDirectMessage(user);
                //console.log("User DM API: "+ JSON.stringify(res.body));
            } else {
                this._toastService.error("There was an getting the DM channel for the contact.");
            }
        });

    }
}









































// 💡 PAGINATION REMOVED DUE TO FRONT-END SORTING ISSUES ... 
//   SET STATUS REMOVED ... ONLY RE-SET FROM SIG-R ...

    // setContactStatus() {
    //     let contactList: any[] = [];
    //     this.contacts.forEach(contact => {
    //         let contactStatus: any  = {
    //             ...contact,
    //             "userStatus": "none",
    //             "userStatusIdx": 5
    //         };
    //         if (contact.contactChatUserKeyNavigation.selectedStatus === 0) {
    //             if (contact.contactChatUserKeyNavigation.presenceStatus === 0) {
    //                 contactStatus.userStatus = "offline";
    //                 contactStatus.userStatusIdx = 4;
    //             } else if (contact.contactChatUserKeyNavigation.presenceStatus === 1) {
    //                 contactStatus.userStatus = "idle"; // was 'away' ... 
    //                 contactStatus.userStatusIdx = 2;
    //             } else if (contact.contactChatUserKeyNavigation.presenceStatus === 2) {
    //                 contactStatus.userStatus = "active";
    //                 contactStatus.userStatusIdx = 0;
    //             }
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 1) {
    //             contactStatus.userStatus = "offline";
    //             contactStatus.userStatusIdx = 4;
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 2) {
    //             contactStatus.userStatus = "dnd";
    //             contactStatus.userStatusIdx = 3;
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 3) {
    //             contactStatus.userStatus = "brb";
    //             contactStatus.userStatusIdx = 1;
    //         }
    //         contactList.push(contactStatus);
    //     });
    //     this.contactsFiltered = contactList;
    //     this.sortBy();
    //     // this.contactsFiltered.sort((a,b) => a.userStatusIdx - b.userStatusIdx);
    //     // this.isLoadingContacts = false;
    //     console.log("%c contacts filtered ", consoleLogStyle, this.contactsFiltered)
    // }

    // _getMoreContacts(link:any) { // returns 24 results ... 2 page len avg. [is 15 for testing...]
    //     this.isLoadingContacts = true;
    //     this._apiCallsService.getGenericForPagination(link)
    //         .subscribe(data => {
    //             if(data.status === 200) {
    //                 this.contactsPag = JSON.parse(data.headers.get('X-Pagination'));
    //                 // console.log("%c MORE contacts ", consoleLogStyle, this.contacts);
    //                 // console.log("%c contacts pagination ? ", consoleLogStyle, this.contactsPag);
    //                 this.contactsFiltered = this.contactsFiltered.concat(data.body);
    //                 this.sortBy();
    //                 // this.setContactPaginationStatus(data.body); // ❌ ... done on backend ... 
    //             } else {
    //                 console.error("error getting your contacts ...")
    //                 this._toastService.error("There was an error getting your contacts.");
    //                 this.isLoadingContacts = false;
    //             }
    //         });
    // }

    // setContactPaginationStatus(moreContacts:any) {
    //     let contactList: any[] = [];
    //     moreContacts.forEach(contact => {
    //         let contactStatus: any  = {
    //             ...contact,
    //             "userStatus": "none",
    //             "userStatusIdx": 5
    //         };
    //         if (contact.contactChatUserKeyNavigation.selectedStatus === 0) {
    //             if (contact.contactChatUserKeyNavigation.presenceStatus === 0) {
    //                 contactStatus.userStatus = "offline";
    //                 contactStatus.userStatusIdx = 4;
    //             } else if (contact.contactChatUserKeyNavigation.presenceStatus === 1) {
    //                 contactStatus.userStatus = "idle"; // was 'away' ... 
    //                 contactStatus.userStatusIdx = 2;
    //             } else if (contact.contactChatUserKeyNavigation.presenceStatus === 2) {
    //                 contactStatus.userStatus = "active";
    //                 contactStatus.userStatusIdx = 0;
    //             }
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 1) {
    //             contactStatus.userStatus = "offline";
    //             contactStatus.userStatusIdx = 4;
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 2) {
    //             contactStatus.userStatus = "dnd";
    //             contactStatus.userStatusIdx = 3;
    //         } else if (contact.contactChatUserKeyNavigation.selectedStatus === 3) {
    //             contactStatus.userStatus = "brb";
    //             contactStatus.userStatusIdx = 1;
    //         }
    //         contactList.push(contactStatus);
    //     });
    //     this.contactsFiltered = this.contactsFiltered.concat(contactList);
    //     this.sortBy();
    //     // this.contactsFiltered.sort((a,b) => a.userStatusIdx - b.userStatusIdx);
    //     // this.isLoadingContacts = false;
    //     console.log("%c contacts filtered pagination", consoleLogStyle, this.contactsFiltered)
    // }


    // 💡 infinite scrolling w/ Intersection Observer api ... [(@listAnimationItemLong.done)="last ? runPaginationObserver() : ''"] 
    // 🌐 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API 
    // paginationObserver() {
    //     let lastEl = <HTMLElement>document.getElementById('LastContact'); // grab last El in list [after animation done & is in DOM] 
    //     console.log("%c List anime done: run intersect observer 🎯 LAST El: ", consoleLogStyle, lastEl)
    //     if(!!lastEl) {
    //         let observer = new IntersectionObserver(entries => {
    //             entries.forEach(entry => { // can observe multiple El's but will always be an Array of [entries] 
    //                 console.log("%c is 🎯 El intersecting? ", consoleLogStyle, entry.isIntersecting)
                    
    //                 // || entry.intersectionRatio > 0 - also works
    //                 if (entry.isIntersecting && entry.intersectionRatio >= 0) { // bottom of view intersecting W/ the top of El 
    //                     console.log('%c 🎯 El is IN view run get next page! ', consoleLogStyle);
                        
    //                     this._getMoreContacts(this.contactsPag.nextPageLink);
                        
    //                     observer.unobserve(entry.target); // stop tracking this El, will grab next LastEl 
    //                 } else {
    //                     console.log('%c 🎯 El OUT of view. ',consoleLogStyle);
    //                 }
    //             });
    //         });
    //         observer.observe(lastEl); // track the last El in list 
    //     } else {
    //         console.info("no last EL?", lastEl);
    //     }
    // }