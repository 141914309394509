import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";


@Component({
    selector: "modal",
    templateUrl: "./modal.component.html",
})
export class ModalComponent implements OnDestroy {

    @Output("closeModal") closeModal = new EventEmitter();
    public formTouched: boolean = false;
    public toastResolver: string = "closeModal";
    public toastMessage: string = "You are about lose all progress, are you sure you want to close this form?";
    private subscriptions: Subscription[] = [];

    constructor( 
            private _toastService: ToastNotificationService,
            private _modalService: ModalService,
        ) { 
        this.subscriptions.push(this._toastService.onToastResolveEvent$.pipe(tap((data) => {
            if(data.type === this.toastResolver) {
                this.closeModal.emit();
            }
        })).subscribe());
        
        this.subscriptions.push(this._modalService.modalFormTouchedSubject.pipe(tap( _ => {
            this.formTouched = true;
        })).subscribe());
        this.subscriptions.push(this._modalService.modalFormCompleteSubject.pipe(tap( _ => {
            this.closeModal.emit();
        })).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    closeConfirm() {
        if(this.formTouched) {
            this._toastService.continue(this.toastMessage, this.toastResolver);
        } else {
            this.closeModal.emit();
        }
    }
}