import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FaxHomepageComponent } from './fax-homepage/fax-homepage.component';

//import { AuthGuard } from '../core/authguard';

// const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
      { path: 'fax', component: FaxHomepageComponent }
  ])],
  exports: [RouterModule]
})
export class FaxAppRoutingModule { }