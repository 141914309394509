<!-- 🚧 new ui ... -->
<div 
    (window:resize)="onWindowResize()"
    *ngIf="pageReady && notInChannel"
    class="channels-page flex">
    <!--  -->
    <div class="channels-main-col flex-col">
        <!-- header -->
        <div 
            class="channel-manager align-center p-4"
            [ngClass]="{
                'flex': isDesktop,
                'flex-col': !isDesktop,
                'p-4': isDesktop,
                'p-2': !isDesktop
            }">
            <div 
                class="flex-col"
                [ngClass]="{
                    'w-desktop': isDesktop,
                    'w-mobile': !isDesktop
                }">
                <strong class="flex"
                    >Organization:
                    <div class="text-ellipsis ms-2">
                        {{ !!userInfo ? userInfo.tenant : " " }}
                    </div>
				</strong>
                <!--  -->
                <mat-select
                    (openedChange)="null"
                    (valueChange)="selectChannelOrder($event)"
                    [(value)]="channelSelectBy"
                    name="channelSortOptions"
                    class="flex borderless-select channel-select mb-1"
                    id="channelSortOptions">
                    <mat-option value="history">
                        <i class="fal fa-clock"></i>Channel History
                    </mat-option>
                    <mat-option value="favorites">
                        <i class="fal fa-star"></i>Favorite Channels
                    </mat-option>
                    <mat-option value="projects">
                        <i class="fal fa-project-diagram"></i>All Channels
                    </mat-option>
                </mat-select>
                <!--  -->
                <div 
                    *ngIf="channelSelectBy === 'projects'"
                    @fadeInFast
                    class="by-proj"
                    >&bull;&nbsp;by project
                </div>
            </div>
            <button 
                (click)="showCreateChannelModal()"
                matTooltip="add project"
                matTooltipClass="tooltip-default"
                class="primary-button"
                [class.ms-auto]="isDesktop"
                [class.mt-2]="!isDesktop">
                <i class="fas fa-plus me-3"></i>Create New
            </button>
        </div>
        <!--  -->
        <!-- ⏲ BY HISTORY || ⭐ BY FAVORITES -->
        <ng-container *ngIf="channelSelectBy === 'history' || channelSelectBy === 'favorites'">
            <!-- history -->
            <div 
                [@listAnimationWrapLong]="channelCards.length" 
                class="channels-wrap flex-row-wrap justify-center mt-3 mx-auto pb-4 scrollbar-new"
                id="channelScrollbox">
                <ng-container *ngFor="let channel of channelCards; index as i; last as last">
                    <ng-container *ngIf="channel.teamProjectName !== 'Shared Folders'">
                        <!-- channel card -->
                        <div 
                            @listAnimationItemLong 
                            class="channel-container my-3"
                            [class.mx-2]="isDesktop"
                            [class.mx-0]="!isDesktop"
                            id="{{(last && channelCardsPag.nextPageLink) ? 'LastChannel' : ''}}"
                            (@listAnimationItemLong.done)="(last && channelCardsPag.nextPageLink) ? paginationObserver() : ''">
                            <!-- options dropdown : if owner show [user manager & schedule meet links] -->
                            <button 
                                type="button" 
                                (wheel)="prevDefStopProp($event)"
                                (click)="showChannelHistoryOptionsDropdown(i)"
                                (mouseleave)="hideChannelHistoryOptionsDropdown(i)"
                                class="channel-options-btn pe-1">
                                <i 
                                    matTooltip="channel options" 
                                    matTooltipClass="tooltip-default" 
                                    class="fas fa-ellipsis-v lg-icon"></i>
                                <!-- channel options dropdown -->
                                <div id="channel-options-dropdown-{{i}}" class="channel-options-dropdown mt-1 py-2">
                                    <ng-container *ngIf="channel.isOwner">
                                        <!-- schedule meeting in channel -->
                                        <button (click)="showScheduleModal(channel.channelId, channel.channelName)" class="list-item">
                                            <i class="far fa-calendar-alt calendar-icon"></i>Schedule meeting
                                        </button>
                                        <!-- user manager/modal -->
                                        <button (click)="showManageUsersModal(channel.channelId)" class="list-item">
                                            <i class="far fa-address-card"></i>Manage users
                                        </button>
                                    </ng-container>
                                    <!-- channel info -->
                                    <button (click)="showChannelInfoModal(channel.channelId)" class="list-item">
                                        <i class="far fa-info-circle info-icon"></i>Channel info
                                    </button>
                                </div>
                            </button>
                            <!-- channel card body -->
                            <a 
                                class="channel-card flex-col" 
                                [routerLink]="'/channel/' + channel.channelId">
                                <div class="channel-card-top-row flex mb-3">
                                    <!-- title -->
                                    <div class="channel-title">
                                        <div class="flex">
                                            <small 
                                                *ngIf="channel.private"
                                                matTooltip="private" 
                                                matTooltipClass="tooltip-default">
                                                <i class="far fa-lock"></i>&nbsp;
                                            </small>
                                            <small 
                                                *ngIf="!channel.private"
                                                matTooltip="public" 
                                                matTooltipClass="tooltip-default">
                                                <i class="far fa-users"></i>&nbsp;
                                            </small>
                                            <!--  -->
                                            <small class="text-ellipsis proj-name">{{channel.teamProjectName}}</small>
                                        </div>
                                        <h4 class="flex m-0 text-ellipsis">{{channel.channelName}}</h4>
                                    </div>
                                    <div class="channel-options mt-1 me-2">
                                        <!-- 🚧 favorite channel -->
                                        <button 
                                            matRipple
                                            [matRippleColor]="rippleColor"
                                            [matRippleUnbounded]="true"
                                            [matRippleCentered]="true"
                                            class="star-btn circle"
                                            (click)="channel.isFavorite ? unFavoriteChannelHistoryView($event, channel.channelHistoryId, i) : favoriteChannelHistoryView($event, channel.channelHistoryId, i)">
                                            <i 
                                                [class.active]="channel.isFavorite"
                                                class="fa{{channel.isFavorite ? 's':'r'}} fa-star"
                                                matTooltip="{{channel.isFavorite ? 'unfavorite':'make favorite'}}" 
                                                matTooltipClass="tooltip-default"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- channel avatars -->
                                <div 
                                    class="channel-card-avitars flex mt-2" @listAnimationWrapAvatar>
                                    <!-- how many more users | > 7 -->
                                    <h4 
                                        (click)="prevDefStopProp($event)" 
                                        @fadeIn 
                                        class="my-auto ms-1" 
                                        style="cursor: default;" 
                                        *ngIf="channel.allChannelUsersRemainder !== 0" 
                                        matTooltip="plus {{channel.allChannelUsersRemainder}} more user{{(channel.allChannelUsersRemainder) > 1 ? 's':''}}" 
                                        matTooltipClass="tooltip-default">
                                        +{{channel.allChannelUsersRemainder}}
                                    </h4>
                                    <!-- first avitar is CREATOR && then OWNERS then members W/ #7 max then + left length (ROW is REVERSED [last is first]) -->
                                    <!-- pipe takes first 7 from all users & reverses it for display in row-rev -->
                                    
                                    <ng-container *ngFor="let user of channel.allChannelUsers | channelCardAvatars; index as idx; last as lastImg">
                                        <ng-container>
                                            <img 
                                                @listAnimationItemAvatar 
                                                *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                                (click)="prevDefStopProp($event)" 
                                                src="https://profile-images.azureedge.us/{{user.hash}}" 
                                                (error)="handleBrokenImage(null, i, idx)"
                                                class="circle" alt="user" 
                                                [class.first]="lastImg" 
                                                [class.member]="user.rank === 'member'" 
                                                matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                                matTooltipClass="tooltip-default"/>
                                            <ng-template #defaultImg>
                                                <div 
                                                    @listAnimationItemAvatar 
                                                    (click)="prevDefStopProp($event)" 
                                                    class="default-user-img circle flex-center-all"
                                                    [class.first]="lastImg" 
                                                    [class.member]="user.rank === 'member'" 
                                                    [class.every-first]="idx % 4 === 0"
                                                    [class.every-second]="idx % 4 === 1"
                                                    [class.every-third]="idx % 4 === 2"
                                                    [class.every-fourth]="idx % 4 === 3"
                                                    matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                                    matTooltipClass="tooltip-default">
                                                    {{user.userName|defaultImageOverlay}}
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="loading-more-channels flex justify-center mt-4" *ngIf="isLoadingMoreChannels">
                    <i class="fas fa-spinner spinner m-auto"></i>
                </div>
                <div class="no-channels flex-row-nowrap-center-all mt-4" *ngIf="noChannelHistory">
                    <p class="text-center secondary-text">You do not have any channels in history.</p>
                </div>
                <div class="no-channels flex-row-nowrap-center-all mt-4" *ngIf="noChannelFavorites">
                    <p class="text-center secondary-text">You do not have any favorite channels.</p>
                </div>
            </div>
            <!--  -->
            <div class="loading-channels flex-center-all" *ngIf="isLoadingChannelCards">
                <loading-home></loading-home>
            </div>
        </ng-container>
        <!--  -->
        <!-- 📺 BY PROJECT -->
        <ng-container *ngIf="channelSelectBy === 'projects'">
            <!-- projects -->
            <!-- 🚧🚧🚧 re-factor to dropdowns here -->
            <div
                class="project-container scrollbar-new flex-col mx-auto mt-4"
                @fadeInFast
                id="projectScrollbox">
                <!-- expand/collapse all -->
                <div 
                    *ngIf="!isLoadingProjects"
                    class="example-action-buttons flex mb-3"
                    [class.ms-auto]="isDesktop">
                    <button 
                        (click)="accordion.openAll()"
                        matRipple
                        [matRippleColor]="rippleColor"
                        class="mx-3 py-1 px-2">
                        Expand All
                    </button>
                    <button 
                        (click)="accordion.closeAll()"
                        matRipple
                        [matRippleColor]="rippleColor"
                        class="mx-3 py-1 px-2">
                        Collapse All
                    </button>
                </div>
                <mat-accordion class="example-headers-align" multi>
                    <ng-container *ngFor="let project of projects;index as projIdx;first as first;">
                        <ng-container *ngIf="project.name !== 'Shared Folders'">
                            <mat-expansion-panel 
                                class="project" 
                                (opened)="
                                    project.channels.length === 0 && project.channelCount !== 0 ?
                                    getChannelsForProject(project.teamProjectId, projIdx) : ''
                                ">
                                <mat-expansion-panel-header>
                                    <!-- icon -->
                                    <i 
                                        class="project-icon fal" 
                                        [class.fa-lock]="project.private"
                                        [class.fa-users]="!project.private"
                                        matTooltip="{{project.private ? 'private':'public'}}"
                                        matTooltipClass="tooltip-default"
                                    ></i>
                                    <!-- title -->
                                    <mat-panel-title>
                                        <div class="project-name">
                                            {{project.name}}
                                        </div>
                                        <!-- 🚧 channels length -->
                                        <div class="project-channel-len">
                                            <ng-container
                                                *ngIf="
                                                    project.channelCount === 0;
                                                    else channelsLen">
                                                &bull;&nbsp;No Channels
                                            </ng-container>
                                            <ng-template #channelsLen>
                                                &bull;&nbsp;{{ project.channelCount }}
                                                <ng-container
                                                    *ngIf="
                                                        project.channelCount === 1;
                                                        else channels"
                                                    >Channel</ng-container>
                                                <ng-template #channels
                                                    >Channels</ng-template>
                                            </ng-template>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- 🚧🚧🚧 dropdown -->
                                <ng-container *ngIf="project.isLoadingChannels">
                                    <!-- loading channels -->
                                    <loading-home></loading-home>
                                </ng-container>
                                <!-- *ngIf="!project.isLoadingChannels" -->
                                <div
                                    
                                    class="channels-wrap-dropdown flex-row-wrap"
                                    [@listAnimationWrapLong]="project.channels.length">
                                    <ng-container
                                        *ngFor="
                                            let channel of project.channels;
                                            index as chIdx;
                                            even as even">
                                        <div
                                            @listAnimationItemLong
                                            class="channel-container my-3"
                                            [class.mx-2]="isDesktop"
                                            [class.mx-0]="!isDesktop">
                                            <button
                                                type="button"
                                                (wheel)="prevDefStopProp($event)"
                                                (click)="showChannelOptionsDropdown(projIdx,chIdx)"
                                                (mouseleave)="hideChannelOptionsDropdown(projIdx,chIdx)"
                                                class="channel-options-btn pe-1">
                                                <i
                                                    matTooltip="channel options"
                                                    matTooltipClass="tooltip-default"
                                                    class="fas fa-ellipsis-v lg-icon"
                                                ></i>
                                                <div
                                                    id="project-channel-options-dropdown-{{projIdx}}-{{ chIdx }}"
                                                    class="channel-options-dropdown mt-1 py-2">
                                                    <ng-container
                                                        *ngIf="channel.isOwner">
                                                        <button
                                                            (click)="showScheduleModal(channel.channelId, channel.channelName)"
                                                            class="list-item">
                                                            <i class="far fa-calendar-alt calendar-icon"></i>Schedule meeting
                                                        </button>
                                                        <button
                                                            (click)="showManageUsersModal(channel.channelId)"
                                                            class="list-item">
                                                            <i class="far fa-address-card"></i>Manage users
                                                        </button>
                                                    </ng-container>
                                                    <ng-container>
                                                        <button
                                                            (click)="showChannelInfoModal(channel.channelId)"
                                                            class="list-item">
                                                            <i class="far fa-info-circle info-icon"></i>Channel info
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </button>
                                            <a
                                                class="channel-card flex-col"
                                                [routerLink]="'/channel/' + channel.channelId">
                                                <div
                                                    class="channel-card-top-row flex mb-3">
                                                    <div class="channel-title">
                                                        <div class="flex">
                                                            <small 
                                                                *ngIf="channel.private"
                                                                matTooltip="private" 
                                                                matTooltipClass="tooltip-default">
                                                                <i class="far fa-lock"></i>&nbsp;
                                                            </small>
                                                            <small 
                                                                *ngIf="!channel.private"
                                                                matTooltip="public" 
                                                                matTooltipClass="tooltip-default">
                                                                <i class="far fa-users"></i>&nbsp;
                                                            </small>
                                                            <!--  -->
                                                            <small class="text-ellipsis proj-name">{{channel.teamProjectName}}</small>
                                                        </div>
                                                        <h4 class="flex m-0 text-ellipsis">{{channel.channelName}}</h4>
                                                    </div>
                                                    <div
                                                        class="channel-options mt-1 me-2">
                                                        <button
                                                            matRipple
                                                            [matRippleColor]="rippleColor"
                                                            [matRippleUnbounded]="true"
                                                            [matRippleCentered]="true"
                                                            class="star-btn circle"
                                                            (click)="
                                                                channel.isFavorite
                                                                    ? unFavoriteChannel(
                                                                        $event,
                                                                        channel.channelHistoryId,
                                                                        projIdx,
                                                                        chIdx)
                                                                    : favoriteChannel(
                                                                        $event,
                                                                        channel.channelHistoryId,
                                                                        projIdx,
                                                                        chIdx)">
                                                            <i
                                                                [class.active]="channel.isFavorite"
                                                                class="fa{{
                                                                    channel.isFavorite
                                                                        ? 's'
                                                                        : 'r'}} fa-star"
                                                                matTooltip="{{
                                                                    channel.isFavorite
                                                                        ? 'unfavorite'
                                                                        : 'make favorite'}}"
                                                                matTooltipClass="tooltip-default"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="channel-card-avitars flex mt-2"
                                                    @listAnimationWrapAvatar>
                                                    <h4
                                                        (click)="prevDefStopProp($event)"
                                                        @fadeIn
                                                        class="my-auto ms-1"
                                                        style="cursor: default"
                                                        *ngIf="channel.allChannelUsersRemainder!== 0"
                                                        matTooltip="plus {{
                                                            channel.allChannelUsersRemainder
                                                        }} more user{{
                                                            channel.allChannelUsersRemainder > 1
                                                                ? 's'
                                                                : ''}}"
                                                        matTooltipClass="tooltip-default">
                                                        +{{channel.allChannelUsersRemainder}}
                                                    </h4>
                                                    <ng-container *ngFor="let user of channel.allChannelUsers | channelCardAvatars; index as idx; last as lastImg;">
                                                        <ng-container>
                                                            <img 
                                                                @listAnimationItemAvatar 
                                                                *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                                                (click)="prevDefStopProp($event)" 
                                                                src="https://profile-images.azureedge.us/{{user.hash}}" 
                                                                (error)="handleBrokenImage(projIdx, chIdx, idx)"
                                                                class="circle" alt="user" 
                                                                [class.first]="lastImg" 
                                                                [class.member]="user.rank === 'member'" 
                                                                matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                                                matTooltipClass="tooltip-default"/>
                                                            <ng-template #defaultImg>
                                                                <div 
                                                                    @listAnimationItemAvatar 
                                                                    (click)="prevDefStopProp($event)" 
                                                                    class="default-user-img circle flex-center-all"
                                                                    [class.first]="lastImg" 
                                                                    [class.member]="user.rank === 'member'" 
                                                                    [class.every-first]="idx % 4 === 0"
                                                                    [class.every-second]="idx % 4 === 1"
                                                                    [class.every-third]="idx % 4 === 2"
                                                                    [class.every-fourth]="idx % 4 === 3"
                                                                    matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                                                    matTooltipClass="tooltip-default">
                                                                    {{user.userName|defaultImageOverlay}}
                                                                </div>
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-expansion-panel>
                        </ng-container>    
                    </ng-container>
                </mat-accordion>
                <!--  -->
                <div class="loading-channels flex-center-all" *ngIf="isLoadingProjects">
                    <loading-home></loading-home>
                </div>
            </div>
        </ng-container>
        <!-- end by projects -->
    </div>
</div>

<ng-container *ngIf="!pageReady && notInChannel">
    <div class="flex w-100 h-100">
        <div class="m-auto">
            <loading-home></loading-home>
        </div>
    </div>
</ng-container>


<!-- 🚧 modals -->
<!-- channel info modal -->
<div
    *ngIf="displayChannelInfoModal"
    class="modal"
    id="channel-info-modal"
    (click)="clickOffChannelModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="closeChannelInfoModal()"
    >
        <!-- new close modal ... -->
        <button
            class="close-modal-x flex-center-all"
            (click)="closeChannelInfoModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <channel-info
            [channelId]="selectedChannelId">
        </channel-info>
    </div>
</div>

<!-- Manage users modal -->
<div
    *ngIf="displayManageUsersModal"
    class="modal"
    id="manage-users-modal"
    (click)="clickOffChannelModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="closeManageUsersModal()"
    >
        <!-- new close modal ... -->
        <button
            class="close-modal-x flex-center-all"
            (click)="closeManageUsersModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <!--  -->
        <manage-users
            [channelId]="selectedChannelId"
            [userInfo]="userInfo"
            [isModalVersion]="true">
        </manage-users>
    </div>
</div>

<!-- schedule meeting for channel modal -->
<div
    *ngIf="displayScheduleModal"
    class="modal"
    id="schedule-modal"
    (click)="clickOffChannelModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="closeScheduleModalConfirm()"
    >
        <!-- new close modal ... -->
        <button
            class="close-modal-x flex-center-all"
            (click)="closeScheduleModalConfirm()"
        >
            <i class="fas fa-times"></i>
        </button>
        <schedule-meeting
            [incomingChannelObject]="scheduleChannelMeetingObject"
            (onCompleteEvent)="closeScheduleModal()"
            (formTouchedEvent)="displayScheduleWarning = $event"
        >
        </schedule-meeting>
    </div>
</div>

<!-- create channel modal -->
<!-- <div
    *ngIf="displayCreateChannelModal"
    class="modal"
    id="create-project-channel-modal"
    (click)="clickOffChannelModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="closeCreateChannelModalConfirm()"
    >
        <button
            class="close-modal-x flex-center-all"
            (click)="closeCreateChannelModalConfirm()"
        >
            <i class="fas fa-times"></i>
        </button>
        <create-channel
            [userInfo]="userInfo"
            (onCompleteEvent)="closeCreateChannelModal()"
            (formTouchedEvent)="displayCreateChannelWarning = true"
        >
        </create-channel>
    </div>
</div> -->

<!-- 🚧 testing global modal -->
<modal 
    *ngIf="displayCreateChannelModal" 
    (closeModal)="displayCreateChannelModal = false">
    <create-channel
        [userInfo]="userInfo">
    </create-channel>
</modal>

<!-- 💡 this is the channel hub, all links to primary channels go through here ... -->
<ng-container *ngIf="pageReady && !notInChannel">
    <channel-primary
        *ngIf="
            channelType === 'default' ||
            channelType === 'legacy' ||
            channelType === 'quick' ||
            channelType === 'direct'
        "
    ></channel-primary>
</ng-container>
