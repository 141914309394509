import { Injectable } from "@angular/core";
// import { SwPush } from "@angular/service-worker";
import { Subject } from "rxjs";
import { AlertSetting } from "../shared/interface/alert";
import { consoleLogStyle } from "../shared/varaibles";
import { AlertsService } from "./alerts.service";
import { ToastNotificationService } from "./toast-notification.service";




@Injectable({
    providedIn: "root",
})
export class SystemNotificationService {

    private onGrantPermission = new Subject<boolean>();
    public onGrantPermission$ = this.onGrantPermission.asObservable();

    private onSettingsTosub = new Subject<string[]>();
    public onSettingsTosub$ = this.onSettingsTosub.asObservable();


    // private readonly VAPID_PUBLIC_KEY = 'BHLHwHWG0LY1higXvANb9hNUVjVVk4JuTVgd2XDyvSjfqTSxadaQjHK9p9_3i2Dk9i3URAwb9J4x-FHeUxt-4tY';

    constructor(
        private _alertService: AlertsService,
        private _toast: ToastNotificationService,
        // private _swPush: SwPush,
    ) {}


    // get alert settings for system, if setting true return array with setting name to sub to ... 
    getSystemAlertsSettings(userId:string) {
        this._alertService.getUserAlertSettings(userId)
            .subscribe(data => {
                if(data.status === 200) {
                    let allSettings: AlertSetting[] = data.body;
                    let settingsToSub: string[] = [];
                    allSettings.forEach((setting, idx) => {
                        // console.info("%c setting to sub check?", consoleLogStyle, setting, allSettings, data.body)
                        let last = allSettings.length - 1;
                        if(setting.alertGroup[3] !== null && setting.alertGroup[3].alertValue !== null) {
                            // console.info("%c setting to sub check?", consoleLogStyle, setting)
                            settingsToSub.push(setting.alertName);
                        }
                        if(idx === last) {
                            // console.info('%c settings to sub from & last to next! ', consoleLogStyle, settingsToSub)
                            this.onSettingsTosub.next(settingsToSub);
                        }
                    });
                    // 
                } else {
                    this._toast.error('There was an error getting your alert settings.');
                }
            });
    }

    // // 🚧 NEW WEB-PUSH W/ SERVICE WORKER ...
    // pushNotificationStart() {
    //     if(this._swPush.isEnabled) {
    //         // console.log('%c  ', consoleLogStyle)
    //         // this._swPush.subscription.subscribe(res => {
    //         //     console.log("%c swPush sub ??? ", consoleLogStyle, res)
    //         // });
    //         this._swPush.notificationClicks.subscribe(notCli =>console.log("%c notification click! ", consoleLogStyle, notCli)); 
    //         this._swPush.requestSubscription({
    //             serverPublicKey: this.VAPID_PUBLIC_KEY
    //         }).then(sub => { console.log("%c REQUEST SW PUSH SUB? ", consoleLogStyle, sub) })
    //         .catch(err => console.error("Could not subscribe to notifications", err));
    //     }
    // }

    // 💡 IN COMPONENT TEMPLATE check for : 
    // >  if(this._systemNotification.hasSystemNotifications() && this._systemNotification.isPermissionGranted()) ... 
    // >  if true need to ask for permission & init notifications ... 
    notificationStart() {
        if(this.hasSystemNotifications) {
            this.initNotifications();
        } else {
            console.log("no Notification in window")
        }
    }
    
    // 💡 new notification permission check ... 
    async initNotifications() {
        // console.info("%c ", consoleLogStyle)
        const permission = await Notification.requestPermission();
        switch (permission) {
            case 'granted': {
                // add show notification func here i think ...
                console.log('%c System Notification Permission was granted! ', consoleLogStyle);
                // this.isPermissionGranted = Notification.permission === 'granted';
                this.onGrantPermission.next(this.isPermissionGranted);
                this.showInitialNotification();
                break;
            }
            case 'denied': {
                console.log('%c System Notification Permission was denied ', consoleLogStyle);
                this.onGrantPermission.next(this.isPermissionGranted);
                // this.isPermissionGranted = Notification.permission === 'granted';
                this._toast.error('Notification permission was Denied, you can go to your Browsers settings to reset.   * may not work in browsers private mode 🕵️‍♂️ ');
                break;
            }
            default: {
                console.log('%c System Notification Permission was not granted or denied ', consoleLogStyle);
                this.onGrantPermission.next(this.isPermissionGranted);
                // this.isPermissionGranted = Notification.permission === 'granted';
            }
        }
    }
    
    // to show initial notification if && when user grants permission ... 
    showInitialNotification() {
        console.log('%c Showing initial notification ', consoleLogStyle);
        const notification = this.notify(
            'VIA3 Notifications',
            'You can now recieve system notifications!',
            'initial notification'
        );
        console.log('%c Showing initial notification not????? ', consoleLogStyle, notification);
        if(notification) {
            document.addEventListener('visibilitychange', () => {
                if(document.visibilityState === 'visible') {
                    // The tab has become visible so clear the now-stale Notification.
                    notification.close();
                }
            });
            notification.addEventListener('click', () => {
                // focus tab on notification click ... 
                window.parent.focus();
            });
        }
    }

    // 🚧 to call from alert event from app component ... 
    showNewNotification(message:string, type: string, image?:string) {
        const notification = this.notify(
            'VIA3 Notifications',
            message,
            type,
            image ?? null
        );
        if(notification) {
            document.addEventListener('visibilitychange', () => {
                if(document.visibilityState === 'visible') {
                    // The tab has become visible so clear the now-stale Notification.
                    notification.close();
                }
            });
            notification.addEventListener('click', () => {
                if(type === 'unread message') {
                    // nav to message here ... ? 
                    window.parent.focus();
                } else {
                    window.parent.focus();
                }
            });
        } else {
            console.warn("notification permission not granted! || system not compatible")
        }
    }
    
    // creates notification ... factory function ... 
    notify(title:string, body:string, tag: string, image?:string) {
        if (this.hasSystemNotifications && this.isPermissionGranted) { // fail safe check ... also checking in html ... 
            return new Notification(title, {
                body,
                tag, // 💡 this is so notifications won't stack ... 
                icon: image ?? 'https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-round-blk-blue-FIXED.png', // our logo ... 
                silent: false, // should hear sound ... could be a setting ... 
                vibrate: [100, 50, 100], // for mobile ... could be a setting ... 
                // 💡 image is optional for Chrome only need to resize as small if start using ...
                // image: 'https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Full/logo-blue-transparent-small.png',
            });
        } else {
            console.warn("notification permission not granted! || system not compatible")
            return null;
        }
    }
    
    // to show if system has notifications ... 
    public get hasSystemNotifications(): boolean { return 'Notification' in window };
    public get isPermissionGranted(): boolean { return Notification.permission === 'granted' };
}
