import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ChannelService } from 'src/app/services/channel-service';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../helpers/must-match.validator';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { Subscription } from 'rxjs';
import { ChannelUser } from 'src/app/shared/interface/channel-user';
import { consoleLogStyle, standardEmailRegex } from 'src/app/shared/varaibles';
import { map } from 'rxjs/operators';

// File Hierachy
// user-app
//    - user-sidebar
//       - modal.compont.ts
//       - change password component
//       - change email 
//       - change displayname 

@Component({
    selector: 'user-sidebar',
    templateUrl: './user-sidebar.component.html',
    styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit, OnDestroy {
    @Output() themeModeSwitch = new EventEmitter();
    // @Output() themeColorSwitch = new EventEmitter();
    // @Input() pinnedSideBar: boolean;
    @Input() themeInit: string;
    @Input() userInfo: ChannelUser;
    @Input() userStatus: any;

    public themeColor: number;
    public themeParams: any = {};

    public showStatusList: boolean = false;
    public showAccountList: boolean = false;
    public showOrganizationList: boolean = false;
    public showThemeList: boolean = false;

    public accountSite = Constants.accountSite;
    public gettingStartedSite = Constants.gettingStartedSite;
    public adminSite = Constants.adminSite;

    public showPhotoEditor: boolean = false;
    public showPasswordEditor: boolean = false;
    public showDisplayNameEditor: boolean = false;
    public showEmailEditor: boolean = false;

    public displayEmailEditorWarning: boolean = false;
    public changeEmail: FormGroup;
    public isSubmitingNewEmail: boolean = false;

    public showGettingStartedPage: boolean = false;
    public showAdminPage: boolean = false;

    public imgIsUploading: boolean = false;
    public isRemovingImg: boolean = false;

    public passwordChange: FormGroup;
    public isFormTouched: boolean;
    public displayPassword: boolean = false;
    public isSubmitingPassword: boolean;
    public passwordSubmitted: boolean = false;
    public passwordResponse: any = {};
    public isCapsOn: boolean;
    public isPasswordVisible: boolean = false;
    public displayEditPasswordWarning: boolean = false;

    public organizationInfo: any = {
        tenantCode: null,
        tenantName: null,
        userCount: null
    }
    public initalUserInfoFound: boolean = false;

    public displayNameChange: FormGroup;
    public isChangingDisplayName: boolean = false;
    public displayEditNameWarning: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private _toastService: ToastNotificationService,
        private _fileStorageService: FileStorageService,
        private _apiCallsService: ApiCallsService,
        public _channelService: ChannelService,
        public _authService: AuthService,
        private _router: Router,
        private _fb: FormBuilder,
    ) {
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "hidePasswordEditor") {
                this.hidePasswordEditor();
            }
            if (data.type === "hideDisplayNameEditor") {
                this.hideDisplayNameEditor();
            }
            if (data.type === "hideEmailEditor") {
                this.hideEmailModal();
            }
        }));
    }

    // parentThemeColorSwitch(color) {
    //     this.themeColorSwitch.emit(color);
    // }

    parentThemeModeSwitch(theme: string) {
        this.themeModeSwitch.emit(theme);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            if (changes.userInfo.currentValue.channelUserId && !this.initalUserInfoFound) {
                this._getTenantInfo();
            }
        }
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // routeToSupportPage() {
    //     this._router.navigate(['/support']);
    // }

    routeToAccountSettings() {
        this._router.navigate(['/alert-settings']);
    }

    signOut() {
        this._authService.signOut();
    }

    mediaCheck() {
        // let mediaWidth = 1248;//Small-Desktop
        let mediaWidth = 1024;//Tablet-Wide
        let mediaQuery = window.matchMedia(`(max-width: ${mediaWidth - 1}px)`);
        return mediaQuery.matches
    }

    changeStatus(status:number) {
        if (status === 0) {
            this._channelService.sendMessageToChannel("/active", null);
        } else if (status === 1) {
            this._channelService.sendMessageToChannel("/invisible", null);
        } else if (status === 2) {
            this._channelService.sendMessageToChannel("/dnd", null);
        } else if (status === 3) {
            this._channelService.sendMessageToChannel("/brb", null);
        }
        this.showStatusList = false;
    }

    expandStatusList() {
        this.showStatusList = (this.showStatusList) ? false : true;
    }

    changeTheme(theme:string) {
        this.themeInit = theme;
        this.parentThemeModeSwitch(theme);
    }

    expandThemeList() {
        this.showThemeList = (this.showThemeList) ? false : true;
    }

    displayPhotoEditor() {
        this.showPhotoEditor = true;
    }

    displayPhotoEditorInput() {
        let hiddenFileInput = <HTMLInputElement>document.getElementById('file-upload');
        hiddenFileInput.click();
    }

    hidePhotoEditor() {
        this.showPhotoEditor = false;
        // stop image upload on hide if still loading
        if (this.imgIsUploading == true) {
            this.imgIsUploading = false;
        }
    }

    displayPasswordEditor() {
        this.createPasswordForm();
        this.showPasswordEditor = true;
    }

    hidePasswordEditor() {
        this.showPasswordEditor = false;
        this.displayPassword = false;
        this.passwordSubmitted = false;
        this.passwordResponse = {};
        this.isCapsOn = false;
        this.isPasswordVisible = false;
    }

    hidePasswordEditorConfirm() {
        if (this.displayEditPasswordWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hidePasswordEditor");
        } else {
            this.hidePasswordEditor();
        }
    }

    displayDisplayNameEditor() {
        this.createDisplayNameForm();
        this.showDisplayNameEditor = true;
    }

    hideDisplayNameEditor() {
        this.showDisplayNameEditor = false;
    }

    hideDisplayNameEditorConfirm() {
        if (this.displayEditNameWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hideDisplayNameEditor");
        } else {
            this.hideDisplayNameEditor();
        }
    }

    expandAccountList() {
        this.showAccountList = (this.showAccountList) ? false : true;
    }

    toggleOrganizationList() {
        this.showOrganizationList = (this.showOrganizationList) ? false : true;
    }

    hideGettingStartedPage() {
        this.showGettingStartedPage = false;
    }

    displayGettingStartedPage() {
        this.showGettingStartedPage = true;
    }

    hideAdminPage() {
        this.showAdminPage = false;
    }

    displayAdminPage() {
        this.showAdminPage = true;
    }

    displayEmailModal() {
        this.createEmailForm();
        this.showEmailEditor = true;
    }

    hideEmailModal() {
        this.showEmailEditor = false;
        this.changeEmail.reset();
    }

    hideEmailModalConfirm() {
        if (this.displayEmailEditorWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hideEmailEditor");
        } else {
            this.hideEmailModal();
        }
    }

    clickOffUserSidebarModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'photo-editor-modal': {
                this.hidePhotoEditor();
                break;
            }
            case 'email-editor-modal': {
                if (this.displayEmailEditorWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hideEmailEditor");
                } else {
                    this.hideEmailModal();
                }
                break;
            }
            case 'password-editor-modal': {
                if (this.displayEditPasswordWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hidePasswordEditor");
                } else {
                    this.hidePasswordEditor();
                }
                break;
            }
            case 'display-name-editor-modal': {
                if (this.displayEditNameWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "hideDisplayNameEditor");
                } else {
                    this.hideDisplayNameEditor();
                }
                break;
            }
            case 'admin-page-modal': {
                this.hideAdminPage();
                break;
            }
            default: return;
        }
    }

    _getTenantInfo() {
        this._authService.getOrganizationInfoByUserId(this.userInfo.channelUserId)
            .subscribe(res => {
                if (res.status === 200) {
                    this.organizationInfo = res.body;
                    // console.log("Org Info!!!", this.organizationInfo);
                    this.initalUserInfoFound = true;
                } else {
                    console.error("tenant info error", res);
                }
            });
    }

    profileImgChange(status: boolean) {
        if (status === true) {
            this.imgIsUploading = status;
        } else {
            this.imgIsUploading = status;
            // console.log("emmiter worked")
            // this._authService.channelUserInfoHasUpdated();
        }
    }

    _removeProfilePhoto() {
        this.isRemovingImg = true;
        this._fileStorageService.fileStorageProfileDelete(this.userInfo.channelUserId)
            .subscribe(res => {
                if (res.status === 200) {
                    this.isRemovingImg = false;
                    this.userInfo.hash = '1';
                    // console.log("remove success", res);
                    // this._authService.channelUserInfoHasUpdated();
                    this._authService.userHashBehaviorSubject.next('1');
                } else {
                    console.error("remove error", res);
                    this.isRemovingImg = false;
                    this._toastService.error('There was an error removing this photo.')
                }
            });
    }

    completeProfileImageSubmit(newHash:string) {
        // console.info('new hash', newHash)
        this.userInfo.hash = newHash;
    }

    createPasswordForm() {
        this.isFormTouched = false;
        this.displayEditPasswordWarning = false;
        let userId = this.userInfo.channelUserId;
        this.passwordChange = this._fb.group({
            userId: [userId],
            currentPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern("^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=-_.]).*$")]],
            confirmNewPassword: ['', Validators.required]
        }, {
            validator: MustMatch('newPassword', 'confirmNewPassword')
        });
        
        this.subscriptions.push(this.passwordChange.valueChanges.subscribe(data => {
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                this.displayEditPasswordWarning = true;
            }
        }));
    }

    createDisplayNameForm() {
        // console.log('is form ted', this.isFormTouched)
        this.isFormTouched = false;
        this.displayEditNameWarning = false;
        this.displayNameChange = this._fb.group({
            displayName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[a-zA-Z'.-\s]*$/)]]
        });
        this.subscriptions.push(this.displayNameChange.valueChanges.subscribe(data => {
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                this.displayEditNameWarning = true;
            }
        }));
    }

    submitPasswordChange() {
        this.isSubmitingPassword = true;
        this._authService.changePassword(this.passwordChange.value)
            .subscribe(res => {
                if (res.status === 200) {
                    if (res.body.returnValue === true) {
                        this.isSubmitingPassword = false;
                        // console.log('Password Change submitted!');
                        this.passwordResponse = {
                            success: true,
                            message: ""
                        }
                        // this.createPasswordForm();
                        this._toastService.success('Your password has been changed')
                        this.hidePasswordEditor();
                        this.passwordChange.reset();
                    } else {
                        //if old password is wrong
                        this.isSubmitingPassword = false;
                        this.passwordResponse = {
                            success: false,
                            message: "The current password you have entered is incorrect"
                        }
                        // console.log(res)
                    }
                } else if (res.status === 422) {
                    this.isSubmitingPassword = false;
                    this.passwordResponse = {
                        success: false,
                        message: "New password can not be the same as your previous 5 passwords"
                    }
                } else {
                    this.isSubmitingPassword = false;
                    this.passwordResponse = {
                        success: false,
                        message: "An error has occured while changing your password"
                    }
                    // console.log(res)
                }
                this.passwordSubmitted = true;
            });
    }

    capsLockCheck(e: any) {
        if (e.keyCode) {
            e.getModifierState('CapsLock') ?
                this.isCapsOn = true :
                this.isCapsOn = false;
        } else {
            return;
        }
    }

    showPasswordText() {
        this.isPasswordVisible = (this.isPasswordVisible) ? false : true;
    }

    get pc() { return this.passwordChange.controls; }

    submitDisplayName() {
        this.isChangingDisplayName = true;
        let nameObject: any = {
            "op": "replace",
            "path": "/displayName",
            "value": this.displayNameChange.value.displayName
        };
        this._apiCallsService.patchChannelUser(this._authService._user.profile.sub, nameObject)
            .subscribe(res => {
                if (res.status === 204) {
                    this.showDisplayNameEditor = false;
                    this._toastService.success(`Your Display Name has been changed to ${nameObject.value}`);
                    this.userInfo.displayName = nameObject.value;
                    this._authService.displayNameBehaviorSubject.next(nameObject.value);
                    this.isChangingDisplayName = false;
                } else {
                    this._toastService.error("There was an error changing display name.");
                    this.isChangingDisplayName = false;
                }
            });
    }

    get dnc() { return this.displayNameChange.controls; }

    closeSideBar() {
        this._channelService.sidebarChangeEvent(3);//For User Profile
    }

    copyCode() {
        navigator.clipboard.writeText(this.organizationInfo.tenantCode);
    }

    createEmailForm() {
        this.isFormTouched = false;
        this.displayEmailEditorWarning = false;
        this.changeEmail = this._fb.group({
            newEmail: ["", [Validators.required, Validators.pattern(standardEmailRegex)]]
        });
        this.subscriptions.push(this.changeEmail.valueChanges.subscribe(data => {
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                this.displayEmailEditorWarning = true;
            }
        }));
    }

    get emailc() { return this.changeEmail.controls; }

    changeUserEmail() {
        this.isSubmitingNewEmail = true;
        let emailObject = {
            "value": this.changeEmail.value.newEmail,
            "path": "/email",
            "op": "replace",
        };
        this._authService.patchUser(emailObject)
            .pipe(map(res => {
                // console.info('patch email res', res);
                this._toastService.success(`Your email has been changes to ${emailObject.value}`);
                this.hideEmailModal();
                this.userInfo.email = emailObject.value;
                return res;
            }))
            .subscribe(
                ()=> {},
                err => {
                    console.error('email error from sub', err)
                    // add inline error ... 

                    this.isSubmitingNewEmail = false;
                }, () => {
                    // console.info("finally sub done")
                    this.isSubmitingNewEmail = false;
                }
            );
    }
}
