import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from '../constants';
import { AuthService } from '../core/auth.service';
import { ApiCallsService } from '../services/api-calls.service';
import { ChannelService } from '../services/channel-service';
import { GuestService } from '../services/guest.service';
import { MeetingService } from '../services/meeting.service';
import { ToastNotificationService } from '../services/toast-notification.service';
import { ChannelUser } from '../shared/interface/channel-user';
import { consoleLogStyle } from '../shared/varaibles';


@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    public userInfo: ChannelUser = this._authService._channelUserInfo; 
    
    public _isSignedIn: boolean = false;

    public isGuest: boolean = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'guest');
    public isViewer: boolean = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'viewer'); // NDIA
    public returnUrl: string = "";
    public subscriptions: Subscription[] = [];
    public meetingId: string = "";
    public token: string = "";
    channelId: any;
    
    constructor(
        public _toastService: ToastNotificationService,
        private _authService: AuthService,
        private _guestService: GuestService,
        private _router: Router,
        private _channelService: ChannelService,
        private _meetingService: MeetingService
    ) {
        this.handleScreenCheck();

        this.subscriptions.push(
            this._authService.userChange$.subscribe(user => {
                this.isGuest = this._authService._userHighestRole === "Guest";
                this.isViewer = this._authService._userHighestRole === "Viewer"; // NDIA - Streaming
                if (this.isGuest && localStorage.getItem("guest_mt") == this.token) this._guestService.routeToChannelInvited();
            })
        );

        this.subscriptions.push(
            this._authService.channelUserInfoChanged$.subscribe(() => {
                if (this._authService._user) {
                    // console.log("%c Channel User Constructor 🔹", consoleLogStyle, this._authService._channelUserInfo)
                    // if (this._authService._channelUserInfo) {
                    // }
                    this.userInfo = this._authService._channelUserInfo;
                }
            }));
    }

    ngOnInit() {
        console.log(window.location.href);
        if(window.location.href.indexOf('?LoginCallback')  > -1){
            this.getMeetingDetails();
            if(this.isGuest && localStorage.getItem("guest_mt") === this.token){
                this.getMeeting();
                console.log("Channel: " + this.channelId);
                localStorage.setItem("channel",this.channelId);
                this._router.navigate(['/channel/' + this.channelId]);
            }else{
                this.returnUrl = `${Constants.stsAuthority}Account/LoginCallback?meetingId=${this.meetingId}&token=${this.token}`
                localStorage.setItem("guest_mt", this.token); 
                window.location.href = this.returnUrl;
            }

        }else if (this.isGuest) this._guestService.routeToChannelInvited();

        
        
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    redirect() {
        this._router.navigate(['/events']);
    }

    handleScreenCheck() {
        // console.info("screen check from home _isDesktop?:", this._channelService._isDesktop)
        if (!this._channelService._isDesktop) {
            this.redirect();
        }
    }
    getMeetingDetails() {
        let url = decodeURIComponent(window.location.href.split('?LoginCallback')[1]);
        this.meetingId = url.split('/')[1];
        this.token = url.split('/')[2].slice(0,-1);
        }

    getMeeting(){
        this._meetingService.getMeetingById(this.meetingId)
        .subscribe(data => {
            if (data.status === 200) {
                this.channelId = data.body.channeId;
            }
        });
    }
}

















    // 💡 utils ref ... 
    // stopProp(e: Event) {
    //     e.stopPropagation();
    // }
    // prevDef(e: Event) {
    //     e.preventDefault();
    // }
    // prevDefStopProp(e: Event) {
    //     e.preventDefault();
    //     e.stopPropagation();
    // }








































    // ❌
    // no app section in new design ... 

    // apps not in new design ... app service removed ... 
    // public apps: any = [];
    // public appsPag: any = {};
    // 
    // _getAppsForChannelUser() {
    //     this._appsService.getAppsForChannelUser(this._authService._user.profile.sub)
    //         .subscribe(data => {
    //             this.apps = data.body;
    //             this.appsPag = JSON.parse(data.headers.get('X-Pagination'));
    //         });
    // }
    // _getMoreApps(link:any) {
    //     this._apiCallsService.getGenericForPagination(link)
    //         .subscribe(data => {
    //             this.apps = data.body;
    //             this.appsPag = JSON.parse(data.headers.get('X-Pagination'));
    //         });
    // }
// ❌ 