import {
    Component,
    Inject,
    Input,
    OnInit,
} from "@angular/core";
import { MeetingEvent } from "../../interface/meeting-event";
import { consoleLogStyle } from '../../varaibles';


@Component({
    selector: "meeting-info",
    templateUrl: "./meeting-info.component.html",
    styleUrls: ["./meeting-info.component.scss"],
})
export class MeetingInfoComponent implements OnInit {
    
    @Input('meetingInfo') meetingInfo: MeetingEvent;
    
    public todaysCurrentDateTime: any = new Date();
    public setCurrentDateTimeInterval: any;
    
    constructor() {}

    ngOnInit(): void {
        console.log("%c meeting info ", consoleLogStyle, this.meetingInfo);
        // interval is cleared onDestroy 
        this.setCurrentDateTimeInterval = setInterval(()=> {
            this.todaysCurrentDateTime = new Date().toISOString(); //  so we can use math on date for comparison: todaysDate > meeting.start
            // console.log("%c Current time ", consoleLogStyle, this.todaysCurrentDateTime);
        }, 1000); // keep current time for to show the "meeting in progress badge"
    }

    handleBrokenImage(isCreator:boolean,userIdx?:number) {
        // console.info('yeeeeeeeeeeeeeee')
        if(isCreator) {
            this.meetingInfo.channelKeyNavigation.chatUserKeyNavigation.hash = '1';
        } else {
            this.meetingInfo.meetingAttendees[userIdx].chatUserKeyNavigation.hash = '1';
        }
    }
}
