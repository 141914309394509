<div class="contacts flex-col px-4">
	<strong>
		Sort by: &nbsp;{{sortOptions[0] ?? ''}}
	</strong>
	<!--  -->
	<div class="title-wrap flex">
		<h3 class="title-thin">My Contacts</h3>
		<!-- 🚧 populate contacts -->
		<button
			class="flex-center-all circle ms-auto"
			(click)="populateContacts()"
			matTooltip="sync contacts from your organization"
			matTooltipClass="tooltip-default"
		>
			<i class="fa-solid fa-rotate"></i>
		</button>
		<button
			class="flex-center-all circle ms-2"
			(click)="setSortBy()"
			matTooltip="sort contacts"
			matTooltipClass="tooltip-default"
		>
			<i class="fas fa-sort"></i>
		</button>
	</div>
	<!-- Adding new Contact/Tenant Associate -->
	<button
		type="button"
		(click)="showOptionModal()"
		*ngIf="!isViewer"
		class="primary-button my-3 option-button"
	>
		<i class="fas fa-plus me-3"></i>Add Associate
	</button>
	<ng-container *ngIf="displayOptionModal">
		<!-- schedule meeting -->
		<div class="mini-modal flex-col-center-all p-3">
			<button
				type="button"
				(click)="showCreateContactModal()"
				class="primary-button"
			>
				Add Personal Contact
			</button>
			<!-- start quick meeting -->
			<button
				*ngIf="!isLoadingSubscription && subscriptionPlan.canAddUsers; else cannotAddUsers"
				type="button"
				(click)="showEmailInviteModal()"
				class="secondary-button mt-2"
			>
				Invite to Join VIA3
			</button>
			<ng-template #cannotAddUsers>
				<!--  -->
				<div class="flex user-limit mt-4">
					<i class="fa-duotone fa-triangle-exclamation me-1"></i>
					<p>User limit reached</p>
				</div>
			</ng-template>
		</div>
	</ng-container>
	<!-- contacts -->
	<div
		[@listAnimationWrap]="contactsFiltered.length"
		class="contacts-wrap flex-col mt-2 scrollbar-new"
		id="contactsScrollbox"
	>
		<ng-container *ngFor="let contact of contactsFiltered; index as i">
			<!-- contact card -->
			<!-- PAGINATION REMOVED -->
			<!-- id="{{(last && contactsPag.nextPageLink) ? 'LastContact' : ''}}" -->
			<!-- (@listAnimationItem.done)="(last && contactsPag.nextPageLink) ? paginationObserver() : ''" -->
			<div
				@listAnimationItem
				class="contact-container"
				(click)="showContactInfoModal(i)"
			>
				<!-- options dropdown ... -->
				<button
					type="button"
					(wheel)="prevDefStopProp($event)"
					(click)="showContactOptionsDropdown(i, $event)"
					(mouseleave)="hideContactOptionsDropdown(i)"
					class="contact-options-btn my-1"
				>
					<i
						matTooltip="contact options"
						matTooltipClass="tooltip-default"
						class="fas fa-ellipsis-v"
					>
					</i>

					<!-- 🚧 -->
					<div
						id="contact-options-dropdown-{{ i }}"
						class="contact-options-dropdown mt-1 py-2"
					>
						<button
							*ngIf="!isViewer && contact.isCustom"
							class="list-item"
							(click)="showCreateContactModal(true, i)"
						>
							<i class="fas fa-user-edit edit-icon"></i>Edit contact
						</button>
						<button
							*ngIf="!isViewer"
							class="list-item"
							(click)="removeContactPrompt(contact.contactId, contact.displayName, i)"
						>
							<ng-container>
								<i class="fas fa-user-times edit-icon"></i>Remove contact
							</ng-container>
						</button>
						<button class="list-item" (click)="showContactInfoModal(i)">
							<i class="far fa-info-circle info-icon"></i>Contact info
						</button>
					</div>
				</button>
				<!-- each contact -->
				<div class="flex-row-nowrap contact-user py-1 pe-1">
					<!-- 🚧 contact status -->
					<span
						class="circle me-2 status-bar {{ contact.userStatus }}"
						matTooltip="{{ contact.userStatus | userStatusName }}"
						matTooltipClass="tooltip-default"
					></span>
					<!-- contact image -->
					<div *ngIf="contact.isCustom" class="user-img-wrap">
					<!--<div class="user-img-wrap">-->
						<img
							*ngIf="!!contact.hash && contact.hash !== '1'; else defaultImg"
							src="https://profile-images.azureedge.us/{{ contact.hash }}"
							(error)="handleBrokenImage(i)"
							class="flex circle me-2"
							alt="contact"
						/>
						<ng-template #defaultImg>
							<div
								class="default-user-img circle flex-center-all me-2"
								[class.every-first]="i % 4 === 0"
								[class.every-second]="i % 4 === 1"
								[class.every-third]="i % 4 === 2"
								[class.every-fourth]="i % 4 === 3"
							>
								{{ contact.firstName | defaultImageOverlay: contact.lastName }}
							</div>
						</ng-template>
					</div>
					<!-- TODO: find why image hash isn't working-->
					<div *ngIf="!contact.isCustom" class="user-img-wrap">
						<!-- <img
							*ngIf="contact.contactChatUserKeyNavigation?.hash &&
								!!contact.contactChatUserKeyNavigation.hash &&
									contact.contactChatUserKeyNavigation.hash !== '1';
								else defaultImg
							"
							src="https://profile-images.azureedge.us/{{
								contact.hash
							}}"
							(error)="handleBrokenImage(i)"
							class="flex circle me-2"
							alt="contact"
						/> -->
						<img
							*ngIf="contact.hash &&
								!!contact.hash &&
									contact.hash !== '1';
								else defaultImg
							"
							src="https://profile-images.azureedge.us/{{
								contact.hash
							}}"
							(error)="handleBrokenImage(i)"
							class="flex circle me-2"
							alt="contact"
						/>
						<ng-template #defaultImg>
							<div
								class="default-user-img circle flex-center-all me-2"
								[class.every-first]="i % 4 === 0"
								[class.every-second]="i % 4 === 1"
								[class.every-third]="i % 4 === 2"
								[class.every-fourth]="i % 4 === 3"
							>
								{{ contact.firstName | defaultImageOverlay: contact.lastName }}
							</div>
						</ng-template>
					</div> 
					<!-- contact name -->
					<div class="flex-col me-auto contact-names-wrap">
						<p class="text-ellipsis contact-name">
							{{ contact.displayName ?? "error in name..." }}
						</p>
						<!-- TODO find out why contact.contactChatUserKeyNavigation.tenant is null ////CG-->

						<!-- <p class="text-ellipsis contact-type">
							{{
								contact.isCustom
									? "personal contact"
									: contact.contactChatUserKeyNavigation.tenant + "member"
							}}
						</p> -->

						<p class="text-ellipsis contact-type">
							{{
								contact.isCustom
									? "personal contact"
									: contact.company.toUpperCase() + " member"
							}}
						</p>
					
					</div>

					<!-- 🚧 contact options -->
					<div class="contact-options flex">
						<!-- open/push DM  (click)="pushChat(contact.channelId)" -->
						<ng-container *ngIf="!contact.isCustom && !isViewer">
							<button
								matRipple
								[matRippleColor]="rippleColor"
								[matRippleUnbounded]="true"
								[matRippleCentered]="true"
								(click)="getDmChannel($event, contact)"
								class="contact-btn message-btn circle"
							>
								<i
									class="far fa-comment-alt"
									matTooltip="send message"
									matTooltipClass="tooltip-default"
								></i>
							</button>
						</ng-container>
						<ng-container *ngIf="contact.isCustom || isViewer">
							<button
								matRipple
								[matRippleColor]="rippleColor"
								[matRippleUnbounded]="true"
								[matRippleCentered]="true"
								(click)="stopProp($event)"
								class="contact-btn email-btn circle"
							>
								<a href="mailto:{{ contact.email }}">
									<i
										class="far fa-envelope"
										matTooltip="send email"
										matTooltipClass="tooltip-default"
									></i>
								</a>
							</button>
						</ng-container>
						<!-- favorite star -->
						<button
							*ngIf="!isViewer"
							matRipple
							[matRippleColor]="rippleColor"
							[matRippleUnbounded]="true"
							[matRippleCentered]="true"
							class="contact-btn star-btn circle ms-2"
							(click)="
								contact.isFavorite
									? unFavoriteContact(contact.contactId, i, $event)
									: favoriteContact(contact.contactId, i, $event)
							"
						>
							<i
								[class.active]="contact.isFavorite"
								class="fa{{ contact.isFavorite ? 's' : 'r' }} fa-star"
								matTooltip="make favorite"
								matTooltipClass="tooltip-default"
							></i>
						</button>
					</div>
				</div>
			</div>
		</ng-container>

		<!-- ❌ no pagination loading ... -->
		<!-- <div class="loading-more-contacts flex justify-center mt-4" *ngIf="isLoadingContacts">
            <i class="fas fa-spinner spinner m-auto"></i>
        </div> -->

		<div
			*ngIf="hasNoContacts"
			class="no-contacts flex-row-nowrap-center-all mt-4"
		>
			<p class="text-center secondary-text">You do not have any contacts.</p>
		</div>
	</div>
	<!--  -->
	<div class="loading-contacts flex-center-all" *ngIf="isLoadingContacts">
		<loading-home></loading-home>
	</div>
</div>

<!-- send email to new user -->
<div
	*ngIf="displayEmailInviteModal"
	class="modal"
	id="email-invite-modal"
	(click)="clickOffModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeEmailInviteModalConfirm()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeEmailInviteModalConfirm()"
		>
			<i class="fas fa-times"></i>
		</button>
		<account-email-invite
			[userInfo]="userInfo"
			[subscriptionPlan]="subscriptionPlan"
			(onCompleteEvent)="closeEmailInviteModal()"
			(formTouchedEvent)="displayEmailInviteWarning = $event"
		>
		</account-email-invite>
	</div>
</div>

<!-- create contact modal -->
<div
	*ngIf="displayCreateContactModal"
	class="modal"
	id="create-contact-modal"
	(click)="clickOffModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeCreateContactModalConfirm()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeCreateContactModalConfirm()"
		>
			<i class="fas fa-times"></i>
		</button>
		<!-- [userInfo]="userInfo" < not used yet ... -->
		<create-contact
			[userInfo]="userInfo"
			[isEditContact]="isEditContact"
			[contactToEdit]="selectedContact"
			(onCompleteEvent)="closeCreateContactModal($event)"
			(formTouchedEvent)="displayCreateContactWarning = true"
		>
		</create-contact>
	</div>
</div>

<!-- contact info modal -->
<div
	*ngIf="displayContactInfoModal"
	class="modal"
	id="contact-info-modal"
	(click)="clickOffModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeContactInfoModalConfirm()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeContactInfoModalConfirm()"
		>
			<i class="fas fa-times"></i>
		</button>
		<contact-info
			[contactInfo]="selectedContact"
			[userInfo]="userInfo"
			(closeInfo)="closeContactInfoModal()"
			(editContactModal)="editContactFromInfo()"
			(formTouchedEvent)="addNoteTouched($event)"
		>
		</contact-info>
	</div>
</div>

<!-- option button modal backdrop 
    [this is only modal with a seperate backdrop element, was made before global modal implementation] 
-->
<ng-container *ngIf="displayOptionModal">
	<div
		(click)="closeOptionModal()"
		(document:keydown.escape)="closeOptionModal()"
		class="hidden-backdrop-modal"
	></div>
</ng-container>
