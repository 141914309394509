<!--  -->
<div class="content-module-full" *ngIf="showPage">
    <div class="header">
        <div class="initialHeader">
            <div class="headerInfo">
                <h2 class="small-header" *ngIf="!isMedical">{{_tenant | uppercase}} Video Intake</h2>
                <h2 class="small-header" *ngIf="isMedical">{{_tenant | uppercase}} Video App</h2>
                <!-- <div class="header-img" *ngIf="isMedical">
                    <img src="https://web-images.azureedge.us/annapolis/annapolis-brand-anne-arundel.png" alt="">
                </div> -->
            </div>
        </div>
    </div>
    <div class="intake-home-content">
        <div class="intake-name">
            <!-- <div *ngIf="isMedical" class="doctor-logo">
                <img src="https://web-images.azureedge.us/annapolis/annapolis-brand-despa.png" alt="">
            </div> -->
            <div *ngIf="_customizations.companyImageHome" class="doctor-logo">
                <img src="{{_customizations.companyImageHome.value}}" alt="Company Logo">
            </div>
        </div>
        
        <div class="connection-check">
            <button 
                class="connection-check-reconnect secondary-button"
                *ngIf="connectionStatus === 'disconnected'"
                (click)="reconnect()">
                Attempt Reconnect
            </button>
            <div class="connection-check-status">
                Connection: <span class="{{connectionStatus}}">{{connectionStatus}}</span>
            </div>
        </div>
    </div>
    
    <div class="content-module">
        <div class="chat" chat-type="a-officer" *ngIf="!_isIntake">
            <vdjj-video-intake-chat-ao
                (connectionStatus)="setConnectionStatus($event)">
            </vdjj-video-intake-chat-ao>
        </div>
        <div class="chat" chat-type="i-officer" *ngIf="_isIntake">
            <vdjj-video-intake-chat-io
                (connectionStatus)="setConnectionStatus($event)">
            </vdjj-video-intake-chat-io>
        </div>
    </div>
</div>

