import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testListLength'
})
export class TestListLengthPipe implements PipeTransform {

  transform(value: any[], multiplier: number): any[] {
    for(let i = 0; i < multiplier; i++) {
      value = value.concat(value)
    }
    console.log("test array multiplyed", value)
    return value;
  }

}
