import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from '../app/constants';
import { AuthService } from './core/auth.service';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { ChannelService } from './services/channel-service';
import { ApiCallsService } from './services/api-calls.service';
import { filter } from 'rxjs/operators';
import { SocketsService } from './services/sockets.service';
import { ToastNotificationService } from './services/toast-notification.service';
import { Subscription } from 'rxjs';
import { ChannelUser } from './shared/interface/channel-user';
import { consoleLogStyle } from '../app/shared/varaibles';
import { SystemNotificationService } from './services/system-notification.service';
import { Platform } from '@angular/cdk/platform';
import { userInfo } from 'os';
import { constants } from 'buffer';

// GOOGLE ANALITICS TAG ... 
declare var gtag; // 

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

    // public pinnedSideBar: boolean = false;
    public showSidebar: boolean = false;
    public changeTheme: any = {
        "op": "replace",
        "path": "/themeName",
        "value": ""
    };
    public darkMode: boolean;
    public themeList = [
        "theme-dark",
        "theme-light",
        "theme-reader"
    ]

    public theme = 'theme-light';

    public _isSignedIn = false;
    public socketServers: any = [];
    public userStatus: string = "none";
    public channelUserInfo: ChannelUser = { // should not need initial value if !! is checked in template ... 
        "channelUserId": null,
        "displayName": "",
        "username": "",
        "hash": "1"
    };
    public channelId: string;
    public sidebarContainerAppType: string = "";

    public subscriptions: Subscription[] = [];
    public systemNotificationsSubscriptions: Subscription[] = [];

    public isGuest: boolean = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'guest');
    public isViewer: boolean = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'viewer'); // NDIA

    public backupSignInCheck = false;
    public inChannel: boolean;

    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _apiService: ApiCallsService,
        public _channelService: ChannelService,
        private _socketService: SocketsService,
        private _toastService: ToastNotificationService,
        private _systemNotifications: SystemNotificationService,
        // private _userAgent: UserAgentService, // use Angular Platform CDK instead ... 
        private _platform: Platform,
    ) {
        this.handleScreenCheck();
        const navEndEvents = _router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        );
        navEndEvents.subscribe((event: NavigationEnd) => {
            if (Constants.isProd) {
                gtag('config', 'UA-7402680-8', {
                    'page_path': event.urlAfterRedirects
                });
            }
        });

        //For unlocking audio on load
        const audioCtx = new AudioContext();
        this.unlockAudioContext(audioCtx);



        this.subscriptions.push(
            this._channelService.onJoinedPageEvent$.subscribe((channelId: any) => {
                // console.log("JoinedPageEvent app component: " + channelId);
                this.channelId = channelId;
            }));


        this.subscriptions.push(
            this._authService.signInChanged$.subscribe(signedIn => {
                // console.log("constructor sign in change: " + signedIn);
                if (this._isSignedIn !== signedIn) {
                    this._isSignedIn = signedIn;
                    //Used if signIn is dealayed
                    if (this._isSignedIn && this.backupSignInCheck) {
                        this.backupSignInCheck = false;
                        this._channelService.Initialize().then(() => {
                            this._channelService.join("");
                        });
                        this._getChannelUserInfo(this._authService._userInfo.sub);

                    }
                }
            }));

        this.subscriptions.push(
            this._authService.userChange$.subscribe(user => {
                this.isGuest = this._authService._userHighestRole === "Guest";
                this.isViewer = this._authService._userHighestRole === "Viewer"; // NDIA - Streaming
                // console.log("User Change!!!!!!!");
                if (this._isSignedIn) {
                    this.backupSignInCheck = false;
                    this._channelService.Initialize().then(() => {
                        this._channelService.join("");
                    });
                    this._getChannelUserInfo(this._authService._userInfo.sub);
                } else {
                    this.backupSignInCheck = true;
                }
            })
        );

        if (window.location.href.indexOf('?postLogout=true') > -1) {
            this._authService.signoutRedirectCallback().then(() => {
                const url: string = this._router.url.substring(
                    0,
                    this._router.url.indexOf('?')
                );
                // console.log("Navigating!!!!!!!1")
                this._router.navigateByUrl(url);
            });
        }

        

        this.subscriptions.push(
            this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
                channelUserStatusList.forEach(channelUser => {
                    if (this.channelUserInfo.username) {
                        if (this.channelUserInfo.username.toLowerCase() === channelUser.username.toLowerCase()) {
                            // console.log("%c sig r event triggered: userstatus ", this.consoleLogStyle)
                            if (channelUser.selectedStatus === 0) {
                                if (channelUser.presenceStatus === 0) {
                                    this.userStatus = "offline";
                                } else if (channelUser.presenceStatus === 1) {
                                    this.userStatus = "away";
                                } else if (channelUser.presenceStatus === 2) {
                                    this.userStatus = "active";
                                } else if (channelUser.presenceStatus === 3) {
                                    //Temp for incall
                                    this.userStatus = "active";
                                }
                            } else if (channelUser.selectedStatus === 1) {
                                this.userStatus = "hidden";
                            } else if (channelUser.selectedStatus === 2) {
                                this.userStatus = "dnd";
                            } else if (channelUser.selectedStatus === 3) {
                                this.userStatus = "brb";
                            }
                            this._authService._userStatus = this.userStatus;
                        }
                    } else {
                        console.error("Could not find username", this.channelUserInfo);
                    }
                });
            }));

        this.subscriptions.push(
            this._channelService.onSidebarAppSelectEvent$.subscribe(value => {
                this.sidebarContainerAppType = value;
            }));

        this.subscriptions.push(
            this._channelService.onSidebarDisplayEvent$.subscribe(show => {
                this.sidebarDisplay(show);
            }));

        this.subscriptions.push(
            this._channelService.onNoClientErrorEvent$.subscribe(() => {
                this._toastService.error("Your session has timed out. Please go to the home page to refresh.");
            }));

        // this.subscriptions.push(
        //     this._authService.updateChannelUserInfo$.subscribe(() => {
        //         console.log("user info changed");
        //         this._getChannelUserInfo(this._authService._user.profile.sub);
        //     }));


        this.subscriptions.push(
            this._router.events
                .subscribe((event: RouterEvent) => {
                    // see also 
                    if (event instanceof NavigationEnd) {
                        this.inChannel = event.url.toLowerCase().startsWith("/channel/") ? true : false;
                        // console.log(event);
                    }
                })
        );

        if (!this._platform.IOS && !this._platform.ANDROID && !this._platform.SAFARI) {
            this.subscriptions.push(this._systemNotifications.onSettingsTosub$
                .subscribe((settingsToSub) => {
                    this.systemNotificationsSubscriptions.forEach(subscription => subscription.unsubscribe());
                    // 💡 create new array of subs for settings ... 
                    // console.info('%c settings to sub to in app constructor update? ', consoleLogStyle, settingsToSub)
                    settingsToSub.forEach(setting => {
                        switch (setting) {
                            // sub to each event type here ... 
                            case "Added to a channel as member": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "Added to a channel as owner": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "Added to a meeting": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            // 🚧 for local - When received 
                            case "Received unread direct messages": {
                                this.systemNotificationsSubscriptions.push(this._channelService.onUnreadDirectMessageNotification$
                                    .subscribe((notiObj) => {
                                        // console.log("%c UNREAD MESSAGE EVENT, notiObj to set noti ", consoleLogStyle, notiObj)
                                        if (notiObj.message) { // if not this event also fires when you open the unread dm ... 
                                            this._systemNotifications
                                                .showNewNotification(
                                                    notiObj.numUnreadMessages < 2 ?
                                                        `${notiObj.message.channelUserKeyNavigation.displayName} has sent a new message` :
                                                        `${notiObj.message.channelUserKeyNavigation.displayName} has sent ${notiObj.numUnreadMessages} new messages`, // message
                                                    'unread message', // type is for routing (not used yet, only if req to nav to full dm) ... 
                                                    notiObj.message.channelUserKeyNavigation.hash === '1' ?
                                                        null : `https://profile-images.azureedge.us/${notiObj.message.channelUserKeyNavigation.hash}` // ...
                                                );
                                        }
                                    }));
                                break;
                            }
                            case "Meeting is cancelled": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "Meeting is rescheduled": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "New user has registered ": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "Fax received": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "File uploaded to channel": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            case "Mentioned (with @) in chat": {
                                // this._systemNotifications.showNewNotification('You will now be notified')
                                break;
                            }
                            default: {
                                return;
                            }
                        }
                    });
                }));
        }

        this.subscriptions.push(this._channelService.onUnreadDirectMessageNotification$
            .subscribe(notiObj => {
                // console.log("navigation overlay unread message badge ", notiObj);
                if (notiObj.message) { // if not this event also fires when you open the unread dm ... 
                    this._toastService.info(notiObj.numUnreadMessages < 2 ?
                        `${notiObj.message.channelUserKeyNavigation.displayName} has sent a new message` :
                        `${notiObj.message.channelUserKeyNavigation.displayName} has sent ${notiObj.numUnreadMessages} new messages`);
                }
            })
        );
    }


    ngOnInit() {

        // console.log("%c eniornment prod? ", consoleLogStyle, environment.production)
        this._authService.isSignedIn(true).then(signedIn => {
            this._isSignedIn = signedIn;
            this._authService.signInSilent();
            // console.log("constructor in Init, IsSignedIn: " + this._isSignedIn);
            if (!this._isSignedIn) {
                

                if (window.location.href.indexOf('meetingInvite') < 0 && window.location.href.indexOf('signin-callback') < 0 && window.location.href.indexOf('guest=') < 0 && window.location.href.indexOf('login') < 0) {
                    this._authService.signIn();
                }

                // if (this._authService._userInfo && window.location.href.indexOf('ReturnUrl') < 0 && window.location.href.indexOf('signin-callback') < 0 && window.location.href.indexOf('guest=') < 0 && window.location.href.indexOf('login') < 0) {
                //     //might come back ass unathorized
                //     this._getChannelUserInfo(this._authService._userInfo.sub);
                // }
                // else if (window.location.href.indexOf('login') > 0) {
                //     //If Initial SignOut in false but userfInfo is found
                //     console.log("Signed out, on login")
                // } else if (this._authService._userInfo && ()) {
                //     //If Initial SignOut in false but userfInfo is found
                //     console.log("Signed Out, but has userInfo")
                // } else if (true) {
                //     console.log("in time out section of app comp")
                // }
            } 
            // else {
            //     if (this._authService._userInfo) {
            //         // this._getChannelUserInfo(this._authService._userInfo.sub);
            //         //asd
            //     }
            // }
        });
        // console.log("isMobile Device: ", this._channelService._isMobileDevice); // 
        // console.info("%c Platform from mat-cdk? : ", consoleLogStyle, this._platform);
        this.themeInit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.systemNotificationsSubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                // console.log("Getactivesocketservers vdjjhomepage" + JSON.stringify(data.body));
                this.socketServers = data.body;
            })
            .catch(err => console.log(err));
    }

    // called on init 
    _getMembershipUserInfo2() {
        this._authService.getMembershipUserInfo()
            .subscribe(data => {
                if (data.status === 200) {
                    // this.userInfo = data;
                    // console.log("%c 💙 got membership info AUTH ... ", consoleLogStyle, data);
                    this._getChannelUserInfo(data.body.sub);
                } else {
                    console.error("error getting user info", data);
                }
            });
    }

    _getChannelUserInfo(id?: string) { // using id from within authService ... 
        this._authService.getChannelUserInfo()
            .subscribe(data => {
                if (data.status === 200) {
                    this.channelUserInfo = data.body;
                    this._authService._channelUserInfo = this.channelUserInfo;
                    // console.log("%c 👤 ChannelUser info from APP component ", consoleLogStyle, this.channelUserInfo)
                    let user = this.channelUserInfo;
                    if (user.selectedStatus === 0) {
                        if (user.presenceStatus === 0) {
                            this.userStatus = "offline";
                        } else if (user.presenceStatus === 1) {
                            this.userStatus = "away";
                        } else if (user.presenceStatus === 2) {
                            this.userStatus = "active";
                        } else if (user.presenceStatus === 3) {
                            //Temp for incall
                            this.userStatus = "active";
                        }
                    } else if (user.selectedStatus === 1) {
                        this.userStatus = "hidden";
                    } else if (user.selectedStatus === 2) {
                        this.userStatus = "dnd";
                    } else if (user.selectedStatus === 3) {
                        this.userStatus = "brb";
                    }
                    this._authService._userStatus = this.userStatus;
                    this._authService.channelUserInfoHasChanged();
                    // 🚧🚧🚧 sub to Alert events here to trigger system notfications 
                    // console.info("%c Platform?  ", consoleLogStyle, this._platform);
                    if (!this._platform.IOS && !this._platform.ANDROID && !this._platform.SAFARI) {
                        // console.info("%c not ios|android|safari, can use NotificationAPI  ", consoleLogStyle, this._platform);
                        if (this._systemNotifications.hasSystemNotifications) {
                            if (this._systemNotifications.isPermissionGranted) {
                                // need to check settings for allowed noti's ... 
                                this._systemNotifications.getSystemAlertsSettings(this.channelUserInfo.channelUserId);
                            }
                        }
                    }
                } else {
                    console.error("%c [APP] error in getting user info?", consoleLogStyle, data);
                }
            });
    }

    // Theme functions 
    themeInit() {
        let localTheme = localStorage.getItem("theme");
        if (localTheme !== null && this.themeList.includes(localTheme)) {
            this.theme = localTheme;
        }
        // 😢 user info not fast enough for this to work ... 
        else if (this.channelUserInfo.themeName) {
            this.theme = this.channelUserInfo.themeName;
        }
        else {
            // console.log("local theme not found")
            this.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "theme-dark" : "theme-light";
        }
        document.getElementsByTagName("BODY")[0].setAttribute("data-theme", this.theme);
    }

    themeModeSet(theme: string) {
        if (this.themeList.includes(theme)) {
            this.theme = theme;
            document.getElementsByTagName("BODY")[0].setAttribute("data-theme", this.theme);
            this.updateTheme();
        }
    }

    updateTheme() {
        this.changeTheme.value = this.theme;
        // console.log("setting theme to " + this.theme + "!");
        localStorage.setItem("theme", this.theme);
        this._apiService.patchChannelUser(this.channelUserInfo.channelUserId, this.changeTheme)
            .subscribe(res => {
                if (res.status === 204) {
                    document.getElementsByTagName("BODY")[0].setAttribute("data-theme", this.theme);
                } else {
                    console.error("error updating theme", res);
                }
            });
    }

    // setPinned() {
    //     let localPinned = JSON.parse(localStorage.getItem("side-pinned"));
    //     if (localPinned) {
    //         this.pinnedSideBar = true;
    //     }
    // }

    sidebarDisplay(show: boolean) {
        // console.log("sideDisplay!!!!!", show)
        this.showSidebar = show;
        if (!show) {
            this._channelService.closeDirectMessage();
        }
    }

    // sperate to avoid infinite loop
    closeSideBar() {
        // console.log("app component close sidebar")
        this._channelService.sidebarChangeEvent(0);
    }

    handleScreenCheck() {
        this._channelService.screenChangeEvent();
    }

    unlockAudioContext(context: AudioContext) {
        // console.log("Context", context);
        if (context.state !== 'suspended') {
            return;
        };
        const b = document.body;
        const events = ['touchend', 'mousedown', 'keydown'];
        events.forEach(e => b.addEventListener(e, unlock, false));
        function unlock() { context.resume().then(clean); }
        function clean() { events.forEach(e => b.removeEventListener(e, unlock)); }
    }
}
