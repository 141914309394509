import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { consoleLogStyle } from '../../varaibles';

@Component({
    selector: 'time-difference',
    templateUrl: './time-difference.component.html',
    styleUrls: ['./time-difference.component.scss']
})
export class TimeDifferenceComponent implements OnInit, OnDestroy {

    @Input() orderTime: any;

    public ticks: number = 0;
    public midNight: number = 0;
    public tickInterval: any;

    constructor() { }

    figureOutTimeSincePost(when) {
        // Convert the "when" into a usable date object, and remember it is a UTC date
        var t = when.replace('T', ' ').replace('+00:00', '').split(/[- :]/);
        this.getTicks(t);
        this.tickInterval = setInterval(() => {
            this.getTicks(t);
            // this.getMidnight();
        }, 60000);
        //return Number(timeDiff);
    }

    getTicks(t) {
        var whenAsUsableDate = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        const date: number = moment(whenAsUsableDate).valueOf();
        // When getting the date, we need to get the UTC date, because we store UTC date in the db
        var timeDiff = Math.floor((new Date().getTime() - date) / 1);
        this.ticks = timeDiff;
    }

    getMidnight() {
        var midnight = new Date();
        var now = new Date();
        this.midNight = ((now.getTime() * 10000) + 621355968000000000)-((midnight.getTime() * 10000) + 621355968000000000);
    }

    min() {
        return Math.floor(this.ticks / 60000);
    }

    hour() {
        return Math.floor(this.ticks / 3600000);
    }

    day() {
        return Math.floor(this.ticks / 86400000);
    }

    week() {
        return Math.floor(this.ticks / 604800000);
    }

    month() {
        return Math.floor(this.ticks / 2.6280E+9);
    }

    year() {
        return Math.floor(this.ticks / 3.1536E+10);
    }

    midnight() {
        // ❌❌❌💡 this is being over called ... see log ... 
        // console.log("%c ❔ Could this be breaking the chat? [time-difference component]", consoleLogStyle)
        var midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        var now = new Date(this.orderTime);
        if (now > midnight) {
            return true;
        } else {
            return false;
        }
    }

    midnightBefore() {
        // ❌❌❌💡 this is being over called ... see log ... 
        // console.log("%c ❔ Could this be breaking the chat? [time-difference component]", consoleLogStyle)
        var midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        var yesterday = new Date();
        yesterday.setDate(midnight.getDate() - 1);
        var now = new Date(this.orderTime);
        if (now > yesterday) {
            return true;
        } else {
            return false;
        }
    }

    ngOnInit() {
        this.figureOutTimeSincePost(this.orderTime);
    }

    ngOnDestroy() {
        clearInterval(this.tickInterval);
    }
}
