<!-- 🚧 ... -->
<div
	class="page-wrapper"
	id="page-wrapper"
	(window:resize)="handleScreenCheck()">
	<div class="page-overlay">
		<!-- top nav bar -->
		<navigation-overlay
			class="navbar"
			id="via-nav"
			[isGuest]="isGuest"
			[class.modal-open]="showSidebar || !_channelService._isDesktop"
			[userInfo]="channelUserInfo"
			[userStatus]="userStatus"
			(sidebarOverlay)="sidebarDisplay($event)"
			[showUnlockedSidebar]="showSidebar">
		</navigation-overlay>
		<!-- sidebar -->
		<!-- *ngIf="!isGuest" -->
		<!-- 💡 there are no nav buttons if guest ... -->
		<div class="sidebar" [ngClass]="{ 'side-bar-show': showSidebar }">
			<sidebar-overlay
				id="side-container"
				[sideBarInView]="showSidebar"
				[userInfo]="channelUserInfo"
				[appType]="sidebarContainerAppType"
			>
			</sidebar-overlay>
			<!--  -->
			<message-sidebar
				id="side-message"
				[userInfo]="channelUserInfo"
			>
			</message-sidebar>
			<!--  -->
			<user-sidebar
				id="side-user"
				(themeModeSwitch)="themeModeSet($event)"
				[themeInit]="theme"
				[userStatus]="userStatus"
				[userInfo]="channelUserInfo"
			>
			</user-sidebar>
		</div>
		<toast-notification></toast-notification>
		<app-jitsi-call-manager></app-jitsi-call-manager>
	</div>
	<!-- 🚧 main ... -->
	<div
		class="main-content"
		[class.mobile-sidebar]="showSidebar"
		[class.in-channel]="inChannel"
		(mouseup)="showSidebar ? closeSideBar() : null"
	>
		<connection-overlay></connection-overlay>

		<!--<toast-notification></toast-notification> -->

		<router-outlet></router-outlet>
	</div>
</div>
