import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import {
    fadeIn,
    fadeInFast,
    rotateInOut,
    slideInLeft,
    slideInRight,
    listAnimationWrap,
    listAnimationItem
} from 'src/app/animations/global-animations';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TokenCredential } from '@azure/core-http';
import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob';
import { consoleLogStyle } from '../../shared/varaibles';
import { ChannelUser } from 'src/app/shared/interface/channel-user';
import { GuestService } from 'src/app/services/guest.service';
import { ChannelService } from 'src/app/services/channel-service';
import { of, Subject } from 'rxjs';
import { map, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';



@Component({
    selector: 'fax-homepage',
    templateUrl: './fax-homepage.component.html',
    styleUrls: ['./fax-homepage.component.scss'],
    animations: [
        fadeIn,
        fadeInFast,
        rotateInOut,
        slideInLeft,
        slideInRight,
        listAnimationWrap,
        listAnimationItem
    ]
})

export class FaxHomepageComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    // isPortable x... 
    @Input('isSideBar') isSideBar: boolean; // if is component; else is fax home.

    public currentFolderArray: any = [{
        urlPath: '/',
        fileName: '/',
        containerName: null,
        blodFileId: null,
        isFile: false
    }];

    public tooltipDelay: number = 400;

    // 🚧 fax variables 
    // public channelInfo: any;
    // public isOwner: boolean;
    // public isCreator: boolean;
    // public ownersList: any[];
    // public membersList: any[];
    // public addOwnerForm: FormGroup;
    // public selectedFaxFileSharedWith: any[] = [];

    public isOwner: boolean = false;

    public selectedFaxFolderOwners: any[] = [];
    public isFaxesDisplayed: boolean;

    public faxFolderName: string = "Fax Accounts"

    public searchUsersList: any[] = [];

    public displayAddOwnerModal: boolean = false;
    public isAddingOwner: boolean = false;
    public isRemovingOwner: boolean = false;

    public displayShareFaxModal: boolean = false;
    public isSharingFax: boolean = false;
    public isUnSharingFax: boolean = false;
    public fileUnread: boolean = true;

    public faxChannelId: string = null;
    public containerName: string;

    public isFilesLoading: boolean;

    public folderList: any[] = []; // set this to fax accounts (main folder)
    public filesList: any[] = [];

    public fileListPagination: any = {};
    public sentFaxPagination: any = {};

    public isGettingNextPage = false;

    public selectedFile: any = null;
    public selectedFileIndex: number = null;

    public renameFileForm: FormGroup;
    public displayRenameFileForm = false;
    public isRenameSubmitting = false;

    public isDeleting = false;

    public isDownloading = false;

    public favoriting = false;

    // public displaySearchFilesInput = false;
    public isSearching = false;

    public userId: string = null;
    public userInfo: ChannelUser;

    // 
    public isDesktop: boolean;
    public scrollTopBlock: boolean;
    public currentDateTime: any = new Date();
    public formattedCurrentDateTime = '';
    public setCurrentDateTime: any;

    // public displayFaxSettingsModal: boolean;
    // public newFaxAccount: FormGroup;
    // public listItemFax: string = "";
    // public listFax: any = [];

    // Send Fax
    public displayReceivedFaxesComponent: boolean = true;
    public displaySendFaxModal: boolean;
    public displayReSendFaxModal: boolean;
    public isSendingFax: boolean;

    // Fax/File Upload Start 
    public sasToken: TokenCredential;
    public blobServiceClient: BlobServiceClient;
    public blockBlobClient: BlockBlobClient;
    public accountFaxNumber = '';

    public uploadFile: any = {};
    public faxFileInfo: any = {};

    public sendFaxForm: any = {};

    public isFileUploading: boolean;
    public isDuplicateFile: boolean;
    public isFaxSending: boolean;

    public isFaxFormEdited: boolean;

    // should only isDuplicateFile suffice?
    public duplicateFileToSameNumber: boolean;

     // for holding the serch string 
     // when searching "shared With Me"    
     private searchSubject = new Subject<string>();
     // when searching users to be owners
     private userSearchSubject = new Subject<string>();

    // File Upload End 

    constructor(
        private _fileStorageService: FileStorageService,
        private _apiCallsService: ApiCallsService,
        private _toastService: ToastNotificationService,
        private _channelService: ChannelService,
        private formBuilder: FormBuilder,
        private _guestService: GuestService,

        // used for change detector ref, handles the Expression __ has changed after it was checked error with screen check
        private cdr: ChangeDetectorRef,
    ) {
        this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "deleteFax") {
                this.deleteFaxForAll();
            }
            if (data.type === "resendFax") {
                this.resendFax();
            }
            if (data.type === "toggleSendFaxModal") {
                // console.log("toggle send fax model called");
                this.toggleSendFaxModal();
            }
        });
    }

    ngOnInit() {
        // console.log("%c MEDIA is desktop ? ", consoleLogStyle, " = ", this.isDesktop);
        this.createForm();
        // this interval is cleared onDestroy 
        this.setCurrentDateTime = setInterval(() => {
            this.currentDateTime = new Date();
            this.formattedCurrentDateTime = formatDate(this.currentDateTime, 'EEEE, MMMM d, y  h:mm:ss a', 'en-US');
            // console.log("%c Current time", consoleLogStyle, this.formattedCurrentDateTime);
        }, 1000)
        // console.log("testing phone number formatting for 876-545-3333: " + this.formatPhoneNumber("18765453333"));
        this._getChannelUser();
        // console.log("%c is desktop? ", consoleLogStyle, this.isDesktop)

        //creates the search subject for the search debounce
        //delays calling the search query until the user paused for the time allotted (400ms here)
        this.searchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
              console.log("Search Query Passed: "+ searchQuery); 
              this._getUsersBySearchQuery(this.selectedFile.sharedFilesWith);
        });

        this.userSearchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
                console.log("Fax User Search Query Passed: "+ searchQuery); 
                this._getUsersBySearchQuery(this.selectedFile.sharedFilesWith);
        });
    }

    // new 
    ngAfterViewInit(): void {
        if (!!this.isSideBar) {
            if (this.isSideBar) {
                // console.log("%c is side bar true? ", consoleLogStyle, this.isSideBar)
                this.isDesktop = false;
            } else {
                // console.log("%c is side bar false? ", consoleLogStyle, this.isSideBar)
                this.handleScreenCheck();
            }
        } else {
            // console.log("%c is side bar undefined (is ok)? ", consoleLogStyle, this.isSideBar)
            this.handleScreenCheck();
        }
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isSideBar) {
            if (!!this.isSideBar) {
                if (this.isSideBar) {
                    this.isDesktop = false;
                } else {
                    this.handleScreenCheck();
                }
            } else {
                this.handleScreenCheck();
            }
        }
    }

    ngOnDestroy() {
        clearInterval(this.setCurrentDateTime);
        // console.log("%c On destroy? ", consoleLogStyle)
    }

    // screenCheck() {
    //     let mediaWidth = 1024; // needs to match .scss mq breakpoint 
    //     let mediaQuery = window.matchMedia(`(max-width: ${mediaWidth - 1}px)`);
    //     if (mediaQuery.matches) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    triggerFaxShareSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery);
    }

    triggerFaxUserSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.userSearchSubject.next(searchQuery);
    }

    handleScreenCheck() {
        // this.isDesktop = !this.isSideBar && this.screenCheck();
        // 🚧🚧🚧 testing here getting screen check from service ... 
        // don't think we need the !this.isSideBar check ...
        this.isDesktop = this._channelService._isDesktop;
        // console.log("%c ✨ Window resized fax, is desktop ? ", consoleLogStyle, this.isDesktop);
    }

    scrollCheck() {
        let scrollBox: any = document.querySelector("#fileScrollbox");
        let boxHeight = scrollBox.scrollHeight - scrollBox.offsetHeight;
        let boxTop = Math.ceil(scrollBox.scrollTop);
        if (!this.scrollTopBlock && boxTop >= boxHeight && this.fileListPagination.nextPageLink) {
            // console.log("scroll top: " + boxTop);
            // console.log("box height: " + boxHeight);
            this.scrollTopBlock = true;
            if (this.displayReceivedFaxesComponent) {
                this._getFilesPagination();
            } else {
                // get sent page ...
                this._getSentFaxesPagination();
            }
        }
    }

    // called oninit (gets user info) 
    _getChannelUser() {
        this.isFilesLoading = true;
        this._apiCallsService.getMyChannelUser()
            .subscribe(res => {
                // console.log("get channel user fax:", res);
                if (res.status === 200) {
                    // console.log("user info fax", res.body)
                    this.userInfo = res.body;
                    if (this.userInfo.isGuest) this._guestService.routeToChannelInvited();
                    this.userId = this.userInfo.channelUserId;
                    this.getFaxAccounts();
                } else {
                    console.error("error getting channel user", res.status, res.body)
                    this.isFilesLoading = false;
                }
            });
    }

    // Send Fax
    showSendFaxModal() {
        // console.log("Fax Modal Opened");
        this.sendFaxForm.reset();
    }

    hideSendFaxModal() {
        // console.log("Fax Modal Closed");
        this.isFaxFormEdited = false;
        this.displaySendFaxModal = false;
        if (this.isDuplicateFile)
            this.isDuplicateFile = false;
    }

    toggleSendFaxModal() {
        
        //Could maybe have a function that checks for "Shared With Me" folder in a list of folders
        ////cg
        if (!this.isOwner && !this.folderList[0] && this.folderList[0].fileName !== "Shared With Me") {
            this._toastService.info(`Speak with your admin to enable Faxing`);
        }
        else {
            this.displaySendFaxModal = (this.displaySendFaxModal) ? false : true;
            (this.displaySendFaxModal) ? this.showSendFaxModal() : this.hideSendFaxModal();
        }
    }

    submitFaxForm() {
        this._uploadFileToStorage();
        // console.log("submitFax Called");
        // console.log("Fax Number:" + this.sendFaxForm.value.faxNumber);
        // this.hideSendFaxModal();
    }

    resendFax() {
        this._apiCallsService.resendFaxApi(this.selectedFile)
            .subscribe(res => {
                if (res.status === 204) {
                    this._toastService.success(`Your fax to: ${this.selectedFile.faxNumber} has been queued to re-send.`);
                    this.sendFaxForm.reset();
                    this.displayReSendFaxModal = false;
                } else {
                    if (this.displayReSendFaxModal) { // done to prevent two toasts?
                        this._toastService.error("Re-sending fax unsuccessful. Try Again!");
                        this.displayReSendFaxModal = false;
                    }
                }
            });
    }

    showReceivedFaxes() {
        // console.log("showing RECIEVED faxes");
        this.displayReceivedFaxesComponent = true;
        this._callFileApi(false).subscribe();
    }

    showSentFaxes() {
        // console.log("showing SENT faxes");
        this.displayReceivedFaxesComponent = false;
        this._getSentFaxes();
    }

    // File Upload Start 
    // 🎫
    /*
        only accept { images, documents, audio, video } 
        & size max { 50mb } 
        
        50 Megabytes (MB)  =  52,428,800 Bytes (B)
        
        .exe = '4D5A'
        .dmg = '78DA'
    */

    fileName(e: Event) {
        const target = e.target as HTMLInputElement; // ang12 ts fix
        const files = target.files as FileList; // ang12 ts fix
        // this.displayCreateFolderForm = false;
        this.isFileUploading = true;
        this.isFaxFormEdited = true;
        // this.uploadStatusChange.emit(this.isFileUploading); //comment back later when in new component
        this.uploadFile = files[0];
        let fileReader = new FileReader();
        fileReader.onloadend = (e: any) => {
            let arr = (new Uint8Array(<ArrayBuffer>e.target.result)).subarray(0, 2);
            let size = e.target.result;
            // console.log("file byte length / file size :::", size.byteLength)
            // console.log("buffer array :::", arr)
            let hex = "";
            for (let i = 0; i < arr.length; i++) {
                hex += arr[i].toString(16);
            }
            // console.log("file hex :::", hex)
            // Check the file signature against known types
            let exe = ['4D5A', '78DA'];
            if (exe.includes(hex.toUpperCase())) {
                // console.log("is exe/dmg :::", hex)
                this.isFileUploading = false;
                this._toastService.error("Can not upload Executable file.")
                return;
            } else if (size.byteLength > 52428800) {
                // console.log("size > 50mb. :::", hex)
                this.isFileUploading = false;
                this._toastService.error("File size too large, file must be less than 50mb.")
                return;
            } else {
                // console.log("not exe | not > 50mb, ok to upload")
            }
        };
        fileReader.readAsArrayBuffer(this.uploadFile);
        // console.log("file size?", this.uploadFile.size)
    }

    _uploadFileToStorage() {
        this.isFaxSending = true;
        let fileInfo: any;
        fileInfo = {
            "containerName": this.folderList[0].containerName,
            "path": "/outbox/" + this.sendFaxForm.value.faxNumber + "/" + this.uploadFile.name,
            "fileName": this.uploadFile.name,
            "autoRename": false,
            "mode": "add",
            "size": this.uploadFile.size,
            "channelId": this.folderList[0].chatRoomId,
            "contentType": "faxoutbox"
        };
        this._fileStorageService.fileUpload(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("%c Success for upload: ", consoleLogStyle, res.status);
                    this._uploadFileToStorage2(fileInfo);
                    this.hideSendFaxModal();
                } else if (res.status === 400) {
                    // console.log("Error with upload NAME ?", res);
                    this._toastService.error("Only file names with 'a-z 0-9 (-._)', no special characters are allowed, file was not uploaded.");
                    this.isFileUploading = false;
                } else if (res.status === 409) {
                    // console.log("%c File already exists! ", consoleLogStyle, res.status);
                    this.isFileUploading = false;
                    // const allFiles = Array.from(document.getElementsByClassName('file-item') as HTMLCollectionOf<HTMLElement>);
                    // console.log("%c All files ", consoleLogStyle, allFiles);
                    // allFiles.forEach(i => i.style.display = 'none');
                    this.isDuplicateFile = true;
                } else {
                    // console.log("error with file upload:", res.status);
                    this._toastService.error("There was an error, file was not uploaded.");
                    this.isFileUploading = false;
                    this.hideSendFaxModal();
                }
                this.isFaxSending = false;
            });
    }

    _uploadFileToStorage2(fileInfo: any) {
        this._fileStorageService.fileUpload2(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    let file: any = res.body;
                    let url = file.uploadUrl;
                    let indexOfQueryStart = url.indexOf("?");
                    let baseUrl = url.substring(0, indexOfQueryStart) + "/" + this.trimStart("/", file.path);
                    this.sasToken = url.substring(indexOfQueryStart);
                    // console.log("%c File Uploaded: ", consoleLogStyle ,baseUrl);
                    this.blockBlobClient = new BlockBlobClient(baseUrl + this.sasToken);
                    this.uploadToBlobStorage(file.blobFileId);
                    this.hideSendFaxModal();
                    // if (!this.displayYourFilesComponent) {
                    //     this._channelService.sharedFileUploadComplete(this.router.url, fileInfo.fileName, this.channelId);
                    // }
                } else {
                    // console.log("Error with upload ?", res);
                    this._toastService.error("There was an error, file was not uploaded.");
                    this.isFileUploading = false;
                    this.hideSendFaxModal();
                }
            });
    }

    handleDuplicateFile(canReplace: boolean) {
        // console.log("%c Duplicate file handle ", consoleLogStyle);
        if (canReplace) {
            let fileInfo: any;
            fileInfo = {
                "containerName": this.filesList[0].containerName,
                "path": "/outbox/" + this.sendFaxForm.value.faxNumber + "/" + this.uploadFile.name,
                "fileName": this.uploadFile.name,
                "autoRename": false,
                "mode": "add",
                "size": this.uploadFile.size,
                "channelId": this.faxChannelId,
                "contentType": "faxoutbox"
            }
            // console.log("%c Replace file authorized ", consoleLogStyle);
            this.isDuplicateFile = false;
            // this._toastService.warn(`${fileInfo.fileName} already exists, overwriting existing file...`);
            this.isFileUploading = true;
            this.duplicateFileToSameNumber = true;
            this._uploadFileToStorage2(fileInfo);
        } else if (!canReplace) {
            // console.log("%c Replace file IS NOT authorized ", consoleLogStyle);
            this.isDuplicateFile = false;
            // this._callFileApi();
        }
    }


    _sendFax(fileId: string) {
        let faxInfo = {
            "chatUserId": this.userId,
            "userFaxAccountId": this.folderList[0].containerName,
            "faxNumber": this.sendFaxForm.value.faxNumber,
            "blobFileId": fileId
        }
        this._apiCallsService.postFaxApi(faxInfo)
            .subscribe(res => {
                if (res.status === 201) {
                    //call get faxes api
                    this._toastService.success(`Your fax to: ${this.sendFaxForm.value.faxNumber} has been queued to send.`);
                    this.sendFaxForm.reset();
                }
            })
    }

    trimStart(character: any, string: any) {
        var startIndex = 0;
        while (string[startIndex] === character) {
            startIndex++;
        }
        return string.substring(startIndex);
    }

    async uploadToBlobStorage(fileId: string) {
        const content = this.uploadFile;
        // const blobName = this.uploadFile.name;
        // console.log("upload to blob",this.blockBlobClient);
        let uploadBlobResponse;
        try {
            uploadBlobResponse = await this.blockBlobClient.upload(content, content.size);
            //  this._toastService.success(`${this.uploadFile.name} has uploaded.`);
            // this._callFileApi();
            if (this.duplicateFileToSameNumber == true) {
                // console.log("Fax Already Exists, Resending");
                this.duplicateFileToSameNumber = false;

                this._sendFax(fileId);
            }
            else {
                this._sendFax(fileId);
            }

        } catch (err) {
            // this._deleteFileFromStorageOnly(fileId);
            // console.error("error with upload to blob", err);
            this._toastService.error("There was an error, file was not uploaded.");
        } finally {
            this.isFileUploading = false;
        }
    }
    //File Upload End 

    createForm() {
        this.sendFaxForm = this.formBuilder.group({
            fileToFax: ['', [Validators.required]],
            faxNumber: ['', [Validators.required]],
        });
        this.renameFileForm = this.formBuilder.group({
            fName: ['', [Validators.required]]
        });
    }

    faxOwnersCheck(ownerCheck: any) {
        if (ownerCheck) {
            this.isOwner = true;
        } else {
            this.isOwner = false;
        }
        // console.log("%c is owner? ", consoleLogStyle, this.isOwner)
    }

    resetSelectedFile() {
        this.selectedFile = null;
        this.selectedFileIndex = null;
    }

    // 📁 gets fax folders   
    getFaxAccounts() {
        this.resetSelectedFile();
        this.isFaxesDisplayed = false;
        this._apiCallsService.getUserFaxAccounts(this.userId)
            .subscribe(res => {
                if (res.status === 200) {
                    //console.log("%c 📠 fax accounts: ", consoleLogStyle, res.body) // returns chatRoomId to 
                    this.filesList = res.body;
                    // separate variable due to fileslist being replaced by files in folder if folder selected
                    // Only grab once at init
                    if (this.folderList.length < 1) {
                        this.folderList = res.body;
                    }
                    this.isFilesLoading = false;
                    // console.log("selected file idx ???", this.selectedFileIndex)
                    // this.selectedFile = this.filesList[this.selectedFileIndex];
                    if (this.filesList[0]) {
                        this.faxOwnersCheck(this.filesList[0].chatRoomChatUsers); // ❌ what if more than one fax account? ... 
                        //console.log("Fax users: "+ JSON.stringify(this.filesList[0].chatRoomChatUsers));
                    }
                } else {
                    console.error("error getting fax accounts:", res.status, res.body)
                    this.isFilesLoading = false;
                }
            });
    }


    // 🚧 add/remove fax folder owner
    toggleAddOwnerModal() {
        this.displayAddOwnerModal = (this.displayAddOwnerModal) ? false : true;
        this.clearSearch();
    }

    // GET ALL USERS HERE 🎁 
    _getUsersBySearchQuery(currentList: any[]) { // CALLED (keyup) of searchbar input
        console.log("Fax user search query called");
        const userSearchBar = <HTMLInputElement>document.getElementById('user-search');
        let searchString = userSearchBar.value.replace(/\s/g, '');
        if (searchString.length < 1) {
            this.searchUsersList = [];
        } else {
            this._apiCallsService.getChannelUsersByTenantAndSearchQuery(this.userInfo.tenant, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        let newList = [];
                        let compareList = currentList.concat(this.selectedFaxFolderOwners);
                        // console.log("(search)✔ current list passed:", currentList, "this saved owners list:", this.selectedFaxFolderOwners)
                        // make sure not already: owner | shared | you, in search list 
                        searchList.forEach((searchUser, index) => {
                            if (this.userId !== searchUser.channelUserId) {
                                if (!compareList.some(owner => searchUser.channelUserId === owner.chatUserKeyNavigation.channelUserId)) {
                                    newList.push(searchList[index]);
                                }
                            }
                        });
                        this.searchUsersList = newList;
                        // console.log("search res:", this.searchUsersList)
                        // this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
                    } else {
                        console.error("error getting users by search", JSON.stringify(data));
                    }
                });
        }
    }

    clearSearch() {
        const userSearchBar = <HTMLInputElement>document.getElementById('user-search');
        if (userSearchBar) {
            userSearchBar.value = '';
        }
        this.searchUsersList = [];
    }

    searchCheckLength() {
        const userSearchBar = <HTMLInputElement>document.getElementById('user-search');
        if (userSearchBar) {
            if (userSearchBar.value.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    preventFileDblClick(e: Event) {
        e.stopPropagation();
    }

    addOwner(userId: string, username: string) {
        this.clearSearch();
        // console.log("adding owner:", userId, username)
        this.isAddingOwner = true; // TODO: add loading spinner to modal <---<<<|)
        let owner = {
            "channelUserId": userId,
            "channelId": this.selectedFile.chatRoomId
        };
        this._apiCallsService.postChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Owner Added");
                    this.isAddingOwner = false;
                    this._toastService.success(`${username} added as an owner.`);
                    this.getFaxAccounts();
                } else if (res.status === 409) {
                    // console.log("Owner Already Exists");
                    this.isAddingOwner = false;
                    this._toastService.error(`${username} is already an owner.`);
                } else {
                    // console.log("error adding owner to fax:", res);
                    this.isAddingOwner = false;
                    this._toastService.error(`There was an error adding ${username} as an owner.`);
                }
            });
    }

    removeOwner(userId: string, username: string) {
        this.clearSearch();
        // console.log("removing owner:", userId, username)
        this.isRemovingOwner = true; // TODO: add loading spinner to modal <---<<<|)
        let owner = {
            "channelUserId": userId,
            "channelId": this.selectedFile.chatRoomId
        };
        this._apiCallsService.deleteChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Owner Removed");
                    this.isRemovingOwner = false;
                    this._toastService.success(`${username} has been removed as owner.`);
                    this.getFaxAccounts();
                } else if (res.status === 404) {
                    // console.log("Owner does not exist");
                    this.isRemovingOwner = false;
                    this._toastService.error(`${username} has already been removed has an owner.`);
                } else {
                    // console.log("error removing owner:", res);
                    this.isRemovingOwner = false;
                    this._toastService.error(`There was an error removing ${username} as an owner.`);
                }
            });
    }
    // END add/remove fax folder owner

    // 🚧 share fax start
    toggleShareFaxModal() {
        this.containerName = this.filesList[0].containerName;
        this.displayShareFaxModal = (this.displayShareFaxModal) ? false : true;
        this.clearSearch();
        // console.log(this.selectedFile)
    }

    shareFax(userId: string, username: string) {
        this.clearSearch();
        this.selectedFileIndex = 0;
        let fileInfo = {
            "chatUserId": userId, // user to share with
            "blobFileId": this.selectedFile.blobFileId,
            "ownerChatUserId": this.userId
        };
        this._fileStorageService.shareFile(fileInfo).pipe(
            switchMap((res: any) => {
                if (res.status === 200) {
                    // console.log("fax share success", fileInfo)
                    return this._callFileApi().pipe(
                        map(_ => {
                            this._toastService.success(`Fax shared with ${username}.`);
                            this.selectedFile = this.filesList.find(x => x.blobFileId == fileInfo.blobFileId)
                        })
                    )
                } else {
                    // console.log("error in fax sharing", res)
                    //this.isSharingFax = false;
                    this._toastService.error(`There was an error sharing this fax with ${username}`);
                    return of(res);
                }
            }
            ),
        ).subscribe();
    }

    unShareFax(userId: string, username: string) {
        this.clearSearch();
        this.isUnSharingFax = true;
        let fileInfo = {
            "chatUserId": userId, // user to share with
            "blobFileId": this.selectedFile.blobFileId,
            "ownerChatUserId": this.userId
        };
        this._fileStorageService.unShareSelf(fileInfo).pipe(
            switchMap(res => {
                if (res.status === 200) {
                    // console.log("fax un-share success", fileInfo)
                    return this._callFileApi().pipe(map(_ => {
                        this.isUnSharingFax = false;
                        this._toastService.success(`${username} has been removed from this fax.`)
                        this.selectedFile = this.filesList.find(x => x.blobFileId == fileInfo.blobFileId)
                    }))

                } else {
                    // console.log("error in fax un-sharing", res)
                    this.isUnSharingFax = false;
                    this._toastService.error(`There was an error removing ${username} from this fax.`);
                    return of(res);//what
                }
            })
        )
            .subscribe();
    }

    unShareFaxWithSelf() {
        // console.log('un-sharing Fax with self:')
        this.isUnSharingFax = true;
        let fileInfo = {
            "chatUserId": this.userId, // user to share with
            "blobFileId": this.selectedFile.blobFileId,
        };
        this._fileStorageService.unShareSelf(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("fax un-share success", fileInfo)
                    this.isUnSharingFax = false;
                    this._toastService.info(`You have been removed from this fax.`)
                    this._callFileApi(true); // if last file, 
                } else {
                    console.error("error in fax un-sharing", res)
                    this.isUnSharingFax = false;
                }
            });
    }
    // END share fax

    //Start Favorites
    addFavorite(fileId: string, index: number, e: Event) {
        e.stopPropagation();
        let fileInfo = {
            "chatUserId": this.userId, // user to share with
            "blobFileId": fileId,
        };
        this._fileStorageService.fileAddToFavorite(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    // this._callFileApi(false);
                    this.filesList[index].favoriteBlobFiles.push({ isFavorite: true })
                } else {
                    this._toastService.error(`Could not add file to favorites`);
                    // console.error("Add favorite error", res);
                }
            });
        // console.log(this.filesList[index]);
    }

    removeFavorite(fileId: string, index: number, e: Event) {
        e.stopPropagation();
        this.favoriting = true;
        let fileInfo = {
            "chatUserId": this.userId, // user to share with
            "blobFileId": fileId,
        };
        this._fileStorageService.fileRemoveFromFavorite(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    // this._callFileApi(false);
                    this.filesList[index].favoriteBlobFiles = [];
                    this.favoriting = false;
                } else {
                    this._toastService.error(`Could not remove file from favorites`);
                    this.favoriting = false;
                    // console.log("Remove favorite error", res);
                }
            });
        // console.log(this.filesList[index]);
    }
    //End Favorites

    goBack() {
        this.currentFolderArray.pop();
        this.getFaxAccounts();
        // this.selectedFile = null;
        // this.selectedFileIndex = null;
        this.resetSelectedFile();
        this.faxChannelId = null;
        this.displayReceivedFaxesComponent = true;
        this.selectedFaxFolderOwners = [];
        // console.log("back-clicked, this.currentFolderArray = ", this.currentFolderArray);
    }

    // 📃 gets faxes ... 
    _callFileApi(isUnshare?: boolean) {
        this.resetSelectedFile();
        this.isFaxesDisplayed = true;
        let chatUserId = null;
        // console.log("FileAPI Selected File: " + this.containerName);
        if (this.selectedFile) {
            if (this.selectedFile.fileName === "Shared With Me" || isUnshare) {
                // console.log("isUnshare??????", isUnshare)
                this.containerName = "sharedfolder";
                chatUserId = this.userId;
            } else {
                chatUserId = null;
            }
        }
        let folderInfo: any = {
            "containerName": this.containerName,
            "urlPath": "",
            "pageSize": 20,
            "orderBy": "LastActivity desc, Created desc",
            "isFile": '',
            "chatUserId": chatUserId
        };

        return this._fileStorageService.getFiles(folderInfo).pipe(map(data => {
            if (data.status === 200) {
                // console.log("%c 📃 fax list: ", consoleLogStyle, data.body);
                this.filesList = data.body;
                this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));

                this.scrollTopBlock = false;
                this.faxOwnersCheck(this.filesList[0].sharedFilesWith);
            } else {
                // console.error("Error getting files ?", data);
                this._toastService.error("There was an error in getting files.");

            }
            return data;
        }))
    }

    _getFilesPagination() {
        this.isGettingNextPage = true;
        // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
        let folderInfo: any = {
            "containerName": this.containerName,
            "parentId": null,
            "orderBy": "LastActivity desc, Created desc",
            "pageNumber": this.fileListPagination.currentPage + 1,
            "pageSize": 20
        };
        this._fileStorageService.getFiles(folderInfo)
            .subscribe(data => {
                // console.log("folder info for pageination", folderInfo)
                if (data.status === 200) {
                    let list: any = data.body;
                    this.filesList = this.filesList.concat(list);
                    // console.log("filesList from pagination:", this.filesList);
                    this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));
                    this.scrollTopBlock = false;
                    setTimeout(() => {
                        this.isGettingNextPage = false;
                        // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
                    });
                } else {
                    // console.error("Error in pagination ?", data);
                    this._toastService.error("There was an error in getting more files.");
                    this.isGettingNextPage = false;
                }
            });
    }

    _getSentFaxes() {
        this.resetSelectedFile();
        this.isFaxesDisplayed = true;
        this.isFilesLoading = true;
        // if (this.selectedFile) {
        //         this.containerName = this.selectedFile.containerName;
        // }
        let folderInfo: any = {
            "containerName": this.containerName,
            "urlPath": "",
            "pageSize": 15,
            "orderBy": "",
            "isFile": "",
            "chatUserId": this.userId
        };
        this.filesList = [];
        this._apiCallsService.getFaxApi(folderInfo.containerName)
            .subscribe(data => {
                if (data.status === 200) {
                    // console.log("%c 📃 fax list: ", consoleLogStyle, data.body);
                    this.filesList = data.body;
                    this.isFilesLoading = false;
                    // console.log("selected file idx ???", this.selectedFileIndex)
                    // this.selectedFile = this.filesList[this.selectedFileIndex];
                    this.sentFaxPagination = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("this.sentFaxPagination: ", this.sentFaxPagination);
                    this.scrollTopBlock = false;
                } else {
                    // console.error("Error getting files ?", data);
                    this._toastService.error("There was an error in getting files.");
                    this.isFilesLoading = false;
                }
            });
    }

    _getSentFaxesPagination() {
        this.isGettingNextPage = true;
        // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
        let folderInfo: any = {
            "containerName": this.containerName,
            "parentId": null,
            "orderBy": "",
            "pageNumber": this.sentFaxPagination.currentPage + 1,
            "pageSize": 20
        };
        // this._apiCallsService.getFaxApi(folderInfo.containerName)
        if (this.sentFaxPagination.nextPageLink != null) {
            this._apiCallsService.getGenericForPagination(this.sentFaxPagination.nextPageLink)
                .subscribe(data => {
                    // console.log("folder info for pageination", folderInfo)
                    if (data.status === 200) {
                        let list: any = data.body;
                        this.filesList = this.filesList.concat(list);
                        // console.log("filesList from pagination:", this.filesList);
                        this.sentFaxPagination = JSON.parse(data.headers.get('X-Pagination'));
                        this.scrollTopBlock = false;
                        setTimeout(() => {
                            this.isGettingNextPage = false;
                            // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
                        });
                    } else {
                        // console.error("Error in pagination ?", data);
                        this._toastService.error("There was an error in getting more files.");
                        this.isGettingNextPage = false;
                    }
                });
        }
        else {
            this.isGettingNextPage = false;
            // console.log("No additional fax pages");
        }
    }


    // 🚧 DO NOT REMOVE YET, MAY NEED...
    // search start
    // showSearchFilesInput() {
    //     // console.log("search bar open ?", this.displaySearchFilesInput);
    //     if(this.displaySearchFilesInput && !this.isSearching) {
    //         this.displaySearchFilesInput = false;
    //         if (this.isSearching && this.filesList.length === 0) {
    //             this.isSearching = false;
    //             this._callFileApi();
    //         }
    //     } else {
    //         this.displaySearchFilesInput = true;
    //         // this.selectedFile = null;
    // this.selectedFileIndex = null;
    //     }
    //     // console.log("search bar open ?", this.displaySearchFilesInput);
    // }

    // searchFiles(e: any) {
    //     let expression = /^\S*$/;
    //     let regex = new RegExp(expression);

    //     if (e.target.value.match(regex)) {
    //         if (e.target.value && e.target.value !== "") {
    //             this._callFileApi(e.target.value);
    //             this.isSearching = true;
    //             // console.log("active file search for: orig target ?", e.originalTarget.value);
    //             // console.log("active file search for: TARGET ?", e.target.value); - same as above 

    //         } else {
    //             this._callFileApi();
    //             this.isSearching = false;
    //         }
    //     }
    // }

    // clearSearch() {
    //     const searchInput: any = document.getElementById('searchInput');
    //     // const searchClose: any = document.getElementById('searchClose');
    //     if (this.isSearching || searchInput) {
    //         searchInput.value = "";
    //         this.isSearching = false;
    //         this._callFileApi();
    //     }
    // }
    // search end

    // For formating current fax phone number as a number

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return [match[2], '-', match[3], '-', match[4]].join('');
        }
        return null;
    }

    // select file & info 
    selectFile(i: number) {
        // console.log("%c file selected ", i)
        // console.log("%c item already selected! ", consoleLogStyle); // <- if IS selected line 345 returns;
        if (!this.isDownloading && !this.isDeleting) {
            if (this.selectedFile && this.selectedFile === this.filesList[i]) return;
            // to close mobile infos on select 
            const allOtherInfos = Array.from(document.getElementsByClassName('file-info-display') as HTMLCollectionOf<HTMLElement>);
            // console.log("%c All other infos ", consoleLogStyle, allOtherInfos);
            allOtherInfos.forEach(info => info.style.display = 'none');
            this.selectedFile = this.filesList[i];
            this.selectedFileIndex = i;
            // console.log("%c Item selected: ", consoleLogStyle, this.filesList[i], i);
        } else {
            return;
        }
    }

    // double click item - 
    openFolder(isFile: boolean, ownersList: any[]) {
        if (!this.isDownloading && !this.isDeleting) {
            if (isFile) {
                this.downloadFile();
                // console.log("file downloaded");
            } else {
                this.faxChannelId = this.selectedFile.chatRoomId;
                this.selectedFaxFolderOwners = ownersList;
                this.accountFaxNumber = this.selectedFile.faxNumber;
                this.containerName = this.selectedFile.containerName;
                this.currentFolderArray.push(this.selectedFile);
                // this._getFilesPagination();
                if (this.displayReceivedFaxesComponent) {
                    this._callFileApi().subscribe();
                }
                else {
                    this._getSentFaxes();
                }
                // this.selectedFile = null;
                // this.selectedFileIndex = null;
                this.resetSelectedFile();
            }
        } else {
            return;
        }
        // console.log("folder open");
    }

    showReSendFaxConfirmation() {
        // this.displayReSendFaxModal = true;
        this._toastService.continue(`Are you sure you want to send this same fax to ${this.formatPhoneNumber(this.selectedFile.faxNumber)}?`, "resendFax");
    }

    closeReSendFaxConfirmation() {
        // this.displayReSendFaxModal = false;
    }

    // mobile only display file info -  i = File index & also id of info element 
    showFileInfo(e: Event, i: number) {
        e.stopPropagation();
        // console.log("show info", i, e);
        // to close other infos 
        const allOtherInfos = Array.from(document.getElementsByClassName('file-info-display') as HTMLCollectionOf<HTMLElement>);
        // console.log("%c All other infos ", consoleLogStyle, allOtherInfos);
        allOtherInfos.forEach(info => info.style.display = 'none');
        // open this 'i' info w/ transition 
        const info = document.getElementById(`fax-info-${i}`);
        info.style.display = "flex";
        info.animate([
            { opacity: 0, transform: 'scale(0)' },
            { opacity: 1, transform: 'scale(1)' }
        ], {
            duration: 200
        });
        // console.log("file-item", i);
    }

    // to close info when clicked itself 
    closeFileInfo(i: number, e: Event) {
        e.stopPropagation();
        // console.log("mouse down", i, e);
        const info = document.getElementById(`fax-info-${i}`);
        info.style.display = "none";
    }

    // download file start 
    downloadFile(e?: Event) {
        if (e) e.stopPropagation();
        this.isDownloading = true;
        let fileInfo = {
            "path": this.selectedFile.urlPath,
            "blobFileId": this.selectedFile.blobFileId,
            "channelId": "fax"
        }
        this._fileStorageService.fileDownload(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    this.createDownloadElement(res.body);
                } else {
                    // console.log("Error in download ?", res);
                    this._toastService.error(`There was an error in downloading ${this.selectedFile.fileName}.`);
                    this.isDownloading = false;
                }
            });
        // console.log("download file clicked:", fileInfo);
    }

    createDownloadElement(link: any) {
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', link);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.isDownloading = false;
    }
    // download file end 

    // rename start 
    autoFocusRenameInput() {
        setTimeout(() => {
            const targetInput: any = document.querySelector('#renameInput');
            if (targetInput) {
                targetInput.focus();
            }
        });
    }

    showRenameFileInput(e?: Event) {
        if (e) e.stopPropagation();
        // this.displayRenameFileForm = (this.displayRenameFileForm) ? false : true;
        if (this.displayRenameFileForm) {
            this.displayRenameFileForm = false;
            this.renameFileForm.reset();
        } else {
            this.displayRenameFileForm = true;
            this.renameFileForm.reset();
            this.autoFocusRenameInput();
        }
    }

    // could rename fax on fron-end so to not get files again ... 
    renameFile() {
        this.isRenameSubmitting = true;
        let fileInfo: any;
        fileInfo = {
            "fromPath": this.selectedFile.urlPath,
            "toPath": "/" + this.renameFileForm.value.fName + "." + this.selectedFile.fileName.substring(this.selectedFile.fileName.lastIndexOf(".") + 1, this.selectedFile.fileName.length),
            "newFileName": this.renameFileForm.value.fName + "." + this.selectedFile.fileName.substring(this.selectedFile.fileName.lastIndexOf(".") + 1, this.selectedFile.fileName.length),
            "autoRename": false,
            "blobFileId": this.selectedFile.blobFileId,
            "channelId": this.faxChannelId
        };
        if (this.renameFileForm.value.fName !== "" && this.renameFileForm.value.fName !== null && this.renameFileForm.value.fName !== this.selectedFile.fileName.substring(0, this.selectedFile.fileName.lastIndexOf("."))) {
            this._fileStorageService.renameFile(fileInfo)
                .subscribe(res => {
                    if (res.status === 200) {
                        // console.log("resolved rename folder?:", res)
                        this._callFileApi().subscribe();
                        this.selectedFile = null;
                        this.isRenameSubmitting = false;
                        this.renameFileForm.reset();
                        this.displayRenameFileForm = false;
                    } else if (res.status === 400) {
                        // console.log("%c Name Error ", consoleLogStyle);
                        this._toastService.error("File name must contain letters and numbers.");
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else if (res.status === 409) {
                        // console.log("%c Duplicate Error: ", consoleLogStyle);
                        this._toastService.error(`${fileInfo.newFileName.slice(0, -1)} already exists.`);
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else {
                        // console.log("something went wrong with rename", res.status.toString());
                        this._toastService.error("Something went wrong with rename.");
                        this.isRenameSubmitting = false;
                        this.displayRenameFileForm = false;
                    }
                });
        } else {
            // console.log("%c Must edit name to submit! ", consoleLogStyle)
            this._toastService.error("Must edit file name to submit.");
            this.isRenameSubmitting = false;
            this.autoFocusRenameInput();
        }
    }
    // rename end

    // delete file start 
    _deleteFileFromAll(e?: Event) {
        if (e) e.stopPropagation();
        if (this.selectedFile.sharedFilesWith && this.selectedFile.sharedFilesWith.length > 0) {
            this._toastService.continue("Deleting this fax will also delete it for anyone it has been shared with, do you still want to delete?", "deleteFax");
            // if(confirm('Deleting this fax will also delete it for anyone it has been shared with, do you still want to delete?')) {
            //     this.deleteFaxForAll();
            // } else {
            //     return;
            // }
        } else {
            this._toastService.continue("You are about to delete this fax, would you like to continue?", "deleteFax");
            // this.deleteFaxForAll();
        }
    }

    // could remove fax on fron-end so to not get files again ... 
    deleteFaxForAll() {
        this.isDeleting = true;
        let fileInfo = {
            "path": this.selectedFile.urlPath,
            "blobFileId": this.selectedFile.blobFileId
        }
        this._fileStorageService.fileDelete(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    if (!this.displayReceivedFaxesComponent) {
                        this._deleteSentFaxRecord();
                    } else {
                        this._callFileApi().subscribe();
                    }
                    this.isDeleting = false;
                    this._toastService.success(`${this.selectedFile.fileName} has been deleted.`);
                    // this.selectedFileIndex = null;
                    // this.selectedFile = null;
                    this.resetSelectedFile();
                } else {
                    // console.log("Error in delete ?", res);
                    this._toastService.error(`There was an error in deleting ${this.selectedFile.fileName}.`);
                    this.isDeleting = false;
                }
            });
    }

    // to close all modals | add switch if needed to close milti ... 
    clickOffFaxModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        // console.log("%c e.t, e.srcel, e.curtar ", consoleLogStyle, event.target, event.srcElement, event.currentTarget)
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
            // console.log("%c tar.id? ,val? ", consoleLogStyle, idAttr, value)
        }
        switch (value) {
            case 'add-owner-modal': {
                this.toggleAddOwnerModal();
                break;
            }
            case 'share-fax-modal': {
                this.toggleShareFaxModal();
                break;
            }
            case 'send-fax-modal': {
                if (this.isFaxFormEdited) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "toggleSendFaxModal");
                } else {
                    this.toggleSendFaxModal();
                }
                break;
            }
        }
    }

    // might want to have this be the main close function for the modal
    closeSendFaxModal() {
        if (this.isFaxFormEdited) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "toggleSendFaxModal");
        } else {
            this.hideSendFaxModal();
        }
    }

    // could remove fax on fron-end so to not get files again ... 
    _deleteSentFaxRecord() {
        this._apiCallsService.deleteSentFax(this.selectedFile.faxId)
            .subscribe(res => {
                if (res.status === 204) {
                    this._getSentFaxes();
                } else {
                    this._toastService.error(`There was an error in deleting ${this.selectedFile.fileName}.`);
                    this.isDeleting = false;
                }
            });
    }


    // showFaxSettings() {
    //     // console.log("showing fax seetings");
    //     this.displayFaxSettingsModal = true;
    // }

    // closeFaxSettingsModal() {
    //     // console.log("showing fax seetings");
    //     this.displayFaxSettingsModal = true;
    // }

    // createFaxAccountForm() {
    //     this.newFaxAccount = this.fb.group({
    //         faxProviderId: "",
    //         faxProviderPassword: "",
    //         faxNumber: "",
    //         faxAccountName: ""
    //     });
    // }

    // get newFaxControls() { return this.newFaxAccount.controls; }

    // submitNewFaxAccountForm() {
    //     this.createChannelFax();
    // }

    // createChannelFax() {
    //     let newChannel = {
    //         "teamProjectId": Constants.faxProjId,
    //         "channelUserId": this.userId,
    //         "name": this.newFaxControls.faxAccountName.value,
    //         "type": "fax",
    //         "videoType": "jitsi",
    //         "topic": null,
    //         "inviteCode": null,
    //         "welcome": null,
    //         "deleteRule": 0,
    //         "deleteInterval": 0,
    //         "scope": "Owner",
    //         "private": true,
    //         "closed": false,
    //         "inVideoCall": false,
    //         "inScreenShare": false,
    //     };
    //     // console.log(JSON.stringify(newChannel));
    //     // console.log(newChannel.name);
    //     this._postChannel(newChannel);
    // }

    // _postChannel(newChannel:any) {
    //     this._apiCallsService.postChannel(newChannel)
    //     .subscribe(res => {
    //         if (res.status === 201) {
    //             let newChannel = res.body;
    //             this.faxChannelId = newChannel.channelId;
    //             this.createFaxAccount();
    //         } else {
    //             console.error("Post Channel", res);
    //             this._toastService.error("Failed to create fax account");
    //         }
    //     });
    // }

    // createFaxAccount() {
    //     let newAccount = {
    //         faxProviderId: this.newFaxControls.faxProviderId.value,
    //         faxProviderPassword: this.newFaxControls.faxProviderPassword.value,
    //         faxNumber: this.newFaxControls.faxNumber.value,
    //         faxAccountName: this.newFaxControls.faxAccountName.value,
    //         channelId: this.faxChannelId,
    //         chatUserId: this.userId,
    //     };
    //     console.log("fax form value :::: ", newAccount)

    //     this._apiCallsService.postCreateUserFaxAccount(newAccount)
    //         .subscribe(res => {
    //             if (res.status === 200) {
    //                 console.log('Fax Account Created!');
    //                 this._toastService.success("Fax account created");
    //             } else {
    //                 console.log ("Fax Account Creation Failed", res);
    //                 this._toastService.error("Failed to create fax account");
    //             }
    //         });
    // }
    // 
}
