<div class="contact-create flex-col">
    <!-- title -->
    <div class="title-row mb-4">
        <div class="contact-title">
            <h3 *ngIf="!isEditContact" class="title-thin flex align-center"><i class="fas fa-user-plus me-3"></i>Create New Contact</h3>
            <h3 *ngIf="isEditContact" class="title-thin flex align-center"><i class="fas fa-user-edit me-3"></i>Edit Contact</h3>
        </div>
    </div>
    <!--  -->
    <form 
        [formGroup]="newContact"
        (document:keydown.enter)="newContact.valid && (!isUpdatingContact || !isCreatingContact) ? keydownEnterForm() : ''"
        class="flex-col">
        <div class="flex mb-1">
            <!-- photo -->
            <div class="profile-img-wrap flex-col-center-all pe-2">
                <div 
                    id="image-drop-target" 
                    class="user-img circle" 
                    (click)="displayPhotoEditor()" 
                    (dragenter)="imageDragEnter($event)" 
                    (dragleave)="imageDragLeave($event)" 
                    (dragover)="imgageDragOver($event)" 
                    (drop)="imageDrop($event)" 
                    matTooltip="upload photo" 
                    matTooltipClass="tooltip-default">
                    
                    <!-- loading image -->
                    <div *ngIf="isImageUploading" class="load-photo flex-center-all">
                        <i class="fas fa-spinner spinner"></i>
                    </div>
                    <img 
                        id="contact-image" 
                        alt="Profile Picture" 
                        (error)="handleBrokenImage($event)"/>
                </div>
                <!--  -->
                <div class="hidden-input">
                    <!-- lots of in/outs as create & update have different requirements -->
                    <profile-img-upload
                        [userId]="userInfo.channelUserId"
                        [isCustomContact]="true"
                        (uploadStatus)="contactImgChange($event)"
                        (image)="recieveImage($event)"
                        [contactId]="newContactId"
                        [imageToSubmit]="imageToSubmit"
                        (complete)="completeContactSubmit($event)"
                        (createContactUploadError)="handleCreateContactImageUploadError($event)"
                        [isEditContact]="isEditContact"
                        (updateComplete)="updateContact($event)">
                    </profile-img-upload>
                </div>
                <!-- only show if image is NOT default : hash 1 -->
                <button 
                    *ngIf="!isImageDefault && !isImageUploading"
                    class="remove-image"
                    (keydown.enter)="prevDef($event)"
                    (click)="removeProfilePhoto()"
                    matTooltip="remove image" 
                    matTooltipClass="tooltip-default">
                    <i class="far fa-trash-alt"></i>
                    <!-- <i *ngIf="isImageUploading" class="fas fa-spinner spinner"></i> -->
                </button>
            </div>
            <!-- name -->
            <div class="flex-col ps-4 w-100 justify-center name-group">
                <input
                    type="text" class="mb-3" placeholder="First Name"
                    maxlength="50"
                    name="firstName" formControlName="firstName">
                
                <input 
                    type="text" placeholder="Last Name"
                    maxlength="50"
                    name="lastName" formControlName="lastName">
                
                <!-- name errors -->
                <ng-container 
                    *ngIf="
                        (newContactControls.firstName.touched && newContactControls.firstName.errors) || 
                        (newContactControls.lastName.touched && newContactControls.lastName.errors)"
                        >
                    <div 
                        class="error-message-name"
                        *ngIf="newContactControls.firstName.errors?.required || newContactControls.lastName.errors?.required">
                        First & last name are required.
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- email & phone errors -->
        <ng-container *ngIf="newContactControls.email.touched && newContactControls.email.errors && !showEmailConflictError">
            <div 
                class="error-message-contact w-50 pe-2"
                *ngIf="newContactControls.email.errors.required">
                Email is required.
            </div>
            <div 
                class="error-message-contact w-50 pe-2"
                *ngIf="newContactControls.email.errors.pattern">
                Valid email - (example@gmail.com)
            </div>
        </ng-container>
        <ng-container *ngIf="newContactControls.phone.touched && newContactControls.phone.errors">
            <div 
                class="error-message-contact w-50 ps-2 ms-auto"
                *ngIf="newContactControls.phone.errors.pattern">
                Valid phone - (555) 555-5555
            </div>
        </ng-container>
        <ng-container *ngIf="showEmailConflictError">
            <!--  -->
            <div 
                class="error-message-contact w-50 pe-2 me-auto">
                Email already in use.
            </div>
        </ng-container>
        <!-- email & phone -->
        <div class="flex mt-4">
            <div class="flex-col-center-all pe-2 w-50">
                <input 
                    id="contact-email-input"
                    maxlength="50"
                    (blur)="handleEmailConflictBlur()"
                    type="text" placeholder="Email"
                    name="email" formControlName="email">
                
            </div>
            <div class="flex-col-center-all ps-2 w-50">
                <input
                    mask="(000) 000-0000"
                    type="text" placeholder="Phone"
                    name="phone" formControlName="phone">
                
            </div>
        </div>
        <!-- company & position -->
        <div class="flex mt-3">
            <div class="flex-col-center-all pe-2 w-50">
                <input 
                    type="text" placeholder="Company"
                    maxlength="250"
                    name="company" formControlName="company">
                
            </div>
            <div class="flex-col-center-all ps-2 w-50">
                <input 
                    type="text" placeholder="Position"
                    maxlength="250"
                    name="position" formControlName="position">
                
            </div>
        </div>
        <!-- address -->
        <div class="flex my-3">
            <div class="flex-col-center-all w-100">
                <input 
                    type="text" placeholder="Address  (street, city, state, zip)"
                    maxlength="250"
                    name="address" formControlName="address">
                
            </div>
        </div>
        <!-- notes -->
        <div class="box-quote my-4">
            <textarea 
                (keydown.enter)="stopProp($event)"
                type="text"
                name="notes"
                spellcheck="false"
                formControlName="notes"
                class="box-quote-textarea scrollbar-new"
                placeholder="You can enter notes on this contact here...">
            </textarea>
            <i class="quote-icon fas fa-quote-right flex-center-all"></i>
        </div>
        <!-- create submit -->
        <div class="flex justify-center mt-4">
            <ng-container *ngIf="!isEditContact">
                <button 
                    class="primary-button"
                    [disabled]="!isFormTouched || newContact.invalid"
                    [class.invalid-form]="isCreatingContact"
                    (click)="newContact.valid && !isCreatingContact ? createContact() : ''">
                    
                    <ng-container *ngIf="!isCreatingContact">
                        Create Contact
                    </ng-container>
                    <ng-container *ngIf="isCreatingContact">
                        <i class="fas fa-spinner spinner"></i>&nbsp;Creating...
                    </ng-container>
                </button>
            </ng-container>
            <!-- update contact -->
            <ng-container *ngIf="isEditContact">
                <button 
                    class="primary-button"
                    [disabled]="!isFormTouched || newContact.invalid"
                    [class.invalid-form]="isUpdatingContact"
                    (click)="newContact.valid && !isUpdatingContact ? updateContactWithImage() : ''">
                    
                    <ng-container *ngIf="!isUpdatingContact">
                        Update Contact
                    </ng-container>
                    <ng-container *ngIf="isUpdatingContact">
                        <i class="fas fa-spinner spinner"></i>&nbsp;Updating...
                    </ng-container>
                </button>
            </ng-container>
        </div>
    </form>
</div>
