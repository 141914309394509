<div
    id="resizeEl-{{ componentId }}"
    class="file-storage-module"
    (window:resize)="componentWidthCheck()"
    (resize)="componentWidthCheck()"
>
    <div 
        class="fs-main-wrap p-2"
        [class.intake]="componentId === 'intake-io' || componentId === 'intake-ao'">
        <div class="fs-view-wrap">
            <!-- YOUR FILES VIEW -->
            <div class="fs-view-your-files">
                <!-- title row -->
                <div class="row align-baseline">
                    <!-- link to previous folder if in sub folder -->
                    <ng-container *ngIf="componentId !== 'intake-ao' && componentId !== 'intake-io'">
                        <button
                            *ngIf="currentFolderArray.length > 1"
                            (click)="goBack()"
                            @fadeIn
                            title="go back"
                            class="back-button"
                        >
                            <span class="fs-button back-icon">
                                <i class="fas fa-arrow-left fs-icon"></i>
                            </span>
                        </button>
                        <div
                            *ngIf="currentFolderArray.length === 1"
                            class="back-button-placeholder"
                        >
                            <span class="back-icon">
                                <i class="fas fa-arrow-left"></i>
                            </span>
                        </div>
                    </ng-container>
                    <!--  -->
                    <div
                        *ngIf="displayYourFilesComponent"
                        title="{{
                            currentFolderArray[currentFolderArray.length - 1]
                                .urlPath
                        }}"
                        @fadeIn
                        class="current-folder-tag"
                        [class.desktop]="isDesktop"
                        [class.intake]="componentId === 'intake-ao' || componentId === 'intake-io'"
                    >
                        {{
                            currentFolderArray[currentFolderArray.length - 1]
                                .fileName | folderName
                        }}
                    </div>
                    <div
                        *ngIf="!displayYourFilesComponent"
                        title="{{
                            currentFolderArray[currentFolderArray.length - 1]
                                .urlPath
                        }}"
                        @fadeIn
                        class="current-folder-tag"
                        [class.desktop]="isDesktop"
                        [class.intake]="componentId === 'intake-ao' || componentId === 'intake-io'"
                    >
                        {{
                            currentFolderArray[currentFolderArray.length - 1]
                                .fileName | channelFolderPipe: channelName
                        }}
                    </div>
                    <!-- <h4 *ngIf="isPortable">I'm portable</h4> -->
                    <!-- displays current date/time -->
                    <div *ngIf="isDesktop" @fadeIn class="fs-time">
                        <i class="fas fa-calendar-day"></i>
                        <!-- ℹ ~ this can be formatted with | date, but this is example of Angular common module date formating in .ts, see below for | date pipe examples -->
                        {{ formattedCurrentDateTime }}
                    </div>
                </div>
                <!-- search row -->
                <div class="row search-row" [class.desktop]="isDesktop">
                    <!-- re-fresh button -->
                    <button
                        (click)="isFilesLoading ? '' : _resetFileList()"
                        class="fs-button"
                        title="refresh"
                    >
                        <i
                            [class.re-freshing]="isFilesLoading"
                            [class.spinner]="isFilesLoading"
                            class="fas fa-sync-alt sort-icon fs-icon"
                        ></i>
                    </button>
                    <!-- search icon displays search bar when clicked -->
                    <div class="search-input-group ms-3">
                        <i
                            [class.search-icon-open]="displaySearchFilesInput"
                            (click)="showSearchFilesInput()"
                            @fadeIn
                            title="search"
                            class="fas fa-search search-icon fs-button"
                        >
                        </i>
                        <input
                            *ngIf="displaySearchFilesInput"
                            @fadeIn
                            class="search-input"
                            [class.desktop]="isDesktop"
                            id="searchInput-{{ componentId }}"
                            (input)="searchFiles($event)"
                            type="text"
                            maxlength="249"
                            placeholder="Search"
                        />
                        <i
                            id="searchClose-{{ componentId }}"
                            *ngIf="displaySearchFilesInput && isSearching"
                            (click)="clearSearch()"
                            @rotateInOut
                            class="fas fa-times search-close"
                        >
                        </i>
                    </div>
                    <!-- space between inputs desktop only -->
                    <div class="desktop-space" *ngIf="isDesktop"></div>
                    <!-- * BOTTOM BUTTONS REORDERED FOR DESKTOP VIEW HERE * -->
                    <div
                        *ngIf="isDesktop"
                        [class.desktop]="isDesktop"
                        class="bottom-buttons-group-right"
                    >
                        <!-- disabled for intake -->
                        <button
                            *ngIf="
                                !isDuplicateFile &&
                                !isFileUploading &&
                                !isFolderSubmitting &&
                                !displayCreateFolderForm &&
                                componentId !== 'intake-ao' &&
                                componentId !== 'intake-io'
                            "
                            type="button"
                            (click)="showCreateFolderForm()"
                            class="
                                fs-button
                                bottom-buttons
                                ms-1
                                create-folder-button
                            "
                            @fadeIn
                            title="create folders"
                        >
                            <i class="fas fa-folder-plus fs-icon"></i>
                        </button>
                        <!-- create folder form -->
                        <span
                            *ngIf="isFolderSubmitting"
                            @fadeInFast
                            class="fs-loading fs-desktop-loading"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Creating folder...
                        </span>
                        <!--  -->
                        <form
                            *ngIf="
                                !isFileUploading &&
                                !isDuplicateFile &&
                                !isFolderSubmitting &&
                                displayCreateFolderForm
                            "
                            [formGroup]="newFolder"
                            [class.desktop]="isDesktop"
                            class="create-folder-form"
                        >
                            <!-- *  -->
                            <input
                                (blur)="showCreateFolderForm()"
                                (keyup.enter)="createFolderOnEnter()"
                                @fadeIn
                                id="createFolderInput-{{ componentId }}"
                                type="text"
                                name="fName"
                                formControlName="fName"
                                class="create-folder-input"
                                [class.desktop]="isDesktop"
                                maxlength="249"
                                placeholder="Folder Name"
                            />
                            <button
                                *ngIf="!enteredSharedFolder"
                                [disabled]="newFolder.invalid"
                                [class.disabled]="newFolder.invalid"
                                @slideInRight
                                type="button"
                                title="create folder"
                                (mousedown)="_createFolder()"
                                [class.desktop]="isDesktop"
                                class="create-folder-submit-button"
                            >
                                <i
                                    class="
                                        fas fa-folder{{
                                        newFolder.invalid ? '' : '-plus'
                                    }}
                                        create-folder-icon
                                        fs-icon
                                    "
                                ></i>
                            </button>
                            <button
                                *ngIf="!channelId && currentFolderArray.length == 1"
                                [disabled]="newFolder.invalid"
                                [class.disabled]="newFolder.invalid"
                                @slideInRight
                                type="button"
                                title="create shared folder"
                                (mousedown)="_createSharedFolder2()"
                                [class.desktop]="isDesktop"
                                class="create-folder-submit-button"
                            >
                                <i
                                class="
                                    fas fa-folder-user{{
                                    newFolder.invalid ? '' : ''
                                }}
                                    create-folder-icon
                                    fs-icon
                                "
                                ></i>
                            </button>
                        </form>
                        <span
                            *ngIf="isFileUploading"
                            @fadeInFast
                            class="fs-loading fs-desktop-loading"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Uploading file...
                        </span>
                        <!--  -->
                        <div 
                            *ngIf="hasMaxedOutStorage" 
                            (click)="storageMaxedOutWarn()"
                            class="file-upload-button fs-button bottom-buttons">
                            <i class="fad fa-exclamation-triangle"></i>
                        </div>
                        <div
                            *ngIf="
                                !isFileUploading &&
                                !isFolderSubmitting &&
                                !isDuplicateFile &&
                                !hasMaxedOutStorage
                            "
                            class="file-upload-button fs-button bottom-buttons"
                            @fadeIn
                            title="upload file"
                        >
                            <!--        [(ngModel)]="uploadFile.fileURL" -->
                            <input
                                type="file"
                                name="file"
                                class="file-upload-input"
                                id="file-upload-{{ componentId }}"
                                title="Upload File"
                                [disabled]="isFileUploading"
                                (click)="selectedFile = null"
                                (change)="fileUploadReader($event)"
                            />
                            <i class="fas fa-cloud-upload fs-icon"></i>
                        </div>
                    </div>
                    <!-- -->
                </div>
                <!-- DISPLAY FOR CURRENT FILES -->
                <!-- ✨ check pre-join class for tablet issue ................................................................................................. -->
                <div
                    class="row file-display-wrap"
                    [class.portable]="isPortable"
                    [class.desktop]="isDesktop"
                    [class.channel-pre-join]="!isPortable && channelId"
                    [class.portable-post-join]="isPortable && channelId"
                >
                    <!-- table view DESKTOP & User Files ONLY -->
                    <div *ngIf="isDesktop && !channelId" class="row fs-table-head">
                        <div class="fs-table-title fs-table-name">Name</div>
                        <div class="fs-table-owners">Shared With</div>
                        <div class="fs-table-title">Created by</div>
                        <div class="fs-table-title">Last Modified</div>
                        <div class="fs-table-title fs-table-size">Size</div>
                        <div
                            style="justify-content: center"
                            class="fs-table-title"
                        >
                            Actions
                        </div>
                    </div>
                    <!-- table view DESKTOP & Channel Files ONLY -->
                    <div *ngIf="isDesktop && channelId" class="row fs-table-head">
                        <div class="fs-table-title fs-table-name">Name</div>
                        <div class="fs-table-title">Created by</div>
                        <div class="fs-table-title">Last Modified</div>
                        <div class="fs-table-title fs-table-size">Size</div>
                        <div
                            style="justify-content: center"
                            class="fs-table-title"
                        >
                            Actions
                        </div>
                    </div>
                    <div
                        (scroll)="scrollCheck()"
                        id="fileScrollbox-{{ componentId }}"
                        class="col file-display scrollbar-new"
                        [class.desktop]="isDesktop"
                        [class.intake]="componentId === 'intake-io' || componentId === 'intake-ao'"
                        [@listAnimationWrap]="filesList.length"
                    >
                        <!-- messages -->
                        <div
                            *ngIf="
                                !isPreLoad &&
                                !isSearching &&
                                !isFilesLoading &&
                                !isDuplicateFile &&
                                filesList.length === 0
                            "
                            @fadeInFast
                            [class.desktop]="isDesktop"
                            class="file-display-message-warn"
                        >
                            This folder is empty.
                        </div>
                        <div
                            *ngIf="
                                !isPreLoad &&
                                isSearching &&
                                !isFilesLoading &&
                                filesList.length === 0
                            "
                            @fadeInFast
                            [class.desktop]="isDesktop"
                            class="file-display-message"
                        >
                            No match found in this folder.
                        </div>
                        <div
                            *ngIf="
                                !isPreLoad && !isFilesLoading && isDuplicateFile
                            "
                            @fadeInFast
                            [class.desktop]="isDesktop"
                            class="file-display-message-warn"
                        >
                            A file with this name already exist in this folder,
                            would you like to replace it ?
                            <!--  -->
                            <div class="duplicate-file-wrap">
                                <button
                                    (click)="handleDuplicateFile(true)"
                                    [class.desktop]="isDesktop"
                                    title="replace file"
                                    class="fs-button duplicate-file-btn"
                                >
                                    REPLACE
                                </button>
                                <button
                                    (click)="handleDuplicateFile(false)"
                                    [class.desktop]="isDesktop"
                                    title="back"
                                    class="fs-button duplicate-file-btn"
                                >
                                    BACK
                                </button>
                            </div>
                        </div>
                        <!-- files loading spinner -->
                        <!--  -->
                        <div
                            *ngIf="!isPreLoad && isFilesLoading"
                            @fadeInFast
                            [class.desktop]="isDesktop"
                            class="m-auto">
                            <loading-home></loading-home>
                        </div>
                        <!-- end messages -->
                        <!-- *** -->
                        <!-- each file in result -->
                        <span
                            *ngIf="!isFilesLoading"
                        >
                        <div
                            *ngFor="
                                let file of filesList;
                                index as i;
                                last as last
                            "
                            @listAnimationItem
                            (click)="selectFile(i)"
                            (dblclick)="openFolder(file.isFile, file.chatRoomChatUsers)"
                            class="row file-item selected-file"
                            [class.desktop]="isDesktop"
                            [class.mobile]="
                                selectedFile === filesList[i] && !isDesktop
                            "
                        >
                            <!--  -->
                            <!-- File Name column DESKTOP & MOBILE -->
                            <div
                                title="{{ file.urlPath }}"
                                class="file-name"
                                [ngClass]="{
                                    'fs-table-info-col fs-table-name': isDesktop
                                }"
                            >
                                <!--  -->
                                <i
                                    *ngIf="file.isFile"
                                    class="fad fa-file-alt file-icon"
                                ></i>
                                <i
                                    *ngIf="!file.isFile && !file.rev"
                                    class="fas fa-folder folder-icon"
                                ></i>
                                <i
                                *ngIf="!file.isFile && file.rev"
                                class="fas fa-folder-user folder-icon-shared"
                                ></i>
                                &nbsp;
                                <span *ngIf="file.isFile" class="file-name">
                                    {{ file.fileName }}
                                </span>
                                <span *ngIf="!file.isFile" class="file-name">
                                    {{ file.fileName | folderName }}
                                </span>
                            </div>
                            <!-- DESKTOP VIEW ONLY TABLE -->
                            <!-- Owners / Shared col -->
                            <div
                            *ngIf="isDesktop"
                            class="fs-table-info-col owners-col"
                            >
                                <!-- owner AVITARS -->
                                <ng-container
                                    *ngIf="
                                        file.chatRoomChatUsers &&
                                        file.chatRoomChatUsers.allChannelUsers &&
                                        file.chatRoomChatUsers.allChannelUsers.length > 0
                                    "
                                >
                                    <div
                                        class="owners-wrap"
                                        [class.selected-folder-img]="
                                            selectedFile === filesList[i]
                                        "
                                        (click)="toggleAddOwnerModal()"
                                        (dblclick)="preventFileDblClick($event)"
                                    >
                                        <div
                                            class="owner-length"
                                            title="{{
                                                file.chatRoomChatUsers.allChannelUsers.length
                                            }} total owners"
                                        >
                                            {{ file.chatRoomChatUsers.allChannelUsers.length }}
                                        </div>
                                        <ng-container
                                            *ngFor="
                                                let user of file.chatRoomChatUsers.allChannelUsers;
                                                let i = index
                                            "
                                        >
                                            <div
                                                class="user-img"
                                                title="{{
                                                    user                                                       .displayName
                                                }}"
                                            >
                                                <!--  -->
                                                <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                                                <img
                                                    src="https://profile-images.azureedge.us/{{
                                                        user.hash
                                                    }}"
                                                    alt="user"
                                                />
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <!-- Created by col -->
                            <div
                                *ngIf="isDesktop"
                                title="{{
                                    file.chatUserKeyNavigation.username
                                }}"
                                class="fs-table-info-col"
                            >
                                {{ file.chatUserKeyNavigation.displayName }}
                            </div>
                            <!-- Last modified col -->
                            <div
                                *ngIf="isDesktop"
                                class="fs-table-info-col"
                            >
                                {{ file.isFile ? (file.lastUpdated + "Z" | date: "M/d/yy h:mm a") : '-'}}
                            </div>
                            <!-- size col -->
                            <div
                                *ngIf="isDesktop && file.isFile"
                                class="fs-table-info-col fs-table-size"
                            >
                                {{ file.size | byteConvert }}
                            </div>
                            <div
                                *ngIf="isDesktop && !file.isFile"
                                class="fs-table-info-col fs-table-size"
                            >
                                -
                            </div>
                            <!-- options col -->
                            <div
                                *ngIf="isDesktop"
                                class="fs-table-info-col fs-table-options"
                            >
                                <!-- * these buttons will be conditionally rendered if item is selected * -->
                                <div
                                    *ngIf="selectedFile === filesList[i]"
                                    [class.desktop]="isDesktop"
                                    class="bottom-buttons-group-right"
                                >
                                    <!--  -->
                                    <span
                                        *ngIf="isDownloading"
                                        @fadeInFast
                                        class="fs-loading fs-desktop-loading"
                                    >
                                        <i class="fas fa-spinner spinner"></i>
                                        &nbsp;Downloading...
                                    </span>
                                    <button
                                        *ngIf="
                                            selectedFile.isFile &&
                                            !isDeleting &&
                                            !isDownloading &&
                                            !isRenameSubmitting &&
                                            !displayRenameFileForm
                                        "
                                        (click)="downloadFile($event)"
                                        (dblclick)="preventFileDblClick($event)"
                                        class="fs-button bottom-buttons"
                                        @fadeIn
                                        title="download"
                                    >
                                        <i
                                            class="
                                                fas
                                                fa-cloud-download
                                                fs-icon
                                            "
                                        ></i>
                                    </button>
                                    <!-- Add Owner -->
                                    <span
                                    *ngIf="isAddingOwnerMember"
                                    @fadeInFast
                                    class="fs-loading fs-mobile-loading-right"
                                    >
                                    <i class="fas fa-spinner spinner"></i>
                                    &nbsp;Adding...
                                        </span>
                                        <button
                                            *ngIf="
                                                !isSharedFolderMember &&
                                                !selectedFile.isFile &&
                                                selectedFile.rev &&
                                                !isDeleting &&
                                                !isDownloading &&
                                                !isFileUploading &&
                                                !isAddingOwnerMember &&
                                                !isRemovingOwnerMember &&
                                                !isSharing &&
                                                !isUnSharing &&
                                                !isRenameSubmitting &&
                                                !displayRenameFileForm
                                            "
                                            (click)="toggleAddOwnerModal()"
                                            (dblclick)="preventFileDblClick($event)"
                                            class="fs-button bottom-buttons"
                                            @fadeIn
                                            title="add user(s)"
                                        >
                                    <i class="fas fa-user-plus fs-icon"></i>
                                </button>
                                    <button
                                        *ngIf="
                                            !selectedFile.isFile &&
                                            !isDeleting &&
                                            !isDownloading &&
                                            !isRenameSubmitting &&
                                            !displayRenameFileForm
                                        "
                                        (click)="
                                            openFolder(selectedFile.isFile, selectedFile.chatRoomChatUsers)
                                        "
                                        (dblclick)="preventFileDblClick($event)"
                                        class="fs-button bottom-buttons"
                                        @fadeIn
                                        title="open22"
                                    >
                                        <i
                                            class="fas fa-arrow-right fs-icon"
                                        ></i>
                                    </button>
                                    <!-- rename -->
                                    <span
                                        *ngIf="isRenameSubmitting"
                                        @fadeInFast
                                        class="fs-loading fs-desktop-loading"
                                    >
                                        <i class="fas fa-spinner spinner"></i>
                                        &nbsp;Renaming...
                                    </span>
                                    <ng-container *ngIf="isPortable && !isSidebar || (!isPortable && !!channelId)">
                                        <button
                                            *ngIf="
                                                !isSharedFolderMember &&
                                                !isDeleting &&
                                                !isDownloading &&
                                                !isRenameSubmitting &&
                                                !displayRenameFileForm &&
                                                (isOwner || componentId === 'intake-io')
                                            "
                                            (click)="
                                                showRenameFileInput($event)
                                            "
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="fs-button bottom-buttons"
                                            @fadeIn
                                            title="rename"
                                        >
                                            <i
                                                class="fas fa-file-edit fs-icon"
                                            ></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="(!isPortable && !channelId) || (isPortable && isSidebar)">
                                        <button
                                            *ngIf="
                                                !isSharedFolderMember &&
                                                !isDeleting &&
                                                !isDownloading &&
                                                !isRenameSubmitting &&
                                                !displayRenameFileForm
                                            "
                                            (click)="
                                                showRenameFileInput($event)
                                            "
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="fs-button bottom-buttons"
                                            @fadeIn
                                            title="rename"
                                        >
                                            <i
                                                class="fas fa-file-edit fs-icon"
                                            ></i>
                                        </button>
                                    </ng-container>
                                    <!-- rename form -->
                                    <form
                                        *ngIf="
                                            !isDeleting &&
                                            !isDownloading &&
                                            !isRenameSubmitting &&
                                            displayRenameFileForm
                                        "
                                        [formGroup]="renameFileForm"
                                        @fadeIn
                                        [class.desktop]="isDesktop"
                                        class="rename-form"
                                    >
                                        <!-- is folder -->
                                        <!--  -->
                                        <input
                                            *ngIf="
                                                displayRenameFileForm &&
                                                !selectedFile.isFile
                                            "
                                            (keyup.enter)="renameFolder()"
                                            (blur)="showRenameFileInput()"
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="rename-input"
                                            [class.desktop]="isDesktop"
                                            id="renameInput-{{ componentId }}"
                                            type="text"
                                            name="fName"
                                            formControlName="fName"
                                            value="{{
                                                selectedFile.fileName
                                                    | folderName
                                            }}"
                                            maxlength="249"
                                            placeholder="Rename folder"
                                        />
                                        <button
                                            *ngIf="
                                                displayRenameFileForm &&
                                                !selectedFile.isFile
                                            "
                                            [disabled]="renameFileForm.invalid"
                                            (mousedown)="renameFolder()"
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            title="rename folder"
                                            class="rename-submit-button"
                                        >
                                            <i
                                                *ngIf="
                                                    displayRenameFileForm &&
                                                    !selectedFile.isFile &&
                                                    renameFileForm.valid
                                                "
                                                @rotateInOut
                                                class="
                                                    far
                                                    fa-check-circle
                                                    rename-submit-icon
                                                "
                                            >
                                            </i>
                                        </button>
                                        <!-- is file -->
                                        <!--  -->
                                        <input
                                            *ngIf="
                                                displayRenameFileForm &&
                                                selectedFile.isFile
                                            "
                                            (keyup.enter)="renameFile()"
                                            (blur)="showRenameFileInput()"
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="rename-input"
                                            [class.desktop]="isDesktop"
                                            id="renameInput-{{ componentId }}"
                                            type="text"
                                            name="fName"
                                            formControlName="fName"
                                            value="{{
                                                selectedFile.fileName | fileName
                                            }}"
                                            maxlength="249"
                                            placeholder="Rename file"
                                        />
                                        <button
                                            *ngIf="
                                                displayRenameFileForm &&
                                                selectedFile.isFile
                                            "
                                            [disabled]="renameFileForm.invalid"
                                            (mousedown)="renameFile()"
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            title="rename file"
                                            class="rename-submit-button"
                                        >
                                            <i
                                                *ngIf="
                                                    displayRenameFileForm &&
                                                    selectedFile.isFile &&
                                                    renameFileForm.valid
                                                "
                                                @rotateInOut
                                                class="
                                                    far
                                                    fa-check-circle
                                                    rename-submit-icon
                                                "
                                            >
                                            </i>
                                        </button>
                                    </form>
                                    <!-- delete -->
                                    <span
                                        *ngIf="isDeleting"
                                        @fadeInFast
                                        class="
                                            fs-loading fs-desktop-loading
                                            deleting
                                        "
                                    >
                                        <i
                                            class="
                                                fas
                                                fa-spinner
                                                spinner
                                                deleting
                                            "
                                        ></i>
                                        &nbsp;Deleting...
                                    </span>
                                    <ng-container *ngIf="isPortable && !isSidebar || (!isPortable && !!channelId)">
                                        <button
                                            *ngIf="
                                                !isSharedFolderMember &&
                                                !isDeleting &&
                                                !isDownloading &&
                                                !isRenameSubmitting &&
                                                !displayRenameFileForm &&
                                                isOwner
                                            "
                                            (click)="
                                                deleteConfirm($event)
                                            "
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="fs-button bottom-buttons"
                                            @fadeIn
                                            [title]="isSharedFolderCreator ? 'remove' : 'delete'"
                                        >
                                            <i
                                                class="fas fa-trash-alt fs-icon"
                                            ></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="(!isPortable && !channelId) || (isPortable && isSidebar) || componentId === 'intake-io'">
                                        <button
                                            *ngIf="
                                                !isSharedFolderMember &&
                                                !isDeleting &&
                                                !isDownloading &&
                                                !isRenameSubmitting &&
                                                !displayRenameFileForm
                                            "
                                            (click)="
                                                deleteConfirm($event)
                                            "
                                            (dblclick)="
                                                preventFileDblClick($event)
                                            "
                                            class="fs-button bottom-buttons"
                                            @fadeIn
                                            [title]="isSharedFolderCreator ? 'remove' : 'delete'"
                                        >
                                            <i
                                                class="fas fa-trash-alt fs-icon"
                                            ></i>
                                        </button>
                                    </ng-container>
                                    <!-- v2.1 feat. -->
                                    <!-- <button (click)="shareFile($event)" class="fs-button bottom-buttons" title="share">
                                        
                                    </button> -->
                                    <!-- <button (click)="moveFile($event)" class="fs-button bottom-buttons" title="move to">
                                        
                                    </button> -->
                                    <!--  -->
                                </div>
                            </div>
                            <!-- * 💚 END DESKTOP VIEW -->
                            <!-- * 💚 MOBILE VIEW -->
                            <!-- to display drop-down of file information MOBILE ONLY -->
                            <div
                                *ngIf="!isDesktop"
                                class="ms-auto file-info-wrap"
                                (click)="showFileInfo(i, $event)"
                                (mouseleave)="closeFileInfo(i, $event)"
                                (dblclick)="preventFileDblClick($event)"
                            >
                                <!-- info icon -->
                                <i
                                    [title]="
                                        file.isFile
                                            ? 'file information'
                                            : 'folder information'
                                    "
                                    class="far fa-info-circle file-info-icon"
                                ></i>
                                <!-- info dropdown for files -->
                                <div
                                    *ngIf="file.isFile"
                                    id="file-info-{{ i }}-{{ componentId }}"
                                    class="file-info-display"
                                    (click)="closeFileInfo(i, $event)"
                                    (dblclick)="preventFileDblClick($event)"
                                >
                                    <!--  -->
                                    <div
                                        title="{{ file.fileName }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >File:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{ file.fileName }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{ file.urlPath }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Path:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{ file.urlPath }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{ file.size | byteConvert }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Size:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{ file.size | byteConvert }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{
                                            file.created + 'Z'
                                                | date: 'M/d/yy h:mm a'
                                        }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Uploaded:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{
                                                file.created + "Z"
                                                    | date: "M/d/yy h:mm a"
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{
                                            file.chatUserKeyNavigation
                                                .displayName
                                        }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Uploaded By:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{
                                                file.chatUserKeyNavigation
                                                    .displayName
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{
                                            file.lastUpdated + 'Z'
                                                | date: 'M/d/yy h:mm a'
                                        }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Last Modified:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{
                                                file.lastUpdated + "Z"
                                                    | date: "M/d/yy h:mm a"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!-- info dropdown for folders -->
                                <div
                                    *ngIf="!file.isFile"
                                    id="file-info-{{ i }}-{{ componentId }}"
                                    class="file-info-display"
                                    (click)="closeFileInfo(i, $event)"
                                    (dblclick)="preventFileDblClick($event)"
                                >
                                    <!--  -->
                                    <div
                                        title="{{ file.fileName | folderName }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Name:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{ file.fileName | folderName }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{ file.urlPath }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Path:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{ file.urlPath }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{
                                            file.created + 'Z'
                                                | date: 'M/d/yy h:mm a'
                                        }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Created:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{
                                                file.created + "Z"
                                                    | date: "M/d/yy h:mm a"
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        title="{{
                                            file.chatUserKeyNavigation
                                                .displayName
                                        }}"
                                        class="file-info-text"
                                    >
                                        <span class="file-info-title"
                                            >Created By:</span
                                        >&nbsp;
                                        <div class="info-text">
                                            {{
                                                file.chatUserKeyNavigation
                                                    .displayName
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </span>
                        <!-- case for pagination loading taking too long -->
                        <div
                            *ngIf="isGettingNextPage && !isFilesLoading"
                            [class.desktop]="isDesktop"
                            class="file-display-message border-none"
                        >
                            <span class="fs-loading">
                                <i class="fas fa-spinner spinner"></i>
                                &nbsp;Loading more files...
                            </span>
                        </div>
                        <!-- extra bottom space for mobile -->
                        <div
                            *ngIf="!isDesktop"
                            class="file-display-bottom-space-mobile"
                        >
                            &nbsp;
                        </div>
                    </div>
                    <!-- 🚧🚧🚧 new storage capacity meter -->
                    <div *ngIf="isDesktop && !hasUnlimitedStorage && showStorageBar" class="storage-capacity desktop mt-1">
                        <!--  -->
                        <p>Storage</p>
                        <div class="bar-outer">
                            <div #storageBarEl class="bar-inner"></div>
                        </div>
                        <p 
                            *ngIf="channelId;else personal">
                            {{fileStorageUsage.organizationStorageUsage|byteConvert}} 
                            of 
                            {{fileStorageUsage.organizationStorageSize|byteConvert}}
                            used
                        </p>
                        <ng-template #personal>
                            <p>
                                {{fileStorageUsage.personalStorageUsage|byteConvert}} 
                                of 
                                {{fileStorageUsage.personalStorageSize|byteConvert}}
                                used
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="isDesktop && hasUnlimitedStorage" class="storage-capacity desktop mt-1">
                        <p 
                            *ngIf="channelId;else personal">
                            {{fileStorageUsage.organizationStorageUsage|byteConvert}} 
                            used
                        </p>
                        <ng-template #personal>
                            <p>
                                {{fileStorageUsage.personalStorageUsage|byteConvert}} 
                                used
                            </p>
                        </ng-template>
                    </div>
                </div>
                <!-- end file display wrap -->
                <!-- BOTTOM BUTTONS ROW MOBILE -->
                <div *ngIf="!isDesktop" class="row bottom-row-mobile">
                    <!--  -->
                    <div
                        *ngIf="!isDesktop"
                        [class.create-folder-open]="displayCreateFolderForm"
                        class="bottom-buttons-group-left"
                    >
                    
                        <!-- file upload start -->
                        <span
                            *ngIf="isFileUploading"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-left"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Uploading file...
                        </span>
                        <div 
                            *ngIf="hasMaxedOutStorage" 
                            (click)="storageMaxedOutWarn()"
                            class="file-upload-button fs-button bottom-buttons">
                            <i class="fad fa-exclamation-triangle"></i>
                        </div>
                        <div
                            *ngIf="
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isDuplicateFile &&
                                !isRenameSubmitting &&
                                !isFolderSubmitting &&
                                !hasMaxedOutStorage
                            "
                            class="file-upload-button fs-button bottom-buttons"
                            @fadeIn
                            title="upload file"
                        >
                            <!-- [(ngModel)]="uploadFile.fileURL" -->
                            <input
                                type="file"
                                name="file"
                                class="file-upload-input"
                                id="file-upload-{{ componentId }}"
                                title="Upload File"
                                [disabled]="isFileUploading"
                                (click)="selectedFile = null"
                                (change)="fileUploadReader($event)"
                            />
                            <i class="fas fa-cloud-upload fs-icon"></i>
                        </div>
                        <!-- file upload end -->
                        <!-- open create folder form -->
                        <!-- disabled for intake -->
                        <button
                            *ngIf="
                                !enteredSharedFolder &&
                                !isDeleting &&
                                !isDownloading &&
                                !isDuplicateFile &&
                                !isFileUploading &&
                                !isFolderSubmitting &&
                                !isRenameSubmitting &&
                                !displayCreateFolderForm &&
                                componentId !== 'intake-ao' &&
                                componentId !== 'intake-io'
                            "
                            type="button"
                            (click)="showCreateFolderForm()"
                            class="
                                fs-button
                                bottom-buttons
                                ms-1
                                create-folder-button
                            "
                            @fadeIn
                            title="create folder"
                        >
                            <i class="fas fa-folder-plus fs-icon"></i>
                        </button>
                        <!-- create folder form -->
                        <span
                            *ngIf="isFolderSubmitting"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-left"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Creating folder...
                        </span>
                        <form
                            *ngIf="
                                !isDeleting &&
                                !isDownloading &&
                                !isDuplicateFile &&
                                !isFileUploading &&
                                !isFolderSubmitting &&
                                displayCreateFolderForm
                            "
                            [formGroup]="newFolder"
                            [class.desktop]="isDesktop"
                            class="create-folder-form"
                        >
                            <!-- Close folder creation button  -->
                            <button
                                [disabled]="newFolder.invalid"
                                [class.disabled]="newFolder.invalid"
                                type="button"
                                title="Cancel New Folder"
                                [class.desktop]="isDesktop"
                                class="create-folder-submit-button"
                            >
                                <!-- <i
                                    class="
                                        fas fa-folder{{
                                        newFolder.invalid ? '' : '-plus'
                                    }}
                                        create-folder-icon
                                        fs-icon
                                    "
                                ></i> -->
                                <i
                                    class="fas fa-xmark
                                        create-folder-icon-exit
                                        fs-icon
                                    "
                                ></i>
                            </button>
                            <!-- *  -->
                            <input
                                (blur)="showCreateFolderForm()"
                                (keyup.enter)="createFolderOnEnter()"
                                id="createFolderInput-{{ componentId }}"
                                type="text"
                                name="fName"
                                formControlName="fName"
                                class="create-folder-input"
                                [class.desktop]="isDesktop"
                                @fadeIn
                                maxlength="249"
                                placeholder="Folder Name"
                            />
                            <!-- Create Folder Buttons on Left Panel Footer -->
                            <span
                                *ngIf="
                                !newFolder.invalid && 
                                !restrictSharedFolderActions
                            "
                             >
                            <button
                                *ngIf="!enteredSharedFolder"
                                [disabled]="newFolder.invalid"
                                [class.disabled]="newFolder.invalid"
                                type="button"
                                title="create folder"
                                (mousedown)="_createFolder()"
                                @slideInRight
                                [class.desktop]="isDesktop"
                                class="create-folder-submit-button"
                            >
                                <i
                                    class="
                                        fas fa-folder{{
                                        newFolder.invalid ? '' : '-plus'
                                    }}
                                        create-folder-icon-left-panel
                                        fs-icon
                                    "
                                ></i>
                            </button>
                            <!-- Create Shared Folder-->
                            <button
                                *ngIf="currentFolderArray.length === 1"
                                [disabled]="newFolder.invalid"
                                [class.disabled]="newFolder.invalid"
                                type="button"
                                title="create shared folder"
                                (mousedown)="_createSharedFolder2()"
                                @slideInRight
                                [class.desktop]="isDesktop"
                                class="create-folder-submit-button"
                            >
                                <i
                                    class="
                                        fas fa-folder{{
                                        newFolder.invalid ? '' : '-user'
                                    }}
                                        create-folder-icon-left-panel
                                        fs-icon
                                    "
                                ></i>
                            </button>
                            </span>
                        </form>
                    </div>
                    <!-- * these buttons will be rendered if item is selected * -->
                    <div
                        *ngIf="selectedFile"
                        [class.desktop]="isDesktop"
                        class="bottom-buttons-group-right"
                    >
                        <!-- download -->
                        <span
                            *ngIf="isDownloading"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-right"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Downloading...
                        </span>
                        <button
                            *ngIf="
                                selectedFile.isFile &&
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isRenameSubmitting &&
                                !displayRenameFileForm
                            "
                            (click)="downloadFile()"
                            class="fs-button bottom-buttons"
                            @fadeIn
                            title="download"
                        >
                            <i class="fas fa-cloud-download fs-icon"></i>
                        </button>
                        <!-- copy -->
                        <!--<span
                            *ngIf="isDownloading"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-right"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Copying...
                        </span> -->
                        <!-- <button
                            *ngIf="
                                selectedFile.isFile &&
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isRenameSubmitting &&
                                !displayRenameFileForm
                            "
                            (click)="copyFile()"
                            class="fs-button bottom-buttons"
                            @fadeIn
                            title="copy"
                        >
                            <i class="fas fa-copy fs-icon"></i>
                        </button> -->
                        <!-- Add Owner -->
                        <span
                            *ngIf="isAddingOwnerMember"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-right"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Adding...
                        </span>
                        <button
                            *ngIf="
                                !isSharedFolderMember &&
                                !selectedFile.isFile &&
                                selectedFile.rev &&
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isAddingOwnerMember &&
                                !isRemovingOwnerMember &&
                                !isSharing &&
                                !isUnSharing &&
                                !isRenameSubmitting &&
                                !displayRenameFileForm
                            "
                            (click)="toggleAddOwnerModal()"
                            (dblclick)="preventFileDblClick($event)"
                            class="fs-button bottom-buttons"
                            @fadeIn
                            title="add user(s)"
                        >
                            <i class="fas fa-user-plus fs-icon"></i>
                        </button>
                        <button
                            *ngIf="
                                !selectedFile.isFile &&
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isRenameSubmitting &&
                                !displayRenameFileForm
                            "
                            (click)="openFolder(selectedFile.isFile, selectedFile.chatRoomChatUsers)"
                            class="fs-button bottom-buttons"
                            @fadeIn
                            title="open"
                        >
                            <i class="fas fa-arrow-right fs-icon"></i>
                        </button>
                        <!-- rename -->
                        <span
                            *ngIf="isRenameSubmitting"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-right"
                        >
                            <i class="fas fa-spinner spinner"></i>
                            &nbsp;Renaming...
                        </span>
                        <ng-container *ngIf="isPortable && !isSidebar || (!isPortable && !!channelId)">
                            <button
                                *ngIf="
                                    !isSharedFolderMember &&
                                    !isDeleting &&
                                    !isDownloading &&
                                    !isFileUploading &&
                                    !isRenameSubmitting &&
                                    !displayRenameFileForm &&
                                    (isOwner || componentId === 'intake-io')
                                "
                                (click)="showRenameFileInput()"
                                class="fs-button bottom-buttons"
                                @fadeIn
                                title="rename"
                            >
                                <i class="fas fa-file-edit fs-icon"></i>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="(!isPortable && !channelId) || (isPortable && isSidebar)">
                            <button
                                *ngIf="
                                    !isSharedFolderMember &&
                                    !isDeleting &&
                                    !isDownloading &&
                                    !isFileUploading &&
                                    !isRenameSubmitting &&
                                    !displayRenameFileForm
                                "
                                (click)="showRenameFileInput()"
                                class="fs-button bottom-buttons"
                                @fadeIn
                                title="rename"
                            >
                                <i class="fas fa-file-edit fs-icon"></i>
                            </button>
                        </ng-container>
                        <form
                            *ngIf="
                                !isDeleting &&
                                !isDownloading &&
                                !isFileUploading &&
                                !isRenameSubmitting &&
                                displayRenameFileForm
                            "
                            [formGroup]="renameFileForm"
                            @fadeIn
                            [class.desktop]="isDesktop"
                            class="rename-form"
                        >
                            <!-- is folder -->
                            <!--  -->
                            <input
                                *ngIf="
                                    displayRenameFileForm &&
                                    !selectedFile.isFile
                                "
                                (keyup.enter)="renameFolder()"
                                (blur)="showRenameFileInput()"
                                class="rename-input"
                                [class.desktop]="isDesktop"
                                id="renameInput-{{ componentId }}"
                                type="text"
                                name="fName"
                                formControlName="fName"
                                value="{{ selectedFile.fileName | folderName }}"
                                maxlength="249"
                                placeholder="Rename folder"
                            />
                            <button
                                *ngIf="
                                    displayRenameFileForm &&
                                    !selectedFile.isFile
                                "
                                [disabled]="renameFileForm.invalid"
                                (mousedown)="renameFolder()"
                                title="rename folder"
                                class="rename-submit-button"
                            >
                                <i
                                    *ngIf="
                                        displayRenameFileForm &&
                                        !selectedFile.isFile &&
                                        renameFileForm.valid
                                    "
                                    @rotateInOut
                                    class="
                                        far
                                        fa-check-circle
                                        rename-submit-icon
                                    "
                                >
                                </i>
                            </button>
                            <!-- is file -->
                            <!--  -->
                            <input
                                *ngIf="
                                    displayRenameFileForm && selectedFile.isFile
                                "
                                (keyup.enter)="renameFile()"
                                (blur)="showRenameFileInput()"
                                class="rename-input"
                                [class.desktop]="isDesktop"
                                id="renameInput-{{ componentId }}"
                                type="text"
                                name="fName"
                                formControlName="fName"
                                value="{{ selectedFile.fileName | fileName }}"
                                maxlength="249"
                                placeholder="Rename file"
                            />
                            <button
                                *ngIf="
                                    displayRenameFileForm && selectedFile.isFile
                                "
                                [disabled]="renameFileForm.invalid"
                                (mousedown)="renameFile()"
                                title="rename file"
                                class="rename-submit-button"
                            >
                                <i
                                    *ngIf="
                                        displayRenameFileForm &&
                                        selectedFile.isFile &&
                                        renameFileForm.valid
                                    "
                                    @rotateInOut
                                    class="
                                        far
                                        fa-check-circle
                                        rename-submit-icon
                                    "
                                >
                                </i>
                            </button>
                        </form>
                        <!-- end rename -->
                        <!-- delete -->
                        <span
                            *ngIf="isDeleting"
                            @fadeInFast
                            class="fs-loading fs-mobile-loading-right deleting"
                        >
                            <i class="fas fa-spinner spinner deleting"></i>
                            &nbsp;Deleting...
                        </span>
                        <ng-container *ngIf="isPortable && !isSidebar || (!isPortable && !!channelId)">
                            <button
                                *ngIf="
                                    !isSharedFolderMember &&
                                    !isDeleting &&
                                    !isDownloading &&
                                    !isFileUploading &&
                                    !isRenameSubmitting &&
                                    !displayRenameFileForm &&
                                    isOwner
                                "
                                (click)="deleteConfirm()"
                                class="fs-button bottom-buttons"
                                @fadeIn
                                [title]="isSharedFolderCreator ? 'delete' : 'remove'"
                            >
                                <i class="fas fa-trash-alt fs-icon"></i>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="(!isPortable && !channelId) || (isPortable && isSidebar) || componentId === 'intake-io'">
                            <button
                                *ngIf="
                                    !isSharedFolderMember &&
                                    !isDeleting &&
                                    !isDownloading &&
                                    !isFileUploading &&
                                    !isRenameSubmitting &&
                                    !displayRenameFileForm
                                "
                                (click)="deleteConfirm()"
                                class="fs-button bottom-buttons"
                                @fadeIn
                                [title]="isSharedFolderCreator ? 'remove' : 'delete'"
                            >
                                <i class="fas fa-trash-alt fs-icon"></i>
                            </button>
                        </ng-container>
                        <!-- v2.1 feat. -->
                        <!-- <button (click)="shareFile($event)" class="fs-button bottom-buttons" title="share">
                        </button> -->
                        <!-- <button (click)="moveFile($event)" class="fs-button bottom-buttons" title="move to">
                        </button> -->
                        <!-- -->
                    </div>
                </div>
                <!-- 🚧🚧🚧 new storage capacity meter -->
                <div 
                    *ngIf="!isDesktop && !hasUnlimitedStorage && showStorageBar" 
                    class="storage-capacity mobile justify-center"
                    [class.mt-1]="channelId"
                    [class.mt-4]="!channelId">
                    <!--  -->
                    <p>Storage</p>
                    <div 
                        class="bar-outer">
                        <div #storageBarEl class="bar-inner"></div>
                    </div>
                    <p 
                        *ngIf="channelId;else personal">
                        {{fileStorageUsage.organizationStorageUsage|byteConvert}} 
                        of 
                        {{fileStorageUsage.organizationStorageSize|byteConvert}}
                        used
                    </p>
                    <ng-template #personal>
                        <p>
                            {{fileStorageUsage.personalStorageUsage|byteConvert}} 
                            of 
                            {{fileStorageUsage.personalStorageSize|byteConvert}}
                            used
                        </p>
                    </ng-template>
                </div>
                <div *ngIf="!isDesktop && hasUnlimitedStorage" class="storage-capacity mobile justify-center">
                    <p 
                        *ngIf="channelId;else personal">
                        {{fileStorageUsage.organizationStorageUsage|byteConvert}} 
                        used
                    </p>
                    <ng-template #personal>
                        <p>
                            {{fileStorageUsage.personalStorageUsage|byteConvert}} 
                            used
                        </p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  -->

<!-- 🚧 ADD OWNERS MODAL -->
<div
    class="modal"
    *ngIf="displayAddOwnerModal"
    id="add-owner-modal"
    (click)="clickOffModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="toggleAddOwnerModal()"
    >
        <button
            title="close"
            class="close-modal-x"
            (click)="toggleAddOwnerModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <!-- current owner list -->
        <h4 class="mb-2">Current Owners</h4>
        <div
            class="owner-list scrollbar-new mb-4"
            [class.scrollable]="selectedFile.sharedFolderOwners?.length >= 3"
        >
            <!--Folder Creator-->
            <ng-container
                *ngFor="
                    let user of selectedFile.sharedFolderOwners
                    let i = index
                "
            >
                <div class="chat-user" 
                *ngIf="user.channelUserId === selectedFile.chatUserKeyNavigation.channelUserId">
                    <div class="user-img">
                        <!--  -->
                        <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                        <img
                            src="https://profile-images.azureedge.us/{{
                                user.hash
                            }}"
                            alt="user"
                        />
                    </div>
                    <div class="chat-names-wrap">
                        <p
                            class="chat-username"
                            title="{{ user.displayName }}"
                        >
                            {{ user.displayName }}
                        </p>
                        <p
                            class="chat-name"
                            title="{{ user.userName }}"
                        >
                            {{ user.userName }}
                        </p>
                    </div>
               
                    <span
                        class="owner-btn remove"
                        title="Shared Folder Creator"
                    >
                        <i class="far fa-crown"></i>
                </span>
                </div>
            </ng-container>

            <!--Owners-->
            <ng-container
                *ngFor="
                    let user of selectedFile.sharedFolderOwners
                    let i = index
                "
            >
                <div class="chat-user" 
                *ngIf="user.rank !== 'member' && user.channelUserId !== selectedFile.chatUserKeyNavigation.channelUserId">
                    <div class="user-img">
                        <!--  -->
                        <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                        <img
                            src="https://profile-images.azureedge.us/{{
                                user.hash
                            }}"
                            alt="user"
                        />
                    </div>
                    <div class="chat-names-wrap">
                        <p
                            class="chat-username"
                            title="{{ user.displayName }}"
                        >
                            {{ user.displayName }}
                        </p>
                        <p
                            class="chat-name"
                            title="{{ user.userName }}"
                        >
                            {{ user.userName }}
                        </p>
                    </div>
                    <!-- remove owner -->
                    <button
                        class="owner-btn remove"
                        *ngIf="user.channelUserId != selectedFile.chatUserKeyNavigation.channelUserId"
                        (click)="
                            removeOwner(
                                user.channelUserId,
                                user.displayName
                            )
                        "
                        title="Remove Owner"
                    >
                        <i class="far fa-times-circle"></i>
                    </button>

                    <!-- <span
                        *ngIf="user.channelUserId === selectedFile.chatUserKeyNavigation.channelUserId"
                        class="owner-btn remove"
                        title="Shared Folder Creator"
                    >
                        <i class="far fa-crown"></i>
                    </span> -->
                </div>
            </ng-container>
        </div>
        <!-- current Members list -->
        <h4 class="mb-2">Current Members</h4>
        <div
            class="owner-list scrollbar-new mb-4"
            [class.scrollable]="selectedFile.sharedFolderMembers?.length > 3"
        >
            <ng-container
                *ngFor="
                    let user of selectedFile.sharedFolderMembers
                    let i = index
                "
            >
                <div class="chat-user">
                    <div class="user-img">
                        <!--  -->
                        <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                        <img
                            src="https://profile-images.azureedge.us/{{
                                user.hash
                            }}"
                            alt="user"
                        />
                    </div>
                    <div class="chat-names-wrap">
                        <p
                            class="chat-username"
                            title="{{ user.displayName }}"
                        >
                            {{ user.displayName }}
                        </p>
                        <p
                            class="chat-name"
                            title="{{ user.userName }}"
                        >
                            {{ user.userName }}
                        </p>
                    </div>
                    <!-- remove member -->
                    <button
                        class="owner-btn remove"
                        (click)="
                            removeMember(
                                user.channelUserId,
                                user.displayName
                            )
                        "
                        title="Remove Member"
                    >
                        <i class="far fa-times-circle"></i>
                    </button>
                </div>
            </ng-container>
        </div>
        <!-- search for users to add -->
        <h4>Share Folder With...</h4>
        <form class="form-style-4 form-no-borders">
            <div class="form-inputs">
                <label class="label-full search">
                    <button (click)="clearUserSearch()">
                        <i
                            *ngIf="!searchCheckLength()"
                            class="fas fa-search search__helper"
                        ></i>
                        <i
                            *ngIf="searchCheckLength()"
                            class="fas fa-times"
                            title="clear"
                        ></i>
                    </button>
                    <input
                        type="text"
                        id="user-search"
                        name="user-search"
                        value=""
                        (input)="triggerSearch($event)"
                        placeholder="Search Users"
                    >
                </label>
            </div>
        </form>
        <!-- search users result list -->
        <div
            class="search-list scrollbar-new"
            [class.scrollable]="searchUsersList.length > 3"
        >
            <div
                *ngFor="let user of searchUsersList; let i = index"
                class="chat-user"
            >
                <div class="user-img">
                    <!--  -->
                    <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                    <img
                        src="https://profile-images.azureedge.us/{{
                            user.hash
                        }}"
                        alt="user"
                    />
                </div>
                <div class="chat-names-wrap">
                    <p class="chat-username" title="{{ user.displayName }}">
                        {{ user.displayName }}
                    </p>
                    <p class="chat-name" title="{{ user.username }}">
                        {{ user.username }}
                    </p>
                </div>
                <!-- add owner -->
                <button
                    class="owner-btn"
                    (click)="addOwner(user.channelUserId, user.displayName)"
                    title="Add Owner"
                >
                    <i class="far fa-user-tie"></i>
                </button>

                <!-- add owner -->
                <button
                    class="owner-btn"
                    (click)="addMember(user.channelUserId, user.displayName)"
                    title="Add Member"
                >
                    <i class="far fa-user-plus"></i>
                </button>
            </div>
            <p
                class="empty-list"
                *ngIf="searchUsersList.length < 1 && searchCheckLength()"
            >
                No Users Found
            </p>
        </div>
    </div>
</div>
