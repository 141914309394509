<div class="alerts flex-col px-4">
    <!-- <strong>Since: &nbsp;All Time</strong> -->
    <strong>&nbsp;</strong>
    <div class="title-wrap flex mb-4">
        <h3 class="title-thin">My Alerts</h3>
        <!-- (click)="showAlertSettingsModal()" -->
        <button 
            [matTooltip]="!isViewer ? 'alert settings' : 'registered users only'" 
            matTooltipClass="tooltip-default" 
            class="flex-center-all circle ms-auto"
            [disabled]="isViewer"
            [class.disabled]="isViewer" 
            routerLink="/alert-settings">
            <i class="fas fa-cog"></i>
        </button>
        <button 
            [matTooltip]="!isViewer ? alerts.length < 1 ? 'no alerts':'clear all alerts' : 'registered users only'" 
            matTooltipClass="tooltip-default" 
            class="flex-center-all circle ms-2 clear-all"
            [disabled]="alerts.length < 1 || isViewer"
            [class.disabled]="alerts.length < 1" 
            (click)="clearAllAlertsConfirm()">
            <i class="far fa-trash-alt"></i>
        </button>
    </div>
    <!-- removed from @listAnimationWrap -  || viewerAlerts.length - add back if needed -->
    <div 
        [@listAnimationWrap]="alerts.length" 
        class="alerts-wrap flex-col scrollbar-new"
        id="alertsScrollbox">
        <!-- 🚧 IF VIEWER SHOW THE 3 LINKS (viewerAlerts)-->
        <!--  -->
        <!-- <ng-container *ngIf="isViewer">
            <ng-container *ngFor="let alert of viewerAlerts; index as i; first as first">
                <div 
                    class="alert-{{componentId}} pb-1"
                    @listAnimationItem
                    [class.pt-3]="first"
                    [class.pt-4]="!first">
                    
                    <a 
                        *ngIf="i === 0" href="https://www.digitalinclusion.org/net-inclusion-2022/" 
                        target="_blank" rel="noopener noreferrer" 
                        class="flex-row-nowrap ndia-alert">
                        <i class="mx-2 fas fa-bell ndia-alert-icon flex-center-all"></i>
                        {{alert}}
                        <i class="fad fa-external-link last ndia-alert-icon flex-center-all ms-auto"></i>
                    </a>
                    
                    <a 
                        *ngIf="i === 1" href="https://www.via3.com/" 
                        target="_blank" rel="noopener noreferrer" 
                        class="flex-row-nowrap ndia-alert">
                        <i class="mx-2 fas fa-bell ndia-alert-icon flex-center-all"></i>
                        {{alert}}
                        <i class="fad fa-external-link last ndia-alert-icon flex-center-all ms-auto"></i>
                    </a>
                    
                    <a 
                        *ngIf="i === 2" href="https://twitter.com/netinclusion" 
                        target="_blank" rel="noopener noreferrer" 
                        class="flex-row-nowrap ndia-alert">
                        <i class="mx-2 fab fa-twitter ndia-alert-icon flex-center-all"></i>
                        {{alert}}
                        <i class="fad fa-external-link last ndia-alert-icon flex-center-all ms-auto"></i>
                    </a>
                    
                </div>
            </ng-container>
        </ng-container> -->
        <!-- not viewer -->
        <!-- ❌❌❌ PAGINATION REMOVED ... -->
        <!-- 
            id="{{(last && alertsPag.nextPageLink) ? 'LastAlert-' + componentId : 'alert-' + i + '-' + componentId}}"
            (@listAnimationItem.done)="(last && alertsPag.nextPageLink) ? paginationObserver() : ''"
        -->
        <ng-container *ngIf="!isViewer">
            <ng-container *ngFor="let alert of alerts; index as i; first as first; last as last">
                <!--  -->
                <div 
                    class="alert-{{componentId}} pb-1"
                    [class.pt-3]="first"
                    [class.pt-4]="!first"
                    @listAnimationItem>
                    <!-- 🚧 link to channel, faxes is type 7 -->
                    <!-- 🚧 cancelled meeting is type 4 -->
                    <!-- 🚧 TODO: need to list types or make an enum instead -->
                    <a 
                        *ngIf="alert.alertType !== 7 && alert.alertType !== 8 && alert.alertType !== 11 && alert.alertType !== 4"
                        [routerLink]="!!alert.channelId ? '/channel/' + alert.channelId : ''"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-- icons ... -->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-- message -->
                        <p class="flex align-center">{{alert.alertMessage}}</p>
                    </a>

                    <!-- Alert 8 is file added to channel -->
                    <!-- <a 
                        *ngIf="alert.alertType === 8"
                        [routerLink]="!!alert.channelId ? '/channel/' + alert.channelId : ''"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-/- icons ... -/->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-/- message -/->
                        <p class="flex align-center">{{alert.alertMessage}}</p>
                    </a> -->

                    <!--Put Back Router Link! -->

                    <a 
                        *ngIf="alert.alertType === 8"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-- icons ... -->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-- message -->
                        <p class="flex align-center">{{alert.alertMessage}}</p>
                    </a>

                    <!-- 🚧 cancelled meeting remove link back to cancelled meeting -->
                    <a 
                        *ngIf="alert.alertType === 4"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-- icons ... -->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-- message -->
                        <p class="flex align-center">{{alert.alertMessage}}</p>
                    </a>
                    <!-- 🚧 link to faxes -->
                    <a 
                        *ngIf="alert.alertType === 7"
                        [routerLink]="'/fax'"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-- icons ... -->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-- message -->
                        <p class="flex align-center">{{alert.alertMessage}}</p>
                    </a>
                    <!-- getting started alert -->
                    <a 
                        *ngIf="alert.alertType === 11"
                        href="https://www.via3.com/getting-started/" target="_blank" rel="noopener noreferrer"
                        (click)="patchAlertViewed(alert.notificationId, i)"
                        class="flex-row-nowrap">
                        <!-- icons ... -->
                        <div class="flex-col align-center">
                            <i class="fas fa-bell alert-type-icon" [class.active]="!alert.viewed"></i>
                            <small class="time-since" [class.active]="!alert.viewed">
                                {{alert.createdDate + 'Z' | timeAgo}}
                            </small>
                        </div>
                        <!-- message -->
                        <p  class="flex align-center">{{alert.alertMessage}}</p>
                    </a>
                    <button 
                            matTooltip="delete alert"
                            matTooltipClass="tooltip-default"
                            class="alert-options-btn"
                            (click)="!alert.isRemoving ? deleteAlert(alert.notificationId, i) : '' ">
                        <i class="far fa-trash-alt"></i>
                    </button>
                    
                    <!-- 💡 options dropdown (save may be needed later) ... -->
                    <!-- <button
                        type="button"
                        (wheel)="prevDefStopProp($event)"
                        (click)="showAlertOptionsDropdown(i, $event)"
                        (mouseleave)="hideAlertOptionsDropdown(i)"
                        class="alert-options-btn">
                        <i
                            class="fas fa-ellipsis-v"
                            matTooltip="alert options"
                            matTooltipClass="tooltip-default">
                        </i>
                        <div id="alert-options-dropdown-{{i}}" class="alert-options-dropdown mt-1 py-2">
                            <button
                                class="list-item"
                                (click)="deleteAlert(alert.notificationId)">
                                <i class="far fa-trash-alt"></i>Delete alert
                            </button>
                            <button class="list-item">
                                <i class="far fa-bell-slash"></i>Turn off this type
                            </button>
                        </div>
                    </button> -->
                </div>
            </ng-container>
        </ng-container>
        <!-- ❌ pagination removed -->
        <!-- <div class="loading-more-alerts flex justify-center mt-4" *ngIf="isLoadingMoreAlerts">
            <i class="fas fa-spinner spinner"></i>
        </div> -->
        
        <div 
            class="no-alerts flex-row-nowrap-center-all mt-4" 
            *ngIf="!isLoadingAlerts && !!alerts && alerts.length < 1 && !isViewer">
            <p class="text-center secondary-text">You do not have any alerts.</p>
        </div>
    </div>
    
    <div class="loading-alerts flex-center-all" *ngIf="isLoadingAlerts">
        <loading-home></loading-home>
    </div>
</div>
