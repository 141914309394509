import { UserProfile } from './user-profile';
import { SimpleClaim } from './simple-claim';

export class AuthContext {
  userProfile: UserProfile;
  claims: SimpleClaim[];

  get isAdmin() {
    return !!this.claims && !!this.claims.find(c =>
      c.type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && c.value.toLowerCase() === 'admin');
  }

  get isManager() {
    return !!this.claims && !!this.claims.find(c =>
      c.type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && c.value.toLowerCase() === 'manager');
  }
}
