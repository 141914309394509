import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Constants } from '../constants';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocketsService {

    constructor(
        private http: HttpClient
    ) { }

    getAllSocketServers(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .get(`${Constants.apiRoot}socketServer/`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getActiveSocketServers(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .get(`${Constants.apiRoot}socketServer?active=true`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getSocketServer(serverId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .get(`${Constants.apiRoot}socketServer/` + serverId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postSocketServer(serverObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .post(`${Constants.apiRoot}socketServer/`, serverObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putSocketServer(serverId: string, serverObject: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .put(`${Constants.apiRoot}socketServer/` + serverId, serverObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteSocketServer(serverId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.http
            .delete(`${Constants.apiRoot}socketServer/` + serverId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
