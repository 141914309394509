import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelService } from "src/app/services/channel-service";
import { ChannelUser } from "../../interface/channel-user";
import { Contact } from "../../interface/contact";
import { Constants } from "src/app/constants";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { consoleLogStyle } from '../../varaibles';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactsService } from "src/app/services/contacts.service";
import { slideInLeft } from "src/app/animations/global-animations";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth.service";


@Component({
    selector: "contact-info",
    templateUrl: "./contact-info.component.html",
    styleUrls: ["./contact-info.component.scss"],
    animations: [slideInLeft]
})
export class ContactInfoComponent implements OnInit, OnChanges, OnDestroy {

    @Input('contactInfo') contactInfo: Contact;
    @Input('userInfo') userInfo: ChannelUser;
    @Output("closeInfo") closeInfo = new EventEmitter();
    @Output("editContactModal") editContactModal = new EventEmitter();
    @Output("notesUpdate") notesUpdate = new EventEmitter(); // update note of selected contact on parent ... 
    @Output("formTouchedEvent") formTouchedEvent = new EventEmitter(); // to show confirm 🚧 todo ...

    public isSendingRegisterLink: boolean = false;

    public newContactNote: FormGroup;
    public isAddingNote: boolean = false;
    public isFormTouched: boolean = false;

    public tenantRegistrationLink: string;
    public isAdmin: boolean;
    private subscriptions: Subscription[] = [];

    constructor(
        public _channelService: ChannelService,
        public _toast: ToastNotificationService,
        public _api:ApiCallsService,
        private fb: FormBuilder, 
        private _contactService: ContactsService,
        private _authService: AuthService,
        private _apiCallService: ApiCallsService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            this.isAdmin = this.userInfo.roles.some(role => role.toLowerCase() === 'admin')
        }
    }

    ngOnInit(): void {
        console.log("%c contact info ", consoleLogStyle, this.contactInfo)
        this.createForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    createForm() {
        this.newContactNote = this.fb.group({
            notes: ["", [Validators.required]],
        });
        if(!!this.contactInfo.notes) {
            this.newContactNote.setValue({
                notes: this.contactInfo.notes,
            });
        }
        this.subscriptions.push(this.newContactNote.valueChanges.subscribe(data => {
            console.log('Form changes', data);
            if(data.notes !== this.contactInfo.notes) {
                if(!this.isFormTouched) {
                    this.isFormTouched = true;
                    this.formTouchedEvent.emit(true);
                    // console.log('Form Touched Emiited', this.isFormTouched);
                }
            } else {
                this.isFormTouched = false;
                this.formTouchedEvent.emit(false);
            }
        }));
    }

    addNote() {
        // patch contact like Favorite here ...
        this.isAddingNote = true;
        let patch = {
            "op": "replace",
            "path": "/notes",
            "value": this.newContactNote.value.notes
        };
        this._contactService.patchContact(this.contactInfo.contactId, patch)
            .subscribe(res => {
                if(res.status === 204) {
                    console.log("contact note added")
                    this.isAddingNote = false;
                    this.contactInfo.notes = this.newContactNote.value.notes;
                    this.newContactNote.setValue({
                        notes: this.contactInfo.notes,
                    });
                } else {
                    this.isAddingNote = false;
                    this._toast.error("There was an error adding note.");
                }
            });
    }

    sendDirectMessage(user: Contact) {
        this.closeInfo.emit();
        // 
        console.log("Open Direct Messages: .................", user);
        this._channelService.directMessageEmitEvent();
        // if dm does not exsist pass there id ...
        if (user.dmChannelId) {
            console.log("DM convo Found!");
            this._channelService.redirectDirectMessage(user.dmChannelId, user.displayName);
        } else {
            console.log("DM convo not found");
            this._channelService.redirectDirectMessage('composeNewMessage', user.displayName, user.contactChannelUserId);
        }
    }

    // 🚧 
    sendRegisterLinkToContact() {
        this.isSendingRegisterLink = true;
        // 
        let allTenants = [];
        this._api.getTenants().subscribe(res => {
            if(res.status === 200) {
                console.log("all tenents?", res.body)
                allTenants = res.body;
                allTenants.filter(tenant => {
                    if(tenant.tenantName.toLocaleLowerCase() === this.userInfo.tenant.toLocaleLowerCase()) {
                        console.log("your tenant register link:", `${Constants.apiRoot}register/${tenant.tenantCode}`)
                        // alert(`Send this link: ${Constants.apiRoot}register/${tenant.tenantCode}, Send to ${this.contactInfo.email}`)
                        this.tenantRegistrationLink = `${Constants.apiRoot}register/${tenant.tenantCode}`;
                        
                        // 🚧 send email here ... 
                        this.openEmailWithRegisterLink();
                    }
                });
                this.isSendingRegisterLink = false;
            } else {
                console.error("error getting all tenants", res)
                this.isSendingRegisterLink = false;
                this._toast.error("There was an error getting your tenants.");
            }
        });
    }

    openEmailWithRegisterLink() {
        // 🚧 
        let subject = `${this.userInfo.displayName} has invited you to join VIA3`;
        let body = `Follow this link to join me on VIA3 - ${this.tenantRegistrationLink}`;
        let emailEl = document.createElement('a');
        emailEl.setAttribute('href', `mailto:${this.contactInfo.email}?subject=${subject}&body=${body}`);
        emailEl.click();
        this.isSendingRegisterLink = false;
    }

    editContact() {
        // close this emit true, open edit ... 
        this.editContactModal.emit();
    }

    getDmChannel(user: Contact){

        console.log("Contact Info: " + user.contactChannelUserId);
        this._apiCallService.getDirectChannelByTwoUsers(user.contactChannelUserId)
        .subscribe(res => {
            if(res.status === 200) {
                // console.log("contact un-favorited")
                let data = res.body;
                user.dmChannelId = data.channelId;
                this.sendDirectMessage(user);
                console.log("User DM API: "+ res.body);
            } else {
                console.error("There was an getting the DM channel for the contact.");
            }
        });

    }
}
