import { Platform } from '@angular/cdk/platform';
import { Component, Inject, OnInit } from '@angular/core';
import { consoleLogStyle } from '../shared/varaibles';
import { ChannelService } from '../services/channel-service';
import { ToastNotificationService } from '../services/toast-notification.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { ChannelUser } from '../shared/interface/channel-user';

interface Device {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
};

@Component({
    selector: 'app-jitsi-call-manager',
    templateUrl: './jitsi-call-manager.component.html',
    styleUrls: ['./jitsi-call-manager.component.scss']
})
export class JitsiCallManagerComponent implements OnInit {
    public userInfo: ChannelUser = this._authService._channelUserInfo;

    public channelId: string;
    public channelName: string;
    public displayManager: boolean;

    public inVideo: boolean; //remove if not used in video

    readonly DEVICEPREFERENCES: string = "devicePreferences";
    public showPreviewModal: boolean = false;
    public isCallStarter: boolean = false;
    public callAccepted: boolean = false;

    public previewVideoInputs: Array<Device> = [];
    public previewAudioInputs: Array<Device> = [];
    public previewAudioOutputs: Array<Device> = [];

    public previewCurrentVideoInputId: string = null;
    public previewCurrentAudioInputId: string = null;
    public previewCurrentAudioOutputId: string = null; // 

    public previewVideoStream: MediaStream;
    public previewAudioStream: MediaStream;

    public videoPreviewOn: boolean;
    public audioPreviewOn: boolean = false;

    public currentMicrophoneStream: MediaStreamAudioSourceNode;
    public audioAnalyzer: AnalyserNode;
    // 💡❌ replace with new AudioWorklet ... 
    public audioScriptProcessor: ScriptProcessorNode;

    public devicesReady: any = false;

    public isSettingJitsiDevices: boolean;

    public devicePreferences: any = {};

    public matRippleDarkMode = '#ffffff1a';

    public clearTimer: boolean = false;

    constructor(
        @Inject('rippleColor') public rippleColor: string,
        private _toastService: ToastNotificationService,
        public _channelService: ChannelService,
        private _authService: AuthService,
        public _platform: Platform,
        private _router: Router,
    ) { }

    ngOnInit(): void {
        this._channelService.onJitisiCallManagerEvent$
            .subscribe(callObj => {
                if (!this.channelId) {
                    console.log("jitisi call manager channel info", callObj);
                    this.isCallStarter = callObj.user.channelUserId === this._authService?._userInfo?.sub;
                    this.getPreferences();
                    this.channelId = callObj.channelId;
                    this.channelName = callObj.channelName;
                    if (!this.isCallStarter) {
                        //if user doesnt answer within 10seconds after reciving
                        setTimeout(() => {
                            if (!this.clearTimer) {
                                this._channelService.callFailed(callObj.channelId);
                                this.hidePreview();
                                this.resetManager();
                                this._toastService.error("Call Not Accepted");
                            } else {
                                this.clearTimer = false;
                            }
                        }, 10000)
                    } else {
                        //backup user doesnt reciving call
                        setTimeout(() => {
                            if (!this.clearTimer) {
                                this.hidePreview();
                                this.resetManager();
                                this._toastService.error("Unable to call user, user may be busy or offline");
                            } else {
                                this.clearTimer = false;
                            }
                        }, 20000)
                    }
                    this.showPreview()
                } else {
                    //if you receivce another call while call manager is up
                    this._channelService.callFailed(callObj.channelId);
                }
            });

        this._channelService.onJitisiCallFailedEvent$
            .subscribe(channelId => {
                if (this.isCallStarter && (channelId === this.channelId)) {
                    this.hidePreview();
                    this.resetManager();
                    this._toastService.error("Unable to call user, user may be busy or offline");
                }
            });

        this._channelService.onJitisiCallCancelledEvent$
            .subscribe(channelId => {
                if (channelId === this.channelId) {
                    this.hidePreview();
                    this.resetManager();
                    this._toastService.error("Call has been Cancelled");
                }
            });

        this._channelService.onJitisiCallResponseEvent$
            .subscribe(callObj => {
                console.log("Call response", callObj);
                if (callObj.channelId === this.channelId && callObj.user.channelUserId !== this._authService?._userInfo?.sub) {
                    this.clearTimer = true;
                    if (callObj.hasAccepted) {
                        this.callAccepted = true;
                    } else {
                        this.hidePreview();
                        this.resetManager();
                        this._toastService.error("User has Denied your call");
                    }
                }
            });

    }

    resetManager(): void {
        this.channelId = null
        this.channelName = null;
        this.isCallStarter = false;
        this.callAccepted = false;
    }

    getPreferences() {
        // console.log("getPreferences called");
        this.devicePreferences = JSON.parse(localStorage.getItem(this.DEVICEPREFERENCES));
        // console.log("devicePreferences: ");
        // console.log(this.devicePreferences);
        if (this.devicePreferences === null) {
            this.devicePreferences = {
                "videoId": "default",
                "audioId": "default",
                "videoRes": 480,
                "jitsi": {
                    "startWithVideoMuted": true,
                    "startWithAudioMuted": false
                }
            };
            this.setPreference();
        } else if (!this.devicePreferences.jitsi) {
            this.devicePreferences.jitsi = {
                "startWithVideoMuted": true,
                "startWithAudioMuted": false
            }
            this.setPreference();
        }
        this.videoPreviewOn = !this.devicePreferences.jitsi.startWithVideoMuted
        this.audioPreviewOn = !this.devicePreferences.jitsi.startWithAudioMuted
        this.devicesReady = true;
    }

    setPreference() {
        localStorage.setItem(this.DEVICEPREFERENCES, JSON.stringify(this.devicePreferences));
    }

    toggleManager(): void {
        this.displayManager = !this.displayManager;
    }

    showPreview() {
        this.displayManager = true;
        this.getPreviewMediaDevices();
        setTimeout(() => {
            console.log(this.devicePreferences.jitsi);
            if (!this.devicePreferences.jitsi.startWithVideoMuted) {
                this.startVideoPreview();
            }
            if (!this.devicePreferences.jitsi.startWithAudioMuted) {
                this.audioPreviewToggle(true);
            }
        });
    }

    hidePreview() {
        this.destroyVideoPreview();
        this.destroyAudioPreview();
        this.displayManager = false;
        this.audioPreviewOn = false;
        this.updateLastActivity();
    }

    startVideoPreview() {
        // 
        if (this.previewCurrentVideoInputId) {
            let constraints = { deviceId: { exact: this.previewCurrentVideoInputId } };
            console.log("%c starting in call video preview w/ current input", consoleLogStyle, this.previewCurrentVideoInputId)
            this.initPreCallVideoPreview({ video: constraints, audio: false }, false);
        } else {
            console.log("%c starting in call video preview from nothing", consoleLogStyle)
            this.initPreCallVideoPreview({ video: true, audio: false }, true);
        }
    }

    initPreCallVideoPreview(config: any, isInitial: boolean) {
        let vidEl: HTMLVideoElement = <HTMLVideoElement>document.getElementById('videoPreviewEl-pre-join');
        // console.info("video preview element?", vidEl)
        let browser = <any>navigator;
        browser.getUserMedia = (browser.getUserMedia ||
            browser.webkitGetUserMedia ||
            browser.mozGetUserMedia ||
            browser.msGetUserMedia);
        // console.info("browser?", browser)
        browser.mediaDevices.getUserMedia(config)
            .then((stream) => {
                // if (isInitial) {
                //     this.getPreviewMediaDevices();
                // }
                // console.info("user video stream?", stream)
                this.previewVideoStream = stream;
                vidEl.srcObject = stream;
                // this.videoPreviewOn = true;
                // this.videoPreviewOn = !this.devicePreferences.jitsi.startWithVideoMuted

                // this.devicePreferences.jitsi.startWithVideoMuted = false;
            }).catch(err => {
                console.error(err.name + ": " + err.message, err);
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (video preview)`);
            });
    }

    // gets users devices & populates select inputs ... 
    getPreviewMediaDevices() {
        let videoDevices = [];
        let audioInputDevices = [];
        let audioOutputDevices = [];
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device => {
                    // console.log("user devices enum: ",device);
                    let option: Device = {
                        "deviceId": device.deviceId,
                        "groupId": device.groupId,
                        "kind": device.kind,
                        "label": device.label
                    };
                    if (device.kind === 'audioinput') {
                        audioInputDevices.push(option);
                    } else if (device.kind === 'audiooutput') {
                        audioOutputDevices.push(option);
                    } else if (device.kind === 'videoinput') {
                        videoDevices.push(option);
                    } else {
                        console.log("Other Device: " + device.kind + ": " + device.label + " id = " + device.deviceId + " was found.");
                    }
                });
            }).catch(err => {
                // console.log(err.name + ": " + err.message, err)
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (get preview devices)`);
            }).finally(() => {
                this.previewVideoInputs = videoDevices;
                if (this.previewVideoInputs.length > 0) {
                    if (!this.previewCurrentVideoInputId) {
                        this.previewCurrentVideoInputId = this.previewVideoInputs[0].deviceId; // SET TO CURRENT FOR SELECT ... 
                    }
                    // console.log("select val?", this.vidPreSelect)
                } else {
                    this._toastService.error('No camera found.')
                }
                // console.log("user devices video: ", this.previewVideoInputs);

                this.previewAudioInputs = audioInputDevices;
                if (this.previewAudioInputs.length > 0) {
                    if (!this.previewCurrentAudioInputId) {
                        this.previewCurrentAudioInputId = this.previewAudioInputs[0].deviceId;
                    }
                } else {
                    this._toastService.error('No audio input found.')
                }
                // console.log("user devices audio input: ", this.previewAudioInputs);

                this.previewAudioOutputs = audioOutputDevices;
                if (this.previewAudioOutputs.length > 0) {
                    if (!this.previewCurrentAudioOutputId) {
                        this.previewCurrentAudioOutputId = this.previewAudioOutputs[0].deviceId;
                    }
                } else {
                    // this._toastService.error('No audio output found.')
                    console.info("no audio output found.")
                }
                // console.log("user devices audio output: ", this.previewAudioOutputs);
            });
    }

    // video preview toggles
    videoPreviewToggle(hideVideoPreview: boolean) {
        // console.info("%c vid tog e : ", consoleLogStyle, hideVideoPreview)
        this.devicePreferences.jitsi.startWithVideoMuted = !hideVideoPreview;
        hideVideoPreview ? this.setVideoPreviewInput() : this.destroyVideoPreview();
    }

    setVideoPreviewInput() {
        this.destroyVideoPreview();
        // 
        let constraints = { deviceId: { exact: this.previewCurrentVideoInputId } };
        console.log("%c starting in call preview current input", consoleLogStyle, this.previewCurrentVideoInputId)
        this.initPreCallVideoPreview({ video: constraints, audio: false }, false);
    }

    // cuts current VIDEO preview track ... 
    destroyVideoPreview() {
        let vidEl: HTMLVideoElement = <HTMLVideoElement>document.getElementById('videoPreviewEl-pre-join');
        vidEl.srcObject = null;
        if (this.previewVideoStream) {
            this.previewVideoStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }

    // audio preview toggles
    audioPreviewToggle(showAudioPreview: boolean) {
        console.log(showAudioPreview);
        this.audioPreviewOn = showAudioPreview;
        // console.info("%c audio tog e audio on? : ", consoleLogStyle, this.audioPreviewOn)
        this.devicePreferences.jitsi.startWithAudioMuted = !showAudioPreview;
        showAudioPreview ? this.setAudioPreviewInput() : this.destroyAudioPreview();
    }

    setAudioPreviewInput() {
        this.destroyAudioPreview();
        // 
        let browser = <any>navigator;
        browser.getUserMedia = (browser.getUserMedia ||
            browser.webkitGetUserMedia ||
            browser.mozGetUserMedia ||
            browser.msGetUserMedia);
        let constraints: any = true;
        if (this.previewCurrentAudioInputId) {
            constraints = { deviceId: { exact: this.previewCurrentAudioInputId } };
        }
        browser.mediaDevices.getUserMedia({ audio: constraints })
            .then((stream) => {

                this.previewAudioStream = stream;
                const audioContext = new AudioContext();
                // console.info("%c ", consoleLogStyle)

                this.currentMicrophoneStream = audioContext.createMediaStreamSource(stream);
                // console.info("%c mic to dissconect on hide? : ", consoleLogStyle, this.currentMicrophoneStream)

                this.audioAnalyzer = audioContext.createAnalyser();
                // console.info("%c analyzer? ", consoleLogStyle, this.audioAnalyzer)
                this.audioAnalyzer.smoothingTimeConstant = 0.8;
                this.audioAnalyzer.fftSize = 1024;

                this.currentMicrophoneStream.connect(this.audioAnalyzer);

                // 💡❌ replace with new AudioWorklet ... 
                this.audioScriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
                // console.info("%c audio script proccessor ", consoleLogStyle, this.audioScriptProcessor)

                this.audioAnalyzer.connect(this.audioScriptProcessor);
                this.audioScriptProcessor.connect(audioContext.destination);

                this.audioScriptProcessor.onaudioprocess = () => {

                    const array = new Uint8Array(this.audioAnalyzer.frequencyBinCount);
                    this.audioAnalyzer.getByteFrequencyData(array);

                    const arraySum = array.reduce((a, value) => a + value, 0);
                    const average = arraySum / array.length;
                    // console.info( "%c Volume level ", consoleLogStyle, Math.round(average));

                    this.colorBars(average);
                };
                // console.info("%c ", consoleLogStyle)
            }).catch(err => {
                // console.error(err.name + ": " + err.message);
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (audio preview)`);
            });
    }

    // Make own componet for Sound
    colorBars(vol: number) {
        const allBars = Array.from(document.getElementsByClassName('bar') as HTMLCollectionOf<HTMLElement>);
        const dot = <HTMLElement>document.getElementById('audio-dot');
        const shadow = <HTMLElement>document.getElementById('audio-bars');
        const numberOfBarsToColor = Math.round(vol / 10);
        // console.info("%c # of bars to color ", consoleLogStyle, numberOfBarsToColor)
        const barsToColor = allBars.slice(0, numberOfBarsToColor);
        for (const bar of allBars) {
            bar.style.opacity = '0';
        }
        for (const bar of barsToColor) {
            // console.log(bar[i]);
            bar.style.opacity = '1';
            if (numberOfBarsToColor > 9) {
                bar.style.backgroundColor = 'var(--new-status-red)';
                dot.style.backgroundColor = 'var(--new-status-red)';
                shadow.style.boxShadow = 'inset -0.1rem -0.1rem 0.4rem rgba(255, 0, 85, 0.8), inset 0.1rem 0.1rem 0.4rem rgba(255, 0, 85, 0.8)';
            } else {
                bar.style.backgroundColor = 'var(--caribbean-green)';
                dot.style.backgroundColor = 'var(--caribbean-green)';
                shadow.style.boxShadow = 'inset -0.1rem -0.1rem 0.4rem rgba(18, 202, 150, 0.8), inset 0.1rem 0.1rem 0.4rem rgba(18, 202, 150, 0.8)';
            }
        }
    }

    // cuts current AUDIO preview track & analyzers & processors ... 
    destroyAudioPreview() {
        if (this.previewAudioStream) {
            this.previewAudioStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (this.currentMicrophoneStream) {
            this.currentMicrophoneStream.disconnect();
        }
        if (this.audioAnalyzer) {
            this.audioAnalyzer.disconnect();
        }
        if (this.audioScriptProcessor) {
            this.audioScriptProcessor.disconnect();
        }
    }

    speakerTest() {
        if (this._platform.FIREFOX || this._platform.IOS || this._platform.ANDROID) {
            // cant set device id on Speach ... 
            // let utterance = new SpeechSynthesisUtterance("Welcome to via three unity");
            // window.speechSynthesis.speak(utterance);
            // console.info("%c speaker test FIREFOX", consoleLogStyle)
            // 💡 audio output select is hidden on firefox, need to test safari ... 
            const audio: HTMLMediaElement = new Audio('../../../../assets/audio/test-tone.mp3');
            audio.play();
        } else {
            // console.info("%c speaker test not FIREFOX", consoleLogStyle, this.previewCurrentAudioOutputId)
            // 💡 type <any> is needed as .setSinkId() is experimental and only works on chrome ... 
            const audio: any = new Audio('../../../../assets/audio/test-tone.mp3');
            audio.setSinkId(this.previewCurrentAudioOutputId);
            audio.play();
        }
    }

    // 🚧 not done yet just for logging ...
    setAudioPreviewOutput() {
        // 
        console.log('%c current speakers: ', consoleLogStyle, this.previewCurrentAudioOutputId)
    }

    cancelCall(): void {
        // this._channelService.useManagerSettings = false;
        this._channelService.callCancelled(this.channelId);
        this.hidePreview();
        this.resetManager();
    }

    denyCall(): void {
        // this._channelService.useManagerSettings = false;
        this.clearTimer = true;
        this._channelService.callResponse(this.channelId, false);
        this.hidePreview();
        this.resetManager();
    }

    acceptCall(): void {
        this._channelService.useManagerSettings = true;
        if (!this.isCallStarter) {
            this.clearTimer = true;

            this._channelService.callResponse(this.channelId, true);
        }
        this.setPreference();
        this.hidePreview();
        this._router.navigate([`/channel/${this.channelId}`]);
        this.resetManager();

    }

    modalClickOff(event: Event): void {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        if (value == "jitsi-call-manager-modal") {
            if (this.isCallStarter) {
                this.cancelCall();
            } else {
                this.denyCall();
            }
        }
    }

    updateLastActivity() {
        if (this.channelId) {
            this._channelService.updateLastActivity(this.channelId);
        }
    }
}
