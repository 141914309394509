

<!--  this component not in use -->
<div class="request-overlay modal">
    <div class="request-box">
        <div class="request-message">
            <!-- 🍎 this 'permissionsError' is never set to true -->
            <p class="error" *ngIf="permissionsError">Please set devices to allow and try again</p>
        </div>
        <div class="request-stream-settings">
            <div class="request-settings-title">
                <p>Settings</p>
            </div>
            <div class="request-settings-list"> 
                <div class="select">
                    <label for="videoSource">Video source: </label>
                    <!-- 🍎 removed this '(change)="switchVideo($event.target.value)"' func is NOT in .ts class ??? -->
                    <select
                        name="videoSource"
                        *ngIf="videoPermissions"
                        [(ngModel)]="devicePreferences.videoId"
                        >
                        <option *ngFor="let device of videoDevices" value="{{device.deviceId}}">{{device.label}}
                        </option>
                    </select>
                    <p *ngIf="!videoPermissions">No Devices Found,
                        <a (click)="checkDevices(true, false)"> Try Again</a>
                    </p>
                </div>
                <div class="select">
                    <label for="audioSource">Audio source: </label>
                    <!-- 🍎 removed this '(change)="switchAudio($event.target.value)"' func is NOT in .ts class ??? -->
                    <select
                        name="audioSource"
                        *ngIf="audioPermissions"
                        [(ngModel)]="devicePreferences.audioId"
                        >
                        <option *ngFor="let device of audioDevices" value="{{device.deviceId}}">{{device.label}}
                        </option>
                    </select>
                    <p *ngIf="!audioPermissions">No Devices Found,
                        <a (click)="checkDevices(false, true)">Try Again</a>
                    </p>
                </div>
                <div class="select">
                    <label for="videoResolution">Video Resolution: </label>
                    <!-- 🍎 removed this '(change)="switchResolution($event.target.value)"' func is NOT in .ts class ??? -->
                    <select 
                        name="videoRes"
                        [(ngModel)]="devicePreferences.videoRes"
                        >
                        <option value="240">240p</option>
                        <option value="480">480p</option>
                        <option value="720">720p</option>
                        <option value="1080">1080p</option>
                        <option value="1440">1440p</option>
                        <option value="2160">4K</option>
                    </select>
                </div>
                <a class="device-check" (click)="checkDevices(true, true)">Check For New Devices</a>
            </div>
        </div>
    </div>
</div>