import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChannelService } from '../../services/channel-service';
import { AuthService } from '../../core/auth.service';
import { AppIntakeService } from '../../../../src/app/services/app-intake.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { SocketsService } from '../../services/sockets.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChannelUser } from 'src/app/shared/interface/channel-user';
import { consoleLogStyle } from '../../shared/varaibles';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { GuestService } from 'src/app/services/guest.service';

@Component({
    selector: 'vdjj-video-intake-homepage',
    templateUrl: './vdjj-video-intake-homepage.component.html',
    styleUrls: ['./vdjj-video-intake-homepage.component.scss']
})
export class VdjjVideoIntakeHomepageComponent implements OnInit, OnDestroy {

    public _app: any = {};
    public _intakeSession: any = {};
    public _tenant: string;
    public _teamProjectId: string;

    public userInfo: ChannelUser = this._authService._channelUserInfo;
    public _isIntake: boolean = false;
    public _officerChannelId: string = "";
    public _customizations: any = {};
    public showPage: boolean = false;
    public officerData: any = {};

    public channelInfo: any = {};
    public connectionStatus: any = "connected";
    public socketServers: any = [];

    public _isSignedIn = false;

    //For Medical
    public isMedical: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private _channelService: ChannelService,
        private _authService: AuthService,
        private _apiCallsService: ApiCallsService,
        private _intakeService: AppIntakeService,
        private _socketService: SocketsService,
        private _route: ActivatedRoute,
        private router: Router,
        private _toast: ToastNotificationService,
        private _guestService: GuestService,
    ) {
        if (this._channelService) {
            this.subscriptions.push(this._channelService.onHubConnectionStateChangeEvent$.subscribe((state: any) => {
                // alert(state);
                console.log("%c hub connection state change event: ", consoleLogStyle, state);
                this.connectionStatus = state;
            }))
        } else {
            alert("Channel service not initialized");
        }

        this.subscriptions.push(
            this._authService.channelUserInfoChanged$.subscribe(() => {
                this.userInfo = this._authService._channelUserInfo;
                if (this.userInfo.isGuest) {
                    this._guestService.routeToChannelInvited();
                }
                this.tenantCheck();
            }));
    }


    ngOnInit() {
        this._route.params.subscribe((params: Params) => {
            // console.log(params);
            if (params['tenant']) {
                this._tenant = params['tenant'];
                if (params['type']) {
                    if (params['type'] === 'medical') {
                        this.isMedical = true;
                    }
                }
                if (this.userInfo) {
                    if (this.userInfo.isGuest) {
                        this._guestService.routeToChannelInvited();
                    }
                    this.tenantCheck();
                }
            } else {
                this.router.navigate(['/unauthorized']);
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        return this._channelService.leave(this._officerChannelId);
    }

    tenantCheck() {
        if (this._tenant.toUpperCase() === this.userInfo.tenant.toUpperCase()) {
            this._getIntakeAppInfo();
        } else {
            this.router.navigate(['/unauthorized']);
        }
    }

    public _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                //console.log("Getactivesocketservers intake app homepage" + JSON.stringify(data.body));
                this.socketServers = data.body;
            })
            .catch(err => console.log(err));
    }

    _getIntakeAppInfo() {
        console.log("getting intake app info w/ tenant & ismedical", this._tenant, this.isMedical)
        this._intakeService.getIntakeApp(this._tenant, this.isMedical)
            .subscribe(data => {
                console.info("getting intake app info data sub", data, data.status, data.body)
                if (data.status === 200) {
                    if (data.body.length > 0) {
                        this._app = data.body[0];
                        console.info("getting intake app info data this._app?", this._app)
                        this._getAppCustomizations();
                        this._getIntakeSession();
                    } else {
                        this.router.navigate(['/unauthorized']);
                    }
                } else {
                    console.error("error getting intake app info ", data.status, data.body)
                }
            });
    }

    _getAppCustomizations() {
        this._intakeService.getAppCustomizations(this._app.appId)
            .subscribe(data => {
                if (data.status === 200) {
                    let customs = data.body;
                    customs.forEach(custom => {
                        if (custom.section === "CompanyImageHome") {
                            this._customizations.companyImageHome = custom;
                        }
                    });
                } else {
                    console.log("error gettting app customizations", data)
                }
            });
    }

    _getIntakeSession() {
        this._intakeService.getIntakeSessionByAppId(this._app.appId)
            .subscribe(data => {
                if (data.status === 200) {
                    this._intakeSession = data.body[0];
                    this._getIntakeUser();
                } else {
                    console.error("error getting intake session", data)
                }
            });
    }

    _getIntakeUser() {
        this._intakeService.getIntakeUserByChannelUserId(this._app.appId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (data.body.length > 0) {
                        let intakeData = data.body[0];
                        this._isIntake = intakeData.isIntakeOfficer;
                        this.officerData = intakeData;
                        //console.log(this.officerData)
                        this._getProjectByName();
                    } else {
                        console.error("error getting intake user", data)
                        this._toast.error("You do not have access to this page.");
                    }
                } else {
                    console.error("error getting intake user", data)
                    this._toast.error("You do not have access to this page.");
                }
            });
    }

    _getProjectByName() {
        let projectName: string;
        if (this.isMedical) {
            projectName = this._tenant.toUpperCase();
        } else {
            projectName = this._tenant.toUpperCase() + "IntakeApp";
        }
        //console.log(projectName);
        this._apiCallsService.getProjectByName(projectName)
            .subscribe(data => {
                if (data.status === 200) {
                    // alert(JSON.stringify(data));
                    this._teamProjectId = data.body[0].teamProjectId;
                    // alert(this._teamProjectId);
                    if (this._isIntake === false) {
                        //console.log('Is Not Intake Officer');
                        this._getChannelByName();
                    } else {
                        this.showPage = true;
                    }
                } else {
                    console.error("error getting project by name", data)
                }
            });
    }

    _getChannelByName() {
        let channelName = "PO_" + this.officerData.chatUserKeyNavigation.channelUserId;
        this._apiCallsService.getChannelByNameAndProjectId(channelName, this._teamProjectId)
            .subscribe(data => {
                if (data.status === 200) {
                    let channels = data.body;
                    if (channels.length > 0) {
                        this.channelInfo = channels[0];
                        this._officerChannelId = channels[0].channelId;
                        this.showPage = true;
                        this.channelInitialize();
                    }
                } else {
                    console.error("error getting channel by name", data)
                }
            });
    }

    channelInitialize() {
        return this._channelService.join(this._officerChannelId);
    }

    setConnectionStatus(status: any) {
        this.connectionStatus = status;
    }

    // ? ...
    reconnect() {
        window.location.reload();
        // this.router.navigate([`intakeapp/${this._tenant.toLowerCase()}`]);
        // this.showPage = false;
        // this.ngOnInit();
    }

    isSignedIn(): boolean {
        return this._isSignedIn;
    }
}
