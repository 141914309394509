import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from '../core/auth.service';

import { catchError } from 'rxjs/operators';
import { Constants } from '../constants';
import { Observable, of } from 'rxjs';
import { FileStorageUsage } from '../shared/interface/file-storage-file';

@Injectable({
    providedIn: 'root'
})
export class FileStorageService {
    // public _isSignedIn = false;

    constructor(
        private httpClient: HttpClient
    ) {}

    fileStorageUpload(commitInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/upload`, commitInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileStorageDownloadSharedFile(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/downloadSharedFile`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    listSharedFiles(folderInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/listSharedFolder`, folderInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    softDeleteFile(deletePath: string, channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let deleteObject = {
            path: deletePath,
            channelId: channelId
        };
        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/delete`, deleteObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteFile(deletePath: string, channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let deleteObject = {
            path: deletePath,
            channelId: channelId
        };
        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/permanentlyDelete`, deleteObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteTempIntakeFiles(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}fileStorage2/deleteAllChannelFiles/${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // delete channel (used for SharedFolders)
    deleteSharedFolder(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        //alert("deleting shared folder");
        return this.httpClient
            .delete(`${Constants.apiRoot}channel/` + channelId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    console.log("Error Deleting Shared Folder Channel: " + err.name);
                    return of(err);
                })
            );
    }


    // patchchannel / renames sharedfolder  
    renameSharedFolder(channelId: string, newChannelName: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let arr = [newChannelName];
        return this.httpClient
            .patch(`${Constants.apiRoot}channel/` + channelId, arr, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    console.log("ERROR REANAMING SHARED FOLDER: " + err.name);
                    return of(err);
                })
            );
    }

    putAzureStorageBlob(magicURL: any, file: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(magicURL, file, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // TODO remove unused typescripts from file storage v1 above <----<<<|) 

    //File Storage Version 2 <-----<<<|) 

    // fax services
    shareFile(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}filesharing/shareFile`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    unShareFile(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}filesharing/unshareFile`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    unShareSelf(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}filesharing/unshareSelf`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getFiles(folderInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/getBlobFiles`, folderInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileUpload(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/upload`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileUpload2(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/upload2`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // DELETE FROM DATABASE && BLOB STORAGE 
    fileDelete(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/permanentlyDelete`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // DELETE FROM DATABASE ONLY 
    blobFileDelete(blobFileId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .delete(`${Constants.apiRoot}blobFile/${blobFileId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileDownload(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/download`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //copy | duplicate
    fileCopy(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/copy`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    createFolder(folderInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/create_folder`, folderInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    renameFolder(folderInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/renameFolder`, folderInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    renameFile(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage2/renameFile`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //profileImg
    fileStorageProfileUpload(commitInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}fileStorage/upload/profileImage`, commitInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileStorageProfileDelete(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .delete(`${Constants.apiRoot}fileStorage/profileImage/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileStorageCustomContactProfileImageDelete(contactId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .delete(`${Constants.apiRoot}fileStorage/customContact/ProfileImage/${contactId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileAddToFavorite(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}blobFile/addToFavorite`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    fileRemoveFromFavorite(fileInfo: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .post(`${Constants.apiRoot}blobFile/removeFromFavorite`, fileInfo, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // fileStorage2/fileStorageUsage/userId ... 
    getStorageUsed(): Observable<HttpResponse<FileStorageUsage>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}fileStorage2/fileStorageUsage`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
