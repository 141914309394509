const standardEmailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passWordRegex: RegExp = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!_.=\\-]).*$/;

const urlRegex: RegExp = /(?:(?:https?|ftp):\/\/|www\.)([\w_-]+)([\w.,{}@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/gmi;

const aTagBeginRgex: RegExp = /\<a[\s\S](.*?)\>/gi; //<a href="www.websites.com>"

const aTagFullRegex: RegExp = /\<a[\s\S](.*?)\>(.*?)\<\/a\>/gi; //<a href="www.websites.com>click me</a>"

const imgTagRegex: RegExp = /\<img[\s\S](.*?)\>/gi;

// const codeTagRegex: RegExp = /\<code[\s\S](.*?)\>(.*?)\<\/code\>/gi;
// const codeTagRegex: RegExp = /\<code(.*?)\>(.*?)\<\/code\>/gi;
const codeTagRegex: RegExp = /\<code\>(.*?)\<\/code\>/gi;



const consoleLogStyle: string = "background:#8f00f526;color:#31f500;margin:1rem;padding:0rem;outline:2px solid #8f00f5;line-height:2";


export { 
    standardEmailRegex, 
    passWordRegex, 
    urlRegex, 
    aTagBeginRgex, 
    aTagFullRegex, 
    imgTagRegex, 
    codeTagRegex, 
    consoleLogStyle, 
};