import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule} from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

import { ChannelAppRoutingModule } from './channel-app-routing.module';
import { ChannelHomepageComponent } from './channel-homepage/channel-homepage.component';
import { ChannelLivestreamComponent } from './channel-livestream/channel-livestream.component';
import { ChannelPrimaryComponent } from './channel-primary/channel-primary.component';


@NgModule({
  declarations: [
    ChannelHomepageComponent,
    ChannelLivestreamComponent,
    ChannelPrimaryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ChannelAppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChannelAppModule { }
