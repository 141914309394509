import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-stream-errors',
    templateUrl: './stream-errors.component.html',
    styleUrls: ['./stream-errors.component.scss']
})
export class StreamErrorsComponent implements OnInit {
@Input() errorType: string;

    constructor() { }

    ngOnInit() {

    }

}
