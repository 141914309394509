<div
	class="video-frame"
	id="video-frame"
	[ngClass]="{
		hidden: preventStartVideo,
		'alt-height': altHeight
	}"
>
	<div id="joinMsg" style="color: var(--color-primary-text)" [class.display-none]="hideStartVideo">
		{{joiningCall ? "Joining..." : ""}}
	</div>
	<div
		*ngIf="!preventStartVideo && !hideStartVideo"
		class="join-box"
		id="btnStartup"
		[class.is-guest]="isGuest"
	>
		<div class="preference-settings">
			<div class="setting-btn">
				<label class="mb-3 outline-circle-checkbox" for="hidevideo">
					<input
						id="hidevideo"
						[(ngModel)]="devicePreferences.jitsi.startWithVideoMuted"
						type="checkbox"
						name="mutesound"
					/>
					<i
						class="fas input-icon"
						[ngClass]="{
							'fa-video': !devicePreferences.jitsi.startWithVideoMuted,
							'fa-video-slash': devicePreferences.jitsi.startWithVideoMuted
						}"
					></i>
				</label>
			</div>
			<div class="setting-btn">
				<label class="mb-3 outline-circle-checkbox" for="mutesound">
					<input
						id="mutesound"
						[(ngModel)]="devicePreferences.jitsi.startWithAudioMuted"
						type="checkbox"
						name="mutesound"
					/>
					<i
						class="fas input-icon"
						[ngClass]="{
							'fa-microphone': !devicePreferences.jitsi.startWithAudioMuted,
							'fa-microphone-slash': devicePreferences.jitsi.startWithAudioMuted
						}"
					></i>
				</label>
			</div>
			<!-- 🚧 open pre-call preview here -->
			<div
				class="setting-btn pre-view"
				matTooltip="device preview"
				matTooltipClass="tooltip-default"
			>
				<label class="mb-3 outline-circle-checkbox" for="preview-options">
					<button (click)="showPreview()">
						<i class="fas fa-sliders-v input-icon"></i>
					</button>
				</label>
			</div>
		</div>
		<div class="set-buttons">
			<button
				class="secondary-button"
				type="button"
				(click)="setPreference(true)"
				[disabled]="!isTokenFound"
			>
				Join Conversation
			</button>
		</div>
	</div>
	<guest-sign-out *ngIf="isGuest" id="guestSignOut"></guest-sign-out>

	<div id="container" class="container">
		<div class="invisible-click" *ngIf="sidebarShowing">
			<!-- used to close sidebar when video is present -->
		</div>
		<div id="toolbox" class="toolbox">
			<div class="video-bar-btn-list">
				<button
					id="btnCustomCamera"
					class="video-bar-btn"
					(click)="customCamera()"
					matTooltip="toggle video"
					matTooltipClass="tooltip-default"
				>
					<i class="fas btn-icon"></i>
				</button>
				<button
					id="btnCustomMic"
					class="video-bar-btn"
					(click)="customMic()"
					matTooltip="toggle mic"
					matTooltipClass="tooltip-default"
				>
					<i class="fas btn-icon"></i>
				</button>
				<button
					*ngIf="channelInfo && channelInfo.liveStreamIngestUrl && !isStreaming"
					class="video-bar-btn"
					(click)="startStream()"
					matTooltip="Start Streaming"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-broadcast-tower btn-icon"></i>
				</button>
				<button
					*ngIf="channelInfo && channelInfo.liveStreamIngestUrl && isStreaming"
					class="video-bar-btn activated"
					(click)="stopStream()"
					matTooltip="Stop Streaming"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-broadcast-tower btn-icon"></i>
				</button>
			</div>
			<div class="video-bar-btn-list">
				<ng-container *ngFor="let btn of featureList">
					<button
						*ngIf="btn.switch()"
						id="{{ btn.id }}"
						class="video-bar-btn {{ btn.class }}"
						(click)="btn.method()"
						matTooltip="{{ btn.title }}"
						matTooltipClass="tooltip-default"
					>
						<i class="{{ btn.icon }} btn-icon"></i>
					</button>
				</ng-container>
				<button
					id="btnOptions"
					*ngIf="!featureList[featureList.length - 1].switch() || isModerator"
					(click)="displayMoreOptions()"
					class="video-bar-btn vert"
					(blur)="_channelService._isDesktop ? hideMoreOptions() : null"
					[ngClass]="{ selected: showMoreOptionsModal }"
					matTooltip="more options"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-ellipsis-v btn-icon"></i>
				</button>
			</div>
			<!--
            <div class="video-bar-btn-list">
                <button *ngIf="channelInfo.liveStreamIngestUrl && !isStreaming"
                        class="video-bar-btn"
                        (click)="startStream()"
                        matTooltip="Start Streaming"
                        matTooltipClass="tooltip-default">
                    <i class="fas fa-broadcast-tower btn-icon"></i>
                </button>
                <button *ngIf="channelInfo.liveStreamIngestUrl && isStreaming"
                        class="video-bar-btn activated"
                        (click)="stopStream()"
                        matTooltip="Stop Streaming"
                        matTooltipClass="tooltip-default">
                    <i class="fas fa-broadcast-tower btn-icon"></i>
                </button>
            </div>
            -->
			<div class="video-bar-btn-list left-side">
				<button
					id="btnHangup"
					class="video-bar-btn stop"
					(click)="hangUpHelper()"
					matTooltip="leave call"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-phone-hangup btn-icon"></i>
				</button>
			</div>
		</div>
		<div id="jitsi-meet-conf-container"></div>
	</div>

	<!-- ellipsis options modal ... -->
	<div
		*ngIf="showMoreOptionsModal"
		class="jitsi-overlay"
		[ngClass]="{ modal: !jitsiBreak }"
		id="more-options-modal"
		(click)="hideMoreOptions()"
	>
		<div class="options-box">
			<div class="options-list">
				<div class="options-title" *ngIf="isModerator">Moderator</div>
				<button
					*ngIf="isModerator"
					id="btnMuteEveryone"
					class="option-btn"
					(mousedown)="muteEveryone()"
					matTooltip="mute everyone"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-microphone-alt-slash btn-icon"></i>
					<span class="btn-title">Mute Everyone</span>
				</button>
				<button
					*ngIf="isModerator"
					id="btnGetParticipants"
					class="option-btn kick"
					(mousedown)="showKickParticipants()"
					matTooltip="kick participants"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-times btn-icon"></i>
					<span class="btn-title">Kick Participants</span>
				</button>
				<div
					class="options-seperator"
					*ngIf="isModerator && !featureList[featureList.length - 1].switch()"
				></div>
				<!-- <button
                    id="btnCustomSettings"
                    class="option-btn"
                    (mousedown)="showSettings()"
                    matTooltip="video settings"
                    matTooltipClass="tooltip-default"
                >
                    <i class="fas fa-cog btn-icon"></i>
                    <span class="btn-title">Settings</span>
                </button> -->
				<ng-container *ngFor="let btn of featureList">
					<button
						*ngIf="!btn.switch()"
						id="{{ btn.id }}"
						class="option-btn {{ btn.class }}"
						(mousedown)="btn.method()"
						(document:keydown.escape)="closeFullScreen()"
						matTooltip="{{ btn.title }}"
						matTooltipClass="tooltip-default"
					>
						<i class="{{ btn.icon }} btn-icon"></i>
						<span class="btn-title">{{ btn.title | titlecase }}</span>
					</button>
				</ng-container>
				<!-- <button
                    id="btnSetFullScreen"
                    class="option-btn fullscreen"
                    (mousedown)="setFullScreen()"
                    type="button"
                    matTooltip="{{
                        isFullScreen ? 'exit full screen' : 'full screen'
                    }}"
                    matTooltipClass="tooltip-default"
                >
                    <i
                        class="fas btn-icon"
                        [ngClass]="{
                            'fa-expand': !isFullScreen,
                            'fa-compress': isFullScreen
                        }"
                    ></i>
                    <span class="btn-title" *ngIf="!isFullScreen"
                        >Full Screen</span
                    >
                    <span class="btn-title" *ngIf="isFullScreen"
                        >Exit Full Screen</span
                    >
                </button> -->
			</div>
		</div>
	</div>

	<!-- 💡🚧 device preview modal [can set devices if : inVideo] -->
	<div
		*ngIf="showPreviewModal"
		class="jitsi-overlay modal"
		id="video-preview-modal"
		(click)="modalClickOff($event)"
	>
		<div class="preview-box" [class.in-video]="inVideo">
			<div class="top-buttons">
				<h4 class="modal-title">Device Preview</h4>
				<button class="close-btn flex-center-all" (click)="hidePreview()">
					<i class="fas fa-times"></i>
				</button>
			</div>
			<!-- 🚧 PRE-call new video preview -->
			<div class="settings-options">
				<div class="preview-options flex-col">
					<video id="videoPreviewEl-pre-join" class="video-preview" autoplay></video>
					<div
						class="preference-settings"
						[class.no-audio-output]="
							_platform.FIREFOX || _platform.IOS || _platform.ANDROID
						"
					>
						<!-- toggle video preview on [also sets join preference] -->
						<div class="setting-btn me-2 ms-1">
							<label class="mb-3 outline-circle-checkbox" for="hidevideopreview">
								<input
									id="hidevideopreview"
									[(ngModel)]="videoPreviewOn"
									(ngModelChange)="videoPreviewToggle($event)"
									type="checkbox"
									name="mutevideopreview"
								/>
								<i
									class="fas input-icon"
									[ngClass]="{
										'fa-video': videoPreviewOn,
										'fa-video-slash': !videoPreviewOn
									}"
								></i>
							</label>
						</div>
						<!-- toggle audio preview on [also sets join preference] -->
						<div class="setting-btn mx-2">
							<label class="mb-3 outline-circle-checkbox" for="muteAudioPreview">
								<input
									id="muteAudioPreview"
									[(ngModel)]="audioPreviewOn"
									(ngModelChange)="audioPreviewToggle($event)"
									type="checkbox"
									name="muteAudioPreview"
								/>
								<i
									class="fas input-icon"
									[ngClass]="{
										'fa-microphone': audioPreviewOn,
										'fa-microphone-slash': !audioPreviewOn
									}"
								></i>
							</label>
						</div>
						<ng-container *ngIf="audioPreviewOn">
							<!-- audio level indicator (dot if on) -->
							<div id="audio-dot" class="mic-on me-2"></div>
							<!-- audio level indicator (bars on volume) -->
							<div id="audio-bars" class="bars-wrapper flex-center-all">
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
							</div>
						</ng-container>
						<div class="setting-btn ms-auto me-1">
							<label class="outline-circle-checkbox" for="speakerTest">
								<button
									(click)="speakerTest()"
									matRipple
									[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
									matTooltip="speaker test"
									matTooltipClass="tooltip-default"
									class="circle"
								>
									<i class="fas fa-volume-up input-icon"></i>
								</button>
							</label>
						</div>
					</div>
				</div>
				<!-- device selectors -->
				<div class="options flex-col ms-3">
					<div class="select">
						<label for="videoSource"><i class="fal fa-video me-1"></i>Camera</label>
						<mat-select
							class="borderless-select"
							name="videoPreviewSource"
							[(ngModel)]="previewCurrentVideoInputId"
							(selectionChange)="setVideoPreviewInput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewVideoInputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewVideoInputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<div class="select">
						<label for="audioSource"
							><i class="fal fa-microphone me-1"></i>Microphone</label
						>
						<mat-select
							class="borderless-select"
							name="audioSource"
							[(ngModel)]="previewCurrentAudioInputId"
							(selectionChange)="setAudioPreviewInput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewAudioInputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewAudioInputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<!-- both firefox & ios not supporting audio output selection ... -->
					<div
						*ngIf="!_platform.FIREFOX && !_platform.IOS && !_platform.ANDROID"
						class="select"
					>
						<label for="outputSource"
							><i class="fal fa-volume me-1"></i>Speakers</label
						>
						<mat-select
							class="borderless-select"
							name="outputSource"
							[(ngModel)]="previewCurrentAudioOutputId"
							(selectionChange)="setAudioPreviewOutput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewAudioOutputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewAudioOutputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<button
						*ngIf="inVideo"
						type="button"
						[disabled]="isSettingJitsiDevices"
						class="device-check mx-auto py-1 px-2 mt-auto mb-2"
						(click)="setJitsiDevices()"
						matRipple
						[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
					>
						Set in call devices
					</button>
					<button
						class="device-check mx-auto py-1 px-2 mt-auto"
						type="button"
						(click)="getPreviewMediaDevices()"
						matRipple
						[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
					>
						Check for new devices
					</button>
				</div>
			</div>
		</div>
	</div>
	<!-- end new device pre-view ^ -->

	<!-- kick participants modal -->
	<div
		*ngIf="showKickParticipantsModal"
		class="jitsi-overlay modal"
		id="participants-modal"
		(click)="modalClickOff($event)"
	>
		<div class="participants-box">
			<div class="top-buttons">
				<h4 class="modal-title">Participants</h4>
				<button class="close-btn flex-center-all" (click)="hideKickParticipants()">
					<i class="fas fa-times"></i>
				</button>
			</div>
			<div class="participant-list scrollbar-new">
				<div class="participant mb-2" *ngFor="let user of participantsInCall">
					<p class="text-ellipsis">{{ user.formattedDisplayName }}</p>
					<button
						*ngIf="callerId !== user.participantId"
						(click)="kickParticipant(user.participantId)"
						class="flex align-center mx-1"
					>
						Kick
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div
        *ngIf="showSettingsModal"
        class="jitsi-overlay modal"
        id="video-setting-modal"
        (click)="modalClickOff($event)"
    >
        <div class="settings-box">
            <div class="top-buttons">
                <h4 class="modal-title">Settings</h4>
                <button
                    class="close-btn flex-center-all"
                    
                >
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="settings-options mt-4">
                <div class="preview-options mt-2 me-3">
                    <video id="videoPreviewEl-pre-join" class="video-preview" autoplay></video>
                </div>
                <div class="options flex-col px-2">
                    <div class="select">
                        <label for="videoSource"><i class="fal fa-video me-1"></i>Camera</label>
                        <mat-select
                            class="borderless-select"
                            name="videoSource"
                            [(ngModel)]="currentVideoInputId"
                            (selectionChange)="setVideoInput()"
                            placeholder="Select A Device"
                        >
                            <mat-option
                                *ngFor="let device of videoInputs"
                                value="{{ device.deviceId }}"
                            >
                                {{ device.label }}
                            </mat-option>
                            <mat-option
                                value
                                disabled
                                *ngIf="videoInputs.length < 1"
                            >
                                No Devices To Select From</mat-option
                            >
                        </mat-select>
                    </div>
                    <div class="select">
                        <label for="audioSource"><i class="fal fa-microphone me-1"></i>Microphone</label>
                        <mat-select
                            class="borderless-select"
                            name="audioSource"
                            [(ngModel)]="currentAudioInputId"
                            (selectionChange)="setAudioInput()"
                            placeholder="Select A Device"
                        >
                            <mat-option
                                *ngFor="let device of audioInputs"
                                value="{{ device.deviceId }}"
                            >
                                {{ device.label }}
                            </mat-option>
                            <mat-option
                                value
                                disabled
                                *ngIf="audioInputs.length < 1"
                            >
                                No Devices To Select From</mat-option
                            >
                        </mat-select>
                    </div>
                    <div 
                        *ngIf="!_platform.FIREFOX"
                        class="select">
                        <label for="outputSource"><i class="fal fa-volume me-1"></i>Speaker</label>
                        <mat-select
                            class="borderless-select"
                            name="outputSource"
                            [(ngModel)]="currentAudioOutputId"
                            (selectionChange)="setAudioOutput()"
                            placeholder="Select A Device"
                        >
                            <mat-option
                                *ngFor="let device of audioOutputs"
                                value="{{ device.deviceId }}"
                            >
                                {{ device.label }}
                            </mat-option>
                            <mat-option
                                value
                                disabled
                                *ngIf="audioOutputs.length < 1"
                            >
                                No Devices To Select From</mat-option
                            >
                        </mat-select>
                    </div>
                    <button 
                        class="device-check mx-auto py-1 px-2 mt-auto" 
                        (click)="getDevices()"
                        matRipple
                        [matRippleColor]="rippleColor">
                        Check For New Devices
                    </button>
                </div>
            </div>
        </div>
    </div> -->

<!-- <div *ngIf="showJoinModal" class="jitsi-overlay modal">
    <div class="join-box">
        <div class="preference-settings" *ngIf="devicesReady">
            <div class="setting-btn">
                <input
                    id="mutesound"
                    [(ngModel)]="devicePreferences.jitsi.startWithAudioMuted"
                    type="checkbox"
                    name="mutesound"
                />
                <label class="video-chat-btn alt" for="mutesound">
                    <i
                        class="fas"
                        [ngClass]="{
                            'fa-microphone':
                                !devicePreferences.jitsi.startWithAudioMuted,
                            'fa-microphone-slash':
                                devicePreferences.jitsi.startWithAudioMuted
                        }"
                    ></i>
                </label>
            </div>
            <div class="setting-btn">
                <input
                    id="hidevideo"
                    [(ngModel)]="devicePreferences.jitsi.startWithVideoMuted"
                    type="checkbox"
                    name="hidevideo"
                />
                <label class="video-chat-btn alt" for="hidevideo">
                    <i
                        class="fas"
                        [ngClass]="{
                            'fa-video':
                                !devicePreferences.jitsi.startWithVideoMuted,
                            'fa-video-slash':
                                devicePreferences.jitsi.startWithVideoMuted
                        }"
                    ></i>
                </label>
            </div>
        </div>
        <div class="set-buttons">
            <button type="button" (click)="setPreference(false)">
                Join Call
            </button>
            <button type="button" (click)="declineCall()">Cancel</button>
        </div>
    </div>
</div> -->
