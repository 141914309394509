import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { BehaviorSubject, Observable, of } from "rxjs";
import { UserManager, User, WebStorageStateStore, Log } from "oidc-client";
import { Constants } from "../constants";
import { Utils } from "./utils";
import { AuthContext } from "../model/auth-context";
import { Subject } from "rxjs";
import { consoleLogStyle } from "../shared/varaibles";
import { ChannelUser } from "../shared/interface/channel-user";
import { TenantData } from "../shared/interface/tenant-data";


@Injectable({ providedIn: "root" })
export class AuthService {
    private _userManager: UserManager;
    public _user: User;

    //universal Properties
    public _userInfo: any;// same as _user.profile 
    public _channelUserInfo: ChannelUser;
    public _userHighestRole: string = null;
    public _tenant: TenantData;
    public _userStatus: string = "active";

    private userChange: Subject<any> = new Subject<any>();
    private _signInChangedSubject = new Subject<boolean>();
    // private _updateChannelUserInfoSubject = new Subject<boolean>();
    private _channelUserInfoChangedSubject = new Subject<boolean>();

    // not updating other users, need the id of user that did the thing, may need custom type w/ id & new val ... 
    public displayNameBehaviorSubject = new BehaviorSubject<string>('');
    public userHashBehaviorSubject = new BehaviorSubject<string>('');
    
    public userChange$ = this.userChange.asObservable();
    public signInChanged$ = this._signInChangedSubject.asObservable();
    // public updateChannelUserInfo$ = this._updateChannelUserInfoSubject.asObservable();
    public channelUserInfoChanged$ = this._channelUserInfoChangedSubject.asObservable();
    private authContext: AuthContext;

    constructor(private httpClient: HttpClient) {
        // Log.logger = console; // comment out for to stop logs <-----<<<|)
        this._user = JSON.parse(localStorage.getItem(`oidc.user:${Constants.stsAuthority}:unity-web-client`));
        if (this._user) {
            //May be to fast to emit to emit app comp but sets default values
            // console.log("First Set User", this._user.profile);
            this.setUserInfo();
        }
        const stsSettings = {
            authority: Constants.stsAuthority,
            client_id: Constants.clientId,
            redirect_uri: `${Constants.clientRoot}signin-callback`,
            scope: "openid unity-api profile",
            response_type: "code", //'id_token token',
            post_logout_redirect_uri: `${Constants.clientRoot}signout-callback`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${Constants.clientRoot}assets/silent-callback.html`,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        };
        this._userManager = new UserManager(stsSettings);
        this._userManager.events.addAccessTokenExpired((_) => {
            this._signInChangedSubject.next(false);
        });
        this._userManager.events.addUserLoaded((user) => {
            if (this._user?.profile?.sub !== user?.profile?.sub) {
                this._user = user;
                this.loadSecurityContext();
                this._signInChangedSubject.next(!!user && !user.expired);
                // console.log("User manager Set User", this._user.profile);
                this.setUserInfo();
            }
        });
    }

    emitUserChangeEvent(user) {
        // console.log("EMIT-------")
        this.userChange.next(user);
    }

    private setUserInfo() {
        this._userInfo = this._user.profile;
        if (this._userInfo) {
            this._userHighestRole = this.highestRoleSet();
            this._tenant = {
                tenantCode: this._userInfo.tenantCode,
                tenantId: this._userInfo.tenantId,
                tenantName: this._userInfo.tenantName
            };
            this.emitUserChangeEvent(this._userInfo);
        } else {
            this._userHighestRole = null;
            this._tenant = {
                tenantCode: null,
                tenantId: null,
                tenantName: null
            };
            this.emitUserChangeEvent(this._userInfo);
        }
    }

    private highestRoleSet(): string {
        if (this._userInfo.role.some(role => role.toLowerCase() === 'admin')) {
            return 'Admin';
        } else if (this._userInfo.role.some(role => role.toLowerCase() === 'manager')) {
            return 'Manager';
        } else if (this._userInfo.role.some(role => role.toLowerCase() === 'user')) {
            return 'User';
        } else if (this._userInfo.role.some(role => role.toLowerCase() === 'guest')) {
            return 'Guest';
        } else if (this._userInfo.role.some(role => role.toLowerCase() === 'viewer')) {
            return 'Viewer';
        } else {
            return null
        }
    }

    //  used to get AuthUser ... not same as ChannelUser ... 
    getMembershipUserInfo(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.stsAuthority}connect/userinfo`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError((err) => {
                    return of(err);
                })
            );
    }

    // used to get full ChannelUser ... 
    getChannelUserInfo(): Observable<HttpResponse<ChannelUser>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channelUser/` + this._user.profile.sub, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError((err) => {
                    return of(err);
                })
            );
    }

    // 🚧 login ??? i dont think we use this one was just testing ...
    loginUser(userObject: any) {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.stsAuthority}account/SpaLogin`, userObject, {
                headers: headersConfig,
                observe: "response",
            })
            .pipe(
                catchError((err) => {
                    return of(err);
                })
            );
    }

    signIn() {
        return this._userManager.signinRedirect();
    }

    completeSignIn() {
        return this._userManager.signinRedirectCallback().then((user) => {
            this._user = user;
            this._signInChangedSubject.next(!!user && !user.expired);
            return user;
        });
    }

    signInSilent() {
        this._userManager.signinSilent();
    }

    signOut(): Promise<any> {
        localStorage.clear();
        return this._userManager.signoutRedirect();
    }

    completeSignOut() {
        this._user = null;
        this._signInChangedSubject.next(false);
        return this._userManager.signoutRedirectCallback();
    }

    isSignedIn(fromAppComp:boolean): Promise<boolean> {
        //fromAppComp Tempory Until isSigned is removed from unecessary components
        return this._userManager.getUser().then((user) => {
            const userCurrent = !!user && !user?.expired;
            if (this._user !== user) {
                this._signInChangedSubject.next(userCurrent);
            }
            if (userCurrent && !this.authContext) {
                this.loadSecurityContext();
            }

            this._user = user;
            if (this._user && fromAppComp) {
                // console.log("Is Signed In Set User", this._user.profile);
                this.setUserInfo();
            }
            return userCurrent;
        });
    }

    getAccessToken() {
        return this._userManager.getUser().then((user) => {
            if (!!user && !user.expired) {
                return user.access_token;
            } else {
                return null;
            }
        });
    }

    signoutRedirectCallback(): Promise<any> {
        return this._userManager.signoutRedirectCallback();
    }

    loadSecurityContext() {
        this.httpClient
            .get<AuthContext>(`${Constants.apiRoot}account/authcontext`)
            .subscribe(
                (context) => {
                    this.authContext = new AuthContext();
                    this.authContext.claims = context.claims;
                    this.authContext.userProfile = context.userProfile;
                },
                (error) => console.error(Utils.formatError(error))
            );
    }

    changePassword(passwordObject: any) {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(
                `${Constants.apiRoot}account/changepassword`,
                passwordObject,
                { headers: headersConfig, observe: "response" }
            )
            .pipe(
                catchError((err) => {
                    return of(err);
                })
            );
    }

    getOrganizationInfoByUserId(userId: string) {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}tenant/organizationInfo/` + userId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError((err) => {
                    return of(err);
                })
            );
    }

    // public channelUserInfoHasUpdated() { 
    //     this._updateChannelUserInfoSubject.next();
    // }

    public channelUserInfoHasChanged() {
        this._channelUserInfoChangedSubject.next();
    }

    removeUserAuthentication() {
        return this._userManager.removeUser();
    }

    patchUser(userObj: any): Observable<any> {
        var headersConfig = new HttpHeaders();
        let userArr = [userObj];
        return this.httpClient
            .patch(`${Constants.apiRoot}aspNetUsers/${this._userInfo.sub}`, userArr, { headers: headersConfig })
            // , observe: "response" 
            .pipe(
                catchError(err => {
                     return of(err);
                     //toast ... 
                })
             );
    }

}
