<div id="toast-container" class="toast-container">
    <div
        @toastNotification
        *ngFor="let item of notifications"
        class="toast {{ notificationClass(item) }}"
        aria-live="polite"
    >
        <div class="toast-symbol">
            <i class="{{ iconClass(item) }}"></i>
        </div>
        <div class="toast-top">
            <div class="toast-title">
                {{ titleDisplay(item) }}
            </div>
            <button
                type="button"
                class="toast-close-button"
                role="button"
                title="close notification"
                (click)="removeNotification(item)">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="toast-bottom">
            <div class="toast-message">
                {{ item.message }}
            </div>
            <label *ngIf="item.checkboxMessage" class="toast-check">
                <input type="checkbox" [(ngModel)]="item.checkboxConfirm">
                <p>{{item.checkboxMessage}}</p>
            </label>
            <div *ngIf="checkContinue(item)" class="toast-buttons">
                <button type="button" (click)="removeNotification(item)">No</button>
                <button type="button" (click)="resolveToast(item)" *ngIf="item.warningType === toastContinue">
                    Yes
                </button>
                <button type="button" [routerLink]="item.data" *ngIf="item.warningType === toastContinueLink">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngFor="let item of notifications">
    <div 
        *ngIf="item.warningType === toastContinue || item.checkboxMessage"
        class="hidden-drop">
    </div>
</ng-container>

<!-- For Reference -->
<!-- 
    <div id="toast-container" class="toast-top-right" *ngFor="let item of notifications">  
    <div class="toast {{cssClass(item) }}" aria-live="polite" style="display: block;">  
        <button type="button" class="toast-close-button" role="button" (click)="removeNotification(item)">×</button>  
        <div class="toast-message">  
            {{item.message}}  
        </div>  
    </div>  
</div>  
-->
