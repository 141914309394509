<input 
    type="file"
    name="file"
    accept="image/x-png,image/gif,image/jpeg"
    class="file-upload"
    id="file-upload"
    [(ngModel)]="uploadFile.fileURL"
    [disabled]="fileIsUploading"
    (change)="fileName($event)">

    <!-- [class.loading-pic]="fileIsUploading" -->