<!-- 💡 nested components do not have to check isSigned in with it here -->

<!-- flex-nowrap may fix tablet (alerts column wraps under) -->
<div
	*ngIf="userInfo && !isGuest"
	id="home-page-container"
	class="home-page flex-row-nowrap">
	<div class="flex-col home-main-col first-home-col">
		<!-- events -->
		<events [userInfo]="userInfo" [isViewer]="isViewer"> </events>
	</div>
	<div class="flex-col home-main-col">
		<!-- channels history -->
		<channels-history [userInfo]="userInfo" [isViewer]="isViewer">
		</channels-history>
	</div>
	<div class="flex-col home-main-col">
		<!-- contacts -->
		<contacts [userInfo]="userInfo" [isViewer]="isViewer"> </contacts>
	</div>
	<div class="flex-col home-main-col">
		<!-- alerts -->
		<alerts
			[componentId]="'alerts-home'"
			[userInfo]="userInfo"
			[isViewer]="isViewer"
		>
		</alerts>
	</div>
</div>

<!-- 🚧 under construction flag -->
<!-- <ng-container>
    <img 
        height="100px" width="100px" alt="🚧" 
        style="filter: grayscale(100%);opacity: 0.2;" 
        src="https://i2.wp.com/www.bapl.org/wp-content/uploads/2019/02/old-under-construction-gif.gif?ssl=1">
    
    <span style="color: rgb(190,195,204);">under construction ...</span>
</ng-container> -->
