import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelService } from "src/app/services/channel-service";
import { MeetingService } from "src/app/services/meeting.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import * as moment from 'moment';
import { Constants } from 'src/app/constants';
import { Router } from "@angular/router";
import {
    listAnimationWrapLong,
    listAnimationItemLong,
    fadeInFast,
    customExitTransition
} from "src/app/animations/global-animations";
import { ChannelUser } from "../../interface/channel-user";
import { consoleLogStyle } from '../../varaibles';
import { MeetingEvent } from "../../interface/meeting-event";
import { ChannelFull } from "../../interface/channel-full";



@Component({
    selector: "events",
    templateUrl: "./events.component.html",
    styleUrls: ["./events.component.scss"],
    animations: [listAnimationWrapLong, listAnimationItemLong, fadeInFast]
})
export class EventsComponent implements OnInit, OnDestroy, OnChanges {

    @Input('userInfo') userInfo: ChannelUser; // 💡 must track from onChanges, initial val will be undefined until api return from home ... 
    @Input('incomingChannelObject') incomingChannelObject: ChannelFull; //Will show events within that channel
    @Input('preventEventClick') preventEventClick: boolean;
    @Input('isViewer') isViewer: boolean;

    public isLoadingMeetings: boolean = true;
    public meetings: Array<MeetingEvent> = [];
    public isLoadingMoreMeetings: boolean;
    public meetingsPag: any = {};

    public displayNewMeetingModal: boolean = false;
    public isCreatingQuick: boolean;
    public newMeeting: MeetingEvent = {};
    public newChannel: any = {};
    public meetingProject: any;
    public meetingType: string = 'quick';

    public projectId: string;
    public channelInfo: ChannelFull;
    public channelId: string;
    public meetingNotFound: boolean = false;
    public isNDIATenant: boolean = false; // 💡 need to use this instead of checking if Viewer

    public meetingToCancel: any = {
        meetingId: null,
        channelId: null,
        meetingIdx: null,
    };

    public todaysCurrentDateTime: any = new Date();
    public setCurrentDateTimeInterval: any;

    public displayScheduleModal: boolean = false;
    public displayScheduleWarning: boolean = false;
    // public editChannelObject: any = null;
    public editMeetingId: string = null;
    public editMeetingType: string = null;

    public selectedMeeting: MeetingEvent;
    public displayMeetingInfoModal: boolean = false;

    private subscriptions: Subscription[] = [];
    public displayMeetingTokenModal: boolean;

    constructor(
        private _toastService: ToastNotificationService,
        private _channelService: ChannelService,
        private _meetingService: MeetingService,
        private _apiCallsService: ApiCallsService,
        private _router: Router,
    ) {

        // 💡 need to also check if is Creator so will not update that user if meeting canceled ...
        // ❌ i need some data back from this ... fires too often ... 
        this.subscriptions.push(this._channelService.onUpdateMeetingListEvent$.subscribe((data) => {
            // console.log("%c 🤣 meeting list updated events home!!! ", consoleLogStyle, data)
            if (this.incomingChannelObject) {
                this._getChannelMeetingsOnly();
            } else {
                this._getMeetings();
            }
        }));

        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "closeScheduleModal") {
                this.closeScheduleModal();
            }
            if (data.type === "cancelMeeting") {
                // console.log("%c meeting to cancel?", consoleLogStyle, this.meetingToCancel)
                this.cancelMeeting(
                    this.meetingToCancel.meetingId, 
                    this.meetingToCancel.channelId, 
                    data.checkboxConfirm, 
                    this.meetingToCancel.meetingIdx
                );
                this.meetingToCancel = {
                    meetingId: null,
                    channelId: null
                };
            }
            if(data.type === "refreshToken"){
                this.refreshMeetingToken();
            }
        }));
    }

    ngOnInit(): void {

        //checks if user is in NDIA Tenant
        if(this.userInfo && this.userInfo.tenant == 'NDIA')
            this.isNDIATenant = true;
        // interval is cleared onDestroy 
        this.setCurrentDateTimeInterval = setInterval(() => {
            this.todaysCurrentDateTime = new Date().toISOString(); //  so we can use math on date for comparison: todaysDate > meeting.start
            // console.log("%c Current time ", consoleLogStyle, this.todaysCurrentDateTime);
        }, 1000); // keep current time for to show the "meeting in progress badge"
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            if (!!this.userInfo) {
                // console.log("%c 👤 user info input changes EVENTS ", consoleLogStyle, this.userInfo);
                if (this.incomingChannelObject) {
                    this._getChannelMeetingsOnly();
                } else {
                    this._getMeetings();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        clearInterval(this.setCurrentDateTimeInterval);
    }

    // not w/ global modal ... small 2 button modal ... 
    showNewMeetingModal() {
        this.displayNewMeetingModal = true;
    }
    
    closeNewMeetingModal() {
        this.displayNewMeetingModal = false;
    }

    _getMeetings() {
        this.isLoadingMeetings = true; 
        this.meetings = []; 
        this._meetingService.getUserMeetingsFuture(this.userInfo.channelUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.meetings = data.body;
                    
                    this.meetingsPag = JSON.parse(data.headers.get('X-Pagination'));
                    this.isLoadingMeetings = false;
                    // console.log("%c 🎟 meetings events: ", consoleLogStyle, this.meetings)
                    // console.log("🎟 meetings Pagination :::", this.meetingsPag)
                } else if (data.status === 404) {
                    console.error(JSON.stringify(data));
                    this.isLoadingMeetings = false;
                } else {
                    this._toastService.error("There was an error getting your events.");
                    console.error(JSON.stringify(data));
                    this.isLoadingMeetings = false;
                }
            });
    }

    _getChannelMeetingsOnly() {
        this.isLoadingMeetings = true; 
        this.meetings = []; 
        this._meetingService.getMeetingsByChannelId(this.incomingChannelObject.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.meetings = data.body;
                    this.isLoadingMeetings = false;
                    // this.meetingsPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c 🎟 meetings events: ", consoleLogStyle, this.meetings)
                    // console.log("🎟 meetings Pagination :::", this.meetingsPag)
                } else if (data.status === 404) {
                    console.error(JSON.stringify(data));
                    this.isLoadingMeetings = false;
                } else {
                    this._toastService.error("There was an error getting your events.");
                    console.error(JSON.stringify(data));
                    this.isLoadingMeetings = false;
                }
            });
    }

    // 💡 infinite scrolling w/ Intersection Observer api ... [(@listAnimationItemLong.done)="last ? runPaginationObserver() : ''"]  
    // 🌐 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API 
    paginationObserver() {
        let lastEl = <HTMLElement>document.getElementById('LastMeeting'); // grab last El in list [after animation done & is in DOM] 
        // console.log("%c List anime done: run intersect observer 🎯 LAST El: ", consoleLogStyle, lastEl)
        if (!!lastEl) {
            let observer = new IntersectionObserver(entries => {
                entries.forEach(entry => { // can observe multiple El's but will always be an Array of [entries] 
                    // console.log("%c is 🎯 El intersecting? ", consoleLogStyle, entry.isIntersecting)
                    // || entry.intersectionRatio > 0 - also works
                    if (entry.isIntersecting && entry.intersectionRatio >= 0) { // bottom of view intersecting W/ the top of El 
                        // console.log('%c 🎯 El is IN view run get next page! ', consoleLogStyle);
                        this._getMoreMeetings(this.meetingsPag.nextPageLink);
                        observer.unobserve(entry.target); // stop tracking this El, will grab next LastEl 
                    } else {
                        // console.log('%c 🎯 El OUT of view. ',consoleLogStyle);
                    }
                });
            });
            observer.observe(lastEl); // track the last El in list 
        } else {
            console.error("no last EL for pagination?", lastEl);
        }
    }

    _getMoreMeetings(link: any) { // returns 8 results ... 2 page len avg. 
        this.isLoadingMoreMeetings = true;
        this._apiCallsService.getGenericForPagination(link)
            .subscribe(data => {
                if (data.status === 200) {
                    this.meetings = this.meetings.concat(data.body);
                    this.meetingsPag = JSON.parse(data.headers.get('X-Pagination'));
                    this.isLoadingMoreMeetings = false;
                    // console.log("%c 🎟 meetings events PAGINATION: ", consoleLogStyle, this.meetings)
                } else {
                    this.isLoadingMoreMeetings = false;
                    this._toastService.error("There was an error getting your events.");
                    console.error(JSON.stringify(data));
                }
            });
    }

    public cancelMeetingPrompt(meetingId: string, channelId: string, meetingIdx: number) {
        this.meetingToCancel = {
            "meetingId": meetingId,
            "channelId": channelId,
            "meetingIdx": meetingIdx
        };
        this._toastService.continue("Are you sure you want to cancel this Meeting?", "cancelMeeting", "Notify the other guests");
    }

    private cancelMeeting(meetingId: string, channelId: string, cancelNotification: boolean, meetingIdx: number) {
        this.meetings[meetingIdx].isCancelingOrRemoving = true;
        let meetingObj = {
            "meetingId": meetingId,
            "cancelNotification": cancelNotification
        };
        // console.log("cancel meeting?")
        this._meetingService.cancelMeeting(meetingObj)
            .subscribe(res => {
                if (res.status === 200) {
                    this.meetings[meetingIdx].isCancelingOrRemoving = false;
                    // console.log("meeting Canceled", res)
                    this.meetings[meetingIdx].isCanceled = true; // local user update 
                    let isNewMeeting = false;
                    this._channelService.updateUpcomingMeetingsList(meetingId, isNewMeeting, channelId); // global sig-r update 
                } else {
                    this.meetings[meetingIdx].isCancelingOrRemoving = false;
                    this._toastService.error("There was an error canceling your event.");
                    // console.error("error canceling meeting?", res)
                }
            });
    }

    removeMeeting(meetingId: string, meetingIdx: number) {
        this.meetings[meetingIdx].isCancelingOrRemoving = true;
        this.hideMeetingOptionsDropdown(meetingIdx);
        let meetingObj = {
            "meetingId": meetingId,
            "userId": this.userInfo.channelUserId
        };
        // console.log("remove meeting")
        this._meetingService.removeMeeting(meetingObj)
            .subscribe(res => {
                if (res.status === 200) {
                    // this.meetings[meetingIdx].isCancelingOrRemoving = false;
                    // console.log("meeting removed", res)
                    let updatedMeetings = this.meetings.filter(meeting => meeting.meetingId !== meetingId);
                    let element = <HTMLElement>document.getElementsByClassName('meetings-container')[meetingIdx];
                    let element2 = <HTMLElement>document.getElementsByClassName('meetings-container')[meetingIdx + 1] || null;
                    customExitTransition(element,element2);
                    setTimeout(() => {
                        this.meetings = updatedMeetings;
                    }, 262);
                } else {
                    this.meetings[meetingIdx].isCancelingOrRemoving = false;
                    this._toastService.error("There was an error removing your event.");
                    // console.log("error removing meeting?", res)
                }
            });
    }

    // create new quick meeting [not scheduled, green btn] ... 
    createQuickMeet() {
        this.isCreatingQuick = true;
        // console.log("creating ad-hoc meeting start");
        this.meetingHub();
    }

    meetingHub() {
        this.meetingProject = 'QuickMeetingApp';
        this._getProject();
    }

    _getProject() {
        this._apiCallsService.getProjectByName(this.meetingProject)
            .subscribe(data => {
                if (data.status === 200) {
                    this.projectId = data.body[0].teamProjectId;
                    this.newChannelInit();
                } else {
                    this._toastService.error("There was an error creating your event.");
                    console.error("error creating meeting - get project:",data);
                    this.isCreatingQuick = false;
                }
            });
    }

    newChannelInit() {
        this.newChannel = {
            "teamProjectId": this.projectId,
            "channelUserId": this.userInfo.channelUserId,
            "name": "",
            "type": this.meetingType,
            "videoType": "jitsi",
            "topic": null,
            "inviteCode": null,
            "welcome": null,
            "deleteRule": 1,
            "deleteInterval": 1440,
            "scope": "Site",
            "private": true,
            "closed": false,
            "inVideoCall": false,
            "inScreenShare": false,
        };
        this.newMeeting = {
            "chatUserId": this.userInfo.channelUserId,
            "chatRoomId": "",
            "title": "",
            "body": "",
            "presentationUrl": "",
            "location": "",
            "start": "",
            "end": "",
            "allDay": true,
            "private": true,
            "markAsBusy": false,
            "displayAttendance": true
        }
        // console.log("new quick channel init from home", this.newChannel)
        // console.log("new quick meeting init from home", this.newMeeting)
        this._postChannel();
    }

    _postChannel() {
        this._apiCallsService.postChannel(this.newChannel)
            .subscribe(res => {
                if (res.status === 201) {
                    let newChannel = res.body
                    // console.log("newchannel being posted: " + JSON.stringify(newChannel));
                    this._postMeeting(newChannel);
                } else {
                    this._toastService.error("There was an error creating your event.");
                    console.error("error creating meeting - post channel:",res);
                    this.isCreatingQuick = false;
                }
            });
    }

    _postMeeting(channel: any) {
        // console.log("channel? ::::", channel)
        this.newMeeting.chatRoomId = channel.channelId;
        this.newMeeting.title = "quick meeting";
        this.newMeeting.body = "quick meeting";
        this.newMeeting.presentationUrl = "" + Constants.unitySite + 'channel/' + channel.channelId + "";
        this.newMeeting.location = "" + Constants.unitySite + 'channel/' + channel.channelId + "";
        this.newMeeting.private = channel.private;
        this.newMeeting.start = moment().toDate();
        this.newMeeting.end = moment().add(1, 'days').toDate();
        // console.log("new meeting created from home :: ::", this.newMeeting)
        this._meetingService.postMeeting(this.newMeeting)
            .subscribe(res => {
                if (res.status === 201) {
                    let meeting = res.body;

                    // REMOVED THIS SINCE THE QUICK MEETING CREATOR IS ALREADY ADDED?? ////CG 
                    // this._addMeetingAttendees(this.userInfo.channelUserId, meeting.meetingId);
                    
                    this._getChannel(channel.channelId);
                    // console.log(res.body);
                    // console.log("new meeting created from home :: ::", meeting)
                    // console.log("MeetingId: " + meeting.meetingId + " channelId: " + channel.channelId)
                    let isNewMeeting = true;
                    this._channelService.updateUpcomingMeetingsList(meeting.meetingId, isNewMeeting, channel.channelId);
                } else {
                    this._toastService.error("There was an error creating your event.");
                    console.error("error creating event from post meeting",res);
                    this.isCreatingQuick = false;
                }
            });
    }

    _addMeetingAttendees(userId: any, meetingId: any) {
        let attendee = {
            "channelUserId": userId,
            "meetingId": meetingId
        };
        this._meetingService.postMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Meeting Attendee Added ::", res.body);
                } else {
                    this._toastService.error("There was an error adding attendees your event.");
                    // console.error("error creating meeting - add meeting attendees:",res);
                    this.isCreatingQuick = false;
                }
            });
    }

    _getChannel(channelId: string) {
        this._apiCallsService.getChannel(channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (data.body.type.toLowerCase() === 'quick') {
                        this.channelInfo = data.body;
                        this.channelId = this.channelInfo.channelId;
                        this.navigateToQuickMeeting();
                    } else {
                        this.meetingNotFound = true;
                        this._toastService.error("There was an error creating your event.");
                        console.error("meeting not found? :", data);
                        this.isCreatingQuick = false;
                    }
                } else {
                    this.meetingNotFound = true;
                    this._toastService.error("There was an error creating your event.");
                    console.error("meeting not found? :", data.status);
                    this.isCreatingQuick = false;
                }
            });
    }

    navigateToQuickMeeting() {
        this.isCreatingQuick = false;
        this._router.navigate(['/channel', this.channelId]);
    }

    // utils 
    prevDefStopProp(e: Event) {
        // console.log('oi')
        e.preventDefault();
        e.stopPropagation();
    }

    editMeeting(meetingId: string, meetingType: string) {
        if (meetingType === "quick") {
            this.showScheduleModal(meetingId, "quick");
        } else {
            this.showScheduleModal(meetingId, "channel");
        }
    }
    showScheduleModal(meetingId: string, meetingType: string) {
        this.editMeetingId = meetingId;
        this.editMeetingType = meetingType;
        this.closeNewMeetingModal();
        this.displayScheduleModal = true;
    }
    closeScheduleModal(isOnFormComplete?: boolean) {
        this.displayScheduleWarning = false;
        this.displayScheduleModal = false;
        this.editMeetingId = null;
        this.editMeetingType = null;
        // if (isOnFormComplete) {
        //     if (this.incomingChannelObject) {
        //         this._getChannelMeetingsOnly();
        //     } else {
        //         this._getMeetings();
        //     }
        // }
    }
    closeScheduleModalConfirm() {
        if (this.displayScheduleWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
        } else {
            this.closeScheduleModal();
        }
    }
    clickOffScheduleModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'schedule-modal': {
                if (this.displayScheduleWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
                } else {
                    this.closeScheduleModal();
                }
                break;
            }
            case 'meeting-info-modal': {
                this.closeMeetingInfoModal();
                break;
            }
            default: return;
        }
    }

    // meeting info modal
    showMeetingInfoModal(idx: number, inProgress: boolean) {
        this.selectedMeeting = this.meetings[idx];
        if(inProgress) {
            this.selectedMeeting.inProgress = true;
            this.displayMeetingInfoModal = true;
        } else {
            this.selectedMeeting.inProgress = false;
            this.displayMeetingInfoModal = true;
        }
    }
    closeMeetingInfoModal() {
        this.displayMeetingInfoModal = false;
    }

    showMeetingOptionsDropdown(i: number, e?: Event) {
        const options = <HTMLElement>document.getElementById(`meeting-options-dropdown-${i}`);
        options.classList.add('active');
        
        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('eventScrollbox');
        scrollBox.classList.add('hidden');
    }

    hideMeetingOptionsDropdown(i: number, e?: Event) {
        const options = <HTMLElement>document.getElementById(`meeting-options-dropdown-${i}`);
        options.classList.remove('active');
        
        let scrollBox = <HTMLElement>document.getElementById('eventScrollbox');
        scrollBox.classList.remove('hidden');
    }

    //For Join Call
    joinChannelCall(e: Event) {
        this.prevDefStopProp(e);
        this._channelService.jitsiSelfJoinEvent(this.incomingChannelObject.channelId);
    }

    showMeetingTokenModal(idx: number, inProgress: boolean){
        this.selectedMeeting = this.meetings[idx];

        if(inProgress) {
            this.selectedMeeting.inProgress = true;
            this.displayMeetingTokenModal = true;
            console.log("Meeting Token Modal: true")
        } else {
            this.selectedMeeting.inProgress = false;
            this.displayMeetingTokenModal = true;
        }

    }
    closeMeetingTokenModal() {
        this.displayMeetingTokenModal = false;
    }

    copyMeetingToken(){
        navigator.clipboard.writeText(this.selectedMeeting.presentationUrl);
        this._toastService.info("Meeting token copied!");
    }

    public refreshMeetingTokenPrompt() {
        this._toastService.continue("Are you sure you want to create a new token?", "refreshToken");
    }

    refreshMeetingToken(){
        this._meetingService.putRefreshMeetingToken(this.selectedMeeting.meetingId, this.selectedMeeting)
        .subscribe(res => {
            if (res.status === 200) {
                this.selectedMeeting.presentationUrl = res.body.meetingToken;
                this._toastService.info("Meeting token refreshed!");
            } else if (res.status === 404) {
                console.error(JSON.stringify(res));
            } else {
                this._toastService.error("There was an error refreshing the meeting token.");
                console.error(JSON.stringify(res));
            }
        });
        
    }
}
