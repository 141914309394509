import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Constants } from "../../app/constants";
import { consoleLogStyle } from "../shared/varaibles";
// import { consoleLogStyle } from '../shared/varaibles';



@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

    public accountSite = Constants.accountSite;
    
    // public accountSiteRegister: string = `${Constants.accountSite}register`;
    public accountSiteRegister: string = `${Constants.accountSite}`;
    public accountSiteForgotPassWord: string = `${Constants.accountSite}forgot-password`;
    public loginForm: FormGroup;
    public isLoggingIn: boolean = false;
    public loginConfirmed: boolean = false;
    public loginError: boolean = false;
    public loginAttemptsLeft: number;
    public isAccountLockedOut: boolean;
    public emailConfirmed: boolean;
    public isCapsOn: boolean;
    public isPasswordVisible:boolean = false;
    public returnUrl: string = "";
    public loginRippleColor: string = 'rgba(255, 255, 255, 0.28)'; // login button feedback ... 
    public isAutofilled: boolean = false;

    public currentYear = new Date().getFullYear();
    
    public logoLogin: any = {
        logoblue:
            "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Partial/logo-via-blue-FIXED.png",
    };
    
    constructor(
        private fb: FormBuilder,
        private _router: Router
    ) {}

    ngOnInit() {
        this.getQueryVariable("ReturnUrl");
        // console.log("ReturnUrl: " + this.returnUrl);
        this.createForm();
        
        // chrome autofill ... no need for user interaction ... 
        setTimeout(() => {
            let name = <HTMLInputElement>document.getElementById('username');
            let pass = <HTMLInputElement>document.getElementById('password');
            // let submit = <HTMLButtonElement>document.getElementById('loginbutton');
            let fill1 = window.getComputedStyle(name, null).getPropertyValue('appearance') === 'menulist-button';
            let fill2 = window.getComputedStyle(pass, null).getPropertyValue('appearance') === 'menulist-button';
            // console.log("Chrome autofill detected comp & prop val", window.getComputedStyle(name, null), window.getComputedStyle(name, null).getPropertyValue('appearance'))
            // console.log("Chrome autofill detected, fill val")
            if(fill1 && fill2) {
                // console.log("Chrome autofill detected, button enabled, good job!", fill1, fill2)
                this.isAutofilled = true;
            }
        }, 600);
    }

    autofillName(e:any) {
        if(e.target.form[0].value && e.target.form[1].value) {
            this.loginForm.setValue({
                username: e.target.form[0].value,
                password: e.target.form[1].value
            });
            // console.info("%c autofill set form full ", consoleLogStyle, e.target.form[0].value, e.target.form[1].value)
        } else {
            this.loginForm.get('username').setValue(
                e.target.value,
            );
            // console.info("%c auto complete name val? ", consoleLogStyle, e.target.value)
        }
    }

    autofillPass(e:any) {
        if(e.target.form[0].value && e.target.form[1].value) {
            this.loginForm.setValue({
                username: e.target.form[0].value,
                password: e.target.form[1].value
            });
            // console.info("%c autofill set form full ", consoleLogStyle, e.target.form[0].value, e.target.form[1].value)
        } else {
            this.loginForm.get('password').setValue(
                e.target.value,
            );
            // console.info("%c auto complete pass val? ", consoleLogStyle, e.target.value)
        }
    }

    createForm() {
        this.loginForm = this.fb.group({
            username: ["", [Validators.required, Validators.minLength(2)]],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.pattern(
                        "^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=-_.]).*$"
                    ),
                ],
            ],
        });
    }

    get loginFormControls() {
        return this.loginForm.controls;
    }

    // login args from api.cs = (loginArgs.Email, loginArgs.Password, loginArgs.RememberMe, lockoutOnFailure: false)

    // ❓ SUBSCRIPTION method does not work with right for some reason with redirectUrl ...
    // login() {
    //   const returnUrl = this.loginForm.value.returnUrl;
    //   const username = this.loginForm.value.username;
    //   const password = this.loginForm.value.password;
    //   let userObj = {
    //     "returnUrl": returnUrl,
    //     "username": username,
    //     "password": password
    //   }
    //   this._authService.loginUser(userObj).subscribe(res => {
    //     console.log("login res?", res)
    //     if(res.status === 200) {
    //       this.loginConfirmed = true;
    //       console.log(" redirect url? ", res.body.redirectUrl)
    //       window.location = res.body.redirectUrl;
    //       // this._router.navigate([res.body.redirectUrl], { replaceUrl: true }); // this should work to nav now with replace url !
    //     } else {
    //       console.error("login fail invalid credentials");
    //       this.loginError = true;
    //     }
    //   });
    // }

    async login() {
        // console.log("💙 login?");
        this.isLoggingIn = true;
        const returnUrl = this.returnUrl;
        const username = this.loginForm.value.username;
        const password = this.loginForm.value.password;
        const response = await fetch(
            `${Constants.stsAuthority}account/SpaLogin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    username,
                    password,
                    returnUrl,
                }),
            }
        );
        const data = await response.json().catch((err) => {
            console.error("🔴 no data from login post", err);
            this.isLoggingIn = false;
            this.loginError = true;
        });
        if (data) {
            // console.log("💙 login data", data);
            if (data.isOk) {
                // console.log("💚 redirect url? ", data.redirectUrl);
                this.isLoggingIn = false;
                this.loginConfirmed = true;
                window.location = data.redirectUrl;
                
            } else {
                console.error("❤ login fail invalid credentials", data);
                // this.getQueryVariable("ReturnUrl");
                // this.loginForm.reset();
                this.isLoggingIn = false;
                this.loginError = true;
                this.emailConfirmed = data.emailConfirmed;
                // #of attempts/locked out
                if (data.attempts > 0) {
                    this.loginAttemptsLeft = data.attempts;
                }
                else if (data.lockedOut) {
                    this.isAccountLockedOut = true;
                }
            }
        } else {
            console.error("🟧 no data from login post");
        }
    }

    capsLockCheck(e: any) {
        if (e.keyCode) {
            e.getModifierState("CapsLock")
                ? (this.isCapsOn = true)
                : (this.isCapsOn = false);
        } else {
            return;
        }
    }
    hideCapsWarn() {
        this.isCapsOn = false;
    }
    showPassword() {
        this.isPasswordVisible = this.isPasswordVisible ? false : true;
    }

    getQueryVariable(variable: string) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (decodeURIComponent(pair[0]) == variable) {
                this.returnUrl = decodeURIComponent(pair[1]);
            }
        }
    }
}
// 
