import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../../constants';

@Component({
    selector: 'app-kurento-video',
    templateUrl: './kurento-video.component.html',
    styleUrls: ['./kurento-video.component.scss']
})
export class KurentoVideoComponent implements OnInit {
    @Input() channelId: string;
    @Input() userName: string;
    @Input() hideOnStart: boolean;
    @Output() endCallEvent = new EventEmitter();

    public iframe: any;
    public socketServers: any = [];

    constructor(
        private sanitizer: DomSanitizer
    ) {
        const id_token = localStorage.getItem('oidc.user:' + Constants.stsAuthority + ':unity-web-client')
        const access_token = JSON.parse(id_token).access_token;

        if (!this.hideOnStart) {
            this.hideOnStart = false;
        }

        if (Constants.isProd) {
            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://video-app-2603-dev.twil.io?name=" +
                this.userName + "&roomname=" + this.channelId + "&access_token=" + access_token);

        } else {
            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://127.0.0.1:44303?name=" +
                this.userName + "&roomname=" + this.channelId + "&access_token=" + access_token);
        }

    }

    ngOnInit() {
    }


}
