import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { AlertsService } from "src/app/services/alerts.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { consoleLogStyle } from '../../varaibles';
import { AlertSetting } from "../../interface/alert";
import { SystemNotificationService } from "src/app/services/system-notification.service";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelUser } from "../../interface/channel-user";
import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from "rxjs";
import { Platform } from "@angular/cdk/platform";
import { ChannelService } from "src/app/services/channel-service";



interface NewSetting {
    settingId:string;
    value:boolean;
};

@Component({
    selector: "alerts-settings",
    templateUrl: "./alerts-settings.component.html",
    styleUrls: ["./alerts-settings.component.scss"]
})
export class AlertsSettingsComponent implements OnInit, OnDestroy {

    @ViewChild(MatAccordion) accordion: MatAccordion;

    public userInfo: ChannelUser;

    public alertSettings: Array<AlertSetting> = [];
    public isLoadingSettings: boolean;

    public newSettings: Array<NewSetting> = [];
    public isUpdatingSettings: boolean;

    public hasNotifications: boolean;
    public permissionGranted: boolean;

    private subscriptions: Subscription[] = [];


    constructor(
        @Inject('rippleColor') public rippleColor: string,
        private _alertsService: AlertsService,
        private _toastService: ToastNotificationService,
        public _systemNotification: SystemNotificationService,
        private _apiCallsService: ApiCallsService,
        public _platform: Platform,
        public _channelService: ChannelService,
    ) {
        this._channelService.sidebarChangeEvent(0);
        // console.info("%c has noti? ", consoleLogStyle, this.hasNotifications)
        // console.info("%c has sys noti permiss been granted? ", consoleLogStyle, this.permissionGranted)
        if(!this._platform.IOS && !this._platform.ANDROID && !this._platform.SAFARI) {
            this.hasNotifications = this._systemNotification.hasSystemNotifications;
            this.permissionGranted  = this._systemNotification.isPermissionGranted;
            // 
            this.subscriptions.push(this._systemNotification.onGrantPermission$
                .subscribe((isPermissionGranted:boolean) => {
                    this.permissionGranted = isPermissionGranted;
                    if(this.permissionGranted) {
                        this._systemNotification.getSystemAlertsSettings(this.userInfo.channelUserId);
                    }
                }));
        }
    }

    ngOnInit(): void {
        this._getChannelUser();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    _getChannelUser() {
        this.isLoadingSettings = true;
        this._apiCallsService.getMyChannelUser()
            .subscribe(data => {
                if(data.status === 200) {
                    // console.log("getchanneluser home", data);
                    this.userInfo = data.body;
                    this.getAlertSettings();
                } else {
                    this._toastService.error('There was an error getting user info.')
                    this.isLoadingSettings = false;
                }
            });
    }

    getAlertSettings() {
        this.alertSettings = [];
        this._alertsService.getUserAlertSettings(this.userInfo.channelUserId)
            .subscribe(res => {
                if(res.status === 200) {
                    // console.log("%c alert settings ", consoleLogStyle, res.body)
                    this.alertSettings = res.body;
                    this.isLoadingSettings = false;
                } else {
                    // console.log("there was an error getting alert settings")
                    this.isLoadingSettings = false;
                    this._toastService.error('There was an error getting your alert settings.');
                }
            });
    }

    // 🚧🚧 New patch setting with every change here ... 
    toggleSettingChange(settingId:string, e: MatSlideToggleChange, alertTarget:number) {
        // 💡 e.checked = the boolean value from toggle event ... 
        // console.log("%c toggle event (id), (val) ", consoleLogStyle, settingId, ',',  e.checked)
        this.patchAlertSetting(settingId, e.checked, alertTarget);
    }

    patchAlertSetting(settingId:string, patchValue:boolean, alertTarget:number) {
        let settingPatch = {
            "op": "replace",
            "path": "/value",
            "value": patchValue
        };
        this._alertsService.patchAlertSetting(settingId, settingPatch)
            .subscribe( res => {
                if(res.status === 204) {
                    // console.log("setting updated!" + settingId, alertTarget)
                    if(alertTarget === 3) {
                        this._systemNotification.getSystemAlertsSettings(this.userInfo.channelUserId);
                    }
                } else {
                    this._toastService.error("There was an error setting this setting.");
                }
            });
    }

    grantSystemPermission() {
        this._systemNotification.notificationStart();
    }
}




































    // @Output('closeAlertSettings') closeAlertSettings = new EventEmitter();
    // @Output("formTouchedEvent") formTouchedEvent = new EventEmitter<boolean>();
    // @Input('userId') userId: string;


        // if(e.checked !== prevValue && !this.newSettings.some(setting => setting.settingId === settingId)) {
        //     this.newSettings.push({settingId: settingId, value: e.checked});
        //     if(this.newSettings.length === 1) {
        //         // this.formTouchedEvent.emit(true);
        //     }
        //     console.log("%c toggle event & value has changed, pushing to new set arr ", consoleLogStyle, this.newSettings)
        // } else if(e.checked === prevValue && this.newSettings.some(setting => setting.settingId === settingId)) {
        //     this.newSettings = this.newSettings.filter(setting => setting.settingId !== settingId);
        //     if(this.newSettings.length === 0) {
        //         // this.formTouchedEvent.emit(false);
        //     }
        //     console.log("%c toggle event & value is same as initial & is in arr, removing so wont update", consoleLogStyle, this.newSettings)
        // } else {
        //     console.log("%c toggle event & value is either already in arr | not so do nothing... ", consoleLogStyle, this.newSettings)
        //     return;
        // }


    // // ❌❌❌ removing here toggle change will patch ...
    // saveAlertSettings() {
    //     this.isUpdatingSettings = true;
    //     this.newSettings.forEach((setting: NewSetting, i) => {
    //         this.patchAlertSetting(setting.settingId, setting.value, (i === (this.newSettings.length - 1)));
    //     });
    // }

                        // this._toastService.info("Alert setting updated!" + settingId);
                    // if(isLast) {
                    //     this.newSettings = [];
                    //     this.isUpdatingSettings = false;
                    //     // this.closeAlertSettings.emit();
                    //     // this.formTouchedEvent.emit(false);
                    //     this._toastService.success("Alert settings updated!");
                    //     this.getAlertSettings();
                    // }