import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { AuthService } from 'src/app/core/auth.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ChannelService } from 'src/app/services/channel-service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { Subscription, Subject } from 'rxjs';
import {
    fadeInFast,
    listAnimationWrap,
    listAnimationItem
} from '../../animations/global-animations';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChannelUser } from 'src/app/shared/interface/channel-user';
import { consoleLogStyle } from 'src/app/shared/varaibles';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

//getUsersbysearchquery

@Component({
    selector: 'message-sidebar',
    templateUrl: './message-sidebar.component.html',
    styleUrls: ['./message-sidebar.component.scss'],
    animations: [
        fadeInFast,
        listAnimationWrap,
        listAnimationItem
    ]
})
export class MessageSidebarComponent implements OnInit, OnDestroy {
    @Input() userInfo: ChannelUser;
    // @Input() pinnedSideBar: boolean;

    public messageList = []; // shown dms
    public messageListPag: any = {};

    public messageSound: any;

    public listLoaded:boolean = false;
    public isSearching: boolean = false;
    public initalUnreadCounted:boolean = false;

    private subscriptions: Subscription[] = [];

    public channelIdParam: string = null;
    public selectedChannel: any = null;

    // for holding the serch string 
    private searchSubject = new Subject<string>();

    constructor(
        private _chManageService: ProjectChannelService,
        private _toastService: ToastNotificationService,
        private _apiCalls: ApiCallsService,
        private _authService: AuthService,
        public _channelService: ChannelService,
        private router: Router,
        private _route: ActivatedRoute,
    ) {
        //We need to know when the user sends a a chat message to update last message in dm channel

        // Only for new dm channels
        this.subscriptions.push(this._channelService.onUpdateDirectMessageListEvent$.subscribe(() => {
            // console.log("UpateDirectMessageList sub push to get message list (active)");
            if (this._authService._user) {
                if (this.isSearching) {
                    this.searchForMessages();
                    this._toastService.info("You have recieved a new Direct Message, you may want to clear your Direct Message Search")
                } else {
                    this._getActiveDirectMessageList();
                }
            } else {
                console.error("Can't find Signed in User");
            }
        }));

        // runs when status is updated MAY NEED TO SWITCH this.messageList to this.messageListFiltered for archive
        this.subscriptions.push(this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            // console.log("?", channelUserStatusList)
            this.messageList.forEach(messUser => {
                channelUserStatusList.forEach(channelUser => {
                    if (messUser.username.toLowerCase() === channelUser.username.toLowerCase()) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                messUser.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                messUser.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                messUser.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                messUser.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            // messUser.userStatus = "hidden";
                            messUser.userStatus = "offline";
                        } else if (channelUser.selectedStatus === 2) {
                            messUser.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            messUser.userStatus = "brb";
                        }
                    }
                });
                // if (this.isSearching) {
                //     //do same
                // }
            });
        }));

        this.subscriptions.push(this._channelService.onDirectMessageCloseEvent$.subscribe(() => this.closeChat()));

        this.subscriptions.push(this._channelService.onDirectMessageRedirectEvent$.subscribe((channel) => {
            // console.log("selected channel info", channel)
            this.selectedChannel = null;//For clearing any previous channels
            setTimeout(() => {
                this.selectedChannel = {
                    channelId: channel.channelId,
                    channelName: channel.channelName,
                    composeUserId: channel.userId
                }
            }, 1);
            
        }));

        // runs on message recieved && when you open a dm, will activate archived channel/message
        // triggered from sendchannelnotification
        this.subscriptions.push(this._channelService.onUnreadDirectMessageNotification$.subscribe((notiObj) => {
            // console.log("sentObject111", notiObj);
            const nineUnreadMessages: number = 9;
            let index = this.messageList.findIndex(dm => dm.channelId === notiObj.channelId);
            let message = this.messageList[index];
            if (message) {
                if (notiObj.message) {
                    message.lastMessage = notiObj.message;
                    message.lastMessage.isSender = false;
                }
                if (notiObj.numUnreadMessages > nineUnreadMessages) {
                    message.unreadMessages = "9+";
                } else {
                    message.unreadMessages = notiObj.numUnreadMessages;
                }
                if (!message.isActive) {
                    this._activateDmChannel(message.channelId);
                }
                if (notiObj.numUnreadMessages > 0) {
                    // If notification is sent from another user
                    if (index > 0) {
                        // If Message is not on Top
                        this.moveChannelToTop(index);
                        this.messageSound.play()
                        // notiObj.hasAudioNotification ? this.messageSound.play() : null;
                    } else {
                        // If Message is already on Top
                        if (notiObj.numUnreadMessages === 1) {
                            // If Message count was 0 and is now 1
                            this.messageSound.play()
                            // notiObj.hasAudioNotification ? this.messageSound.play() : null;
                        }
                    }
                    this._channelService.directMessageNotificationEvent(true);
                } else {
                    // If notification is sent from self
                    // this.moveChannelToTop(i);
                    this._channelService.directMessageNotificationEvent(
                        this.messageList.some(dm => dm.unreadMessages !== 0)
                    );
                }
            } else {
                this._activateDmChannel(notiObj.channelId);
                notiObj.hasAudioNotification ? this.messageSound.play() : null;
                //this._channelService.directMessageNotificationEvent(true);
            }
        }));

        this.subscriptions.push(this.router.events.subscribe(e => {
            // console.log("event", this.router.routerState.root)
            if (e instanceof NavigationEnd) {
                let r = this._route;
                while (r.firstChild) {
                    r = r.firstChild
                }
                r.params.subscribe(params => {
                    if (params['channelId']) {
                        this.channelIdParam = params['channelId'];
                    } else {
                        this.channelIdParam = null;
                    }
                    // console.log("🏎 params?", params, params['channelId'])
                })
            }
        }));

        // This event only triggers on the message sidebar component for the sender of the direct message
        // The message parameter is a channelmessage dto
        this.subscriptions.push(this._channelService.onDirectMessageUpdateForSelfEvent$.subscribe((sentMessage) => {
            // console.log("sentMEssage", sentMessage);
            this.messageList.forEach((message, i) => {
                if (message.channelId === sentMessage.channelId) {
                    if (!message.isActive) {
                        this._activateDmChannel(message.channelId);
                    }
                    message.lastMessage = sentMessage;
                    message.lastMessage.isSender = true;
                    this.moveChannelToTop(i);
                }
            });
        }));


        //When channelUserInfo is received begin channel user
        this.subscriptions.push(
            this._authService.channelUserInfoChanged$.subscribe(() => {
                if (this._authService._user) {
                    this._getActiveDirectMessageList()
                }
            }));
    }
    // end constructor 

    ngOnInit() {
        this.messageSound = new Audio();
        this.messageSound.src = '../../../assets/audio/newMessage02.mp3';

        //creates the search subject for the search debounce
        //delays calling the search query until the user paused for the time allotted (400ms here)
        this.searchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
              console.log("Search Query Passed for dm message search: "+ searchQuery); 
              this.searchForMessages();
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    triggerSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery);
    }

    //  needed to recieve notifications
    _getNotifications() {
        this._chManageService.getChannelNotificationsByUser(this._authService._userInfo.sub)
            .subscribe(data => {
                // console.log("notifications sub from dm sidebar?", data) 
            });
    }

    // called on load to get all dm's active && archived 
    _searchAllDirectMessages(query: string) {
        this._chManageService
            .searchAllDirectMessageChannels(this._authService._userInfo.sub, query)
            .subscribe(data => {
                if (data.status === 200) {
                    let channels = data.body;
                    this.messageList = this.manageChannelUsers(channels);
                    // console.log("🎄 got ALL dm's?", this.allMessagesList)
                    // this.messageListFiltered = this.allMessagesList;
                    this.messageListPag = JSON.parse(data.headers.get('X-Pagination'));
                    this.listLoaded = true;
                    // this._getNotifications();
                } else {
                    console.error("Direct Message Search List error", data);
                    this.listLoaded = true;
                }
            });
    }

    // only returns active dm's & set this.messageListFiltered to display 
    _getActiveDirectMessageList() {
        this._chManageService.getActiveDirectMessageChannels(this._authService._userInfo.sub)
            .subscribe(data => {
                if (data.status === 200) {
                    let channels = data.body;
                    this.messageList = this.manageChannelUsers(channels);
                    // console.log("%c Active Direct Messages List from Message Sidebar", consoleLogStyle, this.messageList);
                    // console.info("message list", this.messageList)
                    this.messageListPag = JSON.parse(data.headers.get('X-Pagination'));
                    this.listLoaded = true;
                    //sets the inital navbar unread message notfications
                    if (!this.initalUnreadCounted) {
                        this._channelService.directMessageNotificationEvent(
                            this.messageList.some(dm => dm.unreadMessages != 0)
                        );
                        this.initalUnreadCounted = true;
                    }
                } else {
                    // console.error("Direct Message List error", data);
                    this.listLoaded = true;
                    this._toastService.error("Direct Messages Failed To Load.");
                }
            });
    }

    manageChannelUsers(channels: any) {
        let userList: any = [];
        channels.forEach(channel => {
            let allUsers: any = channel.chatRoomChatUsers;
            let users: any = {};
            const nineUnreadMessages = 9;
            const userNum = channel.chatRoomChatUsers.length
            const unreadCount = channel.channelNotifications.length;
            // console.log('(from getActive) message all users', allUsers)
            if (userNum >= 2) {
                if (userNum > 2) {
                    users = {
                        // "username": userNum + " User Group With",
                        "displayName": "",
                        "lastMessage": "",
                        "hash": "1",
                        "channelId": channel.channelId,
                        "channelName": channel.name,
                        "userStatus": "none",
                        "username": "none",
                        "isActive": true,
                        "dateCreated": channel.dateCreated
                    };
                    allUsers.forEach((user, i) => {
                        if (user.chatUserKeyNavigation.username.toLowerCase() === this._authService._userInfo.preferred_username.toLowerCase()) {
                            users.isActive = user.isActive;
                            users.lastMessage = user.lastMessage;
                        }
                    });
                    if (unreadCount > nineUnreadMessages) {
                        users.unreadMessages = "9+";
                    } else {
                        users.unreadMessages = unreadCount;
                    }
                } else {
                    if (allUsers[0].chatUserKeyNavigation.username.toLowerCase() === this._authService._userInfo.preferred_username.toLowerCase()) {
                        users = allUsers[1].chatUserKeyNavigation;
                        users.isActive = allUsers[0].isActive;
                        users.lastMessage = allUsers[0].lastMessage;
                    } else {
                        users = allUsers[0].chatUserKeyNavigation;
                        users.isActive = allUsers[1].isActive;
                        users.lastMessage = allUsers[1].lastMessage;
                    }
                    users.channelId = channel.channelId;
                    users.channelName = channel.name;
                    users.dateCreated = channel.dateCreated;
                    if (users.selectedStatus === 0) {
                        if (users.presenceStatus === 0) {
                            users.userStatus = "offline";
                        } else if (users.presenceStatus === 1) {
                            users.userStatus = "away";
                        } else if (users.presenceStatus === 2) {
                            users.userStatus = "active";
                        } else if (users.presenceStatus === 3) {
                            //Temp for incall
                            users.userStatus = "active";
                        }
                    } else if (users.selectedStatus === 1) {
                        // users.userStatus = "hidden";
                        users.userStatus = "offline";
                    } else if (users.selectedStatus === 2) {
                        users.userStatus = "dnd";
                    } else if (users.selectedStatus === 3) {
                        users.userStatus = "brb";
                    }
                    if (unreadCount > nineUnreadMessages) {
                        users.unreadMessages = "9+";
                    } else {
                        users.unreadMessages = unreadCount;
                    }
                }
                if (users.lastMessage) {
                    if (users.lastMessage.channelUserKeyNavigation.username.toLowerCase() === this._authService._userInfo.preferred_username.toLowerCase()) {
                        users.lastMessage.isSender = true;
                    } else {
                        users.lastMessage.isSender = false;
                    }
                }
                userList.push(users);
            }
        });
        return userList;
    }

    searchForMessages() {
        console.log("searchForMessages fired")
        let filterBar: HTMLInputElement = <HTMLInputElement>document.getElementById('searchUserMessages');
        this.listLoaded = false;
        if (filterBar) {
            if (filterBar.value.length > 0) {
                this.isSearching = true;
                this.messageList = [];
                this._searchAllDirectMessages(filterBar.value);
            } else {
                this.isSearching = false;
                this.messageList = [];
                this._getActiveDirectMessageList();
            }
        }
    }

    clearFilter() {
        let filterBar: HTMLInputElement = <HTMLInputElement>document.getElementById('searchUserMessages');
        if (filterBar && this.isSearching) {
            this.isSearching = false;
            this._getActiveDirectMessageList();
            filterBar.value = "";
        }
    }

    moveChannelToTop(i: number) {
        let messageChannel = this.messageList[i];
        let newArray = this.messageList;
        newArray.splice(i, 1);
        newArray.splice(0, 0, messageChannel);
        this.messageList = newArray;
    }

    pushChat(channelId: string) {
        // console.log("ChannelID: " + channelId);
        // console.log("channelIdParam: " + this.channelIdParam);
        if (channelId !== this.channelIdParam) {
            // console.log("🛑 param DOESN'T match!")
            this._channelService.pushDirectMessageToOverlay(channelId);
            this.clearFilter();
        } else {
            // console.log("🛑 param matches!")
            return;
        }
    }

    // TO EMIT COMPOSE NEW CHAT BOX 
    // channel id could be any for new //
    pushComposeNewChat() {
        let composeChannelId = "composeNewMessage";
        // console.log("🛑 composeChannelId:" + composeChannelId);
        this._channelService.pushDirectMessageToOverlay(composeChannelId);
    }

    // new
    openChat(index: number) {
        if (this.messageList[index].channelId !== this.channelIdParam) {
            //new
            this.selectedChannel = this.messageList[index];
            this.clearFilter();
        } else {
            // console.log("🛑 param matches!")
            return;
        }
    }

    openComposeNewChat() {
        this.selectedChannel = {
            channelId: "composeNewMessage",
            channelName: "Compose Direct Message"
        }
        this.clearFilter();
    }

    closeChat() {
        // this._channelService.sendCommand("/leave", this.selectedChannel);
        this.selectedChannel = null
    }
    //

    _activateDmChannel(channelId: string) {
        let chatRoomsChannelUsersObject =
        {
            "channelId": channelId,
            "channelUserId": this._authService._userInfo.sub,
            "isActive": true
        };
        this._apiCalls.putChatRoomsChannelUsers(chatRoomsChannelUsersObject)
            .subscribe(res => {
                if (res.status === 204) {
                    this._getActiveDirectMessageList(); // refresh list may not be needed here 
                    // console.log("activate dm success", res)
                    this.clearFilter();
                } else {
                    console.error("Post Channel", res);
                }
            });
    }

    _deactivateDmChannel(channelId: string, i: number) {
        let chatRoomsChannelUsersObject =
        {
            "channelId": channelId,
            "channelUserId": this._authService._userInfo.sub,
            "isActive": false
        };
        this._apiCalls.putChatRoomsChannelUsers(chatRoomsChannelUsersObject)
            .subscribe(res => {
                if (res.status === 204) {
                    this._getActiveDirectMessageList(); // may not need 
                    // console.log("de-activate dm success", res)
                    this.clearFilter();
                } else {
                    console.error("Post Channel", res);
                }
            });
    }

    archiveDirectMessage(e: any, channelId: string, i:number) {
        e.stopPropagation();
        this._deactivateDmChannel(channelId, i);
        // console.log("archiving message", channelId)
    }

    closeSideBar() {
        // console.log("i close the sidebar")
        this._channelService.sidebarChangeEvent(0);
    }

    // pinToggle() {
    //     if (this.pinnedSideBar) {
    //         this._channelService.sidebarPinEvent(false);
    //     } else {
    //         this._channelService.sidebarPinEvent(true);
    //     }
    // }

    handleBrokenImage(idx:number): void {
        // console.info('yeeeeee')
        this.messageList[idx].hash = '1';
    }

    callTrigger() {
        // this._channelService.triggerAcceptCallModal(this.selectedChannel);
        this._channelService.callUsers(this.selectedChannel.channelId);
        this.closeSideBar();
    }
}







    // TODO comment out all unused testing functions ... 

    // getDirectMessageList() {
    //     this.messageList = [
    //         {
    //             "displayName": faker.name.firstName() + " " + faker.name.lastName(),
    //             "hash": "1", 
    //             "profileImg": faker.image.avatar(), 
    //             "status": "online",
    //             "channelId": "WviLRSjEQ"
    //         },
    //         {
    //             "displayName": faker.name.firstName() + " " + faker.name.lastName(),
    //             "hash": "1",
    //             "profileImg": faker.image.avatar(),
    //             "status": "busy",
    //             "channelId": "aV3x9GF5q"
    //         }
    //     ];
    // }
