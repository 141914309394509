
<div class="meeting-info flex-col">
    <!-- title -->
    <div class="title-row mb-3">
        <div class="flex meeting-title">
            <i class="fas fa-handshake me-3 handshake"></i>
            <h3 class="title-thin">{{meetingInfo.title}}</h3>
        </div>
        <small 
            *ngIf="meetingInfo.isCanceled"
            class="flex canceled mt-3">
            <i class="fas fa-calendar-times me-1"></i>CANCELED
        </small>
        <small 
            *ngIf="meetingInfo.inProgress"
            class="flex in-progress mt-3">
            <i class="fas fa-clock me-1"></i>IN PROGRESS
        </small>
    </div>
    <!-- topic -->
    <div class="meeting-channel flex mb-2">
        <p class="me-2">Topic: </p><strong style="word-break:break-word;">{{meetingInfo.body || 'none'}}</strong>
    </div>
    <!-- channel | quickmeeting -->
    <div class="meeting-channel flex mb-2">
        <p class="me-2">Channel: </p>
        <strong class="text-ellipsis">{{(meetingInfo.channelKeyNavigation.type === 'quick') ? 'Quick meeting' : meetingInfo.channelKeyNavigation.name}}</strong>
    </div>
    <!-- start/duration -->
    <div class="meeting-date mb-4">
        <div class="flex" style="margin-right: 5rem;"><p class="me-2">Date: </p><strong>{{meetingInfo.start + 'Z'|date:'M/d/yyyy'}}</strong></div>
        <div class="flex" style="margin-right: 5rem;"><p class="me-2">Time: </p><strong>{{meetingInfo.start + 'Z'|date:'h:mm a'}}</strong></div>
        <div class="flex"><p class="me-2">Length: </p><strong>{{meetingInfo.allDay ? 'All day' : meetingInfo.duration|meetingDuration}}</strong></div>
    </div>
    <!--  -->
    <div class="break mb-4"></div>
    <!-- attendees -->
    <div class="meeting-attendees flex-col mb-4">
        <p class="me-2 mb-2">Attendees: &nbsp;&nbsp;( <small>{{meetingInfo.meetingAttendees.length + 1}}</small> )</p>
        <div class="meeting-attendees-users flex-col scrollbar-new">
            <!-- meeting creator -->
            <div class="flex align-center mb-2 attendee">
                <div class="user-img me-2">
                    <img 
                        *ngIf="!!meetingInfo.channelKeyNavigation.chatUserKeyNavigation.hash && meetingInfo.channelKeyNavigation.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                        src="https://profile-images.azureedge.us/{{meetingInfo.channelKeyNavigation.chatUserKeyNavigation.hash}}" alt="user"
                        (error)="handleBrokenImage(true)">
                    <ng-template #defaultImg>
                        <div 
                            class="default-user-img circle flex-center-all every-third"
                            matTooltip="{{meetingInfo.channelKeyNavigation.chatUserKeyNavigation.displayName|titlecase}}" 
                            matTooltipClass="tooltip-default">
                            {{meetingInfo.channelKeyNavigation.chatUserKeyNavigation.username|defaultImageOverlay}}
                        </div>
                    </ng-template>
                </div>
                <strong>{{meetingInfo.channelKeyNavigation.chatUserKeyNavigation.displayName}}</strong>
                <small class="ms-2"> &bull; Host</small>
            </div>
            <!-- meeting attendees -->
            <ng-container *ngFor="let user of meetingInfo.meetingAttendees;index as i">
                <div class="flex align-center mb-2 attendee">
                    <div class="user-img me-2">
                        <img 
                            *ngIf="!!user.chatUserKeyNavigation.hash && user.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                            src="https://profile-images.azureedge.us/{{user.chatUserKeyNavigation.hash}}" alt="user"
                            (error)="handleBrokenImage(false,i)" >
                        <ng-template #defaultImg>
                            <div 
                                class="default-user-img circle flex-center-all"
                                [class.every-first]="i % 4 === 0"
                                [class.every-second]="i % 4 === 1"
                                [class.every-third]="i % 4 === 2"
                                [class.every-fourth]="i % 4 === 3"
                                matTooltip="{{user.chatUserKeyNavigation.displayName|titlecase}}" 
                                matTooltipClass="tooltip-default">
                                {{user.chatUserKeyNavigation.username|defaultImageOverlay}}
                            </div>
                        </ng-template>
                    </div>
                    <strong>{{user.chatUserKeyNavigation.displayName}}</strong>
                </div>
            </ng-container>
        </div>
    </div>
    <!--  -->
    <!-- 🚧🚧🚧 meeting notes (needs plan...) -->
    <!-- <div class="meeting-notes mb-4">
        <p class="me-2 mb-1">Notes: </p>
        <div class="box-quote active-quote mt-1 mb-2">
            <i class="quote-icon fas fa-quote-right flex-center-all"></i>
            <div class="box-quote-text scrollbar-new">Here is where users can see notes on the meeting ...</div>
        </div>
    </div>
    <div class="break mb-4"></div> -->
    <!--  -->
    <div class="break mb-4" *ngIf="!meetingInfo.isCanceled"></div>
    <div class="enter-meeting flex">
        <button 
            *ngIf="!meetingInfo.isCanceled"
            class="secondary-button mx-auto"
            [routerLink]="['/channel', meetingInfo.chatRoomId]">
            Enter Meeting
        </button>
    </div>
</div>
