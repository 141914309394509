import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userStatusName'
})
export class UserStatusNamePipe implements PipeTransform {

  transform(value: string): string {
    if(value === 'dnd') return 'do not disturb';
    if(value === 'brb') return 'be right back';
    return value;
  }
}
