<!--  -->
<div class="create-quick-meeting" *ngIf="meetingForm && !manageUsers">
	<h3 class="mb-4 title-thin" *ngIf="!incomingMeetingId">
		<i class="far fa-calendar-plus"></i>
		Create {{ lockChannel ? "Channel" : "New" }} Meeting
	</h3>
	<h3 class="mb-4 title-thin" *ngIf="incomingMeetingId">
		<i class="far fa-calendar-edit"></i>
		Edit Meeting
	</h3>
	<form
		class="primary-form-layout"
		[formGroup]="meetingForm"
		(document:keydown.enter)="
			meetingForm.valid && !createLock && !scheduleError && !isDropdownOpen
				? scheduleManager()
				: ''
		"
	>
		<div class="mb-3 form-inputs">
			<h4 *ngIf="lockChannel" class="mb-3 form-title text-ellipsis two-line-clamp">
				{{ incomingChannelObject.name }}
			</h4>
			<h4
				*ngIf="incomingMeetingId"
				class="mb-3 form-title text-ellipsis two-line-clamp"
			>
				{{
					(meetingInfo.channelKeyNavigation.type | lowercase) === "quick"
						? "Invitational Meeting"
						: meetingInfo.channelKeyNavigation.name
				}}
			</h4>
			<input
				type="text"
				name="name"
				class="borderless-input required"
				placeholder="Event Name* (required field)"
				formControlName="name"
				maxlength="200"
				required
			/>
			<input
				type="text"
				name="topic"
				class="borderless-input"
				placeholder="Event Topic"
				formControlName="topic"
				maxlength="200"
			/>
			<div class="mb-2 group-items-center radio-types" *ngIf="showMeetingType">
				<label class="mb-3 outline-radio">
					<input formControlName="type" type="radio" name="type" value="quick" />
					<p class="input-name">Invitational Meeting</p>
				</label>
				<label class="mb-3 outline-radio">
					<input formControlName="type" type="radio" name="type" value="channel" />
					<p class="input-name">Channel Meeting</p>
				</label>
			</div>
			<div *ngIf="meetingForm.value.type === 'quick' && !isEditQuick" class="mb-2 group-items-space-between set-time">
			<!-- Rounded switch -->
				<mat-slide-toggle 
					matTooltipClass="tooltip-default" 
					id="privacy"
					(change)="togglePrivateChange($event)">
					<ng-container>
						Public Meeting
					</ng-container>
				</mat-slide-toggle>
			</div>
			<mat-select
				class="borderless-select disabled"
				(openedChange)="handleDropdownEnterSubmitLock($event, false, 'chan-select')"
				name="channelId"
				id="chan-select"
				formControlName="channelId"
				*ngIf="
					formControls.type.value === 'channel' && !isEditChannel && !lockChannel
				"
				[class.pre-select]="!formControls.channelId.value"
				placeholder="Select Channel* (required field)"
			>
				<mat-option
					*ngFor="let channel of channelList"
					value="{{ channel.channelId }}"
				>
					{{ channel.name }} | {{ channel.teamProjectKeyNavigation.name }} |
					{{
						channel.chatUserKeyNavigation
							? channel.chatUserKeyNavigation.displayName
							: "No Creator Found"
					}}
				</mat-option>
			</mat-select>
			<!-- set FROM date/time -->
			<h3 class="mb-3 form-title">Schedule:</h3>
			<div class="mb-2 group-items-space-between set-time">
				<!-- date-picker -->
				<label
					class="mb-3 outline-datepicker"
					matTooltip="start date"
					matTooltipClass="tooltip-default"
				>
					<span> Date: </span>
					<input
						matInput
						[(ngModel)]="selectSchedule.fromDate"
						[ngModelOptions]="{ standalone: true }"
						[min]="todaysDate"
						[max]="maxScheduleDate"
						(dateChange)="emitTouched()"
						placeholder="mm/ dd/ yyyy"
						[matDatepicker]="datePicker"
						readonly
					/>
					<mat-datepicker-toggle
						matSuffix
						[for]="datePicker"
						[disableRipple]="true"
					></mat-datepicker-toggle>
					<mat-datepicker
						#datePicker
						(opened)="handleDropdownEnterSubmitLock(true, true)"
						(closed)="handleDropdownEnterSubmitLock(false, true, 'sub-btn')"
					>
					</mat-datepicker>
				</label>

				<!-- pick time -->
				<label
					class="mb-3 outline-timepicker"
					matTooltip="time"
					matTooltipClass="tooltip-default"
				>
					<span> Time: </span>
					<input
						aria-label="time"
						[ngxTimepicker]="timePicker"
						name="time"
						[(ngModel)]="selectSchedule.time"
						[ngModelOptions]="{ standalone: true }"
						placeholder="Time"
						[format]="12"
						readonly
					/>
					<ngx-material-timepicker
						(timeSet)="emitTouched()"
						#timePicker
					></ngx-material-timepicker>
				</label>

				<label
					class="mb-3 outline-select"
					for="lengthSelect"
					matTooltip="length"
					matTooltipClass="tooltip-default"
				>
					<span> Length:</span>
					<mat-select
						[(ngModel)]="selectSchedule.timeLength"
						[ngModelOptions]="{ standalone: true }"
						(selectionChange)="emitTouched()"
						(openedChange)="
							handleDropdownEnterSubmitLock($event, false, 'lengthSelect')
						"
						name="timeLength"
						value="24"
						id="lengthSelect"
					>
						<mat-option value="15">15 min</mat-option>
						<mat-option value="30">30 min</mat-option>
						<mat-option value="1">1 Hour</mat-option>
						<mat-option value="2">2 Hour</mat-option>
						<mat-option value="24">All Day</mat-option>
					</mat-select>
				</label>
				<!-- <p class="error" *ngIf="scheduleError">
                    🛑 you cannot select a date/time in the past.
                </p> -->
			</div>
		</div>
	</form>
	<!-- END SCHEDULE MEETING -->

	<!-- INVITE START -->
	<p class="mb-3 form-info mt-3">
		To add attendees to your meeting.
		{{
			meetingForm.value.type === "quick"
				? "VIA3 Users can be searched by name, while people outside your Organization can be invited by email or phone number."
				: "Channel Members can be searched by name."
		}}
	</p>

	<div class="invite-options">
		<div
			class="group-items-center radio-invites"
			*ngIf="meetingForm.value.type === 'quick'"
		>
			<h3 class="mb-1 form-title">Invite via:</h3>
			<mat-radio-group
				aria-labelledby="example-radio-group-label"
				class="mb-3"
				[(ngModel)]="inviteDisplay"
				[ngModelOptions]="{ standalone: true }"
			>
				<mat-radio-button class="mb-3" value="contacts">
					Contacts
				</mat-radio-button>
				<mat-radio-button class="mb-3" value="sms"> SMS </mat-radio-button>
				<mat-radio-button class="mb-3" value="email"> Email </mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="invite-list">
			<h3 class="mb-1 form-title">Attendee List:</h3>
			<div class="meeting-list scrollbar-new">
				<p
					*ngIf="
						(meetingForm.value.type !== 'quick' &&
							meetingAttendees.length < 1 &&
							inviteChannelUsersList.length < 1) ||
						(meetingForm.value.type === 'quick' &&
							meetingAttendees.length < 1 &&
							inviteTenantUsersList.length < 1 &&
							inviteContactUsersList.length < 1 &&
							listSMS.length < 1 &&
							listEmail.length < 1)
					"
					class="empty-list"
				>
					Your invite list is empty{{
						formControls.channelId.errors ? ", select a Channel to add attendees" : ""
					}}
				</p>

				<ng-container *ngFor="let user of meetingAttendees; let i = index">
					<!-- current attendees -->
					<div
						class="list-item"
						*ngIf="
							user.chatUserKeyNavigation.channelUserId !==
							_authService._user.profile.sub
						"
					>
						<p>
							{{ user.chatUserKeyNavigation.displayName }}
							-
							{{
								user.chatUserKeyNavigation.email ||
									(user.chatUserKeyNavigation.phone | mask: "(000) 000-0000")
							}}
						</p>
						<span
							class="remove-button"
							matTooltip="remove user"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'attendee')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>
				</ng-container>

				<ng-container *ngIf="meetingForm.value.type !== 'quick'">
					<!-- List of Channel Owners/Members -->
					<div
						*ngFor="let user of inviteChannelUsersList; let i = index"
						class="list-item"
					>
						<p
							matTooltip="{{ user.displayName }} {{ user.email }}"
							matTooltipClass="tooltip-default"
						>
							{{ user.displayName }} -
							{{ user.email ? user.email : "(no email)" }}
						</p>
						<span
							class="remove-button"
							matTooltip="remove user"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'channel')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>
				</ng-container>

				<ng-container *ngIf="meetingForm.value.type === 'quick'">
					<!-- List of Tenants -->
					<div
						*ngFor="let user of inviteTenantUsersList; let i = index"
						class="list-item"
					>
						<p
							matTooltip="{{ user.displayName }} {{ user.email }}"
							matTooltipClass="tooltip-default"
						>
							{{ user.displayName }} -
							{{ user.email ? user.email : "(no email)" }}
						</p>
						<span
							class="remove-button"
							matTooltip="remove user"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'tenant')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>

					<!-- List of Contacts -->
					<div
						*ngFor="let user of inviteContactUsersList; let i = index"
						class="list-item"
					>
						<p
							matTooltip="{{ user.displayName }} {{ user.email }}"
							matTooltipClass="tooltip-default"
						>
							{{ user.displayName }} -
							{{ user.email ? user.email : "(no email)" }}
						</p>
						<span
							class="remove-button"
							matTooltip="remove user"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'contact')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>

					<!-- List of SMS -->
					<div *ngFor="let sms of listSMS; let i = index" class="list-item">
						<p
							matTooltip="{{ sms.sms | mask: '(000) 000-0000' }}"
							matTooltipClass="tooltip-default"
						>
							{{ sms.sms | mask: "(000) 000-0000" }}
						</p>
						<span
							class="remove-button"
							matTooltip="remove #"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'sms')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>

					<!-- List of Emails -->
					<div *ngFor="let email of listEmail; let i = index" class="list-item">
						<p matTooltip="{{ email.email }}" matTooltipClass="tooltip-default">
							{{ email.email }}
						</p>
						<span
							class="remove-button"
							matTooltip="remove email"
							matTooltipClass="tooltip-default"
							(click)="removeAttendeeManager(i, 'email')"
						>
							<i class="fas fa-times"></i>
						</span>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<!-- invite by contact -->

	<div
		class="mb-1 borderless-input-group"
		*ngIf="inviteDisplay === 'contacts' && meetingForm.value.type === 'quick'"
	>
		<input
			matTooltip="search contacts"
			matTooltipClass="tooltip-default"
			type="text"
			id="user-contact-search"
			name="user-search"
			class="borderless-input"
			(keyup)="_getUsersFromContactsBySearchQuery()"
			placeholder="Search Contacts"
		/>
		<div
			class="no-button"
			*ngIf="!('user-contact-search' | checkInputLength)"
		></div>
		<button
			type="button"
			class="input-button"
			(click)="clearSearch('user-contact-search')"
			*ngIf="'user-contact-search' | checkInputLength"
		>
			<i class="fas fa-times"></i>
		</button>

		<div class="custom-select-list" *ngIf="searchContactUsersList.length > 0">
			<div
				class="list-item"
				*ngFor="let user of searchContactUsersList; let i = index"
				(click)="attendeeOrganizer(i, 'contact')"
			>
				{{ user.displayName }} -
				{{ user.email ? user.email : "(no email)" }}
			</div>
			<p
				class="empty-list"
				*ngIf="
					searchContactUsersList.length < 1 &&
					('user-contact-search' | checkInputLength)
				"
			>
				No Users Found
			</p>
		</div>
	</div>

	<!-- invite by channel -->

	<div
		class="mb-1 borderless-input-group"
		*ngIf="meetingForm.value.type !== 'quick' && selectedChannelId"
	>
		<input
			matTooltip="search contacts"
			matTooltipClass="tooltip-default"
			type="text"
			id="user-channel-search"
			name="user-search"
			class="borderless-input"
			(keyup)="_getUsersFromChannelBySearchQuery()"
			placeholder="Search Channel Members"
		/>
		<div
			class="no-button"
			*ngIf="!('user-channel-search' | checkInputLength)"
		></div>
		<button
			type="button"
			class="input-button"
			(click)="clearSearch('user-channel-search')"
			*ngIf="'user-channel-search' | checkInputLength"
		>
			<i class="fas fa-times"></i>
		</button>

		<div class="custom-select-list" *ngIf="searchChannelUsersList.length > 0">
			<div
				class="list-item"
				*ngFor="let user of searchChannelUsersList; let i = index"
				(click)="attendeeOrganizer(i, 'channel')"
			>
				{{ user.displayName }} -
				{{ user.email ? user.email : "(no email)" }}
			</div>
			<p
				class="empty-list"
				*ngIf="
					searchChannelUsersList.length < 1 &&
					('user-channel-search' | checkInputLength)
				"
			>
				No Users Found
			</p>
		</div>
	</div>

	<!-- invite by sms -->
	<div
		class="mb-1 borderless-input-group"
		*ngIf="inviteDisplay === 'sms' && meetingForm.value.type === 'quick'"
	>
		<input
			matTooltip="add phone number"
			matTooltipClass="tooltip-default"
			type="text"
			name="phone-number"
			class="borderless-input"
			placeholder="(###) ###-####"
			[(ngModel)]="listItemSMS"
			[ngModelOptions]="{ standalone: true }"
			required
			#sms="ngModel"
			mask="(000) 000-0000"
		/>
		<div class="no-button" *ngIf="!sms.valid"></div>
		<button
			type="button"
			class="input-button"
			(click)="attendeeOrganizer(null, 'sms')"
			*ngIf="sms.valid"
		>
			<span>Add</span>
		</button>
	</div>

	<!-- invite by email -->
	<div
		class="mb-1 borderless-input-group"
		*ngIf="inviteDisplay === 'email' && meetingForm.value.type === 'quick'"
	>
		<input
			matTooltip="add email"
			matTooltipClass="tooltip-default"
			type="text"
			name="email-address"
			class="borderless-input"
			placeholder="Enter Email"
			[(ngModel)]="listItemEmail"
			[ngModelOptions]="{ standalone: true }"
			minlength="6"
			maxlength="50"
			pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
			#email="ngModel"
			required
		/>
		<div class="no-button" *ngIf="!email.valid"></div>
		<button
			type="button"
			class="input-button"
			(click)="attendeeOrganizer(null, 'email')"
			*ngIf="email.valid"
		>
			<span>Add</span>
		</button>
	</div>

	<!--SUBMIT MEETING -->
	<div class="mt-4 group-items-center submit-group">
		<button
			*ngIf="incomingMeetingId && !createLock"
			(click)="cancelMeetingPrompt()"
			type="button"
			class="cancel-button"
			[disabled]="createLock"
		>
			Cancel Event
		</button>
		<!-- <button
			type="button"
			*ngIf="incomingMeetingId && meetingForm.value.type !== 'channel'"
			class="secondary-button"
			[disabled]="createLock"
			(click)="showManageUsers()"
		>
			Manage Users
		</button> -->
		<button
			type="button"
			id="sub-btn"
			*ngIf="!createLock && !scheduleError"
			class="primary-button"
			(click)="scheduleManager()"
			[disabled]="meetingForm.invalid || !formTouched"
		>
			{{ incomingMeetingId ? "Update Event" : "Schedule Meeting" }}
		</button>
		<button
			type="button"
			*ngIf="!meetingForm.invalid && createLock"
			class="primary-button"
			[disabled]="true"
		>
			<i class="fas fa-spinner spinner"></i>&nbsp;
			{{ incomingMeetingId ? "Updating..." : "Creating..." }}
		</button>
	</div>
</div>

<!-- INVITE START -->
<div class="create-quick-meeting" *ngIf="meetingForm && manageUsers">
	<div class="mt-4 group-items-center">
		<button type="button" class="cancel-button" (click)="closeManageUsers()">
			<!-- <i class="fas fa-spinner spinner"></i>&nbsp; -->
			Close
		</button>
		<!-- <button
			[routerLink]="['/channel', selectedChannelId]"
			type="button"
			class="primary-button"
		>
			Enter Meeting
		</button> -->
	</div>
</div>

<ng-container *ngIf="!meetingForm">
	<loading-home></loading-home>
</ng-container>
