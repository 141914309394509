
<div class="guest-sign-out flex-col-center-all w-100">
    <button 
        (click)="signOutGuest()"
        class="cancel-button mb-4">
        Sign Out
    </button>
    <button 
        class="primary-button is-link">
        <a href="{{accountSite}}register" target="_blank">
            Register with VIA3
        </a>
    </button>
</div>
