<div class="channel-create flex-col">
    <!-- title -->
    <div class="title-row mb-4">
        <div class="channel-title">
            <h3 class="title-thin flex align-center">
                <i class="fas fa-tv-retro me-3"></i>Create New Channel
            </h3>
        </div>
    </div>
    <!-- project -->
    <form *ngIf="showCreateProject" [formGroup]="newProject" class="project-wrap">
        <!--  -->
        <input
            type="text"
            formControlName="projectName"
            name="projectName"
            maxlength="200"
            class="borderless-input mb-4"
            style="margin-top: 0.2rem;"
            placeholder="Project Name* (required)"
        />
        <ng-container>
            <ng-container *ngIf="newProjectControls.projectName.touched && newProjectControls.projectName.errors">
                <div 
                    *ngIf="newProjectControls.projectName.errors.required" 
                    class="error-message">
                    Name is required.
                </div>
                <div 
                    *ngIf="newProjectControls.projectName.errors.minlength" 
                    class="error-message">
                    Name must be at least 2 characters.
                </div>
            </ng-container>
        </ng-container>
    </form>
    <!--  -->
    <form
        [formGroup]="newChannel"
        (document:keydown.enter)="
            showCreateProject 
            ? newChannel.valid && newProject.valid && !isLoadingProjects && !isCreatingChannel && !isDropdownOpen 
            : newChannel.valid && !isLoadingProjects && !isCreatingChannel && !isDropdownOpen
            ? createChannelStart()
            : ''
        "
        class="flex-col"
    >
        <!--  -->
        <mat-select
            *ngIf="!showCreateProject"
            (openedChange)="handleDropdownEnterSubmitLock($event, 'channelProject')"
            name="channelProject"
            class="flex borderless-select mb-4"
            formControlName="channelProject"
            placeholder="- Select a Project - * (required)"
            id="channelProject"
        >
            <mat-option *ngIf="isLoadingProjects" diasbled
                ><i
                    class="fas fa-spinner spinner loading-projects"
                ></i>
                loading projects ...
            </mat-option>
            <mat-option *ngIf="hasNoProjects" diasbled>
                you have not created any projects.
            </mat-option>
            <!-- map projects to options -->
            <ng-container
                *ngFor="let project of projects; index as i"
            >
                <mat-option [value]="project.teamProjectId">
                    <i 
                        class="fal"
                        [class.fa-lock]="project.private"
                        [class.fa-users]="!project.private"
                        matTooltip="{{project.private ? 'private':'public'}}"
                        matTooltipClass="tooltip-default">
                    </i>
                    {{project.name}}
                </mat-option>
            </ng-container>
        </mat-select>
        <!--  -->
        <ng-container
            *ngIf="
                !showCreateProject &&
                newChannelControls.channelProject.touched &&
                newChannelControls.channelProject.errors
            "
        >
            <div
                *ngIf="newChannelControls.channelProject.errors.required"
                class="error-message mb-2"
            >
                Project is required.
            </div>
        </ng-container>
        <!--  -->
        <mat-checkbox 
            class="mb-4"
            [checked]="showCreateProject"
            value="{{showCreateProject}}"
            (click)="displayCreateProject()">
            Create new project
        </mat-checkbox>
        <!--  -->
        <input
            type="text"
            formControlName="channelName"
            name="channelName"
            maxlength="200"
            class="borderless-input mb-4"
            placeholder="Channel Name* (required)"
        />

        <ng-container
            *ngIf="
                newChannelControls.channelName.touched &&
                newChannelControls.channelName.errors
            "
        >
            <div
                *ngIf="newChannelControls.channelName.errors.required"
                class="error-message"
            >
                Name is required.
            </div>
            <div
                *ngIf="newChannelControls.channelName.errors.minlength"
                class="error-message"
            >
                Name must be at least 2 characters.
            </div>
        </ng-container>
        <!--  -->
        <input
            type="text"
            formControlName="channelTopic"
            name="channelTopic"
            maxlength="50"
            class="borderless-input mb-3"
            placeholder="Topic"
        />
        <!-- welcome message -->
        <div class="box-quote my-4">
            <textarea
                type="text"
                (keydown.enter)="stopProp($event)"
                formControlName="channelWelcomeMessage"
                name="channelWelcomeMessage"
                spellcheck="false"
                maxlength="300"
                class="box-quote-textarea scrollbar-new"
                placeholder="You can enter the channels welcome message here..."
            >
            </textarea>
            <i class="quote-icon fas fa-quote-right flex-center-all"></i>
        </div>
        <!-- submit -->
        <div class="flex justify-center mt-4">
            <button
                class="primary-button"
                [disabled]="showCreateProject ? newChannel.invalid || newProject.invalid || isLoadingProjects || isCreatingChannel : newChannel.invalid || isLoadingProjects || isCreatingChannel"
                [class.invalid-form]="isCreatingChannel"
                (click)="createChannelStart()"
            >
                <ng-container *ngIf="!isCreatingChannel">
                    Create Channel
                </ng-container>
                <ng-container *ngIf="isCreatingChannel">
                    <i class="fas fa-spinner spinner"></i>&nbsp;Creating...
                </ng-container>
            </button>
        </div>
    </form>
</div>
