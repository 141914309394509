<ng-container *ngIf="!isLoadingChannelInfo">
    <ng-container *ngIf="isModalVersion">
        <div class="title-row">
            <div class="channel-title flex">
                <i class="fas fa-users me-3"></i>
                <h3 class="title-thin text-ellipsis two-line-clamp">
                    {{ channelInfo.name }}
                </h3>
            </div>
        </div>
    </ng-container>
    <div 
        class="list-creator scrollbar-new"
        [class.is-modal]="isModalVersion">
        <div
            class="user-container flex-center-all"
            [class.video-started]="videoStarted"
            [class.is-modal]="isModalVersion">
            <div class="left-container mb-auto" [class.is-modal]="isModalVersion">
                <div
                    class="user-section in-channel-section box-quote scrollbar-new">
                    <h5
                        class="in-quote-title flex-center-all"
                        [class.is-modal]="isModalVersion">
                        In Channel Now
                    </h5>
                    <ng-container>
                        <div
                            *ngIf="inChannelList.length > 0 && !isloadingUsersInChannel"
                            class="channel-users in-channel">
                            <ng-container
                                *ngFor="let user of inChannelList; index as i">
                                <div
                                    *ngIf="user.userStatus !== 'offline'"
                                    class="user-card">
                                    <div class="user-img">
                                        <img
                                            *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                            src="https://profile-images.azureedge.us/{{user.hash}}"alt="user"
                                            (error)="handleBrokenImage('inChannelList', i)"/>
                                        <ng-template #defaultImg>
                                            <img
                                                *ngIf="user.isGuest; else notGuest"
                                                src="https://profile-images.azureedge.us/1"
                                                alt="profile picture"
                                            />
                                            <ng-template #notGuest>
                                                <div 
                                                    class="default-user-img circle flex-center-all me-2"
                                                    [class.every-first]="i % 4 === 0"
                                                    [class.every-second]="i % 4 === 1"
                                                    [class.every-third]="i % 4 === 2"
                                                    [class.every-fourth]="i % 4 === 3">
                                                    {{user.username|defaultImageOverlay}}
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <span
                                        class="status-bar {{ user.userStatus }}"
                                        matTooltip="{{ user.userStatus }}"
                                        matTooltipClass="tooltip-default"
                                    ></span>
                                    <div class="user-name">
                                        {{ user.displayName }}
                                        <!-- <span class="second-name">
                                            {{ user.username }}
                                        </span> -->
                                    </div>
                                </div>
                            </ng-container>
                            <div
                                class="empty-list"
                                *ngIf="isloadingUsersInChannel">
                                <p>Loading Users ...</p>
                            </div>
                            <div
                                class="empty-list"
                                *ngIf="inChannelList.length === 0">
                                <p>No users in channel.</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="right-container mb-auto" [class.is-modal]="isModalVersion">
                <ng-container *ngIf="!isQuickMeeting && !isViewer">
                    <div 
                        *ngIf="!showUserSearch"
                        class="button-section">
                        <button
                            type="button"
                            (click)="showSearch(true)"
                            class="primary-button">
                            <i class="fas fa-user-tie"></i> Add Managers
                        </button>
                        <button
                            type="button"
                            (click)="showSearch(false)"
                            class="primary-button">
                            <i class="fas fa-user-alt"></i> Add Members
                        </button>
                    </div>
                </ng-container>
                <div
                    class="add-users-group"
                    *ngIf="
                        showUserSearch ||
                        (isQuickMeeting &&
                            !showEmailInvite &&
                            !showSMSInvite &&
                            isCreator)">
                    <div
                        class="mt-1 mb-2 borderless-input-group"
                        [ngClass]="{
                            'mb-0': isQuickMeeting,
                            'mb-2': !isQuickMeeting
                        }">
                        <input
                            type="text"
                            id="user-search-edit-channel"
                            class="borderless-input"
                            name="user-search"
                            value=""
                            (input)="triggerSearch($event)"
                            [placeholder]="
                                isQuickMeeting
                                    ? 'Search Organization'
                                    : 'Search for Users'"/>
                        <button
                            type="button"
                            class="input-button"
                            (click)="hideSearch()">
                            <i
                                class="fas fa-times-circle"
                                matTooltip="clear search"
                                matTooltipClass="tooltip-default"></i>
                        </button>
                        <div
                            *ngIf="searchUsersList.length > 0"
                            class="custom-select-list">
                            <span
                                *ngFor="let user of searchUsersList; index as i"
                                class="user-card py-1">
                                <div class="user-img">
                                    <img
                                        *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                        src="https://profile-images.azureedge.us/{{
                                            user.hash
                                        }}"
                                        alt="user"
                                        (error)="handleBrokenImage('searchUsersList', i)"/>
                                    <ng-template #defaultImg>
                                        <div 
                                            class="default-user-img circle flex-center-all me-2"
                                            [class.every-first]="i % 4 === 0"
                                            [class.every-second]="i % 4 === 1"
                                            [class.every-third]="i % 4 === 2"
                                            [class.every-fourth]="i % 4 === 3">
                                            {{user.username|defaultImageOverlay}}
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="user-name">
                                    {{ user.displayName }}
                                    <!-- <span class="second-name">{{
                                        user.username
                                    }}</span> -->
                                </div>
                                <!--Add User To Channel Button -->
                                <button
                                    type="button"
                                    (click)="
                                        isQuickMeeting
                                            ? _addMeetingAttendee(i)
                                            : addUserToChannel(i,isManagerBeingAdded)"
                                    [disabled]="isAddingInvite"
                                    class="single-user-btn me-2">
                                    <!-- icon -->
                                    <i
                                        matTooltip="add user"
                                        matTooltipClass="tooltip-default"
                                        class="fas fa-user-plus"
                                    ></i>
                                </button>
                                <!--Add User To Channel Button-->
                            </span>
                            <p
                                class="empty-list"
                                *ngIf="
                                    searchUsersList.length < 1 && 'user-search-edit-channel' | checkInputLength">
                                No Users Found
                            </p>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!isQuickMeeting">
                    <div class="user-section manager-section box-quote">
                        <h5
                            class="in-quote-title"
                            [class.is-modal]="isModalVersion">
                            Managers
                        </h5>
                        <ng-container>
                            <div
                                *ngIf="ownersList.length > 0"
                                class="channel-users">
                                <div
                                    *ngFor="let user of ownersList; index as i"
                                    class="user-card">
                                    <div class="user-img">
                                        <img
                                            *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                            src="https://profile-images.azureedge.us/{{user.hash}}"alt="user"
                                            (error)="handleBrokenImage('ownersList', i)"/>
                                        <ng-template #defaultImg>
                                            <div 
                                                class="default-user-img circle flex-center-all me-2"
                                                [class.every-first]="i % 4 === 0"
                                                [class.every-second]="i % 4 === 1"
                                                [class.every-third]="i % 4 === 2"
                                                [class.every-fourth]="i % 4 === 3">
                                                {{user.username|defaultImageOverlay}}
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="user-name">
                                        {{ user.displayName }}
                                        <!-- <span class="second-name">{{
                                            user.username
                                        }}</span> -->
                                    </div>
                                    <span
                                        class="creator-icon ms-auto"
                                        *ngIf="
                                            user.channelUserId ===
                                            channelInfo.channelUserId">
                                        <i
                                            class="far fa-crown"
                                            matTooltip="channel creator"
                                            matTooltipClass="tooltip-default"
                                        ></i>
                                    </span>
                                    <!--User Options Button -->
                                    <button
                                        type="button"
                                        (click)="showManagerDropdown(i)"
                                        (mouseleave)="hideManagerDropdown(i)"
                                        class="manage-users-options-btn"
                                        *ngIf="
                                            user.channelUserId !==
                                                channelInfo.channelUserId &&
                                            !isViewer && (isAdmin || isOwner || isCreator)">
                                        <!-- icon -->
                                        <i
                                            matTooltip="user options"
                                            matTooltipClass="tooltip-default"
                                            class="fas fa-ellipsis-v"
                                        ></i>
                                        <!-- options dropdown -->
                                        <div
                                            id="manage-manager-dropdown-{{ i }}"
                                            class="manage-users-options-dropdown mt-1 py-2"
                                        >
                                            <!-- demote user -->
                                            <ng-container>
                                                <button
                                                    type="button"
                                                    (click)="demoteUser(i)"
                                                    class="list-item"
                                                >
                                                    <i
                                                        class="far fa-edit edit-icon text-center"
                                                    ></i
                                                    >Make A Member
                                                </button>
                                            </ng-container>
                                            <!-- demote user -->
                                            <!-- remove user from channel -->
                                            <ng-container>
                                                <button
                                                    type="button"
                                                    class="list-item"
                                                    (click)="removeUserFromList(i, true)">
                                                    <i class="far fa-user-minus"></i
                                                    >Remove From Channel
                                                </button>
                                            </ng-container>
                                            <!-- remove user from channel -->
                                        </div>
                                    </button>
                                    <!--User Optinons Button-->
                                </div>
                                <div
                                    class="empty-list"
                                    *ngIf="ownersList.length < 1">
                                    <p>There are no managers</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="user-section members-section box-quote">
                        <h5
                            class="in-quote-title"
                            [class.is-modal]="isModalVersion">
                            Members
                        </h5>
                        <ng-container>
                            <div
                                *ngIf="memberList.length > 0"
                                class="channel-users">
                                <div
                                    *ngFor="let user of memberList; index as i"
                                    class="user-card">
                                    <div class="user-img">
                                        <img 
                                            *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                            src="https://profile-images.azureedge.us/{{user.hash}}"alt="user"
                                            (error)="handleBrokenImage('memberList', i)"/>
                                        <ng-template #defaultImg>
                                            <div 
                                                class="default-user-img circle flex-center-all me-2"
                                                [class.every-first]="i % 4 === 0"
                                                [class.every-second]="i % 4 === 1"
                                                [class.every-third]="i % 4 === 2"
                                                [class.every-fourth]="i % 4 === 3">
                                                {{user.username|defaultImageOverlay}}
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="user-name">
                                        {{ user.displayName }}
                                        <!-- <span class="second-name">{{
                                            user.username
                                        }}</span> -->
                                    </div>
                                    <!--User Options Button -->
                                    <button
                                        type="button"
                                        (click)="showMemberDropdown(i)"
                                        (mouseleave)="hideMemberDropdown(i)"
                                        class="manage-users-options-btn"
                                        *ngIf="
                                            user.channelUserId !==
                                            channelInfo.channelUserId && !isViewer && (isAdmin || isOwner || isCreator)">
                                        <!-- icon -->
                                        <i
                                            matTooltip="user options"
                                            matTooltipClass="tooltip-default"
                                            class="fas fa-ellipsis-v"
                                        ></i>
                                        <!-- options dropdown -->
                                        <div
                                            id="manage-member-dropdown-{{ i }}"
                                            class="manage-users-options-dropdown mt-1 py-2">
                                            <!-- promote user -->
                                            <ng-container>
                                                <button
                                                    *ngIf="isAdmin || isOwner || isCreator"
                                                    type="button"
                                                    (click)="promoteUser(i)"
                                                    class="list-item">
                                                    <i
                                                        class="far fa-edit edit-icon text-center"
                                                    ></i
                                                    >Make A Manager
                                                </button>
                                            </ng-container>
                                            <!-- promote user -->
                                            <!-- remove user from channel -->
                                            <ng-container>
                                                <button
                                                    type="button"
                                                    class="list-item"
                                                    (click)="removeUserFromList(i, false)">
                                                    <i class="far fa-user-minus"></i
                                                    >Remove From Channel
                                                </button>
                                            </ng-container>
                                            <!-- remove user from channel -->
                                        </div>
                                    </button>
                                    <!--User Optinons Button-->
                                </div>
                                <div
                                    class="empty-list"
                                    *ngIf="memberList.length < 1">
                                    <p>There are no members</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- Quick Meeting Start -->
                <ng-container *ngIf="isQuickMeeting && isMeetingCreator">
                    <div
                        class="button-section"
                        *ngIf="!showEmailInvite && !showSMSInvite">
                        <button
                            type="button"
                            (click)="displayEmailInvite()"
                            class="primary-button">
                            <i class="fas fa-envelope"></i> Invite By Email
                        </button>
                        <button
                            type="button"
                            (click)="displaySMSInvite()"
                            class="primary-button">
                            <i class="fal fa-sms"></i> Invite By SMS
                        </button>
                    </div>
                    <div class="add-users-group" *ngIf="showEmailInvite">
                        <form
                            [formGroup]="inviteForm"
                            class="mt-1 mb-0 borderless-input-group">
                            <input
                                type="text"
                                class="borderless-input"
                                name="email-invite"
                                placeholder="Enter Invitee Email"
                                formControlName="email"
                                maxlength="50"/>
                            <button
                                type="button"
                                class="input-button"
                                [disabled]="true">
                                <i class="fas fa-envelope"></i>
                            </button>
                        </form>
                    </div>
                    <div class="add-users-group" *ngIf="showSMSInvite">
                        <form
                            [formGroup]="inviteForm"
                            class="mt-1 mb-0 borderless-input-group">
                            <input
                                type="text"
                                class="borderless-input"
                                name="sms-invite"
                                placeholder="(###) ###-####"
                                mask="(000) 000 - 0000"
                                formControlName="sms"/>
                            <button
                                type="button"
                                class="input-button"
                                [disabled]="true"
                                matTooltip="click to cancel sending an text message"
                                matTooltipClass="tooltip-default">
                                <i class="fal fa-sms"></i>
                            </button>
                        </form>
                    </div>
                    <div
                        class="button-section"
                        *ngIf="showEmailInvite || showSMSInvite">
                        <button
                            type="button"
                            *ngIf="showEmailInvite"
                            (click)="_addGuestMeetingAttendee('email')"
                            class="secondary-button"
                            [disabled]="emailControl.invalid || isAddingInvite"
                            matTooltip="{{
                                emailControl.valid
                                    ? 'click to invite through email'
                                    : 'enter a valid email address'
                            }}"
                            matTooltipClass="tooltip-default">
                            Send
                        </button>
                        <button
                            type="button"
                            *ngIf="showSMSInvite"
                            (click)="_addGuestMeetingAttendee('sms')"
                            class="secondary-button"
                            [disabled]="smsControl.invalid || isAddingInvite"
                            matTooltip="{{
                                smsControl.valid
                                    ? 'click to invite through sms'
                                    : 'enter a valid phone number'
                            }}"
                            matTooltipClass="tooltip-default">
                            Send
                        </button>
                        <button
                            type="button"
                            (click)="hideInvite()"
                            class="cancel-button"
                            matTooltip="click to cancel sending an email"
                            matTooltipClass="tooltip-default">
                            Cancel
                        </button>
                    </div>
                </ng-container>
                <!-- atendees/invitees list if isCreator && isQuick (can remove invitee) [look in edit meeting invitee list!] -->
                <div
                    class="user-section members-section box-quote"
                    *ngIf="isQuickMeeting && isMeetingCreator">
                    <h5 class="in-quote-title" [class.is-modal]="isModalVersion">
                        Invitees
                    </h5>
                    <div class="channel-users" *ngIf="meetingAttendees.length > 0">
                        <ng-container
                            *ngFor="let user of meetingAttendees; index as i">
                            <ng-container
                                *ngIf="
                                    user.chatUserKeyNavigation.channelUserId !==
                                    userInfo.channelUserId">
                                <div class="user-card">
                                    <div class="user-img">
                                        <img
                                            *ngIf="!!user.chatUserKeyNavigation.hash && user.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                                            src="https://profile-images.azureedge.us/{{
                                                user.chatUserKeyNavigation.hash
                                            }}"alt="user"
                                            (error)="handleBrokenImage('meetingAttendees', i)"/>
                                        <ng-template #defaultImg>
                                            <img
                                                *ngIf="user.chatUserKeyNavigation.isGuest; else notGuest"
                                                src="https://profile-images.azureedge.us/1"
                                                alt="profile picture"
                                            />
                                            <ng-template #notGuest>
                                                <div 
                                                    class="default-user-img circle flex-center-all me-2"
                                                    [class.every-first]="i % 4 === 0"
                                                    [class.every-second]="i % 4 === 1"
                                                    [class.every-third]="i % 4 === 2"
                                                    [class.every-fourth]="i % 4 === 3">
                                                    {{user.chatUserKeyNavigation.username|defaultImageOverlay}}
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !user.chatUserKeyNavigation.isGuest;
                                            else guest">
                                        <div class="user-name">
                                            {{
                                                user.chatUserKeyNavigation
                                                    .displayName
                                            }}
                                            <span class="second-name">{{
                                                user.chatUserKeyNavigation.username
                                            }}</span>
                                        </div>
                                    </ng-container>
                                    <ng-template #guest>
                                        <div class="user-name">
                                            {{
                                                user.chatUserKeyNavigation
                                                    .displayName
                                            }}
                                            <span class="second-name">{{
                                                user.chatUserKeyNavigation.email ||
                                                    (user.chatUserKeyNavigation
                                                        .phone
                                                        | mask: "(000) 000-0000")
                                            }}</span>
                                        </div>
                                    </ng-template>
                                    <button
                                        matTooltip="remove invitee"
                                        matTooltipClass="tooltip-default"
                                        class="single-user-btn"
                                        (click)="
                                            removeUserFromCurrentAttendeesList(i)
                                        ">
                                        <i class="fas fa-user-minus"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="empty-list" *ngIf="meetingAttendees.length < 1">
                        <p>There are no invitees</p>
                    </div>
                </div>
            </div>
            <!--End Of Right Container-->
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isLoadingChannelInfo">
    <loading-home></loading-home>
</ng-container>
