import { Component } from '@angular/core';


@Component({
    selector: 'app-file-storage-homepage',
    templateUrl: './file-storage-homepage.component.html',
    styleUrls: [] // no css ... 
})

export class FileStorageHomepageComponent {
} 
// 