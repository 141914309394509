import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastNotificationService } from "./toast-notification.service";

@Injectable({
    providedIn: "root",
})
export class GuestService {
    constructor(public _router: Router, public _toast: ToastNotificationService) {}

    setInvitedChannelId(channelId:string):void {
        localStorage.setItem('channelId', channelId);
        console.info("🍒🍒🍒🍒🍒 guest invite channelId set to local store :", localStorage.getItem('channelId'))
    }

    routeToChannelInvited() {
        let channelId = localStorage.getItem('channelId');
        console.info("🍇🍇🍇🍇🍇 unauthorized guest! > routing to channel invited : ", channelId)
        if(channelId) {
            // this._toast.warn("Unauthorized area, returning to your meeting.");
            this._router.navigate(['/channel/', channelId]);
        } else {
            // this._toast.warn("Unauthorized user.");
            this._router.navigate(['/unauthorized']);
        }
    }
}


