<div class="compose">
	<ng-container *ngIf="!composeUserId">
		<div class="compose-search">
			<div class="borderless-input-group mb-2">
				<input
					type="text"
					id="user-search-compose"
					class="borderless-input"
					name="user-search"
					value=""
					autocomplete="off"
					(input)="triggerSearch($event)"
					maxlength="56"
					[disabled]="composeList.length >= 8"
					placeholder="{{
						composeList.length < 8 ? 'Find Recipients' : 'Max Recipients Reached'
					}}"
				/>
				<button
					type="button"
					class="input-button"
					(click)="clearSearch()"
					[disabled]="!isSearching"
				>
					<i
						class="fas"
						[ngClass]="{
							'fa-times-circle': isSearching,
							'fa-search': !isSearching
						}"
						matTooltip="clear search"
						matTooltipClass="tooltip-default"
					></i>
				</button>
				<div
					class="custom-select-list"
					*ngIf="isSearching && searchUsersList.length > 0"
				>
					<button
						*ngFor="let user of searchUsersList; let i = index"
						class="user-card selectable py-1"
						(click)="addUserToComposeList(i)"
					>
						<div class="user-img">
							<img src="https://profile-images.azureedge.us/{{ user.hash }}" alt="" />
						</div>
						<div class="user-name">
							{{ user.displayName }}
							<span class="second-name">{{ user.username }}</span>
						</div>
					</button>
				</div>
			</div>
		</div>

		<div>
			<div class="compose-list scrollbar-new" [class.group-list]="composeList.length > 1">
				<div class="list-title">
					<p>Send To:</p>
				</div>
				<div *ngFor="let user of composeList; let i = index" class="chat-user">
					<div class="user-img">
						<img
							src="https://profile-images.azureedge.us/{{ user.hash }}"
							alt="{{ user.displayName }}'s profile picture"
						/>
					</div>
					<p class="chat-name">{{ user.displayName }}</p>
					<i
						(click)="removeUserFromComposeList(i)"
						class="fas fa-user-minus"
						matTooltip="remove from list"
						matTooltipClass="tooltip-default"
					></i>
				</div>
			</div>
			<p
				class="search-message"
				*ngIf="composeList.length > 1 && composeList.length < 8"
			>
				Max 8 Recipients
			</p>
			<p class="search-warning" *ngIf="composeList.length > 7">max 8 recipients.</p>
		</div>

		<div class="compose-name" *ngIf="isGroup">
			<form class="name-form" [formGroup]="groupName">
				<input
					type="text"
					class="borderless-input mb-2"
					name="group-name"
					autocomplete="off"
					maxlength="56"
					formControlName="name"
					placeholder="Group Name (Optional)"
				/>
			</form>
			<p *ngIf="nameControl.touched && nameControl.errors.pattern" class="search-warning">invalid character.</p>
		</div>
	</ng-container>

	<div class="compose-body">
		<p class="loading-users" *ngIf="!messageListLoaded && composeList.length > 0">
			Starting Message...
		</p>

        <div class="form-btns">
            <button
                matTooltip="send message"
                matTooltipClass="tooltip-default"
                type="button"
                class="primary-button"
                (click)="isGroup ? channelGroup() : channelCheckSolo()"
                [disabled]="nameControl.invalid"
                *ngIf="composeList.length > 0 && messageListLoaded"
            >
                {{ isGroup ? "Create Group Message" : "Begin Chatting" }}
            </button>
        </div>
    </div>
</div>
