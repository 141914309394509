<div class="channel-info flex-col">
    <!-- title -->
    <div class="title-row flex mb-4">
        <div class="flex-col me-3">
            <div class="contact-image">
                <!--  -->
                <ng-container *ngIf="contactInfo.contactChatUserKeyNavigation else isCustom">
                    <div class="user-img">
                        <img 
                            class="circle"
                            src="https://profile-images.azureedge.us/{{contactInfo.contactChatUserKeyNavigation.hash}}" alt="contact" 
                            onerror="this.src='https://profile-images.azureedge.us/1'">
                    </div>
                </ng-container>
                <ng-template #isCustom>
                    <div class="user-img">
                        <img 
                            class="circle"
                            src="https://profile-images.azureedge.us/{{contactInfo.hash}}" alt="contact" 
                            onerror="this.src='https://profile-images.azureedge.us/1'">
                    </div>
                </ng-template>
                <!-- 🚧 add fav star here next to img-->
                <div 
                    *ngIf="contactInfo.isFavorite"
                    matTooltip="is favorite" 
                    matTooltipClass="tooltip-default" 
                    class="is-favorite">
                    <i class="fas fa-star"></i>
                </div>
            </div>
        </div>
        <div class="flex-col text-ellipsis">
            <h3 
                class="title-thin text-ellipsis mb-2" 
                style="line-height: 3.5rem;">
                {{contactInfo.displayName}} 
            </h3>
            <!-- email -->
            <div class="contact-email text-ellipsis flex mb-2">
                <p class="me-2">Email: </p><strong class="text-ellipsis">{{contactInfo.email}}</strong>
            </div>
            <!-- phone -->
            <div class="contact-phone flex mb-2">
                <p class="me-2">Phone: </p><strong>{{(contactInfo.phone|mask: "(000) 000-0000") || '&nbsp;-'}}</strong>
            </div>
            <!-- status badge -->
            <div 
                *ngIf="!contactInfo.isCustom" 
                class="px-4 py-1 mt-1 contact-status {{contactInfo.userStatus}}">
                {{contactInfo.userStatus|titlecase}}
            </div>
            <!-- edit custom contact me-3 -->
            <button 
                *ngIf="contactInfo.isCustom" 
                (click)="editContact()"
                class="outline-button mt-1 edit-contact">
                <i class="far fa-user-edit me-3"></i>Edit
            </button>
        </div>
    </div>
    <!--  -->
    <div class="break mb-4"></div>
    <!-- full name -->
    <div class="contact-name flex mb-2">
        <div class="flex-col w-50">
            <div class="flex text-ellipsis">
                <p class="me-2">First name: </p><strong class="text-ellipsis">{{contactInfo.firstName || '&nbsp;-'}}</strong>
            </div>
        </div>
        <div class="flex-col w-50 ms-1">
            <div class="flex text-ellipsis">
                <p class="me-2">Last name: </p><strong class="text-ellipsis">{{contactInfo.lastName || '&nbsp;-'}}</strong>
            </div>
        </div>
    </div>
    <!-- company/position -->
    <div class="contact-company flex mb-2">
        <div class="flex-col w-50">
            <div class="flex text-ellipsis">
                <p class="me-2">Company: </p><strong class="text-ellipsis">{{contactInfo.company || '&nbsp;-'}}</strong>
            </div>
        </div>
        <div class="flex-col w-50 ms-1">
            <div class="flex text-ellipsis">
                <p class="me-2">Position: </p><strong class="text-ellipsis">{{contactInfo.position || '&nbsp;-'}}</strong>
            </div>
        </div>
    </div>
    <!-- fax number -->
    <div class="contact-fax flex mb-2">
        <div class="flex">
            <p class="me-2">Fax number: </p><strong>{{contactInfo.faxNumber || '&nbsp;-'}}</strong>
        </div>
    </div>
    <!-- address -->
    <div class="contact-address flex mb-4">
        <div class="flex">
            <p class="me-2">Address: </p><strong class="break-word">{{contactInfo.address || '&nbsp;-'}}</strong>
        </div>
    </div>
    <div class="break mb-4"></div>
    <!-- notes 🚧 edit add note ... -->
    <div class="mb-4">
        <p class="me-2 mb-2 flex">
            Notes: 
            <button
                *ngIf="isFormTouched"
                @slideInLeft
                (click)="addNote()"
                class="ms-3 add-note">
                <strong>
                    <i class="fas fa-plus me-2"></i>Add Note
                </strong>
            </button>
        </p>
        <form 
            [formGroup]="newContactNote">
            <div class="box-quote mt-1 mb-2">
                <textarea 
                    id="note-focus"
                    spellcheck="false"
                    type="text"
                    name="notes"
                    formControlName="notes"
                    class="box-quote-textarea scrollbar-new"
                    placeholder="You can enter notes on this contact here...">
                </textarea>
                <i class="quote-icon fas fa-quote-right flex-center-all"></i>
            </div>
        </form>
    </div>
    <!--  -->
    <div class="break mb-4"></div>
    <!-- buttons -->
    <div class="contact-options flex">
        <button 
            *ngIf="!contactInfo.isCustom" 
            (click)="getDmChannel(contactInfo)"
            class="primary-button mx-auto"
            >
            <i class="far fa-comment-alt me-3 message"></i>Message
        </button>
        <button 
            class="primary-button mx-auto">
            <a href="mailto:{{contactInfo.email}}">
                <i class="far fa-envelope me-3 email"></i>Email
            </a>
        </button>
        <!-- 🚧 convert to mail to w/ body & sub -->
        <button 
            *ngIf="isAdmin && contactInfo.isCustom"
            (click)="sendRegisterLinkToContact()"
            class="primary-button mx-auto">
            <ng-container *ngIf="!isSendingRegisterLink; else sendingLink">
                <i class="far fa-link me-3"></i>Invite to VIA3
            </ng-container>
            <ng-template #sendingLink>
                <i class="fas fa-spinner spinner"></i>&nbsp;Getting Link 
            </ng-template>
        </button>
    </div>
</div>
