import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ChannelService } from 'src/app/services/channel-service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { AuthService } from 'src/app/core/auth.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { standardEmailRegex } from 'src/app/shared/varaibles'; // this could break ... if so pass a regex : 'string' ... 
import { consoleLogStyle } from '../../shared/varaibles';
import { ChannelUser } from 'src/app/shared/interface/channel-user';
import { ChannelFull } from 'src/app/shared/interface/channel-full';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
    selector: 'manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isModalVersion: boolean;
    @Input() channelInfo: ChannelFull;
    @Input('channelId') channelId: string; // if from channel card, need to get channel info with this ... 
    public isLoadingChannelInfo: boolean;
    
    @Input() userInfo: ChannelUser;
    @Output() channelUpdated = new EventEmitter();
    
    // new ad-hoc 
    @Input() isQuickMeeting: boolean;
    @Input() isMeetingCreator: boolean;
    @Input() meetingId: string;
    @Input() meetingAttendees: any[];
    @Input() videoStarted: boolean;
    @Input() isViewer: boolean;
    @Output() updateAttendeesList = new EventEmitter();

    public isAdmin: boolean;
    public isCreator: boolean = false; // not needed w/ new input from Channel-Primary ??? 
    public isOwner: boolean;

    public userSearchBar: HTMLInputElement; // should be using @ViewChild ... 
    public searchUsersList: any[] = [];
    public searchUsersPag: any = {};

    public ownersList: any[] = [];
    public memberList: any[] = [];
    public inChannelList: any[] = [];
    public isloadingUsersInChannel: boolean = true;

    public showUserSearch: boolean;
    public showEmailInvite: boolean;
    public showSMSInvite: boolean;
    public isManagerBeingAdded: boolean;
    public isAddingInvite: boolean;

    public inviteForm: FormGroup;

    private subscriptions: Subscription[] = [];

    // for holding the serch string 
    private searchSubject = new Subject<string>();

    constructor(
        private _chManageService: ProjectChannelService,
        private _meetingService: MeetingService,
        private _channelService: ChannelService,
        private _apiCalls: ApiCallsService,
        private _authService: AuthService,
        private _toastService: ToastNotificationService,
    ) {
        this.subscriptions.push(this._channelService.onJoinNotification$.subscribe((joinObj: any) => {
            this.getChannelUsersInRoom();
        }));

        this.subscriptions.push(this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            this.getChannelUsersInRoom();
        }));

        //When users get added or removed from channel
        this.subscriptions.push(this._channelService.onGetChannelUsersInRoomEvent$.subscribe(() => {
            // console.log("onGetChannelUsersInRoomEvent sub");
            this.channelUpdated.emit();
        }));

        this.subscriptions.push(this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            this.inChannelList.forEach((channel) => {
                channelUserStatusList.forEach((channelUser) => {
                    if (channel.channelUserId === channelUser.channelUserId) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                channel.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                channel.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                channel.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                channel.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            channel.userStatus = "hidden";
                        } else if (channelUser.selectedStatus === 2) {
                            channel.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            channel.userStatus = "brb";
                        }
                    }
                });
            });
        }));

        this.subscriptions.push(this._channelService.onGuestDisplayNameUpdatedEvent$.subscribe((channelId: any) => {
            if (this.channelInfo.channelId.toLowerCase() === channelId.toLowerCase()) {
                // console.log("update guest username sub");
                this.getChannelUsersInRoom();
            }
        }));
    }

    ngOnInit() {
        if (!this.isModalVersion) {
            this.allUsersSeperate();
        }
        if (this.isQuickMeeting) {
            this.inviteForm = new FormGroup({
                sms: new FormControl('', [
                    Validators.required, 
                    Validators.minLength(10)
                ]),
                email: new FormControl('', [
                    Validators.required, 
                    // this could break ... if so pass a regex : 'string' ... 
                    Validators.pattern(standardEmailRegex)
                ]),
            });
        }

        //creates the search subject for the search debounce
        //delays calling the search query until the user paused for the time allotted (400ms here)
        this.searchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
              console.log("Search Query Passed: "+ searchQuery); 
              this._getUsersBySearchQuery();
        });
    }

    get smsControl() { return this.inviteForm.get('sms'); }
    get emailControl() { return this.inviteForm.get('email'); }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.channelInfo) {
            if(!!this.channelInfo) {
                // console.log("%c 👤 owners/managers list changed ", consoleLogStyle, this.ownersList);
                // console.log("%c 👤 chan info ", consoleLogStyle, this.channelInfo);
                this.isLoadingChannelInfo = true;
                !this.isModalVersion ? this.allUsersSeperate() : '';
            }
        }
        if (changes.channelId) {
            if(!!this.channelId) {
                // console.log("%c 👤 chan info ", consoleLogStyle, this.channelInfo);
                if(this.isModalVersion) {
                    this.isLoadingChannelInfo = true;
                    this.getChannel();
                } 
            }
        }
        if (changes.userInfo) {
            this.isAdmin = this.userInfo.roles.some(role => role.toLowerCase() === 'admin')
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    triggerSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery);
    }

    // need to get channel info by id here if modal from channel card ... 
    getChannel() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe(data => {
                if(data.status === 200) {
                    this.channelInfo = data.body;
                    this.allUsersSeperate();
                    this.getChannelUsersInRoom();
                    // console.info('channel info?', this.channelInfo)
                } else {
                    console.error("error getting channel info by channel id", data)
                    this.isLoadingChannelInfo = false;
                }
            });
    }

    // this would not be needed with an update to the channelInfo data object ... 
    allUsersSeperate() {
        this.ownersList = [];
        this.channelInfo.chatRoomChatUsers.forEach(user => {
            if (user.chatUserKeyNavigation.channelUserId === this.channelInfo.channelUserId) {
                this.ownersList.unshift(user.chatUserKeyNavigation)
                if (this.userInfo.channelUserId === this.channelInfo.channelUserId) {
                    this.isCreator = true;
                }
            } else {
                this.ownersList.push(user.chatUserKeyNavigation)
                if (this.ownersList.some(listUser => this._authService._user.profile.sub === listUser.channelUserId)) {
                    this.isOwner = true;
                }
            }
        });
        this.memberList = [];
        this.channelInfo.chatRoomChatUser1.forEach(user => {
            this.memberList.push(user.chatUserKeyNavigation)
        });
        this.isLoadingChannelInfo = false;
    }

    getChannelUsersInRoom() {
        this.isloadingUsersInChannel = true;
        this._chManageService.getChannelUsersInRoomByChannelId(this.channelInfo.channelId)
            .subscribe(data => {
                if(data.status === 200) {
                    let chatList = data.body;
                    chatList = this.updateUsersStatus(chatList);
                    this.inChannelList = chatList;
                    this.isloadingUsersInChannel = false;
                    // console.log("%c in channel list? ", consoleLogStyle, this.inChannelList)
                } else {
                    this.isloadingUsersInChannel = false;
                    console.error("there was an error getting in channel list")
                }
            });
    }

    updateUsersStatus(list: any[]) {
        let userList = []
        list.forEach(user => {
            let channelUser = user.chatUserKeyNavigation;
            if (channelUser.selectedStatus === 0) {
                if (channelUser.presenceStatus === 0) {
                    channelUser.userStatus = "offline";
                } else if (channelUser.presenceStatus === 1) {
                    channelUser.userStatus = "away";
                } else if (channelUser.presenceStatus === 2) {
                    channelUser.userStatus = "active";
                } else if (channelUser.presenceStatus === 3) {
                    //Temp for incall
                    channelUser.userStatus = "active";
                }
            } else if (channelUser.selectedStatus === 1) {
                channelUser.userStatus = "hidden";
            } else if (channelUser.selectedStatus === 2) {
                channelUser.userStatus = "dnd";
            } else if (channelUser.selectedStatus === 3) {
                channelUser.userStatus = "brb";
            }
            userList.push(channelUser);
        });
        return userList;
    }

    _addOwner(userId: string) {
        let owner = {
            "channelUserId": userId,
            "channelId": this.channelInfo.channelId
        };
        this._apiCalls.postChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Owner Added");
                    this.channelUpdated.emit();
                    this._channelService.updateChannelUsersInRoomEvent(this.channelInfo.channelId);
                } else if (res.status === 409) {
                    // console.log("Owner Already Exists");
                    this.channelUpdated.emit();
                } else {
                    console.error(res);
                }
                this.isAddingInvite = false;
            });
    }

    _removeOwner(userId: string) {
        let owner = {
            "channelUserId": userId,
            "channelId": this.channelInfo.channelId
        };
        this._apiCalls.deleteChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Owner Removed");
                    this.channelUpdated.emit();
                    this._channelService.updateChannelUsersInRoomEvent(this.channelInfo.channelId);
                } else if (res.status === 404) {
                    // console.log("Owner does not exist");
                    this.channelUpdated.emit();
                } else {
                    console.error(res);
                }
            });
    }

    _addMember(userId: string) {
        let member = {
            "channelUserId": userId,
            "channelId": this.channelInfo.channelId
        };
        this._apiCalls.postChannelMember(member)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Member Added");
                    if (!this.isQuickMeeting) {
                        this.channelUpdated.emit();
                    }
                    this._channelService.updateChannelUsersInRoomEvent(this.channelInfo.channelId);
                } else if (res.status === 409) {
                    // console.log("Member Already Exists");
                    this.channelUpdated.emit();
                } else {
                    console.error(res);
                }
                this.isAddingInvite = false;
            });
    }

    _removeMember(userId: string) {
        let member = {
            "channelUserId": userId,
            "channelId": this.channelInfo.channelId
        };
        this._apiCalls.deleteChannelMember(member)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Member Removed");
                    if (!this.isQuickMeeting) {
                        this.channelUpdated.emit();
                    }
                    this._channelService.updateChannelUsersInRoomEvent(this.channelInfo.channelId);
                } else if (res.status === 404) {
                    // console.log("Member does not exist");
                    this.channelUpdated.emit();
                } else {
                    console.error(res);
                }
            });
    }

    _addGuestMeetingAttendee(type: string) {
        this.isAddingInvite = true;
        let attendee;
        if (type === "email") {
            attendee = {
                "email": this.inviteForm.value.email,
                "meetingId": this.meetingId
            };
        } else if (type === "sms") {
            attendee = {
                "phoneNumber": this.inviteForm.value.sms,
                "meetingId": this.meetingId
            };
        }
        this._meetingService.postGuestMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Attendee Added::", res.body);
                    let invite = {
                        "inviteEmail": res.body.email,
                        "meetingId": this.meetingId
                    }
                    this._sendInviteToken(invite, attendee);
                    this.inviteForm.reset();
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, false, this.channelInfo.channelId);
                    // this.updateAttendeesList.emit();
                } else {
                    // console.log("error :::", JSON.stringify(res));
                    if (type === "email") {
                        this._toastService.error(`There was an error sending your email to ${attendee.email}`);
                    } else if (type === "sms") {
                        this._toastService.error(`There was an error sending your invite to ${attendee.phoneNumber}`);
                    }
                }
                this.isAddingInvite = false;
            });
    }

    _addMeetingAttendee(userIndex: any) {
        this.isAddingInvite = true;
        let userInfo = this.searchUsersList[userIndex];
        let attendee = {
            "channelUserId": userInfo.channelUserId,
            "meetingId": this.meetingId
        };
        // console.log(userInfo)
        this._meetingService.postMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("User Attendee Added::", res.body);
                    let invite = {
                        "inviteEmail": userInfo.email,
                        "meetingId": this.meetingId
                    }
                    this._sendInviteToken(invite, userInfo);
                    this.clearSearch();
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, false, this.channelInfo.channelId);
                    // this.updateAttendeesList.emit();
                    this._addMember(attendee.channelUserId);
                } else {
                    this._toastService.error(`There was an error inviting ${userInfo.displayName} to this meeting`);
                }
                this.isAddingInvite = false;
            });
    }

    _sendInviteToken(invite: any, attendee: any) {
        this._meetingService.sendInviteToken(invite)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("Token sent EMAIL ::", res)
                    if (attendee.email) {
                        this._toastService.success(`Email invite was sent to ${attendee.email}`);
                    } else if (attendee.phoneNumber) {
                        this._toastService.success(`SMS invite was sent to ${attendee.phoneNumber}`);
                    } else {
                        this._toastService.success(`An invite was sent to ${attendee.displayName}`);
                    }
                } else {
                    // console.log("error :::", JSON.stringify(res));
                    if (attendee.email) {
                        this._toastService.error(`There was an error sending your email to ${attendee.email}`);
                    } else if (attendee.phoneNumber) {
                        this._toastService.error(`There was an error sending your invite to ${attendee.phoneNumber}`);
                    } else {
                        this._toastService.error(`There was an error sending your invite to ${attendee.displayName}`);
                    }
                }
            })
    }

    showSearch(addingManager: boolean) {
        // Do a check here for user role limitation?
        if (addingManager) {
            this.isManagerBeingAdded = true;
        }
        else {
            this.isManagerBeingAdded = false;
        }
        this.showUserSearch = true;
    }

    hideSearch() {
        this.showUserSearch = false;
        this.clearSearch();
    }

    displayEmailInvite() {
        this.showSMSInvite = false;
        this.showEmailInvite = true;
    }

    displaySMSInvite() {
        this.showEmailInvite = false;
        this.showSMSInvite = true;
    }

    hideInvite() {
        this.showEmailInvite = false;
        this.showSMSInvite = false;
        this.inviteForm.reset();
    }

    addUserToChannel(i: number, asOwner: boolean) {
        this.isAddingInvite = true;
        if (asOwner) {
            this._addOwner(this.searchUsersList[i].channelUserId);
        } else {
            this._addMember(this.searchUsersList[i].channelUserId);
        }
        this.clearSearch();
        this.showUserSearch = false;
    }

    removeUserFromList(i: number, isOwner: boolean) {
        // console.log("Lists Before User Removal:");
        // console.log("Owners List: " + this.ownersList.toString());
        // console.log("Members List: " + this.memberList.toString());
        if (isOwner) {
            if (this.ownersList[i].channelUserId === this.channelInfo.channelUserId) {
                this._toastService.error("You can not remove the Channel Creator");
            } else {
                this._removeOwner(this.ownersList[i].channelUserId);
            }
        } else {
            this._removeMember(this.memberList[i].channelUserId);
        }
    }

    promoteUser(i: number) {
        // console.log("Member List Before Promotion: " + this.memberList);
        this._addOwner(this.memberList[i].channelUserId);
        this._removeMember(this.memberList[i].channelUserId);
    }

    demoteUser(i: number) {
        // console.log("Owners List Before Demotion: " + this.memberList);
        if (this.ownersList[i].channelUserId === this.channelInfo.channelUserId) {
            this._toastService.error("You can not demote the Channel Creator");
        } else {
            this._addMember(this.ownersList[i].channelUserId);
            this._removeOwner(this.ownersList[i].channelUserId);
        }
    }

    clearSearch() {
        this.userSearchBar = <HTMLInputElement>document.getElementById('user-search-edit-channel');
        this.userSearchBar.value = '';
        this.searchUsersList = [];
    }

    // GET ALL USERS HERE 
    _getUsersBySearchQuery() { // CALLED (keyup) of searchbar input
        console.log("Manage users search query called");
        this.userSearchBar = <HTMLInputElement>document.getElementById('user-search-edit-channel');
        let searchString = this.userSearchBar.value.replace(/\s/g, '');
        if (searchString.length < 1) {
            this.searchUsersList = [];
        } else {
            this._apiCalls.getChannelUsersByTenantAndSearchQuery(this.userInfo.tenant, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        let newList = [];
                        let compareList: any = this.memberList.concat(this.ownersList);
                        searchList.forEach((searchUser, index) => {
                            if (this.userInfo.channelUserId !== searchUser.channelUserId) {
                                if (this.isQuickMeeting) {
                                    if (!compareList.some(listUser => // not already in invite list 
                                        searchUser.channelUserId === listUser.channelUserId)
                                        && !this.meetingAttendees.some(attendant => // not already in current attendees list 
                                            searchUser.channelUserId === attendant.chatUserKeyNavigation.channelUserId)) {
                                        newList.push(searchList[index]);
                                    }
                                } else {
                                    if (!compareList.some(listUser => searchUser.channelUserId === listUser.channelUserId)) {
                                        newList.push(searchList[index]);
                                    }
                                }
                            }
                        });
                        this.searchUsersList = newList;
                        this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
                    } else {
                        console.error(JSON.stringify(data));
                    }
                });
        }
    }

    _searchMoreUsers(link: any) {
        this._apiCalls.getGenericForPagination(link)
            .subscribe(data => {
                this.searchUsersList = data.body;
                this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
            });
    }


    // 🚧 new & working for in tennant && guest | need to also cancel token if guest (adrian)?
    removeUserFromCurrentAttendeesList(i: number) {
        let userId = this.meetingAttendees[i].chatUserKeyNavigation.channelUserId;
        let attendeeObject = {
            meetingId: this.meetingId,
            channelUserId: userId
        };
        this._meetingService.deleteMeetingAttendee(attendeeObject)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("remove att res?", res)
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, false, this.channelInfo.channelId);
                    // this.updateAttendeesList.emit();
                    this._removeMember(attendeeObject.channelUserId);
                } else {
                    console.error("error deleting meeting attendee?", res)
                }
            });
    }

    // Meeting options & add users dropdowns ... 
    // on (click) 
    showMemberDropdown(i: number) {
        const options = document.getElementById(`manage-member-dropdown-${i}`);
        options.classList.add('active');
    }

    showManagerDropdown(i: number) {
        const options = document.getElementById(`manage-manager-dropdown-${i}`);
        options.classList.add('active');
    }


    // on (mouseleave) 
    hideMemberDropdown(i: number) {
        const options = document.getElementById(`manage-member-dropdown-${i}`);
        options.classList.remove('active');
    }

    hideManagerDropdown(i: number) {
        const options = document.getElementById(`manage-manager-dropdown-${i}`);
        options.classList.remove('active');
    }

    handleBrokenImage(listName:string, userIdx:number) {
        switch (listName) {
            case "inChannelList": {
                this.inChannelList[userIdx].hash = "1";
                break;
            }
            case "searchUsersList": {
                this.searchUsersList[userIdx].hash = "1";
                break;
            }
            case "ownersList": {
                this.ownersList[userIdx].hash = "1";
                break;
            }
            case "memberList": {
                this.memberList[userIdx].hash = "1";
                break;
            }
            case "meetingAttendees": {
                this.meetingAttendees[userIdx].chatUserKeyNavigation.hash = "1";
                break;
            }
            default:
                break;
        }
    }
}
