import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { consoleLogStyle } from '../../varaibles';



@Component({
    selector: "create-project",
    templateUrl: "./create-project.component.html",
    styleUrls: ["./create-project.component.scss"],
})
export class CreateProjectComponent implements OnInit, OnDestroy {

    @Input("userInfo") userInfo: any;
    @Output('onCompleteEvent') onCompleteEvent = new EventEmitter();
    @Output('formTouchedEvent') formTouchedEvent = new EventEmitter();

    public newProject: FormGroup;
    public isCreatingProject: boolean = false;
    public isFormTouched: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder, 
        private _apiCallsService: ApiCallsService, 
        private _toastService: ToastNotificationService, 
    ) {}

    ngOnInit(): void {
        console.log("%c create chan modal? ", consoleLogStyle, this.userInfo)
        this.createForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    createForm() {
        this.newProject = this.fb.group({
            projectName: ["", [Validators.required, Validators.minLength(2)]], // 200max set on input...
        });
        this.subscriptions.push(this.newProject.valueChanges.subscribe(data => {
            // console.log('Form changes', data);
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                this.formTouchedEvent.emit();
                console.log('Form Touched Emiited', this.isFormTouched);
            }
        }));
    }

    get newProjectControls() {
        return this.newProject.controls;
    }

    createProject() {
        this.isCreatingProject = true;
        let newProj = {
            "channelUserId": this.userInfo.channelUserId,
            "name": this.newProject.value.projectName,
        };
        this._apiCallsService.postProject(newProj)
            .subscribe(res => {
                // 
                if (res.status === 201) {
                    console.log("%c project created", consoleLogStyle, res.body);
                    let newChannelMade = res.body;
                    this.isCreatingProject = false;
                    this.onCompleteEvent.emit(newChannelMade); // pushes to project[] ...
                } else {
                    console.log("error creating project")
                    this.isCreatingProject = false;
                    this._toastService.error("There was an error creating new project.");
                }
            });
    }

    // created if the user doesn't have a 'Shared Folders' project
    // Project/Channel structure used for sharing folders/files
    /*
    createSharedFolderProject() {
        this.isCreatingProject = true;
        let newProj = {
            "channelUserId": this.userInfo.channelUserId,
            "name": "Shared Folders",
        };
        this._apiCallsService.postProject(newProj)
            .subscribe(res => {
                // 
                if (res.status === 201) {
                    console.log("%c project created", consoleLogStyle, res.body);
                    let newChannelMade = res.body;
                    this.isCreatingProject = false;
                    this.onCompleteEvent.emit(newChannelMade); // pushes to project[] ...
                } else {
                    console.log("error creating project")
                    this.isCreatingProject = false;
                    this._toastService.error("There was an error creating new project.");
                }
            });
    }
    */
}

