import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError} from 'rxjs/operators';
import { throwError, of, Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class ProjectChannelService {
    public userId: string;
    public _isSignedIn = false;

    constructor(
        private httpClient: HttpClient,
    ) { }

    getDirectMessageChannels(channelUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        
        return this.httpClient
            .get(`${Constants.apiRoot}channel/direct/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getActiveDirectMessageChannels(channelUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        
        return this.httpClient
            .get(`${Constants.apiRoot}channel/activedirect/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelUsersInRoomByChannelUserId(channelUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}chatUserChatRooms?channelUserId=${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelUsersInRoomByChannelId(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        if(!channelId) {
        // if(true) {
            this.invalidParameterError();
            return;
        }

        return this.httpClient
            .get(`${Constants.apiRoot}chatUserChatRooms?channelId=${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    searchAllDirectMessageChannels(channelUserId: string, query: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}channel/dmChannelSearch/${channelUserId}?searchQuery=${query}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postChannelUsersInRoom(userInRoomsObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}chatUserChatRooms/`, userInRoomsObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
    
    
    //ChannelOwners are ChatRoomChatUsers
    getChannelOwnersByChannelId(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}chatRoomChatUsers?channelId=${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //ChannelMembers are ChatRoomChatUser1
    getChannelMembersByChannelId(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}chatRoomChatUser1?channelId=${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelNotificationsByUser(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}userNotifications/getUserNotifications/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelsByOwnerId(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}channelUser/channelOwner/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    searchForMembersAndOwnersByChannelId(channelId: string, query: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}channel/channelSearch/${channelId}/${query}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }



    invalidParameterError() {
        return throwError("Invalid Paramater" || "Client Error")
    }
}
