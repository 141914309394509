import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { AuthService } from '../core/auth.service';
import * as signalr from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { SocketsService } from './sockets.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ScreenCheck } from '../shared/functions';
import { consoleLogStyle } from '../shared/varaibles';

//import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';

@Injectable({
    providedIn: `root`
})
export class ChannelService {
    // test: any = 2;

    isTyping: boolean = false;
    typingReset: boolean = false;
    public mytime;
    // private _channelId: any;
    // private _serverpath: any;
    private bearerToken;
    private _token;
    private _hubConnection: signalR.HubConnection;
    //private _transportType: any;
    private _initialized = false;
    private _stateChangePromise = Promise.resolve();
    private _joinedChannelMap = {};
    public _isDesktop: boolean;
    public _isMobileDevice: boolean;
    public socketServers: any = [];

    public useManagerSettings: boolean;//testing

    // private _messageSource = new Subject<any>();
    private onConnectionEvent = new Subject<string>();
    private onMessageToClientEvent = new Subject<string>();
    private onJoinEvent = new Subject<string>();
    private onJoinNotification = new Subject<any>();
    private onJoinedPageEvent = new Subject<any>();
    private isTypingEvent = new Subject<boolean>();
    private onNudgeEvent = new Subject<boolean>();
    private onHighFiveEvent = new Subject<boolean>();
    private onThemeChangeEvent = new Subject<boolean>();
    private onCallChannelEvent = new Subject<boolean>();
    private onPerfTestEvent = new Subject<boolean>();
    private onPopulateMessagesEvent = new Subject<boolean>();
    private onPopulateMessagesEvent2 = new Subject<string>();
    private onCallAcceptedEvent = new Subject<boolean>();
    private onDeclineCallEvent = new Subject<boolean>();
    private onReceiveOfferSignalEvent = new Subject<boolean>();
    private onReceiveRtcCandidateSignalEvent = new Subject<boolean>();
    private onReceiveRtcAnswerSignalEvent = new Subject<boolean>();
    private onCallCreatedEvent = new Subject<boolean>();
    private onWhoCommandEvent = new Subject<boolean>();
    private onHangUpUserEvent = new Subject<boolean>();
    private onCallErrorEvent = new Subject<any>();
    private onRtcMaxAttendeeLimitReachedEvent = new Subject<any>();
    private onRtcCallInProgressEvent = new Subject<any>();
    private onUpdateUnreadMessageCountEvent = new Subject<any>();
    private onSelectPoliceOfficerEvent = new Subject<any>();
    private onUpdateUserStatusEvent = new Subject<any>();
    private onUpdateSessionUsersEvent = new Subject<any>();
    private onIntakeSessionEndedEvent = new Subject<any>();
    private onSharedFileUploadCompletedEvent = new Subject<any>();
    private onSharedFileDeleteCompletedEvent = new Subject<any>();
    private onRtcAccepteeCallAcceptedEvent = new Subject<any>();
    private onJoinAccessDeniedEvent = new Subject<any>();
    private onShowBeginCallPromptEvent = new Subject<any>();
    private onSharedFileBeginUploadEvent = new Subject<any>();
    private onUserLeaveEvent = new Subject<any>();
    private onLeaveRoomEvent = new Subject<any>();
    private onBroadcastEvent = new Subject<any>();
    private onStreamAddedEvent = new Subject<any>();
    private onHubConnectionStateChangeEvent = new Subject<any>();
    private onHandEvent = new Subject<any>();
    private onUpdateMeetingListEvent = new Subject<any>();
    private onUpdateChatComponentEvent = new Subject<any>();
    private onDeleteMessagesPermissionDeniedEvent = new Subject<any>();
    private onAddOwnerEvent = new Subject<any>();
    private onAddMemberEvent = new Subject<any>();
    private onRemoveMemberEvent = new Subject<any>();
    private onRemoveOwnerEvent = new Subject<any>();
    private onUpdateChannelHistoryEvent = new Subject<any>();
    private onListOwnersOrMembersEvent = new Subject<any>();
    private onUpdateDirectMessageListEvent = new Subject<any>();
    private onJitsiSelfJoinEvent = new Subject<any>();
    private onJitsiCallCreatedEvent = new Subject<any>();
    private onJitsiCallPromptEvent = new Subject<any>();
    private onJitsiCallAcceptedEvent = new Subject<any>();
    private onJitsiHangUpUserEvent = new Subject<any>();
    private onJitsiMaxAttendeeLimitReachedEvent = new Subject<any>();
    private onJitsiHangUpLastUserEvent = new Subject<any>();
    private onJitsiToggleRecordingEvent = new Subject<any>();
    private onUpdateScheduleEvent = new Subject<any>();
    private onGetChannelUsersInRoomEvent = new Subject<any>();
    private onWhereEvent = new Subject<any>();
    private onUnreadMessageNotificationEvent = new Subject<any>();
    private onUnreadDirectMessageNotification = new Subject<any>();
    private onDirectMessageNotificationEvent = new Subject<any>();
    private onDirectMessageCloseEvent = new Subject<any>();
    private onDirectMessageRedirectEvent = new Subject<any>();
    private onPushDirectMessageToOverlayEvent = new Subject<any>();
    private onNoClientErrorEvent = new Subject<any>();
    private onGuestDisplayNameUpdatedEvent = new Subject<any>();
    private onSidebarPinEvent = new Subject<any>();
    private onSidebarDisplayEvent = new Subject<any>();
    private onSidebarChangeEvent = new Subject<any>();
    private onSidebarAppSelectEvent = new Subject<any>();
    private onDirectMessageEmitEvent = new Subject<any>();
    private onDirectMessageUpdateForSelfEvent = new Subject<any>();
    private onHangUpCallEvent = new Subject<any>();
    private onJitisiCallManagerEvent = new Subject<any>();
    private onJitisiCallFailedEvent = new Subject<any>();
    private onJitisiCallCancelledEvent = new Subject<any>();
    private onJitisiCallResponseEvent = new Subject<any>();
    // Observable string streams
    onConnectionEvent$ = this.onConnectionEvent.asObservable();
    onMessageToClientEvent$ = this.onMessageToClientEvent.asObservable();
    onJoinEvent$ = this.onJoinEvent.asObservable();
    isTypingEvent$ = this.isTypingEvent.asObservable();
    onNudgeEvent$ = this.onNudgeEvent.asObservable();
    onHighFiveEvent$ = this.onHighFiveEvent.asObservable();
    onThemeChangeEvent$ = this.onThemeChangeEvent.asObservable();
    onCallChannelEvent$ = this.onCallChannelEvent.asObservable();
    onPerfTestEvent$ = this.onPerfTestEvent.asObservable();
    onPopulateMessagesEvent$ = this.onPopulateMessagesEvent.asObservable();
    onPopulateMessagesEvent2$ = this.onPopulateMessagesEvent2.asObservable();
    onCallAcceptedEvent$ = this.onCallAcceptedEvent.asObservable();
    onDeclineCallEvent$ = this.onDeclineCallEvent.asObservable();
    onReceiveOfferSignalEvent$ = this.onReceiveOfferSignalEvent.asObservable();
    onReceiveRtcCandidateSignalEvent$ = this.onReceiveRtcCandidateSignalEvent.asObservable();
    onReceiveRtcAnswerSignalEvent$ = this.onReceiveRtcCandidateSignalEvent.asObservable();
    onCallCreatedEvent$ = this.onCallCreatedEvent.asObservable();
    onWhoCommandEvent$ = this.onWhoCommandEvent.asObservable();
    onHangUpUserEvent$ = this.onHangUpUserEvent.asObservable();
    onCallErrorEvent$ = this.onCallErrorEvent.asObservable();
    onRtcMaxAttendeeLimitReachedEvent$ = this.onRtcMaxAttendeeLimitReachedEvent.asObservable();
    onRtcCallInProgressEvent$ = this.onRtcCallInProgressEvent.asObservable();
    onUpdateUnreadMessageCountEvent$ = this.onUpdateUnreadMessageCountEvent.asObservable();
    onSelectPoliceOfficerEvent$ = this.onSelectPoliceOfficerEvent.asObservable();
    onUpdateUserStatusEvent$ = this.onUpdateUserStatusEvent.asObservable();
    onUpdateSessionUsersEvent$ = this.onUpdateSessionUsersEvent.asObservable();
    onIntakeSessionEndedEvent$ = this.onIntakeSessionEndedEvent.asObservable();
    onSharedFileDeleteCompletedEvent$ = this.onSharedFileDeleteCompletedEvent.asObservable();
    onSharedFileUploadCompletedEvent$ = this.onSharedFileUploadCompletedEvent.asObservable();
    onRtcAccepteeCallAcceptedEvent$ = this.onRtcAccepteeCallAcceptedEvent.asObservable();
    onJoinAccessDeniedEvent$ = this.onJoinAccessDeniedEvent.asObservable();
    onShowBeginCallPromptEvent$ = this.onShowBeginCallPromptEvent.asObservable();
    onSharedFileBeginUploadEvent$ = this.onSharedFileBeginUploadEvent.asObservable();
    onJoinNotification$ = this.onJoinNotification.asObservable();
    onJoinedPageEvent$ = this.onJoinedPageEvent.asObservable();
    onUserLeaveEvent$ = this.onUserLeaveEvent.asObservable();
    onLeaveRoomEvent$ = this.onLeaveRoomEvent.asObservable();
    onBroadcastEvent$ = this.onBroadcastEvent.asObservable();
    onStreamAddedEvent$ = this.onStreamAddedEvent.asObservable();
    onHubConnectionStateChangeEvent$ = this.onHubConnectionStateChangeEvent.asObservable();
    onHandEvent$ = this.onHandEvent.asObservable();
    onUpdateMeetingListEvent$ = this.onUpdateMeetingListEvent.asObservable();
    onUpdateChatComponentEvent$ = this.onUpdateChatComponentEvent.asObservable();
    onDeleteMessagesPermissionDeniedEvent$ = this.onDeleteMessagesPermissionDeniedEvent.asObservable();
    onAddOwnerEvent$ = this.onAddOwnerEvent.asObservable();
    onAddMemberEvent$ = this.onAddMemberEvent.asObservable();
    onRemoveMemberEvent$ = this.onRemoveMemberEvent.asObservable();
    onRemoveOwnerEvent$ = this.onRemoveOwnerEvent.asObservable();
    onUpdateChannelHistoryEvent$ = this.onUpdateChannelHistoryEvent.asObservable();
    onListOwnersOrMembersEvent$ = this.onListOwnersOrMembersEvent.asObservable();
    onUpdateDirectMessageListEvent$ = this.onUpdateDirectMessageListEvent.asObservable();
    onJitsiSelfJoinEvent$ = this.onJitsiSelfJoinEvent.asObservable();
    onJitsiCallCreatedEvent$ = this.onJitsiCallCreatedEvent.asObservable();
    onJitsiCallPromptEvent$ = this.onJitsiCallPromptEvent.asObservable();
    onJitsiCallAcceptedEvent$ = this.onJitsiCallAcceptedEvent.asObservable();
    onJitsiHangUpUserEvent$ = this.onJitsiHangUpUserEvent.asObservable();
    onJitsiMaxAttendeeLimitReachedEvent$ = this.onJitsiMaxAttendeeLimitReachedEvent.asObservable();
    onJitsiHangUpLastUserEvent$ = this.onJitsiHangUpLastUserEvent.asObservable();
    onJitsiToggleRecordingEvent$ = this.onJitsiToggleRecordingEvent.asObservable();
    onUpdateScheduleEvent$ = this.onUpdateScheduleEvent.asObservable();
    onGetChannelUsersInRoomEvent$ = this.onGetChannelUsersInRoomEvent.asObservable();
    onWhereEvent$ = this.onWhereEvent.asObservable();
    onUnreadMessageNotificationEvent$ = this.onUnreadMessageNotificationEvent.asObservable();
    onUnreadDirectMessageNotification$ = this.onUnreadDirectMessageNotification.asObservable();
    onDirectMessageNotificationEvent$ = this.onDirectMessageNotificationEvent.asObservable();
    onDirectMessageCloseEvent$ = this.onDirectMessageCloseEvent.asObservable();
    onDirectMessageRedirectEvent$ = this.onDirectMessageRedirectEvent.asObservable();
    onPushDirectMessageToOverlayEvent$ = this.onPushDirectMessageToOverlayEvent.asObservable();
    onNoClientErrorEvent$ = this.onNoClientErrorEvent.asObservable();
    onGuestDisplayNameUpdatedEvent$ = this.onGuestDisplayNameUpdatedEvent.asObservable();
    onSidebarPinEvent$ = this.onSidebarPinEvent.asObservable();
    onSidebarDisplayEvent$ = this.onSidebarDisplayEvent.asObservable();
    onSidebarChangeEvent$ = this.onSidebarChangeEvent.asObservable();
    onSidebarAppSelectEvent$ = this.onSidebarAppSelectEvent.asObservable();
    onDirectMessageEmitEvent$ = this.onDirectMessageEmitEvent.asObservable();
    onDirectMessageUpdateForSelfEvent$ = this.onDirectMessageUpdateForSelfEvent.asObservable();
    onHangUpCallEvent$ = this.onHangUpCallEvent.asObservable();
    onJitisiCallManagerEvent$ = this.onJitisiCallManagerEvent.asObservable();
    onJitisiCallFailedEvent$ = this.onJitisiCallFailedEvent.asObservable();
    onJitisiCallCancelledEvent$ = this.onJitisiCallCancelledEvent.asObservable();
    onJitisiCallResponseEvent$ = this.onJitisiCallResponseEvent.asObservable();


    constructor(
        private _authService: AuthService,
        private _socketService: SocketsService,
        public _toastService: ToastNotificationService
    ) {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
        ) {
            this._isMobileDevice = true;
            this._isDesktop = false;
        } else {
            this._isMobileDevice = false;
            this.screenChangeEvent();
        }
    }

    public _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                this.socketServers = data.body;
                return this.socketServers;
            })
            .catch(error => console.error(`Could not get socket server, ${error}`));
    }

    public Initialize = () => {
        return this._getActiveSocketServers().then(socketServers => {
            let activeSocketServer = socketServers[0];

            return this._authService.getAccessToken().then(token => {
                return this.waitForStates().then(() => token);
            }).then(token => {
                if (true === this._initialized) {
                    return;
                }

                this._token = token;

                if (!this._token) {
                    if (!this._hubConnection) {
                        //Only show alert if we have never had a hub connection since someone with a hubconnection
                        //would already be logged in.
                        alert("User is not signed in.");
                    }
                    return;
                }

                this._hubConnection = new signalr.HubConnectionBuilder()
                    .withUrl(`${activeSocketServer.serverPath}channelhub?clientversion=3.0.0.0`,
                        {
                            accessTokenFactory: () => this.bearerToken = this._token,
                            transport: signalr.HttpTransportType.WebSockets | signalr.HttpTransportType.LongPolling
                        })
                    //.configureLogging(signalr.LogLevel.Trace)
                    //.configureLogging(signalr.LogLevel.Warning)
                    //.configureLogging(signalr.LogLevel.Information)
                    .configureLogging(signalr.LogLevel.Debug)
                    .withAutomaticReconnect([0, 10000, 20000, 30000])
                    //.AddJsonProtocol(options => {
                    //    options.PayloadSerializerOptions.WriteIndented = false;
                    //})

                    .build();
                this._hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 1; //1 minute keepalive
                this._hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes

                this.assignHandlers();
                this._stateChangePromise = this._hubConnection.start();
                return this._stateChangePromise.then(() => {
                    // console.log("connected");
                    this._initialized = true;
                    this.onHubConnectionStateChangeEvent.next("connected");
                });
            });
        });
    }

    private waitForStates = () => {
        //We want to wait for either start/stop to be finished or fail. Sometimes when we cannot connect, the
        //promises will end up rejecting. We should catch the error when trying to join a channel or send requests out.
        //Until we get a more organized way of using the channel service across all the components.
        return !this._hubConnection ? Promise.resolve() : this._stateChangePromise.catch(() => { });
    }

    private assignHandlers = () => {
        this._hubConnection.on('ChannelNudge', () => {
            this.onNudgeEvent.next();
        });

        this._hubConnection.on('HighFive', (displayname: any) => {
            this.onHighFiveEvent.next(displayname);
        });

        this._hubConnection.on('Hand', (displayName: any) => {
            this.onHandEvent.next(displayName);
        })

        this._hubConnection.on('ChannelMessage', (message: any) => {
            //console.log("Channel Message", message);
            //console.log("service ", message);
            this.onMessageToClientEvent.next(message);
        });

        this._hubConnection.on("UpdateDirectMessageForSelf", (message: any) => {
            this.onDirectMessageUpdateForSelfEvent.next(message);
        })

        this._hubConnection.on('PopulateMessages', (message: any) => {
            //console.log("PopulateMessage Service " + message.message);
            this.onPopulateMessagesEvent.next(message);
        });

        this._hubConnection.on("Joined", () => {
            // console.log("Joined Service");
            this.onJoinEvent.next();
            //alert("Joined service");
        })

        this._hubConnection.on("JoinNotification", (joinObj: any) => {
            this.onJoinNotification.next(joinObj);
            //alert("Joined service");
        })

        this._hubConnection.on("JoinedPage", (channelId: any) => {
            this.onJoinedPageEvent.next(channelId);
        })

        this._hubConnection.on("typingNotification", (message: any) => {
            // this.messages.push(message);
            //this.typingReset = true;
            //clearTimeout(this.mytime);
            //console.log("typingNotification");
            this.isTypingEvent.next(message);
            //this.mytime = setTimeout(() => {
            //    this.typingReset = false;
            //    this.isTypingEvent.next(this.typingReset);

            //}, 3000);
        });

        this._hubConnection.on("SetTheme", () => {
            //console.log(themeName);
            // alert("settheme channel service");
            this.onThemeChangeEvent.next();
        })

        this._hubConnection.on("onPerfTest", (perfObj: any) => {
            this.onPerfTestEvent.next(perfObj);
        })

        this._hubConnection.on("incomingCallEvent", (callObj: any) => {
            //alert("incomingCallEvent " + callObj.channelId);
            this.onCallChannelEvent.next(callObj);
        })

        this._hubConnection.on("onWhoCommand", (whoObj: any) => {
            this.onWhoCommandEvent.next(whoObj);
        })

        this._hubConnection.on("rtcCallAccepted", (acceptingCalleeDto: any) => {
            // console.log("Answer Call event listener: " + acceptingCalleeDto.clientId);
            this.onCallAcceptedEvent.next(acceptingCalleeDto);
        })

        this._hubConnection.on("rtcDeclineCall", (userId: any) => {
            // console.log("Reject Call event listener");
            this.onDeclineCallEvent.next(userId);
        })

        this._hubConnection.on("rtcHangUpUser", () => {
            // console.log("rtchangupuser next");
            this.onHangUpUserEvent.next();
        })

        this._hubConnection.on("RtcReceiveOfferSignal", (offerObj: any) => {
            // console.log("RTC receive offer signal event listener: ");
            this.onReceiveOfferSignalEvent.next(offerObj);
        })

        this._hubConnection.on("rtcReceiveCandidateSignal", (candidateObj: any) => {
            // console.log("rtcReceiveCandidateSignal event listener service");
            this.onReceiveRtcCandidateSignalEvent.next(candidateObj);
        })

        this._hubConnection.on("rtcReceiveAnswerSignal", (answerObj: any) => {
            //console.log("rtcReceiveAnswerSignal event listener service " + JSON.stringify(answerObj.answer));
            //("receive answer signal service");
            this.onReceiveRtcAnswerSignalEvent.next(answerObj);
        })

        this._hubConnection.on("rtcCallCreated", (callId: any) => {
            this.onCallCreatedEvent.next(callId);
        })

        this._hubConnection.on("rtcCallEnded", () => {
            this.onCallErrorEvent.next();
        })

        this._hubConnection.on("rtcMaxAttendeeLimitReached", () => {
            this.onRtcMaxAttendeeLimitReachedEvent.next();
        })

        this._hubConnection.on("rtcCallInProgress", () => {
            this.onRtcCallInProgressEvent.next();
        })

        this._hubConnection.on("RtcAccepteeCallAccepted", () => {
            //alert("rtcaccepteecallacceptedservice");
            this.onRtcAccepteeCallAcceptedEvent.next();
        })

        this._hubConnection.on("updateUserStatus", (channelUserStatusList: any) => {
            this.updateUserStatus(channelUserStatusList);
        })

        this._hubConnection.on("updateIntakeSessionUsers", () => {
            //alert("channel service update intake session users");
            this.onUpdateSessionUsersEvent.next();
        })

        this._hubConnection.on("intakeSessionEnded", (channelId: any) => {
            this.onIntakeSessionEndedEvent.next(channelId);
        })

        this._hubConnection.on("sharedFileUploadCompleted", (fileUploadObj: any) => {
            // console.log("Shared File Next .... ", fileUploadObj);
            this.onSharedFileUploadCompletedEvent.next(fileUploadObj);
        })

        this._hubConnection.on("sharedFileDeleteCompleted", (fileDeleteObj: any) => {
            this.onSharedFileDeleteCompletedEvent.next(fileDeleteObj);
        })

        this._hubConnection.on("sharedFileBeginUpload", (channelId: any) => {
            this.onSharedFileBeginUploadEvent.next(channelId);
        })

        this._hubConnection.on("onJoinAccessDenied", () => {
            this.onJoinAccessDeniedEvent.next();
        })

        this._hubConnection.on("ShowBeginCallPrompt", (channelId: any) => {
            this.onShowBeginCallPromptEvent.next(channelId);
        })

        this._hubConnection.on("UserLeaveNotification", (userObj: any) => {
            this.onUserLeaveEvent.next(userObj);
        })

        this._hubConnection.on("LeaveRoom", () => {
            this.onLeaveRoomEvent.next();
        })

        this._hubConnection.on("OnBroadcastCommand", (data: any) => {
            this.onBroadcastEvent.next(data);
        })

        this._hubConnection.on("RtcAddStream", (stream: any) => {
            this.onStreamAddedEvent.next(stream);
        })

        // i need data returned here ... 
        this._hubConnection.on("UpdateUpcomingMeetingsList", (data) => {
            // console.log("%c UpdateUpcomingMeetingsList channelservice", consoleLogStyle,data);
            this.onUpdateMeetingListEvent.next();
        })

        this._hubConnection.on("UpdateChatComponent", (channelId: any) => {
            this.onUpdateChatComponentEvent.next(channelId);
        })

        this._hubConnection.on("DeleteMessagesPermissionDenied", () => {
            this.onDeleteMessagesPermissionDeniedEvent.next();
        })

        this._hubConnection.on("AddOwner", (username: any) => {
            this.onAddOwnerEvent.next(username);
        })

        this._hubConnection.on("AddMember", (username: any) => {
            this.onAddMemberEvent.next(username);
        })

        this._hubConnection.on("RemoveMember", (username: any) => {
            this.onRemoveMemberEvent.next(username);
        })

        this._hubConnection.on("RemoveOwner", (username: any) => {
            this.onRemoveOwnerEvent.next(username);
        })

        this._hubConnection.on("UpdateChannelHistory", () => {
            this.onUpdateChannelHistoryEvent.next();
        })

        this._hubConnection.on("ListOwnersOrMembers", (usernames: any) => {
            this.onListOwnersOrMembersEvent.next(usernames);
        })

        this._hubConnection.on("UpdateDirectMessageList", () => {
            this.onUpdateDirectMessageListEvent.next();
        })

        this._hubConnection.on("JitsiCallCreated", (callId: any) => {
            // console.log("jitsicallcreated next");
            // console.log("jitsicallcreated callId: " + callId);
            this.onJitsiCallCreatedEvent.next(callId);
        })

        this._hubConnection.on("IncomingJitsiCallEvent", (callObj: any) => {
            // console.log("jitsicallprompt next");
            this.onJitsiCallPromptEvent.next(callObj);
        })

        this._hubConnection.on("JitsiCallAccepted", () => {
            this.onJitsiCallAcceptedEvent.next();
        })

        this._hubConnection.on("JitsiHangUpUser", () => {
            this.onJitsiHangUpUserEvent.next();
        })

        this._hubConnection.on("JitsiHangUpLastUser", () => {
            this.onJitsiHangUpLastUserEvent.next();
        })

        this._hubConnection.on("JitsiMaxAttendeeLimitReached", () => {
            this.onJitsiMaxAttendeeLimitReachedEvent.next();
        })

        this._hubConnection.on("JitsiToggleRecording", (disableRecording: any) => {
            this.onJitsiToggleRecordingEvent.next(disableRecording);
        })

        this._hubConnection.on("UpdateSchedule", () => {
            this.onUpdateScheduleEvent.next();
        })

        this._hubConnection.on("GetChannelUsersInRoom", () => {
            // console.log("onGetChannelUsersInRoomEvent next");
            this.onGetChannelUsersInRoomEvent.next();
        })

        this._hubConnection.on("Where", (usernamesList: any) => {
            this.onWhereEvent.next(usernamesList);
        })

        this._hubConnection.on("UnreadMessageNotification", (notiObj: any) => {
            this.onUnreadMessageNotificationEvent.next(notiObj);
        })

        this._hubConnection.on("UnreadDirectMessageNotification", (notiObj: any) => {
            this.onUnreadDirectMessageNotification.next(notiObj);
        })

        this._hubConnection.on("NoClientError", () => {
            this.onNoClientErrorEvent.next();
        })

        this._hubConnection.on("UpdateGuestDisplayName", (channelId: any) => {
            this.guestDisplayNameUpdatedEvent(channelId);
        })

        //Call users
        this._hubConnection.on("CallUsersSend", (callObj) => {
            this.onJitisiCallManagerEvent.next(callObj);
        })

        this._hubConnection.on("CallFailedSend", (channelId) => {
            this.onJitisiCallFailedEvent.next(channelId);
        })

        this._hubConnection.on("CallCancelledSend", (channelId) => {
            this.onJitisiCallCancelledEvent.next(channelId);
        })

        this._hubConnection.on("CallResponseSend", (callObj) => {
            this.onJitisiCallResponseEvent.next(callObj);
        })
        //

        this._hubConnection.onclose((error) => {
            let state = "disconnected";
            this.onHubConnectionStateChangeEvent.next(state);
            this._initialized = false;
            return this.restartHubConnection();
        })

        this._hubConnection.onreconnecting((error) => {
            // console.log("Connection started reconnecting due to an error: " + error);
            let state = "reconnecting";
            this.onHubConnectionStateChangeEvent.next(state);
            //this._toastService.error("Connection lost due to error. Attempting to reconnect.");
        })

        this._hubConnection.onreconnected((connectionId: any) => {
            // console.log("reconnected " + connectionId);
            let state = "connected";
            this.onHubConnectionStateChangeEvent.next(state);
            this._toastService.info("Connection reestablished.");
            // console.log("onreconnected channelId: " + this._channelId + " serverpath: " + this._serverpath);
            return this.rejoinChannels();
        })

        
    }

    private restartHubConnection = () => {
        return this.waitForStates()
            .then(() => this.Initialize())
            .then(() => this.rejoinChannels())
            .catch(error => {
                console.error(`Could not restart hubconnection, ${error}`);
                setTimeout(() => this.restartHubConnection(), 10 * 1000);
            });
    }

    private rejoinChannels = () => {
        let channelsToRejoin = Object.keys(this._joinedChannelMap);
        let joinPromises = channelsToRejoin.map(channelId => this.join(channelId)
            .catch(error => {
                console.error(`Could not rejoin channel ${channelId}, ${error.message}`);
            })
        );
        return Promise.all(joinPromises);
    }

    // calls channelHub
    public sendMessageToChannel = (message: any, channelId: any) => {
        //console.log("sendtochannel service " + channelId + " " + message);

        const data = `${message}`;

        var msg = message.split(' ');
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            if (msg[0].toLowerCase() === "/nudge") {
                this._hubConnection.invoke('SendCommand', "/nudge", channelId);
            } else if (msg[0].toLowerCase() === "/highfive") {
                this._hubConnection.invoke('SendCommand', "/highfive", channelId);
            } else if (msg[0].toLowerCase() === "/shrug") {
                this._hubConnection.invoke('SendCommand', "/shrug", channelId);
            } else if (msg[0].toLowerCase() === "/perftest") {
                this._hubConnection.invoke("SendCommand", "/perftest", channelId);
            } else if (msg[0].toLowerCase() === "/who") {
                this._hubConnection.invoke("SendCommand", "/who", channelId);
            } else if (msg[0].toLowerCase() === "/broadcast") {
                this._hubConnection.invoke("SendCommand", "/broadcast", channelId);
            } else if (msg[0].toLowerCase() === "/topic") {
                this._hubConnection.invoke("SendCommand", message, channelId);
            } else if (msg[0].toLowerCase() === "/leave") {
                this._hubConnection.invoke("SendCommand", "/leave", channelId);
            } else if (msg[0].toLowerCase() === "/hand") {
                this._hubConnection.invoke("SendCommand", "/hand", channelId);
            } else if (msg[0].toLowerCase() === "/deleteall") {
                this._hubConnection.invoke("SendCommand", "/deleteall", channelId);
            } else if (msg[0].toLowerCase() === "/addmember") {
                this._hubConnection.invoke("SendCommand", message, channelId);
            } else if (msg[0].toLowerCase() === "/addowner") {
                this._hubConnection.invoke("SendCommand", message, channelId);
            } else if (msg[0].toLowerCase() === "/removemember") {
                this._hubConnection.invoke("SendCommand", message, channelId);
            } else if (msg[0].toLowerCase() === "/removeowner") {
                this._hubConnection.invoke("SendCommand", message, channelId);
            } else if (msg[0].toLowerCase() === "/owners") {
                this._hubConnection.invoke("SendCommand", "/owners", channelId);
            } else if (msg[0].toLowerCase() === "/members") {
                this._hubConnection.invoke("SendCommand", "/members", channelId);
            } else if (msg[0].toLowerCase() === "/where") {
                this._hubConnection.invoke("SendCommand", "/where", channelId);
            } else if (msg[0].toLowerCase() === "/who") {
                this._hubConnection.invoke("SendCommand", "/who", channelId);
            } else if (msg[0].toLowerCase() === "/brb") {
                this._hubConnection.invoke("SendCommand", "/brb", "status");
            } else if (msg[0].toLowerCase() === "/dnd" || msg[0].toLowerCase() === "/busy") {
                this._hubConnection.invoke("SendCommand", "/dnd", "status");
            } else if (msg[0].toLowerCase() === "/online" || msg[0].toLowerCase() === "/active") {
                this._hubConnection.invoke("SendCommand", "/online", "status");
            } else if (msg[0].toLowerCase() === "/invisible") {
                this._hubConnection.invoke("SendCommand", "/invisible", "status");
            } else {
                // console.log("****Message sent****");
                this._hubConnection.invoke('Send', data, channelId);
            }
        }
        else {
            // console.log("Signalr hub not in connected state");
            this._toastService.error("Your session has expired. Please refresh the page.");
        }
    }

    public sendCommand = (message: any, channelId: any) => {
        //console.log("sendcommand service " + channelId + " " + message);
        const data = `${message}`;
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke('SendCommand', message, channelId);
        }
    }

    public sendMessageToClient(message: any, channelId: any): void {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke('SendMessageToClient', 'Ben', message, channelId);
        }
    }

    public sendMessageToChannelJSON(content: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke('SendJSON', JSON.stringify(content), channelId);
        }
    }

    public typingEvent = (channelId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("Typing", channelId);
        }
    }

    public join = (channelId: any) => {
        //if (!channelId) {
        //    return;
        //}
        // console.log("join channelId", channelId);
        return this.waitForStates()
            .then(() => {
                this._hubConnection.invoke("Join", channelId);
                // console.log("invoke")
                this._joinedChannelMap[channelId] = true;
                // console.log(`Joined channel ${channelId}`);
            }).catch(error => console.error(`Could not join channel, ${error}`));
    }

    public leave = (channelId: any) => {
        return this.waitForStates().then(() => {
            return this._hubConnection.invoke("SendCommand", "/leave", channelId);
        }).then(() => {
            delete this._joinedChannelMap[channelId];
            // console.log(`Left channel ${channelId}`);
        });
    }

    public setTheme = () => {
        //console.log("set theme service");
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/theme", "");
        } else {
            // alert("No hub connection");
        }

        //this._hubConnection.invoke("SendCommand", "/" + themeName, "");
    }

    //public getAllChannelMessages(fullChannelName: any) {
    //    if (this._hubConnection) {
    //        console.log("getAllMessages Service");
    //        this._hubConnection.invoke("GetAllChannelMessages", fullChannelName);
    //    }
    //}

    public rtcCallChannel = (includeVideo: any, channelId: any) => {
        //alert(channelId);
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("rtcCallChannel service");
            this._hubConnection.invoke("SendCommand", "/rtccallchannel " + includeVideo, channelId);
        }
    }

    public rtcAcceptCall = (includeVideo: any, channelId: any, callId: any, callInitializer: any, initiatorClientId: any, initiatorConnectionId: any) => {
        // console.log("rtcAcceptCall service " + initiatorClientId);
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/rtccallaccepted " + includeVideo + " " + callId + " "
                + callInitializer.userId + " " + initiatorClientId + " " + initiatorConnectionId, channelId);
        }
    }

    public rtcDeclineCall = (channelId: any, callId: any, initiatorClientId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/rtccalldeclined " + callId + " " + initiatorClientId, channelId);
        }
    }

    public rtcHangUpUser = (channelId: any, callId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("Hangupuser service");
            this._hubConnection.invoke("SendCommand", "/rtchangupuser " + callId, channelId);
        }
    }

    public RTCSendOfferSignal = (acceptingCalleeConnectionId: any, offer: any, channelId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("RTC Send Offer Signal Service");
            //console.log(JSON.stringify(localDescription));
            //this._hubConnection.invoke("SendCommand", "/rtcsendoffersignal " + acceptingCalleeClientId +
            //    " " + localDescription, channelId);

            this._hubConnection.invoke("SendCommand", "/rtcsendoffersignal " + acceptingCalleeConnectionId + " " + offer, channelId);
        }
    }

    public RTCSendCandidateSignal = (connectionId: any, securityToken: any, candidate: any, channelId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("RTC Send Candidate Signal Service " + JSON.stringify(candidate));

            //this._hubConnection.invoke("SendCommand", "/rtcsendcandidatesignal " + clientId +
            //    " " + securityToken + " " + candidate, channelId);

            this._hubConnection.invoke("SendCommand", "/rtcsendcandidatesignal " + connectionId + " " + candidate, channelId);
        }
    }

    public RTCSendAnswerSignal = (connnectionId: any, answer: any, securityToken: any, channelId: any) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("RTC Send Answer Signal Service " + JSON.stringify(answer));

            //this._hubConnection.invoke("SendCommand", "/rtcsendanswersignal " + clientId + " " +
            //    securityToken + " " + localDescription, channelId);

            this._hubConnection.invoke("SendCommand", "/rtcsendanswersignal " + connnectionId + " " + securityToken + " " + answer, channelId);
        }
    }

    public populateMessages(channelId: string) {
        //alert("channel service populate " + channelId);
        this.onPopulateMessagesEvent2.next(channelId);
    }

    public updateUnreadMessageCount(messageChannelId: any) {
        this.onUpdateUnreadMessageCountEvent.next(messageChannelId);
    }

    public onSelectPoliceOfficer(selectedChannelId: any) {
        //alert(selectedChannelId);
        this.onSelectPoliceOfficerEvent.next(selectedChannelId);
    }

    public updateUserStatus(channelUserStatusList: any) {
        this.onUpdateUserStatusEvent.next(channelUserStatusList);
    }

    public updateUserStatusOnLogin(channelUserId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            alert("hub connection exists");
        } else {
            alert("no hub connection");
        }
    }

    public postIntakeSessionUser(notificationForm: any, appId: any, url: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("postIntakeUserSession channel-service");
            // console.log(notificationForm)
            //var startSessioArgs = {
            //  "intakeUserId" : notificationForm.intakeUserId,
            //  "displayName": notificationForm.displayName,
            //  "email": notificationForm.email,
            //  "SMSNumber" : notificationForm.SMSNumner
            //};

            //this._hubConnection.invoke("SendCommand", "/startsession " + JSON.stringify(startSessioArgs), notificationForm.chatRoomId)
            this._hubConnection.invoke("SendCommand", "/startsession " + notificationForm.intakeUserId + " " + notificationForm.intakeSessionId +
                " " + url + " " + appId + " " + notificationForm.displayName, notificationForm.chatRoomId)
        }
    }

    public deleteIntakeSessionUser(intakeSessionUserId: any, appId: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/endsession " + intakeSessionUserId + " " + appId, channelId);
            //console.log("session ended > sigR emit ❓")
        }
    }

    public sharedFileUploadComplete(url: any, filename: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/sharedfileuploadcomplete " + url + " " + filename, channelId);
        }
    }

    public sharedFileDeleteComplete(channelId: any, filename?: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/sharedfiledeletecomplete " + filename, channelId);
        }
    }

    public sharedFileBeginUpload(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/sharedfilebeginupload", channelId);
        }
    }

    public onShowBeginCallPrompt(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/showbegincallprompt", channelId);
        }
        //this.onShowBeginCallPromptEvent.next(channelId);
    }

    public broadcast(data: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("broadcast" + JSON.stringify(data));
            // console.log(channelId);
            this._hubConnection.invoke("SendCommand", "/broadcast " + data, channelId);
        }
    }

    public onStreamAdded(addTrackObj: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("onstreamadded service " + JSON.stringify(event));
            this._hubConnection.invoke("SendCommand", "/rtcaddstream " + addTrackObj.partnerClientId + " " + addTrackObj.event.streams[0], channelId);
        }
    }

    // should this update home page meetings ???????
    public updateUpcomingMeetingsList(meetingId: any, isNewMeeting: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("updateUpcomingMeetingList FROM SERVICE meetingId: " + meetingId + " channelId: " + channelId);
            this._hubConnection.invoke("SendCommand", "/updateUpcomingMeetingsList " + meetingId + " " + isNewMeeting, channelId);
        }
    }

    public updateDirectMessageList(channelId: any, newChannel: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/updatedirectmessagelist " + newChannel, channelId);
        }
    }

    public JitsiCallChannel(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("channelservice jitsicallchannel");
            this._hubConnection.invoke("SendCommand", "/jitsicallchannel ", channelId);
        } else {
            this._toastService.error("Your session has expired. please refresh the page.");
        }
    }

    public JitsiAcceptCall(callId: any, channelId: any, initiatorClientId: any, initiatorConnectionId: any, initiatorUserId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/jitsiacceptcall " + callId + " " + initiatorClientId + " " + initiatorConnectionId + " " + initiatorUserId, channelId);
        }
    }

    public JitsiHangUpUser(callId: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("JitsiHangUpUser callId: " + callId + "channelId: " + channelId);
            this._hubConnection.invoke("SendCommand", "/jitsihangupuser " + callId, channelId);
        }
    }

    public onJitsiToggleRecordingButton(disableRecording: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/jitsitogglerecording " + disableRecording, channelId);
        }
    }

    public onJitsiStopRecordingEvent(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/jitsistoprecording ", channelId);
        }
    }

    public UpdateSchedule(appId: any, channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/updateschedule " + appId, channelId);
        }
    }

    public directMessageNotificationEvent(showBadge: boolean) {
        this.onDirectMessageNotificationEvent.next(showBadge);
    }

    public closeDirectMessage() {
        this.onDirectMessageCloseEvent.next();
    }

    public redirectDirectMessage(channelId: string, channelName: string, userId: string = null) {
        // console.log("re-direct dm {channel name} & {userId}", channelId, channelName, userId)
        this.onDirectMessageRedirectEvent.next({
            channelId: channelId,
            channelName: channelName,
            userId: userId //used for creating a new DM directly to this userId
        });
    }

    public pushDirectMessageToOverlay(channelId: string) {
        // console.log("Channel ID Sent from Overlay: " + channelId);
        this.onPushDirectMessageToOverlayEvent.next(channelId)
    }

    public updateLastActivity(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/updatelastactivity", channelId);
        }
    }

    public guestDisplayNameUpdated(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("SendCommand", "/updateguestdisplayname", channelId);
        }
    }

    public guestDisplayNameUpdatedEvent(channelId: any) {
        // console.log("guestDisplayNameUpdated next");
        this.onGuestDisplayNameUpdatedEvent.next(channelId);
    }

    public updateChannelUsersInRoomEvent(channelId: any) {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // console.log("updateChannelUsersInRoomEvent invoke");
            this._hubConnection.invoke("SendCommand", "/updatechannelusersinroom", channelId);
        }
    }

    public jitsiSelfJoinEvent(channelId: string) {
        this.onJitsiSelfJoinEvent.next(channelId);
    }

    public sidebarPinEvent(isPinned: boolean) {
        this.onSidebarPinEvent.next(isPinned);
    }

    public sidebarDisplayEvent(show: boolean) {
        this.onSidebarDisplayEvent.next(show);
    }

    public sidebarChangeEvent(num: number, type: string = null) {
        this.onSidebarChangeEvent.next({ num: num, type: type });
    }

    public sidebarAppSelectEvent(val: string) {
        // console.log(val);
        this.onSidebarAppSelectEvent.next(val);
    }

    public directMessageEmitEvent() {
        // console.log("dm emit event...")
        this.onDirectMessageEmitEvent.next();
    }

    public screenChangeEvent() {
        //560 is phablet && and mobile screen break
        this._isDesktop = ScreenCheck(560);
    }

    public hangUpCallEvent() {
        this.onHangUpCallEvent.next();
    }

    public disconnectSigR() {
        this._hubConnection.stop();
    }


    //New for Calling
    public triggerAcceptCallModal(channel: any) {
        this.onJitisiCallManagerEvent.next(channel);
    }

    public callUsers = (channelId: string) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            this._hubConnection.invoke("CallUsers", channelId);
        }
    }

    public callFailed = (channelId: string) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // alert("Call Canceled");
            this._hubConnection.invoke("CallFailed", channelId);
        }
    }

    public callCancelled = (channelId: string) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // alert("Call Canceled");
            this._hubConnection.invoke("CallCancelled", channelId);
        }
    }

    public callResponse = (channelId: string, hasAccepted: boolean) => {
        if (this._hubConnection.state == signalr.HubConnectionState.Connected) {
            // alert("Call Denied");
            this._hubConnection.invoke("CallResponse", channelId, hasAccepted);
        }
    }
}
