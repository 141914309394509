import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants, ConstantsLocal } from '../../../constants';
import * as moment from 'moment';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { AuthService } from 'src/app/core/auth.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { ChannelService } from 'src/app/services/channel-service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { ChannelSelected } from '../../../helpers/channel-selected.validator';
import { Subscription } from 'rxjs';
import { ContactsService } from 'src/app/services/contacts.service';
import { consoleLogStyle } from '../../varaibles';
import { MeetingEvent } from '../../interface/meeting-event';
import { ChannelFull } from '../../interface/channel-full';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

// interface ChannelObj {
//     channelId?: string;
//     name?: string;
// }

interface AttendeeStandBy {
    type: string;
    attendeeId?: string;
    userInfo?: any;
}

@Component({
    selector: 'schedule-meeting',
    templateUrl: './schedule-meeting.component.html',
    styleUrls: ['./schedule-meeting.component.scss']
})
export class ScheduleMeetingComponent implements OnInit, OnDestroy {

    @Input('isEditChannel') isEditChannel: boolean;
    @Input('isEditQuick') isEditQuick: boolean;
    @Input('incomingMeetingId') incomingMeetingId: string;
    @Input('incomingChannelObject') incomingChannelObject: ChannelFull;

    @Output('formTouchedEvent') formTouchedEvent = new EventEmitter();
    @Output('onCompleteEvent') onCompleteEvent = new EventEmitter();

    public meetingId: string;
    public selectedChannelId: string;
    public meetingInfo: MeetingEvent = {};
    public meetingForm: FormGroup;
    public privateMeeting: boolean = true;
    public showMeetingType: boolean = false;
    public lockChannel: boolean = false;
    public formTouched: boolean = false;

    public todaysDate: Date = new Date();
    public maxScheduleDate: Date = new Date(2050, 0, 0);
    public selectSchedule: any = {};

    // guest invite forms
    public inviteDisplay: string = "contacts";

    public addSMS: boolean = false;
    public listItemSMS: string = "";
    public listSMS: any = [];

    public addEmail: boolean = false;
    public listItemEmail: string = "";
    public listEmail: any = [];

    public manageUsers: boolean = false;
    public meetingAttendees: any[] = [];
    public searchTenantUsersList: any = [];
    public searchChannelUsersList: any = [];
    public searchContactUsersList: any = [];
    public inviteTenantUsersList: any = [];
    public inviteChannelUsersList: any = [];
    public inviteContactUsersList: any = [];
    public attendeeStandBylist: AttendeeStandBy[] = [];
    // public attendeeStandByObject: any = {
    //     channel: [],
    //     tenant: [],
    //     contact: [],
    //     email: [],
    //     sms: []

    // };

    public scheduleError: boolean = false; // true when schedule is past Date 
    public createLock: boolean = false; // sets loading spinner
    public isDropdownOpen: boolean = false;

    public channelList: any[] = [];

    private subscriptions: Subscription[] = [];

    constructor(
        private _toastService: ToastNotificationService,
        private _chManageService: ProjectChannelService,
        private _contactsService: ContactsService,
        private _meetingService: MeetingService,
        private _channelService: ChannelService,
        private _apiCalls: ApiCallsService,
        private _formBuilder: FormBuilder, //Import for Forms
        public _authService: AuthService,
    ) {
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "cancelMeetingModal") {
                console.log("%c meeting to cancel?", consoleLogStyle)
                this.cancelMeeting(data.checkboxConfirm);
            }
        }));
    }

    ngOnInit(): void {
        // console.log("---------------------")
        // console.log("isEditChannel", this.isEditChannel)
        // console.log("isEditQuick", this.isEditQuick)
        // console.log("incomingMeetingId", this.incomingMeetingId)
        // console.log("incomingChannelObject", this.incomingChannelObject)
        // console.log("---------------------")
        if (this.isEditChannel || this.isEditQuick) {
            if (this.incomingMeetingId && this.incomingMeetingId !== '') {
                this.meetingId = this.incomingMeetingId;
                // this.newMeetingForm();//prevent form load error
                this._getMeeting();
            } else {
                this._toastService.error("Could not find Meeting");
            }
        } else {
            // if (this.incomingChannelObject && this.incomingChannelObject !== {}) {
            if (this.incomingChannelObject) {
                this.datesInit();
                this.selectedChannelId = this.incomingChannelObject.channelId;
                this.newMeetingForm('channel', this.incomingChannelObject.channelId);
                this.onFormChange();
                this.lockChannel = true;
            } else {
                this.showMeetingType = true;
                this.datesInit();
                this.newMeetingForm('quick');
                this.onFormChange();
                this.getChannels();
                
            }
        }
    }

    ngOnDestroy(): void {
        // console.log("In destroy")
        // if (this.manageUsers) {
            // console.log("manageUSers is true");
            // console.log(this.meetingId);
            // console.log(this.selectedChannelId);
            this._channelService.updateUpcomingMeetingsList(this.meetingId, false, this.selectedChannelId);
        // }
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private _getMeeting() {
        this._meetingService.getMeeting(this.meetingId)
            .subscribe(res => {
                if (res.status === 200) {
                    this.meetingInfo = res.body;
                    // console.log(this.meetingInfo)
                    this.selectedChannelId = this.meetingInfo.
                        channelKeyNavigation.channelId;
                    this.editMeetingInit();
                } else {
                    // console.log(res);
                    this._toastService.error("Could not find Meeting");
                }
            });
    }

    private getChannels() {
        this._chManageService.getChannelsByOwnerId(this._authService._user.profile.sub)
            .subscribe(res => {
                if (res.status === 200) {
                    let channels = res.body;
                    this.channelList = channels;
                    // console.log(channels);
                } else {
                    // console.log(res);
                    this._toastService.error("Could not find Channel");
                }
            })
    }

    private datesInit(): void {
        // this sets initial time val 
        let now = new Date();
        let nowHour = now.getHours();
        let nowDay;
        if (nowHour < 13) {
            if (nowHour == 0) {
                nowHour = 12;
                nowDay = 'AM';
            } else {
                if (nowHour == 12 || nowHour == 11) {
                    nowDay = 'PM';
                } else {
                    nowDay = 'AM';
                }
            }
        } else {
            nowHour -= 12;
            if (nowHour == 12 || nowHour == 11) {
                nowDay = 'AM';
            } else {
                nowDay = 'PM';
            }
        }
        if (nowHour == 12) {
            nowHour = 1;
        } else {
            nowHour++;
            if (nowHour == 12 && nowDay === 'AM') {
                now.setDate(now.getDate() + 1);
            }
        }
        let initalHour = this.modifyTimeString(nowHour, 0, nowDay);
        this.selectSchedule = {
            "fromDate": this.todaysDate,
            "fromHour": nowHour,
            "fromMin": 0,
            "fromDay": nowDay,
            "time": initalHour,
            "toDate": "",
            "toHour": 12,
            "toMin": 0,
            "toDay": "PM",
            "start": "",
            "end": "",
            "timeLength": "1",
            "customHours": "0",
            "customMinutes": "15"
        };
    }

    private newMeetingForm(meetingType: string = '', channelId: string = '') {
        this.meetingForm = this._formBuilder.group({
            name: ['', [Validators.required]],
            topic: [null],
            type: [meetingType],
            channelId: [channelId],
            start: [''],
            end: [''],
            allDay: [false]
        }, {
            validator: ChannelSelected()
        });
    }

    get formControls() { return this.meetingForm.controls; }

    public onFormChange() {
        this.subscriptions.push(this.meetingForm.valueChanges.subscribe(data => {
            // console.log('Form changes', data);
            this.selectedChannelId = data.channelId;
            this.emitTouched();
        }));
    }

    public emitTouched() {
        if (!this.formTouched) {
            this.formTouched = true;
            // console.log('Form Touched Emiited');
            this.formTouchedEvent.emit(true);
        }
    }

    public resetTouched() {
        this.formTouched = false;
        this.formTouchedEvent.emit(false);
    }

    //For Edit Meeting
    private editMeetingInit() {
        // console.log(this.meetingInfo.start);
        let meetStart = new Date(this.meetingInfo.start);
        // console.log(meetStart);
        meetStart.setMinutes(meetStart.getMinutes() - meetStart.getTimezoneOffset()); //Converts back to Local
        // console.log(meetStart)
        let meetEnd = new Date(this.meetingInfo.end);
        meetEnd.setMinutes(meetEnd.getMinutes() - meetEnd.getTimezoneOffset()); //Converts back to Local

        this.selectSchedule = {
            "fromDate": meetStart,
            "fromHour": this.getMeetingInfoToEditTimeFormat(meetStart, 'hour'),
            "fromMin": this.getMeetingInfoToEditTimeFormat(meetStart, 'min'),
            "fromDay": this.getMeetingInfoToEditTimeFormat(meetStart, 'day'),
            "toDate": meetEnd,
            "toHour": this.getMeetingInfoToEditTimeFormat(meetEnd, 'hour'),
            "toMin": this.getMeetingInfoToEditTimeFormat(meetEnd, 'min'),
            "toDay": this.getMeetingInfoToEditTimeFormat(meetEnd, 'day'),
            "start": meetStart,
            "end": meetEnd,
            "timeLength": this.getMeetingTimeLength(meetStart, meetEnd),
            "customHours": this.getMeetingDurationCustom(meetStart, meetEnd, 'hour'),
            "customMinutes": this.getMeetingDurationCustom(meetStart, meetEnd, 'min'),
            "time": ""
        }
        // console.log(this.selectSchedule);
        this.selectSchedule.time = this.modifyTimeString(this.selectSchedule.fromHour, this.selectSchedule.fromMin, this.selectSchedule.fromDay);
        this.meetingForm = this._formBuilder.group({
            name: [this.meetingInfo.title, [Validators.required]],
            topic: [this.meetingInfo.body],
            type: [this.isEditQuick ? "quick" : "channel"],
            channelId: [this.meetingInfo.chatRoomId],
            start: [''],
            end: [''],
            allDay: [this.meetingInfo.allDay]
        });
        this.onFormChange();
        // console.log('schedule', this.selectSchedule);
        // console.log('form', this.meetingForm.value);
        this.getMeetingAttendees();
    }

    // new time return 
    private getMeetingInfoToEditTimeFormat(date: Date, type: string): number | string {
        if (type === 'hour') {
            let hour = date.getHours();
            if (hour > 12) {
                return hour -= 12;
            } else {
                return hour;
            }
        }
        if (type === 'min') return date.getMinutes();
        if (type === 'day') {
            if (date.getHours() < 12) {
                return 'AM';
            } else {
                return 'PM';
            }
        }
    }

    private modifyTimeString(hour: number, min: number, day: string) {
        let newHour: any = hour;
        let newMin: any = min;
        if (hour < 10) {
            newHour = "0" + hour;
        }
        if (min < 10) {
            newMin = "0" + min;
        }
        return newHour + ":" + newMin + " " + day;
    }

    private getMeetingDurationCustom(dateStart, dateEnd, type: string): string {
        let difference = dateEnd - dateStart;
        let differenceInMinutes = difference / 1000 / 60;
        differenceInMinutes = differenceInMinutes % 60;
        let differenceInHours = difference / 1000 / 60 / 60;
        if (type === 'min') return differenceInMinutes.toString();
        if (type === 'hour') return Math.floor(differenceInHours).toString();
    }

    private getMeetingTimeLength(dateStart, dateEnd): string {
        // console.log("start,end?", dateStart,dateEnd):Date
        let difference = dateEnd - dateStart;
        let differenceInMinutes = difference / 1000 / 60;
        let differenceInHours = difference / 1000 / 60 / 60;
        if (differenceInHours === 24) {
            differenceInMinutes = 0;
            return '24';
        } else if (differenceInMinutes === 15) {
            return '15'
        } else if (differenceInMinutes === 30) {
            return '30';
        } else if (differenceInHours === 1) {
            return '1';
        } else {
            return '2';
        }
    }

    private getMeetingAttendees() {
        this._meetingService.getMeetingAttendeesToEditByMeetingId(this.meetingId, this._authService._user.profile.sub)
            .subscribe(res => {
                if (res.status === 200) {
                    this.meetingAttendees = res.body.meetingAttendees; // only attendees, to get creator just res.body is needed...
                    // console.log("%c meeting attendees? ", this.meetingAttendees);
                    // console.log("attendees", this.meetingAttendees)
                } else {
                    // console.log("error getting meeting attendees?", res)
                    this._toastService.error("Could not retrieve meeting attendees")
                }
            });
    }

    // setChannelId(e: Event) {
    //     const target = e.target as HTMLSelectElement; // ang12 ts fix
    //     console.log(target);
    //     this.selectedChannelId = target.value; // ang12 ts fix
    // }

    setChannelId() {
        this.selectedChannelId = this.meetingForm.value.channelId;
    }

    // 🚧 called on meeting submit 
    public scheduleManager() {
        this.createLock = true; // submitting true
        this.setFromDate();
        // console.log(this.meetingForm.value.start);
        if (this.dateCompare(this.meetingForm.value.start)) { // makes sure that the start time is in the future
            this.scheduleError = false;
            this.setToDate();
            if (this.isEditQuick || this.isEditChannel) {
                let newMeeting = this.setMeeting(this.meetingForm.value.channelId);
                this._updateMeeting(newMeeting);
            } else {
                if (this.meetingForm.value.type === "quick") {
                    if (this.meetingForm.value.name !== "private meeting") {
                        this._postQuickMeetingChannel();
                    } else {
                        this._toastService.error("Can not name a meeting 'private meeting', this is for default only.");
                        this.meetingForm.value.name = "";
                        // this.selectSchedule.fromDate = this.todaysDate;
                        // this.resetTimeValueOnError();
                        this.createLock = false; // submitting false
                        this.scheduleError = false;
                        // console.log("can not name meeting private meeting...", this.meetingForm.value.name)
                    }
                } else if (this.meetingForm.value.type === 'channel') {
                    let newMeeting = this.setMeeting(this.meetingForm.value.channelId);
                    this._postMeeting(newMeeting);
                } else {
                    // console.log(this.meetingForm.value);
                    this._toastService.error('Could not identify type');
                }
            }
        } else {
            this._toastService.error("You cannot select a date/time in the past.")
            // this.selectSchedule.fromDate = this.todaysDate;
            // this.resetTimeValueOnError();
            this.createLock = false; // submitting false
            this.scheduleError = false;
            // console.log("there was an error in schedual time?", this.dateCompare(this.newMeeting.start))
        }
    }

    private dateCompare(dateCheck): boolean {
        let now = new Date();
        if (dateCheck.getTime() > now.getTime()) {
            // console.log("date in future");
            return true;
        } else {
            // console.log("date in past");
            return false;
        }
    }

    private setFromDate() {
        if (this.selectSchedule.time.length < 8) {
            this.selectSchedule.time = "0" + this.selectSchedule.time;
        }
        this.selectSchedule.fromHour = this.parseHourFromTime(this.selectSchedule.time);
        this.selectSchedule.fromMin = this.parseMinFromTime(this.selectSchedule.time);
        this.selectSchedule.fromDay = this.parseDayFromTime(this.selectSchedule.time);

        let newStartTime = moment(this.selectSchedule.fromDate).toDate(); // 🎑 moment formating is becoming deprecated... https://sebastiandedeyne.com/moment-js-thank-you-for-your-service/#:~:text=Moment%20has%20been%20the%20date,deprecated%20status%20of%20the%20project.&text=We%20now%20generally%20consider%20Moment,but%20it%20is%20indeed%20done.
        newStartTime.setHours(this.selectSchedule.fromHour);
        newStartTime.setMinutes(this.selectSchedule.fromMin);
        // console.log("::::: new date :::::", newStartTime);
        let newHour = parseInt(this.selectSchedule.fromHour, 10);

        if (this.selectSchedule.fromDay === "PM") {
            if (this.selectSchedule.fromHour != 12) {
                newHour += 12;
            }
        } else if (this.selectSchedule.fromHour == 12) {
            newHour = 0;
        }
        // console.log("new Hour: ", newHour);
        newStartTime.setHours(newHour, this.selectSchedule.fromMin, 0);
        this.meetingForm.value.start = newStartTime;
        // console.log("meeting start ::: ", this.newMeeting.start);
    }

    public setToDate() {
        this.setFromDate();
        let newEndTime: Date = new Date(this.meetingForm.value.start);
        // console.log("meeting start :::", newEndTime);
        if (this.selectSchedule.timeLength === "2") {
            newEndTime.setHours(newEndTime.getHours() + 2);
        } else if (this.selectSchedule.timeLength === "1") {
            newEndTime.setHours(newEndTime.getHours() + 1);
        } else if (this.selectSchedule.timeLength === "30") {
            newEndTime.setMinutes(newEndTime.getMinutes() + 30);
        } else if (this.selectSchedule.timeLength === "15") {
            newEndTime.setMinutes(newEndTime.getMinutes() + 15);
        } else {
            newEndTime.setDate(newEndTime.getDate() + 1)
            this.meetingForm.value.allDay = true;
        }
        // console.log("newEndTimeSet main opt :::", newEndTime)
        this.meetingForm.value.end = newEndTime;
        // console.log("meeting end :::", this.meetingForm.value.end);
    }

    private parseHourFromTime(time) {
        return time[0] + time[1];
    }
    private parseMinFromTime(time) {
        return time[3] + time[4];
    }
    private parseDayFromTime(time) {
        return time[6] + time[7];
    }

    // ❌ unused ...
    // called on schedule error to reset time 
    // private resetTimeValueOnError() {
    //     let now = new Date();
    //     let nowHour = now.getHours();
    //     let nowDay;
    //     if (nowHour < 13) {
    //         if (nowHour == 0) {
    //             nowHour = 12;
    //             nowDay = 'AM';
    //         } else {
    //             if (nowHour == 12 || nowHour == 11) {
    //                 nowDay = 'PM';
    //             } else {
    //                 nowDay = 'AM';
    //             }
    //         }
    //     } else {
    //         nowHour -= 12;
    //         if (nowHour == 12 || nowHour == 11) {
    //             nowDay = 'AM';
    //         } else {
    //             nowDay = 'PM';
    //         }
    //     }
    //     if (nowHour == 12) {
    //         nowHour = 1;
    //     } else {
    //         nowHour++;
    //         if (nowHour == 12 && nowDay === "AM") {
    //             now.setDate(now.getDate() + 1);
    //         }
    //     }
    //     this.selectSchedule.fromHour = nowHour;
    //     this.selectSchedule.fromDay = nowDay;
    // }

    // Called from 'scheduleManager()' 
    private _postQuickMeetingChannel() {
        let newChannel = {
            "teamProjectId": Constants.quickMeetingProjId,
            "channelUserId": this._authService._user.profile.sub,
            "name": this.meetingForm.value.name,
            "type": "quick",
            "videoType": "jitsi",
            "topic": this.meetingForm.value.topic,
            "inviteCode": null,
            "welcome": null,
            "deleteRule": 1,
            "deleteInterval": 1440,
            "scope": "Site",
            "private": this.privateMeeting,
            "closed": false,
            "inVideoCall": false,
            "inScreenShare": false,
        }
        this._apiCalls.postChannel(newChannel)
            .subscribe(res => {
                if (res.status === 201) {
                    let newChannel = res.body; // 🎇 this could have the id i need for addAttendees ???
                    let newMeeting = this.setMeeting(newChannel.channelId);
                    this.selectedChannelId = newChannel.channelId;
                    this._postMeeting(newMeeting); // add invites here <<<<<
                } else {
                    // console.log("error posting channel", res)
                    this.createLock = false;
                    this._toastService.error('Failed to Create Event')
                }
            });
    }

    private setMeeting(channelId: string) {
        let newMeeting = {
            "meetingId": this.meetingId,
            "chatUserId": this._authService._user.profile.sub,
            "chatRoomId": channelId,
            "title": this.meetingForm.value.name,
            "body": this.meetingForm.value.topic,
            "presentationUrl": Constants.unitySite + 'channel/' + channelId,
            "location": Constants.unitySite + 'channel/' + channelId,
            "start": this.meetingForm.value.start,
            "end": this.meetingForm.value.end,
            "allDay": this.meetingForm.value.allDay,
            "private": this.privateMeeting,
            "markAsBusy": false,
            "displayAttendance": true
        };
        // if (this.meetingForm.value.type === "channel" && !this.isEditChannel) {
        //     newMeeting.private = this.incomingChannelObject.private;
        // }
        if (this.incomingChannelObject) {
            newMeeting.private = this.incomingChannelObject.private;
        }
        return newMeeting;
    }

    // 🚧 new post meeting
    private _postMeeting(newMeeting: any) {
        console.log(newMeeting.chatRoomId);
        this.selectedChannelId = newMeeting.chatRoomId;
        // console.log("new meeting :: ::", newMeeting)
        // this.manageUsers = true;
        this._meetingService.postMeeting(newMeeting)
            .subscribe(res => {
                if (res.status === 201) {
                    let meeting = res.body;
                    this.meetingId = meeting.meetingId;
                    this.resetTouched();
                    if (this.meetingForm.value.type === "channel") {
                        this.onCompleteEvent.emit();
                    } else {
                        // console.log("Adding Self to meeting");
                        // ❌💡 Adding self here Might not be needed as the act of creating the channel, adds self to tha attendees?? ////CG
                        //this._addMeetingAttendee(this._authService._user.profile.sub); // add self 
                        // this.manageUsers = true;
                        this.closeManageUsers();
                        this.emitTouched();
                    }
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, true, newMeeting.chatRoomId);
                    // console.log("post meeting res from scedual ::::", res.body);
                    this._toastService.success(`${newMeeting.title} has successfully been created`);
                    this.attendeeStandBylist.forEach(attendee => this.attendeeManager(attendee));
                } else {
                    // console.log(JSON.stringify(res));
                    this._toastService.error("There was a problem creating your meeting")
                }
                this.createLock = false;
            });
    }

    //Update Meeting
    private _updateMeeting(newMeeting: any) {
        this._meetingService.putMeeting(this.meetingId, newMeeting)
            .subscribe(res => {
                if (res.status === 204) {
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, false, newMeeting.chatRoomId);
                    this._toastService.success(`${newMeeting.title} has successfully been updated`);
                    this.attendeeStandBylist.forEach(attendee => this.attendeeManager(attendee));
                    this.resetTouched();
                } else {
                    // console.log("error updating meeting?", res)
                    this._toastService.error('error updating meeting');
                }
                this.createLock = false;
            });
    }

    // ADD USERS IN VIA User TO MEET 
    public attendeeOrganizer(idx: number, type: string) {
        //console.log("Attendee Organizer entered: " + type);
        let userSearchBar: any;
        let newAttendee: AttendeeStandBy = {
            type: type
        }
        if (type === 'channel') {
            userSearchBar = document.getElementById('user-channel-search');
            newAttendee.userInfo = this.searchChannelUsersList[idx];
            newAttendee.attendeeId = this.searchChannelUsersList[idx].userId;
            userSearchBar.value = '';
            this.searchChannelUsersList = [];
            this.inviteChannelUsersList.push(newAttendee.userInfo);
        } else if (type === 'tenant') {
            userSearchBar = document.getElementById('user-tenant-search');
            newAttendee.userInfo = this.searchTenantUsersList[idx];
            newAttendee.attendeeId = this.searchChannelUsersList[idx].userId;
            userSearchBar.value = '';
            this.searchTenantUsersList = [];
            this.inviteTenantUsersList.push(newAttendee.userInfo);
        } else if (type === 'contact') {
            userSearchBar = document.getElementById('user-contact-search');
            newAttendee.userInfo = this.searchContactUsersList[idx];
            newAttendee.attendeeId = this.searchContactUsersList[idx].contactChannelUserId
            userSearchBar.value = '';
            this.searchContactUsersList = [];
            if (newAttendee.userInfo.contactChatUserKeyNavigation) {
                this.inviteTenantUsersList.push(newAttendee.userInfo);
            } else {
                this.inviteContactUsersList.push(newAttendee.userInfo);
            }
        } else if (type === 'email') {
            newAttendee.attendeeId = this.listItemEmail
            this.listItemEmail = "";
            this.listEmail.push({ email: newAttendee.attendeeId});
            // console.log('listEmail: ' + JSON.stringify(this.listEmail[0]));
        } else if (type === 'sms') {
            // console.log('adding user SMS to attendee list: ' + JSON.stringify(newAttendee));
            newAttendee.attendeeId = this.listItemSMS;
            // console.log('newAttendee.attendeeId: ' + newAttendee.attendeeId);
            this.listItemSMS = "";
            // this.listSMS.push({ sms: newAttendee.attendeeId}); ////CG
            // console.log('list SMS: ' + JSON.stringify(this.listSMS[0]));
            this.listSMS.push({phoneNumber: newAttendee.attendeeId});
        } else {
            return
        }
        this.attendeeStandBylist.push(newAttendee);
        this.emitTouched();
    }
    
    public attendeeManager(attendee: AttendeeStandBy) {
        console.log("attendee manager found entered, attendee: " + JSON.stringify(attendee));
        if (attendee.type === 'channel' || attendee.type === 'tenant') {
            this._addMeetingAttendee(attendee.attendeeId, attendee.userInfo, attendee.type);
        } else if (attendee.type === 'contact') {
            if (attendee.userInfo.contactChannelUserId) {
                this._addMeetingAttendee(attendee.attendeeId, attendee.userInfo, attendee.type);
            } else {
                this._addMeetingAttendeeContact(attendee.userInfo);
            }
        } else if (attendee.type === 'email') {
            this._addMeetingAttendeeEmail(attendee.attendeeId);
        } else if (attendee.type === 'sms') {
            // console.log('adding user SMS to attendee list: ' + JSON.stringify(attendee));
            this._addMeetingAttendeeSMS(attendee.attendeeId);
        }
    }

    private _addMeetingAttendee(userId: string, userInfo?: any, type?: string) {
        let attendee = {
            "channelUserId": userId,
            "meetingId": this.meetingId
        }
        console.log("Adding Meeting Attendee: " + JSON.stringify(attendee));
        this._meetingService.postMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Attendee Added");
                    // console.log(res);
                    if (userInfo) {
                        if (this.meetingForm.value.type === "quick" && userInfo) {
                            //userinfo check is to prevent adding self as a member
                            this._addMember(userId, this.selectedChannelId, userInfo.displayName);
                        }
                    }
                    // let isNewMeeting = !this.isEditQuick && !this.isEditChannel ? true : false;
                    // this._channelService.updateUpcomingMeetingsList(meetingId, isNewMeeting, channelId);
                } else if (res.status === 409) { 
                    // console.error(res);
                    this._toastService.error("This user has already been added");
                } else {
                    // console.error(res);
                    this._toastService.error("There was an error adding a attendee");
                }
            });
    }

    //Add Contact ass Attendee
    private _addMeetingAttendeeContact(contact: any) {
        let attendee = {
            "contactId": contact.contactId,
            "meetingId": this.meetingId
        };
        this._meetingService.postGuestMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Contact Added :::", res.body);
                    let invite = {
                        "inviteEmail": contact.email,
                        "meetingId": this.meetingId
                    }
                    this._meetingService.sendInviteToken(invite)
                        .subscribe(data => {
                            if (data.status === 200) {
                                // console.log("Token sent EMAIL ::", data)
                                // this._toastService.success(`Email invite was sent to ${invite.inviteEmail}`);
                            
                            } else {
                                // console.error("error :::", data);
                                this._toastService.error(`There was an error sending your email invite to ${invite.inviteEmail}`);
                            }
                        });
                } else if (res.status === 409) {
                    // console.error(res);
                    this._toastService.error("This contact has already been added");
                } else {
                    // console.error("error :::", res);
                    this._toastService.error("There was an error adding your contact");
                }
            });
    }
    

    private _addMeetingAttendeeEmail(email: string) {
        let attendee = {
            "email": email,
            "meetingId": this.meetingId
        };
        this._meetingService.postGuestMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Attendee Added EMAIL :::", res.body);
                    let invite = {
                        "inviteEmail": email,
                        "meetingId": this.meetingId
                    }
                    
                    this._meetingService.sendInviteToken(invite)
                        .subscribe(data => {
                            if (data.status === 200) {
                                // console.log("Token sent EMAIL ::", data)
                                // this._toastService.success(`Email invite was sent to ${invite.inviteEmail}`);
                            
                            } else {
                                // console.error("error :::", data);
                                this._toastService.error(`There was an error sending your email invite to ${invite.inviteEmail}`);
                            }
                        });
                } else if (res.status === 409) {
                    // console.error(res);
                    this._toastService.error("This email has already been added");
                } else {
                    // console.error("error :::", res);
                    this._toastService.error("There was an error sending your email");
                }
            });
    }

    // 🚧 new post SMS
    private _addMeetingAttendeeSMS(sms: string) {
        console.log("Posting SMS: " + sms);
        let attendee = {
            "phoneNumber": sms,
            "meetingId": this.meetingId
        };
        this._meetingService.postGuestMeetingAttendee(attendee)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Attendee Added SMS :::", res.body)
                    let invite = {
                        "inviteEmail": res.body.email,
                        "meetingId": this.meetingId
                    }
                    this._meetingService.sendInviteToken(invite)
                        .subscribe(data => {
                            if (data.status === 200) {
                                // console.log("Token sent SMS ::", data)
                                // this._toastService.success("SMS invite was sent!");
                            } else {
                                // console.error("error :::", data);
                                this._toastService.error("There was an error sending your SMS.");
                            }
                        });
                } else if (res.status === 409) {
                    // console.error(res);
                    this._toastService.error("This number has already been added");
                } else {
                    // console.error("error :::", res);
                    this._toastService.error("There was an error sending your SMS.");
                }
            });
    }

    // SEARCH FOR USERS IN TENNANT TO MEET 
    // public _getUsersBySearchQuery() {
    //     let userSearchBar: any = document.getElementById('user-tenant-search');
    //     let searchString = userSearchBar.value.replace(/\s/g, '');
    //     if (searchString.length < 1) {
    //         this.searchTenantUsersList = [];
    //     } else {
    //         this._apiCalls.getChannelUsersByTenantAndSearchQuery(searchString)
    //             .subscribe(data => {
    //                 if (data.status === 200) {
    //                     let searchList = data.body;
    //                     this.searchTenantUsersList = [];
    //                     searchList.forEach((searchUser, index) => {
    //                         if (this._authService._user.profile.sub !== searchUser.channelUserId) {
    //                             if (!this.inviteTenantUsersList.some(listUser => // not already in invite list 
    //                                 searchUser.channelUserId === listUser.channelUserId)
    //                                 && !this.meetingAttendees.some(attendant => // not already in current attendees list 
    //                                     searchUser.channelUserId === attendant.chatUserKeyNavigation.channelUserId)) {
    //                                 this.searchTenantUsersList.push(searchList[index]);
    //                             }
    //                         }
    //                     });
    //                 } else {
    //                     console.error(JSON.stringify(data));
    //                 }
    //             });
    //     }
    // }

    //SEARCH MEMBERS/OWNERS OF A CHANNEL
    public _getUsersFromChannelBySearchQuery() {
        let userSearchBar: any = document.getElementById('user-channel-search');
        let searchString = userSearchBar.value.replace(/\s/g, '');
        if (searchString.length < 1) {
            this.searchChannelUsersList = [];
        } else {
            this._chManageService.searchForMembersAndOwnersByChannelId(this.selectedChannelId, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        this.searchChannelUsersList = [];
                        searchList.forEach((searchUser, index) => {
                            if (this._authService._user.profile.sub !== searchUser.channelUserId) {
                                if (!this.inviteChannelUsersList.some(listUser => // not already in invite list 
                                    searchUser.channelUserId === listUser.channelUserId)
                                    && !this.meetingAttendees.some(attendant => // not already in current attendees list 
                                        searchUser.channelUserId === attendant.chatUserKeyNavigation.channelUserId)) {
                                    this.searchChannelUsersList.push(searchList[index]);
                                }
                            }
                        });
                    } else {
                        // console.error(JSON.stringify(data));
                        this._toastService.error("Could not find Channel");
                    }
                });
        }
    }

    //SEARCH Contacts List
    public _getUsersFromContactsBySearchQuery() {
        let userSearchBar: any = document.getElementById('user-contact-search');
        let searchString = userSearchBar.value.replace(/\s/g, '');
        if (searchString.length < 1) {
            this.searchContactUsersList = [];
        } else {
            this._contactsService.getContactsBySearchQuery(this._authService._user.profile.sub, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        this.searchContactUsersList = [];
                        // console.log(this.searchContactUsersList);
                        searchList.forEach((searchUser, index) => {
                            if (
                                // not already in invite list
                                !this.inviteContactUsersList.some(listUser => searchUser.contactId === listUser.contactId)
                                // &&
                                // // not already in current attendees list
                                // !this.meetingAttendees.some(attendant => searchUser.channelUserId === attendant.chatUserKeyNavigation.channelUserId) 
                            ) {
                                this.searchContactUsersList.push(searchList[index]);
                            }
                        });
                    } else {
                        // console.error(JSON.stringify(data));
                        this._toastService.error("Could not get Contacts");
                    }
                });
        }
    }

    public removeAttendeeManager(idx: number, type: string)  {
        let userId;
        if (type === 'attendee') {
            userId = this.meetingAttendees[idx].chatUserKeyNavigation.channelUserId;
        } else if (type === 'channel') {
            userId = this.inviteChannelUsersList[idx].userId
        } else if (type === 'tenant') {
            userId = this.inviteTenantUsersList[idx].contactChannelUserId;
        } else if (type === 'contact') {
            // console.log("Finding remove channel User ID Contact", this.inviteContactUsersList[idx])
            userId = this.inviteContactUsersList[idx].channelUserId;
        } else if (type === 'email') {
            console.log("Finding remove channel User ID Email", this.listEmail[idx]);
            userId = this.listEmail[idx].channelUserId;
        } else if (type === 'sms') {
            //console.log("Finding remove channel User ID SMS", this.listSMS[idx])
            userId = this.listSMS[idx].channelUserId;
        }

        if (userId) {
            console.log("Removing User: ", this.listEmail[idx]);
            this._removeAttendee(userId, type, idx);
            this.emitTouched();
        }
        // When the userID is null; if filling out the form prior to creating a meeting
        //// CG
        else if (type === 'email')
        {
            this.listEmail.splice(idx, 1);
        }
        else if (type === 'sms')
        {
            this.listSMS.splice(idx, 1);
        }

    }

    public _removeAttendee(userId: string, type: string, idx: number) {
        let attendeeObject = {
            meetingId: this.meetingId,
            channelUserId: userId
        };
        this._meetingService.deleteMeetingAttendee(attendeeObject)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("remove att res?", res)
                    if (type === 'attendee') {
                        this.getMeetingAttendees(); // update list
                    } else if (type === 'channel') {
                        this.inviteChannelUsersList.splice(idx, 1);
                    } else if (type === 'tenant') {
                        this.inviteTenantUsersList.splice(idx, 1);
                    } else if (type === 'contact') {
                        this.inviteContactUsersList.splice(idx, 1);
                    } else if (type === 'email') {
                        this.listEmail.splice(idx, 1);
                    } else if (type === 'sms') {
                        this.listSMS.splice(idx, 1)
                    }
                    if (this.meetingForm.value.type === "quick") {
                        this._removeMember(userId, this.selectedChannelId);
                    }
                } else {
                    console.error("error deleting meeting attendee?", res)
                }
            });
    }

    // cancel meeting
    public cancelMeetingPrompt() {
        this._toastService.continue("Are you sure you want to cancel this Meeting?", "cancelMeetingModal", "Notify the other guests")
    }

    private cancelMeeting(cancelNotification: boolean) {
        let meetingObj = {
            "meetingId": this.meetingId,
            "cancelNotification": cancelNotification
        };
        // console.log("cancel meeting?")
        this._meetingService.cancelMeeting(meetingObj)
            .subscribe(res => {
                // console.log("RES???:::", res)
                if (res.status === 200) {
                    // console.log("meeting Cancled", res)
                    let isNewMeeting = false;
                    this._channelService.updateUpcomingMeetingsList(this.meetingId, isNewMeeting, this.meetingForm.value.channelId);
                    // this._router.navigate(['/']);
                    this.onCompleteEvent.emit(); // 💡 can emit newMeeting to events component ... 
                } else {
                    console.error("error canceling meeting?", res)
                }
            });
    }

    public clearSearch(id: string) {
        let userSearchBar: any = document.getElementById(id);
        userSearchBar.value = '';
        this.searchTenantUsersList = [];
        this.searchChannelUsersList = [];
    }

    private _addMember(userId: string, channelId: string | EventEmitter<string>, username: string) {
        let member = {
            "channelUserId": userId,
            "channelId": channelId
        };
        this._apiCalls.postChannelMember(member)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log(username + "Member Added", res.body);
                } else if (res.status === 409) {
                    // console.log(username + " Already Exists");
                } else {
                    // consol/e.log("error in _addMember", res);
                    this._toastService.error(username + " could not be added");
                }
            });
    }

    private _removeMember(userId: string, channelId: string | EventEmitter<string>) {
        let member = {
            "channelUserId": userId,
            "channelId": channelId
        };
        this._apiCalls.deleteChannelMember(member)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Member Removed");
                } else if (res.status === 404) {
                    // console.log("Member does not exist for this Attendee");
                } else {
                    // console.log("error in _addMember", res);
                    this._toastService.error("Member could not be removed");
                }
            });
    }

    // 🚧 ... so can use enter to pick selection from dropdown without triggering the enter submit ... 
    public handleDropdownEnterSubmitLock(isOpen:boolean, isDatePicker: boolean, dropdownId?: string) {
        // console.log(isOpen)
        if(isOpen) {
            this.isDropdownOpen = isOpen;
        } else {
            let el = <HTMLElement>document.getElementById(dropdownId);
            if(isDatePicker) {
                if(!!el) {
                    el.focus();
                }
            } else {
                if(!!el) {
                    el.blur();
                }
            }
            this.isDropdownOpen = isOpen;
        }
    }

    public showManageUsers() {
        this.manageUsers = true;
        this.emitTouched();
        // console.log("this.selectedChannelId", this.selectedChannelId)
        // console.log("this.meetingId", this.meetingId)
    }

    public closeManageUsers() {
        this.onCompleteEvent.emit();
    }

    togglePrivateChange(e: MatSlideToggleChange){
        console.log("Private before: " + this.privateMeeting )
         this.privateMeeting = !e.checked;
        console.log("Private after: " + this.privateMeeting )

    }

}













// old datepicker funcs ... 
    // date picker keydown down arrow lock 
    // public preventDatePickerDownArrowPast(e) {
    //     if ((this.selectSchedule.fromDate || e.target.value) <= this.todaysDate) {
    //         e.preventDefault();
    //         console.log("1 down arrow pressed, past prevented, e.val:", e.target.value, "today:", this.todaysDate)
    //     }
    // }

    // ℹ this being checked on date picker <input (change)="validateMeetingStart()"/>
    // public validateMeetingStart() {
    //     if (this.selectSchedule.fromDate) {
    //         console.log("date on change validation", this.selectSchedule.fromDate)
    //         this.setFromDate();
    //         if (this.dateCompare(this.meetingForm.value.start)) {
    //             this.scheduleError = false;
    //         } else {
    //             this.scheduleError = true;
    //             this.resetTimeValueOnError();
    //         }
    //     } else {
    //         console.log("no val in date picker, no error plz")
    //     }
    // }

    // called on date pickers (blur) if invalid date will reset to Date.now() & remove error message
    // public validateMeetingStartBlurReset() {
    //     console.log("date on blur validation, schedule error? ::", this.scheduleError)
    //     if (this.scheduleError) {
    //         let now = new Date();
    //         this.selectSchedule.fromDate = now;
    //         this.resetTimeValueOnError();
    //         this.scheduleError = false;
    //     }
    // }
