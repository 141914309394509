<div class="project-create flex-col">
    <!-- title -->
    <div class="title-row mb-4">
        <div class="project-title">
            <h3 class="title-thin flex align-center"><i class="fas fa-project-diagram me-3"></i>Create New Project</h3>
        </div>
    </div>
    <form 
        [formGroup]="newProject" 
        (document:keydown.enter)="newProject.valid && !isCreatingProject ? createProject() : ''"
        class="flex-col mt-3">
        <!--  -->
        <input 
            type="text"
            formControlName="projectName"
            name="projectName"
            maxlength="200"
            class="borderless-input mt-4"
            placeholder="Project Name">
        
        <ng-container *ngIf="newProjectControls.projectName.touched && newProjectControls.projectName.errors">
            <div 
                *ngIf="newProjectControls.projectName.errors.required" 
                class="error-message">
                Name is required.
            </div>
            <div 
                *ngIf="newProjectControls.projectName.errors.minlength" 
                class="error-message">
                Name must be at least 2 characters.
            </div>
        </ng-container>
        <!-- submit -->
        <div class="flex justify-center mt-4">
            <button 
                class="primary-button"
                [disabled]="newProject.invalid"
                [class.invalid-form]="isCreatingProject"
                (click)="newProject.valid && !isCreatingProject ? createProject() : ''">
                
                <ng-container *ngIf="!isCreatingProject">
                    Create Project
                </ng-container>
                <ng-container *ngIf="isCreatingProject">
                    <i class="fas fa-spinner spinner"></i>&nbsp;Creating...
                </ng-container>
            </button>
        </div>
    </form>
</div>
