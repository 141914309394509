import { FormGroup } from '@angular/forms';

//For schedule meeting
export function ChannelSelected() {
    return (formGroup: FormGroup) => {
        const typeControl = formGroup.controls['type'];
        const channelIdControl = formGroup.controls['channelId']

        if (channelIdControl.errors && !channelIdControl.errors.channelSelected) {
            // return if another validator has already found an error on the ChannelIdControler
            return;
        }
        if (typeControl.value === 'channel') {
            if (channelIdControl.value === '' || !channelIdControl.value) {
                channelIdControl.setErrors({ channelSelected: true });
                return;
            }
        }
        channelIdControl.setErrors(null);
    }
}
