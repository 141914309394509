


<!-- // ✨✨✨❌❌❌ component not currently in use (see shared/components/live-stream-video for new implementation) ...  -->
<div class="header">
    <div class="initialHeader">
        <div class="headerInfo">
            <h2 class="small-header">Live Stream</h2>
        </div>
    </div>
</div>

<div class="content-module">
    <div class="chat" chat-type="live-stream">
        <div class="quick-meeting-hub" *ngIf="showPage && isFind && !noAccess">
            <div class="find-quick-meeting">
                <!-- <h4 *ngIf="meetingNotFound">Meeting could not be found</h4> -->
                <!-- <h5>Please enter a quick meeting ID</h5> -->
                <form class="form-style-2  form-no-borders">
                    <div class="form-inputs">
                        <label>
                            <p class="set-label">Find A Live Stream</p>
                            <button 
                                *ngIf="chId.valid"
                                (click)="_getChannel(searchId)">
                                <i class="fa fas-search search__helper"></i>
                            </button>
                            <button 
                                class="not-valid"
                                *ngIf="!chId.valid">
                                <i class="fa fas-search search__helper"></i>
                            </button>
                            <input 
                                type="text"
                                name="chId"
                                class="set-input"
                                placeholder="Stream ID"
                                [(ngModel)]="searchId"
                                minlength='9'
                                maxlength='36'
                                required
                                #chId="ngModel">
                        </label>
                    </div>
                </form>
                <div class="errors">
                    <p *ngIf="liveStreamNotFound">Stream could not be found</p>
                    <p *ngIf="denyAccess">You do not have access to this meeting</p>
                </div>
            </div>
        
            <h2 *ngIf="createAllow">Create New Live Stream</h2>
        
            <!-- ❌ this is <schedule-meeting> now if needed ... -->
            <!-- <app-create-meeting
                *ngIf="createAllow"
                class="meeting-form"
                (meetingCreated)="_getChannel($event)"
                [meetingType]="'live'">
            </app-create-meeting> -->
        </div>
        
        <div class="chat-messaging" *ngIf="showPage && !isFind && !noAccess" [ngClass]="{'hide-attendance': !showAttendance}">
            <div class="user-info-bar">
                <p class="channel-name">{{channelInfo.name}}</p>
        
                <div class="channel-bar-buttons">
                    <button title="Display Messages" (click)="displayChat()">
                        <i class="fa fas-comment-text"></i>
                    </button>
                    <button title="Meeting Settings" (click)="displaySettings()" *ngIf="meetingInfo && isOwner">
                        <i class="fa fas-settings"></i>
                    </button>
                </div>
            </div>
        
            <div class="chat-group" [attr.data-show-video]="showVid" [attr.data-show-chat]="showChat" [attr.data-show-settings]="showSettings">
                
                <div class="video-stream live-stream">
                    <amp 
                        id="live-player-box"
                        vId="vid1"
                        autoplay={{isProd}}
                        controls=true
                        src="{{channelInfo.liveStreamUrl}}"> 
                    </amp>
                    <!-- <app-chat></app-chat> -->
                </div>
                <!-- <div class="video-stream" stream-type="" id="video-stream">
                    <app-rtc-video [channelId]="channelId" (resetVideoCall)="hideVideo()" (promptVideoCall)="showVideo()"></app-rtc-video>
                </div> -->
        
                <app-chat [incomingChannelId]="channelId"></app-chat>
            </div>
        
            <div class="users-in-channel" *ngIf="showAttendance">
                <p class="box-message">Users in Channel</p>
                <div *ngFor="let user of chatList">
                    <div class="user-card {{user.userStatus}}" *ngIf="user.userStatus !== 'offline'">
                        <span class="status-bar" title="{{user.userStatus|statusDisplay}}"></span>
                        <div class="user-img">
                            <img src="https://profile-images.azureedge.us/{{user.chatUserKeyNavigation.hash}}"
                                alt="profile picture">
                        </div>
                        <p class="chat-name">
                            {{user.chatUserKeyNavigation.displayName}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="tempFileBtn">
                <button (click)="showModal()" *ngIf="hasAccess">Shared Files</button>
                <!-- 💡💡💡 need to add new file storage app here ... -->
            </div>
        </div>
        
        <div *ngIf="showPage && !isFind && noAccess">
            <h2>You dont have access to this Live Stream</h2>
            <!-- <a (click)="findNewStream()">Find Another Stream?</a> -->
        </div>
    </div>
</div>
