import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "folderName",
})

export class FolderNamePipe implements PipeTransform {
    transform(value: string, args?: any): any {
        if (value === "/") {
            return "Main";
        } else {
            return value.slice(0, -1);
        }
    }
}