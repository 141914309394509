import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChannelHomepageComponent } from './channel-homepage/channel-homepage.component';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'channels', component: ChannelHomepageComponent },
    { path: 'channel', component: ChannelHomepageComponent },

    // need to get a guests invited channel id to block other channel routes, altho if not invited will go to unauthorized anyways ...
    { path: 'channel/:channelId', component: ChannelHomepageComponent },
  ],
    { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }
  )],
  exports: [RouterModule]
})
export class ChannelAppRoutingModule { }
