
<!-- <iframe src="https://www.joelinares.com" width="100%" height="100%"></iframe> -->
<div class="content-module-full" *ngIf="showPage">
    <div class="header">
        <div class="initialHeader">
            <div class="headerInfo">
                <h2 class="small-header">Kurento Meeting</h2>
            </div>
        </div>
    </div>
    <div class="channel-layout-default">
        <div class="channel-layout-head">
            <div class="channel-info-bar">
                <p class="channel-name">
                    {{channelInfo.name}}
                </p>
    
                <div class="channel-bar-buttons">
                    <button title="Topic: {{channelInfo.topic}}" class="info">
                        <i class="zmdi zmdi-info"></i>
                    </button>
                </div>
            </div>

            <div class="channel-button-bar">
                <button (click)="showModal()" title="Shared Files">
                    <i class="zmdi zmdi-folder"></i>
                </button>
            </div>
        </div>
        <!--<iframe src="../../../../assets/KurentoVideo/Test2.html?username={{username}}&channelname={{channelId}}" width="100%" height="100%" class="video-frame"></iframe>-->
        <!--<iframe src="https://unity.via3.com/test2.html" allow="camera;microphone" width="100%" height="100%" class="video-frame"></iframe>-->
        <div class="channel-layout-primary">
            <div class="video-stream-container">
                <iframe 
                    [src]="iframe"
                    width="100%" height="100%" 
                    class="video-frame scrollbar-new"
                    allowfullscreen="true" 
                    webkitallowfullscreen="true" 
                    mozallowfullscreen="true"
                    allow="geolocation; microphone; camera; midi; encrypted-media;">
                </iframe>
            </div>
        </div>
        
        <div class="channel-layout-secondary">
            <app-chat [incomingChannelId]="channelId"></app-chat>
        </div>
    </div>
</div>

<div *ngIf="_showFiles" class="tempFileModal">
    <div class="modal-box">
        <div class="modal-top">
            <h3 class="modal-title">Shared files</h3>
            <span class="close-btn">
                <i class="zmdi zmdi-close" (click)="showModal()"></i>
            </span>
        </div>

        <div class="file-list">
            <div *ngFor="let file of fileList" class="file">
                <a  (click)="_downloadSharedFile(file.pathDisplay, file.name)"  download>
                    {{file.name}}
                </a>
                <a (click)="_deleteFile(file.pathDisplay)">
                    delete
                </a>
            </div>
            <p class="empty-file-list" *ngIf="fileList.length < 1">
                This file list is empty.
            </p>
        </div>

        <div class="file-list-upload">
            <div class="upload-btn" title="Upload File">
                <!-- <app-file-*add new here... [hasAccess]="true" [componentType]="'chat'" [channelInfo]="channelInfo"></app-file-*add new here...> -->
                <div class="file-button">
                    <span>Upload File</span>
                    <i class="zmdi zmdi-upload"></i>
                </div>
            </div>
        </div>
    </div>
</div>
