import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ApiCallsService } from "../../services/api-calls.service";
import { AppComponent } from "src/app/app.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    fadeIn,
    listAnimationWrap,
    listAnimationItem,
    listAnimationWrapLong,
    listAnimationItemLong,
    listAnimationWrapAvatar,
    listAnimationItemAvatar,
    fadeInFast
} from "../../animations/global-animations";
import { AuthService } from "src/app/core/auth.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { Subscription } from "rxjs";
import { ChannelUser } from "src/app/shared/interface/channel-user";
import { consoleLogStyle } from '../../shared/varaibles';
import { Project } from "src/app/shared/interface/project";
import { GuestService } from "src/app/services/guest.service";
import { ChannelService } from 'src/app/services/channel-service';
import { ChannelCard } from "src/app/shared/interface/channel-card";
import { MatAccordion } from "@angular/material/expansion";
import { ChannelFull } from "src/app/shared/interface/channel-full";



@Component({
    selector: "channel-homepage",
    templateUrl: "./channel-homepage.component.html",
    styleUrls: ["./channel-homepage.component.scss"],
    animations: [
        listAnimationWrap,
        listAnimationItem,
        listAnimationItemLong,
        listAnimationWrapLong,
        listAnimationWrapAvatar,
        listAnimationItemAvatar,
        fadeIn,
        fadeInFast,
    ],
})

export class ChannelHomepageComponent implements OnInit, OnDestroy {

    public userInfo: ChannelUser = this._authService._channelUserInfo;

    public channelId: string;
    public channelType: any;
    public channelInfo: ChannelFull;

    // public pageReload: boolean = false;
    public channelAuthPage: boolean = false; // true on 409 error from get channel ... 
    public notInChannel: boolean = false;
    public pageReady: boolean = false;

    public permissions: any = {
        allowFiles: false,
        allowInviteGuest: false,
        allowUserManager: false,
        jitsiPreventList: []
    };

    // 🚧 new modals ... 
    public displayChannelInfoModal: boolean = false;

    public displayScheduleModal: boolean = false;
    public displayScheduleWarning: boolean = false;
    public scheduleChannelMeetingObject: any = null;

    public displayCreateChannelModal: boolean = false;
    public displayCreateChannelWarning: boolean = false;

    public displayManageUsersModal: boolean = false; // new 12/15/2021 ... 
    public selectedChannelId: string = null;

    // 🚧 new channels history ... 
    public isDesktop: boolean = this._channelService._isDesktop;

    public channelSelectBy: string;

    public isLoadingProjects: boolean;
    public isLoadingChannelCards: boolean;

    public channelCards: Array<ChannelCard> = []; // new ... 🚧 is for history & favs ... 
    public noChannelHistory: boolean;
    public noChannelFavorites: boolean;
    public isLoadingMoreChannels: boolean;
    public channelCardsPag: any = {};

    public projects: Array<Project> = [];

    public userHasSharedFoldersProject: boolean = false;

    @ViewChild(MatAccordion) accordion: MatAccordion;

    private subscriptions: Subscription[] = [];


    constructor(
        @Inject('rippleColor') public rippleColor: string,
        private _apiCalls: ApiCallsService,
        private _route: ActivatedRoute,
        private router: Router,
        private _toastService: ToastNotificationService,
        private _authService: AuthService,
        private _guestService: GuestService,
        private _channelService: ChannelService
    ) {
        this.subscriptions.push(
            this._authService.channelUserInfoChanged$.subscribe(() => {
                this.userInfo = this._authService._channelUserInfo;
                this.managePage();
            }));

        // 
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "closeScheduleModal") {
                this.closeScheduleModal();
            }
            if (data.type === "closeCreateChannelModal") {
                this.closeCreateChannelModal();
            }
        }));
    }

    ngOnInit() {
        if(this.userInfo) {
            this.managePage();
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    managePage() {
        if (window.location.href.indexOf("?guest") > -1) {
            let url = this.router.url.split("?guest=");
            this.refreshUser(url);
        }
        this._route.params.subscribe((params: Params) => {
            // console.info("%c params??? ", consoleLogStyle, params)
            this.pageReady = false;
            if (params["channelId"]) {
                this.channelId = params["channelId"];
            }
            if (!this.channelId) {
                this.notInChannel = true;
                this.pageReady = true;
            } else if (this.channelId === "quickmeeting") {
                this.channelId = null;
                this.channelType = "quick";
                this.pageReady = true;
            } else {
                this.getChannel();
            }
        });
        this._route.queryParamMap.subscribe((params) => {
            // console.info("%c params??? ", consoleLogStyle, params.has('orderBy'), params.get('orderBy'))
            if (params.has('orderBy')) {
                this.channelSelectBy = params.get('orderBy');
            } else {
                this.channelSelectBy = 'history';
            }
        });
        if (this.userInfo.isGuest && this.channelId) {
            this._guestService.setInvitedChannelId(this.channelId);
        } else if (this.userInfo.isGuest && !this.channelId) {
            this._guestService.routeToChannelInvited();
        } else if (!this.userInfo.isGuest && !this.channelId) {
            switch (this.channelSelectBy) {
                case 'history': {
                    this._getChannelHistoryForUser();
                    break;
                }
                case 'favorites': {
                    this._getChannelFavoritesForUser();
                    break;
                }
                case 'projects': {
                    this.getUsersProjects();
                    break;
                }
                default:
                    break;
            }
        }
    }

    // 💡 if in channel ... 
    getChannel() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe((data) => {
                // console.log("%c Get Channel from channel homepage", consoleLogStyle, this.channelId);
                if (data.status === 200) {
                    this.channelInfo = data.body;
                    // console.log("Get Channel info from ch. Homepage", this.channelInfo);
                    this.channelType = data.body.type.toLowerCase();
                    if (this.channelType === "quick") {
                        this.permissions.allowFiles = true; // cote new for quick meeting now to allow files
                        // this.permissions.allowInviteGuest = true;
                        this.permissions.jitsiPreventList = ['files', 'record', 'users'];
                    } else {
                        this.permissions.allowFiles = true;
                        // this.permissions.allowUserManager = true;
                    }
                    this.pageReady = true;
                } else if (data.status === 409) {
                    this.channelAuthPage = true;
                    // console.log("you do not have access to this channel");
                } else {
                    console.error("there was an error getting this channel");
                    // alert(JSON.stringify(data.status));
                }
            });
    }
    // 

    // 💡 if channel/projects page ...
    getUsersProjects() {
        this.isLoadingProjects = true;
        //console.log(this.userInfo);
        this.projects = [];
        this._apiCalls.getProjectsForChannelsPage()
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("%c get Users projects/channels res? ", consoleLogStyle, res.body);
                    // this.createProjectsObj(res.body);
                    this.projects = res.body;
                    this.projects.forEach((proj) => proj.channels = []); // 
                    this.isLoadingProjects = false;
                } else {
                    console.error("error in get users projects/channels", res)
                    this.isLoadingProjects = false;
                }
            });
    }

    //  get channels by proj id, push to Project.channels[] ... on dropdown open ...
    getChannelsForProject(projectId: string, projectIdx: number) {
        this.projects[projectIdx].isLoadingChannels = true;
        this._apiCalls.getChannelsByProject(this.userInfo.channelUserId, projectId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.projects[projectIdx].channels = data.body;
                    this.projects[projectIdx].isLoadingChannels = false;
                    // console.info("projects channels?", this.projects[projectIdx].channels)
                } else {
                    this.projects[projectIdx].isLoadingChannels = false;
                    this._toastService.error("there was a problem getting this projects channels.")
                }
            });
    }

    prevDefStopProp(e: Event) {
        // console.log('oi')
        e.preventDefault();
        e.stopPropagation();
    }

    // favorite channel 
    favoriteChannel(e: Event, channelHistoryId: string, projectIdx: number, channelIdx: number) {
        this.prevDefStopProp(e);
        if (!!channelHistoryId) {
            let patch = {
                "op": "replace",
                "path": "/isFavorite",
                "value": true
            };
            this._apiCalls.patchChannelHistoryForChannelUser(channelHistoryId, patch)
                .subscribe(res => {
                    if (res.status === 204) {
                        // console.log("channel favorited")
                        this.projects[projectIdx].channels[channelIdx].isFavorite = true;
                    } else {
                        this._toastService.error("There was an error favoriting this channel.");
                    }
                });
        } else {
            this.createHistoryForFavorite(projectIdx, channelIdx);
        }
    }

    createHistoryForFavorite(projectIdx: number, channelIdx: number) {
        let historyObj = {
            "chatUserId": this.userInfo.channelUserId,
            "chatRoomId": this.projects[projectIdx].channels[channelIdx].channelId,
            "isFavorite": true
        };
        this._apiCalls.postChannelHistoryForChannelUser(historyObj)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("channel favorited")
                    this.projects[projectIdx].channels[channelIdx].isFavorite = true;
                } else {
                    this._toastService.error("There was an error favoriting this channel.");
                }
            });
    }

    unFavoriteChannel(e: Event, channelHistoryId: string, projectIdx: number, channelIdx: number) {
        this.prevDefStopProp(e);
        let patch = {
            "op": "replace",
            "path": "/isFavorite",
            "value": false
        };
        this._apiCalls.patchChannelHistoryForChannelUser(channelHistoryId, patch)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("channel un-favorited")
                    this.projects[projectIdx].channels[channelIdx].isFavorite = false;
                } else {
                    this._toastService.error("There was an error un-favoriting this channel.")
                }
            });
    }

    // Channel options dropdown if by project ... 
    showChannelOptionsDropdown(projIdx: number, chIdx: number, e?: Event) {
        const options = document.getElementById(`project-channel-options-dropdown-${projIdx}-${chIdx}`);
        options.classList.add('active');

        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('projectScrollbox');
        scrollBox.classList.add('hidden');
    }

    hideChannelOptionsDropdown(projIdx: number, chIdx: number, e?: Event) {
        const options = document.getElementById(`project-channel-options-dropdown-${projIdx}-${chIdx}`);
        options.classList.remove('active');

        let scrollBox = <HTMLElement>document.getElementById('projectScrollbox');
        scrollBox.classList.remove('hidden');
    }

    // 🚧 Channel history options dropdown ... only 1 index to track ...
    showChannelHistoryOptionsDropdown(i: number, e?: Event) {
        const options = document.getElementById(`channel-options-dropdown-${i}`);
        options.classList.add('active');

        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('channelScrollbox');
        scrollBox.classList.add('hidden');
    }

    hideChannelHistoryOptionsDropdown(i: number, e?: Event) {
        const options = document.getElementById(`channel-options-dropdown-${i}`);
        options.classList.remove('active');

        let scrollBox = <HTMLElement>document.getElementById('channelScrollbox');
        scrollBox.classList.remove('hidden');
    }

    // modal funcs ...
    // this will close each modal 
    clickOffChannelModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'channel-info-modal': {
                this.closeChannelInfoModal();
                break;
            }
            case 'manage-users-modal': {
                this.closeManageUsersModal();
                break;
            }
            case 'create-project-channel-modal': {
                if (this.displayCreateChannelWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateChannelModal");
                } else {
                    this.closeCreateChannelModal();
                }
                break;
            }
            case 'schedule-modal': {
                if (this.displayScheduleWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
                } else {
                    this.closeScheduleModal();
                }
                break;
            }
            default: return;
        }
    }

    // create channel modal can create project too ...
    showCreateChannelModal() {
        this.displayCreateChannelModal = true;
    }
    closeCreateChannelModal() {
        this.displayCreateChannelModal = false;
        this.displayCreateChannelWarning = false;
    }
    closeCreateChannelModalConfirm() {
        // console.log("esc pressed")
        if (this.displayCreateChannelWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateChannelModal");
        } else {
            this.closeCreateChannelModal();
        }
    }

    // channel info modal
    showChannelInfoModal(channelId: string) {
        this.selectedChannelId = channelId;
        this.displayChannelInfoModal = true;
    }
    closeChannelInfoModal() {
        this.displayChannelInfoModal = false;
        this.selectedChannelId = null;
    }

    // manage users modal ... 
    showManageUsersModal(channelId: string) {
        this.selectedChannelId = channelId;
        this.displayManageUsersModal = true;
    }
    closeManageUsersModal() {
        this.displayManageUsersModal = false;
        this.selectedChannelId = null;
    }

    // schedule meeting Modal
    showScheduleModal(channelId: string, channelName: string) {
        let channelObj = {
            channelId,
            name: channelName
        };
        this.scheduleChannelMeetingObject = channelObj;
        this.displayScheduleModal = true;
    }
    closeScheduleModal() {
        this.displayScheduleWarning = false;
        this.displayScheduleModal = false;
        this.scheduleChannelMeetingObject = null;
    }
    closeScheduleModalConfirm() {
        if (this.displayScheduleWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
        } else {
            this.closeScheduleModal();
        }
    }

    // this seems to be for Guest user ... 
    refreshUser(url: any) {
        if (this._authService._user.profile.name !== url[1]) {
            this._authService.removeUserAuthentication().then(() => {
                window.location.reload();
            });
        }
    }

    // 🚧🚧🚧 NEW CHANNEL HISTORY INTERGRATION ... 

    onWindowResize() {
        this.isDesktop = this._channelService._isDesktop;
    }

    selectChannelOrder(val: string) {
        // console.log("%c order changed", consoleLogStyle, val)
        this.channelSelectBy = val;
        if (val === 'history') {
            this._getChannelHistoryForUser();
        }
        if (val === 'favorites') {
            this._getChannelFavoritesForUser();
        }
        if (val === 'projects') {
            this.getUsersProjects();
        }
    }

    _getChannelHistoryForUser() {
        this.isLoadingChannelCards = true;
        this.channelCards = [];
        this.noChannelHistory = false;
        this.noChannelFavorites = false;
        // console.log("%c api call with userinfo CHANNELS ", consoleLogStyle, this.userInfo);
        this._apiCalls.getChannelHistoryForChannelUserFullList(this.userInfo.channelUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    // .concat(data.body).concat(data.body).concat(data.body) ... page test ... 
                    this.channelCards = data.body;
                    this.channelCardsPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c channel history CHANNELS ", consoleLogStyle, this.channelCards);
                    // console.log("%c channel history CHANNELS pagination ? ", consoleLogStyle, this.channelCardsPag);
                    this.isLoadingChannelCards = false;
                    if (this.channelCards.length === 0) {
                        this.noChannelHistory = true;
                    }
                } else {
                    // console.error("error getting channel history ...", data)
                    this.isLoadingChannelCards = false;
                    this._toastService.error("There was an error getting channel history.");
                }
            });
    }

    _getChannelFavoritesForUser() {
        this.isLoadingChannelCards = true;
        this.channelCards = [];
        this.noChannelFavorites = false;
        this.noChannelHistory = false;
        // console.log("%c api call with userinfo CHANNELS ", consoleLogStyle, this.userInfo);
        this._apiCalls.getChannelFavoritesForChannelUser(this.userInfo.channelUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.channelCards = data.body;
                    this.channelCardsPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c channel favorite CHANNELS ", consoleLogStyle, this.channelCards);
                    // console.log("%c channel favorite CHANNELS pagination ? ", consoleLogStyle, this.channelCardsPag);
                    this.isLoadingChannelCards = false;
                    if (this.channelCards.length === 0) {
                        this.noChannelFavorites = true;
                    }
                } else {
                    // console.error("error getting channel favs ...", data)
                    this.isLoadingChannelCards = false;
                    this._toastService.error("There was an error getting channel favorites.");
                }
            });
    }

    // 💡 infinite scrolling w/ Intersection Observer api ... [(@listAnimationItemLong.done)="last ? runPaginationObserver() : ''"] 
    // 🌐 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API 
    paginationObserver() {
        let lastEl = <HTMLElement>document.getElementById('LastChannel'); // grab last El in list [after animation done & is in DOM] 
        // console.log("%c List anime done: run intersect observer 🎯 LAST El: ", consoleLogStyle, lastEl)
        if (!!lastEl) {
            let observer = new IntersectionObserver(entries => {
                entries.forEach(entry => { // can observe multiple El's but will always be an Array of [entries] 
                    // console.log("%c is 🎯 El intersecting? ", consoleLogStyle, entry.isIntersecting)
                    // || entry.intersectionRatio > 0 - also works
                    if (entry.isIntersecting && entry.intersectionRatio >= 0) { // bottom of view intersecting W/ the top of El 
                        // console.log('%c 🎯 El is IN view run get next page! ', consoleLogStyle);
                        this._getMoreChannelHistory(this.channelCardsPag.nextPageLink);
                        observer.unobserve(entry.target); // stop tracking this El, will grab next LastEl 
                    } else {
                        // console.log('%c 🎯 El OUT of view. ',consoleLogStyle);
                    }
                });
            });
            observer.observe(lastEl); // track the last El in list 
        } else {
            // console.info("no last EL?", lastEl);
        }
    }

    _getMoreChannelHistory(link: any) { // returns 8 results ... 2 page len avg. 
        this.isLoadingMoreChannels = true;
        this._apiCalls.getGenericForPagination(link)
            .subscribe(data => {
                if (data.status === 200) {
                    this.channelCards = this.channelCards.concat(data.body);
                    this.channelCardsPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c MORE channel history CHANNELS", consoleLogStyle, this.channelHistory);
                    // console.log("%c channel history CHANNELS pagination ? ", consoleLogStyle, this.channelCardsPag);
                    this.isLoadingMoreChannels = false;
                } else {
                    console.error("error getting channel history ...")
                    this._toastService.error("There was an error getting channel history.");
                    this.isLoadingMoreChannels = false;
                }
            });
    }

    // favorites for history|fav view
    favoriteChannelHistoryView(e: Event, channelHistoryId: string, channelIdx: number) {
        this.prevDefStopProp(e);
        if (!!channelHistoryId) {
            let patch = {
                "op": "replace",
                "path": "/isFavorite",
                "value": true
            };
            this._apiCalls.patchChannelHistoryForChannelUser(channelHistoryId, patch)
                .subscribe(res => {
                    if (res.status === 204) {
                        // console.log("channel favorited")
                        this.channelCards[channelIdx].isFavorite = true;
                    } else {
                        // console.log("%c channel favorited patch ", consoleLogStyle, channelHistoryId)
                        this._toastService.error("There was an error favoriting this channel.");
                    }
                });
        } else {
            this.createHistoryForFavoriteHistoryView(channelIdx);
        }
    }

    createHistoryForFavoriteHistoryView(channelIdx: number) {
        let historyObj = {
            "chatUserId": this.userInfo.channelUserId,
            "chatRoomId": this.channelCards[channelIdx].channelId,
            "isFavorite": true
        };
        this._apiCalls.postChannelHistoryForChannelUser(historyObj)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("channel favorited")
                    this.channelCards[channelIdx].isFavorite = true;
                } else {
                    // console.log("%c channel favorited create history ", consoleLogStyle, historyObj)
                    this._toastService.error("There was an error favoriting this channel.");
                }
            });
    }

    unFavoriteChannelHistoryView(e: Event, channelHistoryId: string, channelIdx: number) {
        this.prevDefStopProp(e);
        let patch = {
            "op": "replace",
            "path": "/isFavorite",
            "value": false
        };
        this._apiCalls.patchChannelHistoryForChannelUser(channelHistoryId, patch)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("channel un-favorited")
                    this.channelCards[channelIdx].isFavorite = false;
                } else {
                    this._toastService.error("There was an error un-favoriting this channel.")
                }
            });
    }

    handleBrokenImage(projectIdx: number | null, channelIdx: number, userIdx: number) {
        // console.info('yeeeeeeee')
        if (this.channelSelectBy === 'projects') {
            this.projects[projectIdx].channels[channelIdx].allChannelUsers[userIdx].hash = '1';
        } else {
            this.channelCards[channelIdx].allChannelUsers[userIdx].hash = '1';
        }
    }
}
