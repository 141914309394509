import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastNotificationComponent } from './toast-notification-component/toast-notification.component';
import { BrowserModule } from '@angular/platform-browser';
import { ToastNotificationService } from '../services/toast-notification.service';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ToastNotificationComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        BrowserModule,
        RouterModule
    ],
    exports: [    
        ToastNotificationComponent    
    ],
    providers: [    
        ToastNotificationService    
    ]
})
export class ToastNotificationModule { }
