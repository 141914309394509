import { 
    Component, 
    OnInit, 
    OnDestroy, 
    OnChanges, 
    Input, 
    Output,
    EventEmitter,
    AfterViewInit, 
    SimpleChanges, 
    ChangeDetectorRef, 
    ViewChild, 
    ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ChannelService} from 'src/app/services/channel-service';
import { 
    fadeIn, 
    fadeInFast, 
    rotateInOut, 
    slideInLeft, 
    slideInRight, 
    listAnimationWrap, 
    listAnimationItem} from 'src/app/animations/global-animations';
import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob';
import { TokenCredential } from '@azure/core-http';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { ChannelUser } from '../../interface/channel-user';
import { consoleLogStyle } from '../../varaibles';
import { FileStorageFile, FileStorageUsage } from '../../interface/file-storage-file';
import { GuestService } from 'src/app/services/guest.service';
import { AuthService } from 'src/app/core/auth.service';
import { Project } from "../../interface/project";
import { ChannelFull } from "../../interface/channel-full";
import { ChannelCard } from '../../interface/channel-card';
import { LoaderTargetPlugin } from 'webpack';
import { invalid } from 'moment';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'file-storage[componentId]',
    templateUrl: './file-storage.component.html',
    styleUrls: ['./file-storage.component.scss'],
    animations: [
        fadeIn, 
        fadeInFast, 
        rotateInOut, 
        slideInLeft, 
        slideInRight, 
        listAnimationWrap, 
        listAnimationItem, 
    ]
})
export class FileStorageComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input('componentId') componentId: string;// Required ... 
	@Input('isPortable') isPortable: boolean; // if is component; else is fs home.

    // should pass a Channel{} here ... 
	@Input('channelId') channelId: string; // in channel component only
	@Input('channelName') channelName: string; // in channel component only
    @Input('channelContainerName') channelContainerName: string; // in channel component only

    @Input('isGuest') isGuest: boolean; // in channel component only (permissions) 
    @Input('isOwner') isOwner: boolean; // in channel component only (permissions)
    // @Input('isCreator') isCreator: boolean; // in channel component only (permissions) ❌ not currently used ... 

    @Input('isSidebar') isSidebar: boolean;
    @Input('showFiles') showFiles: boolean;
    // @Input('inVideo') inVideo: boolean;

    @Output() channelUpdated = new EventEmitter();

    public isPreLoad: boolean; // to supress error messages while loading ... 

    public containerName: string;
    public currentFolderArray: any = [{ // this container name is re-set on your/channel files 
        urlPath: '/',
        fileName: '/',
        containerName: null,
        blodFileId: null,
        isFile: false
    }];

    public isFilesLoading: boolean;
    public filesList: FileStorageFile[] = [];
    public fullFilesList: FileStorageFile[] = []; 

    public sharedFoldersList: FileStorageFile[] = [];
    public personalFilesList: FileStorageFile[] = [];
    
    public isGettingNextPage = false;
    public fileListPagination: any = {};

    public selectedFile: FileStorageFile = null;

    // create folder
    public newFolder: FormGroup;
    public displayCreateFolderForm = false;
    public isFolderSubmitting = false;

    // rename file
    public renameFileForm: FormGroup;
    public displayRenameFileForm = false;
    public isRenameSubmitting = false;

    public isDeleting = false;
    public isDownloading = false;

    public displaySearchFilesInput = false;
    public isSearching = false;

    public userId: string = null;
    public userInfo: ChannelUser = this.appComp.channelUserInfo;

    public isDesktop: boolean;

    public scrollTopBlock: boolean;

    //copying file
    public isCopying = false;
    public copiedFile: any;

    //File Upload Start 
    public sasToken: TokenCredential;
    public blobServiceClient: BlobServiceClient;
    public blockBlobClient: BlockBlobClient;

    public uploadFile: File;
    public isFileUploading = false;
    public isDuplicateFile = false;
    //File Upload End 

    // 
    public currentDateTime: any = new Date();
    public formattedCurrentDateTime = '';
    public setCurrentDateTime: any;

    // new storage cap ... 
    public fileStorageUsage: FileStorageUsage;
    public showStorageBar: boolean = false;
    public hasUnlimitedStorage: boolean = false;
    public hasMaxedOutStorage: boolean = false;
    @ViewChild('storageBarEl') storageBarEl: ElementRef;

    //file and folder sharing
    public displayAddOwnerModal: boolean = false;
    public isSharing: boolean = false;
    public isUnSharing: boolean = false;
    public isAddingOwnerMember: boolean = false;
    public isRemovingOwnerMember: boolean = false;
    public fileUnread: boolean = true;
    public isCreatingChannel: boolean = false; //since a shared folder is a sort of channel

    public displayYourFilesComponent: boolean;
    public selectedFileFolderOwners: ChannelCard = {};
    public searchUsersList: any[] = [];
    public isSharedFolderMember: boolean = false;
    public restrictSharedFolderActions: boolean = false;

    public isSharedFolderCreator:boolean;
    public sharedFolderUserPool:any[] = [];

    public sharedFolderProjectExists: boolean; // newer
    public sharedFolderProject: Project; //newer

    private subscriptions: Subscription[] = [];

    private projects: Project[] = [];
    private sharedFoldersProject: any;
    private sharedFolders: ChannelFull[] = []; //shared folders are a special type of Channel
    private sharedFolderSelected: boolean = false;
    public enteredSharedFolder: boolean = false;
    private sharedFoldersDownloaded: boolean = false;
    private sharedFoldersDownloading: boolean = false;
    private selectedFileIdx: number = 0;

    // for holding the serch string 
    private searchSubject = new Subject<string>();

    constructor(
        private _fileStorageService: FileStorageService,
        private _toastService: ToastNotificationService,
        public _channelService: ChannelService,
        private _apiCallsService: ApiCallsService,
        private appComp: AppComponent,
        private formBuilder: FormBuilder,
        private router: Router,
        private _guest: GuestService,
        private _auth: AuthService,
        // used for change detector ref, handles the Expression __ has changed after it was checked error with screen check
        private cdr: ChangeDetectorRef, 
    ) {
        this.subscriptions.push(this._channelService.onSharedFileUploadCompletedEvent$.subscribe((fileUploadObj: any) => {
            if(fileUploadObj !== null) {
                this._callFileApi();
                this.getStorageUsage();
                // console.log(fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase());
                if(this.componentId === 'channel-primary' && fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    this._toastService.file(fileUploadObj.uploaderDisplayName + " has uploaded " + fileUploadObj.fileName);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onSharedFileDeleteCompletedEvent$.subscribe((fileDeleteObj: any) => {
            if(fileDeleteObj !== null) {
                this._callFileApi();
                this.getStorageUsage();
                if(this.componentId === 'channel-primary' && fileDeleteObj.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    this._toastService.file(fileDeleteObj.displayName + " has deleted " + fileDeleteObj.fileName);
                }
            }
        }));

        // ✨✨✨ TODO: NEED SIG-R EVENT FOR FILE RE-NAME ... 

        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "deleteFileConfirm") {
                this._deleteFileFromAll();
            }
            if (data.type === "deleteFolderConfirm") {
                this._deleteFileFromAll();
            }
            if (data.type === "deleteSharedFolderConfirm") {
                this._deleteSharedFolder();
            }

            //removes self from shared folder they don't own
            if (data.type === "removeSharedFolderConfirm") {

                this.removeOwner(
                    this.selectedFile.chatRoomChatUsers.chatUserId,
                    this.userInfo.displayName
                )

                //this._removeSharedFolder();
            }
        }));
    }

    ngOnInit() {
        this.isPreLoad = true;
        this.createForm();
        // interval is cleared onDestroy 
        this.setCurrentDateTime = setInterval(()=> {
            this.currentDateTime = new Date();
            this.formattedCurrentDateTime = formatDate(this.currentDateTime, 'EEEE, MMMM d, y  h:mm:ss a', 'en-US');
            // console.log("%c Current time", consoleLogStyle, this.formattedCurrentDateTime);
        }, 1000);
        this._getChannelUser();
        this.getStorageUsage();

        //creates the search subject for the search debounce
        //delays calling the search query until the user paused for the time allotted (400ms here)
        //goal is to prevent api calls from firing every single key stroke
        this.searchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
              console.log("Search Query Passed: "+ searchQuery); 
              this._getUsersBySearchQuery(this.selectedFile.chatRoomChatUsers.allChannelUsers);
        });



        // if(!this.sharedFoldersDownloading && this.currentFolderArray.length === 1 
        //     && (this.sharedFoldersList === undefined || this.sharedFoldersList.length === 0)) {
        //     alert("checking for shared folders on init");
        //     this.sharedFoldersDownloading = true;
        //     this.checkForSharedFolderProject();
        // }

        //this.sharedFoldersProject = this.projectExists(this.projects, "Shared Folders");

        // console.log("%c (0) is this file-storage portble? ", consoleLogStyle, this.isPortable)
        // console.log("%c (3) is this file-storage channel Id? ", consoleLogStyle, this.channelId)
        // console.log("%c (4) is this file-storage channel name? ", consoleLogStyle, this.channelName)
        // console.log("%c (5) is this file-storage channel container name? ", consoleLogStyle, this.channelContainerName)
        // console.log("%c (6) is this file-storage channel GUEST ? ", consoleLogStyle, this.isGuest)
        // console.log("%c (7) is this file-storage channel OWNER ? ", consoleLogStyle, this.isOwner)
        // console.log("%c (8) is this file-storage channel CREATOR ? ", consoleLogStyle, this.isCreator)
        // console.log("%c ((a)) this is onInit call! : ", consoleLogStyle, this.formattedCurrentDateTime)
    }

    ngAfterViewInit() {
        // console.log("%c ((b)) this is AfterViewInit call! : ", consoleLogStyle, this.formattedCurrentDateTime)
        // if (this.isPortable && this.channelId || this.isSidebar) {
        //     this.isDesktop = false;
        // } else if (!this.isPortable && this.channelId) {
        //     // this.isDesktop = true;
        //     this.handleScreenCheck();//might fix mobile
        // } else {
        //     this.handleScreenCheck();
        // }
        // this.cdr.detectChanges();
        // 
        this.componentWidthCheck();
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        //add an extra check for prevideo when doing mobile
        if (changes.isPortable) {
            // console.log("%c is this file-storage portble? ", consoleLogStyle, this.isPortable)
            // this._toastService.info(changes.isPortable.currentValue);
            // if (changes.isPortable.currentValue && this.channelId || this.isSidebar) {
            //     this.isDesktop = false;
            // } else if (!changes.isPortable.currentValue && this.channelId) {
            //     // this.isDesktop = true;
            //     this.handleScreenCheck();//might fix mobile
            // } else {
            //     this.handleScreenCheck();
            // }
            setTimeout(() => {
                this.componentWidthCheck();
            }, 750);
        }
        // 
        if(changes.showFiles) {
            if(this.showFiles) {
                this.componentWidthCheck();
            }
        }
    }

    // onDebouncedKeyup(event: any) {
    //     // Your logic here, e.g., call the _getUsersBySearchQuery method with the event value
    //     // this._getUsersBySearchQuery(event.target.value);
    //     alert("CLICK");
    //     this._getUsersBySearchQuery(this.selectedFile.chatRoomChatUsers.allChannelUsers);
    //   }

    componentWidthCheck() {
        let resizedEl: HTMLElement = <HTMLElement>document.getElementById(`resizeEl-${this.componentId}`);
        if(resizedEl) {
            let width = resizedEl.offsetWidth;
            if(width < 1024) {
                this.isDesktop = false;
                // console.info("%c file storage component width check : ", consoleLogStyle, width)
                // console.info("%c file storage component is desktop view? ", consoleLogStyle, this.isDesktop)
                if(this.fileStorageUsage) this.setStorageUsage();
            } else {
                this.isDesktop = true;
                // console.info("%c file storage component width check : ", consoleLogStyle, width)
                // console.info("%c file storage component is desktop view? ", consoleLogStyle, this.isDesktop)
                if(this.fileStorageUsage) this.setStorageUsage();
            }
        } else {
            // console.info("%c file storage component width check, NO resize el yet... ", consoleLogStyle)
        }
    }

    // handleScreenCheck() {
    //     // 🚧🚧 testing here getting screen check from service ... 
    //     // this makes it so in channel pre-join will show desktop table view if room ... 
    //     // this.isDesktop = this._channelService._isDesktop;
    //     // this.isDesktop = ScreenCheck(900); // 🚧 new tablet fix ... NEED TO CHECK IN CHANNEL TABLET!!!
    //     console.log("%c ✨ Window resized file-store, is desktop(900) ? = ", consoleLogStyle, this.isDesktop);
    // }

    ngOnDestroy() {
        clearInterval(this.setCurrentDateTime);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        // console.log("%c On destroy? ", consoleLogStyle)
    }

    triggerSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery);
    }

    _getChannelUser() {
        this._auth.getChannelUserInfo()
            .subscribe(data => {
                if(data.status === 200) {
                    console.log("getchanneluser file store", data);
                    this.userInfo = data.body;
                    if(this.userInfo.isGuest && this.componentId === 'file-home') this._guest.routeToChannelInvited();
                    this.userId = this.userInfo.channelUserId;
                    this.containerName = this.userInfo.channelUserId;
                    this.channelId ? this.showChannelFiles() : this.showYourFiles();
                } else {
                    this._toastService.error('There was an error getting user info.')
                }
            });
    }

    // 🚧 add/remove shared folder owner
    toggleAddOwnerModal() {
        this.displayAddOwnerModal = (this.displayAddOwnerModal) ? false : true;
        this.clearSearch();

        // refresh folder list when closing modal
        if (this.displayAddOwnerModal == false)
            this._resetFileList();
    }

    dtoggleAddOwnerModal() {
        this.displayAddOwnerModal = (this.displayAddOwnerModal) ? false : true;
        this.displayAddOwnerModal = (this.displayAddOwnerModal) ? false : true;
        this.clearSearch();
    }

    // 🚧 add/remove shared folder member
    toggleAddMemberModal() {
        this.displayAddOwnerModal = (this.displayAddOwnerModal) ? false : true;
        this.clearSearch();
    }

    // GET ALL USERS HERE 🎁 
    _getUsersBySearchQuery(currentList: any[]) { // CALLED (keyup) of searchbar input
        const userSearchBar = <HTMLInputElement>document.getElementById('user-search');
        let searchString = userSearchBar.value.replace(/\s/g, '');
        if (searchString.length < 1) {
            this.searchUsersList = [];
        } else {
            this._apiCallsService.getChannelUsersByTenantAndSearchQuery(this.userInfo.tenant, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        ///console.log("FULL USER LIST SEARCH!!: " +JSON.stringify(searchList));
                        let newList = [];
                        // Filter users from allChannelUsers that are in sharedFolderOwners or sharedFolderMembers
                        const filteredUsers = searchList.filter((user: any) => {
                            // Check if user is in sharedFolderOwners or sharedFolderMembers
                            const isInOwners = this.selectedFile.sharedFolderOwners.some((owner: any) => owner.channelUserId === user.channelUserId);
                            const isInMembers = this.selectedFile.sharedFolderMembers.some((member: any) => member.channelUserId === user.channelUserId);
                        
                            // Include the user in the filtered list if they are in sharedFolderOwners or sharedFolderMembers
                            return isInOwners || isInMembers;
                        });
                        
                        // Get the list of users in allChannelUsers that are not in filteredUsers
                        const unfilteredUsers = searchList.filter((user: any) => !filteredUsers.includes(user));
                        // console.log("unFILTERED USERS222: " + JSON.stringify(unfilteredUsers));
                        // console.log("FILTERED USERS222: " + JSON.stringify(filteredUsers));
                        //let compareList = currentList.concat(this.selectedFileFolderOwners);
                        // console.log("(search)✔ current list passed:", currentList, "this saved owners list:", this.selectedFaxFolderOwners)
                        // make sure not already: owner | shared | you, in search list 
                        
                        /*
                        searchList.forEach((searchUser, index) => {
                            if (this.userId !== searchUser.channelUserId) {
                               // if (!compareList.some(owner => searchUser.channelUserId === owner.chatUserKeyNavigation.channelUserId)) {
                                    newList.push(searchList[index]);
                               // }
                            }
                        });
                        */

                        unfilteredUsers.forEach((searchUser, index) => {
                            if (this.userId !== searchUser.channelUserId) {
                               // if (!compareList.some(owner => searchUser.channelUserId === owner.chatUserKeyNavigation.channelUserId)) {
                                    newList.push(unfilteredUsers[index]);
                               // }
                            }
                        });
                        this.searchUsersList = newList;
                        // console.log("search res:", this.searchUsersList)
                        // this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
                    } else {
                        console.error("error getting users by search", JSON.stringify(data));
                    }
                });
        }
    }


    // call oninit , upload & delete && channel sig-r subs ... 
    getStorageUsage() {
        // set this.fileStorageUsage here ... 
        this._fileStorageService.getStorageUsed()
            .subscribe(data => {
                if(data.status === 200) {
                    // console.log("user file usage", data);
                    this.fileStorageUsage = data.body;
                    this.setStorageUsage();
                } else {
                    this._toastService.error('There was an error getting user info.')
                }
            });
    }

    setStorageUsage() {
        if(this.channelId) {
            if(this.fileStorageUsage.organizationStorageSize < 0) {
                this.hasUnlimitedStorage = true;
            } else {
                this.showStorageBar = true;
                setTimeout(() => {
                    this.colorStorageBar(this.fileStorageUsage.organizationStorageUsage,this.fileStorageUsage.organizationStorageSize);
                });
            }
        } else {
            if(this.fileStorageUsage.personalStorageSize < 0) {
                this.hasUnlimitedStorage = true;
            } else {
                this.showStorageBar = true;
                setTimeout(() => {
                    this.colorStorageBar(this.fileStorageUsage.personalStorageUsage,this.fileStorageUsage.personalStorageSize);
                });
            }
        }
    }

    colorStorageBar(usedBytes: number, maxBytes: number) {
        // console.log("%c used | max ", consoleLogStyle, usedBytes, maxBytes)
        let storagePercent = (usedBytes / maxBytes) * 100;
        // let storagePercent = 26;
        // let storagePercent = 76;
        // let storagePercent = 91;
        // let storagePercent = 100;
        // console.info("%c color bar percent ", consoleLogStyle, storagePercent)
        if(storagePercent > 99) {
            storagePercent = 100;
            this.hasMaxedOutStorage = true;
            // this._toastService.warn("You have maxed out your file storage capacity.");
        }
        this.storageBarEl.nativeElement.style.width = `${storagePercent}%`;
        // move to css ... use ngClass ... 
        if(storagePercent > 99) {
            this.storageBarEl.nativeElement.style.backgroundColor = 'var(--new-status-red)';
        } else if(storagePercent > 90) {
            this.storageBarEl.nativeElement.style.backgroundColor = 'var(--new-status-orange)';
        } else if(storagePercent > 75) {
            this.storageBarEl.nativeElement.style.backgroundColor = 'var(--new-status-yellow)';
        } else {
            this.storageBarEl.nativeElement.style.backgroundColor = 'var(--caribbean-green)';
        }
    }

    storageMaxedOutWarn() {
        this._toastService.warn("You have maxed out your file storage capacity.");
    }

    // top tabs to show  Your files / Channel files 
    showYourFiles() {
        // console.log("showing YOUR files");
        this.displayYourFilesComponent = true;
        this.currentFolderArray = [{
            urlPath: '/',
            fileName: '/',
            containerName: this.userInfo.channelUserId,
            blodFileId: null,
            isFile: false
        }];
        this._callFileApi();
    }

    showChannelFiles() {
        // console.log("showing CHANNEL files");
        this.displayYourFilesComponent = false;
        this.currentFolderArray = [{
            urlPath: '/',
            fileName: '/',
            containerName: this.channelContainerName,
            blodFileId: null,
            isFile: false
        }];
        this._callFileApi();
    }

    // TODO: Need to fix refresh and loading for speed and better logic
    _resetFileList()
    {
      
        if(this.currentFolderArray.length === 1)
        { 
           this._fullRefreshList();
        }
        else
            this.filesList = this.fullFilesList;
    }

    _fullRefreshList()
    {
        this.sharedFoldersDownloaded = false; //delete?
        this.sharedFoldersList = [];
        this.sharedFoldersDownloading = false;
        this._refreshList();
    }
    _refreshList()
    {
        if(this.currentFolderArray.length === 1)
        { 
            this.filesList = this.sharedFoldersList;
            this.fullFilesList = this.filesList;
        }
        else
        {
            this.filesList = [];
            this.fullFilesList = [];
        }

        this._callFileApi();
    }

    // called onInit to get files & pagination & refresh 
    _callFileApi(searchTerm?: string) {
        //this.sharedFoldersList = [];
        this.isPreLoad = false;
        this.isFilesLoading = true;
        //this.selectedFile = null;
    	// change folder info based on if is channel 
        let folderInfo: any;
        if(this.sharedFolderSelected && this.enteredSharedFolder)
        {
            //alert("GETTING SHARED FOLDER FILES");
            // this.sharedFolderSelected = false;
            
            /*
            folderInfo = {
                "containerName": "C245A2DA-AD20-441F-9DFD-06C74C2DF04D",
                //"containerName": this.channelContainerName,
                //"parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "pageSize": 20,
                "orderBy": "isFile, fileName",
                "chatRoomId": "P8DRoK12L",
            }; //WORKING FOR this channel */
            
            //console.log("files list 137: " + this.filesList);
            //console.log("selectedfile index 137: " + this.selectedFileIdx);
            //console.log("this.selectedFile137: " + JSON.stringify(this.selectedFile));
            folderInfo = {
                //"containerName": this.filesList[this.selectedFileIdx].blobFileId,
                "containerName": this.selectedFile.blobFileId,
                //"parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "pageSize": 20,
                "orderBy": "isFile, fileName",
                //"chatRoomId": this.filesList[this.selectedFileIdx].chatRoomId,
                "chatRoomId": this.selectedFile.chatRoomId,
                //"chatRoomId": "P8DRoK12L",
            };

            this.selectedFileIdx = 0;
            
            //console.log("Shared FOLDER INFO: " +JSON.stringify(folderInfo));

            //{"containerName":"c245a2da-ad20-441f-9dfd-06c74c2df04d","pageSize":20,"orderBy":"isFile, fileName","chatRoomId":"P8DRoK12L"}
        }
        else if(this.displayYourFilesComponent) {
            // console.log("%c getting YOUR files ", consoleLogStyle)
            //alert("GETTING YOUR FILES");
            folderInfo = {
                "containerName": this.containerName,
                "parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "pageSize": 20,
                "orderBy": "isFile, fileName",
            };
        } 
        else {
            // if channel files 
            // console.log("%c getting CHANNEL files ", consoleLogStyle)
            //alert("GETTING CHANNEL FILES");
            folderInfo = {
                "containerName": this.channelContainerName,
                "parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "pageSize": 20,
                "orderBy": "isFile, fileName",
                "chatRoomId": this.channelId,
            };
            //console.log("CHANNEL FOLDER INFO: " +JSON.stringify(folderInfo));

            // if(this.sharedFolderSelected){
            //     folderInfo["parentId"] = null;
            // }
        }

        //this.selectedFile = null; ////CG
        
    	// if is searching 
        if (searchTerm) {
            folderInfo.fileName = searchTerm;
            // console.log("searching for:", searchTerm);
        };

        if(!this.displayYourFilesComponent){
            // this.filesList = [];
            this._fileStorageService.getFiles(folderInfo)
                .subscribe(data => {
                    if (data.status === 200) {
                        this.filesList = data.body;
                        this.fullFilesList = data.body;
                        this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));
                        this.isFilesLoading = false;
                        // console.log("%c THIS.filesList: ", consoleLogStyle, this.filesList);
                        // console.log("THIS.filesListPagination:", this.fileListPagination);
                        this.scrollTopBlock = false;
                    } else {
                        this.isFilesLoading = false;
                        this._toastService.error("There was an error in getting files.");
                        console.error("Error getting files ?", data);
                    }
                });
        }
        else{
            // this.filesList = [];
            this._fileStorageService.getFiles(folderInfo)
                .subscribe(data => {
                    if (data.status === 200) {
                        // this.filesList = data.body;
                        this.personalFilesList = data.body;
                        this.fullFilesList = data.body;
                        this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));
                        
                        //TODO: have this called when selecting a Shared Folders tab! ////CG
                        //shared folders only on the main/top folder
                        if(!this.sharedFoldersDownloading && this.currentFolderArray.length === 1 
                            && (this.sharedFoldersList === undefined || this.sharedFoldersList.length === 0)) {
                            //alert("checking for shared folders in files tab");
                            this.sharedFoldersDownloading = true;
                            //this.checkForSharedFolderProject(this.personalFilesList);
                                
                            //this.checkForSharedFolderProject2(this.personalFilesList);

                            this.checkForSharedFolderProject3(this.personalFilesList);
                        }
                        else if (this.currentFolderArray.length === 1)
                        {
                            this.isFilesLoading = false;
                            this.filesList = this.sharedFoldersList.concat(this.personalFilesList);
                        }
                        else{
                            this.isFilesLoading = false;
                            this.filesList = this.fullFilesList;
                         }
                        // console.log("%c THIS.filesList: ", consoleLogStyle, this.filesList);
                        // console.log("THIS.filesListPagination:", this.fileListPagination);
                        this.scrollTopBlock = false;
                    } else {
                        this.isFilesLoading = false;
                        this._toastService.error("There was an error in getting files.");
                        console.error("Error getting files ?", data);
                    }
                });
            this.sharedFoldersDownloaded = true;
           
        }
    }

    //for faster search
    _filterFiles(searchTerm?: string)
    {
       this.filesList = this._filterItems(searchTerm);
    }

    _filterItems(searchText: string): any[] {
        const filteredItems = this.fullFilesList.filter(item =>
          item.fileName.toLowerCase().includes(searchText.toLowerCase())
        );
        //console.log("filteredItems: "+ JSON.stringify(filteredItems));
        return filteredItems;
      }

    _getFilesPagination() {
        this.isGettingNextPage = true;
        let folderInfo: any;
        // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
        if(this.displayYourFilesComponent) {
            folderInfo = {
                "containerName": this.containerName,
                "parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "orderBy": "IsFile, FileName",
                "pageNumber": this.fileListPagination.currentPage + 1,
                "pageSize": 20
            };
        } else {
            folderInfo = {
                "containerName": this.channelContainerName,
                "parentId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "orderBy": "IsFile, FileName",
                "pageNumber": this.fileListPagination.currentPage + 1,
                "chatRoomId": this.channelId,
                "pageSize": 20
            };
        }
        this._fileStorageService.getFiles(folderInfo)
            .subscribe(data => {
                if (data.status === 200) {
                    let list: any = data.body;
                    this.filesList = this.filesList.concat(list);
                    this.fullFilesList = this.filesList;
                    // console.log("THIS.filesList:", this.filesList);
                    this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));
                    this.scrollTopBlock = false;
                    setTimeout(() => {
                        this.isGettingNextPage = false;
                        // console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
                    });
                } else {
                    console.error("Error in pagination ?", data);
                    this._toastService.error("There was an error in getting more files.");
                    this.isGettingNextPage = false;
                }
            });
    }
    // end get files & pagination 

    scrollCheck() {
        let scrollBox: any = document.querySelector(`#fileScrollbox-${this.componentId}`);
        let boxHeight = scrollBox.scrollHeight - scrollBox.offsetHeight;
        let boxTop = Math.ceil(scrollBox.scrollTop);
        
        if (!this.scrollTopBlock && boxTop >= boxHeight && this.fileListPagination.nextPageLink) {
            // console.log("scroll top: " + boxTop);
            // console.log("box height: " + boxHeight);
            this.scrollTopBlock = true;
            this._getFilesPagination();
        }
    }

    goBack() {

           
        this.currentFolderArray.pop();

        // makes the last folder/file the new selected file if not at the top of the file list
        if(this.currentFolderArray.length > 0)
            this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
        //alert("this.currentFolderArray AFTER POP: " + JSON.stringify(this.currentFolderArray));
        //alert("this.currentFolderArray: " + JSON.stringify(this.currentFolderArray[this.currentFolderArray.length-1]));
        //console.log("SEARCHING FOR HOPE: this.currentFolderArray: " + JSON.stringify(this.currentFolderArray[this.currentFolderArray.length-1]));
        //console.log("SEARCHING FOR MORE HOPE: " + JSON.stringify(this.currentFolderArray[this.currentFolderArray.length-1].rev));

        //if you go back to a parent Shared Folder 
        if(this.currentFolderArray[this.currentFolderArray.length-1].rev != null ){
            //alert("I AM HERE!!!!");
            this.enteredSharedFolder = true;
            this.sharedFolderSelected = true;
        }
        //if the folder isn't a shared folder, reset the flags
        else{
            this.enteredSharedFolder = false;
            this.sharedFolderSelected = false;
            this.restrictSharedFolderActions = false;
        }
        

        //When you go back to the home/main file path, need to 
        // if(this.currentFolderArray.urlPath = "/"){
        //     //this.enteredSharedFolder = false;
        //     //this.sharedFolderSelected = false;
        //     this.selectedFile = null;
        // }

        //alert("WHERE YOU ARE" + JSON.stringify(this.currentFolderArray));

        this._refreshList(); //use instead?? ////CG
        //this._fullRefreshList();

        //this._callFileApi();
        this.selectedFile = null;
         

        // console.log("back-clicked, this.currentFolderArray = ", this.currentFolderArray);
    }

    // search start 
    showSearchFilesInput() {
        // console.log("search bar open ?", this.displaySearchFilesInput);
        if(this.displaySearchFilesInput && !this.isSearching) {
            this.displaySearchFilesInput = false;
            if (this.isSearching && this.filesList.length === 0) {
                this.isSearching = false;
                this._callFileApi();
            }
        } else {
            this.displaySearchFilesInput = true;
            this.selectedFile = null;
        }
        // console.log("search bar open ?", this.displaySearchFilesInput);
    }

    searchFiles(e: any) {
        let expression = /^\S*$/;
        let regex = new RegExp(expression);
        if (e.target.value.match(regex)) {
            if (e.target.value && e.target.value !== "") {
                //this._callFileApi(e.target.value);
                this._filterFiles(e.target.value);
                this.isSearching = true;
                // console.log("active file search for: orig target ?", e.originalTarget.value);
                // console.log("active file search for: TARGET ?", e.target.value); - same as above 
            } else {
                this._callFileApi();
                this.isSearching = false;
            }
        }
    }

    clearSearch() {
        //this.sharedFoldersList = [];
        const searchInput: any = document.getElementById(`searchInput-${this.componentId}`);
        // const searchClose: any = document.getElementById('searchClose');
        if (this.isSearching || searchInput) {
            searchInput.value = "";
            this.isSearching = false;
            //this._callFileApi();
            this.filesList = this.fullFilesList;
        }
    }

    clearUserSearch() {
        //this.sharedFoldersList = [];
        const searchInput: any = document.getElementById('user-search');
        if (this.isSearching || searchInput) {
            searchInput.value = "";
            this.isSearching = false;
            this.searchUsersList = [];
            //this.filesList = this.fullFilesList;
        }
    }

    searchCheckLength() {
        const userSearchBar = <HTMLInputElement>document.getElementById('user-search');
        if (userSearchBar) {
            if (userSearchBar.value.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    // search end 

    // // TODO implement sorting 
    // sortFiles(e: any) {
    //     console.log("sort files . . .", e);
    //     // this.filesList = this.filesList.reverse();
    // }

    // select file & info 
    selectFile(i: number) {
        //console.log("%c file selected ", this.filesList[i])
        this.selectedFileIdx = i;
        // console.log("%c item already selected! ", consoleLogStyle); <- if IS selected line 345 returns;
        if(!this.isDownloading && !this.isRenameSubmitting && !this.isDeleting) {
            if (this.selectedFile && this.selectedFile === this.filesList[i]) return;
            // to close folder form on select 
            if (this.displayCreateFolderForm) this.displayCreateFolderForm = false;
            if (this.displayRenameFileForm) this.displayRenameFileForm = false;
            // to close mobile infos on select 
            const allOtherInfos = Array.from(document.getElementsByClassName('file-info-display') as HTMLCollectionOf<HTMLElement>);
            // console.log("%c All other infos ", consoleLogStyle, allOtherInfos);
            allOtherInfos.forEach(info => info.style.display = 'none');

            if(this.filesList[i].rev) { 
                this.sharedFolderSelected = true;
            }
            else
                this.sharedFolderSelected = false;

            this.isSharedFolderCreator = this.sharedFolderSelected && (this.filesList[i].chatUserId == this.userInfo.channelUserId) ;

            this.selectedFile = this.filesList[i];
            this.isSharedFolderMember = false;
            //see if current user is a member only of the shared folder, if a shared folder is selected:
            //TODO: indicate Shared Folder selected here?
            if(this.selectedFile.rev){
                this.isSharedFolderMember = this.checkIfOnlyASharedFolderMember(this.userInfo, this.selectedFile.sharedFolderMembers);
                console.log("IS MEMBER ONLY: " + this.isSharedFolderMember);
            }

            //this.buildFilteredUserList(this.selectedFile);
            //console.log("FULL FOLDER DATA: " + JSON.stringify(this.selectedFile));
            //console.log("%c Item selected: ", consoleLogStyle, this.selectedFile, i);
            //console.log("this.currentFolderArray: " + JSON.stringify(this.currentFolderArray));
        } else {
            return;
        }
    }

    // double click item - 
    openFolder(isFile: boolean, ownersList: ChannelCard) {
        //console.log("OPENED FOLDER!: " + JSON.stringify(this.selectFile));
        this.restrictSharedFolderActions = false;
        //TODO: fix logic here, a bit messy
        if(!this.isDownloading && !this.isRenameSubmitting && !this.isDeleting) {
            if (isFile) {
                this.downloadFile();
                // console.log("file downloaded");
            } else {
                this.currentFolderArray.push(this.selectedFile);
                this.selectedFileFolderOwners = ownersList;
                if(this.sharedFolderSelected)
                {
                    this.enteredSharedFolder = true;
                    if(this.isSharedFolderMember)
                    {
                        this.restrictSharedFolderActions = true;
                    }
                    else
                    this.restrictSharedFolderActions = false;
                }
                else 
                    this.enteredSharedFolder = false;
                this._refreshList();
                //this._callFileApi();
                this.selectedFile = null;
            }
        } else {
            return;
        }
        // console.log("");
    }

    // take this event bubbling...
    preventFileDblClick(e: Event) {
        e.stopPropagation();
    }

    addOwner(userId: string, username: string) {
        this.clearSearch();
        //console.log("adding owner:", userId, username)
        //console.log("to folder/file: ", this.selectedFile)
        this.isAddingOwnerMember = true; // TODO: add loading spinner to modal <---<<<|)
        let owner = {
            "channelUserId": userId,
            //"channelId": this.selectedFile.chatRoomId
            "channelId": this.selectedFile.rev
        };
        this._apiCallsService.postChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Owner Added");
                    this.isAddingOwnerMember = false;
                    this._toastService.success(`${username} added as an owner.`);
                    //this._getChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    //_getChannelFolderData();
                    // this._resetChannelFolderData(this.selectedFile.rev);
                    //this._resetChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    //this._resetChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    this._resetChannelFolderData(this.selectedFile);
                    //this._fullRefreshList();
                    //Slow
                    // this.getFaxAccounts(); ////CG
                } else if (res.status === 409) {
                    // console.log("Owner Already Exists");
                    this.isAddingOwnerMember = false;
                    this._toastService.error(`${username} is already an owner.`);
                } else {
                    // console.log("error adding owner to fax:", res);
                    this.isAddingOwnerMember = false;
                    this._toastService.error(`There was an error adding ${username} as an owner.`);
                }
            });
    }

    resetSelectedFile() {
        this.selectedFile = null;
        this.selectedFileIdx = null;
    }

    removeOwner(userId: string, username: string) {
        this.clearSearch();
        // console.log("removing owner:", userId, username)
        this.isRemovingOwnerMember = true; // TODO: add loading spinner to modal <---<<<|)
        let owner = {
            "channelUserId": userId,
            "channelId": this.selectedFile.chatRoomId
        };
        this._apiCallsService.deleteChannelOwner(owner)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Owner Removed");
                    this.isRemovingOwnerMember = false;
                    this._toastService.success(`${username} has been removed as owner.`);
                    
                    //resets folder data?
                    //this._getChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    //this._resetChannelFolderData(this.selectedFile.rev);
                    //this._resetChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    this._resetChannelFolderData(this.selectedFile);
                    //this._fullRefreshList(); //SLOW
                    //this.dtoggleAddOwnerModal();
                    //let priorSelectedFileIndex = this.selectedFileIdx;
                    //this.resetSelectedFile();
                    //this._getChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[priorSelectedFileIndex]);
                    //console.log("NEW selected File: " + JSON.stringify(this.selectedFile));
                    //this.dtoggleAddOwnerModal();
                    //this.getFaxAccounts(); ////CG
                } else if (res.status === 404) {
                    // console.log("Owner does not exist");
                    this.isRemovingOwnerMember = false;
                    this._toastService.error(`${username} has already been removed has an owner.`);
                } else {
                    // console.log("error removing owner:", res);
                    this.isRemovingOwnerMember = false;
                    this._toastService.error(`There was an error removing ${username} as an owner.`);
                }
            });
    }
    // END add/remove Shared folder owner

    addMember(userId: string, username: string) {
        this.clearSearch();
        //console.log("adding owner:", userId, username)
        //console.log("to folder/file: ", this.selectedFile)
        this.isAddingOwnerMember = true; // TODO: add loading spinner to modal <---<<<|)
        let member = {
            "channelUserId": userId,
            //"channelId": this.selectedFile.chatRoomId
            "channelId": this.selectedFile.rev
        };
        this._apiCallsService.postChannelMember(member)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("Member Added");
                    //this.isAddingOwnerMember = false;
                    this._toastService.success(`${username} added as member.`);
                    //this._getChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    //this._resetChannelFolderData(this.selectedFile.rev);
                    //this._resetChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    this.channelUpdated.emit();
                    this._channelService.updateChannelUsersInRoomEvent(member.channelId);
                    this._resetChannelFolderData(this.selectedFile);
                    //this._fullRefreshList(); //SLOW
                    //this.dtoggleAddOwnerModal();
                    // this.getFaxAccounts(); ////CG
                } else if (res.status === 409) {
                    // console.log("Owner Already Exists");
                    //this.isAddingOwnerMember = false;
                    this._toastService.error(`${username} is already a member.`);
                } else {
                    // console.log("error adding owner to fax:", res);
                    //this.isAddingOwnerMember = false;
                    this._toastService.error(`There was an error adding ${username} as a member.`);
                }
                this.isAddingOwnerMember = true
            });
    }

    removeMember(userId: string, username: string) {
        this.clearSearch();
        console.log("removing member:", userId, username)
        this.isRemovingOwnerMember = true; // TODO: add loading spinner to modal <---<<<|)
        let member = {
            "channelUserId": userId,
            "channelId": this.selectedFile.chatRoomId
        };
        console.log("removing member:", JSON.stringify(member));
        this._apiCallsService.deleteChannelMember(member)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("Owner Removed");
                    this.isRemovingOwnerMember = false;
                    this._toastService.success(`${username} has been removed as a member.`);
                    //this._getChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    //this._resetChannelFolderData(this.selectedFile.rev);
                    //this._resetChannelFolderData(this.projects[this.sharedFoldersProject.projectIdx].channels[this.selectedFileIdx]);
                    this._resetChannelFolderData(this.selectedFile);
                    //this._fullRefreshList(); //Slow
                    //this.getFaxAccounts(); ////CG
                } else if (res.status === 404) {
                    // console.log("Owner does not exist");
                    this.isRemovingOwnerMember = false;
                    this._toastService.error(`${username} has already been removed has a member.`);
                } else {
                    // console.log("error removing owner:", res);
                    this.isRemovingOwnerMember = false;
                    this._toastService.error(`There was an error removing ${username} as a member.`);
                }
            });
    }
    // END add/remove Shared folder owner

    // mobile only display file info -  i = File index & also id of info element 
    showFileInfo(i: number ,e: Event) {
        e.stopPropagation();
        // console.log("show info", i, e);
        // to close other infos 
        const allOtherInfos = Array.from(document.getElementsByClassName('file-info-display') as HTMLCollectionOf<HTMLElement>);
        // console.log("%c All other infos ", consoleLogStyle, allOtherInfos);
        allOtherInfos.forEach(info => info.style.display = 'none');
        // open this 'i' info w/ transition 
        const info = document.getElementById(`file-info-${i}-${this.componentId}`);
        info.style.display = "flex";
        info.animate([
            { opacity: 0, transform: 'scale(0)' },
            { opacity: 1, transform: 'scale(1)' }
        ], {
            duration: 172,
            easing: 'ease-out'
        });
        // console.log("file-item", i);
    }

    // to close info when clicked itself | mouseleave 🚧🚧🚧 ... 
    closeFileInfo(i: number, e: Event) {
        e.stopPropagation();
        // console.log("mouse down", i, e);
        
        const info = document.getElementById(`file-info-${i}-${this.componentId}`);
        info.style.display = "none";
    }
    // select file & info end

    // possible v2.1 feat.
    // shareFile(e) {
    //     console.log("share file:", e);
    // }

    // copyFile(e?: Event) {
    //         if(e)
    //             console.log("copy file:", e);
    //         else
    //             console.log("copy file, no event attached");
    //     }

    // copy file start 
    copyFile(e?: Event) {
        if (e) e.stopPropagation();
        this.isDownloading = true;
        let fileInfo: any;
        if(this.displayYourFilesComponent && !this.enteredSharedFolder) {
            fileInfo = {
                "path": this.selectedFile.urlPath,
                //"fromPath": "pause-button.png",
                "fromPath": this.selectedFile.urlPath,
                //"toPath": this.currentFolderPath() + this.selectedFile.fileName + "." + this.selectedFile.fileName.substring(this.selectedFile.fileName.lastIndexOf(".") + 1 , this.selectedFile.fileName.length),
                "toPath": this.selectedFile.urlPath,
                //toPath": "/copiedFile.png",
                //"newFileName": this.renameFileForm.value.fName + "." + this.selectedFile.fileName.substring(this.selectedFile.fileName.lastIndexOf(".") + 1 , this.selectedFile.fileName.length),
                "newFileName": "copiedFile2.png",
                "autoRename": true,
                "blobFileId": this.selectedFile.blobFileId
            }
        } else {

            if(this.enteredSharedFolder && this.channelId == null){
                this.channelId = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
            }

            fileInfo = {
                "path": this.selectedFile.urlPath,
                "blobFileId": this.selectedFile.blobFileId,
                "channelId": this.channelId
            }
        }
        this._fileStorageService.fileCopy(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    this.copiedFile = res.body;
                    //console.log("copy file data:", this.copiedFile);
                } else {
                    console.log("Error in copy ?", res);
                    this._toastService.error(`There was an error in copying ${this.selectedFile.fileName}.`);
                    this.isDownloading = false;
                }
            });
        //console.log("copy file clicked:", fileInfo);
    }

    // download file start 
    downloadFile(e?: Event) {
        if (e) e.stopPropagation();
        this.isDownloading = true;
        let fileInfo: any;

        //shared folders are channel stroages thus the extra check
        if(this.displayYourFilesComponent && !this.enteredSharedFolder) {
            fileInfo = {
                "path": this.selectedFile.urlPath,
                "blobFileId": this.selectedFile.blobFileId
            }
        } else {

            //Shared folder channel Ids are found in their parent folder object
            if(this.enteredSharedFolder && this.channelId == null){
                this.channelId = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
            }

            fileInfo = {
                "path": this.selectedFile.urlPath,
                "blobFileId": this.selectedFile.blobFileId,
                "channelId": this.channelId
            }
        }
        this._fileStorageService.fileDownload(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    this.createDownloadElement(res.body);
                } else {
                    // console.log("Error in download ?", res);
                    this._toastService.error(`There was an error in downloading ${this.selectedFile.fileName}.`);
                    this.isDownloading = false;
                }
            });
        // console.log("download file clicked:", fileInfo);
    }
    createDownloadElement(link: any) {
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', link);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.isDownloading = false;
    }
    // download file end 

    // delete confirm 
    deleteConfirm(e?: Event) {
        if (e) {
            e.preventDefault();
        }
        // console.log("is file?", isFile)
        if(this.selectedFile.isFile) {
            this._toastService.continue("Are you sure you want to delete this file?", "deleteFileConfirm");
        } else {
            // Shared Folders have a .rev in their data, otherwise rev is null
            if(this.selectedFile.rev){

                if(this.isSharedFolderCreator)
                    this._toastService.continue("Are you sure you want to delete this Shared Folder and all contents? Data will be lost to all it is shared with.", "deleteSharedFolderConfirm");
                else
                    this._toastService.continue("Are you sure you want to remove this Shared Folder from your list?", "removeSharedFolderConfirm");
            }
            //regular folder
            else{
                this._toastService.continue("Are you sure you want to delete this folder and all contents?", "deleteFolderConfirm");
            }
        }
    }
    // delete file start 
    _deleteFileFromAll(e?: Event) {
        if (e) e.stopPropagation();
        if (this.selectedFile === null) {
            // console.log("no file selected");
        } else {
            this.isDeleting = true;
            let fileInfo: any;
            if(this.displayYourFilesComponent && !this.enteredSharedFolder) {
                fileInfo = {
                    "path": this.selectedFile.urlPath,
                    "blobFileId": this.selectedFile.blobFileId
                }
            } else {

                if(this.enteredSharedFolder && this.channelId == null){
                    this.channelId = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
                }

                fileInfo = {
                    "path": this.selectedFile.urlPath,
                    "blobFileId": this.selectedFile.blobFileId,
                    "channelId": this.channelId
                }
            }
            this._fileStorageService.fileDelete(fileInfo)
                .subscribe(res => {
                    if (res.status === 200) {
                        if (!this.displayYourFilesComponent) {
                            this._channelService.sharedFileDeleteComplete(this.channelId, this.selectedFile.fileName);
                        }

                        //makes sure we reload into the current shared folder after renaming
                        if(this.enteredSharedFolder){
                            this.sharedFolderSelected = true;
                            if(this.currentFolderArray.length > 0)
                                this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
                        }

                        this._callFileApi();
                        this.getStorageUsage();
                        this.isDeleting = false;
                        this.selectedFile = null;
                    } else {
                        // console.log("Error in delete ?", res);
                        this._toastService.error(`There was an error in deleting ${this.selectedFile.fileName}.`);
                        this.isDeleting = false;
                    }
                });
        }
    }

    // v2.1 feat. 
    // moveFile(e: any) {
    //     console.log("move file:", e);
    // }

    // rename start 
    autoFocusRenameInput() {
        setTimeout(() => {
            const targetInput: any = document.querySelector(`#renameInput-${this.componentId}`);
            if (targetInput) {
                targetInput.focus();
            }
        });
    }

    showRenameFileInput(e?: Event) {
        if (e) e.stopPropagation();
        // this.displayRenameFileForm = (this.displayRenameFileForm) ? false : true;
        if(this.displayRenameFileForm) {
            this.displayRenameFileForm = false;
            this.renameFileForm.reset();
        } else {
            this.displayRenameFileForm = true;
            if(this.selectedFile.isFile) {
                let fileNameNoExt = this.selectedFile.fileName.substring(0, this.selectedFile.fileName.lastIndexOf('.'));
                // console.log("%c file name no .ext",consoleLogStyle,fileNameNoExt)
                this.renameFileForm.setValue({
                    fName: fileNameNoExt
                });
            } else {
                this.renameFileForm.setValue({
                    fName: this.selectedFile.fileName.slice(0, -1),
                });
            }
            this.autoFocusRenameInput();
        }
    }

    renameFolder() {

        //divert to shared folder renaming if the shared folder; designated by the .rev data
        //console.log("FOR LOCKING OUT NON OWNERS: " + JSON.stringify(this.selectedFile));
        if(this.selectedFile.rev != null)
        {
            this._renameSharedFolder();
            return;
        }

        this.isRenameSubmitting = true;
        let folderInfo: any;
        if(this.displayYourFilesComponent) {
            folderInfo = {
                "fromPath": this.selectedFile.urlPath,
                "toPath": this.currentFolderPath() + this.renameFileForm.value.fName.trim() + "/",
                "newFolderName": this.renameFileForm.value.fName.trim() + "/",
                "autoRename": false,
                "blobFileId": this.selectedFile.blobFileId,
            };
        } else {
            folderInfo = {
                "fromPath": this.selectedFile.urlPath,
                "toPath": this.currentFolderPath() + this.renameFileForm.value.fName.trim() + "/",
                "newFolderName": this.renameFileForm.value.fName.trim() + "/",
                "autoRename": false,
                "blobFileId": this.selectedFile.blobFileId,
                "channelId": this.channelId,
            };
        }
        if (this.renameFileForm.value.fName !== "" && this.renameFileForm.value.fName.trim() !== null && this.renameFileForm.value.fName.trim() !== this.selectedFile.fileName.trim().slice(0, -1)) {
            this._fileStorageService.renameFolder(folderInfo)
                .subscribe(res => {
                    if (res.status === 200) {
                        // console.log("resolved rename folder?:", res)
                        this._callFileApi();
                        this.selectedFile = null;
                        this.isRenameSubmitting = false;
                        this.renameFileForm.reset();
                        this.displayRenameFileForm = false;
                    } else if (res.status === 400) {
                        // console.log("%c Name Error ", consoleLogStyle);
                        this._toastService.error("Folder name must contain letters and numbers.");
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else if (res.status === 409) {
                        // console.log("%c Duplicate Error: ", consoleLogStyle);
                        this._toastService.error(`${folderInfo.newFolderName.trim().slice(0, -1)} already exists.`);
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else {
                        // console.log("Something went wrong with rename folder", res.status.toString());
                        this.isRenameSubmitting = false;
                        this.displayRenameFileForm = false;
                        this._toastService.error("Something went wrong with rename folder.");
                    }
                });
        } else {
            this._toastService.error("Must edit folder name to submit.");
            this.isRenameSubmitting = false;
            this.autoFocusRenameInput();
        }
    }

    renameFile() {
        this.isRenameSubmitting = true;
        let fileInfo: any;
        if(this.displayYourFilesComponent && !this.enteredSharedFolder) {
            //alert("SENDING REGULAR FILE INFO IN RENAME");
            fileInfo = {
                "fromPath": this.selectedFile.urlPath,
                "toPath": this.currentFolderPath() + this.renameFileForm.value.fName.trim() + "." + this.selectedFile.fileName.trim().substring(this.selectedFile.fileName.trim().lastIndexOf(".") + 1 , this.selectedFile.fileName.trim().length),
                "newFileName": this.renameFileForm.value.fName.trim() + "." + this.selectedFile.fileName.trim().substring(this.selectedFile.fileName.lastIndexOf(".") + 1 , this.selectedFile.fileName.trim().length),
                "autoRename": false,
                "blobFileId": this.selectedFile.blobFileId
            };
        } else {
           // alert("RENAMING CHANNEL FILES?");
            //alert("this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId:" + this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId);
            
            let toPathString = this.currentFolderPath() + this.renameFileForm.value.fName.trim() + "." + this.selectedFile.fileName.trim().substring(this.selectedFile.fileName.trim().lastIndexOf(".") + 1 , this.selectedFile.fileName.trim().length);
            //Shared folder channel Ids are found in their parent folder object
            if(this.enteredSharedFolder){
                //alert("RENAMING SHARED FILES");
                this.channelId = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
                //NEED TO TEST NESTED FOLDER SITUATIONS! ////CG
                toPathString = "/" + this.renameFileForm.value.fName.trim() + "." + this.selectedFile.fileName.trim().substring(this.selectedFile.fileName.trim().lastIndexOf(".") + 1 , this.selectedFile.fileName.trim().length);
            }
            
                fileInfo = {
                "fromPath": this.selectedFile.urlPath,
                "toPath": toPathString,
                "newFileName": this.renameFileForm.value.fName.trim() + "." + this.selectedFile.fileName.trim().substring(this.selectedFile.fileName.trim().lastIndexOf(".") + 1 , this.selectedFile.fileName.trim().length),
                "autoRename": false,
                "blobFileId": this.selectedFile.blobFileId,
                "channelId": this.channelId,
            };
        }

        //console.log("RENAME DEBUGGING:");
        //console.log("Rename FileInfo Passed: " + JSON.stringify(fileInfo));
        //console.log("Rename this.selectedfile: " + JSON.stringify(this.selectedFile));

        if (this.renameFileForm.value.fName.trim() !== "" && this.renameFileForm.value.fName.trim() !== null && this.renameFileForm.value.fName.trim() !== this.selectedFile.fileName.trim().substring(0, this.selectedFile.fileName.trim().lastIndexOf("."))) {
            this._fileStorageService.renameFile(fileInfo)
                .subscribe(res => {
                    if (res.status === 200) {
                        // console.log("resolved rename folder?:", res)

                        //makes sure we reload into the current shared folder after renaming
                        if(this.enteredSharedFolder){
                            this.sharedFolderSelected = true;
                            if(this.currentFolderArray.length > 0)
                                this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
                        }
                            
                        this._callFileApi();
                        //this.selectedFile = null;
                        this.isRenameSubmitting = false;
                        this.renameFileForm.reset();
                        this.displayRenameFileForm = false;
                        //this._fullRefreshList();
                    } else if (res.status === 400) {
                        // console.log("%c Name Error ", consoleLogStyle);
                        this._toastService.error("File name must contain letters and numbers.");
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else if (res.status === 409) {
                        // console.log("%c Duplicate Error: ", consoleLogStyle);
                        this._toastService.error(`${fileInfo.newFileName.slice(0, -1)} already exists.`);
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else {
                        // console.log("something went wrong with rename", res.status.toString());
                        this._toastService.error("Something went wrong with rename.");
                        this.isRenameSubmitting = false;
                        this.displayRenameFileForm = false;
                    }
                });
        } else {
            // console.log("%c Must edit name to submit! ", consoleLogStyle)
            this._toastService.error("Must edit file name to submit.");
            this.isRenameSubmitting = false;
            this.autoFocusRenameInput();
        }
    }
    // rename end 

    // create a folder start 
    autoFocusCreateFolderInput() {
        setTimeout(() => {
            const targetInput: any = document.querySelector(`#createFolderInput-${this.componentId}`);
            if (targetInput) {
                targetInput.focus();
            }
        });
    }

    showCreateFolderForm() {
        this.displayCreateFolderForm = (this.displayCreateFolderForm) ? false : true;
        this.selectedFile = null;
        this.newFolder.reset();
        this.autoFocusCreateFolderInput();
        // console.log("%c Show create folder form? ", consoleLogStyle, this.displayCreateFolderForm);
    }

    createForm() {
        this.newFolder = this.formBuilder.group({
            fName: ['', [Validators.required]]
        });
        this.renameFileForm = this.formBuilder.group({
            fName: ['', [Validators.required]]
        });
    }

    // get newFolderControls() { return this.newFolder.controls; }

    // dont use this in template ... no unbound funcs in template ... 
    currentFolderPath() {
        // console.log("%c is this being over called curr folder path? ... ", consoleLogStyle)
        return this.currentFolderArray[this.currentFolderArray.length - 1].urlPath;
    }

    // fix here add if displayYourFiles 
    _createFolder() {
        this.isFolderSubmitting = true;
        let folderInfo: any;

        //console.log("%c Folder is submitting? ", consoleLogStyle, this.isFolderSubmitting);
        if(this.displayYourFilesComponent) {
            folderInfo = {
                "containerName": this.containerName,
                "path": this.currentFolderPath() + this.newFolder.value.fName.trim() + "/",
                "folderName": this.newFolder.value.fName.trim() + "/",
                "autoRename": false,
                "parentBlobFileId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId
            };
        } else {
            folderInfo = {
                "containerName": this.channelContainerName,
                "path": this.currentFolderPath() + this.newFolder.value.fName.trim() + "/",
                "folderName": this.newFolder.value.fName.trim() + "/",
                "autoRename": false,
                "parentBlobFileId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "channelId": this.channelId,
            };
        }
        
        this._fileStorageService.createFolder(folderInfo)
            .subscribe(res => {
                // console.log("%c Folder status ", consoleLogStyle, res);
                if (res.status === 200) {
                    this._callFileApi();
                    this.isFolderSubmitting = false;
                    this.newFolder.reset();
                    //console.log("%c New Folder Data ", consoleLogStyle, this.newFolder);
                    this.displayCreateFolderForm = false;
                } else if (res.status === 400) {
                    // console.log("%c Folder name Error ", consoleLogStyle, res);
                    this._toastService.error("Folder name must contain letters and numbers.");
                    this.isFolderSubmitting = false;
                    this.newFolder.reset();
                    this.displayCreateFolderForm = true;
                    this.autoFocusCreateFolderInput();
                    // console.log("%c Show create folder form? ", consoleLogStyle, this.displayCreateFolderForm);
                } else if (res.status === 409) {
                    // console.log("%c Duplicate folder Error: ", consoleLogStyle, res);
                    this._toastService.error(`${folderInfo.folderName.slice(0, -1)} already exists.`);
                    this.isFolderSubmitting = false;
                    this.newFolder.reset();
                    this.displayCreateFolderForm = true;
                    this.autoFocusCreateFolderInput();
                    // console.log("%c Show create folder form? ", consoleLogStyle, this.displayCreateFolderForm);
                } else {
                    // console.log("something went wrong with CREATE_FOLDER", res.status.toString());
                    this.isFolderSubmitting = false;
                    this.displayCreateFolderForm = false;
                    this._toastService.error("Something went wrong with create folder.");
                    }
                });
                
    }

    _createSharedFolder() {
        this.isFolderSubmitting = true;
        
        //console.log("this.sharedFoldersProject.projectFound: " + this.sharedFoldersProject);
        if(this.sharedFoldersProject && this.sharedFoldersProject.projectFound != null) {
            this.createSharedFolderChannel(this.sharedFoldersProject.projectFound.teamProjectId,
                this.sharedFoldersProject.projectFound,this.newFolder.value.fName.trim());              
        }
        else{
            this.createSharedFolderProject(this.newFolder.value.fName.trim());
        }
    }

    _createSharedFolder2() {
        this.isFolderSubmitting = true;
        
        if(this.sharedFolderProject && this.sharedFolderProjectExists) {
            this.createSharedFolderChannel(this.sharedFolderProject.teamProjectId,
                this.sharedFolderProject,this.newFolder.value.fName.trim());              
        }
        else{
            this.createSharedFolderProject(this.newFolder.value.fName.trim());
        }
    }

    _renameSharedFolder() {
        this.isRenameSubmitting = true;
        if (this.renameFileForm.value.fName !== "" && this.renameFileForm.value.fName.trim() !== null && this.renameFileForm.value.fName.trim() !== this.selectedFile.fileName.trim().slice(0, -1)) {

            let patch = {
                "op": "replace",
                "path": "/Name",
                "value": this.renameFileForm.value.fName.trim()
            };
            this._fileStorageService.renameSharedFolder(this.selectedFile.chatRoomId,patch)
                .subscribe(res => {
                    if (res.status === 204) {
                        //console.log("resolved rename shared folder?:", res)
                        //this.sharedFoldersList = []; //reset shared folder list
                        if(this.selectedFile == null && this.currentFolderArray.length > 0)
                            this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
                        //this._callFileApi();
                        //this.selectedFile = null;
                        this.sharedFolderSelected = false;
                        this.isRenameSubmitting = false;
                        this.renameFileForm.reset();
                        this.displayRenameFileForm = false;
                        this._fullRefreshList();
                    } else if (res.status === 400) {
                        // console.log("%c Name Error ", consoleLogStyle);
                        this._toastService.error("Folder name must contain letters and numbers.");
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else if (res.status === 409) {
                        // console.log("%c Duplicate Error: ", consoleLogStyle);
                        this._toastService.error(`${this.renameFileForm.value.fName.trim()} already exists.`);
                        this.isRenameSubmitting = false;
                        this.autoFocusRenameInput();
                    } else {
                        console.log("Something went wrong with rename shared folder", res.status.toString());
                        this.isRenameSubmitting = false;
                        this.displayRenameFileForm = false;
                        this._toastService.error("Something went wrong with rename folder.");
                    }
                });
        } else {
            this._toastService.error("Must edit folder name to submit.");
            this.isRenameSubmitting = false;
            this.autoFocusRenameInput();
        }
    }
    
    /*
    _renameSharedFolders(e?: Event){
        if (e) e.stopPropagation();
        if (this.selectedFile === null) {
            // console.log("no file selected");
        } 
        else{
            let patch = {
                "Name": this.selectedFile.fileName
            };
            this._fileStorageService.renameSharedFolder(this.selectedFile.chatRoomId, patch)
                .subscribe(res => {
                    if (res.status === 204) {
                        //alert("shared Folder Renamed")
                        //this.projects[projectIdx].channels[channelIdx].isFavorite = true;
                    } else {
                        this._toastService.error("There was an error renaming this shared folder.");
                    }
                });
        }
    }
    */

    //Delete Shared Folder
    _deleteSharedFolder(e?: Event) {
        if (e) e.stopPropagation();
        if (this.selectedFile === null) {
            // console.log("no file selected");
        } else {
            this.isDeleting = true;
            let fileInfo: any;
            if(this.displayYourFilesComponent) {
                fileInfo = {
                    "path": this.selectedFile.urlPath,
                    "blobFileId": this.selectedFile.blobFileId,
                    "channelId": this.selectedFile.chatRoomId,
                    "other": "test data"
                }
            } else {
                fileInfo = {
                    "path": this.selectedFile.urlPath,
                    "blobFileId": this.selectedFile.blobFileId,
                    "channelId": this.selectedFile.chatRoomId,
                }
            }

            //alert("FILE INFO IN SHARED FOLDER DELETE: "+ JSON.stringify(fileInfo) +"\n" + JSON.stringify(this.selectedFile));
            //this.isDeleting = false;
            
            this._fileStorageService.deleteSharedFolder(this.selectedFile.chatRoomId)
                .subscribe(res => {
                    if (res.status === 204) {
                        if (!this.displayYourFilesComponent) {
                            this._channelService.sharedFileDeleteComplete(this.channelId, this.selectedFile.fileName);
                        }

                        //makes sure we reload into the current shared folder / spot after deleting
                        // if(this.enteredSharedFolder){
                        //     this.sharedFolderSelected = true;
                        //     if(this.currentFolderArray.length > 0)
                        //         this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
                        // }

                        //this._callFileApi();

                        this.getStorageUsage();
                        this.isDeleting = false;
                        this.selectedFile = null;
                        this._fullRefreshList();
                    } else {
                        console.log("Error in delete ?", res);
                        this._toastService.error(`There was an error in deleting ${this.selectedFile.fileName}.`);
                        this.isDeleting = false;
                    }
                });
                
                
                /*
                this._fileStorageService.fileDelete(fileInfo)
                .subscribe(res => {
                    if (res.status === 200) {
                        if (!this.displayYourFilesComponent) {
                            this._channelService.sharedFileDeleteComplete(this.channelId, this.selectedFile.fileName);
                        }
                        this._callFileApi();
                        this.getStorageUsage();
                        this.isDeleting = false;
                        this.selectedFile = null;
                    } else {
                        // console.log("Error in delete ?", res);
                        this._toastService.error(`There was an error in deleting ${this.selectedFile.fileName}.`);
                        this.isDeleting = false;
                    }
                });
                */
        }
    }

    /*
    _editSharedFolder() {
        this.editChannel.teamProjectId = this._projectId

        this._apiCallsService.putChannel(this.allChannelsFiltered[this._editIndex].channelId, this.editChannel).pipe(
            switchMap(res => {
                if (res.status === 204) {
                    console.log("updated channel");
                    this.showModalEdit();
                    return this._getProject(this._projectId);
                } else {
                    alert(JSON.stringify(res));
                }
            }),
        ).subscribe();
        
    }
    */


    // Creating a Channel/Shared Folder in-tenant
    // Akin to Fax Folders ////CG

    //This gets the projects and picks out the 'Shared Folders' one if it exists
    async checkForSharedFolderProject2(personalFiles:FileStorageFile[]) {
        this._apiCallsService.getProjectByName("Shared Folders")
            .subscribe(res => {
                if (res.status === 200) {
                    console.log("%c checkForSharedFolderProject2 data_ ", consoleLogStyle, res.body);

                    //find the correct team project
                    
                    this.sharedFolderProjectExists = true;
                    this.sharedFolderProject = res.body[0];
                    this.getChannelsForProject2(this.sharedFolderProject.teamProjectId);     
                        
                } 
                        
                else {
                      console.error("error in getting shared folders", res);
                     //alert("Error1");
                      this.filesList = personalFiles;
                      this.sharedFoldersProject.projectFound = null;
                      //this.isLoadingProjects = false;
                    }
                    });
    }

    async checkForSharedFolderProject3(personalFiles:FileStorageFile[]) {
        this._apiCallsService.getProjectsForChannelsPage()
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("%c get Users projects/channels(3) res? ", consoleLogStyle, res.body);
                    let projectsFound = res.body;

                    for (const project of projectsFound) {
                        if (project.name === "Shared Folders") {
                            this.sharedFolderProjectExists = true;
                            this.sharedFolderProject = project;
                            break; // Exit the loop once the desired project is found
                        }
                    }

                    if(this.sharedFolderProjectExists){
                        console.log("Found Shared Folder Project!");
                        this.getChannelsForProject2(this.sharedFolderProject.teamProjectId); 
                    }
                    else {
                         console.error("error in getting shared folders_", res);
                         this.filesList = personalFiles;
                         this.sharedFoldersProject.projectFound = null;
                    }
                        
                } 
                        
                else {
                      console.error("error in getting shared folders", res);
                     //alert("Error1");
                      this.filesList = personalFiles;
                      this.sharedFoldersProject.projectFound = null;
                      //this.isLoadingProjects = false;
                    }
                    });
    }

    //This gets the projects and picks out the 'Shared Folders' one if it exists
    async checkForSharedFolderProject(personalFiles:FileStorageFile[]) {
        this._apiCallsService.getProjectsForChannelsPage()
            .subscribe(res => {
                if (res.status === 200) {
                    //console.log("%c get Users projects/channels res? ", consoleLogStyle, res.body);
                    this.projects = res.body;
                    this.projects.forEach((proj) => proj.channels = []); // 
                    this.sharedFoldersProject = this.projectExists(this.projects, "Shared Folders");
                    //console.log("this.sharedFoldersProject: " + JSON.stringify(this.sharedFoldersProject));
                    
                    // Runs through the channels / folders of the Shared folders project if it is there
                    if(this.sharedFoldersProject == null || this.sharedFoldersProject.projectFound == null || this.sharedFoldersProject.projectFound.teamProjectId == null)
                    {
                        // Just show personal Files until there is Shared Folders to search
                        console.error("No Shared Folders Project Created");
                        this.isFilesLoading = false;
                        this.sharedFoldersDownloading = false;
                        this.filesList = personalFiles;
                    }
                    else{
                        console.log("running getChannelsForProject 1");
                        this.getChannelsForProject(this.sharedFoldersProject.projectFound.teamProjectId,
                            this.sharedFoldersProject.projectIdx)
                    }
                    
                } else {
                    console.error("error in get users projects/channels for shared folder", res)
                    this.filesList = personalFiles;
                    //this.isLoadingProjects = false;
                }
            });
    }

    // created if the user doesn't have a 'Shared Folders' project
    // Project/Channel structure used for sharing folders/files
    createSharedFolderProject(sharedFolderName: string) {
        this.isCreatingChannel = true;
        let newProj = {
            "channelUserId": this.userInfo.channelUserId,
            "name": "Shared Folders",
        };
        this._apiCallsService.postProject(newProj)
            .subscribe(res => {
                // 
                if (res.status === 201) {
                    // console.log("%c project created", consoleLogStyle, res.body);
                    let newChannelMade: Project = res.body;
                    this.createSharedFolderChannel(newChannelMade.teamProjectId, 
                        newChannelMade,sharedFolderName);
                } else {
                    // console.log("error creating project")
                    this.isCreatingChannel = false;
                    this._toastService.error("There was an error creating new project.");
                }
            });
    }

    // Should now return only the channels of this project that this user is a part of
    async getChannelsForProject2(projectId: string) {
        //this.projects[projectIdx].isLoadingChannels = true;
        this._apiCallsService.getChannelsByProject(this.userInfo.channelUserId, projectId)
            .subscribe(data => {
                if (data.status === 200) {
                    //console.log("%c Got channel data for project ", consoleLogStyle, this.userInfo.channelUserId);
                    if(data.body.length >= 1){
                        console.log("%c Got more channel data for project ", consoleLogStyle, data);
                        //let isSharedFolderCreator:boolean = false;
                        //let isOwnerOfSharedFolder:boolean = false;
                        for(let i = 0; i<data.body.length; i++){
                             // gets the data and converts it to a file folder object if the user is the creator or the folder was shared
                             //isSharedFolderCreator = (this.userInfo.channelUserId === data.body[i]["chatUserId"]);
                                        
                             //iterates all of the owners of the Shared folder to see if the current user was shared the folder
                             //TODO: should be userId, NOT username!...but userId in allchannelusers is returning the default GUI value ////CG
                             //console.log("FOUND ALLUSERS: " + JSON.stringify(this.projects[projectIdx].channels[i]["allChannelUsers"]));
                             /*
                             isOwnerOfSharedFolder = false;
                             for(let j = 0; j< data.body[i]["allChannelUsers"].length; j++){
                                   //console.log("FOUND USERNAME: " + JSON.stringify(data.body[i]["allChannelUsers"][j]["userName"]));
                                            
                                 if(data.body[i]["allChannelUsers"][j]["userName"]===this.userInfo.username){
                                      //alert("SHARED WITH USER!!");
                                      console.log("Folder is Shared with User!");
                                      console.log("data username: " + JSON.stringify(data.body[i]["allChannelUsers"][j]["userName"]));
                                      console.log("userInfousername: " + this.userInfo.username);
                                     isOwnerOfSharedFolder = true;
                                 }
                                 else {
                                    console.log("data username NOT FOUND: " + JSON.stringify(data.body[i]["allChannelUsers"][j]["userName"]))
                                 }

                            }
                            */
                                        
                             //if(isSharedFolderCreator == true || isOwnerOfSharedFolder == true ){
                             console.log("Getting shared folder data");
                             this._getChannelFolderData(data.body[i]);
                                   //alert("MATCH!");
                             //}
                             //else {
                             //   console.log("userInfousername NOT FOUND: " + this.userInfo.username)
                             //   console.log("Not the owner of this folder: " + JSON.stringify(data.body[i]));
                             //}

                                        
                        }
                    }
                 } 
                else {
                    // this.projects[projectIdx].isLoadingChannels = false;
                    this._toastService.error("there was a problem getting this projects channels.")
                }
                });
    }

     //  get channels by proj id, push to Project.channels[] ... on dropdown open ...
     // Finds the shared folders which are channels in the Shared Folders Project that was found
     // Filters out any channels that the user is either not the creator of or a chat user of / aka, been shared the folder
     async getChannelsForProject(projectId: string, projectIdx: number) {
        this.projects[projectIdx].isLoadingChannels = true;
        this._apiCallsService.getChannelsByProject(this.userInfo.channelUserId, projectId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.projects[projectIdx].channels = data.body;
                    this.projects[projectIdx].isLoadingChannels = false;
                    //console.info("All Shared Folders Found", this.projects[projectIdx].channels[0].channelId)
                    if(this.projects[projectIdx].channels.length >= 1){
                        // iterates through all the folders
                        //var allFoldersFound = this.projects[projectIdx].channels
                        let isSharedFolderCreator:boolean = false;
                        let isOwnerOfSharedFolder:boolean = false;
                        for(let i = 0; i<this.projects[projectIdx].channels.length; i++){
                            // gets the data and converts it to a file folder object if the user is the creator or the folder was shared
                            isSharedFolderCreator = (this.userInfo.channelUserId === this.projects[projectIdx].channels[i]["chatUserId"]);
                            
                            //iterates all of the owners of the Shared folder to see if the current user was shared the folder
                            //TODO: should be userId, NOT username!...but userId in allchannelusers is returning the default GUI value ////CG
                            //console.log("FOUND ALLUSERS: " + JSON.stringify(this.projects[projectIdx].channels[i]["allChannelUsers"]));
                            isOwnerOfSharedFolder = false;
                            for(let j = 0; j< this.projects[projectIdx].channels[i]["allChannelUsers"].length; j++){
                                //console.log("FOUND USERNAME: " + JSON.stringify(this.projects[projectIdx].channels[i]["allChannelUsers"][j]["userName"]));
                                
                                if(this.projects[projectIdx].channels[i]["allChannelUsers"][j]["userName"]===this.userInfo.username){
                                    //alert("SHARED WITH USER!!");
                                    isOwnerOfSharedFolder = true;
                                }
                            }
                            
                            //console.log("Shared Folder data Passed: " + JSON.stringify(this.projects[projectIdx].channels[i]));
                            //console.log("Shared Folder Creator ID: " + JSON.stringify(this.projects[projectIdx].channels[i]["chatUserId"]));
                            //console.log("Current User ID: " + JSON.stringify(this.userInfo.channelUserId));
                            // this._getChannelFolderData(this.projects[projectIdx].channels[i]);
                            // console.log("isSharedFolderCreator: " + JSON.stringify(isSharedFolderCreator));
                            if(isSharedFolderCreator == true || isOwnerOfSharedFolder == true ){
                               this._getChannelFolderData(this.projects[projectIdx].channels[i]);
                                //alert("MATCH!");
                            }
                            
                        }
                    }
                } else {
                    this.projects[projectIdx].isLoadingChannels = false;
                    this._toastService.error("there was a problem getting this projects channels.")
                }
            });
    }

    async _getChannelFolderData(channelPassed:any){
        //var fullChannelData : any;
        // console.log("channelPassed: " + JSON.stringify(channelPassed));
        //console.log("ALL CHANNEL USERS 137: " + JSON.stringify(channelPassed.allChannelUsers));
        this._apiCallsService.getChannel(channelPassed.channelId)
            .subscribe((data) => {
                if (data.status === 200) {
                    //fullChannelData = data.body;
                    //console.log("sharedFolders? data Found: " + JSON.stringify(data.body));
                    //console.log("fullChannelData: " + JSON.stringify(fullChannelData));
                    //console.log("Shared Folder ID: " + fullChannelData.blobContainerId);
                    //console.log("sharedFolders Found: " + JSON.stringify(this.sharedFolders));
                    //alert("Shared Folders are Loading");
                    //if(this.sharedFoldersList == null)
                        this.convertSharedFoldersToFileFolders(data.body, channelPassed);

                } else {
                    this.sharedFoldersDownloading = false;
                    console.error(data.status);
                }
        });
    }

    async _resetChannelFolderData(channelPassed:any){
        //var fullChannelData : any;
        // console.log("channelPassed: " + JSON.stringify(channelPassed));
        //console.log("ALL CHANNEL USERS 137: " + JSON.stringify(channelPassed.allChannelUsers));
        //console.log("Reset channel passed: " + channelPassed);
        //console.log("Selected File Before Refresh: " + JSON.stringify(this.selectedFile));
        this._apiCallsService.getChannel(channelPassed.chatRoomId)
            .subscribe((data) => {
                if (data.status === 200) {
                    //fullChannelData = data.body;
                    //console.log("sharedFolders? data Found: " + JSON.stringify(data.body));
                    this.refreshUserMemberList(data.body, channelPassed);
                    //alert("REFRESH");
                    //console.log("Files List:" + this.fullFilesList);
                    //alert("DETAILS: " + JSON.stringify(this.fullFilesList[this.selectedFileIdx]));

                } else {
                    this.sharedFoldersDownloading = false;
                    //alert("ERROR WITH RESET");
                    console.error(data.status);
                }
        });
    }

    _createSharedFolderFileList(sharedFolderChannelData: any){

        let folderInfo = 
        {
            "containerName": sharedFolderChannelData.blobContainerId,
            "pageSize":this.fileListPagination.currentPage + 1,
            "orderBy":"isFile, fileName",
            "chatRoomId":sharedFolderChannelData.channelId
        }

        this._fileStorageService.getFiles(folderInfo)
            .subscribe(data => {
                if (data.status === 200) {
                    let list: any = data.body;
                    //console.log("THIS.list:", JSON.stringify(list));
                    //this.filesList = this.filesList.concat(list);
                    //console.log("THIS.filesList:", this.filesList);
                    this.fileListPagination = JSON.parse(data.headers.get('X-Pagination'));
                    this.scrollTopBlock = false;
                    setTimeout(() => {
                        this.isGettingNextPage = false;
                        //console.log('%c Show getting more files = ', consoleLogStyle, this.isGettingNextPage, this.currentDateTime);
                    });
                } else {
                    console.error("Error in pagination ?", data);
                    this._toastService.error("There was an error in getting more files.");
                    this.isGettingNextPage = false;
                }
            });

    }

    // post Shared Folder "Channel"
    createSharedFolderChannel(newProjectId?: string, newProject?: Project, sharedFolderName?: string) {
        this.isCreatingChannel = true;
        let newChannelMade: ChannelFull;
        let channel = {
            //"teamProjectId": newProjectId ? newProjectId : this.newChannel.value.channelProject,
            "teamProjectId": newProjectId,
            "channelUserId": this.userInfo.channelUserId,
            "name": sharedFolderName,
            "type": "legacy", //make use of this to 'folder' later
            "videoType": "jitsi",
            "topic": "Shared Folder: " + sharedFolderName,
            "welcome": "Shared Folder: " + sharedFolderName,
            "deleteRule": 0,
            "deleteInterval": 0,
            "scope": "Tenant",
            "private": true
        };
        this._apiCallsService.postChannel(channel)
            .subscribe(res => {
                if (res.status === 201) {
                    console.log("%c channel created", consoleLogStyle, res.body);
                    
                    //reset shared folders list
                    //this.sharedFoldersList = [];

                    //makes sure we reload inot the current shared folder after renaming
                    if(this.enteredSharedFolder){
                        this.sharedFolderSelected = true;
                        if(this.currentFolderArray.length > 0)
                            this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
                    }

                    newChannelMade = res.body;
                    this.isCreatingChannel = false;
                    //console.log('New Shared Channel Created: ' + JSON.stringify(newChannelMade));
                    this.isFolderSubmitting = false;
                    this.newFolder.reset();
                    this.displayCreateFolderForm = false;
                    this._fullRefreshList();
                    // this.onCompleteEvent.emit();
                    // this._modalService.modalFormCompleteSubject.next();
                    // if staying on this page can push new channel to project then push to front 
                    // this.onCompleteEvent.emit(newChannelMade); // pushes to project[] ... 
                    // this.router.navigate(['/channel', newChannelMade.channelId]);
                } else {
                    // console.log("error creating channel")
                    this.isCreatingChannel = false;
                    this.isFolderSubmitting = false;
                    this.newFolder.reset();
                    this.displayCreateFolderForm = false;
                    this._toastService.error("There was an error creating new channel.");
                }

                
            
            });

    }

    // desktop only on (keyup.enter) of input 
    createFolderOnEnter() {
        if (this.newFolder.invalid) {
            // console.log("%c Hey no value ", consoleLogStyle);
            return;
        } else if (this.newFolder.valid) {
            this._createFolder();
        }
    }
    // create a folder end 

    // File Upload Start 
    // 🎫
    /*
        only accept { images, documents, audio, video } 
        & size max { 50mb } 
        
        50 Megabytes (MB)  =  52,428,800 Bytes (B)
        100 Megabytes (MB)  =  104,857,600 Bytes (B)
        
        .exe = '4D5A'
        .dmg = '78DA'
    */

    fileUploadReader(e: Event) {
        const target = e.target as HTMLInputElement; // ang12 ts fix
        const files = target.files as FileList; // ang12 ts fix
        this.displayCreateFolderForm = false;
        this.isFileUploading = true;
        // this.uploadStatusChange.emit(this.isFileUploading); //comment back later when in new component
        this.uploadFile = files[0];
        let fileReader = new FileReader();
        fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
            let arr = (new Uint8Array(<ArrayBuffer>e.target.result)).subarray(0, 2);
            let size = <ArrayBuffer>e.target.result;
            // console.log("file byte length / file size :::", size.byteLength)
            // console.log("buffer array :::", arr)
            let hex = "";
            for (let i = 0; i < arr.length; i++) {
                hex += arr[i].toString(16);
            }
            // console.log("file hex :::", hex)
            // Check the file signature against known types
            let exe = ['4D5A', '78DA'];
            if (exe.includes(hex.toUpperCase())) {
                // console.log("is exe/dmg :::", hex)
                this.isFileUploading = false;
                this._toastService.error("Can not upload Executable file.")
                return;
            } else if (size.byteLength > 104857600) {
                // console.log("size > 50mb. :::", hex)
                this.isFileUploading = false;
                this._toastService.error("File size too large, file must be less than 100mb.")
                return;
            } else {
                // console.log("not exe | not > 50mb, ok to upload")
                this._uploadFileToStorage();
            }
        };
        fileReader.readAsArrayBuffer(this.uploadFile);
        // console.log("file size?", this.uploadFile.size)
    }

    _uploadFileToStorage() {
        //alert("HERE!!");
        let fileInfo: any;
        //console.log("FILE INFO:");
        //console.log("this.selectedFile: " + JSON.stringify(this.selectedFile));
        //console.log("this.displayYourFilesComponent: " + JSON.stringify(this.displayYourFilesComponent));
        // Uploads to channel files if in a shared folder as they are channels
        // .rev having data is what determines this
        // TODO: simplyfy logic: displayyourfilescomponent being null is true in both the
        //       main file component page and on channels.. however, selectedfile can be null thus chaking for .rev can cause an error 
        ////CG
        if(this.displayYourFilesComponent && this.enteredSharedFolder == false) {
            //alert("REGULAR FILE");
            fileInfo = {
                "containerName": this.containerName,
                "path": this.currentFolderPath() + this.uploadFile.name,
                "fileName": this.uploadFile.name,
                "autoRename": false,
                "mode": "add",
                "parentBlobFileId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "size": this.uploadFile.size
            };
        } else { // channel files
            //alert("CHANNEL FILE");

            // This is because shared folder files are channel files but the channelID is stored in the parent folder
            let folderPathToUploadTo = "";

            if(this.enteredSharedFolder && this.channelId == null){
                this.channelId = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
                folderPathToUploadTo = "/" + this.uploadFile.name;
            }
            else
                folderPathToUploadTo = this.currentFolderPath() + this.uploadFile.name


            fileInfo = {
                "containerName": this.channelContainerName,
                "path": folderPathToUploadTo,
                "fileName": this.uploadFile.name,
                "autoRename": false,
                "mode": "add",
                "parentBlobFileId": this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId,
                "size": this.uploadFile.size,
                "channelId": this.channelId,
            };

            if(!this.channelContainerName && this.enteredSharedFolder == true){
                //alert("UPLOADING TO SHARED FOLDER: ");
                fileInfo["containerName"] = fileInfo["parentBlobFileId"];
                //fileInfo["containerName"] = "0a05bb42-e838-440f-8bc2-b9a1a647b222";
                
                fileInfo["path"] = "/"+this.uploadFile.name;
                // fileInfo["parentBlobFileId"] = null;
                delete fileInfo.parentBlobFileId;
            }
        }

        //console.log("FILE INFO PASSED TO UPLOAD: "+ JSON.stringify(fileInfo));
        this._fileStorageService.fileUpload(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    //console.log("%c Success for upload: ", consoleLogStyle, res.status);
                    this._uploadFileToStorage2(fileInfo);
                } else if (res.status === 400) {
                    // console.log("Error with upload NAME ?", res);
                    this._toastService.error("Only file names with 'a-z 0-9 (-._)', no special characters are allowed, file was not uploaded.");
                    this.isFileUploading = false;
                } else if (res.status === 409) {
                    // console.log("%c File already exists! ", consoleLogStyle, res.status);
                    this.isFileUploading = false;
                    const allFiles = Array.from(document.getElementsByClassName('file-item') as HTMLCollectionOf<HTMLElement>);
                    // console.log("%c All files ", consoleLogStyle, allFiles);
                    allFiles.forEach(i => i.style.display = 'none');
                    this.isDuplicateFile = true;
                } else {
                    console.log("error with file upload:", res.status);
                    this._toastService.error("There was an error, file was not uploaded.");
                    this.isFileUploading = false;
                }
            });
    }

    handleDuplicateFile(canReplace: boolean) {
        // console.log("%c Duplicate file handle ", consoleLogStyle);
        if (canReplace) {
            let fileInfo: any;
            let parentBlobFileIdentifierString = this.currentFolderArray[this.currentFolderArray.length - 1].blobFileId;
            if(this.enteredSharedFolder){
                parentBlobFileIdentifierString = this.currentFolderArray[this.currentFolderArray.length-1].chatRoomId;
            }

            if(this.displayYourFilesComponent) {
                fileInfo = {
                    "containerName": this.containerName,
                    "path": this.currentFolderPath() + this.uploadFile.name,
                    "fileName": this.uploadFile.name,
                    "autoRename": false,
                    "mode": "add",
                    "parentBlobFileId": parentBlobFileIdentifierString,
                    "size": this.uploadFile.size
                }; 
            } else {
                fileInfo = {
                    "containerName": this.containerName,
                    "path": this.currentFolderPath() + this.uploadFile.name,
                    "fileName": this.uploadFile.name,
                    "autoRename": false,
                    "mode": "add",
                    "parentBlobFileId": parentBlobFileIdentifierString,
                    "size": this.uploadFile.size,
                    "channelId": this.channelId,
                }; 
            }

            if(!this.channelContainerName && this.enteredSharedFolder == true){
                //alert("UPLOADING TO SHARED FOLDER: ");
                fileInfo["containerName"] = fileInfo["parentBlobFileId"];
                //fileInfo["containerName"] = "0a05bb42-e838-440f-8bc2-b9a1a647b222";
                
                fileInfo["path"] = "/"+this.uploadFile.name;
                // fileInfo["parentBlobFileId"] = null;
                delete fileInfo.parentBlobFileId;
            }

            //alert("fileInfo: " + JSON.stringify(fileInfo));
            // console.log("%c Replace file authorized ", consoleLogStyle);
            this.isDuplicateFile = false;
            this._toastService.warn(`${fileInfo.fileName} already exists, overwriting existing file...`);
            this.isFileUploading = true;
            this._uploadFileToStorage2(fileInfo);
        } else if (!canReplace) {
            // console.log("%c Replace file IS NOT authorized ", consoleLogStyle);
            this.isDuplicateFile = false;
            this._callFileApi();
        }
    }

    _uploadFileToStorage2(fileInfo: any) {
        this._fileStorageService.fileUpload2(fileInfo)
            .subscribe(res => {
                if (res.status === 200) {
                    let file: any = res.body;
                    let url = file.uploadUrl;
                    let indexOfQueryStart = url.indexOf("?");
                    let baseUrl = url.substring(0, indexOfQueryStart) + "/" + this.trimStart("/", fileInfo.path);
                    this.sasToken = url.substring(indexOfQueryStart);
                    // console.log("%c File Uploaded: ", consoleLogStyle ,baseUrl);
                    this.blockBlobClient = new BlockBlobClient(baseUrl + this.sasToken);
                    this.uploadToBlobStorage(file.blobFileId, fileInfo);
                } else {
                    // console.log("Error with upload ?", res);
                    this._toastService.error("There was an error, file was not uploaded.");
                    this.isFileUploading = false;
                }
            });
    }

    trimStart(character: any, string: any) {
        var startIndex = 0;
        while (string[startIndex] === character) {
            startIndex++;
        }
        return string.substring(startIndex);
    }

    async uploadToBlobStorage(fileId: string, fileInfo: any) {
        //console.log("Content Being Uploaded To Blob: ");
        //console.log("File ID: "+ JSON.stringify(fileId));
        //console.log("***FILE INFO***: " + JSON.stringify(fileInfo));
        const content = this.uploadFile;
        // const blobName = this.uploadFile.name;
        // console.log("upload to blob",this.blockBlobClient);
        let uploadBlobResponse;
        try {
            uploadBlobResponse = await this.blockBlobClient.upload(content, content.size);
            //this._toastService.success(`${blobName} has uploaded.`);
            if(this.selectedFile == null && this.currentFolderArray.length > 0)
                    this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
            
            //makes sure we reload into the current shared folder after uploading
            if(this.enteredSharedFolder){
                this.sharedFolderSelected = true;
                if(this.currentFolderArray.length > 0)
                    this.selectedFile = this.currentFolderArray[this.currentFolderArray.length-1];
            }

            this._callFileApi();
            this.getStorageUsage();
            if (!this.displayYourFilesComponent) {
                this._channelService.sharedFileUploadComplete(this.router.url, fileInfo.fileName, this.channelId);
            }

            this.selectedFile = null;
        } catch (err) {
            this._deleteFileFromStorageOnly(fileId);
            console.error("error with upload to blob", err);
            this._toastService.error("There was an error, file was not uploaded.");
        } finally {
            this.isFileUploading = false;
        }
    }
    // called on upload to blob storage for error catch 
    _deleteFileFromStorageOnly(blobFileId: string) {
        this._fileStorageService.blobFileDelete(blobFileId)
            .subscribe(res => {
                if (res.status === 204) {
                    this._callFileApi();
                } else {
                    // console.log("error in soft delete from error in upload:",res);
                    this._toastService.error("There was an error with uploading");
                }
            });
    }
    //File Upload End 

    // to close all modals | add switch if needed to close milti ... 
    clickOffModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        // console.log("%c e.t, e.srcel, e.curtar ", consoleLogStyle, event.target, event.srcElement, event.currentTarget)
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
            // console.log("%c tar.id? ,val? ", consoleLogStyle, idAttr, value)
        }
        switch (value) {
            case 'add-owner-modal': {
                this.toggleAddOwnerModal();
                break;
            }
             ////CG Add for completed sharing functionality 
            // case 'share-fax-modal': {
            //     this.toggleShareModal();
            //     break;
            // }

            // case 'send-file-modal': {
            //     if (this.isFaxFormEdited) {
            //         this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "toggleSendFaxModal");
            //     } else {
            //         this.toggleSendFileModal();
            //     }
            //     break;
            // }
        }
    }

    // helper function for finding if a channel exists in project array
    /* projectExists(projects:Project[], projectName: string): boolean {
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].name === projectName) {
                return true;
            }
        }
        return false;
    } */

    // Grabs any shared folders user is a member of 
    // getUserSharedFolders(projects:Project[]): { exists: boolean, foldersFound: any } {
    //     for (let i = 0; i < projects.length; i++) {
    //         if (projects[i] === "Shared Folders") {
    //             alert("Shared Folder Found: " + projects[i].channels[0].channelName);
    //             return { exists: true, foldersFound: projects[i].channels };
    //         }
    //     }
    //     return { exists: false, foldersFound: null };
    // }

    //helper function to check if a project exists for the user
    projectExists(projects:Project[], projectName: string): { exists: boolean, projectFound: Project, projectIdx: number } {
        //console.log("Projects passed: " + JSON.stringify(projects));
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].name === projectName) {
                //console.log("Shared Folder Channels Found: " + projects[i]);

                /*this.getChannelsForProject(projects[i].teamProjectId,i).then(()=>{
                    console.log("SHARED FOLDERS LOADED!!");
                });*/
                
                return { exists: true, projectFound: projects[i], projectIdx: i };
            }
        }
        return { exists: false, projectFound: null, projectIdx: -1 };
    }

    //  setCreatingSharedFolder(){
    //     this.creatingSharedFolder = true;
    //     this._createFolder();
    // }



    //builds the lists of owners or members in the shared folder
    /*
    buildOwnerMemberList(sharedFolderUsersFound:any)
    {
        const allUserData: string[] = [];

        for (let i = 0; i < sharedFolderUsersFound.length; i++) {
          const userInfo = sharedFolderUsersFound[i]["chatUserKeyNavigation"];
          allUserData.push(userInfo);
        }

        return allUserData;
    }
    */

    buildOwnerMemberList(sharedFolderUsersFound: any[]) {
        const allUserData: any[] = [];
        const creators: any[] = [];
        
        for (let i = 0; i < sharedFolderUsersFound.length; i++) {
          const userInfo = sharedFolderUsersFound[i]["chatUserKeyNavigation"];
          if (userInfo.rank === 'creator') {
            creators.push(userInfo);
          } else {
            allUserData.push(userInfo);
          }
        }
      
        // Sort the creators array by displayName
        creators.sort((a, b) => a.displayName.localeCompare(b.displayName));
      
        // Sort the allUserData array by displayName
        allUserData.sort((a, b) => a.displayName.localeCompare(b.displayName));
      
        // Concatenate the creators array with the allUserData array (creators first)
        const sortedUserData = creators.concat(allUserData);
      
        return sortedUserData;
      }
      

    refreshUserMemberList(sharedfolderdata: ChannelFull, channelUsersPassed: ChannelCard)
    {
        //console.log("Converting shared Folder");
        //console.log("Refreshing the following folder data: " + JSON.stringify(sharedfolderdata) );
        //console.log("channelUsersPassed 137: " + JSON.stringify(channelUsersPassed));
        //console.log("channelUsersPassed 137 array 0: " + JSON.stringify(channelUsersPassed[0].displayName));
        
        // Parsing Members and Owners out

        //Members
        const sharedFolderMembersFound = sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUser1"];
        let allmemberUserData: string[] = this.buildOwnerMemberList(sharedFolderMembersFound);

        //Owners
        const sharedFolderOwnersFound = sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUsers"];
        let allOwnerUserData: string[] = this.buildOwnerMemberList(sharedFolderOwnersFound);


        //updates the searchable pool of users to add as owners/members to a shared folder
        // this.sharedFolderUserPool = this.buildFilteredUserList(this.selectedFile);

        //sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUser1"][0]["chatUserKeyNavigation"]
        
        let sharedFolderFileObj: FileStorageFile = 
            {
                blobFileId: sharedfolderdata.blobContainerId,
                fileName: sharedfolderdata.name + "/", // added / due to naming removing last char
                chatRoomId: sharedfolderdata.channelId,
                chatUserId: sharedfolderdata.channelUserId,
                chatUserKeyNavigation: sharedfolderdata.chatUserKeyNavigation,
                created: sharedfolderdata.dateCreated,
                lastActivity: sharedfolderdata.lastActivity,
                lastUpdated: sharedfolderdata.lastActivity,
                rev: sharedfolderdata.channelId,
                urlPath: "/" + sharedfolderdata.name + "/",
                urlPathLower: "/" + sharedfolderdata.name.toLowerCase() + "/",
                chatRoomChatUsers: channelUsersPassed,
                sharedFolderOwners: allOwnerUserData,
                sharedFolderMembers: allmemberUserData,
            };

            this.selectedFile = sharedFolderFileObj;

            //updates the folder in the list without full reset
            this.fullFilesList[this.selectedFileIdx] = sharedFolderFileObj;

            //refreshes the search list of users for shared folder
            this._getUsersBySearchQuery(this.selectedFile.chatRoomChatUsers.allChannelUsers);
        
            // console.log("UPDATED OWNER/MEMBER DATA: " + JSON.stringify(sharedFolderFileObj));
            /*
            this.sharedFoldersList.push(sharedFolderFileObj);
            console.log("Shared Folder ObjectPushed: " + JSON.stringify(sharedFolderFileObj) );
            //console.log("Shared Folder MemberID: " + JSON.stringify(sharedFolderFileObj.sharedFolderMembers[0]["chatUserNavigation"]["channelUserId"]) );

        //add to the main files list
        this.filesList = [];
        this.filesList = this.sharedFoldersList.concat(this.personalFilesList);
        this.fullFilesList = this.filesList;
        this.isFilesLoading = false;
        this.sharedFoldersDownloading = false;
        //console.log("Shared Folder List File Objects Created: "+ JSON.stringify(this.sharedFoldersList));
        //console.log("File List after shared folders found: "+ JSON.stringify(this.filesList));
        */
    }

    //used to filter out current shared folder users out of the search query
    /*
    buildFilteredUserList(selectedFile: any) {
        if(selectedFile == null){
            console.error("Selected File is Null");
            return null;
        }
        const allChannelUsers = selectedFile.chatRoomChatUsers.allChannelUsers;
        const sharedFolderOwners = selectedFile.sharedFolderOwners;
        const sharedFolderMembers = selectedFile.sharedFolderMembers;
      
        // Filter users from allChannelUsers that are not in sharedFolderOwners or sharedFolderMembers
        const filteredUsers = allChannelUsers.filter((user: any) => {
          // Check if user is not in sharedFolderOwners or sharedFolderMembers
          const isNotInOwners = !sharedFolderOwners.some((owner: any) => owner.channelUserId === user.channelUserId);
          const isNotInMembers = !sharedFolderMembers.some((member: any) => member.channelUserId === user.channelUserId);
            console.log("FILTERED OWNERS!");
          // Include the user in the filtered list if they are not in sharedFolderOwners or sharedFolderMembers
          return isNotInOwners && isNotInMembers;
        });
        
        console.log("FILTERED USERS: " + JSON.stringify(filteredUsers));
        return filteredUsers;
      }
      */      

    // converts from ChannelFull to FileStorageFile
    convertSharedFoldersToFileFolders(sharedfolderdata: ChannelFull, channelUsersPassed: ChannelCard)
    {
        //console.log("Converting shared Folder");
        //console.log("Shared Folder Data: " + JSON.stringify(sharedfolderdata) );
        //console.log("channelUsersPassed 137: " + JSON.stringify(channelUsersPassed));
        //console.log("channelUsersPassed 137 array 0: " + JSON.stringify(channelUsersPassed[0].displayName));
        
        // Parsing Members and Owners out of search query

        //Members
        const sharedFolderMembersFound = sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUser1"];
        let allmemberUserData: string[] = this.buildOwnerMemberList(sharedFolderMembersFound);

        //Owners
        const sharedFolderOwnersFound = sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUsers"];
        let allOwnerUserData: string[] = this.buildOwnerMemberList(sharedFolderOwnersFound);

        //updates the searchable pool of users to add as owners/members to a shared folder
        // this.sharedFolderUserPool = this.buildFilteredUserList(this.selectedFile);

        //
        

        //sharedfolderdata.teamProjectKeyNavigation.channels[0]["chatRoomChatUser1"][0]["chatUserKeyNavigation"]
        
        let sharedFolderFileObj: FileStorageFile = 
            {
                blobFileId: sharedfolderdata.blobContainerId,
                fileName: sharedfolderdata.name + "/", // added / due to naming removing last char
                chatRoomId: sharedfolderdata.channelId,
                chatUserId: sharedfolderdata.channelUserId,
                chatUserKeyNavigation: sharedfolderdata.chatUserKeyNavigation,
                created: sharedfolderdata.dateCreated,
                lastActivity: sharedfolderdata.lastActivity,
                lastUpdated: sharedfolderdata.lastActivity,
                rev: sharedfolderdata.channelId,
                urlPath: "/" + sharedfolderdata.name + "/",
                urlPathLower: "/" + sharedfolderdata.name.toLowerCase() + "/",
                chatRoomChatUsers: channelUsersPassed,
                sharedFolderOwners: allOwnerUserData,
                sharedFolderMembers: allmemberUserData,
            };
            this.sharedFoldersList.push(sharedFolderFileObj);
            // console.log("Shared Folder ObjectPushed: " + JSON.stringify(sharedFolderFileObj) );
            //console.log("Shared Folder MemberID: " + JSON.stringify(sharedFolderFileObj.sharedFolderMembers[0]["chatUserNavigation"]["channelUserId"]) );

        //add to the main files list
        this.filesList = [];

        //sort the shared folders list
        this.sharedFoldersList.sort((a, b) => a.fileName.localeCompare(b.fileName));

        this.filesList = this.sharedFoldersList.concat(this.personalFilesList);
        this.fullFilesList = this.filesList;
        this.isFilesLoading = false;
        this.sharedFoldersDownloading = false;
        //console.log("Shared Folder List File Objects Created: "+ JSON.stringify(this.sharedFoldersList));
        //console.log("File List after shared folders found: "+ JSON.stringify(this.filesList));
    }

    checkIfOnlyASharedFolderMember(userToCheck: any, sharedFolderMembers: any[])
    {
        //console.log("User Checking: " + JSON.stringify(userToCheck));
        //console.log("Member List Passed: " + JSON.stringify(sharedFolderMembers));
        //const userToCheck = { channelUserId: "123" }; // User to check against sharedFolderMembers
        // const sharedFolderMembers = [
        //     { channelUserId: "456", name: "John" },
        //     { channelUserId: "789", name: "Jane" },
        //     { channelUserId: "234", name: "Alice" },
        // ];

        let userFound = false;

        for (let i = 0; i < sharedFolderMembers.length; i++) {
            const member = sharedFolderMembers[i];
            if (member.channelUserId === userToCheck.channelUserId) {
                userFound = true;
                break;
            }
        }

        if (!userFound) {
            //console.log("User not found in sharedFolderMembers");
            return false;
        } else {
            //console.log("User found in sharedFolderMembers");
            return true;
        }

    }
}
// end 

