import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "mediaWidthCheck"
})

//only works for inital load
export class MediaWidthCheckPipe implements PipeTransform {
    transform(mediaWidth: number, lessThan?: boolean): boolean {
        let mediaQuery = window.matchMedia(`(max-width: ${mediaWidth - 1}px)`)
        if (mediaQuery.matches) {
            return lessThan ? true : false;
        } else {
            return lessThan ? false : true;
        }
    }
}