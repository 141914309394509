import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { Constants } from '../constants';
import { DocumentToSign, User } from '../models/models';
import { ChannelCard } from '../shared/interface/channel-card';
import { Project } from '../shared/interface/project';
import { consoleLogStyle } from '../shared/varaibles';



@Injectable({
    providedIn: 'root'
})
export class ApiCallsService {
    public _http: string = Constants.apiRoot;
    public _user: any = this._authService._user;

    constructor(private httpClient: HttpClient, private _authService: AuthService) {
        this._authService.userChange$.subscribe(() => {
            this._user = this._authService._user;
        });
    }

    // FORMS & DOC SIGN APIS AT BOTTOM  ... 


    // Get Channel History [for home page column /w only 6 results] temp in lu of favs on homepage ... 
    getChannelHistoryForChannelUserOnly6(channelUserId: string): Observable<HttpResponse<Array<ChannelCard>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + `channelHistory?chatUserId=${channelUserId}&pageSize=6`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // new full channel history /w pagination
    getChannelHistoryForChannelUserFullList(channelUserId: string): Observable<HttpResponse<Array<ChannelCard>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + `channelHistory?chatUserId=${channelUserId}&pageSize=24`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // new full channel favorites /w pagination
    getChannelFavoritesForChannelUser(channelUserId: string): Observable<HttpResponse<Array<ChannelCard>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + `channelHistory?chatUserId=${channelUserId}&pageSize=24&isFavorite=true`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // new channel by project
    getChannelsByProject(channelUserId: string, teamProjectIdId: string): Observable<HttpResponse<Array<ChannelCard>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + `channel/${channelUserId}/teamProject/${teamProjectIdId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // ⭐ all projects you created ... 
    getAllProjects(tenant: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}teamProject?tenant=` + tenant, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // ⭐ get all projects by user id ... 
    // returns all projects that you are a member/owner of channel ... 
    getProjectsForChannelsPage(): Observable<HttpResponse<Array<Project>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'teamproject/channelUser/' + this._authService._user.profile.sub, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postChannelHistoryForChannelUser(historyObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(this._http + 'channelHistory', historyObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    patchChannelHistoryForChannelUser(channelHistoryId: string, channelHistoryObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let channelHistoryArray = [channelHistoryObj];
        return this.httpClient
            .patch(this._http + 'channelHistory/' + channelHistoryId, channelHistoryArray, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Getting a user with all of their channels from Search
    getChannelSearch(searchEntry: Observable<string>) {
        // console.log("%c channel search from navbar? ", consoleLogStyle, searchEntry)
        return searchEntry.pipe(debounceTime(200)) // ??? ...
            //.distinctUntilChanged()
            .pipe(switchMap(searchEntry => this.getChannelsWithSearchEntry(searchEntry)));
    }

    getChannelsWithSearchEntry(searchEntry: string) {
        if (!searchEntry) {
            // console.log("searchEntry is empty!");
            return [];
        }
        return this.getUserWithSearchChannels(searchEntry);
    }

    getUserWithSearchChannels(searchEntry: string): Observable<HttpResponse<any>> {
        this._user = this._authService._user;
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channelUser/channelSearch/' + this._user.profile.sub + '/' + searchEntry, { headers: headersConfig })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    
    getDirectChannelByTwoUsers(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channel/getDirectByTwoUsers/' + userId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
    // new from admin ... 
    postProject(projectObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}teamProject/`, projectObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Getting a user with all of their channels 
    getUsersChannels(): Observable<HttpResponse<any>> {
        this._user = this._authService._user;
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channelUser/channels/' + this._user.profile.sub, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // using to get single channel by id in channel primary ... 
    // need to add to any modal with channel info from card ... 
    getChannel(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channel/' + channelId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelByName(channelName: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channel?name=' + channelName, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    // console.log(err.name);
                    return of(err);
                })
            );
    }

    getChannelByNameAndProjectId(channelName: string, teamProjectId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channel?name=${channelName}&teamProjectId=${teamProjectId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    // console.log(err.name);
                    return of(err);
                })
            );
    }

    postChannel(channelObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(this._http + 'channel/userCreateChannel', channelObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putChannel(channelId: string, channelObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}channel/` + channelId, channelObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //Get Channel User With ChatUserId
    getChannelUserWithChatUserId(channelUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channelUser/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Get User Profiles with User Id's
    getChannelUserProfileWithId(channelUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}profile/user/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // My channel User
    getMyChannelUser(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channelUser/claimsUser`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Search Channel Users
    getChannelUsersBySearchQuery(queryString: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channelUser?searchQuery=${queryString}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getChannelUsersByTenantAndSearchQuery(tenant: string, queryString: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channelUser?tenant=${tenant}&searchQuery=${queryString}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //Check if list of usernames, returns true usernames
    checkUsersExistByUsername(names: string[]): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}channelUser/checkUsersExist`, names, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Channel Owners/ chatRoomChatUsers
    getAllChannelOwners(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}chatRoomChatUsers`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postChannelOwner(ownerObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}chatRoomChatUsers`, ownerObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postBatchChannelOwners(ownersList: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}chatRoomChatUsers/batchCreate`, ownersList, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteChannelOwner(ownerObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let channelId = ownerObject.channelId;
        let channelUserId = ownerObject.channelUserId;
        return this.httpClient
            .delete(`${Constants.apiRoot}chatRoomChatUsers/${channelId}/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getUserFaxAccounts(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channel/fax/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postCreateUserFaxAccount(faxAccountObject: any) {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}faxAccounts`, faxAccountObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Channel Members/ chatRoomChatUser1
    postChannelMember(memberObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}chatRoomChatUser1`, memberObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putChatRoomsChannelUsers(chatRoomsChannelUsersObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}chatRoomChatUsers/`, chatRoomsChannelUsersObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postJitsiJwt(jitsiJwtObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}jitsiToken`, jitsiJwtObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getJitsiJwt(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}jitsiToken`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteChannelMember(memberObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let channelId = memberObject.channelId;
        let channelUserId = memberObject.channelUserId;
        return this.httpClient
            .delete(`${Constants.apiRoot}chatRoomChatUser1/${channelId}/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Getting Channel Specific Messages
    // Initial Messages
    getInitialMessages(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'channelMessage?pageSize=15&channelId=' + channelId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // ❌ Additional Messages // this is not in use
    //getMoreMessages(channelId, firstMessageDate, page) {
    //    return this.httpClient
    //        .get(this._http + 'channelMessage?channelId=' + channelId + '&LoadDate=' + firstMessageDate + '&PageNumber=' + page, { observe: "response" });
    //}	

    getTenants(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}tenant`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getProjectByName(projectName: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'teamProject/single/' + projectName, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Generic api call for pagination calls, will return based on pagination link (cote updated W/ pipe(err) && Return<Type>)
    getGenericForPagination(link: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(link, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // used to set guest display name 
    patchChannelUser(userId: string, userObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let arr = [userObject];
        return this.httpClient
            .patch(this._http + 'channelUser/' + userId, arr, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // Sending a Support Ticket to Zen Desk using our API
    sendSupportTicket(supportObj: any): Observable<HttpResponse<any>> {
        supportObj.email = this._authService._user.profile.email;
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(this._http + 'supportTicket/open_ticket', supportObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getSupportTicket(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'supportTicket/externalId/' + this._authService._user.profile.sub, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //Send fax API call
    postFaxApi(faxObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}fax`, faxObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    //ReSend fax API call
    resendFaxApi(faxObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}fax/resend`, faxObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getFaxApi(containerName: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}fax?pageSize=15&containerName=` + containerName, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteSentFax(sentFaxId: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}fax/${sentFaxId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    // console.log(err.name);
                    return of(err);
                })
            );
    }
    // 








    // FORMS & DOC SIGN APIS ... 

    // getDocument(documentId:string) {
    //     if (documentId === '1234') {
    //         return this.document2;
    //     }
    //     // make API call to get document using the documentId
    //     return this.document;
    // }

    //  Online Forms
    // Build Online Form
    postCreateForm(formObject) {
        return this.httpClient.post(this._http + 'formTemplate/createForm', formObject, { observe: "response" });
    }
    // get online forms
    getOnlineForms() {
        return this.httpClient.get(this._http + 'formTemplate?pagesize=20', { observe: "response" });
    }
    getSpecificOnlineForms(formName: string) {
        return this.httpClient.get(this._http + 'formTemplate?FormName=' + formName, { observe: "response" });
    }
    getSpecificOnlineFormsForChannel(channelId: string) {
        return this.httpClient.get(this._http + 'chatRoomFormTemplate?ChannelId=' + channelId, { observe: "response" });
    }
    getChannelSpecificFormInstances(channelId: string) {
        return this.httpClient.get(this._http + 'formInstance?channelId=' + channelId, { observe: "response" });
    }
    // fill out online form
    postOnlineForm(responseObject) {
        return this.httpClient.post(this._http + 'formTemplate/submitForm', responseObject, { observe: "response" });
    }
    // get specific Form with form template id
    getOnlineFormByTemplateId(formTemplateId) {
        return this.httpClient.get(this._http + 'formTemplate/form/' + formTemplateId, { observe: "response" });
    }
    // get a form that was filled out
    getFormInstance(formTemplateId, formInstanceId) {
        return this.httpClient.get(this._http + 'formTemplate/filledOutForm/' + formTemplateId + '/' + formInstanceId, { observe: "response" });
    }

    // api call to upload files
    // needs error handling ...
    postFile(fileToUpload: File) {
        const endpoint = 'your-destination-url';
        const formData: FormData = new FormData();
        var headersConfig = new HttpHeaders();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        return this.httpClient
            .post(this._http + endpoint, formData, { headers: headersConfig });
        //.map(() => { return true; })
        //.catch((e) => this.handleError(e));
    }
    // api call to retrieve documents for a user
    getDocumentsForUser(userId) {
        var response = [];
        for (let doc of this.documentsForUser) {
            if (doc.userId === userId) {
                response.push(doc);
            }
        }
        return response;
    }
    setSignature(initials, signature) {
        // make api call to set the images of the initials and the signature
        // this._user.userInitialsUrl = "../../assets/images/initials.png";
        // this._user.userInitialsUrl = "../../assets/images/signature.png";
        this._user.userInitialsUrl = initials;
        this._user.userSignatureUrl = signature;
        // replace with the return statement of setting this to the api
        return true;
    }


    // 

    public stuffAndThings = {
        "entries":
            [
                {
                    "name": "$DirectMessageChannels$",
                    "id": "FiDUVlBVM2SZHXzXQWmZfg",
                    "pathLower": "/$directmessagechannels$",
                    "pathDisplay": "/$DirectMessageChannels$",
                    "isFile": false,
                    "isDeleted": false,
                    "isRestored": false,
                    "isRenamed": false,
                    "hasSharedLink": false
                }
            ]
    }


    public documentsForUser: any[] = [
        {
            'documentId': '1234',
            'date': '09-09-2019',
            'name': 'contract for stuff',
            'pages': '3',
            'userId': '1234',
            'complete': false
        },
        {
            'documentId': '1235',
            'date': '09-15-2019',
            'name': 'more stuff in contract',
            'pages': '6',
            'userId': '1234',
            'complete': false
        },
        {
            'documentId': '1236',
            'date': '09-02-2019',
            'name': 'other things that are contract related',
            'pages': '8',
            'userId': '1235',
            'complete': false
        }
    ];

    public fakeUser: User = {
        "userId": "1234",
        "userInitialsUrl": "",
        "userSignatureUrl": "",
        "userFirstName": "Dan",
        "userMiddleName": "",
        "userLastName": "Enache",
        "userEmail": "calin121@gmail.com"
    };

    // Client Customer ID should be the same as the userId
    public document: DocumentToSign = {
        "documentId": "123",
        "clientCoName": "VideoNet",
        "clientEmployeeName": "Ben Leishman",
        "clientEmployeeEmail": "ben@leishman.co",
        "clientMessage": "Please sign the below items and get them back to me as soon as possible. The sooner we get these signed, the sooner we can get your money. -Ben",
        "clientCustomerName": "Dan Enache",
        "clientCustomerEmail": "calin121@gmail.com",
        "pages":
            [
                {
                    "pageId": "page1",
                    "imageUrl": "../../../assets/images/rule-of-thumb.png",
                    "metadata": {
                        "width": "600px",
                        "heigth": "514px",
                        "requestInputs": [
                            {
                                "inputId": "input1",
                                "userId": "",
                                "type": "initials",
                                "x": "120px",
                                "y": "312px",
                                "height": "120px",
                                "width": "140px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            },
                            {
                                "inputId": "input2",
                                "userId": "",
                                "type": "signature",
                                "x": "420px",
                                "y": "422px",
                                "height": "90px",
                                "width": "160px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            },
                            {
                                "inputId": "input3",
                                "userId": "",
                                "type": "text",
                                "x": "120px",
                                "y": "122px",
                                "height": "30px",
                                "width": "160px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "Does this work?",
                            },
                            {
                                "inputId": "input4",
                                "userId": "",
                                "type": "name",
                                "x": "10px",
                                "y": "12px",
                                "height": "30px",
                                "width": "180px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "Does this work?",
                            }
                        ]
                    }
                },
                {
                    "pageId": "page2",
                    "imageUrl": "../../../assets/images/lola.jpg",
                    "metadata": {
                        "width": "1918px",
                        "heigth": "1077px",
                        "requestInputs": [
                            {
                                "inputId": "input1",
                                "userId": "",
                                "type": "signature",
                                "x": "1520px",
                                "y": "512px",
                                "height": "90px",
                                "width": "160px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            },
                            {
                                "inputId": "input2",
                                "userId": "",
                                "type": "initials",
                                "x": "1220px",
                                "y": "322px",
                                "height": "80px",
                                "width": "130px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            }
                        ]
                    }
                },
                {
                    "pageId": "page3",
                    "imageUrl": "../../../assets/images/documentImage.jpg",
                    "metadata": {
                        "width": "700px",
                        "heigth": "906px",
                        "requestInputs": [
                            {
                                "inputId": "input1",
                                "userId": "",
                                "type": "signature",
                                "x": "320px",
                                "y": "512px",
                                "height": "90px",
                                "width": "160px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            },
                            {
                                "inputId": "input2",
                                "userId": "",
                                "type": "date",
                                "x": "120px",
                                "y": "322px",
                                "height": "40px",
                                "width": "120px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            },
                            {
                                "inputId": "input3",
                                "userId": "",
                                "type": "email",
                                "x": "20px",
                                "y": "422px",
                                "height": "40px",
                                "width": "160px",
                                "inputed": false,
                                "inputImage": "",
                                "value": "",
                            }
                        ]
                    }
                }
            ]
    };
    public document2: DocumentToSign = {
        "documentId": "1234",
        "clientCoName": "VideoNet",
        "clientEmployeeName": "Ben Leishman",
        "clientEmployeeEmail": "ben@leishman.co",
        "clientMessage": "Please sign the below items and get them back to me as soon as possible. The sooner we get these signed, the sooner we can get your money. -Ben",
        "clientCustomerName": "Dan Enache",
        "clientCustomerEmail": "calin121@gmail.com",
        "pages":
            [
                {
                    "pageId": "page1",
                    "imageUrl": "../../../assets/images/AssignContract.png",
                    "metadata": {
                        "width": "789px",
                        "heigth": "991px",
                        "requestInputs": [
                            // {
                            //   "inputId": "input3",
                            //   "userId": "",
                            //   "height": "60px",
                            //   "inputId": "input0",
                            //   "inputImage": "../../../assets/images/initialsImage.png",
                            //   "type": "signature",
                            //   "width": "60px",
                            //   "x": "340px",
                            //   "y": "340px"
                            // }
                        ]
                    }
                },
                {
                    "pageId": "page2",
                    "imageUrl": "../../../assets/images/consultingContract.jpg",
                    "metadata": {
                        "width": "791px",
                        "heigth": "1024px",
                        "requestInputs": [
                        ]
                    }
                },
                {
                    "pageId": "page3",
                    "imageUrl": "../../../assets/images/employmentContract.png",
                    "metadata": {
                        "width": "728px",
                        "heigth": "942px",
                        "requestInputs": [
                        ]
                    }
                }
            ]
    }

    public realUser = {
        "Key": "99999",
        "UserId": "444EF335-EEF8-42B1-B686-5F3A35E258E4",
        "Tenant": "444EF335-EEF8-42B1-B686-5F3A35E258E4",
        "Username": "aaa444.bbb444",
        "DisplayName": "aaa444 bbb444",
        "DisplayLocation": "NULL",
        "Hash": "NULL",
        "LastActivity": "2018-01-02 01:34:38.5300107",
        "LastNudged": "NULL",
        "Status": "2",
        "Note": "NULL",
        "AfkNote": "NULL",
        "IsAfk": "0",
        "Flag": "NULL",
        "IsAdmin": "0",
        "IsBanned": "0",
        "RawPreferences": "NULL",
        "InVideoCall": "0",
        "InScreenShare": "0",
        "Phone": "NULL",
        "Title": "NULL",
        "Organization": "NULL",
        "Department": "NULL",
        "AllowFileStorageNotifications": "1",
        "AllowDirectMessageNotifications": "1",
        "AllowMentionsNotifications": "1",
        "AllowUnreadChannelMessageNotifications": "1",
        "AllowMissedCallNotifications": "1",
        "SendEmailInterval": "15",
        "NotificationKeywords": "NULL",
        "ThemeName": "NULL",
        "PromoCode": "NULL"
    };
}
