import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "faxFolderName",
})

export class FaxFolderNamePipe implements PipeTransform {
    transform(value: string, args?: any): any {
        if (value === "/") {
            return "My Faxes";
        } else {
            return value;
        }
    }
}