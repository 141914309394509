import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatPhoneNumber",
})

export class FormatPhoneNumberPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        var cleaned = ('' + value).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return [match[2], '-', match[3], '-', match[4]].join('');
        }
        return null;
    }
}
