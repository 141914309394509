import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MessageSidebarComponent } from './message-sidebar/message-sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { MessageSidebarSelectedComponent } from './message-sidebar-selected/message-sidebar-selected.component';
import { MessageComposeComponent } from './message-compose/message-compose.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    MessageSidebarComponent,
    MessageComposeComponent,
    MessageSidebarSelectedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
      MessageSidebarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessageAppModule { }
