export class ToastNotification {
    type: ToastNotificationType;
    infoType: ToastInfoType;
    warningType: ToastWarningType;
    message: string;
    name: string;
    data: string;
    checkboxMessage: string;
    checkboxConfirm: boolean;
    disableTimeOut: boolean;
    disableSound: boolean;
}

export enum ToastNotificationType {
    Success,
    Error,
    Info,
    Warning
}

export enum ToastInfoType {
    Default,
    User,
    Enter,
    Leave,
    File
}

export enum ToastWarningType {
    Default,
    Continue,
    ContinueLink
}