<div *ngIf="!isLoadingNdiaEvents" class="ndia-events flex-col">
    <!--  -->
    <div class="events-title flex align-center pb-4 px-4">
        <div class="flex-col">
            <strong class="flex">Organization:<div class="text-ellipsis ms-2">Net Inclusion 2022</div></strong>
            <div class="flex align-center">
                <h3 class="title-thin">NDIA full schedule</h3>
                <small class="secondary-text text-bold ms-3 mt-1">&bull; by day</small>
            </div>
        </div>
    </div>
    <!--  -->
    <div 
        class="ndia-container scrollbar-new mx-auto" 
        [@listAnimationWrap]="ndiaEvents.length"
        id="ndiaScrollbox">
        <!--  -->
        <ng-container *ngFor="let schedule of ndiaEvents; index as dayIdx; first as first; even as even; odd as odd">
            <!--  -->
            <div 
                @listAnimationItem 
                class="ndia-day-group mb-4"
                [class.mt-2]="first"
                [class.mt-4]="!first">
                <!--  -->
                <div 
                    class="day px-1 pt-4 pb-2">
                    <!--  -->
                    <div class="flex title-wrap">
                        <div class="flex-col text-ellipsis">
                            <h3 class="title-thin text-ellipsis">{{schedule.day}}</h3>
                            <strong class="day-event-len ps-2">
                                &bull;
                                <ng-container>{{schedule.events.length}} Events</ng-container>
                            </strong>
                        </div>
                    </div>
                    <div class="events-wrap flex-row-wrap" @listAnimationWrapLong>
                        <!--  -->
                        <ng-container *ngFor="let meeting of schedule.events; index as eventIdx; even as even">
                            <!--  -->
                            <!-- 🚧 meeting card -->
                            <div 
                                @listAnimationItemLong 
                                class="event-container my-3 mx-1">
                                <!--  -->
                                <!-- meeting in progress bar -->
                                <div 
                                    @fadeInFast
                                    class="meeting-in-progress-bar ps-3"
                                    *ngIf="meeting.start + 'Z' <= todaysCurrentDateTime && !meeting.isCanceled">
                                    <strong> <i class="fas fa-clock me-1"></i>In progress </strong>
                                </div>
                                <!-- meeting options button ... only meeting CREATOR can edit/cancel & if isCancled then anyone can remove from there list -->
                                <button 
                                    type="button"
                                    (wheel)="prevDefStopProp($event)"
                                    (click)="showMeetingOptionsDropdown(dayIdx, eventIdx)"
                                    (mouseleave)="hideMeetingOptionsDropdown(dayIdx, eventIdx)"
                                    class="meeting-options-btn pe-1">
                                    <!-- icon -->
                                    <i 
                                        matTooltip="meeting options" 
                                        matTooltipClass="tooltip-default" 
                                        class="fas fa-ellipsis-v"></i>
                                    <!-- options dropdown -->
                                    <div id="meeting-options-dropdown-{{dayIdx}}-{{eventIdx}}" class="meeting-options-dropdown mt-1 py-2">
                                        <!-- edit meeting -->
                                        <!-- <ng-container
                                            *ngIf="
                                                meeting.chatUserId === userInfo.channelUserId &&
                                                meeting.title !== 'quick meeting' &&
                                                !meeting.isCanceled">
                                            <button 
                                                type="button"
                                                (click)="editMeeting(meeting.meetingId, meeting.channelKeyNavigation.type)"
                                                class="list-item"
                                            >
                                                <i class="far fa-edit edit-icon"></i>Edit meeting
                                            </button>
                                        </ng-container> -->
                                        <!-- cancel meeting -->
                                        <!-- <ng-container
                                            *ngIf="
                                                meeting.chatUserId === userInfo.channelUserId &&
                                                !meeting.isCanceled">
                                            <button 
                                                type="button"
                                                class="list-item"
                                                (click)="
                                                    cancelMeetingPrompt(
                                                        meeting.meetingId,
                                                        meeting.chatRoomId, i)">
                                                <i class="far fa-calendar-times calendar-icon"></i>Cancel meeting
                                            </button>
                                        </ng-container> -->
                                        <!-- hide meeting -->
                                        <!-- <ng-container *ngIf="meeting.isCanceled">
                                            <button 
                                                type="button"
                                                class="list-item"
                                                (click)="removeMeeting(meeting.meetingId, i)">
                                                <ng-container *ngIf="!meeting.isCancelingOrRemoving || meeting.isCancelingOrRemoving === undefined">
                                                    <i class="far fa-trash-alt trash-icon"></i>Remove meeting
                                                </ng-container>
                                                <ng-container *ngIf="meeting.isCancelingOrRemoving">
                                                    <i class="far fa-spinner spinner"></i>Removing...
                                                </ng-container>
                                            </button>
                                        </ng-container> -->
                                        <!-- meeting info | only if !creator (can still see info if canceled)-->
                                        <ng-container>
                                            <button 
                                                type="button"
                                                (click)="showMeetingInfoModal(dayIdx, eventIdx)" class="list-item">
                                                <i class="far fa-info-circle info-icon"></i>Meeting info
                                            </button>
                                        </ng-container>
                                    </div>
                                </button>
                                <!-- meeting card body [moded for viewer]-->
                                <!-- 
                                    [class.disabled]="meeting.isCanceled"
                                    !meeting.isCanceled && !this.incomingChannelObject ? 
                                    (click)="!meeting.isCanceled && this.incomingChannelObject ? joinChannelCall($event) : null"
                                    
                                -->
                                <a 
                                    (dragstart)="prevDefStopProp($event)"
                                    class="flex-row-nowrap meeting-card px-3"
                                    [routerLink]="['/channel', meeting.chatRoomId]"
                                    matTooltip="{{
                                            'ENTER : ' + (meeting.title | uppercase)
                                    }}"
                                    matTooltipClass="{{
                                            'tooltip-meeting-card'
                                    }}"
                                    matTooltipPosition="below"
                                >
                                    <ng-container *ngIf="!meeting.isCancelingOrRemoving">
                                        <!-- start date -->
                                        <div class="meeting-date flex-col pe-3">
                                            <strong class="text-ellipsis text-center">
                                                {{meeting.start + "Z" | date: "EEE, MMM."}}
                                            </strong>
                                            <h1 class="text-center">
                                                {{ meeting.start + "Z" | date: "d" }}
                                            </h1>
                                        </div>
                                        
                                        <div class="meeting-right flex-col ps-2">
                                            <!-- start time -->
                                            <div class="meeting-time">
                                                <strong>{{
                                                    meeting.start + "Z" | date: "hh:mm a"
                                                }}</strong>
                                            </div>
                                            <!-- meeting title -->
                                            <p class="text-ellipsis mt-1">
                                                <!-- {{ meeting.title | titlecase }} -->
                                                {{ meeting.title }}
                                            </p>
                                            <!-- meeting body (topic) -->
                                            <small
                                                *ngIf="meeting.title !== 'quick meeting'"
                                                class="text-ellipsis"
                                                >{{ meeting.body || '&nbsp;' }}</small
                                            >
                                            <small
                                                *ngIf="meeting.title === 'quick meeting'"
                                                class="text-ellipsis no-line"
                                                >&nbsp;</small
                                            >
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="meeting.isCancelingOrRemoving">
                                        <i class="fas fa-spinner spinner m-auto secondary-text canceling-removing-loader"></i>
                                    </ng-container>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>


<ng-container *ngIf="isLoadingNdiaEvents">
    <div class="flex w-100 h-100">
        <div class="m-auto">
            <loading-home></loading-home>
        </div>
    </div>
</ng-container>



<!-- meeting info modal -->
<div
    *ngIf="displayMeetingInfoModal"
    class="modal"
    id="meeting-info-modal"
    (click)="clickOffScheduleModalEvent($event)">
    
    <div 
        class="modal-container"
        (document:keydown.escape)="closeMeetingInfoModal()">
        <!-- new close modal ... -->
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeMeetingInfoModal()">
            <i class="fas fa-times"></i>
        </button>
        <meeting-info 
            [meetingInfo]="selectedMeeting">
        </meeting-info>
    </div>
</div>
