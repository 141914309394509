import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FaxAppRoutingModule } from './fax-app-routing.module';
import { FaxHomepageComponent } from './fax-homepage/fax-homepage.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    FaxHomepageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FaxAppRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    FaxHomepageComponent
  ]
})
export class FaxAppModule { }
