import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth.service";
import { ChannelService } from "../../../services/channel-service";



@Component({
    selector: "connection-overlay",
    templateUrl: "./connection-overlay.component.html",
    styleUrls: ["./connection-overlay.component.scss"],
})
export class ConnectionOverlayComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];
    public connectionStatus: any = "connecting";
    public showOverlay: boolean = false;
    public _isSignedIn: boolean;

    constructor(
            private _channelService: ChannelService,
            private _auth: AuthService
        ) {
        this._auth.isSignedIn(false).then(signedIn => {this._isSignedIn = signedIn});
        this.subscriptions.push(
            this._channelService.onHubConnectionStateChangeEvent$.subscribe(
                (state: any) => {
                    // console.log("app component state: " + state);
                    this.connectionStatus = state;
                    if (state.toLowerCase() === "disconnected" && this._isSignedIn) {
                        this.showOverlay = true;
                        //   document.getElementById("connection-overlay").style.display = "flex";
                    } else {
                        //   document.getElementById("connection-overlay").style.display = "none";
                        this.showOverlay = false;
                    }
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
