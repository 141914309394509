export const ConstantsLocal = {
    // **** Local
    // ** If testing other apps locally as well, switch to localHost urls
    // ** All but this repo's application is being tested using the dev environment
    clientId: 'unity-web-client',
    wwwSite: 'https://localhost:44306/',
    // accountSite: 'https://localhost:44301/',
    accountSite: 'https://account.dev-via3.com/',
    //adminSite: 'https://localhost:44302/',
    adminSite: 'https://admin.dev-via3.com/',
    unitySite: 'https://localhost:44304/',
    // signalRSite: 'https://localhost:44309/',
    signalRSite: 'https://wsapi.dev-via3.com/',
    clientRoot: 'https://localhost:44304/',
    // apiRoot: 'https://localhost:44303/',
    apiRoot:'https://apicore.dev-via3.com/',
    // stsAuthority: 'https://localhost:44305/',
    stsAuthority: 'https://idp.dev-via3.com/',
    gettingStartedSite: 'https://www.via3.com/getting-started/',
    isProd: false,
    directMessageProjId : "9052af8d-dbf5-411d-a6c4-48c27a4e8e2f",
    quickMeetingProjId : "11ee030f-d0d0-450c-a6b1-976e52d11f97",
    jitsiDomain : 'meet.dev-via3.com',
    version: '0.0.2',
    publishDate: '0.0.1'
};

export const ConstantsRuntime = {
    clientId: '#{client-id}#',
    wwwSite: '#{www-site}#',
    accountSite: '#{account-site}#',
    adminSite: '#{admin-site}#',
    unitySite: '#{unity-site}#',
    gettingStartedSite: '#{getting-started-site}#',
    signalRSite: '#{signalR-site}#',
    clientRoot: '#{client-root}#',
    apiRoot: '#{api-root}#',
    stsAuthority: '#{sts-authority}#',
    isProd: '#{is-prod}#',
    directMessageProjId : '#{directMessage-proj-id}#',
    quickMeetingProjId : '#{quick-meeting-proj-id}#',
    jitsiDomain : '#{jitsi-domain}#',
    version: '#{buildVersion}#',
    publishDate: '#{releaseVersion}#',
};

export const Constants = (window.location.href.indexOf('localhost:') > -1 ? ConstantsLocal : ConstantsRuntime);
