import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'compareStrings'
})
export class CompareStringsPipe implements PipeTransform {

    transform(string1: string, string2: string): boolean {
        if (string1 === string2) {
            return true;
        } else {
            return false;
        };
    }
}
