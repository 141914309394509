import { 
    Component, 
    Inject, 
    Input, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    SimpleChanges, } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { 
    listAnimationWrapLong, 
    listAnimationItemLong, 
    fadeIn, 
    listAnimationWrapAvatar, 
    listAnimationItemAvatar } from "src/app/animations/global-animations";
import { ChannelUser } from "../../interface/channel-user";
import { consoleLogStyle } from '../../varaibles';
import { ChannelCard } from "../../interface/channel-card";
import { ChannelService } from "src/app/services/channel-service";


@Component({
    selector: "channels-history",
    templateUrl: "./channels-history.component.html",
    styleUrls: ["./channels-history.component.scss"],
    animations: [ 
        listAnimationWrapLong, 
        listAnimationItemLong, 
        fadeIn, 
        listAnimationWrapAvatar, 
        listAnimationItemAvatar ]
})
export class ChannelsHistoryComponent implements OnInit, OnChanges, OnDestroy {
    
    @Input("userInfo") userInfo: ChannelUser;
    @Input('isViewer') isViewer: boolean;

    public isLoadingChannels: boolean = true;
    public channelHistory: Array<ChannelCard> = [];
    // public isLoadingMoreChannels: boolean;
    // public channelHistoryPag: any = {};

    public displayChannelInfoModal: boolean = false;
    
    private subscriptions: Subscription[] = [];
    
    public displayScheduleModal: boolean = false;
    public displayScheduleWarning: boolean = false;
    public scheduleChannelMeetingObject: any = null;
    
    public displayCreateChannelModal: boolean = false;
    public displayCreateChannelWarning: boolean = false;
    
    public displayManageUsersModal: boolean = false;
    public selectedChannelId: string = null;


    constructor(
        private _apiCallsService: ApiCallsService,
        public _channelService: ChannelService,
        private _toastService: ToastNotificationService,
        @Inject('rippleColor') public rippleColor: string,
        
    ) {
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "closeScheduleModal") {
                this.closeScheduleModal();
            } else if (data.type === "closeCreateChannelModal") {
                this.closeCreateChannelModal();
            }
        }));
    }
    
    ngOnInit(): void {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            if (!!this.userInfo) {
                // console.log("%c 👤 user info input changes CHANNELS ", consoleLogStyle, this.userInfo);
                this._getChannelHistoryForUser();
            }
        }
    }
    
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    _getChannelHistoryForUser() {
        this.isLoadingChannels = true;
        // console.log("%c api call with userinfo CHANNELS ", consoleLogStyle, this.userInfo);
        this._apiCallsService.getChannelHistoryForChannelUserOnly6(this.userInfo.channelUserId)
            .subscribe(data => {
                if(data.status === 200) {
                    this.channelHistory = data.body;
                    // console.log("%c channel history CHANNELS ", consoleLogStyle, this.channelHistory);
                    // this.channelHistoryPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c channel history CHANNELS pagination ? ", consoleLogStyle, this.channelHistoryPag);
                    this.isLoadingChannels = false;
                } else {
                    // console.error("error getting channel history ...", data)
                    this.isLoadingChannels = false;
                    this._toastService.error("There was an error getting channel history.");
                }
            });
    }

    // 💡 infinite scrolling w/ Intersection Observer api ... [(@listAnimationItemLong.done)="last ? runPaginationObserver() : ''"] 
    // 🌐 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API 
    // paginationObserver() {
    //     let lastEl = <HTMLElement>document.getElementById('LastChannel'); // grab last El in list [after animation done & is in DOM] 
    //     // console.log("%c List anime done: run intersect observer 🎯 LAST El: ", consoleLogStyle, lastEl)
    //     if(!!lastEl) {
    //         let observer = new IntersectionObserver(entries => {
    //             entries.forEach(entry => { // can observe multiple El's but will always be an Array of [entries] 
    //                 // console.log("%c is 🎯 El intersecting? ", consoleLogStyle, entry.isIntersecting)
                    
    //                 // || entry.intersectionRatio > 0 - also works
    //                 if (entry.isIntersecting && entry.intersectionRatio >= 0) { // bottom of view intersecting W/ the top of El 
    //                     // console.log('%c 🎯 El is IN view run get next page! ', consoleLogStyle);
                        
    //                     this._getMoreChannelHistory(this.channelHistoryPag.nextPageLink);
                        
    //                     observer.unobserve(entry.target); // stop tracking this El, will grab next LastEl 
    //                 } else {
    //                     // console.log('%c 🎯 El OUT of view. ',consoleLogStyle);
    //                 }
    //             });
    //         });
    //         observer.observe(lastEl); // track the last El in list 
    //     } else {
    //         console.info("no last EL?", lastEl);
    //     }
    // }

    // _getMoreChannelHistory(link:any) { // returns 8 results ... 2 page len avg. 
    //     this.isLoadingMoreChannels = true;
    //     this._apiCallsService.getGenericForPagination(link)
    //         .subscribe(data => {
    //             if(data.status === 200) {
    //                 this.channelHistory = this.channelHistory.concat(data.body);
    //                 this.channelHistoryPag = JSON.parse(data.headers.get('X-Pagination'));
    //                 // console.log("%c MORE channel history CHANNELS", consoleLogStyle, this.channelHistory);
    //                 // console.log("%c channel history CHANNELS pagination ? ", consoleLogStyle, this.channelHistoryPag);
    //                 this.isLoadingMoreChannels = false;
    //             } else {
    //                 console.error("error getting channel history ...")
    //                 this._toastService.error("There was an error getting channel history.");
    //                 this.isLoadingMoreChannels = false;
    //             }
    //         });
    // }

    // utils 
    prevDefStopProp(e: Event) {
        // console.log('oi')
        e.preventDefault();
        e.stopPropagation();
    }

    // this will close each modal 
    clickOffChannelModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'channel-info-modal': {
                this.closeChannelInfoModal();
                break;
            }
            case 'manage-users-modal': {
                this.closeManageUsersModal();
                break;
            }
            case 'create-channel-modal': {
                if (this.displayCreateChannelWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateChannelModal");
                } else {
                    this.closeCreateChannelModal();
                }
                break;
            }
            case 'schedule-modal': {
                if (this.displayScheduleWarning) {
                    this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
                } else {
                    this.closeScheduleModal();
                }
                break;
            }
            default: return;
        }
    }

    // create channel modal
    showCreateChannelModal() {
        this.displayCreateChannelModal = true;
    }
    closeCreateChannelModal() {
        this.displayCreateChannelModal = false;
        this.displayCreateChannelWarning = false;
    }
    closeCreateChannelModalConfirm() {
        // console.log("esc pressed")
        if(this.displayCreateChannelWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeCreateChannelModal");
        } else {
            this.closeCreateChannelModal();
        }
    }

    // channel info modal
    showChannelInfoModal(channelId:string) {
        this.selectedChannelId = channelId;
        this.displayChannelInfoModal = true;
    }
    closeChannelInfoModal() {
        this.displayChannelInfoModal = false;
        this.selectedChannelId = null;
    }

    // manage users modal ... 
    showManageUsersModal(channelId:string) {
        this.selectedChannelId = channelId;
        this.displayManageUsersModal = true;
    }
    closeManageUsersModal() {
        this.displayManageUsersModal = false;
        this.selectedChannelId = null;
    }

    // schedule meeting Modal
    showScheduleModal(channelObj) {
        // let channelObj = {
        //     channelId,
        //     name: channelName
        // };
        this.scheduleChannelMeetingObject = channelObj;
        this.displayScheduleModal = true;
    }
    closeScheduleModal() {
        this.displayScheduleWarning = false;
        this.displayScheduleModal = false;
        this.scheduleChannelMeetingObject = null;
    }
    closeScheduleModalConfirm() {
        if(this.displayScheduleWarning) {
            this._toastService.continue("You are about lose all progress, are you sure you want to close this form?", "closeScheduleModal");
        } else {
            this.closeScheduleModal();
        }
    }


    // 🚧 Channel history options dropdown ... 
    showChannelOptionsDropdown(i: number) {
        const options = document.getElementById(`channel-options-dropdown-${i}`);
        options.classList.add('active');
        
        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('channelScrollbox');
        scrollBox.classList.add('hidden');
    }

    hideChannelOptionsDropdown(i: number) {
        const options = document.getElementById(`channel-options-dropdown-${i}`);
        options.classList.remove('active');
        
        let scrollBox = <HTMLElement>document.getElementById('channelScrollbox');
        scrollBox.classList.remove('hidden');
    }


    // 🚧 favorite channel 
    favoriteChannel(e: Event, channelHistoryId:string, channelIdx: number) {
        this.prevDefStopProp(e);
        if(!!channelHistoryId) {
            let patch = {
                "op": "replace",
                "path": "/isFavorite",
                "value": true
            };
            this._apiCallsService.patchChannelHistoryForChannelUser(channelHistoryId, patch)
                .subscribe(res => {
                    if(res.status === 204) {
                        // console.log("channel favorited")
                        this.channelHistory[channelIdx].isFavorite = true;
                        // this.sortByFavs();
                    } else {
                        // console.log("%c channel favorited patch ", consoleLogStyle, channelHistoryId)
                        this._toastService.error("There was an error favoriting this channel.");
                    }
                });
        } else {
            this.createHistoryForFavorite(channelIdx);
        }
    }

    createHistoryForFavorite(channelIdx:number) {
        let historyObj = {
            "chatUserId": this.userInfo.channelUserId,
            "chatRoomId": this.channelHistory[channelIdx].channelId,
            "isFavorite": true
        };
        this._apiCallsService.postChannelHistoryForChannelUser(historyObj)
            .subscribe(res => {
                if(res.status === 201) {
                    // console.log("channel favorited")
                    this.channelHistory[channelIdx].isFavorite = true;
                    // this.sortByFavs();
                } else {
                    // console.log("%c channel favorited create history ", consoleLogStyle, historyObj)
                    this._toastService.error("There was an error favoriting this channel.");
                }
            });
    }

    unFavoriteChannel(e: Event, channelHistoryId:string, channelIdx: number) {
        this.prevDefStopProp(e);
        let patch = {
            "op": "replace",
            "path": "/isFavorite",
            "value": false
        };
        this._apiCallsService.patchChannelHistoryForChannelUser(channelHistoryId, patch)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("channel un-favorited")
                    this.channelHistory[channelIdx].isFavorite = false;
                    // this.sortByFavs();
                } else {
                    this._toastService.error("There was an error un-favoriting this channel.")
                }
            });
    }

    // sortByFavs() {
    //     this.channelHistory = this.channelHistory.sort((a,b) => {
    //         return Number(b.isFavorite) - Number(a.isFavorite);
    //     });
    // }

    handleBrokenImage(channelIdx:number, userIdx:number) {
        // console.info('yeeeeee')
        this.channelHistory[channelIdx].allChannelUsers[userIdx].hash = '1';
    }
}
