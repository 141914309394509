// Main core modules 
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth.interceptor';


// Components 
import { AppComponent } from './app.component';
import { NavigationOverlayComponent } from './navigation-overlay/navigation-overlay.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SidebarOverlayComponent } from './sidebar-overlay/sidebar-overlay.component';

import { UnauthorizedComponent } from './home/unauthorized.component';
import { PageNotFoundComponent } from './home/pagenotfound.component'; // 🚧 needs to be implemented is 404 page not currently used ... 


// auth components (oidc) needs review ... 
import { SigninRedirectCallbackComponent } from './home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './home/signout-redirect-callback.component';

// ❌ App modules (unused) needs review & possible removal ... 🧹 
// import { DocumentEditorAppModule } from './document-editor-app/document-editor-app.module';
// import { DocumentSigningModule } from './document-signing/document-signing.module';
// import { DocumentViewModule } from './document-view/document-view.module';
// import { FormBuilderModule } from './form-builder/form-builder.module';
// import { AppStoreAppModule } from './app-store-app/app-store-app.module';
// import { AttorneyAppModule } from './attorney-app/attorney-app.module';
// import { SaltLakeLegalAppModule } from './salt-lake-legal-app/salt-lake-legal-app.module';
// import { LiveStreamAppModule } from './live-stream-app/live-stream-app.module';
// import { VideoOnDemandAppModule } from './video-on-demand-app/video-on-demand-app.module';

// import { SupportAppModule } from './support/support-app.module'; // ❔ ... not sure if working ... 


// App modules 
import { ChannelAppModule } from './channel-app/channel-app.module';
import { MessageAppModule } from './message-app/message-app.module';
import { UserAppModule } from './user-app/user-app.module';
import { FaxAppModule } from './fax-app/fax-app.module';
import { FileStorageAppModule } from './file-storage-app/file-storage-app.module';
import { ToastNotificationModule } from './toast-notification/toast-notification.module';
import { VdjjVideoIntakeAppModule } from './vdjj-video-intake-app/vdjj-video-intake-app.module';
import { JitsiCallManagerComponent } from './jitsi-call-manager/jitsi-call-manager.component';


// 💡 all pipes & mat- are exported from shared mod, shared mod is imported here 
import { SharedModule } from './shared/shared.module';


// new examples 
import { ElementExamplesComponent } from './element-examples/element-examples.component';

// 🎉 FOR CONFERENCE 
import { LoginConferenceComponent } from './login-conference/login-conference.component';
import { ConferenceFullScheduleComponent } from './conference-full-schedule/conference-full-schedule.component';

// 💡 new pwa module ...
import { ServiceWorkerModule } from '@angular/service-worker';

// new pages ... 
import { EventsAppModule } from './events-app/events-app.module';
import { ContactsAppModule } from './contacts-app/contacts-app.module';

import { Platform } from '@angular/cdk/platform';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    NavigationOverlayComponent,
    ElementExamplesComponent,
    LoginConferenceComponent,
    SidebarOverlayComponent,
    ConferenceFullScheduleComponent,
    JitsiCallManagerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ChannelAppModule,
    MessageAppModule,
    VdjjVideoIntakeAppModule,
    UserAppModule,
    FileStorageAppModule,
    FaxAppModule,
    ToastNotificationModule,
    EventsAppModule,
    ContactsAppModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _platform: Platform) {
    // 💡 only register service worker if PWA ... 
    // need to check if window in standalone here ... 
    if(!this._platform.isBrowser) {
      // console.info("---- ::: >> not running in a browser ... regisering service worker ---- ::: >>", this._platform)
      ServiceWorkerModule.register('./ngsw-worker.js', {
        // 💡 removed env here for testing ... [environment.production]
        enabled: true,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        
        // registrationStrategy: 'registerWhenStable:30000' //
        registrationStrategy: 'registerImmediately'
      });
    } 
    // else {
    //   // console.info("---- ::: >> IS running in a browser ... NOT regisering service worker ---- ::: >>", this._platform)
    // }
  }
}
