import {
    Pipe,
    PipeTransform,
    NgZone,
    ChangeDetectorRef,
    OnDestroy,
} from "@angular/core";
import { consoleLogStyle } from "../shared/varaibles";

@Pipe({
    name: "timeAgo",
    pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private timer: number;
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}
    transform(value: string, isCountDown?:boolean): string {
        this.removeTimer();
        let d = new Date(value);
        // console.info("%c time ago value??? ", consoleLogStyle, d)
        let now = new Date();
        let seconds = Math.round(
            Math.abs((now.getTime() - d.getTime()) / 1000)
        );
        let timeToUpdate = Number.isNaN(seconds)
            ? 1000
            : this.getSecondsUntilUpdate(seconds) * 1000;
        this.timer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== "undefined") {
                return window.setTimeout(() => {
                    this.ngZone.run(() =>
                        this.changeDetectorRef.markForCheck()
                    );
                }, timeToUpdate);
            }
            return null;
        });
        let minutes = Math.round(Math.abs(seconds / 60));
        let hours = Math.round(Math.abs(minutes / 60));
        let days = Math.round(Math.abs(hours / 24));
        let months = Math.round(Math.abs(days / 30.416));
        let years = Math.round(Math.abs(days / 365));
        if (Number.isNaN(seconds)) {
            return "";
        } else if (seconds <= 59) {
            return isCountDown ? seconds + " seconds" : "now";
        } else if (seconds >= 60 && minutes <= 59) {
            return isCountDown ? minutes + " minutes" : minutes + "m";
        } else if (minutes >= 60 && hours <= 23) {
            return isCountDown ? hours + " hours" : hours + "h";
        } else if (hours >= 24 && months <= 0) {
            return isCountDown ? days + " days" : days + "d";
        } else if (months >= 1 && months <= 11) {
            return isCountDown ? months + " months" : months + "mo";
        } else if (months >= 12) {
            return isCountDown ? years + " years" : years + "y";
        }
    }
    ngOnDestroy(): void {
        this.removeTimer();
    }
    private removeTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }
    private getSecondsUntilUpdate(seconds: number) {
        let min = 60;
        let hr = min * 60;
        let day = hr * 24;
        if (seconds < min) {
            // less than 1 min, update every 1 secs
            return 1;
        } else if (seconds < hr) {
            // less than an hour, update every 30 secs
            return 30;
        } else if (seconds < day) {
            // less then a day, update every 5 mins
            return 300;
        } else {
            // update every hour
            return 3600;
        }
    }
}
