<div class="unauth-page flex">
    <div class="modal">
        <div class="modal-container">
            <div 
                *ngIf="!channelMessage" 
                class="no-channel-message">
                <h3 class="title-thin mb-4">
                    <i class="fas fa-do-not-enter me-2"></i>You may not have access to this area.
                </h3>
                <h4>Contact your administrator to get access.</h4>
                <button 
                    class="primary-button mt-4" 
                    type="button" 
                    (click)="goHome()">
                    Go Back
                </button>
            </div>
            <div 
                *ngIf="channelMessage" 
                class="channel-message">
                <h3 class="title-thin mb-4"><i class="fas fa-do-not-enter"></i></h3>
                <h3 class="title-thin mb-4">
                    You do not have access to this channel.
                </h3>
                <h4>Contact your administrator to get access.</h4>
                <button 
                    class="primary-button mt-4" 
                    type="button" 
                    (click)="goHome()">
                    Go Home
                </button>
            </div>
        </div>
    </div>
</div>

