<!-- parent is: 'flex-col-center-all home-main-col' -->
<div class="events flex-col px-4">
	<!-- curr date -->
	<strong *ngIf="!incomingChannelObject"
		>Today: &nbsp;{{ todaysCurrentDateTime | date: "MMMM dd, YYYY" }}</strong
	>
	<!-- title -->
	<h3 class="title-thin">
		{{ incomingChannelObject ? "Channel Events" : "Upcoming Events" }}
	</h3>
	<!-- create meeting btn -->
	<button
		type="button"
		(click)="showNewMeetingModal()"
		*ngIf="!incomingChannelObject && !isViewer"
		class="primary-button my-3 meeting-button"
	>
		<i class="fas fa-plus me-3"></i>New Meeting
	</button>
	<!-- NDIA all events -->
	<button
		routerLink="/NDIA-full-schedule"
		*ngIf="!incomingChannelObject && isNDIATenant"
		class="primary-button my-4 meeting-button"
	>
		<i class="far fa-calendar-alt me-3"></i>NDIA Full Schedule
	</button>
	<!--  -->
	<ng-container *ngIf="displayNewMeetingModal">
		<!-- schedule meeting -->
		<div class="mini-modal flex-col-center-all p-3">
			<button
				type="button"
				(click)="showScheduleModal(null, null)"
				class="primary-button"
			>
				Schedule a meeting
			</button>
			<!-- start quick meeting -->
			<button
				type="button"
				(click)="!isCreatingQuick ? createQuickMeet() : ''"
				class="secondary-button mt-2"
			>
				<ng-container *ngIf="!isCreatingQuick"> Start a meeting now </ng-container>
				<ng-container *ngIf="isCreatingQuick">
					<i class="fas fa-spinner spinner"></i>&nbsp;Creating meeting
				</ng-container>
			</button>
		</div>
	</ng-container>
	<!-- meeting list -->
	<div
		[@listAnimationWrapLong]="meetings.length"
		class="meetings-wrap flex-col mt-2 pb-4 scrollbar-new"
		[class.extra-padding]="incomingChannelObject"
		id="eventScrollbox"
	>
		<ng-container *ngFor="let meeting of meetings; index as i; last as last">
			<!-- meeting card -->
			<div
				[class.in-progress]="
					meeting.start + 'Z' <= todaysCurrentDateTime && !meeting.isCanceled
				"
				class="meetings-container mb-3"
				@listAnimationItemLong
				id="{{ last && meetingsPag.nextPageLink ? 'LastMeeting' : '' }}"
				(@listAnimationItemLong.done)="
					last && meetingsPag.nextPageLink ? paginationObserver() : ''
				"
			>
				<!-- meeting in progress bar -->
				<div
					@fadeInFast
					class="meeting-in-progress-bar ps-3"
					*ngIf="meeting.start + 'Z' <= todaysCurrentDateTime && !meeting.isCanceled"
				>
					<strong><i class="fas fa-clock me-1"></i>In progress </strong>
				</div>
				<!-- meeting options button ... only meeting CREATOR can edit/cancel & if isCancled then anyone can remove from there list -->
				<button
					type="button"
					(wheel)="prevDefStopProp($event)"
					(click)="showMeetingOptionsDropdown(i)"
					(mouseleave)="hideMeetingOptionsDropdown(i)"
					class="meeting-options-btn pe-1"
				>
					<!-- icon -->
					<i
						matTooltip="meeting options"
						matTooltipClass="tooltip-default"
						class="fas fa-ellipsis-v"
					></i>
					<!-- options dropdown -->
					<div
						id="meeting-options-dropdown-{{ i }}"
						class="meeting-options-dropdown mt-1 py-2"
					>
						<!-- edit meeting -->
						<ng-container
							*ngIf="
								meeting.chatUserId === userInfo.channelUserId &&
								meeting.title !== 'quick meeting' &&
								!meeting.isCanceled
							"
						>
							<button
								type="button"
								(click)="
									editMeeting(meeting.meetingId, meeting.channelKeyNavigation.type)
								"
								class="list-item"
							>
								<i class="far fa-edit edit-icon"></i>Edit meeting
							</button>
						</ng-container>
						<!-- cancel meeting -->
						<ng-container
							*ngIf="
								meeting.chatUserId === userInfo.channelUserId && !meeting.isCanceled
							"
						>
							<button
								type="button"
								class="list-item"
								(click)="cancelMeetingPrompt(meeting.meetingId, meeting.chatRoomId, i)"
							>
								<i class="far fa-calendar-times calendar-icon"></i>Cancel meeting
							</button>
						</ng-container>
						<!-- meeting token -->
						<ng-container
							*ngIf="
								meeting.chatUserId === userInfo.channelUserId && !meeting.isCanceled
							"
						>
							<button
								type="button"
								class="list-item"
								(click)="
								meeting.start + 'Z' <= todaysCurrentDateTime && !meeting.isCanceled
									? showMeetingTokenModal(i, true)
									: showMeetingTokenModal(i, false)">
								<i class="fa-solid fa-link"></i>Invite Link
							</button>
						</ng-container>
						<!-- hide meeting -->
						<ng-container *ngIf="meeting.isCanceled">
							<button
								type="button"
								class="list-item"
								(click)="removeMeeting(meeting.meetingId, i)"
							>
								<ng-container
									*ngIf="
										!meeting.isCancelingOrRemoving ||
										meeting.isCancelingOrRemoving === undefined
									"
								>
									<i class="far fa-trash-alt trash-icon"></i>Remove meeting
								</ng-container>
								<ng-container *ngIf="meeting.isCancelingOrRemoving">
									<i class="far fa-spinner spinner"></i>Removing...
								</ng-container>
							</button>
						</ng-container>
						<!-- meeting info | only if !creator (can still see info if canceled)-->
						<ng-container
							*ngIf="
								meeting.chatUserId !== userInfo.channelUserId ||
								(meeting.chatUserId === userInfo.channelUserId && meeting.isCanceled) ||
								meeting.title === 'quick meeting'
							"
						>
							<button
								type="button"
								(click)="
									meeting.start + 'Z' <= todaysCurrentDateTime && !meeting.isCanceled
										? showMeetingInfoModal(i, true)
										: showMeetingInfoModal(i, false)
								"
								class="list-item"
							>
								<i class="far fa-info-circle info-icon"></i>Meeting info
							</button>
						</ng-container>
					</div>
				</button>
				<!-- meeting card body -->
				<a
					class="flex-row-nowrap meeting-card px-3"
					[class.disabled]="meeting.isCanceled"
					[routerLink]="
						!meeting.isCanceled && !incomingChannelObject
							? ['/channel', meeting.chatRoomId]
							: null
					"
					(click)="
						!meeting.isCanceled && incomingChannelObject && !preventEventClick
							? joinChannelCall($event)
							: null
					"
					matTooltip="{{
						!meeting.isCanceled
							? 'ENTER : ' + (meeting.title | uppercase)
							: 'CANCELED'
					}}"
					matTooltipClass="{{
						!meeting.isCanceled
							? 'tooltip-meeting-card'
							: 'tooltip-meeting-card-canceled'
					}}"
					matTooltipPosition="below"
				>
					<ng-container *ngIf="!meeting.isCancelingOrRemoving">
						<!-- start date -->
						<div class="meeting-date flex-col pe-3">
							<strong class="text-ellipsis text-center">
								{{ meeting.start + "Z" | date: "EEE, MMM." }}
							</strong>
							<h1 class="text-center">
								{{ meeting.start + "Z" | date: "d" }}
							</h1>
						</div>
						<div class="meeting-right flex-col ps-2">
							<!-- start time -->
							<div class="meeting-time">
								<strong>{{ meeting.start + "Z" | date: "hh:mm a" }}</strong>
								<small 
                                    *ngIf="!meeting.private"
                                    matTooltip="public" 
                                    matTooltipClass="tooltip-default">
                                    
                                    &nbsp; <i class="far fa-users"></i>
                                </small>
							</div>
							<!-- meeting title -->
							<p class="text-ellipsis mt-1">
								<!-- {{ meeting.title | titlecase }} -->
								{{ meeting.title }}
							</p>
							<!-- meeting body (topic) -->
							<small *ngIf="meeting.title !== 'quick meeting'" class="text-ellipsis">{{
								meeting.body || "&nbsp;"
							}}</small>
							<small
								*ngIf="meeting.title === 'quick meeting'"
								class="text-ellipsis no-line"
								>&nbsp;
							</small>
						</div>
					</ng-container>
					<ng-container *ngIf="meeting.isCancelingOrRemoving">
						<i
							class="fas fa-spinner spinner m-auto secondary-text canceling-removing-loader"
						></i>
					</ng-container>
				</a>
			</div>
		</ng-container>
		<!-- meetings loading -->
		<div
			class="loading-more-meetings flex justify-center mt-4"
			*ngIf="isLoadingMoreMeetings"
		>
			<i class="fas fa-spinner spinner m-auto"></i>
		</div>
		<!-- if 0 meetings returned -->
		<div
			class="no-meetings flex-row-nowrap-center-all mt-4"
			*ngIf="!isLoadingMeetings && !!meetings && meetings.length < 1"
		>
			<p class="text-center secondary-text">No upcoming events to display.</p>
		</div>
	</div>
	<button
		type="button"
		*ngIf="incomingChannelObject && !isViewer"
		(click)="showScheduleModal(null, null)"
		class="primary-button my-4 meeting-button-channel"
	>
		<i class="fas fa-plus me-3"></i>New Channel Meeting
	</button>
	<!-- NDIA all events; originally checked for isViewer -->
	<button
		routerLink="/NDIA-full-schedule"
		*ngIf="incomingChannelObject && isNDIATenant"
		class="primary-button my-4 meeting-button"
	>
		<i class="far fa-calendar-alt me-3"></i>NDIA Full Schedule
	</button>
	<!--  -->
	<div class="loading-meetings flex-center-all" *ngIf="isLoadingMeetings">
		<loading-home></loading-home>
	</div>
</div>

<!-- meeting button modal backdrop 
    [this is only modal with a seperate backdrop element, was made before global modal implementation] 
-->
<ng-container *ngIf="displayNewMeetingModal">
	<div
		(click)="closeNewMeetingModal()"
		(document:keydown.escape)="closeNewMeetingModal()"
		class="hidden-backdrop-modal"
	></div>
</ng-container>

<!-- scedule meeting modal -->
<div
	*ngIf="displayScheduleModal"
	class="modal"
	id="schedule-modal"
	(click)="clickOffScheduleModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeScheduleModalConfirm()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeScheduleModalConfirm()"
		>
			<i class="fas fa-times"></i>
		</button>
		<schedule-meeting
			[isEditChannel]="editMeetingType === 'channel' ? true : false"
			[isEditQuick]="editMeetingType === 'quick' ? true : false"
			[incomingMeetingId]="editMeetingId"
			[incomingChannelObject]="incomingChannelObject"
			(onCompleteEvent)="closeScheduleModal(true)"
			(formTouchedEvent)="displayScheduleWarning = $event"
		></schedule-meeting>
	</div>
</div>

<!-- meeting info modal -->
<div
	*ngIf="displayMeetingInfoModal"
	class="modal"
	id="meeting-info-modal"
	(click)="clickOffScheduleModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeMeetingInfoModal()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeMeetingInfoModal()"
		>
			<i class="fas fa-times"></i>
		</button>
		<meeting-info [meetingInfo]="selectedMeeting"> </meeting-info>
	</div>
</div>

<!-- meeting token modal -->
<div
	*ngIf="displayMeetingTokenModal"
	class="modal"
	id="meeting-token-modal"
	(click)="clickOffScheduleModalEvent($event)"
>
	<div
		class="modal-container"
		(document:keydown.escape)="closeMeetingTokenModal()"
	>
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="closeMeetingTokenModal()"
		>
			<i class="fas fa-times"></i>
		</button>
		<div class="meeting-info flex-col">
			<!-- title -->
			<div class="title-row mb-3">
				<div class="flex meeting-title">
					<i class="fas fa-handshake me-3 handshake"></i>
					<h3 class="title-thin">{{selectedMeeting.title}}</h3>
				</div>
			</div>
			<!-- topic -->
			<div class="meeting-channel flex mb-2">
				<p class="me-2">Topic: </p><strong style="word-break:break-word;">{{selectedMeeting.body || 'none'}}</strong>
			</div>
			<!-- channel | quickmeeting -->
			<div class="meeting-channel flex mb-2">
				<p class="me-2">Channel: </p>
				<strong class="text-ellipsis">{{(selectedMeeting.channelKeyNavigation.type === 'quick') ? 'Quick meeting' : selectedMeeting.channelKeyNavigation.name}}</strong>
			</div>
			<!-- start/duration -->
			<div class="meeting-date mb-4">
				<div class="flex" style="margin-right: 5rem;"><p class="me-2">Date: </p><strong>{{selectedMeeting.start + 'Z'|date:'M/d/yyyy'}}</strong></div>
				<div class="flex" style="margin-right: 5rem;"><p class="me-2">Time: </p><strong>{{selectedMeeting.start + 'Z'|date:'h:mm a'}}</strong></div>
				<div class="flex"><p class="me-2">Length: </p><strong>{{selectedMeeting.allDay ? 'All day' : selectedMeeting.duration|meetingDuration}}</strong></div>
			</div>
			<!--  -->
			<div class="break mb-4"></div>

			<div class="flex" >
				<p class="me-2"><strong>Invite Link:</strong> 
				</p>
				<button class="item-static-click" 
				style="margin-right: 2rem;"
                (click)="copyMeetingToken()"
                [matTooltip]="'click to copy link'"
                matTooltipClass="tooltip-default"
            >
                ...{{ selectedMeeting.presentationUrl | slice:-36 }}
		
				<i class="fa-solid fa-clipboard" ></i>
	
            </button>

			<button
			type="button"
			style="margin-left: 2rem;"
			(click)="refreshMeetingTokenPrompt()"
			class="me-2"
			[matTooltip]="'refresh link'"
            matTooltipClass="tooltip-default"
			>
			<i class="fa-solid fa-rotate"></i>
			</button>
            </div>
		</div>	
	</div>
</div>
