<div class="flex-center-all nav-logo">
    <!-- <button
		type="button"
		(click)="displayMenu()"
		class="menu-button flex-center-all py-3"
		*ngIf="!_channelService._isDesktop && _isSignedIn"
	>
		<i
			class="fas fa-bars"
			style="--fa-secondary-color: var(--vivid-sky-blue)"
			matTooltip="menu"
			matTooltipClass="tooltip-default"
			[matTooltipShowDelay]="tooltipDelay"
		></i>
	</button> -->

    <a
        [routerLink]="isGuest || !_isSignedIn ? null : '/home'"
        (click)="isGuest || !_isSignedIn ? guestNavToMarketingSite() : null"
        (mousedown)="
            !_channelService._isDesktop ? hideOverlay() : closeSideBar()
        "
        [matTooltip]="isGuest ? 'www.via3.com' : 'home'"
        matTooltipClass="tooltip-default"
    >
        <svg
            *ngIf="!_channelService._isDesktop"
            class="nav-logo-img mobile"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1874.29 561.73"
            id="via-logo-nav-mobile"
        >
            <g id="via-nav-logo-mobile">
                <path
                    d="M937.63,12.14,852.48,359.36h-7.74L759.6,12.14H574.93L703.8,460a140.37,140.37,0,0,0,50.54,73.42c73,58.42,193,20.73,218.61-70.19l141.6-451.13Z"
                />
                <path
                    d="M1190.84,12.14l-25.91,81.25h176.93l25.91-81.25ZM1015.63,561.73h176.92L1327.07,139.8H1150.14Z"
                />
                <path
                    d="M1712.65,108.65c-21.57-68.54-91-103.58-160-96.51a140.54,140.54,0,0,0-133.53,96.75L1270.56,561.73h185.76c21.64-84.32,76.67-298.83,97.31-380.4h7.74c20,79,76.32,298.57,97.31,380.4h203.47Z"
                />
                <path
                    d="M1836,86.28c86.22-54.75-7.53-118-67.45-68.3L1786,44.36c49.93-34.67,58.43,28.54,2.21,33.85v30c69.8-4.29,46.14,60.8-14.72,52.13l11,31.51c45.17-.27,90.71-23.12,89.82-60.89C1874.27,110.7,1860.94,92.94,1836,86.28Z"
                />
                <path
                    d="M270.29,12.74C-90.13,18.9-90.07,555,270.31,561.12,630.7,555,630.65,18.85,270.29,12.74Zm176.2,145L335.89,401.6a16.14,16.14,0,0,1-24.22,6.3c-115.57-83.59-206.38,32.47-209.5,36.53C153.58,373,246.36,284.14,292,242a2.9,2.9,0,0,0-3.58-4.55c-130,76.79-85.91,95.89-169.09,2.09a16.12,16.12,0,0,1,8.09-26.22l300.39-77.83A16.12,16.12,0,0,1,446.49,157.77Z"
                />
            </g>
        </svg>

        <svg
            *ngIf="_channelService._isDesktop"
            class="nav-logo-img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1431.46 1708.02"
            id="via-logo-nav-desktop"
        >
            <g id="via-nav-logo-desktop">
                <path
                    id="plane"
                    d="M664.46,0C347.84,0,91.18,256.67,91.18,573.29s256.66,573.28,573.28,573.28,573.28-256.67,573.28-573.28S981.08,0,664.46,0Zm352.4,303.25L785.62,813A33.75,33.75,0,0,1,735,826.22c-241.63-174.77-431.51,67.9-438,76.38,107.5-149.28,301.5-335.15,396.81-423.21,5.47-5.06-1.25-13.59-7.49-9.52L483,602.19a33.71,33.71,0,0,1-43.77-6.06L332.71,474.22a33.7,33.7,0,0,1,16.92-54.8L977.71,256.7C1005.14,249.59,1028.56,277.45,1016.86,303.25Z"
                />
                <g id="via-three">
                    <g id="via">
                        <path
                            d="M371,1204.44,283.86,1522.6h-7.92l-87.08-318.16H0l131.81,410.4c8.86,27.59,27.36,50.89,51.67,67.28s54.42,25.9,86.46,25.9c62.79,0,118.32-36.53,137.14-90.21L551.9,1204.44Z"
                        />
                        <path
                            d="M629.92,1204.44l-26.49,74.45h181l26.5-74.45ZM450.72,1708H631.67l137.6-386.6h-181Z"
                        />
                        <path
                            d="M1163.62,1292.87c-19.45-52.78-74.48-88.43-136.52-88.43H999.94c-62.13,0-117.23,35.76-136.59,88.65L711.45,1708h190l66.41-232L1001,1359.46h7.91l32.12,113L1108.42,1708h208.09Z"
                        />
                    </g>
                    <g id="three">
                        <path
                            d="M1355.14,1211.25c171.76-81.85-15-176.36-134.39-102.12l34.84,39.45c99.47-51.84,116.42,42.66,4.41,50.61V1244c122.28-7.58,109.52,80.12-13.26,78.43-6.3,0-11.22-.21-16.06-.48l21.82,47.12c89.72-.41,181-26.49,178.93-91C1431.43,1247.76,1404.89,1221.2,1355.14,1211.25Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </a>
</div>

<!-- main links -->
<div
    *ngIf="_isSignedIn && !isGuest"
    class="main-links flex-center-all"
    [class.show-menu]="!_channelService._isDesktop"
    [class.hide-menu]="!_channelService._isDesktop && inChannel"
>
    <!-- home -->
    <a
        *ngIf="_channelService._isDesktop"
        routerLink="/home"
        routerLinkActive="active"
        #rlaHome="routerLinkActive"
        (click)="!_channelService._isDesktop ? hideOverlay() : closeSideBar()"
        class="nav-link flex-center-all py-3"
        id="home-button"
        [class.disabled-link]="rlaHome.isActive"
    >
        <i
            class="{{
                rlaHome.isActive ? 'fad fa-home active' : 'fas fa-home'
            }}"
            style="--fa-secondary-color: var(--vivid-sky-blue)"
            matTooltip="home"
            matTooltipClass="tooltip-default"
        ></i>
    </a>

    <!-- ✨ calendar app page -->
    <!-- <a
		class="nav-link flex-center-all py-3">

		<i
			class="fas fa-calendar-alt" matTooltip="calendar"
			matTooltipClass="tooltip-default" [matTooltipShowDelay]="tooltipDelay"></i>
		<span class="nav-link-badge"></span>
	</a> -->
    <!-- ✨ contacts full page -->
    <!-- <a
		class="nav-link flex-center-all py-3">

		<i
			class="fas fa-address-book" matTooltip="contacts"
			matTooltipClass="tooltip-default" [matTooltipShowDelay]="tooltipDelay"></i>
		<span class="nav-link-badge"></span>
	</a> -->

    <ng-container *ngIf="_channelService._isDesktop">
        <!-- file storage -->
        <button
            type="button"
            (click)="setSidebar(1, 'files')"
            class="nav-link flex-center-all py-3"
            [disabled]="currentRoute === '/filestorage'"
        >
            <i
                class="{{
                    currentRoute === '/filestorage'
                        ? 'fad fa-folder-open active'
                        : 'fas fa-folder-open'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="files"
                matTooltipClass="tooltip-default"
            ></i>
        </button>

        <!-- fax app -->
        <!-- WORKS FOR TEST *ngIf="_authService._userInfo.subscriptionName === 'enterprise' || _authService._userInfo.subscriptionName === 'Via3'"-->
        <button
            *ngIf="_authService._userInfo.subscriptionName.lowercase !== 'starter' && _authService._userInfo.subscriptionName.lowercase !== 'pro'"
            type="button"
            (click)="setSidebar(1, 'faxes')"
            class="nav-link flex-center-all py-3"
            [disabled]="currentRoute === '/fax'"
        >
            <i
                class="{{
                    currentRoute === '/fax' ? 'fad fa-fax active' : 'fas fa-fax'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="faxes"
                matTooltipClass="tooltip-default"
            ></i>
        </button>

        <!-- projects/channels page -->
        <button
            type="button"
            (click)="setSidebar(1, 'channels')"
            class="nav-link flex-center-all py-3"
            [disabled]="
                currentRoute === '/channels' ||
                currentRoute === '/channel' ||
                currentRoute === '/channels?orderby=projects'
            "
        >
            <i
                class="{{
                    currentRoute === '/channels' ||
                    currentRoute === '/channel' ||
                    currentRoute === '/channels?orderby=projects'
                        ? 'fad fa-tv-retro active'
                        : 'fas fa-tv-retro'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="channels"
                matTooltipClass="tooltip-default"
            ></i>
        </button>
    </ng-container>

    <!-- ✨ MOBILE NAV HERE -->
    <ng-container *ngIf="!_channelService._isDesktop">
        <!-- events -->
        <a
            routerLink="/events"
            routerLinkActive="active"
            #rlaEvents="routerLinkActive"
            (click)="hideOverlay()"
            class="nav-link flex-center-all py-3"
        >
            <i
                class="{{
                    rlaEvents.isActive
                        ? 'fad fa-calendar-alt active'
                        : 'fas fa-calendar-alt'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="events"
                matTooltipClass="tooltip-default"
            ></i>
        </a>

        <!-- contacts -->
        <a
            routerLink="/contacts"
            routerLinkActive="active"
            #rlaContacts="routerLinkActive"
            (click)="hideOverlay()"
            class="nav-link flex-center-all py-3"
        >
            <i
                class="{{
                    rlaContacts.isActive
                        ? 'fad fa-address-book active'
                        : 'fas fa-address-book'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="contacts"
                matTooltipClass="tooltip-default"
            ></i>
        </a>

        <!-- file storage -->
        <a
            routerLink="/filestorage"
            routerLinkActive="active"
            #rlaFiles="routerLinkActive"
            (click)="hideOverlay()"
            class="nav-link flex-center-all py-3"
        >
            <i
                class="{{
                    rlaFiles.isActive
                        ? 'fad fa-folder-open active'
                        : 'fas fa-folder-open'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="files"
                matTooltipClass="tooltip-default"
            ></i>
        </a>

        <!-- fax app -->
        <a
            *ngIf="_authService._userInfo.subscriptionName.lowercase !== 'starter' && _authService._userInfo.subscriptionName.lowercase !== 'pro'"
            routerLink="/fax"
            routerLinkActive="active"
            #rlaFaxes="routerLinkActive"
            (click)="hideOverlay()"
            class="nav-link flex-center-all py-3"
        >
            <i
                class="{{
                    rlaFaxes.isActive ? 'fad fa-fax active' : 'fas fa-fax'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="faxes"
                matTooltipClass="tooltip-default"
            ></i>
        </a>

        <!-- projects/channels page -->
        <a
            routerLink="/channels"
            routerLinkActive="active"
            #rlaChannels="routerLinkActive"
            (click)="hideOverlay()"
            class="nav-link flex-center-all py-3"
        >
            <i
                class="{{
                    rlaChannels.isActive
                        ? 'fad fa-tv-retro active'
                        : 'fas fa-tv-retro'
                }}"
                style="--fa-secondary-color: var(--vivid-sky-blue)"
                matTooltip="channels"
                matTooltipClass="tooltip-default"
            ></i>
        </a>
    </ng-container>

    <!-- ✨ intake app || medical app -->
    <a
        *ngIf="(userInfo.tenant | lowercase) === 'heartofida'"
        routerLink="/medicalapp/heartofida/medical"
        routerLinkActive="active"
        #rlaIntakeHOI="routerLinkActive"
        (mousedown)="
            !_channelService._isDesktop ? hideOverlay() : closeSideBar()
        "
        class="nav-link flex-center-all py-3"
    >
        <i
            class="{{
                rlaIntakeHOI.isActive
                    ? 'fad fa-home-heart active'
                    : 'fas fa-home-heart'
            }}"
            style="--fa-secondary-color: var(--vivid-sky-blue)"
            matTooltip="heart of ida"
            matTooltipClass="tooltip-default"
        ></i>
    </a>
    <!-- 🚧 adding Oceanic icon -->
    <a
        *ngIf="(userInfo.tenant | lowercase) === 'oceanic'"
        routerLink="/medicalapp/oceanic/medical"
        routerLinkActive="active"
        #rlaIntakeOceanic="routerLinkActive"
        (mousedown)="
            !_channelService._isDesktop ? hideOverlay() : closeSideBar()
        "
        class="nav-link flex-center-all py-3"
    >
        <i
            class="{{
                rlaIntakeOceanic.isActive ? 'fad fa-ship active' : 'fas fa-ship'
            }}"
            style="--fa-secondary-color: var(--vivid-sky-blue)"
            matTooltip="oceanic"
            matTooltipClass="tooltip-default"
        ></i>
    </a>
    <!-- vdjj -->
    <a
        *ngIf="(userInfo.tenant | lowercase) === 'vdjj'"
        routerLink="/intakeapp/vdjj"
        routerLinkActive="active"
        #rlaIntake="routerLinkActive"
        (mousedown)="
            !_channelService._isDesktop ? hideOverlay() : closeSideBar()
        "
        class="nav-link flex-center-all py-3"
    >
        <!-- police-officer icon solid -->
        <svg
            *ngIf="!rlaIntake.isActive"
            matTooltip="intake app"
            matTooltipClass="tooltip-default"
            xmlns="http://www.w3.org/2000/svg"
            (mouseenter)="changeSVGIconFillEnter($event)"
            (mouseleave)="changeSVGIconFillLeave($event)"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="user-police-tie"
            class="svg-inline--fa fa-user-police-tie"
            role="img"
            viewBox="0 0 448 512"
        >
            <path
                id="fa-hov-i"
                fill="var(--color-primary-icon)"
                d="M78.54 102.4v28.04C119.1 148.9 169.4 160 224 160s104.9-11.08 145.5-29.55V102.4l21.96-22.3c4.564-4.635 7.129-10.93 7.129-17.48c0-11.35-7.602-21.24-18.44-23.99l-148.3-37.67c-5.096-1.295-10.43-1.295-15.52 0l-148.3 37.67C57.06 41.39 49.46 51.28 49.46 62.62c0 6.555 2.564 12.85 7.129 17.48L78.54 102.4zM191.1 61.08l30-12.5C221.9 48.26 223.2 48 224 48c.8281 0 2.122 .2559 2.884 .5723l30 12.5c2.779 1.152 4.609 3.889 4.609 6.92C261.5 107.1 232.1 128 223.1 128C216.1 128 186.5 108.2 186.5 67.1C186.5 64.96 188.3 62.23 191.1 61.08zM319.7 352.5l-66.63 111L240 408l32-56h-96l32 56l-13.07 55.55l-66.63-111C57.05 355.2 0 413.4 0 485.3C0 500.1 11.94 512 26.66 512H421.3C436.1 512 448 500.1 448 485.3C448 413.4 390.1 355.2 319.7 352.5zM390.5 437.3l-15.81 15.37l3.736 21.71c.6719 3.926-3.469 6.852-6.939 5.029L352 469.2l-19.54 10.25c-3.471 1.836-7.611-1.104-6.939-5.029l3.736-21.71l-15.81-15.37c-2.844-2.76-1.27-7.58 2.648-8.146L337.9 426l9.764-19.76c1.766-3.566 6.834-3.521 8.582 0L366.1 426l21.85 3.18C391.8 429.7 393.4 434.6 390.5 437.3zM97.97 172.4C96.98 178.9 96 185.3 96 192c0 70.69 57.3 128 127.1 128s127.1-57.31 127.1-128c0-6.705-.9399-13.15-1.934-19.57C311.2 185.2 268.4 192 224 192C179.6 192 136.8 185.2 97.97 172.4z"
            />
        </svg>
        <!-- police-officer icon duo tone -->
        <svg
            *ngIf="rlaIntake.isActive"
            matTooltip="intake app"
            matTooltipClass="tooltip-default"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="user-police-tie"
            class="svg-inline--fa fa-user-police-tie"
            role="img"
            viewBox="0 0 448 512"
        >
            <g class="fa-duotone-group">
                <path
                    fill="var(--color-secondary-text)"
                    d="M346 155.5C310.1 168.3 268.7 176 223.1 176S137.9 168.3 101.1 155.5C98.49 167.1 96 179.2 96 192c0 70.75 57.25 128 127.1 128s127.1-57.25 127.1-128C351.1 179.2 349.5 167.1 346 155.5zM176 352l32 56l-16.64 70.71L208 512h32l16.64-33.29L240 408l32-56H176z"
                />
                <path
                    fill="var(--vivid-sky-blue)"
                    d="M0 485.3C0 500.1 11.94 512 26.66 512H208l-79.7-159.5C57.05 355.2 0 413.4 0 485.3zM369.5 102.4l21.96-22.3c4.564-4.635 7.129-10.93 7.129-17.48c0-11.35-7.602-21.24-18.44-23.99l-148.3-37.67c-5.096-1.295-10.43-1.295-15.52 0l-148.3 37.67C57.06 41.39 49.46 51.28 49.46 62.62c0 6.555 2.564 12.85 7.129 17.48l21.96 22.3v44.04C119.1 164.9 169.4 176 223.1 176s104.9-11.08 145.5-29.55V102.4zM223.1 128C216.1 128 186.5 108.2 186.5 67.1c0-3.031 1.83-5.768 4.623-6.92l30-12.5C221.9 48.26 223.2 48 224 48c.8281 0 2.122 .2559 2.884 .5723l30 12.5c2.779 1.152 4.609 3.889 4.609 6.92C261.5 107.1 232.1 128 223.1 128zM319.7 352.5L240 512h181.3C436.1 512 448 500.1 448 485.3C448 413.4 390.1 355.2 319.7 352.5zM390.5 437.3l-15.81 15.37l3.736 21.71c.6719 3.926-3.469 6.852-6.939 5.029L352 469.2l-19.54 10.25c-3.471 1.836-7.611-1.104-6.939-5.029l3.736-21.71l-15.81-15.37c-2.844-2.76-1.27-7.58 2.648-8.146L337.9 426l9.764-19.76c1.766-3.566 6.834-3.521 8.582 0L366.1 426l21.85 3.18C391.8 429.7 393.4 434.6 390.5 437.3z"
                />
            </g>
        </svg>
    </a>

    <!-- For theme testing -->
    <!-- <button
		(click)="themeSwitch()"
		class="nav-link flex-center-all py-3">
		<i
			class="fas fa-adjust"></i>
	</button> -->
</div>

<!-- bottom links -->
<!-- [class.is-guest]="isGuest" -->
<div *ngIf="_isSignedIn && !isGuest" class="nav-bottom flex-col-center-all">
    <!-- search -->
    <!-- <button
        class="bottom-link flex-center-all my-2">
        
        <i 
            class="fas fa-search" matTooltip="search" 
            matTooltipClass="tooltip-default"></i>
    </button> -->

    <!--temp click for overlay tabs-->

    <!-- Testing -->

    <!-- direct alerts bar -->
    <button
        (click)="setSidebar(1, 'alerts')"
        class="bottom-link flex-center-all"
    >
        <i
            class="fas fa-bell"
            matTooltip="alerts"
            matTooltipClass="tooltip-default"
        ></i>
        <span
            *ngIf="notificationBadge"
            class="notification-dot"
            matTooltip="unread notifications"
            matTooltipClass="tooltip-default"
        >
        </span>
    </button>

    <!-- direct messages bar -->
    <button (click)="setSidebar(2)" class="bottom-link flex-center-all">
        <i
            class="fas fa-comments-alt"
            matTooltip="messages"
            matTooltipClass="tooltip-default"
        ></i>
        <span
            *ngIf="messageBadge"
            class="notification-dot"
            matTooltip="unread messages"
            matTooltipClass="tooltip-default"
        >
        </span>
    </button>

    <!-- user profile bar -->
    <button (click)="setSidebar(3)" class="bottom-link flex-center-all">
        <img
            *ngIf="!!userInfo.hash && userInfo.hash !== '1'; else defaultImg"
            class="circle"
            src="https://profile-images.azureedge.us/{{ userInfo.hash }}"
            alt="Profile Picture"
            (error)="handleBrokenImage()"
            matTooltip="{{ userInfo.displayName }}"
            matTooltipClass="tooltip-default"
        />
        <ng-template #defaultImg>
            <div
                class="default-user-img circle flex-center-all every-third"
                matTooltip="{{ userInfo.displayName | titlecase }}"
                matTooltipClass="tooltip-default"
            >
                {{ userInfo.username | defaultImageOverlay }}
            </div>
        </ng-template>
        <span
            class="status-dot {{ userStatus }}"
            matTooltip="{{ userStatusTitle }}"
            matTooltipClass="tooltip-default"
        >
        </span>
    </button>
</div>
