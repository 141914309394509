import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChannelService } from '../../services/channel-service';
import { AppIntakeService } from '../../../../src/app/services/app-intake.service';
import { ApiCallsService } from '../../../../src/app/services/api-calls.service';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { SocketsService } from '../../services/sockets.service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { VdjjVideoIntakeHomepageComponent } from '../vdjj-video-intake-homepage/vdjj-video-intake-homepage.component';
// import { DomSanitizer } from '@angular/platform-browser';
import { ToastNotificationService } from '../../services/toast-notification.service';
import { AuthService } from 'src/app/core/auth.service';
import { consoleLogStyle } from 'src/app/shared/varaibles';
import { ChannelFull } from 'src/app/shared/interface/channel-full';



@Component({
    selector: 'vdjj-video-intake-chat-io',
    templateUrl: './vdjj-video-intake-chat-io.component.html',
    styleUrls: ['./vdjj-video-intake-chat-io.component.scss']
})
export class VdjjVideoIntakeChatIoComponent implements OnInit, OnDestroy {
    @Output() connectionStatus = new EventEmitter();

    public _intakeSession: any = {};
    public _officer: any = {}
    public _intakeOfficerShiftLogId: string = null;
    public _customizations: any = {};
    public _tenant: string = '';
    public _app: any;

    public officerList: any = [];
    public officerListFilter: any = [];
    public selectedOfficer: any = {};
    public officerReady: boolean = false;

    public showOverview: HTMLElement;
    public showChat: HTMLElement;
    public showVideo: HTMLElement;
    public showHistory: HTMLElement;
    // public showForms: HTMLElement;
    
    public showFormsBool: boolean = false;
    public selectedChannel: any = {};
    public channelId: string = null;
    public teamProjectId: string;
    public videoType: string;
    public isIntake: boolean;
    public groupChatSelected: boolean = false; //true if user is currently in group chat 
    public inCall: boolean = false;

    // public alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    // public filterLetter: string = null;

    public newSchedule: any = {};
    public updateSchedule: any = {};
    public showSchedule: boolean = false;  // true if editing schedule
    public scheduleReady: boolean = false; // true when schedule has loaded
    public scheduleError: boolean = false; // true when schedule is set to past Date
    public selectSchedule: any = {
        "fromHour": 12,
        "fromMin": 0,
        "fromDay": "pm",
        "toHour": 12,
        "toMin": 0,
        "toDay": "pm",
        "fromDate": "",
        "toDate": ""
    }
    public selectStatus: boolean = false;

    public chatList: any = [];
    public accessList: any = {
        "owners": [],
        "members": []
    };

    // for files
    public _showFiles: boolean = false
    public channelInfo: ChannelFull;
    public channelDisplayName: string = "";

    public socketServers: any = [];


    //for Jitsi
    public videoPreventList: any = ['users'];

    //for Medical
    public isMedical: boolean = this._intakeGlobal.isMedical;

    //for Iframe
    // public iframe: any = null;

    private subscriptions: Subscription[] = [];

    public isSessionEnding: boolean = false;

    constructor(
        private _authService: AuthService,
        private _channelService: ChannelService,
        private _intakeService: AppIntakeService,
        private _apiCallsService: ApiCallsService,
        private _fileStorageService: FileStorageService,
        private _chManageService: ProjectChannelService,
        private _intakeGlobal: VdjjVideoIntakeHomepageComponent,
        private _apiCalls: ApiCallsService,
        // private sanitizer: DomSanitizer,
        private _socketService: SocketsService,
        private _toastService: ToastNotificationService,
    ) {
        // new for files events ... 
        this.subscriptions.push(this._channelService.onSharedFileUploadCompletedEvent$.subscribe((fileUploadObj: any) => {
            if (fileUploadObj !== null) {
                console.log(fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase());
                if(fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    this._toastService.file(fileUploadObj.uploaderDisplayName + " has uploaded " + fileUploadObj.fileName);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onUnreadMessageNotificationEvent$.subscribe((notiObj) => {
            console.log("unread# " + notiObj.numUnreadMessages + " allowSound " + notiObj.hasAudioNotification + " channelId: " + notiObj.channelId, " isIntakeGroupChat: " + notiObj.isIntakeGroupChat);
            // notiObj.hasAudioNotification will be true when notiObj.numUnreadMessages equals 1. Joe, you can set off the audio notification when this is true.
            var nineUnreadMessages = 9;
            if(notiObj.isIntakeGroupChat) {
                // console.log("Group Chat unread counter: " + notiObj.numUnreadMessages);
                if (!this.groupChatSelected) {
                    if (notiObj.numUnreadMessages > nineUnreadMessages) {
                        this._app.unreadMessages = "9+";
                    } else {
                        this._app.unreadMessages = notiObj.numUnreadMessages;
                    }
                }
            } else {
                this.officerList.forEach((officer) => {
                    if (officer.channelId === notiObj.channelId) {
                        if (notiObj.numUnreadMessages > nineUnreadMessages) {
                            officer.unreadMessages = "9+";
                        } else {
                            officer.unreadMessages = notiObj.numUnreadMessages;
                        }
                    }
                });
                this.filterOfficers();
            }
        }));

        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "intakeDeleteFiles") {
                this.showBox(1); // Moves user to overview to show 'session is ending' while we wait for session to end
                this.endSessionFileDelete();
            }
        }));

        // sets every arresting officer's status from the passed in list of users to online
        this.subscriptions.push(this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            // alert(JSON.stringify(channelUserStatusList))
            //console.log("channelUserStatusList", channelUserStatusList)
            this.officerList.forEach((officer) => {
                channelUserStatusList.forEach((channelUser) => {
                    if (officer.intakeUserKeyNavigation.chatUserId === channelUser.channelUserId) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            // officer.userStatus = "hidden";
                            officer.userStatus = "offline";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                    }
                });
            });

            // this._getChannelUsersInRoom();
            this.chatList.forEach((officer) => {
                channelUserStatusList.forEach((channelUser) => {
                    if (officer.channelUserId === channelUser.channelUserId) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            // officer.userStatus = "hidden";
                            officer.userStatus = "offline";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                    }
                });
            });
        }));

        // only called on start session //
        this.subscriptions.push(this._channelService.onUpdateSessionUsersEvent$.subscribe(() => {
            console.log("onUpdateSessionUsersEvent sub");
            //console.log("update session users event start session")
            //alert("chat io update intake session users subscribe");
            //check if intake officer was on AO's session that was just ended, if they were call this, if not, call init
            this._getAllIntakeSessionUsers()
                    .then(()=>{
                        // if (this.filterLetter === null) {
                        //     this.filterOfficers();
                        // } else {
                        // }
                        // this.filterOfficersByLetter();
                        this._getChannelUsersInRoom();
                    })
                    .catch(err => console.log(err));
            // called on begin && end
            // console.log("🟢 session begin | end")
        }));

        this.subscriptions.push(this._channelService.onRtcCallInProgressEvent$.subscribe(() => {
            this.selectedOfficer.intakeStatus = 'in-call';
            this.selectedOfficer.userStatus = "in-call";
        }));

         // called on session END ONLY //
        this.subscriptions.push(this._channelService.onIntakeSessionEndedEvent$.subscribe((channelId: any) => {
            if (this.selectedOfficer.chatRoomId.toLowerCase() === channelId.toLowerCase()) {
                this.showBox(2);
                this.officerReady = false;
                this.isSessionEnding = false;
                console.log("🔴 session ended event emitted");
                //this.officerList.splice(index, 1);
                this._officersNotAvailable();
                this._getAllIntakeSessionUsers()
                    .then(()=>{
                        // if (this.filterLetter === null) {
                        //     this.filterOfficers();
                        // } else {
                        // }
                        // this.filterOfficersByLetter();
                        this._getChannelUsersInRoom();
                    })
                    .catch(err=>console.log(err));
                this.selectedChannel = null;
            }
        }));

        this.subscriptions.push(this._channelService.onLeaveRoomEvent$.subscribe(() => {
            if (this.officerList.length == 0) {
                this.showBox(2);
                this.officerReady = false;
                console.log("endSession: " + this.selectedOfficer);
                //this.officerList.splice(index, 1);
                this._officersNotAvailable();
                // if (this.filterLetter === null) {
                //     this.filterOfficers();
                // } else {
                // }
                // this.filterOfficersByLetter();
                this.selectedChannel = null;
            }
        }));

        this.subscriptions.push(this._channelService.onJoinNotification$.subscribe((joinObj: any) => {
            //this._getAllIntakeSessionUsers();
            this._getChannelUsersInRoom();
            if (joinObj.showJoinNotification == true) {
                if (this.channelId.toLowerCase() === joinObj.channelId.toLowerCase()) {
                    this._toastService.enter(joinObj.displayname);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            this._getChannelUsersInRoom();
            if (this.channelId.toLowerCase() === userObj.channelId.toLowerCase()) {
                this._toastService.leave(userObj.displayname);
            }
        }));

        this.subscriptions.push(this._channelService.onGetChannelUsersInRoomEvent$.subscribe(() => {
            this._getChannelUsersInRoom();
        }));

        this.subscriptions.push(this._channelService.onHangUpUserEvent$.subscribe(() => {
            //alert("hangupuser subscribe");
            // Todo: Joe, this is for displaying begin call ... ❔ do we need this ... 
            //this.resetCall();
        }));

        this.subscriptions.push(this._channelService.onCallChannelEvent$.subscribe((callObj: any) => {
            this.inCall = true;
        }));

        document.addEventListener("visibilitychange", this.onVisibilityChange);
    }


    ngOnInit() {
        this.showOverview = <HTMLElement>document.getElementById('o-overview');
        this.showChat = <HTMLElement>document.getElementById('o-chat');
        this.showVideo = <HTMLElement>document.getElementById('video-stream');
        this.showHistory = <HTMLElement>document.getElementById('o-history');
        //this.showForms = <HTMLElement>document.getElementById('o-forms');
        this._intakeSession = this._intakeGlobal._intakeSession;
        this._officer = this._intakeGlobal.officerData;
        //console.log("officer: ", this._officer);
        this._app = this._intakeGlobal._app;
        this._app.unreadMessages = 0;
        this._tenant = this._intakeGlobal._tenant;
        this.teamProjectId = this._intakeGlobal._teamProjectId;
        if (!this.isMedical) {
            this.videoPreventList.push('record');
        }
        this._getChannelByNameInit(this._officer.chatUserId);
        //this._channelService.Initialize(this._officer.intakeUserId);
        //this._channelService.join(this.channelId);
        //this._channelService.populateMessages(this.channelId);
        //this._getChannelUsersInRoom();
        this._getAppCustomizations();
        this._getIntakeOfficerShiftLogByIntakeUser();
        //this._getAllIntakeSessionUsersInit();
        this.showBox(2);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        return this._channelService.leave(this.channelId);
    }

    public showBox(val: number) {
        this.showOverview.style.display = 'none';
        this.showChat.style.display = 'none';
        this.showVideo.style.display = 'none';
        this.showHistory.style.display = 'none';
        //this.showForms.style.display = 'none';
        this.showFormsBool = false;
        if (val === 5) {
            this.showHistory.style.display = 'block';
        } else if (val === 4) {
            //this.showForms.style.display = 'block';
            this.showFormsBool = true;
            //this.getOnlineForms();
        } else if (val === 3) {
            this.showVideo.style.display = 'block';
        } else if (val === 2) {
            this.showChat.style.display = 'block';
            this.selectedOfficer.unreadMessages = 0;
        } else {
            this.showOverview.style.display = 'grid';
        }
    }

    public beginCall() {
        this.selectedOfficer.intakeStatus = 'in-call';
        //this.selectedOfficer.userStatus = "in-call";
        // let div: any = document.getElementsByClassName('begin-videocall')[0]
        // div.style.display = 'none';
        this.inCall = true;
        this._channelService.onShowBeginCallPrompt(this.channelId);
    }

    public resetCall() {
        this.selectedOfficer.intakeStatus = "ready";
        // this.selectedOfficer.userStatus = "online";
        this.inCall = false;
        this.showVideoPrompt();
    }

    public showVideoPrompt(): boolean {
        if (this.selectedOfficer.intakeStatus !== 'in-call') {
            return true;
        } else {
            if (this.inCall) {
                return false;
            } else {
                return true;
            }
        }
    }

    _getAllIntakeSessionUsersInit() {
        //Temp
        //this.officerList = officerList;
        //this.intakeSessionsAdditionalInfo(true);
        this._intakeService.getAllActiveIntakeSessionUsersBySession(this._intakeSession.intakeSessionId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.officerList = data.body;
                    // this._getOnlineOfficers();
                    this._getNotifications();
                } else {
                    console.error(data)
                }
            });
    }

    _getAllIntakeSessionUsers(): Promise<any> {
        return this._intakeService.getAllActiveIntakeSessionUsersBySession(this._intakeSession.intakeSessionId)
            .toPromise()
            .then(data => {
                console.log("all intake officers", data);
                if (data.status === 200) {
                    this.officerList = data.body;
                    this._getNotifications();
                } else {
                    this._toastService.error("Failed to get intake sessions.")
                    console.error(data);
                }
            })
            .catch(err => console.error(err));
    }

    intakeSessionsAdditionalInfo(init: boolean) {
        console.log("this.officerList", this.officerList)
        this.officerListFilter = this.officerList;
        this.officerList.forEach(officer => {
            officer.intakeStatus = "pending";
            // officer.unreadMessages = "10+";
            officer.userStatus = "";
            officer.channelId = officer.chatRoomId;
            officer.overview = {
                report: false,
                refferal: false,
                schedule: false
            };
        });
        // console.log("Filter List!!!!!!!!!",this.officerListFilter);
        this._getChannelUserInfo();
        if (init) {
            // console.log("intakeSessionAddionalInfo, should be after officer list loads " + JSON.stringify(this.officerList));
            //this.selectFirstOfficer();
        }
    }

    _getNotifications() {
        this._chManageService.getChannelNotificationsByUser(this._officer.chatUserId)
            .subscribe( data => {
                if (data.status === 200) {
                    let notifications = data.body;
                    const nineUnreadMessages = 9;
                    this.officerList.forEach((officer) => {
                        let num = 0;
                        notifications.forEach((notification) => {
                            if (officer.chatRoomId === notification) {
                                num++;
                            }
                        });
                        if (num > nineUnreadMessages) {
                            officer.unreadMessages = "9+";
                        } else {
                            officer.unreadMessages = num;
                        }
                    });
                    this.intakeSessionsAdditionalInfo(true)
                } else {
                    console.error("notifications error", data) 
                }
            });
    }

    _getAppCustomizations() {
        this._intakeService.getAppCustomizations(this._app.appId)
            .subscribe(data => {
                if(data.status === 200) {
                    let customs = data.body;
                    customs.forEach(custom => {
                        if (custom.section === "CompanyImageIO") {
                            this._customizations.companyImageIO = custom;
                        }
                    });
                } else {
                    console.error("error getting app customizations", data)
                }
            });
    }

    _getChannelUserInfo() {
        this.officerList.forEach(officer => {
            this._authService.getChannelUserInfo()
                .subscribe(data => {
                    if(data.status === 200) {
                        let channelUser: any = data.body;
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            officer.userStatus = "hidden";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                    } else {
                        console.error("error getting user info", data)
                    }
                });
        });
    }

    _getChannelByName(userId: any) {
        //console.log("getChannelByName");
        let channelName = "PO_" + userId;
        this._apiCallsService.getChannelByNameAndProjectId(channelName, this.teamProjectId)
            .subscribe(data => {
                if(data.status === 200) {
                    let channels = data.body;
                    this.selectedChannel = channels[0];
                    this.channelId = this.selectedChannel.channelId;
                    this.videoType = this.selectedChannel.videoType;
                    if (this.videoType === null || this.videoType === 'kurento') {
                        this.videoType = 'jitsi';
                    }
                    //console.log("getChannelByName: " + this.channelId);
                    this._channelService.onSelectPoliceOfficer(this.channelId);
                    this._connectToChannel();
                    this._getChannelOwners();
                    this._getChannelMembers();
                    // this._channelOwnerCheck();
                    this.channelInfo = channels[0]; 
                    //temp Iframe
                    // this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(
                    //     "../../../assets/jitsi/meeting.html?username=" +
                    //     this._officer.chatUserKeyNavigation.displayName + "&channelname=" + this.channelId
                    // );
                } else {
                    console.error("error getting channel", data)
                }
            });
    }

    public _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                //console.log("Getactivesocketservers " + JSON.stringify(data.body));
                this.socketServers = data.body;
                //this.Initialize(channelId);
            })
            .catch(err => console.error(err));
    }

    _getChannelByNameInit(userId: any) {
        //console.log("getChannelByName");
        let channelName = "PO_" + userId;
        this._apiCallsService.getChannelByNameAndProjectId(channelName, this.teamProjectId)
            .subscribe(data => {
                if(data.status === 200) {
                    let channels = data.body;
                    this.channelId = channels[0].channelId;
                    this.videoType = channels[0].videoType;
                    if (this.videoType === null || this.videoType === 'kurento') {
                        this.videoType = 'jitsi';
                    }
                    //console.log("getChannelByName: " + channelName);
                    this._channelService.join(this.channelId);
                    this._getAllIntakeSessionUsersInit();
                } else {
                    console.error("error getting channel", data)
                }
            });
    }

    checkGroupChat() {
        if (!this.groupChatSelected) {
            if (this.channelId !== null) {
                this._channelService.hangUpCallEvent();
                console.log("leaving channel: " + this.channelId);
                this._channelService.sendCommand("/leave", this.channelId);
            }
            this.groupChatSelected = true;
            this._app.unreadMessages = 0;
            this._getGroupChatChannel();
        }
    }

    _getGroupChatChannel() {
        console.log(this.selectedOfficer.displayName);
        if (this.selectedOfficer.displayName !== "Intake Group Chat" || this.selectedOfficer.displayName !== "Staff Group Chat") {
            this.selectedOfficer = {
                "userStatus": "none",
                "intakeStatus": "",
                "displayName": "Intake Group Chat",
                "intakeUserKeyNavigation": {
                    "chatUserId": null,
                    "chatUserKeyNavigation": {
                        "username": "None",
                        "displayName": "None",
                        "hash": "1"
                    }
                }
            };
            if (this.isMedical) {
                this.selectedOfficer.displayName = "Staff Group Chat"
            }
            this.chatList = [];
            this._intakeService.getIntakeGroupChatChannel(this.teamProjectId, this.isMedical)
                .subscribe(data => {
                    if(data.status === 200) {
                        let channels = data.body;
                        this.channelId = channels[0].channelId;
                        this.videoType = channels[0].videoType;
                        if (this.videoType === null || this.videoType === 'kurento') {
                            this.videoType = 'jitsi';
                        }
                        this.selectedOfficer.channelId = channels[0].channelId;
                        this.selectedChannel = channels[0];
                        //this._channelService.Initialize(this.channelId);
                        //temp Iframe 
                        // this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(
                        //     "../../../assets/jitsi/meeting.html?username=" +
                        //     this._officer.chatUserKeyNavigation.displayName + "&channelname=" + this.channelId
                        // );
                        
                        this._channelService.join(this.channelId);
                        this._channelService.populateMessages(this.channelId);
                        // TODO: Change the name to a more broad Intake App action
                        // ie: onSelectNewIntakeChannel
                        this._channelService.onSelectPoliceOfficer(this.channelId);
                        this.channelInfo = channels[0] //temp for files
                        this.officerReady = true;
                        if (this.showOverview.style.display === 'grid') {
                            this.showOverview.style.display = 'none';
                            this.showChat.style.display = 'block';
                        } else if (this.showFormsBool) {
                            this.showFormsBool = false;
                            this.showChat.style.display = 'block';
                        }
                    } else {
                        console.error("error getting group chat", data)
                    }
                });
        }
    }

    _selectOfficer(i: number) {
        this.groupChatSelected = false;
        if (this.selectedChannel) {
            if (this.selectedChannel.name !== this.officerListFilter[i].chatRoomKeyNavigation.name) {
                this._channelService.hangUpCallEvent();
                this._channelService.sendCommand("/leave", this.channelId);
                this._getOfficer(i);
            }
        } else {
            this._getOfficer(i);
        }
        this.officerReady = true;
        if (this.showChat.style.display === 'block') {
            this.selectedOfficer.unreadMessages = 0;
        }
    }

    _getOfficer(i: number) {
        this.selectedOfficer = this.officerListFilter[i];
        this._getChannelByName(this.selectedOfficer.intakeUserKeyNavigation.chatUserId);
    }

    _officersNotAvailable() {
        this.selectedOfficer = {
            "intakeUserId": null,
            "firstName": "None",
            "lastName": "None",
            "displayName": "None",
            "chatRoomId": undefined,
            "userStatus": "away",
            "intakeStatus": "pending",
            "unreadMessages": 0,
            "overview": {
                "report": false,
                "refferal": false,
                "schedule": false
            },
            "intakeUserKeyNavigation": {
                "chatUserId": null,
                "chatUserKeyNavigation": {
                    "username": "None",
                    "displayName": "None",
                    "hash": "1"
                }
            }
        };
        // this.channelId = this.selectedOfficer.channelId; //Will be used to prevent no channels
    }

    _connectToChannel() {
        if (this.selectedChannel) {
            this._channelService.join(this.channelId);
            this._channelService.populateMessages(this.channelId);
        } else {
            this.officerReady = false;
            this._toastService.error("Could not connect to channel.");
        }
    }

    _getChannelUsersInRoom() {
        this._chManageService.getChannelUsersInRoomByChannelId(this.channelId)
            .subscribe(data => {
                if(data.status === 200) {
                    this.chatList = [];
                    let chatList = data.body;
                    let _officerId = this._officer.chatUserKeyNavigation.channelUserId
                    chatList.sort(function (x, y) { return x.channelUserId == _officerId ? -1 : y.channelUserId == _officerId ? 1 : 0; });
                    this.chatList = chatList;
                    this.chatList.forEach(officer => {
                        let channelUser = officer.chatUserKeyNavigation;
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            officer.userStatus = "hidden";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                    });
                } else {
                    console.error("error getting channel users in room", data)
                }
            });
    }

    _getChannelOwners() {
        this._chManageService.getChannelOwnersByChannelId(this.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.accessList.owners = data.body;
                    this.accessList.ownersPag = JSON.parse(data.headers.get('X-Pagination'));
                    if (this.accessList.ownersPag.nextPageLink) {
                        this._getMoreChannelOwners(this.accessList.ownersPag.nextPageLink);
                    }
                } else {
                    this.accessList.owners = [];
                }
            });
    }

    _getMoreChannelOwners(link: any) {
        this._apiCalls.getGenericForPagination(link)
            .subscribe(data => {
                if (data.status === 200) {
                    //console.log("owners")
                    //console.log(this.accessList.owners)
                    //console.log("body from pag")
                    //console.log(data.body)
                    let owners: any = data.body;
                    owners.forEach(owner => {
                        //console.log("new owner")
                        //console.log(owner);
                        this.accessList.owners.push(owner);
                    });
                    //console.log("owners after pag")
                    //console.log(this.accessList.owners)
                    // this.accessList.owners.concat(owners);
                    this.accessList.ownersPag = JSON.parse(data.headers.get('X-Pagination'));
                    if (this.accessList.ownersPag.nextPageLink) {
                        this._getMoreChannelOwners(this.accessList.ownersPag.nextPageLink);
                    }
                } else {
                    console.error("error getting channel owners", data)
                }
            });
    }

    _getChannelMembers() {
        // this.accessList.members = [];
        this._chManageService.getChannelMembersByChannelId(this.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.accessList.members = data.body;
                } else {
                    this.accessList.owners = []; // ? ... is'nt this members ... 
                }
            });
    }

    filterOfficers() {
        console.log("%c filterofficers ", consoleLogStyle);
        // this.filterLetter = null;
        console.log("officerlistfilter before", this.officerListFilter)
        this.officerListFilter = [];
        let filterBar: HTMLInputElement = <HTMLInputElement>document.getElementById('io-filter');
        if (filterBar.value !== '') {
            let filter = filterBar.value.toUpperCase().replace(/\s/g, '');
            if (this.officerList) {
                for (let i = 0; i < this.officerList.length; i++) {
                    let name = this.officerList[i].displayName;
                    if (name.toUpperCase().indexOf(filter) > -1) {
                        this.officerListFilter.push(this.officerList[i]);
                    }
                }
                console.log("officerlist", this.officerList);
            }
        } else { 
            this.officerListFilter = this.officerList;
            console.log("no val in input", this.officerListFilter)
        }
    }

    // setFilterLetter(letter: string) {
    //     this.filterLetter = letter;
    //     this.filterOfficersByLetter();
    // }

    // filterOfficersByLetter() {
    //     console.log("%c filterofficers by letter - ", consoleLogStyle, this.filterLetter);
    //     this.officerListFilter = [];
    //     if (this.officerList) {
    //         for (let i = 0; i < this.officerList.length; i++) {
    //             let fLetter = this.officerList[i].displayName[0]
    //             if (fLetter.toUpperCase() === this.filterLetter.toUpperCase()) {
    //                 this.officerListFilter.push(this.officerList[i]);
    //             }
    //         }
    //     }
    // }

    clearFilter() {
        this.officerListFilter = this.officerList;
        let filterBar: any = document.getElementById('io-filter');
        filterBar.value = "";
        // this.filterLetter = null;
    }

    overviewCheck() {
        if (this.selectedOfficer.intakeStatus !== "in-call") {
            if (this.selectedOfficer.overview.report == true &&
                this.selectedOfficer.overview.refferal == true &&
                this.selectedOfficer.overview.schedule == true) {
                this.selectedOfficer.intakeStatus = "ready";
            } else {
                this.selectedOfficer.intakeStatus = "pending";
            }
        }
    }

    _getIntakeOfficerShiftLogByIntakeUser() {
        this._intakeService.getIntakeOfficerShiftLogByIntakeUser(this._officer.intakeUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (data.body.length > 0) {
                        let updateSchedule = data.body[0];
                        this.updateSchedule = {
                            "intakeOfficerShiftLogId": "",
                            "startTime": updateSchedule.startTime,
                            "endTime": updateSchedule.endTime
                        }
                        let endTime = new Date(this.updateSchedule.endTime);
                        endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset());
                        if (this.dateCompare(endTime)) {
                            this.updateSchedule.intakeOfficerShiftLogId = updateSchedule.intakeOfficerShiftLogId;
                            this._intakeOfficerShiftLogId = this.updateSchedule.intakeOfficerShiftLogId;
                        } else {
                            this._intakeOfficerShiftLogId = null;
                        }
                        this.scheduleConverter();
                    } else {
                        this.scheduleReady = true;
                    }
                } else {
                    console.log(data.status);
                    this._toastService.error("Could not retrieve shift log.")
                }
            });
    }

    _postIntakeOfficerShiftLog() {
        this.newSchedule.intakeUserId = this._officer.intakeUserId;
        this.newSchedule.intakeSessionId = this._intakeSession.intakeSessionId;
        this._intakeService.postIntakeOfficerShiftLog(this.newSchedule)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log(res);
                    this._getIntakeOfficerShiftLogByIntakeUser();
                } else {
                    this._toastService.error("Schedule could not be created")
                    console.error(res)
                }
            });
    }

    _putIntakeOfficerShiftLog() {
        this.updateSchedule.intakeUserId = this._officer.intakeUserId;
        this.updateSchedule.intakeSessionId = this._intakeSession.intakeSessionId;
        this._intakeService.putIntakeOfficerShiftLog(this._intakeOfficerShiftLogId, this.updateSchedule)
            .subscribe(res => {
                if (res.status === 204) {
                    this._getIntakeOfficerShiftLogByIntakeUser();
                    // console.log(res);
                } else {
                    this._toastService.error("Schedule could not be changed")
                    console.error(res)
                }
            });
    }

    scheduleManager() {
        let schedule = this.selectSchedule;
        let newHours: any = {};
        newHours.fromHour = parseInt(schedule.fromHour, 10);
        newHours.toHour = parseInt(schedule.toHour, 10);
        let newStartTime: Date = new Date();
        if (schedule.fromDay === "pm") {
            if (schedule.fromHour !== 12) {
                newHours.fromHour += 12;
            }
        } else if (schedule.fromHour === 12) {
            newHours.fromHour = 0;
        }
        newStartTime.setHours(newHours.fromHour, schedule.fromMin, 0); // Auto converts to UTC when set to object
        let newEndTime: Date = new Date();
        if (schedule.toDay === "pm") {
            if (schedule.toHour !== 12) {
                newHours.toHour += 12;
            }
        } else if (schedule.toHour === 12) {
            newHours.toHour = 24;
        }
        newEndTime.setHours(newHours.toHour, schedule.toMin, 0); // Auto converts to UTC when set to object
        console.log("End Date: " + newEndTime);
        if (newStartTime.getTime() >= newEndTime.getTime()) {
            newEndTime.setDate(newEndTime.getDate() + 1)
        }
        if (this.dateCompare(newEndTime)) {
            this.scheduleError = false;
            if (this._intakeOfficerShiftLogId) {
                this.updateSchedule.startTime = newStartTime;
                this.updateSchedule.endTime = newEndTime;
                this._putIntakeOfficerShiftLog();
            } else {
                this.newSchedule.startTime = newStartTime;
                this.newSchedule.endTime = newEndTime;
                this._postIntakeOfficerShiftLog();
            }
            this._channelService.UpdateSchedule(this._app.appId, this.channelId);
        } else {
            this.scheduleError = true;
        }
    }

    scheduleConverter() {
        let startTime = new Date(this.updateSchedule.startTime);
        let endTime = new Date(this.updateSchedule.endTime);
        // console.log(startTime);
        // console.log(endTime);
        startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset()); //Converts back to Local
        endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset()); //Converts back to Local
        this.selectSchedule.fromDate = startTime;
        this.selectSchedule.toDate = endTime;
        this.selectSchedule.fromHour = startTime.getMinutes()
        if (startTime.getHours() < 13) {
            if (startTime.getHours() === 0) {
                this.selectSchedule.fromHour = 12
                this.selectSchedule.fromDay = 'am'
            } else {
                this.selectSchedule.fromHour = startTime.getHours()
                if (startTime.getHours() === 12) {
                    this.selectSchedule.fromDay = 'pm'
                } else {
                    this.selectSchedule.fromDay = 'am'
                }
            }
        } else {
            this.selectSchedule.fromHour = startTime.getHours() - 12
            this.selectSchedule.fromDay = 'pm'
        }
        this.selectSchedule.toHour = endTime.getMinutes()
        if (endTime.getHours() < 13) {
            if (endTime.getHours() === 0) {
                this.selectSchedule.toHour = 12
                this.selectSchedule.toDay = 'am'
            } else {
                this.selectSchedule.toHour = endTime.getHours()
                if (endTime.getHours() === 12) {
                    this.selectSchedule.toDay = 'pm'
                } else {
                    this.selectSchedule.toDay = 'am'
                }
            }
        } else {
            this.selectSchedule.toHour = endTime.getHours() - 12
            this.selectSchedule.toDay = 'pm'
        }
        this.scheduleReady = true;
        if (this._intakeOfficerShiftLogId) {
            this.showSchedule = true;
        }
    }

    dateCompare(dateCheck) {
        let now = new Date();
        if (dateCheck.getTime() > now.getTime()) {
            return true;
        } else {
            return false;
        }
    }

    changeSchedule() {
        if (this.showSchedule) {
            this.showSchedule = false;
        } else {
            this.scheduleManager();
        }
    }

    onVisibilityChange() {
        if (this.channelId) {
            if (document.hidden) {
                //console.log("Hidden " + this.channelId);
                this._channelService.sendCommand("/leave", this.channelId);
            }
            else {
                //console.log("SHOWN " + this.channelId);
                this._channelService.join(this.channelId);
            }
        }
    }

    // files modal // 
    showFilesModal() {
        this._showFiles = (this._showFiles) ? false : true;
    }

    propmtDeleteFiles() {
        // toast shoulding show while in the intake group chat
        if(this.selectedOfficer.displayName !== 'Intake Group Chat')
            this._toastService.continue("Would you like to end this session, and DELETE ALL chat history and shared files?", "intakeDeleteFiles");
        else
            console.log("Tried to end session in the group chat");
    }
    // end session new w/ file delete confirm // 
    endSessionFileDelete() {
        this.isSessionEnding = true;
        // end && delete files 
        // 🚧 ... 
        this._deleteFiles();
        this._channelService.hangUpCallEvent();
        this._deleteIntakeSessionUser(this.selectedOfficer.intakeSessionUserId, this._app.appId, this.selectedOfficer.chatRoomId);
    }

    _deleteFiles() {
        this._fileStorageService.deleteTempIntakeFiles(this.channelInfo.channelId)
            .subscribe(res => {
                if (res.status === 200) {
                    console.log(res);
                } else {
                    this._toastService.error("There was an error deleting intake files.");
                    console.error("error deleting file", res)
                }
            });
    }

    _deleteIntakeSessionUser(intakeSessionUserId: any, appId: any, channelId: any) {
        //console.log("session ended call to service >")
        this._channelService.deleteIntakeSessionUser(intakeSessionUserId, appId, channelId);
    }
    // ^ end session ^ //

    // to close all modals | add switch if needed to close milti ... 
    clickOffModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        // console.log("%c e.t, e.srcel, e.curtar ", consoleLogStyle, event.target, event.srcElement, event.currentTarget)
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
            // console.log("%c tar.id? ,val? ", consoleLogStyle, idAttr, value)
        }
        switch (value) {
            case 'intake-files-modal': {
                this.showFilesModal();
                break;
            }
            default: return;
        }
    }
}



// ❌ old file popup funcs ... 

    // // upload event emitter .html line 455 //
    // uploadChange(isUploading:boolean) {
    //     this.fileIsUploading = isUploading;
    // }

    // temp files
    // filesInit() {
    //     this.downloadFile = {
    //         "roomOwnerUserId": "",
    //         "channelId": "",
    //         "path": "",
    //         "rev": ""
    //     }
    // }

    // _downloadSharedFile(filePath: string) {
    //     this.downloadFile.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
    //     this.downloadFile.path = filePath;
    //     this.downloadFile.channelId = this.channelInfo.channelId;
    //     this._fileStorageService.fileStorageDownloadSharedFile(this.downloadFile)
    //         .subscribe(data => {
    //             if (data.status === 200) {
    //                 console.log(data);
    //                 // window.open(data.body, '_blank') //open
    //                 this.createDownloadElement(data.body);
    //                 this.filesInit();
    //             } else {
    //                 this._toastService.error("File failed to download");
    //                 // alert(JSON.stringify(data));
    //             }
    //         });
    // }

    // createDownloadElement(link: any) {
    //     // alert(link);
    //     var element = document.createElement('a');
    //     element.setAttribute('href', link);
    //     element.setAttribute('download', link);
    //     element.setAttribute('target', '_blank');
    //     element.style.display = 'none';
    //     document.body.appendChild(element);
    //     element.click();
    //     document.body.removeChild(element);
    // }

    // _sharedFileList() {
    //     this.sharedFileListQuery.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
    //     this.sharedFileListQuery.projectName = this.channelInfo.teamProjectKeyNavigation.name;
    //     this.sharedFileListQuery.roomName = this.channelInfo.name;
    //     this._fileStorageService.listSharedFiles(this.sharedFileListQuery)
    //         .subscribe(data => {
    //             if (data.status === 200) {
    //                 this.fileList = data.body.entries;
    //             } else {
    //                 this._toastService.error("Files failed to load.");
    //                 console.error(data);
    //             }
    //         });
    // }

    // _softDeleteFile(filePath: string) {
    //     this._fileStorageService.softDeleteFile(filePath, this.channelInfo.channelId)
    //         .subscribe(res => {
    //             // alert(JSON.stringify(res));
    //             this._sharedFileList();
    //         });
    // }


// ❌ other un-used funcs ... 

    // emitConnectionStatus(status) {
    //     this.connectionStatus.emit(status);
    // }

        //selectFirstOfficer() {
    //    if (this.officerList && this.officerList.length > 0) {
    //      //alert("select first officer ");
    //       // this.selectedOfficer = this.officerList[0];
    //        this.officerListFilter = this.officerList;
    //        this._getOfficer(0);
    //        // this.officerReady = true;
    //    } else {
    //        this._officersNotAvailable();
    //    }
    //}