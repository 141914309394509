import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'amp',
  templateUrl: './amp-player.component.html',
  styleUrls: ['./amp-player.component.scss']
})
export class AmpPlayerComponent implements OnInit {
  @ViewChild('video', { static: true }) videoPlayer;
  @Input() vId;
  @Input() src;
  @Input() autoplay;
  @Input() controls;
  @Output() ended: EventEmitter<any> = new EventEmitter();
  @Output() seeking: EventEmitter<any> = new EventEmitter();
  public version: string = "2.2"

  constructor() { }

  ngOnInit() {
    console.log(this.videoPlayer);
    var liveeventurl = this.src;

    //alert(JSON.stringify(liveeventurl));

    if (liveeventurl) {
      //liveeventurl = liveeventurl;


      var myOptions = {
        "nativeControlsForTouch": false,
        autoplay: JSON.parse(this.autoplay),
        controls: JSON.parse(this.controls),
        // width: "100%",
        // height: "auto",
        fluid: true,
        id: this.vId,
        logo: {
          enabled: false
        },
        playbackSpeed: {
          enabled: true,
          initialSpeed: 1.0,
          speedLevels: [
            { name: "x4.0", value: 4.0 },
            { name: "x3.0", value: 3.0 },
            { name: "x2.0", value: 2.0 },
            { name: "x1.75", value: 1.75 },
            { name: "x1.5", value: 1.5 },
            { name: "x1.25", value: 1.25 },
            { name: "normal", value: 1.0 },
            { name: "x0.75", value: 0.75 },
            { name: "x0.5", value: 0.5 },
          ]
        },
        poster: ""
      };
      console.log(JSON.stringify(myOptions));
      //var myOptions = {
      //    autoplay: true,
      //    controls: true,

      //    width: "1280",
      //    height: "720",
      //    poster: ""
      //};

      var myPlayer = amp(this.videoPlayer.nativeElement, myOptions);
      myPlayer.currentTime(30);




      //var myPlayer = amp("azuremediaplayer", myOptions);
      ////myPlayer.currentTime(30);
      myPlayer.src([{ src: "//" + liveeventurl, type: "application/vnd.ms-sstr+xml" },]);

      //var myPlayer = amp("azuremediaplayer1", myOptions);
      //myPlayer.src([{ src: "//" + liveeventurl, type: "application/vnd.ms-sstr+xml" },]);
    }


    ////var liveeventurl = "via3media.streaming.mediaservices.windows.net/eb6b5d6e-dbec-4b39-8510-47cbc6a38b12/New Features - Part 2 - 2017-08-22 01-34-19.ism/manifest";

    //// Dynamically load the amp player control
    //var myPlayer = amp(this.videoPlayer.nativeElement, {
    //  /* Options */
    //  //"nativeControlsForTouch": false,
    //  autoplay: this.autoplay,
    //  controls: true,
    //  //sourceList: [{ src: "//" + liveeventurl, type: "application/vnd.ms-sstr+xml" },],
    //  //width: this.width,
    //  //height: this.height,
    //  //id: this.id,
    //  logo: { enabled: false },
    //  //ampAds: {
    //  //  preRoll: {
    //  //    sourceUri: '//sr-test.azurewebsites.net/Plugins/AMP2/Preroll_amp/vast_test.xml', //be sure to include your own VAST compliant ad tag provided from your Ad Server. this is a hardcoded VAST for demo purposes 
    //  //    options:
    //  //    {
    //  //      skipAd:
    //  //      {
    //  //        enabled: true,
    //  //        offset: 3
    //  //      }
    //  //    }
    //  //  },
    //  //  midRoll: [
    //  //    {
    //  //      sourceUri: '//sr-test.azurewebsites.net/Plugins/AMP2/Preroll_amp/vast_test.xml',//be sure to include your own VAST compliant ad tag provided from your Ad Server. this is a hardcoded VAST for demo purposes 
    //  //      startTime: 50,
    //  //      options:
    //  //      {
    //  //        skipAd:
    //  //        {
    //  //          enabled: true,
    //  //          offset: 3
    //  //        }
    //  //      }
    //  //    }
    //  //  ],
    //  //  mainProgram: {
    //  //    source: [{
    //  //      src: this.src
    //  //    }]
    //  //  }
    //  //}
    //}
    //);

    //myPlayer.ngComponent = this;

    //// Add playback ended event listener
    //myPlayer.addEventListener('ended', this.amp_ended);
    //myPlayer.addEventListener('seeking', this.amp_seeking);

  }

  amp_ended() {
    console.log("AMP::Playback ended");
    //console.log(this.ngComponent.version);
    //console.log(this.ngComponent.ended);
    //this.ngComponent.ended.emit(null);
  }

  amp_seeking() {
    console.log("AMP::Seek event");
    //this.ngComponent.seeking.emit(this.currentTime());
  }

}
