import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "channelCardAvatars",
})
export class ChannelCardAvitars implements PipeTransform {
    transform(value: any[]): any[] {
        // console.log(" reverse pipe value, val.sli 0, 8 .rev ::::: ", value, value.slice(0, 9).reverse())
        return value.reverse();
    }
}
