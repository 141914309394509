<!-- <span *ngIf="ticks < 60000">a few seconds ago</span>

<span *ngIf="ticks < 3600000 && ticks >= 60000">{{min()}} minute<span *ngIf="min() > 1">s</span> ago </span>

<span *ngIf="ticks < 86400000 && ticks >= 3600000">{{hour()}} hour<span *ngIf="hour() > 1">s</span> ago </span>

<span *ngIf="ticks < 604800000 && ticks >= 86400000">{{day()}} day<span *ngIf="day() > 1">s</span> ago </span>

<span *ngIf="ticks < 2.6280E+9 && ticks >= 604800000">{{week()}} week<span *ngIf="week() > 1">s</span> ago
</span>

<span *ngIf="ticks < 3.1536E+10 && ticks >= 2.6280E+9">{{month()}} month<span *ngIf="month() > 1">s</span> ago
</span>

<span *ngIf="ticks >= 3.1536E+10">{{year()}} year<span *ngIf="year() > 1">s</span> ago </span> -->


<!-- <span *ngIf="ticks < 60000">just now</span>

<span *ngIf="ticks < 3600000 && ticks >= 60000">
    {{min()}} min<span *ngIf="min() > 1">s</span> ago 
</span>

<span *ngIf="ticks < 86400000 && ticks >= 3600000">
    Today {{ orderTime | date:'h:mm a'}}
</span>

<span *ngIf="ticks < 604800000 && ticks >= 86400000">
    <span *ngIf="day() < 2"> Yesturday {{ orderTime | date:'h:mm a'}}</span>
    <span *ngIf="day() > 1">{{ orderTime | date:'EEEE h:mm a'}}</span>
</span>

<span *ngIf="ticks < 2.6280E+9 && ticks >= 604800000">
    {{ orderTime | date:'M/d/yy h:mm a'}}
</span>

<span *ngIf="ticks < 3.1536E+10 && ticks >= 2.6280E+9">
    {{ orderTime | date:'M/d/yy h:mm a'}}
</span>

<span *ngIf="ticks >= 3.1536E+10">
    {{ orderTime | date:'M/d/yy h:mm a'}}
</span> -->

<span *ngIf="midnight()">
    Today
</span>

<span *ngIf="midnightBefore() && !midnight()">
    Yesterday
</span>

<span *ngIf="!midnightBefore() && ticks < 604800000">
    {{ orderTime | date:'EEEE'}}
</span>

<span *ngIf="ticks >= 604800000">
    <!-- {{ orderTime | date:'M/d/yy'}} -->
    {{ orderTime | date:'MMM d, y'}}
</span>
