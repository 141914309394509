import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; //Import for Forms
import { ToastNotificationService } from '../services/toast-notification.service'; //Import for Toast Notifications

@Component({
    selector: 'app-element-examples',
    templateUrl: './element-examples.component.html',
    styleUrls: ['./element-examples.component.scss']
})
export class ElementExamplesComponent implements OnInit {
    //Properties needed for Modals
    public displayModal: boolean = false;
    public displaySelectModal: boolean = false;

    //Properties needed for Forms
    public formGroupExample: FormGroup;

    //properties for display purposes
    public disableButtons = false;
    public radio = 'radio1';
    public todaysDate: Date = new Date();
    public minDate: Date;
    public maxDate: Date;

    constructor(
        private _formBuilder: FormBuilder, //Import for Forms
        private _toastService: ToastNotificationService, //Import for Toast
    ) { 
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(this.todaysDate);
        this.maxDate = new Date(currentYear + 1, 11, 31);
    }

    ngOnInit(): void {
        this.createForm();
    }

    //Methods for display purposes
    public toggleDisableButtons() {
        this.disableButtons = this.disableButtons ? false : true;
    }


    //Methods needed for Modals
    public showModal() {
        this.displayModal = true;
    }

    public closeModal() {
        this.displayModal = false;
    }

    public clickOffModalEvent(event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }

        if (value === "modal-id") {
            this.closeModal();
        }
    }

    //Methods needed for Custom Select Modal
    showSelectModal() {
        this.displaySelectModal = true;
    }
    closeSelectModal() {
        this.displaySelectModal = false;
    }

    ///Methods for Forms
    createForm() {
        this.formGroupExample = this._formBuilder.group({
            inputTextExample: [''],
            inputTextExampleRequired: ['', [Validators.required]],
            selectExample: ['']
        });
    }

    get formControls() { return this.formGroupExample.controls; }

    formSubmitted() {
        alert(JSON.stringify(this.formGroupExample.value));
    }


    //Examples of Toast Notifications
    public toastExamples() {
        this._toastService.showToastExamples();
    }

}
