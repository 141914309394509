import { Component, OnInit } from '@angular/core';
import { ToastNotification, ToastInfoType, ToastWarningType, ToastNotificationType } from "../toast-notification.model";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { toastNotification } from "../../animations/global-animations";



@Component({
    selector: 'toast-notification',
    templateUrl: './toast-notification.component.html',
    styleUrls: ['./toast-notification.component.scss'],
    animations: [toastNotification]
})

export class ToastNotificationComponent implements OnInit {

    notifications: ToastNotification[] = [];
    private errorSound: any;
    private warningSound: any;
    public toastContinue = ToastWarningType.Continue;
    public toastContinueLink = ToastWarningType.ContinueLink;

    constructor(
        public _toastService: ToastNotificationService,
    ) {
        this._toastService.onToastExamplesEvent$.subscribe(() => this.toastExmples())
    }

    ngOnInit() {
        this.errorSound = new Audio();
        this.errorSound.src = '../../../assets/audio/error00.mp3';

        this.warningSound = new Audio();
        this.warningSound.src = '../../../assets/audio/warning00.mp3';

        this.notifications = [];
        this._toastService
            .getAlert()
            .subscribe((alert: ToastNotification) => {
                // this.notifications = [];  
                if (!alert) {
                    this.notifications = [];
                    return;
                }

                this.audioPlay(alert);

                if (!alert.disableTimeOut) {
                    setTimeout(() => {
                        this.notifications = this.notifications.filter(x => x !== alert);
                    }, 7500);//Was 5000
                } else {
                    this.notifications = this.notifications.filter(x => x.data !== alert.data);
                }

                this.notifications.push(alert);
            });

        // For Testing Styles
        // this.toastExmples();
    }

    removeNotification(notification: ToastNotification) {
        this.notifications = this.notifications.filter(x => x !== notification);
    }

    /**Set css class for Alert -- Called from alert component**/
    notificationClass(notification: ToastNotification) {
        if (!notification) {
            return;
        }
        switch (notification.type) {
            case ToastNotificationType.Success:
                return 'toast-success';
            case ToastNotificationType.Error:
                return 'toast-error';
            case ToastNotificationType.Info:
                return 'toast-info';
            case ToastNotificationType.Warning:
                return 'toast-warning';
            default:
                return;
        }
    }

    iconClass(notification: ToastNotification) {
        if (!notification) {
            return;
        }
        switch (notification.type) {
            case ToastNotificationType.Success:
                return 'far fa-check';
            case ToastNotificationType.Error:
                return 'far fa-times';
            case ToastNotificationType.Info:
                switch (notification.infoType) {
                    case ToastInfoType.File:
                        return 'fas fa-file-alt';
                    default:
                        return 'fas fa-bell';
                }
            case ToastNotificationType.Warning:
                return 'far fa-exclamation-triangle';
        }
    }

    audioPlay(notification: ToastNotification) {
        // console.log("alert type = ", ?);  
        if (!notification) {
            return;
        }
        switch (notification.type) {
            case ToastNotificationType.Success:
                return;
            case ToastNotificationType.Error:
                return this.errorSound.play();
            case ToastNotificationType.Info:
                switch (notification.infoType) {
                    case ToastInfoType.Default:
                    case ToastInfoType.User:
                    case ToastInfoType.Enter:
                    case ToastInfoType.Leave:
                    case ToastInfoType.File:
                        return;
                }
            case ToastNotificationType.Warning:
                return this.warningSound.play();
        }
    }

    titleDisplay(notification: ToastNotification) {
        if (!notification) {
            return;
        }
        switch (notification.type) {
            case ToastNotificationType.Success:
                return 'Success';
            case ToastNotificationType.Error:
                return 'Error';
            case ToastNotificationType.Info:
                switch (notification.infoType) {
                    case ToastInfoType.Default:
                        return 'Info';
                    case ToastInfoType.User:
                    case ToastInfoType.Enter:
                    case ToastInfoType.Leave:
                        return notification.name;
                    case ToastInfoType.File:
                        return 'Channel Files';
                    default:
                        return 'Info';
                }
            case ToastNotificationType.Warning:
                return "Warning";
        }
    }

    checkContinue(notification: ToastNotification) {
        if (!notification) {
            return false;
        }
        switch (notification.warningType) {
            case ToastWarningType.Continue:
            case ToastWarningType.ContinueLink:
                return true;
            default:
                return false;
        }
    }

    resolveToast(notification: ToastNotification) {
        let type = notification.data;
        this._toastService.completeToastResolveEvent(type, notification.checkboxConfirm);
        this.removeNotification(notification);
    }

    toastExmples() {
        this.notifications = [
            <ToastNotification>{
                type: ToastNotificationType.Success,
                message: "Message",
                disableSound: true
            },
            <ToastNotification>{
                type: ToastNotificationType.Error,
                message: "Message",
                disableSound: true
            },
            <ToastNotification>{
                type: ToastNotificationType.Info,
                message: "Message",
                disableSound: true
            },
            <ToastNotification>{
                type: ToastNotificationType.Info,
                infoType: ToastInfoType.File,
                message: "Message",
                disableSound: true
            },
            <ToastNotification>{
                type: ToastNotificationType.Warning,
                warningType: ToastWarningType.Continue,
                checkboxMessage: "CheckBox Message",
                checkboxConfirm: false,
                message: "Message",
                disableSound: true
            },
        ];
    }
}
//