
<div class="login-page">
    <div class="login-wrap flex">
        <form 
            [formGroup]="loginForm"
            (document:keydown.enter)="((loginForm.valid || isAutofilled) && !isLoggingIn && !loginConfirmed) ? login() : ''"
            class="login-form flex-col align-center m-auto">
            <h3 class="mb-4">Login to <img src="{{logoLogin.logoblue}}" alt="via-logo" class="via-logo ms-2"></h3>
            <input 
                #username
                autofocus
                (onautocomplete)="autofillName($event)"
                id="username"
                name="username"
                formControlName="username"
                class="my-3 login" type="text" placeholder="Email / Username">
            <div class="password-group">
                <input
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    (onautocomplete)="autofillPass($event)"
                    (keyup)="capsLockCheck($event)"
                    (blur)="hideCapsWarn()"
                    id="password"
                    name="password"
                    formControlName="password"
                    #password
                    class="mb-3 login" placeholder="Password">
                <i  
                    *ngIf="!isPasswordVisible"
                    (click)="showPassword()"
                    class="fas fa-eye-slash the-eye"
                    matTooltip="show password"
                    matTooltipClass="tooltip-default">
                </i>
                <i  
                    *ngIf="isPasswordVisible"
                    (click)="showPassword()"
                    class="fas fa-eye the-eye fix"
                    matTooltip="hide password"
                    matTooltipClass="tooltip-default">
                </i>
            </div>
            <!-- error messages -->
            <div class="message-wrap">
                <ng-container *ngIf="!isLoggingIn && !loginConfirmed">
                    <div *ngIf="isCapsOn" class="invalid-feedback">
                        <div class="caps-warn text-center"><i class="fas fa-exclamation-triangle caps-warn"></i>&nbsp;Caps lock is on.</div>
                    </div>
                    <div *ngIf="loginFormControls.username.touched && loginFormControls.username.errors" [class.mt-2]="isCapsOn" class="invalid-feedback">
                        <div class="error-message text-center" *ngIf="loginFormControls.username.errors.required">Username is required.</div>
                        <div class="error-message text-center" *ngIf="loginFormControls.username.errors.minlength">Username must be at least 2 characters.</div>
                    </div>
                    <div *ngIf="loginFormControls.password.touched && loginFormControls.password.errors" [class.mt-2]="isCapsOn || loginFormControls.username.touched && loginFormControls.username.errors" class="invalid-feedback">
                        <div class="error-message text-center" *ngIf="loginFormControls.password.errors.required">Password is required.</div>
                        <div class="error-message text-center" *ngIf="loginFormControls.password.errors.minlength">Password must be at least 8 characters.</div>
                        <div class="error-message text-center" *ngIf="loginFormControls.password.errors.pattern && !loginFormControls.password.errors.minlength">Password must include: 1 lowercase,<br>1 uppercase, 1 number & 1 special character.</div>
                    </div>
                    <div [class.mt-2]="isCapsOn || loginFormControls.username.touched && loginFormControls.username.errors || loginFormControls.password.touched && loginFormControls.password.errors" class="invalid-feedback">
                        <div *ngIf="loginError && !isAccountLockedOut && emailConfirmed" class="caps-warn text-center"><i class="fas fa-exclamation-triangle caps-warn"></i>&nbsp;Invalid credentials.</div>
                        <div *ngIf="loginError && !isAccountLockedOut && !emailConfirmed" class="caps-warn text-center"><i class="fas fa-exclamation-triangle caps-warn"></i>&nbsp;Your email has not been confirmed.</div>
                        <div *ngIf="loginError && loginAttemptsLeft && !isAccountLockedOut && emailConfirmed" class="caps-warn text-center">You have {{loginAttemptsLeft}} attempt<ng-container *ngIf="loginAttemptsLeft > 1">s</ng-container> left. </div>
                        <div *ngIf="isAccountLockedOut" class="error-message text-center"><i class="fas fa-user-lock error-message"></i>&nbsp;You have been locked out,<br>please contact your administrator.</div>
                    </div>
                </ng-container>
            </div>
            <!-- submit button -->
            <!-- [disabled]="loginForm.invalid && !isLoggingIn && !loginConfirmed && !isAutofilled && !emailConfirmed" -->
            <!-- (click)="((loginForm.valid || isAutofilled) && !isLoggingIn && !loginConfirmed) ? login() : ''" -->
            <button 
                [disabled]="(loginForm.invalid && !isAutofilled) || isLoggingIn || loginConfirmed"
                type="button"
                class="mb-2 primary-button"
                matRipple
                [matRippleColor]="loginRippleColor"
                [matRippleDisabled]="(loginForm.invalid && !isAutofilled) || isLoggingIn || loginConfirmed"
                [class.invalid-form]="(loginForm.invalid && !isAutofilled) || isLoggingIn || loginConfirmed"
                (click)="login()">
                <!--  -->
                <ng-container *ngIf="!isLoggingIn && !loginConfirmed">LOGIN</ng-container>
                <ng-container *ngIf="isLoggingIn"><i class="fas fa-spinner spinner"></i>&nbsp;LOGGING IN . . .</ng-container>
                <ng-container *ngIf="loginConfirmed"><i class="fas fa-check"></i>&nbsp;LOGIN SUCCESS !</ng-container>
            </button>
            <!--  -->
            <div class="account-links mt-4">
                <a href="{{accountSiteRegister}}" class="mb-2 mx-2" style="margin-left: 1px;">Create Account</a>
                <a href="{{accountSiteForgotPassWord}}" class="mb-2">Forgot Password</a>
            </div>
        </form>
        <!--  -->
        <!-- <span class="border-mid"></span> -->
        <!-- 🎉 TEMP FOR CONFERENCE -->
        <!-- <div class="conference flex-col align-center my-auto me-auto ms-5">
            <h3 class="mb-4">Upcoming Events</h3>
            <img class="conf-logo" src="https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/NET-INCLUSION-2022-LOGO.png" alt="conf-logo">
            <p class="mt-3 nida-info">
                Three days of livestreamed sessions from Portland State University, featuring digital inclusion community practitioners, advocates, academics, Internet service providers, and policymakers.
            </p>
            <button class="primary-button" routerLink="/conference-signin">
                SIGN IN
            </button>
        </div> -->
    </div>
    <!--  -->
    <div class="footer">
        <p>
            <a href="{{accountSite}}download" target="_blank">
                <strong class="me-2 secondary-text">Download App</strong>
            </a>
            &copy;&nbsp;{{ currentYear }},&nbsp;VIA3&nbsp;Corporation.&nbsp;All&nbsp;rights&nbsp;reserved. 
            <a href="{{accountSite}}terms-of-use" target="_blank" class="mx-1">Terms of Use</a>&nbsp;|&nbsp;
            <a href="{{accountSite}}privacy-policy" target="_blank">Privacy Policy</a>
        </p>
    </div>
</div>

