import { Component } from "@angular/core";



@Component({
    selector: "loading-home",
    templateUrl: "./loading-home.component.html",
    styleUrls: ["./loading-home.component.scss"],
})
export class LoadingHomeComponent {
    // loading spinner for home page columns ...
}
