import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meetingDuration'
})
export class MeetingDurationPipe implements PipeTransform {

  transform(value: number): string {
    if(value === 15) return '15 min';
    if(value === 30) return '30 min';
    if(value === 60) return '1 hr';
    if(value === 120) return '2 hrs';
  }
}
