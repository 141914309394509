<div class="user-bar scrollbar-new">
    <div class="user-nav">
        <button
            class="user-nav-btn"
            (click)="closeSideBar()"
            matTooltip="close sidebar"
            matTooltipClass="tooltip-default"
        >
            <i
                class="fas"
                [ngClass]="{
                    'fa-user-circle': _channelService._isDesktop,
                    'fa-arrow-left': !_channelService._isDesktop
                }"
            ></i>
        </button>
        <h5 class="user-nav-title">Profile Manager</h5>
        <!-- <button
			class="me-1 flex-center-all pin-side-button"
			[class.pinned]="pinnedSideBar"
			(click)="pinToggle()"
		>
			<i *ngIf="!pinnedSideBar" class="far fa-thumbtack"></i>
			<i *ngIf="pinnedSideBar" class="fas fa-thumbtack"></i>
		</button> -->
    </div>
    <!--  -->
    <div class="user-container">
        <div class="user-img">
            <div class="change-photo" (click)="displayPhotoEditor()">
                <i class="fas fa-camera"></i>
            </div>
            <img
                src="https://profile-images.azureedge.us/{{ userInfo.hash }}"
                alt="Profile Picture"
                onerror="this.src='https://profile-images.azureedge.us/1'"
            />
        </div>
        <div class="user-info">
            <p
                class="user user-name"
                [matTooltip]="userInfo.displayName"
                matTooltipClass="tooltip-default"
            >
                {{ userInfo.displayName }}
            </p>
            <p
                class="user user-username"
                [matTooltip]="userInfo.displayName"
                matTooltipClass="tooltip-default"
            >
                {{ userInfo.username }}
            </p>
            <p
                class="user user-email"
                [matTooltip]="userInfo.email"
                matTooltipClass="tooltip-default"
            >
                {{ userInfo.email }}
            </p>
        </div>
    </div>
    <div class="options-container">
        <!-- Status Change -->
        <p
            class="option option-list-display {{ userStatus }}"
            (click)="expandStatusList()"
            [class.showList]="showStatusList"
        >
            <span class="status-display">{{ userStatus | statusDisplay }}</span>
            <i class="fas fa-chevron-right flex-center-all"></i>
        </p>
        <div class="menu-list" [class.showList]="showStatusList">
            <p class="status-select online" (click)="changeStatus(0)">
                <span class="status-dot"></span>
                Online
            </p>
            <p class="status-select hidden" (click)="changeStatus(1)">
                <span class="status-dot"></span>
                Invisible
            </p>
            <p class="status-select dnd" (click)="changeStatus(2)">
                <span class="status-dot"></span>
                Do Not Disturb
            </p>
            <p class="status-select brb" (click)="changeStatus(3)">
                <span class="status-dot"></span>
                Be Right Back
            </p>
            <!-- <p class="status-select brb" (click)="changeStatus(3)">Away</p> -->
        </div>

        <!-- Account Settings -->
        <p
            class="option option-list-display"
            (click)="expandAccountList()"
            [class.showList]="showAccountList"
        >
            <span class="status-display">Account Settings</span>
            <i class="fas fa-chevron-right flex-center-all"></i>
        </p>
        <div class="menu-list" [class.showList]="showAccountList">
            <p class="item-select" (click)="displayPasswordEditor()">
                Change Password
            </p>
            <p class="item-select" (click)="displayDisplayNameEditor()">
                Change Display Name
            </p>
            <p class="item-select" (click)="displayEmailModal()">
                Change Email
            </p>
        </div>

        <!-- Organization Info -->
        <p
            class="option option-list-display"
            (click)="toggleOrganizationList()"
            [class.showList]="showOrganizationList"
        >
            <span class="status-display">Organization Info</span>
            <i class="fas fa-chevron-right flex-center-all"></i>
        </p>
        <div class="menu-list" [class.showList]="showOrganizationList">
            <p class="item-static">Name: {{ organizationInfo.tenantName }}</p>
            <p
                class="item-static-click"
                (click)="copyCode()"
                [matTooltip]="'click to copy code'"
                matTooltipClass="tooltip-default"
            >
                Invite Code: {{ organizationInfo.tenantCode }}
            </p>
            <p class="item-static">Users: {{ organizationInfo.userCount }}</p>
        </div>

        <!-- Theme Manager -->
        <p
            class="option option-list-display"
            (click)="expandThemeList()"
            [class.showList]="showThemeList"
        >
            <span class="status-display">Theme Manager</span>
            <i class="fas fa-chevron-right flex-center-all"></i>
        </p>
        <div class="menu-list" [class.showList]="showThemeList">
            <p
                class="item-select"
                [class.selected]="themeInit === 'theme-dark'"
                (click)="changeTheme('theme-dark')"
            >
                Dark Mode
            </p>
            <p
                class="item-select"
                [class.selected]="themeInit === 'theme-light'"
                (click)="changeTheme('theme-light')"
            >
                Light Mode
            </p>
            <p
                class="item-select"
                [class.selected]="themeInit === 'theme-reader'"
                (click)="changeTheme('theme-reader')"
            >
                Reader Mode
            </p>
        </div>

        <!-- Getting Started -->
        <a (click)="displayGettingStartedPage()" class="option option-link">
            How To Get Started
        </a>

        <!-- alert settings -->
        <!-- routerLink wont work here for some reason ... -->
        <!-- routerLink="/alert-settings" -->
        <!--  -->
        <a 
            (click)="routeToAccountSettings()"
            class="option option-link">
            Alert Settings
        </a>
        <!-- Admin -->
        <a
            (click)="displayAdminPage()"
            class="option option-link"
            *ngIf="_authService._userHighestRole === 'Admin' || _authService._userHighestRole === 'Manager'"
        >
            Admin Panel
        </a>

        <!--❌ <a (click)="routeToSupportPage()" class="option option-link">Support</a>-->

        <p class="option sign-out" (click)="signOut()">Sign Out</p>
    </div>
</div>

<!-- modals -->
<!-- 🚧 change photo modal -->
<div
    *ngIf="showPhotoEditor"
    class="modal"
    id="photo-editor-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div class="modal-body" (document:keydown.escape)="hidePhotoEditor()">
        <div class="top-buttons">
            <h4 class="modal-title"></h4>
            <button class="close-btn" (click)="hidePhotoEditor()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="editor-body">
            <div class="user-img circle mb-4">
                <img
                    src="https://profile-images.azureedge.us/{{
                        userInfo.hash
                    }}"
                    alt="Profile Picture"
                    onerror="this.src='https://profile-images.azureedge.us/1'"
                />
            </div>
            <div class="hidden-input" [class.hidden-loading]="imgIsUploading">
                <profile-img-upload
                    [userId]="userInfo.channelUserId"
                    (uploadStatus)="profileImgChange($event)"
                    (complete)="completeProfileImageSubmit($event)"
                >
                </profile-img-upload>
            </div>
            <!-- no need for hidden input to overlay the button with this displayPhotoEditorInput() ... -->
            <button
                type="button"
                class="primary-button w-20rem"
                (click)="displayPhotoEditorInput()"
                [disabled]="imgIsUploading"
            >
                <ng-container
                    *ngIf="!imgIsUploading && userInfo.hash === ('1' || null)"
                >
                    Upload Photo
                </ng-container>
                <ng-container
                    *ngIf="
                        !imgIsUploading &&
                        !!userInfo.hash &&
                        userInfo.hash !== '1'
                    "
                >
                    Change Photo
                </ng-container>
                <ng-container *ngIf="imgIsUploading">
                    <i *ngIf="imgIsUploading" class="fas fa-spinner spinner"></i
                    >&nbsp;Updating Photo
                </ng-container>
            </button>
            <button
                class="cancel-button w-20rem mt-2"
                *ngIf="!!userInfo.hash && userInfo.hash !== '1'"
                (click)="_removeProfilePhoto()"
            >
                <ng-container *ngIf="!isRemovingImg">
                    Remove Photo
                </ng-container>
                <ng-container *ngIf="isRemovingImg">
                    <i class="fas fa-spinner spinner"></i>&nbsp;Removing Photo
                </ng-container>
            </button>
        </div>
    </div>
</div>

<!-- 🚧 change password modal -->
<div
    *ngIf="showPasswordEditor"
    class="modal"
    id="password-editor-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div
        class="modal-body forms"
        (document:keydown.escape)="hidePasswordEditorConfirm()"
    >
        <div class="top-buttons mb-4">
            <h4 class="modal-title">Change Password</h4>
            <button class="close-btn" (click)="hidePasswordEditorConfirm()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="form-manager">
            <form [formGroup]="passwordChange" class="flex-col">
                <input
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    (keyup)="capsLockCheck($event)"
                    (blur)="isCapsOn = false"
                    id="currentPassword"
                    class=""
                    name="currentPassword"
                    placeholder="Current Password"
                    formControlName="currentPassword"
                    maxlength="51"
                    #currentPassword
                />
                <i
                    *ngIf="!isPasswordVisible"
                    (click)="showPasswordText()"
                    class="fas fa-eye-slash the-eye"
                    matTooltip="Show password"
                    matTooltipClass="tooltip-default"
                >
                </i>
                <i
                    *ngIf="isPasswordVisible"
                    (click)="showPasswordText()"
                    class="fas fa-eye the-eye fix"
                    matTooltip="hide password"
                    matTooltipClass="tooltip-default"
                >
                </i>
                <div class="password-requirements my-3">
                    <p>Password must contain 8 characters, including...</p>
                    <ul>
                        <p>&bull; at least one uppercase letter</p>
                        <p>&bull; at least one lowercase letter</p>
                        <p>&bull; at least one number</p>
                        <p>&bull; at least one symbol</p>
                    </ul>
                </div>
                <input
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    (keyup)="capsLockCheck($event)"
                    (blur)="isCapsOn = false"
                    class=""
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password"
                    formControlName="newPassword"
                    maxlength="64"
                    #newPassword
                />
                <div
                    *ngIf="pc.newPassword.touched && pc.newPassword.errors"
                    class="invalid-feedback"
                >
                    <div
                        class="errorMessage"
                        *ngIf="pc.newPassword.errors.required"
                    >
                        New Password is required
                    </div>
                    <div
                        class="errorMessage"
                        *ngIf="pc.newPassword.errors.minlength"
                    >
                        New Password must be at least 8 characters
                    </div>
                </div>
                <input
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    (keyup)="capsLockCheck($event)"
                    (blur)="isCapsOn = false"
                    id="confirmNewPassword"
                    class="mt-3"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    formControlName="confirmNewPassword"
                    maxlength="64"
                    #confirmNewPassword
                />
                <div
                    *ngIf="
                        pc.confirmNewPassword.touched &&
                        pc.confirmNewPassword.errors
                    "
                    class="invalid-feedback"
                >
                    <div
                        class="errorMessage"
                        *ngIf="pc.confirmNewPassword.errors.required"
                    >
                        Confirm New Password is required
                    </div>
                    <div
                        class="errorMessage"
                        *ngIf="pc.confirmNewPassword.errors.mustMatch"
                    >
                        Confirm New Passwords must match
                    </div>
                </div>
                <div *ngIf="isCapsOn" class="invalid-feedback cap-warn">
                    <div class="errorMessage caps">
                        <span class="warn-badge">⚠️</span>&nbsp;Caps lock is on.
                    </div>
                </div>
                <div
                    *ngIf="passwordSubmitted"
                    [ngClass]="{
                        'confirmed-feedback': passwordResponse.success,
                        'failed-feedback': !passwordResponse.success
                    }"
                >
                    <p>{{ passwordResponse.message }}</p>
                </div>
                <button
                    type="button"
                    class="primary-button mx-auto mt-4"
                    (click)="submitPasswordChange()"
                    [disabled]="passwordChange.invalid"
                >
                    <ng-container *ngIf="!isSubmitingPassword">
                        Update Password
                    </ng-container>
                    <ng-container *ngIf="isSubmitingPassword">
                        <i class="fas fa-spinner spinner"></i>&nbsp;Updating...
                    </ng-container>
                </button>
            </form>
        </div>
    </div>
</div>

<!-- 🚧 change email modal -->
<div
    *ngIf="showEmailEditor"
    class="modal"
    id="email-editor-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div
        class="modal-body forms"
        (document:keydown.escape)="hideEmailModalConfirm()"
    >
        <div class="top-buttons mb-4">
            <h4 class="modal-title">Change Email</h4>
            <button class="close-btn" (click)="hideEmailModalConfirm()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="form-manager">
            <form
                [formGroup]="changeEmail"
                class="flex-col"
                (document:keydown.enter)="
                changeEmail.valid ? changeUserEmail() : ''
                "
            >
                <input
                    type="text"
                    id="email"
                    placeholder="Enter New Email"
                    formControlName="newEmail"
                />
                <div class="current-email ms-1 mt-1">
                    <i class="text-ellipsis">&bull;&nbsp;{{userInfo.email}}</i>
                </div>
                <div *ngIf="emailc.newEmail.touched && emailc.newEmail.errors" class="invalid-feedback">
                    <div
                        class="errorMessage"
                        *ngIf="(emailc.newEmail.errors.required || emailc.newEmail.errors.pattern)"
                    >
                        Must be a valid email.
                    </div>
                </div>
                <button
                    type="button"
                    class="primary-button mx-auto mt-4"
                    (click)="changeUserEmail()"
                    [disabled]="changeEmail.invalid"
                >
                    <ng-container *ngIf="!isSubmitingNewEmail">
                        Update Email
                    </ng-container>
                    <ng-container *ngIf="isSubmitingNewEmail">
                        <i class="fas fa-spinner spinner"></i>&nbsp;Updating...
                    </ng-container>
                </button>
            </form>
        </div>
    </div>
</div>

<!-- display name modal -->
<div
    *ngIf="showDisplayNameEditor"
    class="modal"
    id="display-name-editor-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div
        class="modal-body forms"
        (document:keydown.escape)="hideDisplayNameEditorConfirm()"
    >
        <div class="top-buttons mb-4">
            <h4 class="modal-title">Change Display Name</h4>
            <button class="close-btn" (click)="hideDisplayNameEditorConfirm()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="form-manager">
            <form
                [formGroup]="displayNameChange"
                class="flex-col"
                (document:keydown.enter)="
                    displayNameChange.valid ? submitDisplayName() : ''
                "
            >
                <input
                    type="text"
                    id="displayName"
                    name="displayName"
                    placeholder="Display Name"
                    formControlName="displayName"
                    maxlength="51"
                    #displayName
                />
                <div *ngIf="dnc.displayName.errors" class="invalid-feedback">
                    <div
                        class="errorMessage"
                        *ngIf="
                            dnc.displayName.touched &&
                            (dnc.displayName.errors.required ||
                                dnc.displayName.errors.minlength)
                        "
                    >
                        Name must be at least 2 characters.
                    </div>
                    <div
                        class="errorMessage"
                        *ngIf="dnc.displayName.errors.pattern"
                    >
                        Possibly including a hyphen, apostrophe, and/or period.
                    </div>
                </div>
                <button
                    type="button"
                    class="primary-button mx-auto mt-4"
                    (click)="submitDisplayName()"
                    [disabled]="displayNameChange.invalid"
                >
                    <ng-container *ngIf="!isChangingDisplayName">
                        Update Display Name
                    </ng-container>
                    <ng-container *ngIf="isChangingDisplayName">
                        <i class="fas fa-spinner spinner"></i>&nbsp;Updating...
                    </ng-container>
                </button>
            </form>
        </div>
    </div>
</div>

<!-- 🚧 getting started page links -->
<div
    *ngIf="showGettingStartedPage"
    class="modal"
    id="admin-page-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div
        class="modal-body"
        (document:keydown.escape)="hideGettingStartedPage()"
    >
        <div class="top-buttons">
            <h4 class="modal-title">VIA3 Unity Guide</h4>
            <button class="close-btn" (click)="hideGettingStartedPage()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="editor-body mt-4">
            <button type="button" class="primary-button w-20rem">
                <a href="{{ gettingStartedSite }}" target="_blank"
                    >Open In New Tab</a
                >
            </button>
            <button
                type="button"
                class="secondary-button w-20rem mt-2"
                (click)="hideGettingStartedPage()"
            >
                <a href="{{ gettingStartedSite }}"> Continue Here </a>
            </button>
        </div>
    </div>
</div>

<!-- 🚧 admin page links -->
<div
    *ngIf="showAdminPage"
    class="modal"
    id="admin-page-modal"
    (click)="clickOffUserSidebarModalEvent($event)"
>
    <div class="modal-body" (document:keydown.escape)="hideAdminPage()">
        <div class="top-buttons">
            <h4 class="modal-title">Open Admin Panel</h4>
            <button class="close-btn" (click)="hideAdminPage()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="editor-body mt-4">
            <button type="button" class="primary-button is-link w-20rem">
                <a href="{{ adminSite }}" target="_blank">Open In New Tab</a>
            </button>
            <button
                type="button"
                class="secondary-button is-link w-20rem mt-2"
                (click)="hideAdminPage()"
            >
                <a href="{{ adminSite }}"> Continue Here </a>
            </button>
        </div>
    </div>
</div>

<!-- 🚧 user profile card ...  -->
