<div class="intake-chat-messaging">
    <div class="io-taskbar">
        <h5>Schedule</h5>
        <form
            class="form-style-3 schedule-select flex-col"
            *ngIf="!showSchedule && scheduleReady"
        >
            <label>
                <p>From:</p>
                <select
                    class="third"
                    name="fromHour"
                    [(ngModel)]="selectSchedule.fromHour"
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <select
                    class="third"
                    name="fromMin"
                    [(ngModel)]="selectSchedule.fromMin"
                >
                    <option value="0">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </select>
                <select
                    class="third"
                    name="fromDay"
                    [(ngModel)]="selectSchedule.fromDay"
                >
                    <option value="am">am</option>
                    <option value="pm">pm</option>
                </select>
            </label>
            <label>
                <p>To:</p>
                <select
                    class="third"
                    name="toHour"
                    [(ngModel)]="selectSchedule.toHour"
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
                <select
                    class="third"
                    name="toMin"
                    [(ngModel)]="selectSchedule.toMin"
                >
                    <option value="0">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </select>
                <select
                    class="third"
                    name="toDay"
                    [(ngModel)]="selectSchedule.toDay"
                >
                    <option value="am">am</option>
                    <option value="pm">pm</option>
                </select>
            </label>
            <button (click)="changeSchedule()" class="primary-button mx-auto my-1">
                <span *ngIf="!_intakeOfficerShiftLogId">Set Schedule</span>
                <span *ngIf="_intakeOfficerShiftLogId">Update</span>
            </button>
        </form>
        <div class="io-schedule flex-col" *ngIf="showSchedule && scheduleReady">
            <p>
                <span>
                    {{ selectSchedule.fromDate | date: "E, MMM d" }}
                </span>
                <span *ngIf="selectSchedule.fromMin != 0">
                    ({{ selectSchedule.fromHour }} :
                    {{ selectSchedule.fromMin }} {{ selectSchedule.fromDay }})
                </span>
                <span *ngIf="selectSchedule.fromMin == 0">
                    ({{ selectSchedule.fromHour }} {{ selectSchedule.fromDay }})
                </span>
                <span> - </span>
            </p>
            <p>
                <span>
                    {{ selectSchedule.toDate | date: "E, MMM d" }}
                </span>
                <span *ngIf="selectSchedule.toMin != 0">
                    ({{ selectSchedule.toHour }} : {{ selectSchedule.toMin }}
                    {{ selectSchedule.toDay }})
                </span>
                <span *ngIf="selectSchedule.toMin == 0">
                    ({{ selectSchedule.toHour }} {{ selectSchedule.toDay }})
                </span>
            </p>
            <button (click)="changeSchedule()" class="primary-button mx-auto">Change Schedule</button>
        </div>
        <div class="schedule-message">
            <p *ngIf="!_intakeOfficerShiftLogId && !scheduleError">
                No Schedule Set Today
            </p>
            <p class="error" *ngIf="scheduleError">Schedule cannot be Past</p>
        </div>
        <!-- <form class="status-select">
            <label>
                Status:
                <select 
                    name="status"
                    [(ngModel)]="selectStatus"
                    (change)="changeStatus()">
                    <option value=false >Off Duty</option>
                    <option value=true >On Duty</option>
                </select>
            </label>
        </form> -->
    </div>
    <div class="chat-users-io">
        <form class="searchbar">
            <button>
                <i class="fas fa-search"></i>
            </button>
            <input
                type="text"
                placeholder="Search"
                id="io-filter"
                name="filter"
                value=""
                (keyup)="filterOfficers()"
            />
        </form>
        <!-- <div class="chat-sort">
            <p>
                <span (click)="clearFilter()">Clear Filters</span>
                <span *ngFor="let a of alphabet" (click)="setFilterLetter(a)">{{
                    a
                }}</span>
            </p>
        </div> -->
        <div class="table-head">
            <table>
                <tr class="chat-user">
                    <th class="img-col"></th>
                    <th class="space-col1"></th>
                    <th
                        class="name-col"
                        style="text-align: left"
                        *ngIf="!isMedical"
                    >
                        Officer Name
                    </th>
                    <th
                        class="name-col"
                        style="text-align: left"
                        *ngIf="isMedical"
                    >
                        Resident Name
                    </th>
                    <th class="space-col2"></th>
                    <th class="message-col">Unread Messages</th>
                </tr>
            </table>
        </div>
        <div class="table-body scrollbar-new">
            <table>
                <tr
                    *ngFor="let officer of officerListFilter; let i = index"
                    class="chat-user"
                    (click)="_selectOfficer(i)"
                >
                    <td class="img-col">
                        <div class="officer-img">
                            <img
                                src="https://profile-images.azureedge.us/{{
                                    officer.intakeUserKeyNavigation
                                        .chatUserKeyNavigation.hash
                                }}"
                                alt="profile picture"
                            />
                        </div>
                        <span
                            class="status-bar {{ officer.userStatus }}"
                        ></span>
                    </td>
                    <td class="space-col1"></td>
                    <td class="name-col">{{ officer.displayName }}</td>
                    <td class="space-col2"></td>
                    <!-- <td class="status-col">
                        <span class="status-text {{officer.intakeStatus}}"></span>
                    </td>
                    <td class="space-col2"></td> -->
                    <!-- <td class="request-col">
                        <p *ngIf="!officer.chatRequest">No requests</p>
                        <button *ngIf="officer.chatRequest" (click)="acceptChatRequest(i)" style="color: black">Request to chat</button>
                    </td> -->
                    <!-- 🛑 EDIT here -->
                    <!-- for testing remove - -->
                    <!-- *ngIf="officer.unreadMessages != 0 && officer.unreadMessages" = (line177) 
                        |&| officer.unreadMessages = (line180) ! = 2 -->
                    <td class="message-col">
                        <i
                            class="fas fa-envelope message-icon"
                            matTooltip="{{ officer.unreadMessages }} unread messages" 
                            matTooltipClass="tooltip-default" 
                        >
                            <span
                                class="badge-message"
                                [attr.data-messages]="officer.unreadMessages"
                            ></span>
                        </i>
                    </td>
                </tr>
                <tr class="chat-user" (click)="checkGroupChat()">
                    <td class="img-col">
                        <div class="officer-img">
                            <img
                                src="{{ _app.brandedAppImageUrl }}"
                                alt="{{ _app.name }} Logo"
                                *ngIf="_app.brandedAppImageUrl"
                            />
                            <img
                                src="https://profile-images.azureedge.us/1"
                                alt="No Logo"
                                *ngIf="!_app.brandedAppImageUrl"
                            />
                        </div>
                    </td>
                    <td class="space-col1"></td>
                    <td class="name-col" *ngIf="!isMedical">
                        Intake Group Chat
                    </td>
                    <td class="name-col" *ngIf="isMedical">
                        Staff Group Chat
                    </td>
                    <td class="space-col2"></td>
                    <td class="message-col">
                        <i
                            class="fas fa-envelope message-icon"
                            matTooltip="{{ _app.unreadMessages }} unread messages" 
                            matTooltipClass="tooltip-default" 
                            
                        >
                            <span
                                class="badge-message"
                                [attr.data-messages]="_app.unreadMessages"
                            ></span>
                        </i>
                    </td>
                </tr>
            </table>
            <p class="empty-table" *ngIf="officerList.length < 1 && !isMedical">
                There are currently no officers online.
            </p>
            <p class="empty-table" *ngIf="officerList.length < 1 && isMedical">
                There are currently no residents online.
            </p>
            <p
                class="empty-table"
                *ngIf="officerListFilter.length < 1 && officerList.length > 0"
            >
                There are no users to select from,
                <span (click)="clearFilter()">Clear Filters</span>.
            </p>
        </div>
    </div>
    <!-- end edit -->
    <!-- ❓ empty div -->
    <div class="user-info-bar" *ngIf="!officerReady"></div>
    <div
        class="user-info-bar {{ selectedOfficer.userStatus }}"
        *ngIf="officerReady"
    >
        <div *ngIf="!(selectedOfficer.userStatus == 'none')" class="user-img">
            <img
                src="https://profile-images.azureedge.us/{{
                    selectedOfficer.intakeUserKeyNavigation
                        .chatUserKeyNavigation.hash
                }}"
                alt="profile picture"
            />
        </div>
        <div *ngIf="selectedOfficer.userStatus == 'none'" class="user-img">
            <img
                src="{{ _app.brandedAppImageUrl }}"
                alt="{{ _app.name }} Logo"
                *ngIf="_app.brandedAppImageUrl"
            />
            <img
                src="https://profile-images.azureedge.us/1"
                alt="No Logo"
                *ngIf="!_app.brandedAppImageUrl"
            />
        </div>
        <p class="chat-name">
            <span class="top-name">{{ selectedOfficer.displayName }}</span>
            <span class="bottom-name">{{
                selectedOfficer.intakeUserKeyNavigation.chatUserKeyNavigation
                    .username
            }}</span>
        </p>
        <p class="status-text">
            <span class="status-circle-sm"></span>
        </p>
        <div class="officer-options">
            <span
                *ngIf="
                    !(selectedOfficer.displayName === 'Intake Group Chat') &&
                    !(selectedOfficer.displayName === 'Staff Group Chat')
                "
                (click)="showBox(1)"
                >Overview</span
            >
            <span (click)="showBox(2)">Messages</span>
            <span (click)="showBox(3)">Video Call</span>
        </div>
    </div>
    <div class="officer-overview" *ngIf="showFormsBool">
        <h3 class="title">Forms</h3>
        <div class="overview-main">
            <app-form-viewer-home
                [channelId]="channelId"
            ></app-form-viewer-home>
        </div>
    </div>
    <div class="officer-overview" id="o-overview">
        <h3 class="title">Overview</h3>
        <div class="access-list scrollbar-new">
            <h4>Room Users</h4>
            <div class="list-box">
                <h5 *ngIf="!isMedical">Police Officers</h5>
                <h5 *ngIf="isMedical">Residents</h5>
                <div
                    *ngFor="let officer of accessList.members"
                    class="list-card"
                >
                    <div class="officer-img">
                        <img
                            src="https://profile-images.azureedge.us/{{
                                officer.chatUserKeyNavigation.hash
                            }}"
                            alt="profile picture"
                        />
                    </div>
                    <p class="officer-name">
                        {{ officer.chatUserKeyNavigation.displayName }}
                    </p>
                </div>
            </div>
            <div class="list-box">
                <h5 *ngIf="!isMedical">Intake Officers</h5>
                <h5 *ngIf="isMedical">Staff</h5>
                <div
                    *ngFor="let officer of accessList.owners"
                    class="list-card"
                >
                    <div class="officer-img">
                        <img
                            src="https://profile-images.azureedge.us/{{
                                officer.chatUserKeyNavigation.hash
                            }}"
                            alt="profile picture"
                        />
                    </div>
                    <p class="officer-name">
                        {{ officer.chatUserKeyNavigation.displayName }}
                    </p>
                </div>
            </div>
        </div>
        <div class="overview-main">
            <!-- <form action="" (change)="overviewCheck()" *ngIf="officerReady">
                <label for="report">
                    <input type="checkbox" [(ngModel)]="selectedOfficer.overview.report" name="report">
                    Officer submited inital police report to determine probable cause?
                </label>
                <label for="referral">
                    <input type="checkbox" [(ngModel)]="selectedOfficer.overview.refferal" name="refferal">
                    Officer submitted referral complaint form?
                </label>
                <label for="schedule">
                    <input type="checkbox" [(ngModel)]="selectedOfficer.overview.schedule" name="schedule">
                    Officer scheduled a video call time?
                </label>
            </form> -->
            <!-- 🚧 end session new -->
            <div class="end-intake-session">
                <button
                    *ngIf="!(selectedOfficer.displayName === 'Intake Group Chat') && !isSessionEnding"
                    (click)="propmtDeleteFiles()"
                    class="cancel-button"
                >
                    End Session
                </button>
                <!-- <button
                    *ngIf="
                        !(
                            selectedOfficer.displayName === 'Intake Group Chat'
                        ) &&
                        fileList.length > 0 &&
                        !isSessionEnding
                    "
                    (click)="propmtDeleteFiles()"
                    class="cancel-button"
                >
                    End Sessions
                </button>
                <div class="session-ending" *ngIf="isSessionEnding">
                    <p>The session is ending</p>
                </div> -->
            </div>
        </div>
    </div>
    <div id="o-chat" class="app-chat">
        <div class="chat-layout">
            <app-chat
                *ngIf="channelId"
                [teamProjectId]="teamProjectId"
                [currentIntakeUser]="_officer"
            >
            </app-chat>
            <div class="users-in-channel mb-2">
                <p class="box-message" *ngIf="!isMedical">
                    Officers in Channel
                </p>
                <p class="box-message" *ngIf="isMedical">Staff in Channel</p>
                <ng-container *ngFor="let officer of chatList">
                    <div
                        class="user-card pe-1 flex align-center {{ officer.userStatus }}"
                        *ngIf="officer.userStatus !== 'offline' || officer.userStatus !== 'hidden'">
                        <div class="user-img-fix flex">
                            <img
                                src="https://profile-images.azureedge.us/{{
                                    officer.chatUserKeyNavigation.hash
                                }}"
                                class="circle"
                                alt="profile picture"
                            />
                            <span 
                                class="status-bar"
                                [matTooltip]="officer.userStatus" 
                                matTooltipClass="tooltip-default"></span>
                        </div>
                        <p 
                            class="chat-name"
                            [matTooltip]="officer.chatUserKeyNavigation.displayName" 
                            matTooltipClass="tooltip-default">
                            {{ officer.chatUserKeyNavigation.displayName }}
                        </p>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="officerReady" class="tempFileBtn flex">
                <button (click)="showFilesModal()" class="secondary-button mx-auto">
                    Shared Files 
                </button>
            </div>
        </div>
        <div class="officer-pre-select" *ngIf="!officerReady">
            <!-- <div *ngIf="isMedical" class="doctor-logo">
                <img src="https://web-images.azureedge.us/annapolis/annapolis-brand-anne-arundel.png" alt="Anne Arundel Medical Group">
            </div> -->
            <div *ngIf="_customizations.companyImageIO" class="doctor-logo">
                <img
                    src="{{ _customizations.companyImageIO.value }}"
                    alt="Company Logo"
                />
            </div>
            <p *ngIf="officerList.length > 0 && !isMedical">
                Please select an officer
            </p>
            <p *ngIf="officerList.length > 0 && isMedical">
                Please select a resident
            </p>
            <p *ngIf="officerList.length < 1 && !isMedical">
                There are currently no officers to select from
            </p>
            <p *ngIf="officerList.length < 1 && isMedical">
                There are currently no residents to select from
            </p>
        </div>
    </div>
    <div class="video-stream" stream-type="i-officer" id="video-stream">
        <app-rtc-video
            (resetVideoCall)="resetCall()"
            *ngIf="videoType === 'webrtc'"
        ></app-rtc-video>
        <!--Used to Connect Users and prompt the other officer-->
        <div
            class="begin-videocall"
            *ngIf="showVideoPrompt() && videoType === 'webrtc'"
        >
            <button (click)="beginCall()" style="color: black">
                Begin Call
            </button>
        </div>
        <jitsi-video
            [channelId]="channelId"
            [userName]="_officer.chatUserKeyNavigation.displayName"
            [preventList]="videoPreventList"
            (openChatEvent)="showBox(2)"
            (openFilesEvent)="showFilesModal()"
            (endCallEvent)="propmtDeleteFiles()"
            *ngIf="videoType === 'jitsi'"
        >
        </jitsi-video>
    </div>
    <div class="call-history" id="o-history">
        <div class="officer-offline">
            <h3>Call History</h3>
            <p>Call History coming soon.</p>
        </div>
    </div>
</div>


<!-- file storage modal -->
<!-- 🚧🚧🚧 converting to new global modal && fs2.0! -->
<div
	*ngIf="_showFiles"
	class="modal"
	id="intake-files-modal"
	(click)="clickOffModalEvent($event)"
>
	<div
		class="modal-container">
		<!-- new close modal ... -->
		<button
			class="close-modal-x flex-center-all"
			(click)="showFilesModal()"
		>
			<i class="fas fa-times"></i>
		</button>
        <file-storage
            [componentId]="'intake-io'"
            [channelContainerName]="channelInfo.blobContainerId"
            [channelName]="'Temporary Intake Files'"
            [channelId]="channelId"
            [isPortable]="true">
        </file-storage>
	</div>
</div>