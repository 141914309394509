<!-- <div
	id="jitsi-call-manager-container"
	class="jitsi-call-manager-container"
> -->
<div
	*ngIf="displayManager"
	id="jitsi-call-manager-modal"
	class="modal call-manager-container"
	(click)="modalClickOff($event)"
>
	<div class="modal-container">
		<div class="preview-box" [class.in-video]="false">
			<!-- Remove in-video if never used in video -->
			<div class="top-buttons">
				<!-- <h4 class="modal-title">Device Preview {{ channelId }}</h4> -->
				<h4 class="modal-title">
					{{
						isCallStarter ? "Starting a Call" : "Incoming call from " + channelName
					}}
				</h4>
				<!-- <button class="close-btn flex-center-all" (click)="hidePreview()">
					<i class="fas fa-times"></i>
				</button> -->
			</div>
			<!-- 🚧 PRE-call new video preview -->
			<div class="settings-options">
				<div class="preview-options flex-col">
					<video id="videoPreviewEl-pre-join" class="video-preview" autoplay></video>
					<div
						class="preference-settings"
						[class.no-audio-output]="
							_platform.FIREFOX || _platform.IOS || _platform.ANDROID
						"
					>
						<!-- toggle video preview on [also sets join preference] -->
						<div class="setting-btn me-2 ms-1">
							<label class="mb-3 outline-circle-checkbox" for="hidevideopreview">
								<input
									id="hidevideopreview"
									[(ngModel)]="videoPreviewOn"
									(ngModelChange)="videoPreviewToggle($event)"
									type="checkbox"
									name="mutevideopreview"
								/>
								<i
									class="fas input-icon"
									[ngClass]="{
										'fa-video': videoPreviewOn,
										'fa-video-slash': !videoPreviewOn
									}"
								></i>
							</label>
						</div>
						<!-- toggle audio preview on [also sets join preference] -->
						<div class="setting-btn mx-2">
							<label class="mb-3 outline-circle-checkbox" for="muteAudioPreview">
								<input
									id="muteAudioPreview"
									[(ngModel)]="audioPreviewOn"
									(ngModelChange)="audioPreviewToggle($event)"
									type="checkbox"
									name="muteAudioPreview"
								/>
								<i
									class="fas input-icon"
									[ngClass]="{
										'fa-microphone': audioPreviewOn,
										'fa-microphone-slash': !audioPreviewOn
									}"
								></i>
							</label>
						</div>
						<ng-container *ngIf="audioPreviewOn">
							<!-- audio level indicator (dot if on) -->
							<div id="audio-dot" class="mic-on me-2"></div>
							<!-- audio level indicator (bars on volume) -->
							<div id="audio-bars" class="bars-wrapper flex-center-all">
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
								<div class="bar"></div>
							</div>
						</ng-container>
						<div class="setting-btn ms-auto me-1">
							<label class="outline-circle-checkbox" for="speakerTest">
								<button
									(click)="speakerTest()"
									matRipple
									[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
									matTooltip="speaker test"
									matTooltipClass="tooltip-default"
									class="circle"
								>
									<i class="fas fa-volume-up input-icon"></i>
								</button>
							</label>
						</div>
					</div>
				</div>
				<!-- device selectors -->
				<div class="options flex-col ms-3">
					<div class="select">
						<label for="videoSource"><i class="fal fa-video me-1"></i>Camera</label>
						<mat-select
							class="borderless-select"
							name="videoPreviewSource"
							[(ngModel)]="previewCurrentVideoInputId"
							(selectionChange)="setVideoPreviewInput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewVideoInputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewVideoInputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<div class="select">
						<label for="audioSource"
							><i class="fal fa-microphone me-1"></i>Microphone</label
						>
						<mat-select
							class="borderless-select"
							name="audioSource"
							[(ngModel)]="previewCurrentAudioInputId"
							(selectionChange)="setAudioPreviewInput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewAudioInputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewAudioInputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<!-- both firefox & ios not supporting audio output selection ... -->
					<div
						*ngIf="!_platform.FIREFOX && !_platform.IOS && !_platform.ANDROID"
						class="select"
					>
						<label for="outputSource"
							><i class="fal fa-volume me-1"></i>Speakers</label
						>
						<mat-select
							class="borderless-select"
							name="outputSource"
							[(ngModel)]="previewCurrentAudioOutputId"
							(selectionChange)="setAudioPreviewOutput()"
							placeholder="Select A Device"
						>
							<mat-option
								*ngFor="let device of previewAudioOutputs"
								value="{{ device.deviceId }}"
							>
								{{ device.label }}
							</mat-option>
							<mat-option value disabled *ngIf="previewAudioOutputs.length < 1">
								No Devices To Select From</mat-option
							>
						</mat-select>
					</div>
					<!-- <button
						*ngIf="inVideo"
						type="button"
						[disabled]="isSettingJitsiDevices"
						class="device-check mx-auto py-1 px-2 mt-auto mb-2"
						(click)="setJitsiDevices()"
						matRipple
						[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
					>
						Set in call devices
					</button> -->
					<button
						class="device-check mx-auto py-1 px-2 mt-auto"
						type="button"
						(click)="getPreviewMediaDevices()"
						matRipple
						[matRippleColor]="inVideo ? matRippleDarkMode : rippleColor"
					>
						Check for new devices
					</button>
				</div>
			</div>
			<h5 class="mt-4 flex-row-wrap-center-all" *ngIf="isCallStarter && !callAccepted">
				Calling DM
			</h5>
			<h5 class="mt-4 flex-row-wrap-center-all" *ngIf="isCallStarter && callAccepted">
				Call Accepted
			</h5>
			<div class="mt-4 flex-row-wrap call-accept-buttons">
				<button
					type="button"
					class="cancel-button"
					(click)="isCallStarter ? cancelCall() : denyCall()"
				>
					{{ isCallStarter ? "Cancel" : "Deny" }}
				</button>
				<button
					*ngIf="!isCallStarter || (isCallStarter && callAccepted)"
					type="button"
					class="secondary-button"
					(click)="acceptCall()"
				>
					{{ isCallStarter ? "Join" : "Accept" }}
				</button>
			</div>
		</div>
	</div>
</div>
