import { Component, OnInit } from '@angular/core';
import * as tVideo from 'twilio-video';
import { TwilioService } from 'src/app/services/twillio.service';

@Component({
    selector: 'app-twilio-video',
    templateUrl: './twilio-video.component.html',
    styleUrls: ['./twilio-video.component.scss']
})
export class TwilioVideoComponent implements OnInit {

    constructor(
        _twillioService: TwilioService
    ) { }

    ngOnInit() {
    }

}
