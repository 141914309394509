import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsHomepageComponent } from './events-homepage/events-homepage.component';

// const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'events', component: EventsHomepageComponent }
  ])],
  exports: [RouterModule]
})
export class EventsAppRoutingModule { }
