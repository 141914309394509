import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

// import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
import { from, Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Constants } from '../constants';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private _router: Router) {
    //console.log('********* test req.url: ' + req.url);
  }
  
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // console.log('********* req.url: ' + req.url);
    //alert(req.url);

    //if (req.url.startsWith(Constants.apiRoot)) {
    return from(this._authService.getAccessToken()
      .then(token => {

        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        const authReq = req.clone({ headers });

        return next.handle(authReq).pipe(tap(_ => { }, error => {
          const respError = error as HttpErrorResponse;

          if (respError && (respError.status === 401 )) {
            this._router.navigate(['/unauthorized']);
          }
        })).toPromise();
      }));
    //}
    //else {
    //  return next.handle(req);
    //}



    ////if (req.url.startsWith(Constants.apiRoot)) {
    //  if (this.startsWith(req.url, Constants.apiRoot) ||
    //      this.startsWith(req.url, Constants.stsAuthority)) {
    //
    //  const accessToken = this._authService.getAccessToken();
    //  const headers = req.headers.set('Authorization', `Bearer ${accessToken}`);
    //  const authReq = req.clone({ headers });
    //
    //  //alert(req.url);
    //  //console.log(`********* accessToken: ${accessToken}`);
    //
    //   //return next.handle(authReq).do(() => {}, error => {
    //   //  const respError = error as HttpErrorResponse;
    //
    //   //  if (respError && (respError.status === 401 || respError.status === 403)) {
    //   //    this._router.navigate(['/unauthorized']);
    //   //  }
    //   //});
    //
    //  return next.handle(authReq).pipe(
    //    tap(
    //      event => this.handleResponse(authReq, event),
    //      error => this.handleError(authReq, error)
    //    )
    //  );
    //
    //} else {
    //  return next.handle(req);
    //}
  }

  //handleResponse(req: HttpRequest<any>, event) {
  //  console.log('Handling response for ', req.url, event);
  //  if (event instanceof HttpResponse) {
  //    console.log('Request for ', req.url,
  //        ' Response Status ', event.status,
  //        ' With body ', event.body);
  //  }
  //}
  //
  //handleError(req: HttpRequest<any>, event) {
  //  console.error('Request for ', req.url,
  //        ' Response Status ', event.status,
  //        ' With error ', event.error);
  //
  //  const respError = event.error as HttpErrorResponse;
  //
  //  if (respError && (respError.status === 401 || respError.status === 403)) {
  //    this._router.navigate(['/unauthorized']);
  //  }
  //}

  //startsWith(str, word) {
  //  return str.lastIndexOf(word, 0) === 0;
  //}
  //
  //endsWith(str, word) {
  //  return str.indexOf(word, str.length - word.length) !== -1;
  //}
}
