import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// import { PageNotFoundComponent } from './home/pagenotfound.component'; // 🚧 imported in app.mod but not used ...


import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './home/unauthorized.component';

import { SigninRedirectCallbackComponent } from './home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './home/signout-redirect-callback.component';

import { ElementExamplesComponent } from './element-examples/element-examples.component';

// 🎉 TEMP FOR CONFERENCE 
import { LoginConferenceComponent } from './login-conference/login-conference.component';
import { ConferenceFullScheduleComponent } from './conference-full-schedule/conference-full-schedule.component';

import { AlertsSettingsComponent } from './shared/components/alerts-settings/alerts-settings.component';
import { GuestService } from './services/guest.service';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'conference-signin', component: LoginConferenceComponent },
  { path: 'NDIA-full-schedule', component: ConferenceFullScheduleComponent },
  { path: 'meetingInvite', component: HomeComponent},
  // 💡 NEW 
  { path: 'alert-settings', component: AlertsSettingsComponent },
  
  { path: 'unauthorized', component: UnauthorizedComponent },
  
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent }, // RE-DIRECTS TO LOGIN COMPONENT 
  
  // new examples // anyone can see if they know url ... 
  { path: 'examples', component: ElementExamplesComponent },
  
  // 🚧 testing ... wildcard route ? eg: if user types something invalid to url ? 
  // { path: '**', component: PageNotFoundComponent } // should implement 404 not found page? ... 
  // this just re-directs to home if url invalid ... 
];

@NgModule({  
  imports: [RouterModule.forRoot(routes, { enableTracing: true, relativeLinkResolution: 'legacy' }), CommonModule],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
