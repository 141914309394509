import { Injectable } from "@angular/core";
import { Constants } from "../constants";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { of, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Contact } from "../shared/interface/contact";



@Injectable({
    providedIn: "root",
})
export class ContactsService {
    constructor(private httpClient: HttpClient) { }

    // 🚧 TO GET ALL CONTACTS w/o PAGINATION ... 
    getAllContactsNoPage(userId: string): Observable<HttpResponse<Array<Contact>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}userContacts/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllContactsViewer(userId: string): Observable<HttpResponse<Array<Contact>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}userContacts/viewerContacts/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getContactsBySearchQuery(userId: string, queryString: string) {
        var headersConfig = new HttpHeaders();

        return this.httpClient
            .get(`${Constants.apiRoot}userContacts/search/${userId}/${queryString}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 💡 PAGINATION REMOVED DUE TO FRONT-END SORTING ISSUES ... 
    // getAllContacts(userId: string): Observable<HttpResponse<any>> {
    //     var headersConfig = new HttpHeaders();
    //     return this.httpClient
    //         .get(`${Constants.apiRoot}userContacts?channelUserId=${userId}&pageSize=24`, { headers: headersConfig, observe: "response" })
    //         .pipe(
    //             catchError(err => {
    //                 return of(err);
    //             })
    //         );
    // }

    // can be used to populate all contacts from your tenant ... 
    populateContactsFromTennant(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}userContacts/syncContacts/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    createContact(contactObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}userContacts`, contactObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    updateContact(contactId: string, contactObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}userContacts/${contactId}`, contactObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // used to favorite a contact ... 
    patchContact(contactId: string, contactObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let contactArray = [contactObj];
        return this.httpClient
            .patch(`${Constants.apiRoot}userContacts/${contactId}`, contactArray, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteContact(contactId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}userContacts/${contactId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    sendTenantInvitesByEmail(emailList: any[]): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}sendGrid`, { emailAddresses: emailList }, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
