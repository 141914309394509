
<div class="login-page flex-col">
    <form [formGroup]="loginForm"
          (document:keydown.enter)="(loginForm.valid && !isLoggingIn && !loginConfirmed) ? login() : ''"
          class="login-form flex-col-center-all m-auto" style="width:380px;">

        <img class="conf-logo" src="https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/NET-INCLUSION-2022-LOGO.png" alt="conf-logo">

        <h3 class="mb-4 title-thin">Powered by &nbsp; <img src="{{logoIcon2.logoblue}}" alt="via-logo" class="via-logo"></h3>
        <p>The conference has concluded.</p>

        <!--<input #userName
               autofocus
               id="userName"
               class="formInput"
               name="userName"
               formControlName="userName"
               class="my-3" type="text" placeholder="your name">

        <input type="text"
               id="email"
               name="email"
               formControlName="email"
               #email
               class="mb-3" placeholder="email address">

        <!-- error messages -/->
        <ng-container *ngIf="!isLoggingIn && !loginConfirmed">
            <div *ngIf="loginFormControls.userName.touched && loginFormControls.userName.errors" class="invalid-feedback">
                <div class="error-message text-center" *ngIf="loginFormControls.userName.errors.required">Your name is required.</div>
                <div class="error-message text-center" *ngIf="loginFormControls.userName.errors.minlength">Your name must be at least 2 characters.</div>
            </div>

            <div *ngIf="loginFormControls.email.touched && loginFormControls.email.errors" [class.mt-2]="loginFormControls.userName.touched && loginFormControls.userName.errors" class="invalid-feedback">
                <div class="error-message text-center" *ngIf="loginFormControls.email.errors.required">Email is required.</div>
                <div class="error-message text-center" *ngIf="loginFormControls.email.errors.pattern">Must be a valid email.</div>
            </div>
        </ng-container>
        -->
        <!-- submit button -->
        <!--  -->
        <a href="{{accountSiteRegister}}" class="mt-4 mb-2 primary-button" style="text-decoration: none;">CREATE ACCOUNT</a>
        <!--  -->
        <!--
        <button [disabled]="loginForm.invalid && !isLoggingIn && !loginConfirmed"
                type="button"
                class="mt-4 mb-2 primary-button"
                matRipple
                [matRippleColor]="loginRippleColor"
                [matRippleDisabled]="loginForm.invalid || isLoggingIn || loginConfirmed"
                [class.invalid-form]="loginForm.invalid || isLoggingIn || loginConfirmed"
                (click)="(loginForm.valid && !isLoggingIn && !loginConfirmed) ? login() : ''">
            <!--  -/->
            <ng-container *ngIf="!isLoggingIn && !loginConfirmed">ENTER CONFERENCE</ng-container>
            <ng-container *ngIf="isLoggingIn"><i class="fas fa-spinner spinner"></i>&nbsp;SIGNING IN . . .</ng-container>
            <ng-container *ngIf="loginConfirmed"><i class="fas fa-check"></i>&nbsp;SIGNIN SUCCESS !</ng-container>
        </button>
        <!--  -->
        <div class="account-links text-center mt-4">
            <a routerLink="/login" style="margin-left: 1px;">Existing User Login</a>
        </div>
    </form>
    <div class="footer">
        <p>
            {{ currentYear }} © VIA3 Corporation. All rights reserved.
            <a href="{{accountSite}}terms-of-use" target="_blank">Terms of Use</a> |
            <a href="{{accountSite}}privacy-policy" target="_blank">Privacy Policy</a>
        </p>
    </div>
</div>
