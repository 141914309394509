import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

import { ContactsAppRoutingModule } from './contacts-app-routing.module';
import { ContactsHomepageComponent } from './contacts-homepage/contacts-homepage.component';


@NgModule({
  declarations: [
    ContactsHomepageComponent
  ],
  imports: [
    CommonModule,
    ContactsAppRoutingModule,
    BrowserModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactsAppModule { }
