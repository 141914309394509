import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import {
    listAnimationWrap,
    listAnimationItem,
    fadeIn,
    customExitTransition,
    // customExitAllTransition,
} from "src/app/animations/global-animations";
import { AlertsService } from "src/app/services/alerts.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { ChannelService } from "src/app/services/channel-service";
import { ChannelUser } from "../../interface/channel-user";
import { consoleLogStyle } from '../../varaibles';
import { Alert } from "../../interface/alert";



@Component({
    selector: "alerts[componentId]",
    templateUrl: "./alerts.component.html",
    styleUrls: ["./alerts.component.scss"],
    animations: [listAnimationWrap, listAnimationItem, fadeIn],
})
export class AlertsComponent implements OnInit {
    @Input('componentId') componentId: string;// Required ... [will be - 'alerts-home' : 'sidebar-alerts'] ... 
    @Input("userInfo") userInfo: ChannelUser;
    @Input('isViewer') isViewer: boolean;

    // public viewerAlerts: any[] = [];

    public alerts: Array<Alert> = [];
    public isLoadingAlerts: boolean = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private _alertsService: AlertsService,
        private _toastService: ToastNotificationService,
        private _channelService: ChannelService
    ) {
        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "clearAllAlerts") {
                this.clearAllAlerts();
            }
        }));

        // ❌💡 this should .shift(newAlert) to alerts[] ... no need to load all again ... 
        // 🐛 this is happening for meeting creator as well ... does not need to ... 
        // i need data back from this event to fix ... 
        
        // refresh alerts when a new meeting invitation is received so that alerts appear for new meeting invites 
        this.subscriptions.push(this._channelService.onUpdateMeetingListEvent$.subscribe((data) => {
            // console.log("%c 🤣 THIS IS FROM THE ALERTS COMPONENT (.onUpdateMeetingListEvent$).sub " , consoleLogStyle, data);
            this.getUserAlerts(); 
        }));

        this.subscriptions.push(this._alertsService.onRemoveAlertEvent$.subscribe((alertObj) => {
            let updatedAlerts = this.alerts.filter(alert => alert.notificationId !== alertObj.notificationId);
            let element = <HTMLElement>document.getElementsByClassName(`alert-${this.componentId}`)[alertObj.alertIdx];
            let element2 = <HTMLElement>document.getElementsByClassName(`alert-${this.componentId}`)[alertObj.alertIdx + 1] || null;
            element.style.opacity = '0.5';
            customExitTransition(element, element2);
            setTimeout(() => {
                this.alerts = updatedAlerts;
            }, 262);
        }));

        this.subscriptions.push(this._alertsService.onRemoveAllAlertsEvent$.subscribe(() => {
            this.alerts = [];
            this.isLoadingAlerts = false;
        }));

        // ❌ the notiObj returned here is HUGE ... 
        this.subscriptions.push(this._channelService.onUnreadDirectMessageNotification$
            .subscribe((notiObj) => {
                // console.log("%c THIS IS FROM THE ALERTS COMPONENT (.onUnreadDirectMessageNotification$).sub " , consoleLogStyle, notiObj);
                if(notiObj.numUnreadMessages === 0) {
                    return;
                } else {
                    this.getUserAlerts();
                }
            })
        );
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userInfo) {
            if (!!this.userInfo) {
                // console.log("%c user info input changes ALERTS (not viewer)", consoleLogStyle, this.userInfo);
                if(this.isViewer) {
                    this.isLoadingAlerts = false;
                    // this.viewerAlerts = ["Learn more about NDIA", "Learn more about VIA3", "Follow us on Twitter @netinclusion"];
                } else {
                    this.getUserAlerts();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getUserAlerts() {
        this.isLoadingAlerts = true;
        this._alertsService.getUserAlerts(this.userInfo.channelUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.alerts = data.body;
                    // this.alertsPag = JSON.parse(data.headers.get('X-Pagination'));
                    // console.log("%c your alerts ", consoleLogStyle, this.alerts)
                    this.isLoadingAlerts = false;
                    return;
                } else {
                    // console.error("error getting alerts", data)
                    this._toastService.error("There was an error getting your alerts.");
                    this.isLoadingAlerts = false;
                }
            });
    }

    clearAllAlertsConfirm() {
        this._toastService.continue("Are you sure you want to clear all Alerts?", "clearAllAlerts");
    }

    clearAllAlerts() {
        // console.log("clearing all alerts!")
        // let allAlerts = document.getElementsByClassName(`alert-${this.componentId}`);
        // console.log(allAlerts)
        // customExitAllTransition(allAlerts);
        this.alerts = []; // clear all ... 
        this.isLoadingAlerts = true;
        this._alertsService.deleteAllAlerts(this.userInfo.channelUserId)
            .subscribe(res => {
                if(res.status === 204) {
                    // console.log("all alerts removed")
                    // this.isLoadingAlerts = false;
                    this._alertsService.removeAllAlerts();
                } else {
                    this._toastService.error("There was an error deleting all alerts.");
                    this.getUserAlerts();
                }
            });  
    }

    deleteAlert(notificationId:string, alertIdx: number) {
        this.alerts[alertIdx].isRemoving = true;
        let element = <HTMLElement>document.getElementsByClassName(`alert-${this.componentId}`)[alertIdx];
        element.style.opacity = '0.5';
        this._alertsService.deleteAlert(notificationId)
        .subscribe(res => {
            if(res.status === 204) {
                // console.log("alert removed", notificationId, element)
                this._alertsService.removeAlert(notificationId, alertIdx);
            } else {
                this._toastService.error("There was an error deleting this alert.");
                element.style.opacity = '1';
                this.alerts[alertIdx].isRemoving = false;
            }
        });
    }

    patchAlertViewed(notificationId: string, alertIdx: number) {
        if(!this._channelService._isDesktop) {
            this._channelService.sidebarChangeEvent(0);
        }
        // console.log("%c alert viewed! ", consoleLogStyle)
        let settingPatch = {
            "op": "replace",
            "path": "/viewed",
            "value": true
        };
        this._alertsService.patchAlert(notificationId, settingPatch)
            .subscribe( res => {
                if(res.status === 204) {
                    // console.log("alert viewed updated!")
                    this.alerts[alertIdx].viewed = true;
                    // this._toastService.info("Alert viewed updated!" + notificationId);
                } else {
                    this._toastService.error("There was an error patching this alert.");
                }
            });
    }
}








    // pagination removed ... 

    // public alertsPag: any = {};
    // public isLoadingMoreAlerts: boolean;

    // 💡 infinite scrolling w/ Intersection Observer api ... [(@listAnimationItemLong.done)="last ? runPaginationObserver() : ''"] 
    // 🌐 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API 
    // paginationObserver() {
    //     let lastEl = <HTMLElement>document.getElementById(`LastAlert-${this.componentId}`); // grab last El in list [after animation done & is in DOM] 
    //     // console.log("%c List anime done: run intersect observer 🎯 LAST El: ", consoleLogStyle, lastEl)
    //     if(!!lastEl) {
    //         let observer = new IntersectionObserver(entries => {
    //             entries.forEach(entry => { // can observe multiple El's but will always be an Array of [entries] 
    //                 // console.log("%c is 🎯 El intersecting? ", consoleLogStyle, entry.isIntersecting)
                    
    //                 // || entry.intersectionRatio > 0 - also works
    //                 if (entry.isIntersecting && entry.intersectionRatio >= 0) { // bottom of view intersecting W/ the top of El 
    //                     // console.log('%c 🎯 El is IN view run get next page! ', consoleLogStyle);
                        
    //                     this.getMoreUserAlerts(this.alertsPag.nextPageLink);
                        
    //                     observer.unobserve(entry.target); // stop tracking this El, will grab next LastEl 
    //                 } else {
    //                     // console.log('%c 🎯 El OUT of view. ',consoleLogStyle);
    //                 }
    //             });
    //         });
    //         observer.observe(lastEl); // track the last El in list 
    //     } else {
    //         console.info("no last EL?", lastEl);
    //     }
    // }

    // getMoreUserAlerts(link:any) { // returns 48 results ... 2 page len avg. 
    //     this.isLoadingMoreAlerts = true;
    //     this._apiCallsService.getGenericForPagination(link)
    //         .subscribe(data => {
    //             if(data.status === 200) {
    //                 this.alerts = this.alerts.concat(data.body);
    //                 this.alertsPag = JSON.parse(data.headers.get('X-Pagination'));
    //                 // console.log("%c MORE alerts", consoleLogStyle, this.alerts);
    //                 // console.log("%c alerts pagination ? ", consoleLogStyle, this.alertsPag);
    //                 this.isLoadingMoreAlerts = false;
    //             } else {
    //                 console.error("error getting alerts ...", data)
    //                 this._toastService.error("There was an error getting alerts.");
    //                 this.isLoadingMoreAlerts = false;
    //             }
    //         });
    // }

    // 💡 alert options dropdown ... removed for now ... may need later ... 
    
    // showAlertOptionsDropdown(i: number ,e?: Event) {
    //     e.stopPropagation();
    //     const options = document.getElementById(`alert-options-dropdown-${i}`);
    //     options.classList.add('active');
        
    //     // also hide scrollbar ... 
    //     let scrollBox = <HTMLElement>document.getElementById('alertsScrollbox');
    //     scrollBox.classList.add('hidden');
    // }

    // hideAlertOptionsDropdown(i: number ,e?: Event) {
    //     const options = document.getElementById(`alert-options-dropdown-${i}`);
    //     options.classList.remove('active');
        
    //     // also resume scrolling ... 
    //     let scrollBox = <HTMLElement>document.getElementById('alertsScrollbox');
    //     scrollBox.classList.remove('hidden');
    // }