import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ApiCallsService } from '../../../services/api-calls.service';
// import { DocumentToSign, User } from '../../../models/models';
import { ActivatedRoute } from '@angular/router';
// import * as faker from 'faker'; //For testing
import 'webrtc-adapter';
import { ChannelService } from '../../../services/channel-service';
// import { constants } from 'os';
// import { JsonHubProtocol } from '@microsoft/signalr';
// import { connect } from 'http2';
import { ConnectionManagerService } from '../../../services/connection-manager.service';
import { AppIntakeService } from '../../../../../src/app/services/app-intake.service';

@Component({
    selector: 'app-rtc-video',
    templateUrl: './rtc-video.component.html',
    styleUrls: ['./rtc-video.component.css'],
    providers: [ConnectionManagerService]
})
export class RTCVideoComponent implements OnInit {
    @Output() resetVideoCall = new EventEmitter();
    @Output() promptVideoCall = new EventEmitter();
    @Input() channelId: string;
    //private _pc1: any; //pc1Local
    //private _pc2: any; //pc1Remote
    //private _pc3: any; //pc2Local
    //private _pc4: any; //pc2Remote

    private peerConnection: any;
    private localVideo: any;
    private localStream: any;
    private remoteVideo: any;
    private uuid: any;
    private serverConnection: any;
    private offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
    };

    private _connection: any;
    private _localStream: any;
    private _localVideo: any;
    private _remoteVideo1: any;
    //private _remoteVideo2: any;
    private _startTime: any;
    private _timer: any;
    private _acceptingCalleeDto: any;

    private _offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
    };

    private _callId: any;
    //private _fullChannelName: any;

    // public testImg1 = faker.image.avatar();
    // public testText1 = faker.lorem.sentence();
    // public testFName1 = faker.name.firstName();
    // public testLName1 = faker.name.lastName();
    // public testImg2 = faker.image.avatar();
    // public testText2 = faker.lorem.sentence();
    // public testFName2 = faker.name.firstName();
    //public testLName2 = faker.name.lastName();

    public async: any;
    message = '';
    messages: string[] = [];

    public firstCall: boolean = true;
    public videoConstraints: any = {};
    public audioConstraints: any;
    //public constraints: any;
    public videoStream: any;
    public videoTracks: any;
    public audioTracks: any;
    public mainStream: any;
    public myStream: any;
    public videoSelect: any;
    public resolutionSelect: any;
    public audioSelect: any;
    public streamPlayer: any;

    public audioSet: Array<string> = ["up", "off"];
    public cameraSet: Array<string> = ["", "off"];

    public audioDevices: any = [];
    public videoDevices: any = [];
    public devicePreferences: any = {}

    public showGeneral: any;
    public showVideo: any;
    public showAudio: any;
    public videoSize: string;

    public _inCall: boolean = false;
    public _callFail: boolean = false;
    public showRequest: boolean = false;// Request Modal
    public showJoin: boolean = false;// Join Modal
    public requestSettings: boolean = false;// Shows Settings on Request/Join Modal
    public fullScreenMode: boolean = false;// If Video is full screen
    public audioPermissions: boolean = true;// False if Denied or not selected
    public videoPermissions: boolean = true;// False if Denied or not selected
    public permissionsError: boolean = false;
    public permissionsErrorAo: boolean = false;
    public streamError: any = {
        "isError": false, // True if Stream Error
        "errorType": ""
    }
    public _callInitalizer: any;
    public _initiatorClientId: any;

  // public officers: any = {};
  // public channelId: string;
  public _initiatorConnectionId: any;
  public _ice = {
    "iceServers": []
  };

  //public _ice = {
  //  "iceServers": [
  //    { "urls": "stun:208.64.181.244:3478" },
  //    {
  //      "urls": "turn:208.64.181.244:3478?transport=udp",
  //      "credential": "via3ice",
  //      "username": "via3ice"
  //    },
  //    {
  //      "urls": "turn:208.64.181.244:3478?transport=tcp",
  //      "credential": "via3ice",
  //      "username": "via3ice"
  //    }
  //  ]
  //};

    constructor(
        private _apiCalls: ApiCallsService,
        private _route: ActivatedRoute,
        private _channelService: ChannelService,
        private _connectionManagerService: ConnectionManagerService,
        private _intakeService: AppIntakeService
    ) {

        if (this._channelService) {
            // this._channelService.onJoinEvent$.subscribe(() => {
            //     console.log("Joined Channel " + this.channelId);
            // });

            this._channelService.onCallChannelEvent$.subscribe((callObj: any) => {
                //alert(callObj.initiatorClientId)
                this.channelId = callObj.channelId;
                this._callId = callObj.callId;
                this._callInitalizer = callObj.caller;
                this._initiatorClientId = callObj.initiatorClientId;
                this._initiatorConnectionId = callObj.callerConnectionId;

                let streamType: any = document.getElementById('video-stream');
                if (streamType.getAttribute('stream-type') === 'a-officer') {
                    // this.devicePrompt(false, true);
                    this.aoDevicePrompt();
                    // this.rtcAcceptCall(true);
                } else {
                    this.showRequest = true;
                    this.promptVideoCall.emit();
                }
            });

            this._channelService.onCallAcceptedEvent$.subscribe((acceptingCalleeDto: any) => {
                //alert("RtcInitiatorCallAccepted")
                this.onCallAccepted(acceptingCalleeDto);
            });

            this._channelService.onDeclineCallEvent$.subscribe((userId: any) => {
                //only if all the users decline
                this._channelService.sendMessageToChannel(userId + " has declined the call", this.channelId);
                //this.HangUp();
            });

            this._channelService.onHangUpUserEvent$.subscribe(() => {
                console.log("hangupuser subscribe");
                this.HangUpUser();
            });

            this._channelService.onReceiveOfferSignalEvent$.subscribe((offerObj: any) => {
                //alert(JSON.stringify(offerObj));
                console.log("ReceiveOfferSignalEvent rtc comp " + JSON.stringify(offerObj.offer));
                //this.onReceiveOfferSignal(offerObj.callerClientId, offerObj.localDescription, offerObj.securityToken);

                this.gotMessageFromServer(offerObj.offer);
            });

            this._channelService.onReceiveRtcCandidateSignalEvent$.subscribe((candidateObj: any) => {
                console.log("Receive RTC Candidate Signal subscribe " + candidateObj.candidate);
                //this.onReceivedCandidateSignal(candidateObj);

                this.gotMessageFromServer(candidateObj.candidate);
            });

            //this._channelService.onReceiveRtcAnswerSignalEvent$.subscribe((answerObj: any) => {
            //    console.log("RTC receive answer signal event subscribe " + answerObj.answer);
            //    this.gotMessageFromServer(answerObj);
            //});

            this._channelService.onCallCreatedEvent$.subscribe((callId: any) => {
                this.rtcCallCreated(callId);
            });

            this._channelService.onStreamAddedEvent$.subscribe((stream: any) => {
                console.log("stream added event " + JSON.stringify(stream));
                this._remoteVideo1.srcObject = stream;

            });

            this._channelService.onCallErrorEvent$.subscribe(() => {
                alert("The call you are trying to join has ended or is not currently in progress.")
            });

            this._channelService.onRtcMaxAttendeeLimitReachedEvent$.subscribe(() => {
                alert("The call you are trying to join is full.");
            });

            this._channelService.onRtcCallInProgressEvent$.subscribe(() => {
                //alert("Call in progress");
            });

            this._channelService.onSelectPoliceOfficerEvent$.subscribe((selectedChannelId: any) => {
                //alert("selected officer channel id: " + selectedChannelId);
                this.channelId = selectedChannelId;
            });

            this._channelService.onRtcAccepteeCallAcceptedEvent$.subscribe(() => {
                alert("rtcaccepteecallaccepted");
            });

            this._channelService.onShowBeginCallPromptEvent$.subscribe((channelId: any) => {
                this.channelId = channelId;
                this.promptVideoCall.emit();
                this.showJoin = true;
            });

            this._channelService.onBroadcastEvent$.subscribe((data: any) => {
                console.log("onbroadcastevent subscribe " + data);
                //alert("Onbroadcastevent " + data);
                this.gotMessageFromServer(data.answer);
            });

        } else {
            alert("Channel Service not initialized");
        }

    }

    getUserMediaSuccess(stream: any) {
        this.localStream = stream;
        this.localVideo.srcObject = stream;
        console.log("get user media success");
    }

    BroadcastTully(isCaller: boolean, includeVideo: boolean) {

    console.log("broadcastTully isCaller: " + isCaller);
    //alert("broadcast");
    //let data = "hello";
    
    this.peerConnection = new RTCPeerConnection(this._ice);

        this.peerConnection.onicecandidate = (event) => {
            console.log("onice event");
            this.gotIceCandidate(event, isCaller, this._initiatorConnectionId);
        }

        //this.peerConnection.onaddstream = (event) => {
        this.peerConnection.ontrack = (event) => {
            console.log("ontrack event");
            this.gotRemoteStream2(event);
        };
        this.peerConnection.addStream(this._localStream);

        //if (isCaller) {
        //  this.peerConnection.createOffer()
        //    .then((description) => {
        //      this.createdDescription(description);
        //    })
        //    .catch(this.errorHandler);
        //}

        //this._channelService.broadcast(data, this.channelId);
    }

    gotMessageFromServer(message: any) {
        if (!this.peerConnection) this.BroadcastTully(false, true);
        //console.log("gotMessageFromServer: " + JSON.stringify(message)[2]);
        //console.log("gotMessageFromServer: " + JSON.stringify(message)[3]);
        console.log("gotMessageFromServer: " + message);
        console.log("gotMessageFromServer: " + JSON.stringify(message));
        //var signal = JSON.parse(JSON.stringify(message));
        if (message) {
            var signal = JSON.parse(message);
        } else {
            console.log("Got message from server error " + JSON.stringify(message));
        }

        if (signal.sdp) {
            console.log("signal.sdp");
            this.peerConnection.setRemoteDescription(new RTCSessionDescription(signal.sdp)).then(() => {
                console.log("setremotedescription");
                // Only create answers in response to offers
                if (signal.sdp.type == 'offer') {
                    console.log("signal.type.offer");
                    this.peerConnection.createAnswer()
                        .then((answer) => {
                            console.log("signal.type.answer");
                            this.createdDescription(answer, this._initiatorConnectionId);
                        })
                        .catch((e) => {
                            this.errorHandler(e);
                        });
                }
            }).catch(this.errorHandler);
        } else if (signal.ice) {
            console.log("signal.type.ice")
            this.peerConnection.addIceCandidate(new RTCIceCandidate(signal.ice)).catch(this.errorHandler);
        }
    }

    gotIceCandidate(event: any, isCaller: boolean, connectionId: any) {
        if (event.candidate != null) {
            console.log("goticecandidate connectionId: " + connectionId);
            this._channelService.RTCSendCandidateSignal(connectionId, "123456", JSON.stringify({ 'ice': event.candidate }), this.channelId);
        }
    }

    createdDescription(description: any, connectionId: any) {
        console.log('got description ' + JSON.stringify(description));

        this.peerConnection.setLocalDescription(description).then(() => {

            console.log("createdDescription " + JSON.stringify(this.peerConnection.localDescription));

            if (description.type == "offer") {
                this._channelService.RTCSendOfferSignal(connectionId, JSON.stringify({ 'sdp': this.peerConnection.localDescription }), this.channelId);
            }

            else if (description.type == "answer") {
                this._channelService.RTCSendAnswerSignal(this._initiatorConnectionId, JSON.stringify({ 'sdp': this.peerConnection.localDescription }), "123456", this.channelId);
            }
        }).catch((e) => {
            this.errorHandler(e);
        });
    }

    gotRemoteStream2(event: any) {
        //alert('got remote stream');
        console.log("got remote stream " + JSON.stringify(event));
        this._remoteVideo1.srcObject = event.streams[0];
    }

    errorHandler(error: any) {
        console.log(error);
    }

    displayTimer = async () => {
        if (true === this._inCall) {
            // later record end time
            var now = Math.floor(Date.now() / 1000);

            // time difference in ms
            var timeDiff = now - this._startTime;

            // get seconds
            var seconds = Math.round(timeDiff % 60);
            seconds = this.padTime(seconds);

            // remove seconds from the date
            timeDiff = Math.floor(timeDiff / 60);

            // get minutes
            var minutes = Math.round(timeDiff % 60);
            minutes = this.padTime(minutes);

            // remove minutes from the date
            timeDiff = Math.floor(timeDiff / 60);

            // get hours
            var hours = Math.round(timeDiff % 24);

            // remove hours from the date
            timeDiff = Math.floor(timeDiff / 24);

            // the rest of timeDiff is number of days
            var days = timeDiff;

            if (days < 1) {
                document.getElementById("call-timer").innerHTML = hours + ":" + minutes + ":" + seconds;
            } else {
                document.getElementById("call-timer").innerHTML = days + " days, " + hours + ":" + minutes + ":" + seconds;
            }
            var t = setTimeout(this.displayTimer, 1000);
        }

    }

    padTime(i) {
        if (i < 10) {
            i = "0" + i; // add zero in front of numbers < 10
        }
        return i;
    }

    timer() {
        this._startTime = Math.floor(Date.now() / 1000);
        this.displayTimer();
    }

    hideTimer = async () => {
        document.getElementById("call-timer").innerHTML = "";
    }

    aoDevicePrompt() {
        if (!this.audioPermissions || !this.videoPermissions) {
            this.permissionsErrorAo = true;
            this.checkDevices(true, true)
        } else {
            this.permissionsErrorAo = false;
            this.rtcAcceptCall(true)
        }
    }

    devicePrompt(callClick, includeVideo) {
        if (!this.audioPermissions) {
            this.permissionsError = true;
            this.checkDevices(includeVideo, true);
        } else if (!this.videoPermissions && includeVideo) {
            this.permissionsError = true;
            this.checkDevices(true, true);
        } else {
            this.permissionsError = false;
            if (callClick) {
                this.Call_Click(includeVideo);
            } else {
                this.rtcAcceptCall(includeVideo)
            }
        }
    }

    Call_Click = async (includeVideo: any) => {
        // alert("call click " + this.channelId);
        this.showJoin = false;
        this.streamError.isError = false;
        console.log("CallHelper");

        try {
            let constraints = this.getConstraints(includeVideo);
            if (navigator.mediaDevices.getUserMedia) {
                await navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream) => {
                        console.log("Successful getUserMedia");

                        //this._localVideo.srcObject = stream;
                        this._localStream = stream;
                        this.videoTracks = this._localStream.getVideoTracks();
                        this.audioTracks = this._localStream.getAudioTracks();

                        //this._connectionManagerService.initialize(this.setIceCandidates(), this._localStream, this.channelId);

                        this._channelService.rtcCallChannel(includeVideo, this.channelId);

                        //this._inCall = true;
                        this._localVideo.srcObject = this._localStream;

                        //this.timer();
                        this.setMediaDevices()
                    })
                    .catch((err) => {
                        this.errorCallback(err, constraints.video.width.ideal, constraints.video.height.ideal);
                        this._callFail = true;
                    });
            } else {
                this.streamError.isError = true;
                this.streamError.errorType = "no-compatibility";
            }
            // event.target.disabled = true;
            // alert(JSON.stringify(stream.getTracks);
        } catch (event) {
            this.handleError(event);
        }
    }

    async rtcAcceptCall(includeVideo: boolean) {
        this.showRequest = false;
        this.streamError.isError = false;

        try {
            let streamType: any = document.getElementById('video-stream');
            if (streamType.getAttribute('stream-type') === 'a-officer') {
                this.streamPlayer.style.minHeight = "calc(100vh - 12rem)";
            }
            //alert(this.channelId);
            // console.log("rtcacceptcall");

            if (!this.audioPermissions || !this.videoPermissions) {
                this.checkDevices(includeVideo, true);
            }
            let constraints = this.getConstraints(includeVideo);
            if (navigator.mediaDevices) {
                await navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream) => {
                        console.log("Successful getUserMedia");
                        this.showRequest = false;

                        //this._localVideo.srcObject = stream;
                        this._localStream = stream;
                        this.videoTracks = this._localStream.getVideoTracks();
                        this.audioTracks = this._localStream.getAudioTracks();

                        //this._connectionManagerService.initialize(this.setIceCandidates(), this._localStream, this.channelId);

                        this._inCall = true;
                        this._localVideo.srcObject = this._localStream;

                        //alert(this.channelId);
                        //alert(this._initiatorClientId);
                        console.log("callInititiatorConnectionId: " + this._initiatorConnectionId + " includeVideo:" + includeVideo + " channelId: " + this.channelId + " callInitializer: " + JSON.stringify(this._callInitalizer.userId) + " initiatorClientId: " + this._initiatorClientId);
                        this._channelService.rtcAcceptCall(includeVideo, this.channelId, this._callId, this._callInitalizer, this._initiatorClientId, this._initiatorConnectionId);

                        this.timer();

                        this.setMediaDevices()
                    })
                    .catch((err) => {
                        this.errorCallback(err, constraints.video.width.ideal, constraints.video.height.ideal);
                        this._callFail = true;
                    });
            } else {
                this.streamError.isError = true;
                this.streamError.errorType = "no-compatibility";
            }
            // event.target.disabled = true;
            // alert(JSON.stringify(stream.getTracks);
        } catch (event) {
            this.handleError(event);
        }
    }

    rtcDeclineCall = () => {
        //alert(this._initiatorClientId);
        this._channelService.rtcDeclineCall(this.channelId, this._callId, this._initiatorClientId);

        this.streamError = {
            "isError": false,
            "errorType": ""
        }
        this.showRequest = false;
        this.showJoin = false;
        this._callFail = false;
        this.resetVideoCall.emit();
    }

    onReceiveOfferSignal = async (callerClientId: any, localDescription: any, securityToken: any) => {
        try {
            //alert("onreceiveoffersignal");
            console.log("onreceiveoffersignal");
            this._connectionManagerService.newSignal(callerClientId, localDescription, securityToken);
        } catch (event) {
            this.handleError(event);
        }
    }

// methods shouldnt need public/private 
    //public setVideo = async (localDescription: any) => {
    //  this.videoTracks = this._localStream.getVideoTracks();
    //  this.audioTracks = this._localStream.getAudioTracks();

    //  var localDescriptionParsed = JSON.parse(localDescription);

    //  console.log("new signal " + JSON.stringify(localDescriptionParsed.sdp));

    //  //if (localDescriptionParsed.sdp) {
    //  console.log("new signal");
    //  //todo: check to see if media stream is instantiated

    //  //this.receivedSdpSignal(connection, callerClientId, localDescription.sdp, mediaStream, securityToken);

    //  let configuration = this.setIceCandidates();

    //  let pc0 = new RTCPeerConnection(null);
    //  //pc0.onicecandidate(this.onIceCandidate(pc0, e));

    //  pc0.onicecandidate = (e) => {
    //    //todo: send candidates up to channel hub, add partnerClientId

    //    ////this._channelService.RTCSendCandidateSignal(partnerClientId, securityToken,
    //    ////    JSON.stringify({ "candidate": e.candidate }), this._fullChannelName);

    //    //this._channelService.RTCSendCandidateSignal(JSON.stringify({ "candidate": e.candidate }), channelName);
    //    //this.onIceCandidate(pc0, e);
    //    //alert(JSON.stringify(e));

    //    //console.log(JSON.stringify(pc));
    //    //try {
    //    //    //await (pc.addIceCandidate(new RTCIceCandidate(event.candidate)));
    //    //    await(this._connection.addIceCandidate(e.candidate));
    //    //    console.log(`${pc} addIceCandidate success`);
    //    //} catch (err) {
    //    //    console.log(`${pc} failed to add ICE Candidate: ${err.toString()}`);
    //    //}
    //    //console.log(`${pc} ICE candidate:\n${e.candidate ? event.e.candidate : '(null)'}`);
    //  }
    //  pc0.addEventListener('icecandidate', e => this.onIceCandidate(pc0, e));
    //  pc0.addEventListener('iceconnectionstatechange', e => this.onIceStateChange(pc0, e));
    //  pc0.addEventListener('track', e => this.gotRemoteStream(e));
    //  this._localStream.getTracks().forEach(track => pc0.addTrack(track, this._localStream));

    //  console.log('pc0 setRemoteDescription start');
    //  try {
    //    await pc0.setRemoteDescription(localDescriptionParsed.sdp);
    //    this.onSetRemoteSuccess(pc0);
    //  } catch (e) {
    //    this.onSetSessionDescriptionError(e);
    //  }

    //  console.log('pc2 createAnswer start');
    //  try {
    //    const answer = await pc0.createAnswer();
    //    await this.onCreateAnswerSuccess(answer, pc0);
    //  } catch (e) {
    //    this.onCreateSessionDescriptionError(e);
    //  }

    //  //} else {
    //  //this.receivedCandidateSignal(connection, callerClientId, localDescription.candidate);
    //  //}
    //}

    //public receivedSdpSignal = async (connection, callerClientId, sdp, securityToken) => {
    //    //create a remote peer connection
    //    let configuration = this._ice;
    //    this._pc1 = new RTCPeerConnection(configuration);
    //    this._pc1.addEventListener('iceconnectionstatechange', e => this.onIceStateChange(this._pc1, e));
    //    this._pc1.addEventListener('icecandidate', e => this.onIceCandidate(this._pc1, e));
    //    this._localStream.getTracks().forEach(track => this._pc1.addTrack(track, this._localStream));

    //    //set remote description equal to sdp

    //    console.log('pc1 setRemoteDescription start');
    //    try {
    //        await this._pc1.setRemoteDescription(sdp);
    //        this.onSetRemoteSuccess(this._pc1);
    //    } catch (e) {
    //        this.onSetSessionDescriptionError(e);
    //    }
    //    //create answer
    //    // use the answer's local description to create a local peer connection
    //    console.log('pc2 createAnswer start');
    //    try {
    //        const answer = await this._pc2.createAnswer();
    //        await this.onCreateAnswerSuccess(answer);
    //    } catch (e) {
    //        this.onCreateSessionDescriptionError(e);
    //    }

    //    //make a call to send the answer's local description to the caller
    //    //caller will use this description as it's remote description

    //}

    onReceivedCandidateSignal = (candidateObj: any) => {
        //set ice candidate
        console.log("onreceivedcandidatesignal " + JSON.stringify(candidateObj.candidate));
        if (!candidateObj.candidate.sdp) {
            let candidateParsed = JSON.parse(candidateObj.candidate);
            console.log("received candidate signal " + JSON.stringify(candidateParsed));

            this._connectionManagerService.newSignal(candidateObj.clientId, candidateObj.candidate, candidateObj.securityToken)
        }
    }

    onReceiveAnswerSignal = (answerObj: any) => {
        console.log("onreceivedanswersignal " + JSON.stringify(answerObj.answer));
        this._connectionManagerService.newSignal(answerObj.clientId, answerObj.answer, answerObj.securityToken);
    }

    //WebRTC

    errorCallback = (error: any, videoWidth: any, videoHeight: any) => {
        if (
            error.name === "ConstraintNotSatisfiedError" ||
            error.name === "OverconstrainedError"
        ) {
            console.log(error.name);
            console.error("The resolution " + videoWidth + "x" +
                videoHeight + " px is not supported by your device.");
            this.streamError.isError = true;
            this.streamError.errorType = "overconstrain";
        } else if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDismissedError" ||
            error.name === "PermissionDeniedError"
        ) {
            console.error("Permissions have not been granted to use your camera and " + "microphone, you need to allow the page access to your devices in " + "order for the video call to work.");
            this.streamError.isError = true;
            this.streamError.errorType = "no-permissions";
        } else if (
            error.name === "NotFoundError" ||
            error.name === "DevicesNotFoundError" ||
            error.name === "TypeError"
        ) {
            //todo: display webcam something went wrong message  to user
            console.error(error.name + " : " + error.message);
            this.streamError.isError = true;
            this.streamError.errorType = "no-video-audio";
        } else if (
            error.name === "NotReadableError" ||
            error.name === "TrackStartError"
        ) {
            //todo: display webcam something went wrong message  to user
            console.error(error.name + " : " + error.message + ", your device might be in use a a seperate program");
            this.streamError.isError = true;
            this.streamError.errorType = "no-device-available";
        } else {
            console.error("Failed to get hardware access! Do you have another " +
                "browser type open and using your cam/mic? You were not connected to the " +
                "server. Actual Error: " + error);
            this.streamError.isError = true;
            this.streamError.errorType = "unknown";
        }
    }

    async onCallAccepted(acceptingCalleeDto: any) {
        //alert(JSON.stringify(acceptingCalleeDto));
        //alert("call initiator call accepted");
        this._acceptingCalleeDto = acceptingCalleeDto;

        // this.setIceCandidates();
        this.peerConnection = new RTCPeerConnection(this._ice);

        this.peerConnection.onicecandidate = (event) => {
            console.log("onice event");
            this.gotIceCandidate(event, false, this._acceptingCalleeDto.calleeConnectionId);
        }

        //this.peerConnection.onaddstream = (event) => {
        this.peerConnection.ontrack = (event) => {
            console.log("ontrack event");
            this.gotRemoteStream2(event);
        };

        this.peerConnection.addStream(this._localStream);

        this.peerConnection.createOffer()
            .then((description) => {
                this.createdDescription(description, acceptingCalleeDto.calleeConnectionId);
            })
            .catch(this.errorHandler);

        this.timer();
    }

    handleError(error) {
        if (error.name === 'ConstraintNotSatisfiedError') {
            this.errorMsg('Resolution not Found', null);
        } else if (error.name === 'PermissionDeniedError') {
            this.errorMsg('Permissions have not been granted to use your camera and ' +
                'microphone, you need to allow the page access to your devices in ' +
                'order for the demo to work.', null);
        }
        this.errorMsg(`getUserMedia error: ${error.name}`, error);
    }

    errorMsg(msg, error) {
        const errorElement = document.querySelector('#errorMsg');
        errorElement.innerHTML += `<p>${msg}</p>`;
        if (typeof error !== 'undefined') {
            console.error(error);
        }
    }

    //Stream Settings
    switchAudio(value: any) {
        this.devicePreferences.audioId = value;
        localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        // let constraints = this._connectionManagerService.setConstraints(true, this.devicePreferences.videoId, this.videoConstraints.height, this.videoConstraints.width, this.devicePreferences.audioId)
        // this._localVideo.srcObject.applyConstraints(constraints)
        //   .catch(err => {
        //     console.error(err);
        //   });
    }

    // private switchAudio(constraints: any) {
    //   this.audioTracks.forEach(function (track) {
    //     track.stop();
    //   })
    //   if (constraints.audio != false) {
    //     constraints.audio = {
    //       deviceId: { exact: this.audioSelect.value }
    //     };
    //     this.Call();
    //   } else {
    //     constraints.audio = {
    //       deviceId: { exact: this.audioSelect.value }
    //     };
    //   }
    // }

    switchVideo(value: any) {
        this.devicePreferences.videoId = value;
        localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        // let constraints = this._connectionManagerService.setConstraints(true, this.devicePreferences.videoId, this.videoConstraints.height, this.videoConstraints.width, this.devicePreferences.audioId)
        // this._localVideo.srcObject.applyConstraints(constraints)
        //   .catch(err => {
        //     console.error(err);
        //   });
    }

    // private switchVideo(constraints: any) {
    //   // if (this.videoTracks) {
    //   this.videoTracks.forEach(function (track) {
    //     track.stop();
    //   });
    //   if (constraints.video != false) {
    //     constraints.video = {
    //       deviceId: { exact: this.videoSelect.value },
    //       width: { exact: this.videoConstraints.width },
    //       height: { exact: this.videoConstraints.height }
    //     };
    //     this.Call();
    //   } else {
    //     constraints.video = {
    //       deviceId: { exact: this.videoSelect.value },
    //       width: { exact: this.videoConstraints.width },
    //       height: { exact: this.videoConstraints.height }
    //     };
    //   }
    //   // } else {
    //   //     //TODO: Figure out error -- Joe
    //   // }
    // }

    switchResolution(value: any) {
        console.log(value);
        if (value === "2160") {
            this.devicePreferences.videoRes = 2160;
        } else if (value === "1440") {
            this.devicePreferences.videoRes = 1440;
        } else if (value === "1080") {
            this.devicePreferences.videoRes = 1080;
        } else if (value === "720") {
            this.devicePreferences.videoRes = 720;
        } else if (value === "480") {
            this.devicePreferences.videoRes = 480;
        } else {
            this.devicePreferences.videoRes = 240;
        }
        localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        console.log("video resolution set");
    }

    //Stream Controls
    audio() {
        if (this.audioSet[1] === 'off') {
            this.audioSet = ["off", "on"];
            this.audioTracks.forEach(function (track) {
                track.enabled = false;
            });
        } else {
            this.audioSet = ["up", "off"];
            this.audioTracks.forEach(function (track) {
                track.enabled = true;
            });
        }
    }

    // public audio(constraints: any) {
    //   if (this.audioSet[1] === 'off') {
    //     this.audioSet = ["off", "on"];
    //     constraints.audio = false;
    //     this.audioTracks.forEach(function (track) {
    //       track.stop();
    //     })
    //   } else {
    //     this.audioSet = ["up", "off"];
    //     constraints.audio = {
    //       deviceId: { exact: this.audioSelect.value }
    //     };
    //     this.CallChannel(event);
    //   }
    // }

    camera() {
        if (this._localVideo.srcObject) {
            if (this.cameraSet[1] === "off") {
                this.cameraSet = ["-off", "on"];
                this.videoTracks.forEach(function (track) {
                    track.enabled = false;
                });
            } else {
                this.cameraSet = ["", "off"];
                this.videoTracks.forEach(function (track) {
                    track.enabled = true;
                });
            }
        }
    }

    // public camera(constraints: any) {
    //   // console.log(constraints)
    //   // if (constraints) {
    //   if (this.cameraSet[1] === "off") {
    //     this.cameraSet = ["-off", "on"];
    //     constraints.video = false;
    //     this.videoTracks.forEach(function (track) {
    //       track.stop();
    //     });
    //     // this.video.srcObject = null;
    //   } else {
    //     this.cameraSet = ["", "off"];
    //     constraints.video = {
    //       deviceId: { exact: this.videoSelect.value }
    //     };
    //     // this.CallChannel(event);
    //   }
    //   // } else {
    //   //     //TODO: Figure out error -- Joe
    //   // }
    // }

    settings() {
        let att = document.getElementsByClassName("stream-bar-overlay")[0];
        if (att.getAttribute("s-settings") === "inactive") {
            att.setAttribute("s-settings", "active")
        } else {
            att.setAttribute("s-settings", "inactive")
        }
    }

    fullscreen() {
        let elem = this.streamPlayer;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // } else if (document.mozCancelFullScreen) { /* Firefox */
            //   document.mozCancelFullScreen();
            // } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            //   document.webkitExitFullscreen();
            // } else if (document.msExitFullscreen) { /* IE/Edge */
            //   document.msExitFullscreen();
            // }
        }
        this.showFullScreen();
    }


    showSettings(val: number) {
        this.showGeneral.style.display = 'none';
        this.showVideo.style.display = 'none';
        this.showAudio.style.display = 'none';

        if (val === 3) {
            this.showAudio.style.display = 'block';
        } else if (val === 2) {
            this.showVideo.style.display = 'block';
        } else {
            this.showGeneral.style.display = 'block';
        }
    }

    timerShow(check) {
        let timer: any = document.getElementById('call-timer')
        let checkbox = check.currentTarget;
        if (checkbox.checked) {
            timer.style.display = "block"
            // timer.style.color = "red"
        } else {
            timer.style.display = "none";
            // timer.style.color = "blue"
        }
    }

    //public async onIceCandidate2(pc, event) {
    //    try {
    //        await (this.getOtherPc2(pc).addIceCandidate(event.candidate));
    //        this.onAddIceCandidateSuccess2(pc);
    //    } catch (e) {
    //        this.onAddIceCandidateError2(pc, e);
    //    }
    //    console.log(`${this.getName2(pc)} ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`);
    //}

    //public onAddIceCandidateSuccess2(pc) {
    //    console.log(`${this.getName2(pc)} addIceCandidate success`);
    //}

    //public onAddIceCandidateError2(pc, error) {
    //    console.log(`${this.getName2(pc)} failed to add ICE Candidate: ${error.toString()}`);
    //}

    onIceStateChange(pc, event) {
        if (pc) {
            console.log(`${pc} ICE state: ${pc.iceConnectionState}`);
            console.log('ICE state change event: ', event);
        }
    }

    //public onIceStateChange2(pc, event) {
    //    if (pc) {
    //        console.log(`${this.getName2(pc)} ICE state: ${pc.iceConnectionState}`);
    //        console.log('ICE state change event: ', event);
    //    }
    //}

    //public getName(pc) {
    //    return (pc === this._pc1) ? 'pc1' : 'pc2';
    //}

    //public getName2(pc) {
    //    return (pc === this._pc3) ? 'pc3' : 'pc4';
    //}

    //public getOtherPc(pc) {
    //    return (pc === this._pc1) ? this._pc2 : this._pc1;
    //}

    //public getOtherPc2(pc) {
    //    return (pc === this._pc3) ? this._pc4 : this._pc3;
    //}

    // public gotRemoteStream(e) {
    //     console.log("remote video: " + JSON.stringify(this._remoteVideo1));
    //     if (this._remoteVideo1.srcObject !== e.streams[0]) {
    //         this._remoteVideo1.srcObject = e.streams[0];
    //         console.log('pc2 received remote stream');
    //     }
    // }

    //public gotRemoteStream2(e) {
    //    console.log("remote video: " + this._remoteVideo2);
    //    if (this._remoteVideo2.srcObject !== e.streams[0]) {
    //        this._remoteVideo2.srcObject = e.streams[0];
    //        console.log('pc4 received remote stream');
    //    }
    //}

    onCreateSessionDescriptionError(error) {
        console.log(`Failed to create session description: ${error.toString()}`);
    }

    onSetLocalSuccess(pc) {
        console.log(`${pc} setLocalDescription complete`);
    }

    //public onSetLocalSuccess2(pc) {
    //    console.log(`${this.getName2(pc)} setLocalDescription complete`);
    //}

    onSetRemoteSuccess(pc) {
        console.log(`${pc} setRemoteDescription complete`);
    }

    //public onSetRemoteSuccess2(pc) {
    //    console.log(`${this.getName2(pc)} setRemoteDescription complete`);
    //}

    onSetSessionDescriptionError(error) {
        console.log(`Failed to set session description: ${error.toString()}`);
    }

    async onCreateAnswerSuccess(desc: any, pc0: any) {
        console.log(`Answer from pc2:\n${desc.sdp}`);
        console.log('pc2 setLocalDescription start');
        try {
            await pc0.setLocalDescription(desc);
            this.onSetLocalSuccess(pc0);
        } catch (e) {
            this.onSetSessionDescriptionError(e);
        }
        //--console.log('pc1 setRemoteDescription start');
        //--try {
        //--    await this._pc1.setRemoteDescription(desc);
        //--    this.onSetRemoteSuccess(this._pc1);
        //--} catch (e) {
        //--    this.onSetSessionDescriptionError(e);
        //--}
    }

    //public async onCreateAnswerSuccess2(desc) {
    //    console.log(`Answer from pc4:\n${desc.sdp}`);
    //    console.log('pc4 setLocalDescription start');
    //    try {
    //        await this._pc4.setLocalDescription(desc);
    //        this.onSetLocalSuccess2(this._pc4);
    //    } catch (e) {
    //        this.onSetSessionDescriptionError(e);
    //    }
    //    console.log('pc3 setRemoteDescription start');
    //    try {
    //        await this._pc3.setRemoteDescription(desc);
    //        this.onSetRemoteSuccess2(this._pc3);
    //    } catch (e) {
    //        this.onSetSessionDescriptionError(e);
    //    }
    //}

    //public async onCreateOfferSuccess2(desc) {
    //    console.log(`Offer from pc3\n${desc.sdp}`);
    //    console.log('pc3 setLocalDescription start');
    //    try {
    //        await this._pc3.setLocalDescription(desc);
    //        this.onSetLocalSuccess2(this._pc3);
    //    } catch (e) {
    //        this.onSetSessionDescriptionError(e);
    //    }

    //    console.log('pc4 setRemoteDescription start');
    //    try {
    //        await this._pc4.setRemoteDescription(desc);
    //        this.onSetRemoteSuccess2(this._pc4);
    //    } catch (e) {
    //        this.onSetSessionDescriptionError(e);
    //    }

    //    console.log('pc4 createAnswer start');
    //    // Since the 'remote' side has no media stream we need
    //    // to pass in the right constraints in order for it to
    //    // accept the incoming offer of audio and video.
    //    try {
    //        const answer = await this._pc4.createAnswer();
    //        await this.onCreateAnswerSuccess2(answer);
    //    } catch (e) {
    //        this.onCreateSessionDescriptionError(e);
    //    }
    //}

    setIceCandidates() {
     this._connectionManagerService.getIceSettings()
      .subscribe(data => {
        let iceServer;

        //console.log("GetIceSettings " + JSON.stringify(data.body));
        data.body.forEach((ice) => {
          console.log(JSON.stringify(ice));
          if (ice.type.toLowerCase() === "stun") {
            iceServer = {
              "url": "stun:" + ice.ipAddress + ":" + ice.port
            };
          } else {
            iceServer = {
              "url": "turn:" + ice.ipAddress + ":" + ice.port + ice.queryString,
              "credential": ice.credential,
              "username": ice.username
            };
          }

          this._ice.iceServers.push(iceServer);
        })
        //return this._ice;
      })
  }

  //private setIceCandidates() {
  //  let ice = {
  //    "iceServers": [
  //      { "urls": "stun:208.64.181.244:3478" },
  //      {
  //        "urls": "turn:208.64.181.244:3478?transport=udp",
  //        "credential": "via3ice",
  //        "username": "via3ice"
  //      },
  //      {
  //        "urls": "turn:208.64.181.244:3478?transport=tcp",
  //        "credential": "via3ice",
  //        "username": "via3ice"
  //      }
  //    ]
  //  };
  //  console.log("Ice: " + JSON.stringify(ice));
  //  return ice;
  //}

    getConstraints(includeVideo: boolean) { 
        console.log("setting constraints");
        let constraints = this._connectionManagerService.setConstraints(includeVideo, this.devicePreferences.videoId, this.videoConstraints.height, this.videoConstraints.width, this.devicePreferences.audioId);

        if (constraints.audio) {
            this.audioSet = ["up", "off"];
        } else {
            this.audioSet = ["off", "on"];
        }

        if (constraints.video) {
            this.cameraSet = ["", "off"];
        } else {
            this.cameraSet = ["-off", "on"];
        }
        return constraints;
    }

    async Call() {
        console.log("Call");
        this._inCall = true;
        this._startTime = window.performance.now();
        this.videoTracks = this._localStream.getVideoTracks();
        this.audioTracks = this._localStream.getAudioTracks();

    //let configuration = this.setIceCandidates();
    //let configuration = { sdpSemantics: "unified-plan" };
    //let configuration = { sdpSemantics: "plan-b" };
    this._connection = new RTCPeerConnection(null);
    this._connection.addEventListener('icecandidate', e => this.onIceCandidate(this._connection, e));


        //--this._pc2 = new RTCPeerConnection(configuration);
        //--this._pc2.addEventListener('icecandidate', e => this.onIceCandidate(this._pc2, e));

        this._connection.addEventListener('iceconnectionstatechange', e => this.onIceStateChange(this._connection, e));
        //--this._pc2.addEventListener('iceconnectionstatechange', e => this.onIceStateChange(this._pc2, e));
        //--this._pc2.addEventListener('track', e => this.gotRemoteStream(e));

        this._localStream.getTracks().forEach(track => this._connection.addTrack(track, this._localStream));
        //this._pc3 = new RTCPeerConnection(configuration);
        //this._pc4 = new RTCPeerConnection(configuration);
        //this._pc3.addEventListener('icecandidate', e => this.onIceCandidate2(this._pc3, e));
        //this._pc4.addEventListener('icecandidate', e => this.onIceCandidate2(this._pc4, e));

        //this._pc3.addEventListener('iceconnectionstatechange', e => this.onIceStateChange2(this._pc3, e));
        //this._pc4.addEventListener('iceconnectionstatechange', e => this.onIceStateChange2(this._pc4, e));
        //this._pc4.addEventListener('track', e => this.gotRemoteStream2(e));


        //this._localStream.getTracks().forEach(track => this._pc3.addTrack(track, this._localStream));


        //--try {
        //--    console.log('pc1 createOffer start');
        //--    const offer = await this._pc1.createOffer(this._offerOptions);
        //--    await this.onCreateOfferSuccess(offer);
        //--} catch (e) {
        //--    this.onCreateSessionDescriptionError(e);
        //--}

        //try {
        //    console.log('pc3 createOffer start');
        //    const offer = await this._pc3.createOffer(this._offerOptions);
        //    await this.onCreateOfferSuccess2(offer);
        //} catch (e) {
        //    this.onCreateSessionDescriptionError(e);
        //}
    }

    setDOMVariables() {
        this._localVideo = document.querySelector("#my-stream");
        this._localVideo.muted = true;
        this._remoteVideo1 = document.querySelector("#main-stream");
        //this._remoteVideo2 = document.querySelector("#remoteVideo2");


    this.streamPlayer = document.querySelector("#stream-player");
    this.audioSelect = document.querySelector("select#audioSource");
    this.videoSelect = document.querySelector("select#videoSource");
    this.resolutionSelect = document.querySelector("select#videoResolution");

        this.initalConstraints();
    }

    initalConstraints() {
        this.devicePreferences = JSON.parse(localStorage.getItem("devicePreferences"));

        if (this.devicePreferences === null) {
            this.devicePreferences = {
                "videoId": "default",
                "audioId": "default",
                "videoRes": 480
            };
            localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        }

        if (this.devicePreferences.videoRes === 2160) {
            this.videoConstraints.width = 3840;
            this.videoConstraints.height = 2160;
        } else if (this.devicePreferences.videoRes === 1440) {
            this.videoConstraints.width = 2560;
            this.videoConstraints.height = 1440;
        } else if (this.devicePreferences.videoRes === 1080) {
            this.videoConstraints.width = 1920;
            this.videoConstraints.height = 1080;
        } else if (this.devicePreferences.videoRes === 720) {
            this.videoConstraints.width = 1280;
            this.videoConstraints.height = 720;
        } else if (this.devicePreferences.videoRes === 480) {
            this.videoConstraints.width = 640;
            this.videoConstraints.height = 480;
        } else {
            this.videoConstraints.width = 320;
            this.videoConstraints.height = 240;
        }

        console.log(this.devicePreferences);
        this.setMediaDevices();
    }

    checkDevices(videoBool: boolean, audioBool: boolean) {
        const constraints = {
            video: videoBool,
            audio: audioBool
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(() => {
                this.setMediaDevices();
            })
            .catch(err => {
                console.log(err.name + ": " + err.message);
            });
    }

    setMediaDevices() {
        let videoDevices = [];
        let audioDevices = [];
        let videoId = this.devicePreferences.videoId;
        let audioId = this.devicePreferences.audioId;
        this.videoPermissions = true;
        this.audioPermissions = true;
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device => {
                    console.log(device);
                    let option = {
                        "deviceId": device.deviceId,
                        "groupId": device.groupId,
                        "kind": device.kind,
                        "label": device.label
                    };

                    if (device.kind === 'audioinput') {
                        if (device.label == "") {
                            this.audioPermissions = false;
                            //   if (this.streamError.errorType === "") {
                            //     this.streamError.errorType = "no-audio-permissions";
                            //   }
                            // } else {
                            //   if (this.streamError.errorType === "no-audio-permissions") {
                            //     this.streamError.errorType = "";
                            //   }
                        }
                        audioDevices.push(option);
                    } else if (device.kind === 'videoinput') {
                        if (device.label == "") {
                            this.videoPermissions = false;
                            //   if (this.streamError.errorType === "") {
                            //     this.streamError.errorType = "no-video-permissions";
                            //   }
                            // } else {
                            //   if (this.streamError.errorType === "no-video-permissions") {
                            //     this.streamError.errorType = "";
                            //   }
                        }
                        videoDevices.push(option);
                    } else {
                        console.log("Other Device: " + device.kind + ": " + device.label +
                            " id = " + device.deviceId + " was found");
                    }
                });

                if (videoDevices.length > 0) {
                    if (this.streamError.errorType === "no-video") {
                        this.streamError.errorType = "";
                    } else if (this.streamError.errorType === "no-video-audio") {
                        this.streamError.errorType = "no-audio";
                    }
                    if (videoDevices.some(device => device.deviceId === videoId)) {
                        console.log("video is here");
                        videoDevices.sort((x, y) => { return x.deviceId == videoId ? -1 : y.deviceId == videoId ? 1 : 0; });
                    } else {
                        console.log("video is not here");
                        this.devicePreferences.videoId = videoDevices[0].deviceId;
                    }
                } else {
                    this.videoPermissions = false;
                    if (this.streamError.errorType === "") {
                        this.streamError.errorType = "no-video";
                    } else if (this.streamError.errorType === "no-audio") {
                        this.streamError.errorType = "no-video-audio";
                    }
                }

                if (audioDevices.length > 0) {
                    if (this.streamError.errorType === "no-audio") {
                        this.streamError.errorType = "";
                    } else if (this.streamError.errorType === "no-video-audio") {
                        this.streamError.errorType = "no-video";
                    }
                    if (audioDevices.some(device => device.deviceId === audioId)) {
                        console.log("audio is here");
                        audioDevices.sort((x, y) => { return x.deviceId == audioId ? -1 : y.deviceId == audioId ? 1 : 0; });
                    } else {
                        console.log("audio is not here");
                        this.devicePreferences.audioId = audioDevices[0].deviceId;
                        // console.log(this.devicePreferences.audioId);
                    }
                } else {
                    this.audioPermissions = false;
                    if (this.streamError.errorType === "") {
                        this.streamError.errorType = "no-audio";
                    } else if (this.streamError.errorType === "no-video") {
                        this.streamError.errorType = "no-video-audio";
                    }
                }
                // console.log("Stream Errors: " + this.streamError.errorType)
            })
            .catch(err => {
                console.log(err.name + ": " + err.message);
            });
        this.videoDevices = videoDevices;
        this.audioDevices = audioDevices;


        //// For Reference
        // navigator.mediaDevices.enumerateDevices()
        //   .then(function (devices) {
        //     devices.forEach(function (device) {
        //       const option = document.createElement('option');
        //       option.value = device.deviceId;
        //       console.log(device);
        //       if (device.kind === 'audioinput') {
        //         let audioSelect = document.querySelector("select#audioSource");
        //         option.text = device.label;
        //         audioSelect.appendChild(option);
        //       } else if (device.kind === 'videoinput') {
        //         let videoSelect = document.querySelector("select#videoSource");
        //         option.text = device.label;
        //         videoSelect.appendChild(option);
        //       } else {
        //         console.log("Other Device: " + device.kind + ": " + device.label +
        //           " id = " + device.deviceId + " was found");
        //       }
        //     });
        //   })
        //   .catch(function (err) {
        //     console.log(err.name + ": " + err.message);
        //   });
    }

    setVideoSize() {
        let vidCount = document.getElementById('caller-views').childElementCount;
        if (vidCount <= 3) {
            this.videoSize = "three-max";
        } else if (vidCount > 3 && vidCount <= 5) {
            this.videoSize = "five-max";
        } else {
            this.videoSize = "no-max";
        }

        this.showGeneral = document.getElementById('gen-settings');
        this.showVideo = document.getElementById('vid-settings');
        this.showAudio = document.getElementById('aud-settings');

        this.showGeneral.style.display = 'block';
        this.showVideo.style.display = 'none';
        this.showAudio.style.display = 'none';
    }

    async HangUpUser() {
        let streamType: any = document.getElementById('video-stream');
        //alert(streamType.getAttribute('stream-type') === 'a-officer');
        if (streamType.getAttribute('stream-type') === 'a-officer') {
            this.streamPlayer.style.minHeight = 0;
        }
        //alert("hangup user");
        this.hideTimer();
        this.streamError = {
            "isError": false,
            "errorType": ""
        }
        this._inCall = false;
        this._callFail = false;

        if (this._localStream) {
            this._localStream.getTracks().forEach(track => track.stop());
            this._localStream = null;
            this._localVideo.srcObject = null;
        }

        if (this._remoteVideo1.srcObject) {
            this._remoteVideo1.srcObject.getTracks().forEach(track => track.stop());
        }
        this._remoteVideo1.srcObject = null;

        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }

        //if (this._pc1) {
        //    this._pc1.close();
        //    this._pc1 = null;
        //}
        //if (this._pc2) {
        //    this._pc2.close();
        //    this._pc2 = null;
        //}
        //if (this._pc3) {  
        //    this._pc3.close();
        //    this._pc3 = null;
        //}
        //if (this._pc4) {
        //    this._pc4.close();
        //    this._pc4 = null;
        //}
        this.resetVideoCall.emit();

        if (this.fullScreenMode) {
            this.showFullScreen();
        }
    }

    HangUpHelper() {
        //alert(this.channelId + " " + this._callId);
        this._channelService.rtcHangUpUser(this.channelId, this._callId);
    }

    async onCreateOfferSuccess(desc: any, pc0: any) {
        console.log(`Offer from pc1\n${desc.sdp}`);
        console.log('pc1 setLocalDescription start');

        try {
            await pc0.setLocalDescription(desc).then(() => {
                this.onSetLocalSuccess(pc0);
                this._channelService.RTCSendOfferSignal(this._acceptingCalleeDto.clientId, JSON.stringify({ "sdp": desc }), this.channelId);
            }).catch((e) => {
                console.error("Error: " + e);
            });

        } catch (e) {
            this.onSetSessionDescriptionError(e);
        }

        //--console.log('pc2 setRemoteDescription start');
        //--try {
        //--    await this._pc2.setRemoteDescription(desc);
        //--    this.onSetRemoteSuccess(this._pc2);
        //--} catch (e) {
        //--    this.onSetSessionDescriptionError(e);
        //--}

        //--console.log('pc2 createAnswer start');
        // Since the 'remote' side has no media stream we need
        // to pass in the right constraints in order for it to
        // accept the incoming offer of audio and video.
        //--try {
        //--    const answer = await this._pc2.createAnswer();
        //--    await this.onCreateAnswerSuccess(answer);
        //--} catch (e) {
        //--    this.onCreateSessionDescriptionError(e);
        //--}
    }

    async onIceCandidate(pc: any, event: any) {
        //console.log(JSON.stringify(pc));
        try {
            //await (pc.addIceCandidate(new RTCIceCandidate(event.candidate)));
            await (pc.addIceCandidate(event.candidate));
            console.log(`${pc} addIceCandidate success`);
        } catch (e) {
            console.log(`${pc} failed to add ICE Candidate: ${e.toString()}`);
        }
        console.log(`${pc} ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`);
    }

    rtcCallCreated(callId: any) {
        //alert("rtcCallCreated callId: " + callId);
        this._inCall = true;
        this._localVideo.srcObject = this._localStream;
        this._callId = callId;
        //this.timer();
    }

    showJoinModal() {
        this.showJoin = (this.showJoin) ? false : true;
    }

    showRequestSettings() {
        this.requestSettings = (this.requestSettings) ? false : true;
    }

    showFullScreen() {
        this.fullScreenMode = (this.fullScreenMode) ? false : true;
    }

    ngOnInit() {
        this.setIceCandidates();
        this.setDOMVariables();
        this.setVideoSize();
    }
}
