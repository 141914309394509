import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

import { EmojiButton } from "@joeattardi/emoji-button";

@Component({
    selector: "emoji-button",
    templateUrl: "./emoji-button.component.html",
    styleUrls: ["./emoji-button.component.scss"],
})
export class EmojiButtonComponent implements OnInit {
    @Output() sendEmoji = new EventEmitter();

    @Input('channelId') channelId: string;
    @Input('isDM') isDm: boolean;

    picker = new EmojiButton({
        position: "top-start",
        theme: "dark",
        autoHide: false,
        rows: 3,
        emojiVersion: "12.0",
        showVariants: false,
        showPreview: false,
        showSearch: true,
        autoFocusSearch: false
    });

    constructor() {}

    ngOnInit() {
        this.picker.on("emoji", (selection) => {
            console.log("emoji:", selection);
            this.sendEmoji.emit(selection.emoji);
        });
    }

    showEmojiPicker() {
        const target = document.getElementById("emoji-trigger" + this.channelId);
        this.picker.togglePicker(target);
        // this.addClass();
        console.log("emoji picker open", target);
    }

    // addClass() {
    //     let wrapper = document.querySelector('.emoji-picker__wrapper');
    //     console.log("wrapper", wrapper)
    // }
}
