import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
// import { ApiCallsService } from 'src/app/services/api-calls.service';
import { Subject, Subscription } from 'rxjs';
import { ChannelService } from '../services/channel-service';
import { fadeIn } from '../animations/global-animations';
import { AuthService } from '../core/auth.service';
import { Event as RouterEvent, Router, NavigationEnd } from '@angular/router';
import { consoleLogStyle } from '../shared/varaibles';
import { ChannelUser } from '../shared/interface/channel-user';



@Component({
    selector: 'navigation-overlay',
    templateUrl: './navigation-overlay.component.html',
    styleUrls: ['./navigation-overlay.component.scss'],
    animations: [fadeIn]
})
export class NavigationOverlayComponent implements OnInit, OnDestroy, OnChanges {

    // @Input('currentTheme') currentTheme: string;
    @Input() userStatus: string;
    @Input() userInfo: ChannelUser;
    @Input() isGuest: boolean; // no nav buttons if guest

    // check these 
    @Input() sideBarLocked: boolean;
    @Input() showUnlockedSidebar: boolean;
    @Output() sidebarOverlay = new EventEmitter();

    // ❌ check to be removed ... 
    public showSideContainer: any; // sidebar element 
    public showMessage: any; // sidebar element 
    public showUser: any; // sidebar element 
    // public lockSidebar: boolean = false;
    public displayingContainerView: boolean = false;
    public notificationBadge: boolean = false;
    public displayingMessageView: boolean = false;
    public displayingProfileView: boolean = false;//Not currently being used for anything
    // public showMenu: boolean = false;//Not using right now
    public rightBarNum: number = 0;
    public rightBarType: string = null;
    // 

    public _isSignedIn: boolean = false;
    public messageBadge: boolean = false;
    
    // 🔎 search [💡 search not in use yet] ... 
    // public loadingChannels: boolean = false;
    // public searchResultsIn: boolean = false;
    // public searchChannels = new Subject<string>();
    // public searchChannelVariable: string = '';
    // public channelList: any[] = [];
    // public usersList: any[] = [];
    // public displaySearchInput: boolean = false;
    // 
    public channelId: string; // for join/leave page ? 
    public userStatusTitle: string;

    public currentRoute: string;
    public inChannel: boolean;

    private subscriptions: Subscription[] = [];

    constructor(
        public _authService: AuthService,
        // private _apiCalls: ApiCallsService,
        public _channelService: ChannelService,
        private _router: Router,
        // private _toast: ToastNotificationService,
    ) {
        this.subscriptions.push(this._authService.signInChanged$
            .subscribe(signedIn => {
                this._isSignedIn = signedIn;
            })
        );
        // 💡 search not in use yet ... 
        // this.subscriptions.push(this._apiCalls.getChannelSearch(this.searchChannels)
        //     .subscribe(data => {
        //         this.channelList = [];
        //         this.usersList = [];
        //         if (!Array.isArray(data)) {
        //             this.createChannelList(data);
        //         }
        //         else {
        //             this.usersList = data;
        //             this.searchResultsIn = true;
        //             this.loadingChannels = false;
        //         }
        //         // console.log(data);
        //     })
        // );
        this.subscriptions.push(this._channelService.onJoinedPageEvent$
            .subscribe((channelId: any) => {
                // console.log("JoinedPageEvent: " + channelId);
                this.channelId = channelId;
            })
        );
        this.subscriptions.push(this._channelService.onDirectMessageNotificationEvent$
            .subscribe((showBadge: boolean) => {
                this.messageBadge = showBadge;
            })
        );

        // this.subscriptions.push(this._channelService.onUnreadDirectMessageNotification$
        //     .subscribe(notiObj => {
        //         console.log("navigation overlay unread message badge " + notiObj.numUnreadMessages);
        //         if (notiObj.numUnreadMessages > 0) {
        //             if (!this.showUnlockedSidebar) {
        //                 this.messageBadge = true;
        //             } else if (!this.displayingMessageView) {
        //                 this.messageBadge = true;
        //             }
        //         }
        //     })
        // );

        this.subscriptions.push(this._channelService.onSidebarChangeEvent$
            .subscribe(data => {
                // console.log("onSidebarChangeEvent$", data);
                this.setSidebar(data.num, data.type);
            })
        );

        this.subscriptions.push(this._channelService.onDirectMessageEmitEvent$
            .subscribe(() => {
                // console.log("sidebar triggered dm open --------------------")
                this.rightBarNum = 2;
                this.showSidebar();
            })
        );
        this.subscriptions.push(this._router.events
            .subscribe((event: RouterEvent) => {
                // see also 
                if (event instanceof NavigationEnd) {
                    this.currentRoute = event.url.toLowerCase();
                    this.inChannel = this.currentRoute.startsWith("/channel/") ? true : false;
                    // console.log(event);
                }
            })
        );
    } // end constructor 


    ngOnInit() {
        // console.info("%c Auth UserInfo ", consoleLogStyle, this._authService._userInfo)
        this.showSideContainer = document.getElementById('side-container');
        this.showMessage = document.getElementById('side-message');
        this.showUser = document.getElementById('side-user');
        this.showSideContainer.style.display = 'none';
        this.showMessage.style.display = 'none';
        this.showUser.style.display = 'none';
        this.displayingContainerView = false;
        this.displayingMessageView = false;
        this.displayingProfileView = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userStatus) {
            this.userStatusTitle = this.statusDisplay(changes.userStatus.currentValue);
        }
        if (changes.isGuest) {
            this.showSideContainer = document.getElementById('side-container');
            this.showMessage = document.getElementById('side-message');
            this.showUser = document.getElementById('side-user');
            this.showSideContainer ? this.showSideContainer.style.display = 'none' : null;
            this.showMessage ? this.showMessage.style.display = 'none' : null;
            this.showUser ? this.showUser.style.display = 'none' : null;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // 🔎 search needs re-factor ... not in use ... 
    // createChannelList(userWithChannels:any) {
    //     // console.log(userWithChannels);
    //     this.channelList = userWithChannels.chatRoomChatUsers.concat(userWithChannels.chatRoomChatUser1.filter((item) => userWithChannels.chatRoomChatUsers.indexOf(item) < 0));
    //     this.loadingChannels = false;
    //     this.searchResultsIn = true;
    //     // console.log(this.channelList);
    // }

    // clearSearch() {
    //     this.channelList = [];
    //     this.loadingChannels = false;
    //     this.searchResultsIn = false;
    //     this.searchChannelVariable = '';
    //     (<HTMLInputElement>document.getElementById("channelSearch")).value = "";
    // }

    // quickSearchInUse() {
    //     this.channelList = [];
    //     var searchVal = (<HTMLInputElement>document.getElementById("channelSearch")).value;
    //     if (searchVal !== "") {
    //         this.loadingChannels = true;
    //         this.searchResultsIn = false;
    //     } else {
    //         this.loadingChannels = false;
    //         this.searchResultsIn = false;
    //     }
    // }

    // showSearchInput() {
    //     this.displaySearchInput = (this.displaySearchInput) ? false : true;
    // }
    // 

    // clickLink(link: string) {
    //     this.router.navigate([link]);
    //     // .then(() => {
    //     //     location.reload();// work around for component reload 😭 
    //     // });
    // }

    // sidebar 
    setSidebar(val: number, type?: string) {
        if (val === this.rightBarNum) {
            if (val === 1) {
                if (type === this.rightBarType) {
                    this.rightBarNum = 0;
                } else {
                    this.rightBarNum = val;
                }
            } else {
                this.rightBarNum = 0;
            }
        } else {
            this.rightBarNum = val;
        }
        if (type) {
            this.rightBarType = type;
        }
        this.showSidebar();
    }

    showSidebar() {
        let val = this.rightBarNum;
        // console.log("sidebar val: ", val)
        this.displayingContainerView = false;
        this.displayingMessageView = false;
        this.displayingProfileView = false;
        if (val > 0) {
            // this.sidebarOverlay.emit(true);
            this._channelService.sidebarDisplayEvent(true);
            if (val === 1) {
                this._channelService.sidebarAppSelectEvent(this.rightBarType);
                this.displayingContainerView = true;
                this.showSideContainer.style.display = null;
                this.showUser.style.display = 'none';
                this.showMessage.style.display = 'none';
            } else if (val === 2) {
                this.displayingMessageView = true;
                // this.messageBadge = false;
                this.showMessage.style.display = null;
                this.showUser.style.display = 'none';
                this.showSideContainer.style.display = 'none';
            } else if (val === 3) {
                this.displayingProfileView = true;
                this.showMessage.style.display = 'none';
                this.showUser.style.display = null;
                this.showSideContainer.style.display = 'none';
            }
        } else {
            // this.sidebarOverlay.emit(false);
            this._channelService.sidebarDisplayEvent(false);
        }
        if (!this._channelService._isDesktop) {
            // this.hideMenu();
        }
    }

    hideSideBar() {
        // this.sidebarOverlay.emit(false);
        this._channelService.sidebarDisplayEvent(false);
    }

    signIn() {
        this._authService.signIn();
    }

    signOut() {
        this._authService.signOut();
    }

    leave() {
        if (this.channelId !== null) {
            // console.log("leave navbar: ", this.channelId);
            this._channelService.sendCommand("/leave", this.channelId);
        }
        // this.hideMenu();
    }

    statusDisplay(status: string) {
        if (status === "offline") {
            return "Offline";
        } else if (status === "hidden") {
            return "Invisible";
        } else if (status === "away") {
            return "Away(AFK)";
        } else if (status === "active") {
            return "Active";
        } else if (status === "dnd") {
            return "Do Not Disturb";
        } else if (status === "brb") {
            return "Be Right Back";
        }
    }

    // (hamburger menu) ... showMenu not used in template ... 
    // displayMenu() {
    //     this.showMenu = (this.showMenu) ? false : true;
    // }
    // hideMenu() {
    //     this.showMenu = false;
    // }

    hideOverlay() {
        // this.hideMenu();
        this.setSidebar(0, null);
    }

    closeSideBar(e?:Event) {
        this.setSidebar(0, null);
    }

    // menuRoute(link) {
    //     this.router.navigate([link]);
    //     this.displayMenu();
    // }

    // 👮‍♂️ vdjj icon hov ... 
    changeSVGIconFillEnter(e?: Event) {
        // console.log(e)
        let tar = document.getElementById('fa-hov-i');
        tar.style.fill = "var(--color-secondary-text)";
    }
    changeSVGIconFillLeave(e?: Event) {
        // console.log(e)
        let tar = document.getElementById('fa-hov-i');
        tar.style.fill = "var(--color-primary-icon)";
    }

    guestNavToMarketingSite() {
        let linkToMarketing = document.createElement('a');
        linkToMarketing.setAttribute('href', 'https://www.via3.com');
        linkToMarketing.setAttribute('target', '_blank');
        linkToMarketing.click();
    }

    handleBrokenImage() {
        // console.info('yeeeeee')
        this.userInfo.hash = '1';
    }
}
