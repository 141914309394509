import { Component, OnInit, OnDestroy } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChannelHomepageComponent } from "../channel-homepage/channel-homepage.component";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ChannelService } from "src/app/services/channel-service";
import { AuthService } from "src/app/core/auth.service";
import { ToastNotificationService } from '../../services/toast-notification.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { consoleLogStyle } from '../../shared/varaibles';
import { ChannelUser } from "src/app/shared/interface/channel-user";
import { ChannelFull } from "src/app/shared/interface/channel-full";
import { ScreenCheck } from "src/app/shared/functions";
import { Constants } from "src/app/constants";



@Component({
    selector: 'channel-primary',
    templateUrl: './channel-primary.component.html',
    styleUrls: ['./channel-primary.component.scss'],
})

export class ChannelPrimaryComponent implements OnInit, OnDestroy {
    // ❌ unused ... 
    // public creatorId: string; // channel creator id 
    // public socketServers: any = []; //
    // public phoneModeBreak: number = 640; //tablet-small
    // public showSettings: boolean = false;
    // ❌💡 these should be handled in the <manage-users component now ... 
    // public membersList: any = [];
    // public inCallList: any = [];
    // public iframe: any; ❌ unused
    // ❌💡 these should be handled in the <manage-users component now ... 
    // public searchUsersList: any = [];
    // public inviteUsersList: any = [];
    // public userSearchBar: any;
    // public isSendingTennantInvite: boolean = false;
    // public displayInviteModal: boolean;
    // public displayInviteOptions: boolean;
    // public displayInviteByEmail: boolean = false;
    // public listItemEmail: string = "";
    // public listEmail: any = [];
    // public isSendingEmailInvite: boolean = false;
    // public displayInviteBySMS: boolean = false;
    // public listItemSMS: string = "";
    // public listSMS: any = [];
    // public isSendingSMSInvite: boolean = false;
    // ❌💡 these should be handled in the <events component now ... 
    // public displayScheduleMeetForm: boolean = false; // If no channel Info is provided 
    // public meetingNotFound: boolean = false;
    // public showCreateMeeting: boolean = false;

    public isDesktop: boolean;
    public isChannelBreak: boolean;

    public userInfo: ChannelUser;
    public channelInfo: ChannelFull = this._channelsHome.channelInfo;

    public channelName: string;
    public channelId: string = null;
    public channelContainerName: string;

    public showVid: boolean = false;
    public showSide: boolean = true;

    public ownersList: any = [];
    public isOwner: boolean = false;
    public isCreator: boolean = false;
    public inVideo: boolean = false;

    public showChat: boolean = true;
    public showUsers: boolean = false;
    public showFiles: boolean = false;
    public showMobileVideo: boolean = false;
    public showMobileEvents: boolean = false;

    // permissions set from channel home ... 
    public allowFiles: boolean = false; // 💡 is ALWAYS true from channels home ? ... 
    // public allowInviteGuest: boolean = false; ❌ unused
    // public allowUserManager: boolean = false; ❌ unused
    public jitsiPreventList: any[] = [];

    // public showPage: boolean = false; ❌ unused
    public hasAccess: boolean = false; // set to true on join event sub ...
    public username: any = ""; // = this._authService._user.profile.preferred_username 

    public activeTab: number = 1;

    // new quick meeting vars 
    public isQuickMeeting: boolean; // channelInfo.type === 'quick';
    public isDirectMessage: boolean // channelInfo.type === 'direct';
    public meetingId: string;
    public meetingTitle: string;
    public isMeetingCreator: boolean;
    public isGuest: boolean;
    public isViewer: boolean; // NDIS - Streaming
    public userId: string; // only used for checking if is meeting creator 
    public meetingAttendees: any[] = [];
    
    // new meeting not ready vars ... 
    public meetingStart: any;
    public meetingNotStarted: boolean = false;
    public meetingEnd: any;
    public meetingEnded: boolean = false; // if quick meeting has ended ... 
    public meetingCanceled: boolean = false;
    public accountSiteRegister: string = `${Constants.accountSite}register`;
    public timeRemaining: any;
    public meetingStartForPipe: any;

    // if guest 
    public displayGuestModal: boolean;
    public isSettingGuestName: boolean;
    public newGuestName: FormGroup;
    public changeDisplayName: any = {
        "op": "replace",
        "path": "/displayName",
        "value": ""
    };

    // for the notification in video page if on a browser that doesn't offer users all video options
    // public isSafariOrOtherBrowser: boolean; // could use angular-mat Platform cdk ... 

    private subscriptions: Subscription[] = [];

    constructor(
        private _channelsHome: ChannelHomepageComponent,
        private _channelService: ChannelService,
        private _apiCalls: ApiCallsService,
        private _authService: AuthService,
        public _toastService: ToastNotificationService,
        private formBuilder: FormBuilder,
        private _meetingService: MeetingService,
        private router: Router
    ) {
        if (this.channelInfo) {
            this.channelContainerName = this.channelInfo.blobContainerId;
            this.channelName = this.channelInfo.name;
            this.channelId = this.channelInfo.channelId;
            this.username = this._authService._userInfo.preferred_username;
            this.isViewer = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'viewer');
            // makes files default tab if viewer
            if (this.isViewer) {
                this.toggleFiles();
            }
            this.userInfo = this._channelsHome.userInfo;
            this.allowFiles = this._channelsHome.permissions.allowFiles;
            // this.allowInviteGuest = this._channelsHome.permissions.allowInviteGuest;
            // this.allowUserManager = this._channelsHome.permissions.allowUserManager;
            this.jitsiPreventList = this._channelsHome.permissions.jitsiPreventList;
            // this.channelInitialize();
            // new 
            this.userId = this._authService._userInfo.sub
            this.isGuest = this._channelsHome.userInfo.isGuest;
            this.isQuickMeeting = this.channelInfo.type === 'quick';
            this.isDirectMessage = this.channelInfo.type === 'direct';
            // console.log("this user id? :::", this.userId)
            // console.log("is guest in primary channel ? :::", this.isGuest)
            // console.log("this.isQuickmeeting:", this.isQuickMeeting)
            
            // 🚧🚧🚧 new meeting hasnt started/ meeting has ended start ... 
            
            // if guest can enter meeting 15min early (modal closes at that time) cannot close modal early ...
            // if user show hasnt started modal but can close it ...
            
            if (this.isQuickMeeting) {
                this._meetingService.getQuickMeetingByChannelId(this.channelId)
                    .subscribe(res => {
                        if (res.status === 200) {
                            let meeting = res.body[0];
                            // console.info("%c MEETING JOINED??", consoleLogStyle, meeting)
                            let meetingCreatorId = meeting.chatUserId;
                            this.meetingTitle = meeting.title;
                            this.meetingId = meeting.meetingId;
                            this.meetingStart = meeting.start + 'Z';
                            this.meetingEnd = meeting.end + 'Z';
                            if (this.userId === meetingCreatorId) {
                                this.isMeetingCreator = true;
                            }
                            
                            let startDateNow = new Date();
                            startDateNow.setMinutes(startDateNow.getMinutes() + 15);
                            let startDateNowRes = startDateNow.toISOString();
                            
                            let endDateNow = new Date().toISOString();
                            
                            
                            // console.log("%c quick meeting ::: ", consoleLogStyle, meeting, startDateNowRes )
                            // console.log("%c quick meeting not started ??? ", consoleLogStyle, this.meetingStart > startDateNowRes )
                            // console.log("%c quick meeting ended ??? ", consoleLogStyle, this.meetingEnd < endDateNow )
                            
                            if(meeting.isCanceled) {
                                this.meetingCanceled = true;
                                this.meetingEnded = true;
                            }
                            else if (Date.parse(this.meetingStart) > Date.parse(startDateNowRes) && !this.isMeetingCreator) {
                                // console.info("meeting hasnt started yet...................")
                                this.meetingNotStarted = true;
                                if(this.isGuest) {
                                    this.meetingNotStartedTimer();
                                }
                            }
                            else if (Date.parse(this.meetingEnd) < Date.parse(endDateNow) && !this.isMeetingCreator) {
                                // console.info("meeting has ended...................")
                                this.meetingEnded = true;
                            } else {
                                this.getMeetingAttendees();
                                // console.log("meeting id chan pri >", this.meetingId)
                                if(this.isGuest) {
                                    this.createGuestNameForm(); // guest name form init ... 
                                } else {
                                    this.displayGuestModal = false;
                                }
                                if (this.isMeetingCreator) {
                                    // console.log("is creator, show invite?", (this.isMeetingCreator));
                                    this.toggleUsers();
                                }
                            }
                        } else {
                            console.error("get meetings error?", res.status);
                        }
                    });
            }
        } else {
            console.log("No channel info in constructor of primary channel. this is bad.", this.channelInfo);
        }

        this.subscriptions.push(this._channelService.onJoinEvent$.subscribe(() => {
            this.hasAccess = true;
        }));

        this.subscriptions.push(this._channelService.onJoinNotification$.subscribe((joinObj: any) => {
            if (joinObj.showJoinNotification == true) {
                if (this.channelId.toLowerCase() === joinObj.channelId.toLowerCase()) {
                    this._toastService.enter(joinObj.displayname);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            if (userObj.showLeaveNotification == true) {
                this._toastService.leave(userObj.displayname);
            }
        }));

        this.subscriptions.push(this._channelService.onUpdateMeetingListEvent$.subscribe(() => {
            this.getMeetingAttendees();
        }));

        this.subscriptions.push(this._authService.userChange$.subscribe(() => {
            this.username = this._authService._userInfo.preferred_username;
            this.isViewer = this._authService._userInfo?.role.some(role => role.toLowerCase() === 'viewer');
            this.userId = this._authService._userInfo.sub
        }));
    }

    ngOnInit() {
        this.desktopCheck();
        this.ownerCheck();
        if (!this.isChannelBreak) {
            this.toggleMobileVideo();
        }
        // this.isSafariOrOtherBrowser = /msie\s|trident\/|safari\//i.test(window.navigator.userAgent);
        if (!this.channelInfo) {
            // console.info("%c no channel info on init channel primary ?", this.channelInfo)
            // this.displayScheduleMeetForm = true;
        } else {
            // console.log("Channel info? :::", this.channelInfo)
            // this.channelPermissionsCheck(true); fix
            if (!this.isViewer && !this.isGuest) this.channelInitialize();
            // if (this.isQuickMeeting && this.isGuest && this.userInfo.displayName === "Guest User" && !this.meetingEnded && !this.meetingNotStarted) {
            //     this.createGuestNameForm(); // guest name form init ... 
            // } else {
            //     this.displayGuestModal = false;
            // }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        return this._channelService.leave(this.channelId);
    }

    channelInitialize() {
        return this._channelService.join(this.channelId);
    }

    // ❔ just for logging ??? 
    getParticipantsInCall(participants:any):void { 
        // console.info("%c participents in call? ", consoleLogStyle, participants) 
    }

    videoStarted() {
        this.inVideo = true;
        // if (!this.isChannelBreak) {
        //     // this.hideSide();
        // }
    }

    videoEnded() {
        this.inVideo = false;
        if (this.showMobileEvents && this.isChannelBreak) {
            this.toggleChat();
        }
        this.displaySide();
    }

    showVideo() {
        this.showVid = true;
    }

    hideVideo() {
        this.showVid = false;
    }

    toggleSide() {
        this.showSide = this.showSide ? false : true;
    }

    hideSide() {
        this.showSide = false;
    }

    displaySide() {
        this.showSide = true;
    }

    toggleChat() {
        this.showUsers = false;
        this.showFiles = false
        this.showMobileVideo = false;
        this.showMobileEvents = false;
        this.showChat = true;
        this.setActiveTab(1);
    }

    toggleUsers() {
        this.showChat = false;
        this.showFiles = false;
        this.showMobileVideo = false;
        this.showMobileEvents = false;
        this.showUsers = true;
        this.setActiveTab(2);
    }

    toggleFiles() {
        this.showUsers = false;
        this.showChat = false;
        this.showMobileVideo = false;
        this.showMobileEvents = false;
        this.showFiles = true;
        this.setActiveTab(3);
    }

    toggleMobileVideo() {
        this.showUsers = false;
        this.showChat = false;
        this.showFiles = false;
        this.showMobileEvents = false;
        this.showMobileVideo = true;
        this.setActiveTab(4);
    }

    toggleMobileEvents() {
        this.showUsers = false;
        this.showChat = false;
        this.showFiles = false;
        this.showMobileVideo = false;
        this.showMobileEvents = true;
        this.setActiveTab(5);
    }

    setActiveTab(tabNumberToSet: number) {
        this.activeTab = tabNumberToSet;
        // console.log("active tab set", tabNumberToSet)
    }

    onChannelUpdate() {
        this._getChannel();
        // this.showSettings = false;
    }

    _getChannel() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe((data) => {
                if (data.status === 200) {
                    this.channelInfo = data.body;
                    // this.showPage = true;
                    // this.ownerCheck();
                    console.log("Guest: " + this.userInfo.isGuest);
                } else {
                    console.error(data.status);
                }
        });
    }

    // 💡 for file storage permissions ... 
    ownerCheck() {
        this.channelInfo.chatRoomChatUsers.forEach((user) => {
            this.ownersList.push(user.chatUserKeyNavigation);
        });
        // console.log(this.ownersList);
        if (
            this.ownersList.some(
                (listUser) =>
                    this._authService._userInfo.sub ===
                    listUser.channelUserId
            )
        ) {
            this.isOwner = true;
        }
    }

    getMeetingAttendees() {
        // console.log("%c get meeting attendees params? ", consoleLogStyle, this.meetingId, this.userInfo.channelUserId)
        this._meetingService.getMeetingAttendeesToEditByMeetingId(this.meetingId, this.userInfo.channelUserId)
            .subscribe(res => {
                if (res.status === 200) {
                    this.meetingAttendees = res.body.meetingAttendees; // only attendees, to get creator just res.body is needed...
                    // console.log("%c meeting attendees channel? ", consoleLogStyle, this.meetingAttendees)
                } else {
                    console.error("error getting meeting attendees?", res)
                }
            });
    }

    // if guest enters ... 
    createGuestNameForm() {
        this.newGuestName = this.formBuilder.group({
            displayName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[a-zA-Z'.-\s]*$/)]]
        });
        this.displayGuestModal = true;
        setTimeout(() => {
            let inel:HTMLElement = <HTMLElement>document.getElementById('guest-name-input');
            if (inel) inel.focus();
        });
    }

    get gn() { return this.newGuestName.controls; }

    setGuestUserNameAndEnterMeeting(displayName: string) {
        this.isSettingGuestName = true;
        this.changeDisplayName.value = displayName;
        this._apiCalls.patchChannelUser(this._authService._userInfo.sub, this.changeDisplayName)
            .subscribe(res => {
                if (res.status === 204) {
                    // console.log("new guest display name set :::", this.changeDisplayName)
                    this.isSettingGuestName = false;
                    this._toastService.success(`Display name set. Hello, ${displayName}!`);
                    this._authService.displayNameBehaviorSubject.next(displayName);
                    this.displayGuestModal = false;
                    this.guestDisplayNameUpdated();
                    this.channelInitialize();
                } else {
                    // console.log("error in setting guest name?", res)
                    this.isSettingGuestName = false;
                    this._toastService.error("Invalid display name.");
                    this.displayGuestModal = true;
                    setTimeout(() => {
                        let inel = document.getElementById('guest-name-input');
                        if (inel) inel.focus();
                    }); // a settimeout with no time ensures this will run after modal is displayed ...
                }
            });
    }

    goToHomepage() {
        this.router.navigate(['']);
    }

    guestDisplayNameUpdated() {
        this._channelService.guestDisplayNameUpdated(this.channelId);
    }

    desktopCheck() {
        //560 is phablet
        this.isDesktop = ScreenCheck(560);
        this.isChannelBreak = ScreenCheck(900); //match $channel-break
        if (this.showMobileVideo && this.isChannelBreak) {
            this.toggleChat();
        } else if (this.showMobileEvents && this.isChannelBreak && !this.inVideo) {
            this.toggleChat();
        }
    }

    meetingNotStartedTimer() {
        // console.log("%c start the count down!", consoleLogStyle, this.meetingStart, this.meetingEnd)

        // this is for the 'timeAgo' pipe (15 min before meeting countdown pipe) ... 
        let startMeetMod = new Date(this.meetingStart);
        startMeetMod.setMinutes(startMeetMod.getMinutes() - 15); // countdown ends 15min before meeting start ... 
        this.meetingStartForPipe = startMeetMod;

        // this is for the setTimeout() that will close this modal 15min before meeting start ... 
        let startDateNow = new Date();
        startDateNow.setMinutes(startDateNow.getMinutes() + 15);
        let startDateNowRes = startDateNow.toISOString();
        this.timeRemaining = Date.parse(this.meetingStart) - Date.parse(startDateNowRes);

        // console.info("%c time remaining num|string", consoleLogStyle, this.timeRemaining, this.meetingStartForPipe)
        // open set name modal 15min before meeting starts - 
        setTimeout(() => {
            this._toastService.success("🎉 You can now enter the meeting.");
            this.meetingNotStarted = false;
            this.createGuestNameForm(); // guest name form init ... 
        }, this.timeRemaining);
    }

    closeMeetingEnded() {
        this.meetingEnded = false;
        this.getMeetingAttendees();
    }

    closeMeetingNotStarted() {
        this.meetingNotStarted = false;
    }
}




    // ❌ ... remove after testing ... 
    // new quick meeting 
    // channelPermissionsCheck(init: boolean) {
    //     if (init) {
    //         // ❌💡 these should be handled in the <manage-users component now ...
    //         // this.ownerCheck();
    //     } else {
    //         // this.meetingNotFound = false;
    //         this.router.navigate(['/channel', this.channelId]);
    //     }
    //     if (!this.isViewer) {
    //         this.channelInitialize();
    //     }
    // }

    // beginChat() {
    //     // this.showVideo();
    //     this._channelService.onShowBeginCallPrompt(this.channelId);
    // }

    // overlayDisplay(num: number) {
    //     if (num === 1) {
    //         // this.showCreateMeeting = false;
    //         this.toggleSettings();
    //     } else {
    //         this.showSettings = false;
    //         // this.toggleCreateMeeting();
    //     }
    // }

    // meetingCreated() {
    //     this.toggleCreateMeeting();
    //     // alert("Meeting Created");
    // }

    // toggleSettings() {
    //     this.showSettings = this.showSettings ? false : true;
    // }

    // toggleCreateMeeting() {
    //     this.showCreateMeeting = this.showCreateMeeting ? false : true;
    // }

    // ❌💡 these should be handled in the <events component now ... 
    // CALLED ON SCHEDULE MEETING, submit output emit from schedule-meeting 
    // _getChannelScheduledMeet(channelId:string) {
    //     this._apiCalls.getChannel(channelId)
    //         .subscribe(data => {
    //             // console.log("get channel data :::", data)
    //             if (data.status === 200) {
    //                 if (data.body.type.toLowerCase() === 'quick') {
    //                     this.channelInfo = data.body;
    //                     this.channelId = this.channelInfo.channelId;
    //                     this.channelPermissionsCheck(false);
    //                 } else {
    //                     this.meetingNotFound = true;
    //                     console.log("meeting not found? :::", data.status)
    //                 }
    //             } else {
    //                 this.meetingNotFound = true;
    //                 console.error("meeting not found? :::", data.status);
    //             }
    //         });
    // }

    // ❌💡 these should be handled in the <manage-users component now ... 
    // guest invite START // 

    // showInviteModal() {
    //     // console.log("show invite modal")
    //     this.displayInviteModal = true;
    //     this.displayInviteOptions = true;
    // }

    // closeInviteModal() {
    //     // this.searchUsersList = [];
    //     this.displayInviteModal = false;
    //     this.displayInviteByEmail = false;
    //     this.displayInviteBySMS = false;
    //     this.displayInviteOptions = false;
    // }

    // backToInviteOptions() {
    //     this.displayInviteByEmail = false;
    //     this.displayInviteBySMS = false;
    //     this.displayInviteOptions = true;
    // }

    // showEmailForm() {
    //     this.displayInviteOptions = false;
    //     this.displayInviteByEmail = true;
    // }

    // showSMSForm() {
    //     this.displayInviteOptions = false;
    //     this.displayInviteBySMS = true;
    // }

    // EMAIL INVITE FORM //                                       
    // addEmailToList() {
    //     if (!this.listEmail.includes(this.listItemEmail)) {
    //         this.listEmail.push(this.listItemEmail);
    //         this.listItemEmail = '';
    //     } else {
    //         this.listItemEmail = '';
    //     }
    // }

    // removeEmailFromList(i: number) {
    //     this.listEmail.splice(i, 1);
    // }

    // sends it !!!
    // inviteGuestByEmail() {
    //     console.log("sending email invites ::", this.listEmail)
    //     if (this.listEmail.length > 0) {
    //         this._addMeetingAttendeesEmail();
    //     }
    // }

    // _addMeetingAttendeesEmail() {
    //     this.isSendingEmailInvite = true;
    //     this.listEmail.forEach((email: string) => {
    //         let attendee = {
    //             "email": email,
    //             "meetingId": this.meetingId
    //         };
    //         this._meetingService.postGuestMeetingAttendee(attendee)
    //             .subscribe(res => {
    //                 if (res.status === 201) {
    //                     console.log("Attendee Added EMAIL :::", res.body);
    //                     let invite = {
    //                         "inviteEmail": email,
    //                         "meetingId": this.meetingId
    //                     }
    //                     this._meetingService.sendInviteToken(invite)
    //                         .subscribe(res => {
    //                             if (res.status === 200) {
    //                                 console.log("Token sent EMAIL ::", res)
    //                                 this.isSendingEmailInvite = false;
    //                                 this.closeInviteModal();
    //                                 this.getMeetingAttendees();
    //                                 this._toastService.success(`Email invite was sent to ${email}.`);
    //                                 this.listEmail = [];
    //                             } else {
    //                                 console.log("error :::", JSON.stringify(res));
    //                                 this.isSendingEmailInvite = false;
    //                                 this._toastService.error(`There was an error sending your email to ${email}.`);
    //                                 this.listEmail = [];
    //                             }
    //                         })
    //                 } else {
    //                     console.log("error :::", JSON.stringify(res));
    //                     this.isSendingEmailInvite = false;
    //                     this._toastService.error(`There was an error sending your email to ${email}.`);
    //                     this.listEmail = [];
    //                 }
    //             });
    //     });
    // }

    // SMS INVITE FORM //                                     
    // addSMSToList() {
    //     if (!this.listSMS.includes(this.listItemSMS)) {
    //         this.listSMS.push(this.listItemSMS);
    //         this.listItemSMS = '';
    //     } else {
    //         this.listItemSMS = '';
    //     }
    // }

    // removeSMSFromList(i: number) {
    //     this.listSMS.splice(i, 1);
    // }

    // sends it !!!
    // inviteGuestBySMS() {
    //     console.log("sending SMS invite ::", this.listSMS)
    //     if (this.listSMS.length > 0) {
    //         this._addMeetingAttendeesSMS();
    //     }
    // }

    // _addMeetingAttendeesSMS() {
    //     this.isSendingSMSInvite = true;
    //     this.listSMS.forEach((sms: string) => {
    //         let attendee = {
    //             "phoneNumber": sms,
    //             "meetingId": this.meetingId
    //         };
    //         this._meetingService.postGuestMeetingAttendee(attendee)
    //             .subscribe(res => {
    //                 if (res.status === 201) {
    //                     console.log("Attendee Added SMS :::", res.body)
    //                     let invite = {
    //                         "inviteEmail": res.body.email,
    //                         "meetingId": this.meetingId
    //                     }
    //                     this._meetingService.sendInviteToken(invite)
    //                         .subscribe(res => {
    //                             if (res.status === 200) {
    //                                 console.log("Token sent SMS ::", res)
    //                                 this.isSendingSMSInvite = false;
    //                                 this.closeInviteModal();
    //                                 this.getMeetingAttendees();
    //                                 this._toastService.success(`SMS invite was sent to ${sms}.`);
    //                                 this.listSMS = [];
    //                             } else {
    //                                 console.log("error :::", JSON.stringify(res));
    //                                 this.isSendingSMSInvite = false;
    //                                 this._toastService.error(`There was an error sending your invite to ${sms}.`);
    //                                 this.listSMS = [];
    //                             }
    //                         })
    //                 } else {
    //                     console.log("error :::", JSON.stringify(res));
    //                     this.isSendingSMSInvite = false;
    //                     this._toastService.error(`There was an error sending your invite to ${sms}.`);
    //                     this.listSMS = [];
    //                 }
    //             });
    //     });
    // }

    // ❌💡 these should be handled in the <manage-users component now ... 
    // SEARCH FOR USERS IN TENNANT TO MEET (no pagination in api)
    // _getUsersBySearchQuery() {
    //     this.userSearchBar = document.getElementById('user-search-create-meeting');
    //     let searchString = this.userSearchBar.value.replace(/\s/g, '');
    //     if (searchString.length < 1) {
    //         this.searchUsersList = [];
    //     } else {
    //         this._apiCalls.getChannelUsersBySearch Query&tenant(tenant,searchString)
    //             .subscribe(data => {
    //                 if (data.status === 200) {
    //                     let searchList = data.body;
    //                     this.searchUsersList = [];
    //                     searchList.forEach((searchUser, index) => {
    //                         if (this._authService._user.profile.sub !== searchUser.channelUserId) {
    //                             if (!this.inviteUsersList.some(listUser => // not already in invite list 
    //                                 searchUser.channelUserId === listUser.channelUserId)
    //                                 && !this.meetingAttendees.some(attendant => // not already in current attendees list 
    //                                     searchUser.channelUserId === attendant.chatUserKeyNavigation.channelUserId)) {
    //                                 this.searchUsersList.push(searchList[index]);
    //                             }
    //                         }
    //                     });
    //                     // this.searchUsersList = data.body;
    //                     // this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
    //                 } else {
    //                     console.error(JSON.stringify(data));
    //                 }
    //             });
    //     }
    // }

    // clearSearch() {
    //     console.log("clear search???")
    //     this.userSearchBar = document.getElementById('user-search-create-meeting');
    //     this.userSearchBar.value = '';
    //     this.searchUsersList = [];
    // }

    // addUserToList(i: number) {
    //     // this.userSearchBar = document.getElementById('user-search-create-meeting');
    //     this.inviteUsersList.push(this.searchUsersList[i]);
    //     // console.log("invited users list >",this.inviteUsersList)
    //     // this.clearSearch();
    //     // this.userSearchBar.value = '';
    //     // this.searchUsersList = [];
    // }

    // removeUserFromList(i: number) {
    //     this.inviteUsersList.splice(i, 1);
    // }

    // ADD USERS IN TENNANT TO MEET 
    // _addMeetingAttendees() {
    //     // this.clearSearch();
    //     this.isSendingTennantInvite = true;
    //     this.inviteUsersList.forEach(user => {
    //         let attendee = {
    //             "channelUserId": user.channelUserId,
    //             "meetingId": this.meetingId
    //         };
    //         console.log("Attendee Added from in tennant start")
    //         this._meetingService.postMeetingAttendee(attendee)
    //             .subscribe(res => {
    //                 if (res.status === 201) {
    //                     console.log("Attendee Added from in tennant RESULT?", res)
    //                     this.isSendingTennantInvite = false;
    //                     this.closeInviteModal();
    //                     this.getMeetingAttendees();
    //                     this._toastService.success(`User invite sent to ${user.displayName}.`);
    //                     this.inviteUsersList = [];
    //                 } else {
    //                     console.error(JSON.stringify(res));
    //                     this._toastService.error(`There was an error sending your invite to ${user.displayName}.`);
    //                     this.isSendingTennantInvite = false;
    //                     this.inviteUsersList = [];
    //                 }
    //                 console.log("Attendee Added from in tennant RESULT???", res)
    //             });
    //     });
    // }


// test() {
//     this._toastService.continue('cool')
//     if (this._toastService.continue('cool')) {
//         alert("yes");
//     } else {      
//         alert("no");
//     }
// }


// ❌ unused ...

    // ❌💡 these should be handled in the <manage-users component now ...
    // channelUsers() {
    //     this.ownersList = [];
    //     this.channelInfo.chatRoomChatUsers.forEach(user => {
    //         if (user.chatUserKeyNavigation.channelUserId === this.channelInfo.channelUserId) {
    //             this.ownersList.unshift(user.chatUserKeyNavigation)
    //             if (this.userInfo.channelUserId === this.channelInfo.channelUserId) {
    //                 this.isCreator = true;
    //             }
    //         } else {
    //             this.ownersList.push(user.chatUserKeyNavigation)
    //         }
    //         if (user.chatUserKeyNavigation.channelUserId === this.userInfo.channelUserId) {
    //             this.isOwner = true;
    //         }
    //     });
    //     this.membersList = [];
    //     this.channelInfo.chatRoomChatUser1.forEach(user => {
    //         this.membersList.push(user.chatUserKeyNavigation)
    //     });
    // }

    // _getActiveSocketServers(): Promise<any> {
    //     return this._socketService
    //         .getActiveSocketServers()
    //         .toPromise()
    //         .then((data) => {
    //             // console.log("Getactivesocketservers quick meeting" + JSON.stringify(data.body));
    //             this.socketServers = data.body;
    //         })
    //         .catch((err) => console.log(err));
    // }

