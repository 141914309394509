import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
// import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ChannelService } from 'src/app/services/channel-service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { consoleLogStyle } from 'src/app/shared/varaibles';
import { Constants } from '../../constants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-message-compose',
    templateUrl: './message-compose.component.html',
    styleUrls: ['./message-compose.component.scss']
})
export class MessageComposeComponent implements OnInit, OnChanges, OnDestroy {
    @Input('composeUserId') composeUserId: string;

    public showPage: boolean = false;
    public channelId: string;

    public messageList = [];
    public messageListLoaded: boolean = false;
    public userSearchBar: any = {};
    public searchUsersList = [];
    public searchUsersPag = {};
    public composeList = [];
    public isSearching: boolean = false;
    public isGroup: boolean = false;
    public groupName: FormGroup;

    public username: string = this._authService._userInfo.preferred_username;
    public composerTenant: string = this._authService._tenant.tenantName;

    public _isSignedIn: boolean = false;
    public userCheck: any = {
        initial: false,
        loaded: false
    }

    private subscriptions: Subscription[] = [];

    // for holding the serch string 
    private searchSubject = new Subject<string>();

    constructor(
        private _chManageService: ProjectChannelService,
        private _toastService: ToastNotificationService,
        private _channelService: ChannelService,
        private _apiCalls: ApiCallsService,
        private _authService: AuthService,
    ) {
        
        this.subscriptions.push(
            this._authService.userChange$.subscribe(user => {
                this.username = this._authService._userInfo.preferred_username;
                this.composerTenant = this._authService._tenant.tenantName;
                this._getDirectMessageList();
            })
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        // this._toastService.info("changes");
        if (changes.composeUserId) {
            let userId = changes.composeUserId.currentValue;
            if (userId) {
                this.clearSearch()
                if (this.groupName) {
                    this.groupName.reset();
                }
                this._populateDmPerson(userId);
            }
        }
    }

    ngOnInit(): void {
        if (!this.composeUserId) {
            this._getDirectMessageList();
            this.groupName = new FormGroup({
                name: new FormControl('', [
                    Validators.maxLength(56),
                    Validators.pattern(/^[A-Za-z0-9_-\s]*$/)
                ]),
            });
        }
        // console.info("%c composer tenant? ", consoleLogStyle, this.composerTenant)
        this.showPage = true;

        //creates the search subject for the search debounce
        //delays calling the search query until the user paused for the time allotted (400ms here)
        this.searchSubject
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(searchQuery => {
              console.log("Search Query Passed: "+ searchQuery); 
              this._getUsersBySearchQuery();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    triggerSearch(event: any) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery);
    }

    get nameControl() { return this.groupName.get('name'); }

    _populateDmPerson(dmPersonId) {
        this._apiCalls.getChannelUserWithChatUserId(dmPersonId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (this._authService._user.profile.sub !== data.body.channelUserId) {
                        this.composeList = [];
                        this.composeList.push(data.body);
                        this.setChannel();
                    }
                } else {
                    // console.log(data)
                    this._toastService.error("Direct Message not found")
                }
            });
    }

    _getDirectMessageList() {
        this._chManageService.getDirectMessageChannels(this._authService._user.profile.sub)
            .subscribe(data => {
                if (data.status === 200) {
                    let channels = data.body;
                    let userList = [];
                    channels.forEach(channel => {
                        const userNum = channel.chatRoomChatUsers.length;
                        if (userNum >= 2) {
                            userList.push(channel);
                        }
                    });

                    this.messageList = userList;
                    // console.log("%c Direct Messages List from Message Compose", consoleLogStyle, this.messageList);
                    this.messageListLoaded = true;
                } else {
                    console.error("Direct Message List", data);
                }
            });
    }

    _getUsersBySearchQuery() {
        this.userSearchBar = document.getElementById('user-search-compose');
        let searchString = this.userSearchBar.value.replace(/\s/g, '');
        console.log("message compose search query called called");
        if (searchString.length < 1) {
            this.searchUsersList = [];
            this.isSearching = false;
        } else {
            this.isSearching = true;
            
            this._apiCalls.getChannelUsersByTenantAndSearchQuery(this.composerTenant, searchString)
                .subscribe(data => {
                    if (data.status === 200) {
                        let searchList = data.body;
                        let newList = [];
                        searchList.forEach(searchUser => {
                            if (this._authService._user.profile.sub !== searchUser.channelUserId) {
                                if (this.composeList.some(composeUser => composeUser.channelUserId === searchUser.channelUserId)) {
                                    return;
                                } else {
                                    newList.push(searchUser);
                                }
                            }
                        });
                        this.searchUsersList = newList;
                        // console.log(this.searchUsersList);
                        // this.searchUsersList = searchList;
                        this.searchUsersPag = JSON.parse(data.headers.get('X-Pagination'));
                    } else {
                        // console.log(data);
                        this._toastService.error("An error occurred while searching for users");
                    }
                });
        }
    }

    clearSearch() {
        this.userSearchBar = document.getElementById('user-search-compose');
        if (this.userSearchBar) {
            this.userSearchBar.value = '';
        }
        this.searchUsersList = [];
        this.isSearching = false;
    }

    searchCheckLength() {
        let search: any = document.getElementById('user-search-compose');

        if (search) {
            if (search.value.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    addUserToComposeList(i) {
        // this.composeList = [] //temp For Single
        if (this.composeList.length < 8) {
            this.composeList.push(this.searchUsersList[i]);
        }
        if (this.composeList.length > 1) {
            this.isGroup = true;
        } else {
            this.isGroup = false;
        }
        this.clearSearch();
    }

    removeUserFromComposeList(i) {
        this.composeList.splice(i, 1);
        if (this.composeList.length > 1) {
            this.isGroup = true;
        } else {
            this.isGroup = false;
        }
    }

    channelCheckSolo() {
        if (this.dmUserCompare()) {
            // alert("channel found");
            this.startMessageToChannel(false);
        } else {
            // alert("channel not found");
            this.setChannel();
        }
    }

    channelCheckMulti() {
        this.composeList.forEach(composeUser => {
            if (true) {
                return;
            } else {
                // newList.push(searchUser);
            }
        });
    }

    channelGroup() {
        // if (this.groupName.match(/^(?!\s*$)[A-Za-z0-9_-\s]*$/)) {
        if (this.groupName.value.name.length < 1) {
            this.setChannel();
        } else if (this.groupName.value.name.match(/^[^\s][A-Za-z0-9_-\s]*$/)) {
            this.setChannel();
        } else {
            alert("Invalid Group Name");
        }
    }

    dmUserCompare() {
        return this.composeList.some((composeUser) => {
            let tempChannelId = "";
            if (this.messageList.some((messageItem) => {
                if (messageItem.chatRoomChatUsers.length === 2) {
                    tempChannelId = messageItem.channelId;
                    return messageItem.chatRoomChatUsers.some(
                        (messageUser) => {
                            return (
                                messageUser.chatUserKeyNavigation.username.toLowerCase() === composeUser.username.toLowerCase()
                            );
                        }
                    );
                } else {
                    return false;
                }
            })
            ) {
                this.channelId = tempChannelId;
                return true;
            } else {
                return false;
            }
        });
    }

    setChannel() {
        let newChannel = {
            teamProjectId: Constants.directMessageProjId,
            channelUserId: this._authService._user.profile.sub,
            name: "",
            type: "direct",
            videoType: "jitsi",
            topic: null,
            inviteCode: null,
            welcome: null,
            deleteRule: 0,
            deleteInterval: 0,
            scope: "Owner",
            private: true,
            closed: false,
            inVideoCall: false,
            inScreenShare: false,
        };

        if (this.isGroup && this.groupName.value.name.length > 0) {
            newChannel.name = this.groupName.value.name;

        }
        // alert(JSON.stringify(newChannel));
        // alert(newChannel.name);
        this._postChannel(newChannel);
    }

    _postChannel(newChannel) {
        this._apiCalls.postChannel(newChannel)
            .subscribe(res => {
                if (res.status === 201) {
                    let newChannel = res.body;
                    this.channelId = newChannel.channelId;
                    this._addMultipleOwners();
                } else {
                    // console.error("Post Channel", res);
                    this._toastService.error("Failed to compose message");
                }
            });
    }

    _activateDmChannel(channelId) {
        let chatRoomsChannelUsersObject =
        {
            "channelId": channelId,
            "channelUserId": this._authService._user.profile.sub,
            "isActive": true
        };

        this._apiCalls.putChatRoomsChannelUsers(chatRoomsChannelUsersObject)
            .subscribe(res => {
                if (res.status === 204) {

                    this._getDirectMessageList();
                } else {
                    console.error("Post Channel", res);

                }
            });
    }

    _addMultipleOwners() {
        let ownersList = [];
        this.composeList.forEach(user => {
            ownersList.push({
                "channelUserId": user.channelUserId,
                "channelId": this.channelId
            });
        });
        this._apiCalls.postBatchChannelOwners(ownersList)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("Owners Added");
                    this.startMessageToChannel(true);
                } else if (res.status === 409) {
                    // console.log("Owners Already Exists");
                    this.startMessageToChannel(true);
                } else {
                    // console.error("Post multiple Owners", res);
                    this._toastService.error("Failed to compose message");
                }
            });
    }

    public startMessageToChannel(newChannel: boolean): void {
        this._channelService.updateDirectMessageList(
            this.channelId,
            newChannel
        );
        let channelName: string;
        this._activateDmChannel(this.channelId);
        if (this.composeList.length > 1 && this.groupName.value.name.length > 1) {
            channelName = this.groupName.value.name;
        } else if (this.composeList.length == 1) {
            channelName = this.composeList[0].displayName
        } else if (this.composeList.length == 2) {
            channelName = `${this.composeList[0].displayName} & ${this.composeList[1].displayName}`
        } else {
            channelName = "";
            this.composeList.forEach((user, i) => {
                if (i == this.composeList.length - 2) {
                    channelName += user.displayName + " & "
                } else if (i < this.composeList.length - 1) {
                    channelName += user.displayName + ", ";
                } else {
                    channelName += user.displayName;
                }
            });
        }
        this._channelService.redirectDirectMessage(this.channelId, channelName);
    }

}
