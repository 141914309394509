import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChannelService } from '../services/channel-service';
import { ChannelUser } from '../shared/interface/channel-user';



@Component({
    selector: 'sidebar-overlay',
    templateUrl: './sidebar-overlay.component.html',
    styleUrls: ['./sidebar-overlay.component.scss']
})
export class SidebarOverlayComponent implements OnChanges {
    @Input() appType: string;
    @Input() userInfo: ChannelUser;
    // @Input() pinnedSideBar: boolean;
    @Input() sideBarInView: boolean;

    public showSidebar: boolean;//destroys component after time delay

    public appObject: any = {
        title: null,
        icon: null
    };

    constructor(
        public _channelService: ChannelService
    ) { 
        this.showSidebar = this.sideBarInView;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appType) {
            let type = changes.appType.currentValue;
            if (type === 'apps') {
                this.appObject = {
                    title: "My Apps",
                    icon: "fas fa-th-large",
                };
            } else if (type === 'alerts') {
                this.appObject = {
                    title: "Alerts",
                    icon: "fas fa-bell",
                };
            } else if (type === 'channels') {
                this.appObject = {
                    title: "Channels",
                    icon: "fas fa-tv-retro",
                    redirectLink: "/channels"
                };
            } else if (type === 'contacts') {
                this.appObject = {
                    title: "My Contacts",
                    icon: "fa-address-book",
                };
            } else if (type === 'events') {
                this.appObject = {
                    title: "My Events",
                    icon: "fas fa-calendar-alt",
                };
            } else if (type === 'faxes') {
                this.appObject = {
                    title: "Faxes",
                    icon: "fas fa-fax",
                    redirectLink: "/fax"
                };
            } else if (type === 'files') {
                this.appObject = {
                    title: "My File Storage",
                    icon: "fas fa-folder-open",
                    redirectLink: "/filestorage"
                };
            } else {
                this.appObject = {
                    title: null,
                    icon: null,
                };
            }
        }

        if (changes.sideBarInView) {
            if (!changes.sideBarInView.currentValue) {
                // setTimeout(() => {
                    this.showSidebar = false;
                // }, 400);//Was 5000
            } else {
                this.showSidebar = true;
            }
        }
    }

    closeSideBar() {
        this._channelService.sidebarChangeEvent(1, this.appType);
    }

    // pinToggle() {
    //     if (this.pinnedSideBar) {
    //         this._channelService.sidebarPinEvent(false);
    //     } else {
    //         this._channelService.sidebarPinEvent(true);
    //     }
    // }
}
