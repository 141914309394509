import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiCallsService } from "src/app/services/api-calls.service";
import { ModalService } from "src/app/services/modal.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { ChannelUser } from "../../interface/channel-user";
import { Project } from "../../interface/project";
import { consoleLogStyle } from '../../varaibles';



@Component({
    selector: "create-channel",
    templateUrl: "./create-channel.component.html",
    styleUrls: ["./create-channel.component.scss"],
})
export class CreateChannelComponent implements OnInit, OnDestroy {

    @Input("userInfo") userInfo: ChannelUser;

    // @Output('formTouchedEvent') formTouchedEvent = new EventEmitter();
    // @Output('onCompleteEvent') onCompleteEvent = new EventEmitter();


    public newChannel: FormGroup;
    public isFormTouched: boolean = false;

    public isLoadingProjects: boolean;
    public projects: Project[] = [];
    public hasNoProjects: boolean;

    public isCreatingChannel: boolean = false;

    public isDropdownOpen: boolean = false;

    private subscriptions: Subscription[] = [];

    // new create project intergration ...
    public newProject: FormGroup;
    public showCreateProject: boolean = false;
    // public isCreatingProject: boolean = false;


    constructor(
        private router: Router,
        private fb: FormBuilder, 
        private _apiCallsService: ApiCallsService, 
        private _toastService: ToastNotificationService,
        private _modalService: ModalService,
    ) {
    }

    ngOnInit(): void {
        this.isLoadingProjects = true;
        this.createForm();
        this.getProjectsByTenant();
        // console.log("%c create chan modal? ", consoleLogStyle, this.userInfo)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getProjectsByTenant() {
        this._apiCallsService.getAllProjects(this.userInfo.tenant)
            .subscribe(res => {
                if(res.status === 200) {
                    this.projects = res.body;
                    // this.projects = []; // testing no projects ... 
                    if(this.projects.length === 0)  {
                        this.isLoadingProjects = false;
                        this.hasNoProjects = true;
                        this.displayCreateProject();
                    } else {
                        this.isLoadingProjects = false;
                    }
                    // console.log("projects> ? ", this.projects)
                } else {
                    console.error("error getting projects")
                    this.isLoadingProjects = false;
                }
            });
    }

    createForm() {
        this.newChannel = this.fb.group({
            channelProject: ["", [Validators.required]],
            channelName: ["", [Validators.required, Validators.minLength(2)]], // 200 max  set on input.. 
            channelTopic: ["", []], // 50 max  set on input.. 
            channelWelcomeMessage: ["", []], // 300 max  set on input.. 
        });
        this.subscriptions.push(this.newChannel.valueChanges.subscribe(data => {
            // console.log('Form changes', data);
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                // this.formTouchedEvent.emit();
                this._modalService.modalFormTouchedSubject.next();
                // console.log('Form Touched Emiited', this.isFormTouched);
            }
        }));
    }

    get newChannelControls() {
        return this.newChannel.controls;
    }

    // post channel 
    createChannel(newProjectId?: string, newProject?: Project) {
        this.isCreatingChannel = true;
        let channel = {
            "teamProjectId": newProjectId ? newProjectId : this.newChannel.value.channelProject,
            "channelUserId": this.userInfo.channelUserId,
            "name": this.newChannel.value.channelName,
            "type": "legacy",
            "videoType": "jitsi",
            "topic": this.newChannel.value.channelTopic,
            "welcome": this.newChannel.value.channelWelcomeMessage,
            "deleteRule": 0,
            "deleteInterval": 0,
            "scope": "Tenant",
            "private": true
        };
        this._apiCallsService.postChannel(channel)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("%c channel created", consoleLogStyle, res.body);
                    let newChannelMade = res.body;
                    this.isCreatingChannel = false;
                    // this.onCompleteEvent.emit();
                    this._modalService.modalFormCompleteSubject.next();
                    // if staying on this page can push new channel to project then push to front 
                    // this.onCompleteEvent.emit(newChannelMade); // pushes to project[] ... 
                    this.router.navigate(['/channel', newChannelMade.channelId]);
                } else {
                    // console.log("error creating channel")
                    this.isCreatingChannel = false;
                    this._toastService.error("There was an error creating new channel.");
                }
            });
    }

    // post Shared Folder "Channel"
    createSharedFolderChannel(newProjectId?: string, newProject?: Project) {
        this.isCreatingChannel = true;
        let channel = {
            "teamProjectId": newProjectId ? newProjectId : this.newChannel.value.channelProject,
            "channelUserId": this.userInfo.channelUserId,
            "name": this.newChannel.value.channelName,
            "type": "folder",
            "videoType": "jitsi",
            "topic": this.newChannel.value.channelTopic,
            "welcome": this.newChannel.value.channelWelcomeMessage,
            "deleteRule": 0,
            "deleteInterval": 0,
            "scope": "Tenant",
            "private": true
        };
        this._apiCallsService.postChannel(channel)
            .subscribe(res => {
                if (res.status === 201) {
                    // console.log("%c channel created", consoleLogStyle, res.body);
                    let newChannelMade = res.body;
                    this.isCreatingChannel = false;
                    // this.onCompleteEvent.emit();
                    this._modalService.modalFormCompleteSubject.next();
                    // if staying on this page can push new channel to project then push to front 
                    // this.onCompleteEvent.emit(newChannelMade); // pushes to project[] ... 
                    // this.router.navigate(['/channel', newChannelMade.channelId]);
                } else {
                    // console.log("error creating channel")
                    this.isCreatingChannel = false;
                    this._toastService.error("There was an error creating new channel.");
                }
            });
    }

    prevDef(e: Event) {
        // console.log('oi')
        e.preventDefault();
    }
    stopProp(e: Event) {
        e.stopPropagation();
    }

    // 🚧 ... so can use enter to pick selection from dropdown without triggering the enter submit ... 
    public handleDropdownEnterSubmitLock(isOpen:boolean, dropdownId: string) {
        // console.log(isOpen)
        if(isOpen) {
            this.isDropdownOpen = isOpen;
        } else {
            let el = <HTMLElement>document.getElementById(dropdownId);
            if(!!el) {
                el.blur();
            }
            this.isDropdownOpen = isOpen;
        }
    }

    // new create project ...
    displayCreateProject() {
        if(!this.showCreateProject) {
            this.newChannel.removeControl('channelProject');
            this.newProject = this.fb.group({
                projectName: ["", [Validators.required, Validators.minLength(2)]], // 200max set on input...
            });
            this.subscriptions.push(this.newProject.valueChanges.subscribe(data => {
                // console.log('Form changes', data);
                if(!this.isFormTouched) {
                    this.isFormTouched = true;
                    // this.formTouchedEvent.emit();
                    this._modalService.modalFormTouchedSubject.next();
                    // console.log('Form Touched Emiited', this.isFormTouched);
                }
            }));
            this.showCreateProject = true;
        } else {
            // this.newProject.reset();
            this.newChannel.addControl('channelProject', new FormControl('', Validators.required))
            this.showCreateProject = false;
        }
    }

    get newProjectControls() {
        return this.newProject.controls;
    }

    createProject() {
        this.isCreatingChannel = true;
        let newProj = {
            "channelUserId": this.userInfo.channelUserId,
            "name": this.newProject.value.projectName,
        };
        this._apiCallsService.postProject(newProj)
            .subscribe(res => {
                // 
                if (res.status === 201) {
                    console.log("%c project created", consoleLogStyle, res.body);
                    let newChannelMade: Project = res.body;
                    this.createChannel(newChannelMade.teamProjectId, newChannelMade);
                } else {
                    // console.log("error creating project")
                    this.isCreatingChannel = false;
                    this._toastService.error("There was an error creating new project.");
                }
            });
    }

    createChannelStart() {
        if(this.showCreateProject) {
            this.createProject();
        } else {
            this.createChannel();
        }
    }
}
