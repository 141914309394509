import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ChannelService } from '../../../services/channel-service';
// import { Constants } from '../../../constants';
import { Subscription } from 'rxjs';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
// import { ApiCallsService } from '../../../services/api-calls.service';
// import { AuthService } from 'src/app/core/auth.service';
// import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';



@Component({
    selector: 'app-live-streaming-video',
    templateUrl: './live-streaming-video.component.html',
    styleUrls: ['./live-streaming-video.component.scss']
})
export class LiveStreamingVideoComponent implements OnInit {
    @Input() channelId: string;
    @Input() channelInfo;
    @Input() userName: string;
    @Input() forMedicalEMT: boolean;
    @Input() preventList: string[]; //Features To be Prevented
    @Input() mainChannel: boolean;
    @Output() endCallEvent = new EventEmitter();
    @Output() startCallEvent = new EventEmitter();
    @Output() openChatEvent = new EventEmitter();
    @Output() openUsersEvent = new EventEmitter();
    @Output() openFilesEvent = new EventEmitter();
    @Output() participantsListOut = new EventEmitter();

    public apiObj: any = null;
    public dispName: string = null;
    public roomName: string = ""; //meeting_id
    public callerId: string = null; //users participant id
    public jitsiToken: string = "";

    public showJoinModal: boolean = false;
    public showSettingsModal: boolean = false;
    public showMoreOptionsModal: boolean = false;
    public showKickParticipantsModal: boolean = false;
    public joingCall: boolean = false;

    public inVideo: boolean = true;
    public isRecording: boolean = false;
    public isStreaming: boolean = false;
    public disableRecording: boolean = false;
    public disableStreaming: boolean = false;
    public isFullScreen: boolean = false;
    public inCall: boolean = false;
    public handsUp: boolean = false;
    public isModerator: boolean = false;
    public participantsInCall: any = [];

    public devicePreferences: any = {};
    public devicesReady: any = false;
    public videoInputs: any = [];
    public audioInputs: any = [];
    public audioOutputs: any = [];
    public currentVideoInput: any = null;
    public currentAudioInput: any = null;
    public currentAudioOutput: any = null;

    //Feature List
    public hideStartVideo: boolean = false;
    public featureList: any[] = [
        {
            name: "screenshare",
            id: "btnScreenShareCustom",
            icon: "fas fa-desktop",
            title: "screen share",
            method: () => {
                this.screenShareCustom()
            }
        },
        {
            name: "raisehand",
            id: "raiseHand",
            icon: "fas fa-hand-paper",
            title: "raise hand",
            method: () => {
                this.setHand()
            }
        },
        {
            name: "tileview",
            id: "btnCustomTileView",
            icon: "fas fa-th",
            title: "tile view",
            method: () => {
                this.customTileView()
            }
        },
        {
            name: "fullscreen",
            id: "btnSetFullScreen",
            icon: "fas fa-expand",
            title: "full screen",
            method: () => {
                this.setFullScreen()
            }
        },
        {
            name: "settings",
            id: "btnCustomSettings",
            icon: "fas fa-cog",
            title: "video settings",
            method: () => {
                this.showSettings()
            }
        }
    ];

    //for forMedicalEMT
    public altHeight: boolean = false;

    public callId: any = null;
    public initiatorClientId = null;
    public initiatorConnectionId: any = null;
    public initiatorUserId: any = null;

    //for handling out site wide sidebar display
    public sidebarShowing: boolean;
    // public sidebarPinned: boolean;

    // Live Feed NDIA
    public loadLiveFeed: boolean;
    private subscriptions: Subscription[] = [];

    constructor(
        private _toastService: ToastNotificationService,
        public _channelService: ChannelService,
        private _appComp: AppComponent,
        // private _authService: AuthService,
        // private _apiCallsService: ApiCallsService,
        // private _router: Router
    ) {
        if (!this.forMedicalEMT) {
            this.forMedicalEMT = false;
        }

        //inital set for sidebar
        this.sidebarShowing = this._appComp.showSidebar;
        // this.sidebarPinned = this._appComp.pinnedSideBar;

        this.subscriptions.push(this._channelService.onJoinedPageEvent$
            .subscribe((channelId: any) => {
                console.log("JoinedPageEvent channelID: " + channelId);
                if (this.mainChannel) {
                    this.channelId = channelId;
                    // this.getJitsiToken(); // NDIA
                }
            })
        );

        this.subscriptions.push(this._channelService.onSelectPoliceOfficerEvent$
            .subscribe((channelId: any) => {
                console.log("JoinedPageEvent channelID: " + channelId);
                this.channelId = channelId;
                // this.getJitsiToken(); // NDIA
            })
        );

        this.subscriptions.push(this._channelService.onJitsiCallCreatedEvent$.subscribe((callId: any) => {
            //console.log("jitsicallcreated sub");
            // console.log("callchannelevent sub");
            this.callId = callId;
            // this.startUp();
        }));

        this.subscriptions.push(this._channelService.onJitsiCallPromptEvent$.subscribe((callObj: any) => {
            console.warn("jitsicallprompt sub");
            // this.channelId = callObj.channelId;
            // this.roomName = callObj.channelId;
            this.initiatorClientId = callObj.initiatorClientId;
            this.initiatorConnectionId = callObj.initiatorConnectionId;
            this.initiatorUserId = callObj.initiatorUserId;

            // if (this.forMedicalEMT) {
            if (this.hideStartVideo) {
                if (false === this.inCall) {
                    this.callId = callObj.callId;
                    this.startUpHelper(false);
                }
            } else {
                //this.showJoinModal = true;
            }
        }));


        this.subscriptions.push(this._channelService.onJitsiToggleRecordingEvent$.subscribe((disableRecording: any) => {
            if (disableRecording === "true" || disableRecording === true) {
                this.isRecording = true;
                this.disableRecording = true;
            } else {
                this.isRecording = false;
                this.disableRecording = false;
            }
            console.log("isRecording: " + this.isRecording);
            console.log("disableRecording: " + this.disableRecording);
        }));

        this.subscriptions.push(this._channelService.onJitsiSelfJoinEvent$.subscribe((joinChannelId: string) => {
            if (joinChannelId === this.channelId) {
                this.setPreference(true);
            }
        }));

        this.subscriptions.push(this._channelService.onSidebarDisplayEvent$.subscribe(show => {
            this.sidebarShowing = show;
        }));

        // this.subscriptions.push(this._channelService.onSidebarPinEvent$.subscribe(isPinned => {
        //     this.sidebarPinned = isPinned;
        // }));
    }

    ngOnInit() {
        console.log("CHANNEL INFO:" + this.channelInfo);
        if (!this.userName) {
            this.dispName = "New User";
        } else {
            this.dispName = this.userName;
        }

        console.log("USER NAME On Init: " + this.dispName);

        // this.getJitsiToken(); // NDIA

        // console.log("ngOnIt going to call getPreferences")
        // this.getPreferences();
        if (this.preventList) {
            // alert(this.preventList);
            this.preventList.forEach(item => {
                if (item === 'startVideo') {
                    this.hideStartVideo = true;
                } else if (item === 'record') {
                    this.featureList = this.featureList.filter(feature => feature.name != "record")
                } else if (item === 'chat') {
                    this.featureList = this.featureList.filter(feature => feature.name != "chat")
                } else if (item === 'users') {
                    this.featureList = this.featureList.filter(feature => feature.name != "users")
                } else if (item === 'files') {
                    this.featureList = this.featureList.filter(feature => feature.name != "files")
                }
            });
        }
        this.featureList.forEach((item, i) => {
            if (i === 0) {
                item.switch = () => {
                    return true;
                };
            } else if (i === 1) {
                item.switch = () => {
                    return this.jitsiCheck(400);
                };
            } else if (i === 2) {
                item.switch = () => {
                    return this.jitsiCheck(450);
                };
            } else if (i === 3) {
                item.switch = () => {
                    return this.jitsiCheck(500);
                };
            } else if (i === 4) {
                item.switch = () => {
                    return this.jitsiCheck(550);
                };
            } else {
                item.switch = () => {
                    return false;
                };
            }
        });

    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    startUpHelper(isCaller: any) {
        console.log("startup helper isCaller: " + isCaller);
        if (!this.channelId) {
            this._toastService.error("Failed to join this call");
            console.log('meeting id is missing');
            this.joingCall = false;
        } else {
            let joinMsg: any = document.querySelector('#joinMsg');
            let btnStartup: any = document.querySelector("#btnStartup");
            joinMsg.innerHTML = "Loading Event...";
            if (btnStartup) {
                btnStartup.style.display = 'none';
            }
            this.roomName = this.channelId;
            this.loadLiveFeed = true; // NDIA
            this.inVideo = true; // NDIA
            this.devicesReady = true; //NDIA

            console.log("CHANNEL AT STARTUP: " + this.channelId);
            this.showJoinModal = false;
            this.startMeeting(this.roomName, this.dispName, this.jitsiToken);
        }
    }

    hangUp() {
        this.loadLiveFeed = false // NDIA
        this.inCall = false;
        this.isModerator = false;
        let toolbox: any = document.querySelector('#toolbox');
        let btnStartup: any = document.querySelector("#btnStartup");
        this.hideStartVideo = false;
        let joinMsg: any = document.querySelector('#joinMsg');
        joinMsg.innerHTML = "";
        if (!this.hideStartVideo) {
            this.altHeight = false;
        }
        if (btnStartup) {
            btnStartup.style.display = 'inline-block';
        }
        if (toolbox) {
            toolbox.style.display = 'none';
        }
        this.inVideo = false;
        // console.log("leaving live event")
        this.endCallEvent.emit();
        if (this.isFullScreen) {
            this.closeFulllScreen();
        }
        if (this.hideStartVideo) {
            console.log("haning up emt");
            this.altHeight = false;
        }
    }

    hangUpHelper() {
        if (!this.channelId) {
            this._toastService.error("Failed to leave this call");
            console.log('meeting id is missing');
        } else {
            this.roomName = this.channelId;
        }
        this.hangUp();
        // this._channelService.JitsiHangUpUser(this.callId, this.roomName);
    }

    declineCall() {
        this.showJoinModal = false;
        this.updateLastActivity();
    }

    customMic() {
        // this.apiObj.executeCommand('toggleAudio');
        // this.updateLastActivity();
    }

    customCamera() {
        // this.apiObj.executeCommand('toggleVideo');
        // this.updateLastActivity();
    }

    customTileView() {
       // this.apiObj.executeCommand('toggleTileView');
        // this.updateLastActivity();
    }

    screenShareCustom() {
        // this.apiObj.executeCommand('toggleShareScreen');
        // this.updateLastActivity();
    }

    // Remove for NDIA livestream
    setVideoInput() {
       
    }

    // Remove for NDIA livestream 
    setAudioInput() {
    
    }

    // Remove for NDIA livstream
    setAudioOutput() {
        
    }

    startStream() {
        this.isStreaming = true;
        this.apiObj.executeCommand('startRecording', { mode: 'stream', rtmpStreamKey: 123 })
    }

    stopStream() {
        this.isStreaming = false;
        this.apiObj.executeCommand('stopRecording', 'stream')
    }

    showSettings() {
        // this.getDevices();
        this.showSettingsModal = true;
        this.updateLastActivity();
    }

    hideSettings() {
        this.showSettingsModal = false;
        this.updateLastActivity();
    }

    hideSettingsFromModal(event) {
        let target = event.target || event.srcElement || event.currentTarget;
        let value = null;
        if (target.attributes.id) {
            let idAttr = target.attributes.id;
            value = idAttr.nodeValue;
        }

        if (value == "video-setting-modal") {
            this.showSettingsModal = false;
        }
        this.updateLastActivity();
    }

    showMoreOptions() {
        this.showMoreOptionsModal = true;
        this.updateLastActivity();
    }

    hideMoreOptions() {
        this.showMoreOptionsModal = false;
        this.updateLastActivity();
    }

    displayMoreOptions() {
        this.showMoreOptionsModal = this.showMoreOptionsModal ? false : true;
        this.updateLastActivity();
    }

    setDevices() {
        this.showSettingsModal = false;
    }

    muteEveryone() {
        this.apiObj.executeCommand('muteEveryone');
        this.updateLastActivity();
    }

    getParticipants() {
       // removed for NDIA
    }

    kickParticipant(participantId: string) {
        console.log(participantId);
        this.apiObj.executeCommand('kickParticipant', participantId);
        this.updateLastActivity();
    }

    showKickParticipants() {
        this.getParticipants()
        this.showKickParticipantsModal = true;
    }

    hideKickParticipants() {
        this.showKickParticipantsModal = false;
    }

    hideKickParticipantsFromModal(event) {
        let target = event.target || event.srcElement || event.currentTarget;
        let value = null;
        if (target.attributes.id) {
            let idAttr = target.attributes.id;
            value = idAttr.nodeValue;
        }

        if (value == "participants-modal") {
            this.showKickParticipantsModal = false;
        }
    }

    setFullScreen() {
        let elem: any = document.querySelector("#video-frame");
        if (this.isFullScreen) {
            this.closeFulllScreen();
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                this.isFullScreen = true;
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
                this.isFullScreen = true;
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
                this.isFullScreen = true;
            } else {
                this._toastService.error("Your browser doesn't support this feature.");
                this.isFullScreen = false;
            }
            this.featureList.find(item => {
                if (item.name === "fullscreen") {
                    if (this.isFullScreen) {
                        item.icon = "fas fa-compress";
                        item.title = "exit full screen"
                    } else {
                        item.icon = "fas fa-expand";
                        item.title = "full screen"
                    }
                    return true;
                }
            });
        }
        this.updateLastActivity();
    }

    closeFulllScreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            this.isFullScreen = false;
        } else if (document['webkitExitFullscreen']) { /* Safari */
            document['webkitExitFullscreen']();
            this.isFullScreen = false;
        } else if (document['msExitFullscreen']) { /* IE11 */
            document['msExitFullscreen']();
            this.isFullScreen = false;
        } else {
            this._toastService.error("Your browser doesn't support this feature.");
            this.isFullScreen = false;
        }

        this.featureList.find(item => {
            if (item.name === "fullscreen") {
                item.icon = "fas fa-expand";
                item.title = "full screen"
                return true;
            }
        });
    }

    setHand() {
        this.apiObj.executeCommand('toggleRaiseHand');
        // this.handsUp = this.handsUp ? false : true;
        // this.featureList.find(item => {
        //     if (item.name === "raisehand") {
        //         if (this.handsUp) {
        //             item.class = "activated";
        //         } else {
        //             item.class = "";
        //         }
        //         return true;
        //     }
        // });
        this.updateLastActivity();
    }

    openChat() {
        if (this.isFullScreen) {
            this.closeFulllScreen();
        }
        this.openChatEvent.emit();
        this.updateLastActivity();
    }

    openUsers() {
        if (this.isFullScreen) {
            this.closeFulllScreen();
        }
        this.openUsersEvent.emit();
        this.updateLastActivity();
    }

    openFiles() {
        if (this.isFullScreen) {
            this.closeFulllScreen();
        }
        this.openFilesEvent.emit();
        this.updateLastActivity();
    }

    startMeeting(roomName: string, dispName: string, token: string) {
        //let videoMuted = this.devicePreferences.jitsi.startWithVideoMuted; // NDIA
        // let audioMuted = this.devicePreferences.jitsi.startWithAudioMuted; // NDIA
        this.startCallEvent.emit();
        this.inVideo = true;
        this.joingCall = false;
        let joinMsg: any = document.querySelector('#joinMsg');
        joinMsg.innerHTML = "";
      
        if (this.hideStartVideo) {
            this.altHeight = true;
        }
    }

    getPreferences() {
        console.log("getPreferences called");
        // this.devicePreferences = JSON.parse(localStorage.getItem("devicePreferences"));
        // console.log("devicePreferences: ");
        // console.log(this.devicePreferences);
        if (this.devicePreferences === null) {
            this.devicePreferences = {
                "videoId": "default",
                "audioId": "default",
                "videoRes": 480,
                "jitsi": {
                    "startWithVideoMuted": true,
                    "startWithAudioMuted": true
                }
            };
            // localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        } else if (!this.devicePreferences.jitsi) {
            this.devicePreferences.jitsi = {
                "startWithVideoMuted": true,
                "startWithAudioMuted": true
            }
           //  localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        }
        this.devicesReady = true;
    }

    setPreference(isCaller: any) {
        console.log("SetPreferences Called.  IsCaller:" + isCaller);
        this.joingCall = true;
        this.startUpHelper(isCaller);

        localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
    }

    checkDevicePermissions() {
        // Removed for NDIA
    }

    getDevices() {
        let videoInputs = [];
        let audioInputs = [];
        let audioOutputs = [];

        let foundVideoInput = false;
        let foundAudioInput = false;
        let foundAudioOutput = false;

        // let selecteAudioInput = this.currentAudioInput;
        // let selecteVideoInput = this.currentVideoInput;
        // let selecteAudioOutput = this.currentAudioOutput;
    }

    getParticipantById(id) {
        return this.participantsInCall.find(user => user.participantId === id);
    }

    screenCheck(mediaWidth: number) {
        let mediaQuery = window.matchMedia(`(max-width: ${mediaWidth - 1}px)`)
        if (mediaQuery.matches) {
            return false;
        } else {
            return true;
        }
    }

    jitsiCheck(mediaWidth: number) {
        let targetWidth: any = document.getElementById("video-frame");
        if (targetWidth) {
            targetWidth = targetWidth.offsetWidth;
            if (targetWidth < mediaWidth) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }


    clickOff() {
        //For On blur
    }

    updateLastActivity() {
        if (this.channelId) {
           // this._channelService.updateLastActivity(this.channelId);
        }
    }
}
