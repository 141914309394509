import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './pagenotfound.component.html' //,
    //styleUrls: ['./pagenotfound.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    constructor() { }

    ngOnInit() {

    }
}
