import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import { IApps } from './apps';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { resolve } from 'q';
import { Subject } from 'rxjs';
import { ChannelService } from './channel-service';


@Injectable()
export class ConnectionManagerService {

    private _iceServers = {};
    private _mediaStream: any;
    private _channelId: any;
    private _connections = {};
    private _acceptingCalleeDto: any;

    private _offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
    };

    private onStreamAddedEvent = new Subject<any>();

    onStreamAddedEvent$ = this.onStreamAddedEvent.asObservable();

    private onStreamRemovedEvent = new Subject<any>();

    onStreamRemovedEvent$ = this.onStreamAddedEvent.asObservable();


    constructor(
        private _channelService: ChannelService,
        private http: HttpClient) { }


    public initialize(iceServers: any, mediaStream: any, channelId: any) {
        //alert("connection manager service initialize");
        this._iceServers = iceServers;
        this._mediaStream = mediaStream;
        this._channelId = channelId;

    }

    public getIceSettings() {
        var headersConfig = new HttpHeaders();

        return this.http
            .get(`${Constants.apiRoot}icesetting?enabled=true`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    public setConstraints(includeVideo: any, videoDevice: any, height: any, width: any, audioDevice: any) {
        let constraints: any;

        if (true === includeVideo) {
            constraints = {
                audio: {
                    deviceId: audioDevice,
                    googEchoCancellation: true,
                    googAutoGainControl: true,
                    googNoiseSuppression: true,
                    googHighpassFilter: true,
                    googNoiceSuppression2: true,
                    googEchoCancellation2: true,
                    googAutoGainControl2: true

                },
                video: {
                    // deviceId: { exact: videoDevice },
                    deviceId: videoDevice,
                    width: {
                        min: 320,
                        ideal: width,
                        max: 3840
                    },
                    height: {
                        min: 240,
                        ideal: height,
                        max: 2160
                    },
                    frameRate: {
                        min: 15,
                        ideal: 30,
                        max: 60 
                    },
                    aspectRatio: 1.777777778
                }
            };
        } else {
            constraints = {
                audio: true,
                video: false
            };
        }

        return constraints;
    }

    public setVideoConstraints(includeVideo: any, videoDevice: any, height: any, width: any) {
        let constraints: any;

        if (true === includeVideo) {
            constraints = {
                video: {
                    // deviceId: { exact: videoDevice },
                    deviceId: videoDevice,
                    width: {
                        min: 320,
                        ideal: width,
                        max: 3840
                    },
                    height: {
                        min: 240,
                        ideal: height,
                        max: 2160
                    },
                    frameRate: {
                        min: 15,
                        ideal: 30,
                        max: 60 
                    },
                    aspectRatio: 1.777777778
                }
            };
        } else {
            constraints = {
                video: false
            };
        }

        return constraints;
    }

    public setAudioConstraints(audioDevice: any) {
        let constraints = {
            audio: {
                deviceId: audioDevice,
                googEchoCancellation: true,
                googAutoGainControl: true,
                googNoiseSuppression: true,
                googHighpassFilter: true,
                googNoiceSuppression2: true,
                googEchoCancellation2: true,
                googAutoGainControl2: true

            }
        }
        return constraints;
    }


    public createConnection(partnerClientId, securityToken) {
        var pc = new RTCPeerConnection(this._iceServers);

        pc.onicecandidate = (e) => {

            if (e.candidate) {
                console.log("create connection candidate " + JSON.stringify(e.candidate));
                //this._channelService.RTCSendCandidateSignal(partnerClientId, securityToken, JSON.stringify({ "candidate": e.candidate }), this._channelId);
                this._channelService.RTCSendCandidateSignal(partnerClientId, securityToken, JSON.stringify(e.candidate), this._channelId);
            } else {
                console.debug("Ice candidate gathering complete");
            }
        }


        pc.onconnectionstatechange = () => {
            var states = {
                "iceConnectionState": pc.iceConnectionState,
                "iceGatheringState": pc.iceGatheringState,
                "connectionState": pc.connectionState,
                "signalingState": pc.signalingState
            };
            console.debug(JSON.stringify(states));
        }

        pc.ontrack = (event: any) => {
            console.log("create connection ontrack " + JSON.stringify(event));
            var addTrackObj = {
                "pc": pc,
                "event": event,
                "partnerClientId": partnerClientId
            };
            //this.onStreamAddedEvent.next(addTrackObj);
            this._channelService.onStreamAdded(addTrackObj, this._channelId);
        }

        pc.removeTrack = (e) => {
            var removeTrackObj = {
                "pc": pc,
                "partnerClientId": partnerClientId
            };
            this.onStreamRemovedEvent.next(removeTrackObj);
        }

        this._connections[partnerClientId] = pc;

        return pc;
    }

    public newSignal = async (callerClientId, localDescription, securityToken) => {
        console.log("newsignal localdesc: " + localDescription);
        console.log("newsignal callerclientid: " + callerClientId);
        var signal = JSON.parse(localDescription);
        console.log("signal: " + JSON.stringify(signal));
        var connection = this.getConnection(callerClientId, securityToken);

        if (signal.sdp) {
            //alert("signal.sdp");
            this.receivedSdpSignal(connection, callerClientId, signal.sdp, this._mediaStream, securityToken);

        } else {
            this.receivedCandidateSignal1(connection, JSON.stringify({ "candidate": signal.candidate }));
        }
    }

    private receivedSdpSignal = (connection: any, clientId: any, sdp: any, mediaStream: any, securitToken: any) => {
        console.log("receivedSdpSignal");
        connection.setRemoteDescription(new RTCSessionDescription(sdp))
            .then(() => {
                console.log("connection remotedescription type: " + connection.remoteDescription.type);
                if (connection.remoteDescription.type === "offer") {
                    connection.addStream(mediaStream);
                    connection.createAnswer()
                        .then((remoteDescription) => {
                            console.log("answer");
                            connection.setLocalDescription(remoteDescription);
                        })
                        .then(() => {
                            console.log("rtcsendanswer connection manager service");
                            this._channelService.RTCSendAnswerSignal(this._acceptingCalleeDto.clientId,
                                JSON.stringify({ "sdp": connection.localDescription }), securitToken, this._channelId);
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                }
                else if (connection.remoteDescription.type === "answer") {
                    console.debug("Received Answer");

                }
                else {
                    console.error("Unsupported type: " + connection.remoteDescription.type);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public receivedCandidateSignal1 = (connection: any, candidate: any) => {

        console.log("receivedCandidateSignal1 " + candidate);
        connection.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)))
            .catch((e) => {
                console.error(e);
            })
    }

    private getConnection = (clientId: any, securityToken: any) => {
        console.log("getConnection " + clientId);
        var connection = this._connections[clientId] ||
            this.createConnection(clientId, securityToken);

        return connection;
    }

    private closeAllConnections = () => {
        for (var connectionId in this._connections) {
            this.closeConnection(connectionId);
        }
    }

    private closeConnection = (connectionId: any) => {

    }

    public async InitiateOffer(acceptingCalleeDto: any) {
        console.log("acceptingCalleeDto " + acceptingCalleeDto.clientId);
        this._acceptingCalleeDto = acceptingCalleeDto;
        //alert("Initiate Offer calleeClientId: " + acceptingCalleeDto.clientId + " calleeUserId: " + acceptingCalleeDto.calleeUserId);

        if (!this._mediaStream) {
            console.debug("_mediaStream not set: " + JSON.stringify(this._mediaStream));
            return;
        }

        var pc = this.getConnection(acceptingCalleeDto.clientId, null);

        pc.addStream(this._mediaStream);

        try {
            console.log('pc1 createOffer start');
            const offer = await pc.createOffer(this._offerOptions); //instantiates and defines the offer
            await this.onCreateOfferSuccess(offer, pc);
        } catch (e) {
            this.onCreateSessionDescriptionError(e);
        }
    }

    private async onCreateOfferSuccess(desc: any, pc: any) {
        console.log(`Offer from pc1\n${desc.sdp}`);
        console.log('pc1 setLocalDescription start');

        try {
            await pc.setLocalDescription(desc).then(() => {
                this.onSetLocalSuccess(pc);
                console.log("createoffer clientid: " + this._acceptingCalleeDto.clientId);
                this._channelService.RTCSendOfferSignal(this._acceptingCalleeDto.clientId, JSON.stringify({ "sdp": desc }), this._channelId);
            }).catch((e) => {
                console.error("Error: " + e);
            });

        } catch (e) {
            this.onSetSessionDescriptionError(e);
        }
    }

    private onCreateSessionDescriptionError(error) {
        console.log(`Failed to create session description: ${error.toString()}`);
    }

    private onSetLocalSuccess(pc) {
        console.log(`${pc} setLocalDescription complete`);
    }

    private onSetSessionDescriptionError(error) {
        console.log(`Failed to set session description: ${error.toString()}`);
    }
}
