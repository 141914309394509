import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../core/auth.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
    selector: "unauthorized",
    templateUrl: "./unauthorized.component.html",
    styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedComponent implements OnInit {
    @Input("channelAccessMsg") channelAccessMsg: boolean; // ❔❔❔ i do not see this input being passed anywhere ...
    public channelMessage: boolean = false;

    public _isSignedIn: boolean;

    constructor(
        private _authService: AuthService, 
        private _location: Location, 
        private _router: Router
        ) {
            this._authService.isSignedIn(false).then(signedIn => {
                this._isSignedIn = signedIn;
                if(!this._isSignedIn) {
                    this._router.navigate(['/login']);
                }
            });
        }

    ngOnInit() {
        console.log("Channel Message: " + this.channelAccessMsg);
        this.channelMessage = this.channelAccessMsg;
    }

    goBack() {
        this._location.back();
    }

    goHome() {
        this._router.navigate(['/home']);
    }
}
