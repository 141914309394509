<!-- parent is: 'flex-col-center-all home-main-col'  -->
<div class="channels flex-col px-4">
    <strong class="flex org-name">Organization:<div class="text-ellipsis ms-2">{{!!userInfo ? userInfo.tenant : ' '}}</div></strong>
    <div class="title-wrap mb-4">
        <h3 class="title-thin">Recent Channels</h3>
        <button *ngIf="!isViewer"
            (click)="showCreateChannelModal()"
            class="flex-center-all circle"
            matTooltip="create a new channel" 
            matTooltipClass="tooltip-default">
            <i class="fas fa-plus"></i>
        </button>
    </div>
    <!-- channels list -->
    <div 
        [@listAnimationWrapLong]="channelHistory.length" 
        class="channels-wrap flex-col mt-3 scrollbar-new"
        id="channelScrollbox">
        <ng-container *ngFor="let channel of channelHistory; index as i; last as last">
            <!-- channel card -->
            <!-- ❌ pagination removed unill further notice ... -->
            <!-- 
                id="{{(last && channelHistoryPag.nextPageLink) ? 'LastChannel' : ''}}"
                (@listAnimationItemLong.done)="(last && channelHistoryPag.nextPageLink) ? paginationObserver() : ''"
            -->
            <div 
                *ngIf="channel.teamProjectName !== 'Shared Folders'" 
                @listAnimationItemLong class="channel-container mb-4">
                <!-- options dropdown : if owner show [user manager & schedule meet links] -->
                <button 
                    type="button" 
                    (wheel)="prevDefStopProp($event)"
                    (click)="showChannelOptionsDropdown(i)"
                    (mouseleave)="hideChannelOptionsDropdown(i)"
                    class="channel-options-btn pe-1">
                    
                    <i 
                        matTooltip="channel options" 
                        matTooltipClass="tooltip-default"
                        class="fas fa-ellipsis-v lg-icon"></i>
                    <!-- channel options dropdown -->
                    <div id="channel-options-dropdown-{{i}}" class="channel-options-dropdown mt-1 py-2">
                        <ng-container *ngIf="channel.isOwner">
                            <!-- schedule meeting in channel -->
                            <button (click)="showScheduleModal(channel)" class="list-item">
                                <i class="far fa-calendar-alt calendar-icon"></i>Schedule meeting
                            </button>
                            <!-- 🚧 go to user manager/modal ??? -->
                            <button (click)="showManageUsersModal(channel.channelId)" class="list-item">
                                <i class="far fa-address-card"></i>Manage users
                            </button>
                        </ng-container>
                        <!-- channel info -->
                        <button (click)="showChannelInfoModal(channel.channelId)" class="list-item">
                            <i class="far fa-info-circle info-icon"></i>Channel info
                        </button>
                    </div>
                </button>
                <!-- channel card body -->
                <a 
                    class="channel-card flex-col" 
                    [routerLink]="'/channel/' + channel.channelId">
                    
                    <div class="channel-card-top-row flex mb-3">
                        <!-- title -->
                        <div class="channel-title">
                            <div class="flex">
                                <small 
                                    *ngIf="channel.private"
                                    matTooltip="private" 
                                    matTooltipClass="tooltip-default">
                                    
                                    <i class="far fa-lock"></i>&nbsp;
                                </small>
                                <small 
                                    *ngIf="!channel.private"
                                    matTooltip="public" 
                                    matTooltipClass="tooltip-default">
                                    
                                    <i class="far fa-users"></i>&nbsp;
                                </small>
                                <!--  -->
                                <small class="text-ellipsis proj-name">{{channel.teamProjectName}}</small>
                            </div>
                            <h4 class="flex m-0 text-ellipsis">{{channel.channelName}}</h4>
                        </div>
                        <div class="channel-options mt-1 me-2">
                            <!-- 🚧 favorite channel -->
                            <button 
                                matRipple
                                [matRippleColor]="rippleColor"
                                [matRippleUnbounded]="true"
                                [matRippleCentered]="true"
                                class="star-btn circle"
                                (click)="channel.isFavorite ? unFavoriteChannel($event, channel.channelHistoryId, i) : favoriteChannel($event, channel.channelHistoryId, i)">
                                <i 
                                    [class.active]="channel.isFavorite"
                                    class="fa{{channel.isFavorite ? 's':'r'}} fa-star"
                                    matTooltip="{{channel.isFavorite ? 'unfavorite':'make favorite'}}" 
                                    matTooltipClass="tooltip-default"></i>
                            </button>
                        </div>
                    </div>
                    <!-- channel avatars -->
                    <div 
                        class="channel-card-avitars flex mt-2" @listAnimationWrapAvatar>
                        <!-- how many more users | > 7 -->
                        <h4 
                            (click)="prevDefStopProp($event)" 
                            @fadeIn 
                            class="my-auto ms-1" 
                            style="cursor: default;" 
                            *ngIf="channel.allChannelUsersRemainder !== 0" 
                            matTooltip="plus {{channel.allChannelUsersRemainder}} more user{{(channel.allChannelUsersRemainder) > 1 ? 's':''}}" 
                            matTooltipClass="tooltip-default">
                            
                            +{{channel.allChannelUsersRemainder}}
                        </h4>
                        <!-- first avitar is CREATOR && then OWNERS then members W/ #7 max then + left length (ROW is REVERSED [last is first]) -->
                        <!-- pipe takes first 7 from all users & reverses it for display in row-rev -->
                        <ng-container *ngFor="let user of channel.allChannelUsers | channelCardAvatars; index as idx; last as lastImg">
                            <ng-container>
                                <img 
                                    @listAnimationItemAvatar 
                                    *ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
                                    (click)="prevDefStopProp($event)" 
                                    src="https://profile-images.azureedge.us/{{user.hash}}" 
                                    (error)="handleBrokenImage(i, idx)"
                                    class="circle" alt="user" 
                                    [class.first]="lastImg" 
                                    [class.member]="user.rank === 'member'" 
                                    matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                    matTooltipClass="tooltip-default"/>
                                <ng-template #defaultImg>
                                    <div 
                                        @listAnimationItemAvatar 
                                        (click)="prevDefStopProp($event)" 
                                        class="default-user-img circle flex-center-all"
                                        [class.first]="lastImg" 
                                        [class.member]="user.rank === 'member'" 
                                        [class.every-first]="idx % 4 === 0"
                                        [class.every-second]="idx % 4 === 1"
                                        [class.every-third]="idx % 4 === 2"
                                        [class.every-fourth]="idx % 4 === 3"
                                        matTooltip="{{user.displayName|titlecase}}&nbsp;&nbsp;&nbsp;&bull;&nbsp;{{user.rank|titlecase}}" 
                                        matTooltipClass="tooltip-default">
                                        {{user.userName|defaultImageOverlay}}
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                </a>
            </div>
        </ng-container>
        
        <ng-container *ngIf="!isLoadingChannels">
            <button 
                class="outline-button mx-auto mb-2 mt-3" 
                (click)="_channelService.sidebarChangeEvent(0)"
                routerLink="/channels" 
                [queryParams]="{orderBy:'projects'}">All Channels</button>
        </ng-container>
        
        <!-- <div class="loading-more-channels flex justify-center mt-4" *ngIf="isLoadingMoreChannels">
            <i class="fas fa-spinner spinner m-auto"></i>
        </div> -->
        
        <div class="no-channels flex-row-nowrap-center-all mt-4" *ngIf="!isLoadingChannels && !!channelHistory && channelHistory.length < 1">
            <p class="text-center secondary-text">You do not have any channels in history.</p>
        </div>
    </div>
    <!--  -->
    <div class="loading-channels flex-center-all" *ngIf="isLoadingChannels">
        <loading-home></loading-home>
    </div>
</div>

<!-- channel info modal -->
<div
    *ngIf="displayChannelInfoModal"
    class="modal"
    id="channel-info-modal"
    (click)="clickOffChannelModalEvent($event)">
    
    <div 
        class="modal-container"
        (document:keydown.escape)="closeChannelInfoModal()">
        <!-- new close modal ... -->
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeChannelInfoModal()">
            <i class="fas fa-times"></i>
        </button>
        <!-- getting channel info independently now ... -->
        <channel-info
            [channelId]="selectedChannelId">
        </channel-info>
    </div>
</div>

<!-- Manage users modal -->
<div
    *ngIf="displayManageUsersModal"
    class="modal"
    id="manage-users-modal"
    (click)="clickOffChannelModalEvent($event)">
    
    <div 
        class="modal-container"
        (document:keydown.escape)="closeManageUsersModal()">
        <!-- new close modal ... -->
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeManageUsersModal()">
            <i class="fas fa-times"></i>
        </button>
        <!--  -->
        <manage-users
            [channelId]="selectedChannelId"
            [userInfo]="userInfo"
            [isModalVersion]="true">
        </manage-users>
    </div>
</div>

<!-- schedule meeting for channel modal -->
<div
    *ngIf="displayScheduleModal"
    class="modal"
    id="schedule-modal"
    (click)="clickOffChannelModalEvent($event)">
    
    <div 
        class="modal-container"
        (document:keydown.escape)="closeScheduleModalConfirm()">
        <!-- new close modal ... -->
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeScheduleModalConfirm()">
            <i class="fas fa-times"></i>
        </button>
        <schedule-meeting
            [incomingChannelObject]="scheduleChannelMeetingObject"
            (onCompleteEvent)="closeScheduleModal()"
            (formTouchedEvent)="displayScheduleWarning = $event"
        ></schedule-meeting>
    </div>
</div>

<!-- create channel modal -->
<!-- <div
    *ngIf="displayCreateChannelModal"
    class="modal"
    id="create-channel-modal"
    (click)="clickOffChannelModalEvent($event)">
    
    <div 
        class="modal-container"
        (document:keydown.escape)="closeCreateChannelModalConfirm()">
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeCreateChannelModalConfirm()">
            <i class="fas fa-times"></i>
        </button>
        <create-channel
            [userInfo]="userInfo"
            (onCompleteEvent)="closeCreateChannelModal()"
            (formTouchedEvent)="displayCreateChannelWarning = true">
        </create-channel>
    </div>
</div> -->

<!-- 🚧 testing global modal -->
<modal 
    *ngIf="displayCreateChannelModal" 
    (closeModal)="displayCreateChannelModal = false">
    <create-channel
        [userInfo]="userInfo">
    </create-channel>
</modal>