<div class="alert-settings flex-col mx-auto my-4">
    <!-- page title -->
    <div class="title-row">
        <div class="flex channel-title">
            <i class="fas fa-cogs me-3 settings-icon"></i>
            <h3 class="title-thin text-ellipsis">
                Alert Settings
            </h3>
        </div>
    </div>
    <!-- expand/collapse all -->
    <div 
        *ngIf="!isLoadingSettings"
        class="example-action-buttons mb-3 ms-auto">
        <button 
            (click)="accordion.openAll()"
            matRipple
            [matRippleColor]="rippleColor"
            class="mx-3 py-1 px-2">
            Expand All
        </button>
        <button 
            (click)="accordion.closeAll()"
            matRipple
            [matRippleColor]="rippleColor"
            class="mx-3 py-1 px-2">
            Collapse All
        </button>
    </div>
    <!-- each setting -->
    <mat-accordion class="example-headers-align" multi>
        <ng-container *ngFor="let setting of alertSettings; index as settingIdx">
            <mat-expansion-panel class="new-setting">
                <mat-expansion-panel-header>
                    <!-- icon -->
                    <i 
                        class="title-icon"
                        [class.fal]="setting"
                        [class.fa-tv-retro]="setting.alertSource === 'channel'"
                        [class.fa-handshake]="setting.alertSource === 'meeting'"
                        [class.fa-comments-alt]="setting.alertSource === 'direct'"
                        [class.fa-user-plus]="setting.alertSource === 'register'"
                        [class.fa-fax]="setting.alertSource === 'fax'"
                        [class.fa-folder-open]="setting.alertSource === 'file'"
                        [class.fa-comment-alt-smile]="setting.alertSource === 'mention'"
                        ></i>
                    <!-- title -->
                    <mat-panel-title class="new-setting-name">{{setting.alertName}}</mat-panel-title>
                </mat-expansion-panel-header>
                <!-- toggles [4] -->
                <ng-container *ngFor="let type of setting.alertGroup;">
                    <ng-container *ngIf="type.alertTarget !== 3">
                        <div 
                            class="setting-types mb-3"
                            [class.mb-0]="!hasNotifications && type.alertTarget === 2">
                            <mat-slide-toggle 
                                matTooltip="{{ 
                                    !type.isActive ? 'disabled by admin' : 
                                    type.alertTarget === 0 ? 'in platform' : 
                                    type.alertTarget === 1 ? 'email' : 'sms' 
                                }}" 
                                matTooltipClass="tooltip-default" 
                                id="toggle-{{settingIdx}}-{{type.alertTarget}}"
                                [checked]="type.alertValue"
                                [disabled]="!type.isActive"
                                (change)="toggleSettingChange(type.settingId, $event, type.alertTarget)">
                                <ng-container *ngIf="type.alertTarget === 0">
                                    VIA3<i *ngIf="!type.isActive" class="fas fa-lock ms-2"></i>
                                </ng-container>
                                <ng-container *ngIf="type.alertTarget === 1">
                                    Email<i *ngIf="!type.isActive" class="fas fa-lock ms-2"></i>
                                </ng-container>
                                <ng-container *ngIf="type.alertTarget === 2">
                                    SMS<i *ngIf="!type.isActive" class="fas fa-lock ms-2"></i>
                                </ng-container>
                            </mat-slide-toggle>
                        </div>
                    </ng-container>
                    <!-- ios throws error for Notification use ... -->
                    <ng-container *ngIf="!_platform.IOS && !_platform.ANDROID && !_platform.SAFARI">
                        <!-- ✨ remove - setting.alertName === 'When received unread direct messages' -->
                        <!--            when more settings are added -->
                        <ng-container *ngIf="setting.alertName === 'Received unread direct messages'">
                            <!-- ^ x -->
                            <ng-container *ngIf="hasNotifications && type.alertTarget === 3">
                                <div 
                                    class="setting-types mb-0"
                                    [class.per-gran]="permissionGranted">
                                    <mat-slide-toggle 
                                        matTooltip="{{ !permissionGranted ? 'must grant permission' : 'system' }}" 
                                        matTooltipClass="tooltip-default" 
                                        id="toggle-{{settingIdx}}-{{type.alertTarget}}"
                                        [checked]="type.alertValue"
                                        [disabled]="!permissionGranted"
                                        (change)="toggleSettingChange(type.settingId, $event, type.alertTarget)">
                                        System<i *ngIf="!permissionGranted" class="fas fa-lock ms-2"></i>
                                    </mat-slide-toggle>
                                    <button 
                                        *ngIf="!permissionGranted"
                                        (click)="grantSystemPermission()"
                                        matRipple
                                        [matRippleColor]="rippleColor"
                                        class="ms-3 py-1 px-2">
                                        grant system permission
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <!-- loading settings -->
    <ng-container *ngIf="isLoadingSettings">
        <div class="loading-settings flex-center-all">
            <loading-home></loading-home>
        </div>
    </ng-container>
</div>
