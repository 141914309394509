import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { Constants } from '../constants';
import { catchError } from 'rxjs/operators';



export interface SubscriptionPlan {
    activatedAt: string;
    balanceInCents: number;
    chargifyProductHandle: string; // 'starter' | 'pro-plan' ... 
    createdAt: string;
    productId: string;
    status: string;
    subscriptionId: string;
    tenantId: string;
    updatedAt: string;
    defaultCardExists: boolean; // if you have card on file ... 

    canAddUsers?: boolean;
    remainingUsers?: number;
};

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(private httpClient: HttpClient) { }

    // get your sub plan by id ... 
    getSubscriptionById(subscriptionId:string): Observable<HttpResponse<SubscriptionPlan>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}subscription/${subscriptionId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
