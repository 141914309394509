<div class="messagebar" [class.channel-selected]="selectedChannel">
	<div class="message-nav">
		<ng-container *ngIf="!selectedChannel">
			<button
				class="message-nav-btn"
				(click)="closeSideBar()"
				matTooltip="close messages"
				matTooltipClass="tooltip-default"
			>
				<i
					class="fas"
					[ngClass]="{
						'fa-comments-alt': _channelService._isDesktop,
						'fa-arrow-left': !_channelService._isDesktop
					}"
				></i>
			</button>
			<h5
				class="message-nav-title"
				matTooltip="direct messages"
				matTooltipClass="tooltip-default"
			>
				Direct Messages
			</h5>
			<button
				class="flex-center-all compose-button"
				(click)="openComposeNewChat()"
				matTooltip="compose message"
				matTooltipClass="tooltip-default"
			>
				<i class="far fa-comment-alt-plus"></i>
			</button>
		</ng-container>
		<ng-container *ngIf="selectedChannel">
			<button class="message-nav-btn" (click)="closeChat()">
				<i class="fas fa-arrow-left"></i>
			</button>
			<h5
				class="message-nav-title"
				matTooltip="{{ selectedChannel.channelName }}"
				matTooltipClass="tooltip-default"
			>
				{{ selectedChannel.channelName }}
			</h5>
			<!-- <a
				*ngIf="selectedChannel.channelId !== 'composeNewMessage'"
				class="me-1 flex-center-all redirect-side-button"
				[routerLink]="'/channel/' + selectedChannel.channelId"
				(click)="closeSideBar()"
				routerLinkActive="active"
				matTooltip="open this dm in full"
				matTooltipClass="tooltip-default"
			>
				<i class="far fa-chevron-circle-right"></i>
			</a> -->
			<button
				class="me-1 flex-center-all redirect-side-button"
				(click)="callTrigger()"
				matTooltip="open this dm in full"
				matTooltipClass="tooltip-default"
			>
				<i class="far fa-chevron-circle-right"></i>
			</button>
		</ng-container>
	</div>

	<div class="mx-3 my-3 add-new-message" *ngIf="!selectedChannel">
		<div class="mb-0 borderless-input-group">
			<input
				id="searchUserMessages"
				(input)="triggerSearch($event)"
				type="text"
				maxlength="249"
				class="borderless-input"
				placeholder="Search Direct Messages"
				matTooltip="search direct message"
				matTooltipClass="tooltip-default"
			/>
			<button
				*ngIf="!isSearching"
				type="button"
				class="input-button"
				[disabled]="true"
			>
				<i class="fas fa-search"></i>
			</button>
			<button
				*ngIf="isSearching"
				type="button"
				class="input-button"
				(click)="clearFilter()"
			>
				<i class="far fa-times-circle"></i>
			</button>
		</div>
	</div>

	<div
		*ngIf="!selectedChannel"
		[@listAnimationWrap]="messageList.length"
		class="px-2 pt-1 direct-message-list scrollbar-new"
	>
		<div
			*ngFor="let user of messageList; let i = index"
			(click)="openChat(i)"
			class="mb-2 chat-user {{ user.userStatus }}"
			@listAnimationItem
		>
			<div
				class="user-img"
				matTooltip="{{
					user.username !== 'none' && user.username
						? user.username + ' profile picture'
						: user.channelName
				}}"
				matTooltipClass="tooltip-default"
			>
				<ng-container *ngIf="user.username !== 'none'; else group">
					<img
						*ngIf="!!user.hash && user.hash !== '1'; else defaultImg"
						src="https://profile-images.azureedge.us/{{ user.hash }}"
						alt="{{ user.displayName }}'s profile picture"
						(error)="handleBrokenImage(i)"
					/>
					<ng-template #defaultImg>
						<div
							class="default-user-img circle flex-center-all me-2"
							[class.every-first]="i % 4 === 0"
							[class.every-second]="i % 4 === 1"
							[class.every-third]="i % 4 === 2"
							[class.every-fourth]="i % 4 === 3"
						>
							{{ user.username | defaultImageOverlay }}
						</div>
					</ng-template>
				</ng-container>
				<ng-template #group>
					<img src="https://profile-images.azureedge.us/1" alt="group message" />
				</ng-template>
			</div>
			<span
				class="status-bar"
				matTooltip="{{ user.userStatus | statusDisplay: true }}"
				matTooltipClass="tooltip-default"
			></span>
			<div class="chat-names-wrap">
				<p
					class="chat-name"
					matTooltip="{{ user.channelName }}"
					matTooltipClass="tooltip-default"
				>
					{{ user.channelName }}
				</p>
				<!-- <p class="chat-last-message"">
                    {{ user.lastMessage }}
                </p> -->
				<ng-container *ngIf="user.unreadMessages">
					<p class="chat-new-messages">
						Sent <b>{{ user.unreadMessages }}</b
						>{{ user.unreadMessages == 1 ? " new message" : " new messages" }}
					</p>
				</ng-container>
				<ng-container *ngIf="user.lastMessage && !user.unreadMessages">
					<p class="chat-last-message">
						{{ user.lastMessage.content | lastMessage }}
					</p>
				</ng-container>
				<ng-container *ngIf="!user.lastMessage && !user.unreadMessages">
					<p class="chat-last-message">
						Conversation started with {{ user.channelName }}
					</p>
				</ng-container>
			</div>
			<p class="chat-time">
				{{
					user.lastMessage
						? (user.lastMessage.when | timeAgo)
						: (user.dateCreated | timeAgo)
				}}
			</p>
			<!-- <div
                *ngIf="user.unreadMessages !== 0 && user.unreadMessages"
                class="unread-wrap"
            >
                <i
                    class="fa fas-email"
                    mattooltip="{{ user.unreadMessages }} unread messages"
                >
                    <span
                        class="badge-message"
                        [attr.data-messages]="user.unreadMessages"
                    ></span>
                </i>
            </div> -->
			<!-- IF !UNREAD > REMOVE CHAT BTN -->
			<div
				class="archive-btn"
				matTooltip="archive conversation"
				matTooltipClass="tooltip-default"
				(click)="archiveDirectMessage($event, user.channelId, i)"
				*ngIf="user.unreadMessages === 0 && !user.unreadMessages && user.isActive"
			>
				<i class="fas fa-archive"></i>
			</div>
		</div>
	</div>
	<p
		*ngIf="messageList.length < 1 && listLoaded"
		class="no-messages"
		[class.is-searching]="isSearching"
		@fadeInFast
	>
		{{
			isSearching
				? "Could not find Direct Messages"
				: "You have not started any Direct Messages"
		}}
	</p>
	<span
		*ngIf="messageList.length < 1 && !listLoaded"
		@fadeInFast
		class="messages-loading"
	>
		<i class="fas fa-spinner spinner"></i>
		&nbsp;{{ isSearching ? "Searching messages..." : "Loading messages..." }}
	</span>

	<div class="direct-message-select" *ngIf="selectedChannel">
		<app-message-sidebar-selected
			[channelId]="selectedChannel.channelId"
			[composeUserId]="selectedChannel.composeUserId"
			(closeChat)="closeChat()"
		>
		</app-message-sidebar-selected>
	</div>
</div>
