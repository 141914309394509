import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quickmeetingChannelName'
})
export class QuickmeetingChannelNamePipe implements PipeTransform {

  transform(value: string): string {
    if(value === "QuickMeetingApp") {
      return "Quick Meeting";
    } else {
      return value;
    }
  }
}
