<div 
    class="video-frame"
    id="video-frame"
    [ngClass]="{ hidden: hideStartVideo, 'alt-height': altHeight }">
    <div id="joinMsg"></div>
    <div class="join-box" id="btnStartup" *ngIf="!hideStartVideo">
        <div class="preference-settings" *ngIf="devicesReady">
            <div class="setting-btn">
                <label class="mb-3 outline-circle-checkbox" for="mutesound">
                    <i class="fas input-icon"
                        [ngClass]="{
                            'fa-microphone':
                                false,
                            'fa-microphone-slash':
                                true
                        }"></i>
                </label>
            </div>
            <div class="setting-btn">
                <label class="mb-3 outline-circle-checkbox" for="hidevideo">
                    <i class="fas input-icon"
                        [ngClass]="{
                            'fa-video':
                                false,
                            'fa-video-slash':
                                true
                        }"></i>
                </label>
            </div>
        </div>

        <div class="set-buttons">
            <button class="secondary-button"
                    type="button"
                    (click)="setPreference(true)"
                    [disabled]="joingCall">
                View Live Event
            </button>
        </div>
    </div>
    <div *ngIf="loadLiveFeed" class="live-feed-container">
        <button id="btnHangup"
                class="video-bar-btn stop"
                (click)="hangUpHelper()"
                matTooltip="Exit Event"
                matTooltipClass="tooltip-default">
            <i class="far fa-times-circle"></i>
        </button>
        <div class="video-stream live-stream">
            <a href="https://www.digitalinclusion.org/" target="_blank" rel="noopener noreferrer">
                <img src="https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/via3_ndia_joint_logo.png" alt="NDIA Presented by VIA3 Unity" class="live-event-banner" />
            </a>
            <amp 
                class="live-stream-box"
                id="live-player-box"
                vId="vid1"
                autoplay=true
                controls=true
                src={{channelInfo.liveStreamUrl}}>
                <!-- channelInfo.liveStreamUrl}} //amssamples.streaming.mediaservices.windows.net/3b970ae0-39d5-44bd-b3a3-3136143d6435/AzureMediaServicesPromo.ism/manifest-->
            </amp>
        </div>
    </div>

    <div id="container" class="container">
        <!-- <div id="toolbox" class="toolbox">
            <div class="video-bar-btn-list">
                <button id="btnCustomCamera"
                        class="video-bar-btn"
                        (click)="customCamera()"
                        matTooltip="toggle video"
                        matTooltipClass="tooltip-default"
                        [matTooltipShowDelay]="tooltipDelay">
                    <i class="fas btn-icon"></i>
                </button>
                <button id="btnCustomMic"
                        class="video-bar-btn"
                        (click)="customMic()"
                        matTooltip="toggle mic"
                        matTooltipClass="tooltip-default"
                        [matTooltipShowDelay]="tooltipDelay">
                    <i class="fas btn-icon"></i>
                </button>
            </div>
            <div class="video-bar-btn-list left-side">
                <ng-container *ngFor="let btn of featureList">
                    <button *ngIf="btn.switch()"
                            id="{{ btn.id }}"
                            class="video-bar-btn {{ btn.class }}"
                            (click)="btn.method()"
                            matTooltip="{{ btn.title }}"
                            matTooltipClass="tooltip-default"
                            [matTooltipShowDelay]="tooltipDelay">
                        <i class="{{ btn.icon }} btn-icon"></i>
                    </button>
                </ng-container>
                <button id="btnOptions"
                        *ngIf="
                    !featureList[featureList.length - 1].switch() ||
                    isModerator
                "
                        (click)="displayMoreOptions()"
                        class="video-bar-btn vert"
                        (blur)="hideMoreOptions()"
                        [ngClass]="{ selected: showMoreOptionsModal }"
                        matTooltip="more options"
                        matTooltipClass="tooltip-default"
                        [matTooltipShowDelay]="tooltipDelay">
                    <i class="fas fa-ellipsis-v btn-icon"></i>
                </button>
            </div>
            <div class="video-bar-btn-list">
                <button id="btnHangup"
                        class="video-bar-btn stop"
                        (click)="hangUpHelper()"
                        matTooltip="leave call"
                        matTooltipClass="tooltip-default"
                        [matTooltipShowDelay]="tooltipDelay">
                    <i class="fas fa-phone btn-icon"></i>
                </button>
            </div>
        </div> -->
        <!-- {{currentAudioDevice | json}}
        {{audioDevices | json}} -->
        <div class="invisible-click" *ngIf="sidebarShowing">
            <!-- used to close sidebar when video is present -->
        </div>
        <div id="jitsi-meet-conf-container"></div> <!-- remove for NDIA -->
    </div>

    <div 
        *ngIf="showMoreOptionsModal"
        class="jitsi-overlay"
        [ngClass]="{ modal: !_channelService._isDesktop}">
        <div class="options-box">
            <!-- <div class="top-buttons">
                <div></div>
                <span class="close-btn" (mousedown)="hideMoreOptions()">
                    <i class="fa fas-times"></i>
                </span>
            </div> -->
            <div class="options-list">
                <div class="options-title" *ngIf="isModerator">Moderator</div>
                <button *ngIf="isModerator"
                        id="btnMuteEveryone"
                        class="option-btn"
                        (mousedown)="muteEveryone()"
                        matTooltip="mute everyone"
                        matTooltipClass="tooltip-default">
                    <i class="fas fa-microphone-alt-slash btn-icon"></i>
                    <span class="btn-title">Mute Everyone</span>
                </button>
                <button *ngIf="isModerator"
                        id="btnGetParticipants"
                        class="option-btn kick"
                        (mousedown)="showKickParticipants()"
                        matTooltip="kick participants"
                        matTooltipClass="tooltip-default">
                    <i class="fas fa-times-circle btn-icon"></i>
                    <span class="btn-title">Kick Participants</span>
                </button>
                <div class="options-seperator"
                     *ngIf="
                        isModerator &&
                        !featureList[featureList.length - 1].switch()
                    "></div>
                <!-- <button
                    id="btnCustomSettings"
                    class="option-btn"
                    (mousedown)="showSettings()"
                    matTooltip="video settings"
                    matTooltipClass="tooltip-default"
                >
                    <i class="fas fa-cog btn-icon"></i>
                    <span class="btn-title">Settings</span>
                </button> -->
                <ng-container *ngFor="let btn of featureList">
                    <button *ngIf="!btn.switch()"
                            id="{{ btn.id }}"
                            class="option-btn {{ btn.class }}"
                            (mousedown)="btn.method()"
                            matTooltip="{{ btn.title }}"
                            matTooltipClass="tooltip-default">
                        <i class="{{ btn.icon }} btn-icon"></i>
                        <span class="btn-title">
                            {{
                            btn.title | titlecase
                            }}
                        </span>
                    </button>
                </ng-container>

                <!-- <button
                    id="btnSetFullScreen"
                    class="option-btn fullscreen"
                    (mousedown)="setFullScreen()"
                    type="button"
                    matTooltip="{{
                        isFullScreen ? 'exit full screen' : 'full screen'
                    }}"
                    matTooltipClass="tooltip-default"
                    [matTooltipShowDelay]="tooltipDelay"
                >
                    <i
                        class="fas btn-icon"
                        [ngClass]="{
                            'fa-expand': !isFullScreen,
                            'fa-compress': isFullScreen
                        }"
                    ></i>
                    <span class="btn-title" *ngIf="!isFullScreen"
                        >Full Screen</span
                    >
                    <span class="btn-title" *ngIf="isFullScreen"
                        >Exit Full Screen</span
                    >
                </button> -->
            </div>
        </div>
    </div>

    <div 
        *ngIf="showSettingsModal"
        class="jitsi-overlay modal"
        id="video-setting-modal"
        (click)="hideSettingsFromModal($event)">
        <div class="settings-box">
            <div class="top-buttons">
                <h4 class="modal-title">Settings</h4>
                <span 
                    *ngIf="_channelService._isDesktop"
                    class="close-btn"
                    (mousedown)="hideSettings()"
                    >
                    <i class="fas fa-times-circle"></i>
                </span>
            </div>
            <div class="settings-options">
                <div class="select">
                    <label for="audioSource">Video Input Source: </label>
                    <mat-select class="borderless-select"
                                name="videoSource"
                                [(ngModel)]="currentVideoInput"
                                (selectionChange)="setVideoInput()"
                                placeholder="Select A Device">
                        <mat-option *ngFor="let device of videoInputs"
                                    value="{{ device.deviceId }}">
                            {{ device.label }}
                        </mat-option>
                        <mat-option value
                                    disabled
                                    *ngIf="videoInputs.length < 1">
                            No Devices To Select From
                        </mat-option>
                    </mat-select>
                </div>
                <div class="select">
                    <label for="audioSource">Audio Input Source: </label>
                    <mat-select class="borderless-select"
                                name="audioSource"
                                [(ngModel)]="currentAudioInput"
                                (selectionChange)="setAudioInput()"
                                placeholder="Select A Device">
                        <mat-option *ngFor="let device of audioInputs"
                                    value="{{ device.deviceId }}">
                            {{ device.label }}
                        </mat-option>
                        <mat-option value
                                    disabled
                                    *ngIf="audioInputs.length < 1">
                            No Devices To Select From
                        </mat-option>
                    </mat-select>
                </div>
                <div class="select">
                    <label for="outputSource">Audio Output Source: </label>
                    <mat-select class="borderless-select"
                                name="outputSource"
                                [(ngModel)]="currentAudioOutput"
                                (selectionChange)="setAudioOutput()"
                                placeholder="Select A Device">
                        <mat-option *ngFor="let device of audioOutputs"
                                    value="{{ device.deviceId }}">
                            {{ device.label }}
                        </mat-option>
                        <mat-option value
                                    disabled
                                    *ngIf="audioOutputs.length < 1">
                            No Devices To Select From
                        </mat-option>
                    </mat-select>
                </div>
                <a class="device-check" (click)="getDevices()">
                    Check For New Devices
                </a>
            </div>
        </div>
    </div>

    <div 
        *ngIf="showKickParticipantsModal"
        class="jitsi-overlay modal"
        id="participants-modal"
        (click)="hideKickParticipantsFromModal($event)">
        <div class="participants-box">
            <div class="top-buttons">
                <h4 class="modal-title">Participants</h4>
                <span 
                    *ngIf="_channelService._isDesktop"
                    class="close-btn"
                    (click)="hideKickParticipants()"
                    >
                    <i class="fas fa-times-circle"></i>
                </span>
            </div>
            <div class="participant-list">
                <div class="participant"
                     *ngFor="let user of participantsInCall">
                    <p>{{ user.formattedDisplayName }}</p>
                    <button *ngIf="callerId !== user.participantId"
                            (click)="kickParticipant(user.participantId)">
                        Kick
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showJoinModal" class="jitsi-overlay modal">
    <div class="join-box">
        <div class="preference-settings" *ngIf="devicesReady">
            <div class="setting-btn">
                <input
                    id="mutesound"
                    [(ngModel)]="devicePreferences.jitsi.startWithAudioMuted"
                    type="checkbox"
                    name="mutesound"
                />
                <label class="video-chat-btn alt" for="mutesound">
                    <i
                        class="fas"
                        [ngClass]="{
                            'fa-microphone':
                                !devicePreferences.jitsi.startWithAudioMuted,
                            'fa-microphone-slash':
                                devicePreferences.jitsi.startWithAudioMuted
                        }"
                    ></i>
                </label>
            </div>
            <div class="setting-btn">
                <input
                    id="hidevideo"
                    [(ngModel)]="devicePreferences.jitsi.startWithVideoMuted"
                    type="checkbox"
                    name="hidevideo"
                />
                <label class="video-chat-btn alt" for="hidevideo">
                    <i
                        class="fas"
                        [ngClass]="{
                            'fa-video':
                                !devicePreferences.jitsi.startWithVideoMuted,
                            'fa-video-slash':
                                devicePreferences.jitsi.startWithVideoMuted
                        }"
                    ></i>
                </label>
            </div>
        </div>
        <div class="set-buttons">
            <button type="button" (click)="setPreference(false)">
                Join Call
            </button>
            <button type="button" (click)="declineCall()">Cancel</button>
        </div>
    </div>
</div>
