import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "lastMessage"
})

export class lastMessagePipe implements PipeTransform {
    transform(message: string, isSender?: boolean) {
        let content = "";
        let tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = message;
        content = tempDivElement.textContent || tempDivElement.innerText || "";
        // console.log(content);
        if (content) {
            return content;
        } else {
            return `${isSender ? "You sent" : "Sent"} a message`;
        }
    }
}