import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { AuthService } from 'src/app/core/auth.service';
// import { ApiCallsService } from 'src/app/services/api-calls.service';
import { ChannelService } from 'src/app/services/channel-service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';

@Component({
    selector: 'app-message-sidebar-selected',
    templateUrl: './message-sidebar-selected.component.html',
    styleUrls: ['./message-sidebar-selected.component.scss']
})
export class MessageSidebarSelectedComponent implements OnInit, OnDestroy, OnChanges {
    @Input("channelId") channelId: string;
    @Input("composeUserId") composeUserId: string;
    @Output("closeChat") closeChat = new EventEmitter();

    public hasNotification = false;
    public composeMessage: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private _toastService: ToastNotificationService,
        private _channelService: ChannelService,
        // private _apiCalls: ApiCallsService,
        // private _authService: AuthService,
        // private sanitizer: DomSanitizer,
        private _router: Router
    ) {

        this.subscriptions.push(this._toastService.onToastResolveEvent$.subscribe(data => {
            if (data.type === "openFullChat") {
                this._router.navigate(['/channel', this.channelId]);
            }
        }));
    }
    
    ngOnChanges(changes: SimpleChanges) {
        let channelId = changes.channelId.currentValue;
        
        if (channelId) {
            if (channelId === 'composeNewMessage') {
                this.composeMessage = true;
            } else {
                this.composeMessage = false;
                this.channelInitialize();
            }
        } else {
            this._toastService.error("No channel found");
        }
    }

    ngOnInit(): void {
        // alert(this.channelId)
        // this._toastService.error("From Initi");
        // if (this.channelId) {
        //     if (this.channelId === 'composeNewMessage') {
        //         this.composeMessage = true;
        //     } else {
        //         this.channelInitialize();
        //     }
        // } else {
        //     this._toastService.error("No channel found");
        // }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        return this._channelService.leave(this.channelId);
    }

    channelInitialize() {
        return this._channelService.join(this.channelId);
    }

    openFullChat() {
        this._toastService.continue("Open in full will close current channel and open a new one with this user / group.", 'openFullChat');
        this.backToMessage();
    }

    backToMessage() {
        this.closeChat.emit();
    }
}
