import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChannelHomepageComponent } from '../channel-homepage/channel-homepage.component';
import { ChannelService } from 'src/app/services/channel-service';
import { SocketsService } from 'src/app/services/sockets.service';
import { ApiCallsService } from 'src/app/services/api-calls.service';
import { Router } from '@angular/router';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { AuthService } from 'src/app/core/auth.service';
import { Constants } from '../../constants';
import { MeetingService } from 'src/app/services/meeting.service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { ChannelUser } from 'src/app/shared/interface/channel-user';

// ✨✨✨❌❌❌ component not currently in use (see shared/components/live-stream-video for new implementation) ... 

@Component({
    selector: 'app-channel-livestream',
    templateUrl: './channel-livestream.component.html',
    styleUrls: ['./channel-livestream.component.scss']
})
export class ChannelLivestreamComponent implements OnInit, OnDestroy {
    public isProd = Constants.isProd;

    public userInfo: ChannelUser = this._channelsHome.userInfo;
    public channelInfo: any = this._channelsHome.channelInfo;
    public meetingInfo: any = null;
    public channelId: string;
    public projectId: string;

    public ownersList: any = [];

    public searchId: any = "";
    public newChannel: any = {};

    public chatList: any = [];
    public socketServers: any = [];

    public isOwner: boolean = false;
    public isMember: boolean = false;

    public liveStreamNotFound: boolean = false;
    public noAccess: boolean = false;
    public denyAccess: boolean = false;
    public isFind: boolean = false; // If no channel Info is provided
    public showPage: boolean = false; // Set when channel is ready
    public showVid: boolean = true;
    public showChat: boolean = true;
    public showSettings: boolean = false;
    public showAttendance: boolean = false;
    public hasAccess: boolean = false;

    //temp for files
    public _showFiles: boolean = false
    public channelDisplayName: string = "";
    public downloadFile: any = {};
    public sharedFileListQuery: any = {};
    public fileList: any = [];

    public createAllow: any = false;

    constructor(
        private _channelsHome: ChannelHomepageComponent,
        private _fileStorageService: FileStorageService,
        private _chManageService: ProjectChannelService,
        private _channelService: ChannelService,
        private _meetingService: MeetingService,
        private _socketService: SocketsService,
        private _apiCalls: ApiCallsService,
        private _authService: AuthService,
        private router: Router
    ) {
        if (this.channelInfo) {
            this.channelId = this.channelInfo.channelId;
            this.channelInitialize();
        }

        this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            // alert('status changed')
            //alert(JSON.stringify(this.chatList));
            // this._getChannelUsersInRoom()
            this.chatList.forEach(user => {
                channelUserStatusList.forEach(channelUser => {
                    if (channelUser.selectedStatus === 0) {
                        if (channelUser.presenceStatus === 0) {
                            user.userStatus = "offline";
                        } else if (channelUser.presenceStatus === 1) {
                            user.userStatus = "away";
                        } else if (channelUser.presenceStatus === 2) {
                            user.userStatus = "active";
                        } else if (channelUser.presenceStatus === 3) {
                            //Temp for incall
                            user.userStatus = "active";
                        }
                    } else if (channelUser.selectedStatus === 1) {
                        // messUser.userStatus = "hidden";
                        user.userStatus = "offline";
                    } else if (channelUser.selectedStatus === 2) {
                        user.userStatus = "dnd";
                    } else if (channelUser.selectedStatus === 3) {
                        user.userStatus = "brb";
                    }
                });
            });
        });

        this._channelService.onJoinNotification$.subscribe(() => {
            // alert('someone joined')
            this._getChannelUsersInRoom();
        });

        this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            // alert('someone left')
            this._getChannelUsersInRoom();
        });

        this._channelService.onGetChannelUsersInRoomEvent$.subscribe((userObj: any) => {
            // alert('someone left')
            this._getChannelUsersInRoom();
        });

        this._channelService.onJoinEvent$.subscribe(() => {
            this.hasAccess = true;
        });
    }

    ngOnInit() {
        if (!this.channelInfo) {
            this.isFind = true;
            // this._getProject();
            this.checkProd();
            this.showPage = true;
        } else {
            this.channelId = this.channelInfo.channelId;
            this.channelPermissionsCheck(true);
        }
    }

    ngOnDestroy() {
        return this._channelService.leave(this.channelId);
    }

    checkProd() {
        // alert(Constants.isProd);
        // this.createAllow = true;
        if (Constants.isProd) {
            let list = [
                "2cf2e305-bdb8-4713-9f8f-18af1c7ac62b",
                "2637ebb1-ffb5-4144-b41b-d65e098188f3",
                "4753aca7-4390-4046-a8db-ca4511766af1",
                "10a19a28-c1ff-489d-9a25-7e7894f09a8f"
            ];
            if (list.some(id => id === this._authService._user.profile.sub.toLowerCase())) {
                this.createAllow = true;
            } else {
                this.createAllow = false;
            }
        } else {
            this.createAllow = true;
        }
    }

    ownerMemberCheck() {
        let owners = [];
        let members = [];

        if (this.channelInfo.private) {
            this.channelInfo.chatRoomChatUsers.forEach(user => {
                owners.push(user.chatUserKeyNavigation);
                if (user.chatUserKeyNavigation.channelUserId === this._authService._user.profile.sub) {
                    this.isOwner = true;
                    // alert("Owner");
                }
            });
            this.channelInfo.chatRoomChatUser1.forEach(user => {
                members.push(user.chatUserKeyNavigation);
                if (user.chatUserKeyNavigation.channelUserId === this._authService._user.profile.sub) {
                    this.isMember = true;
                    // alert("Member");
                }
            });
            if (this.isOwner || this.isMember) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    channelPermissionsCheck(init) {
        // if (this.ownerMemberCheck()) {
        if (true) {
            if (init) {
                this.ownerCheck();
                this._getMeeting();
                this.showPage = true;
            } else {
                this._getMeeting();
                this.isFind = false;
                this.liveStreamNotFound = false;
                this.denyAccess = false;
                this.router.navigate(['/channel', this.channelId]);
            }
            this.channelInitialize();
            this._sharedFileList();
        
        } else { // unreachable code ??? ... 
            if (init) { 
                this.noAccess = true;
                this.showPage = true;
            } else {
                this.denyAccess = true;
                // this.liveStreamNotFound = true;
            }
        }
    }

    channelInitialize() {
        return this._channelService.join(this.channelId);
    }

    _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                console.log("Getactivesocketservers quick meeting" + JSON.stringify(data.body));
                this.socketServers = data.body;
            })
            .catch(err => console.log(err));
    }



    // ❌ there is no error catching in this api sub ... 
    // ...
    _getChannelUsersInRoom() {
        this._chManageService.getChannelUsersInRoomByChannelId(this.channelId)
            .subscribe(data => { 
                //alert(this.channelId);
                let chatList = data.body;
                let _userId = this.userInfo.channelUserId;
                chatList.sort(function (x, y) { return x.channelUserId == _userId ? -1 : y.channelUserId == _userId ? 1 : 0; });
                chatList.forEach(user => {
                    if (user.chatUserKeyNavigation.PresenceStatus === 0) {
                        user.userStatus = "online";
                    } else if (user.chatUserKeyNavigation.PresenceStatus === 1) {
                        user.userStatus = "away";
                    } else if (user.chatUserKeyNavigation.PresenceStatus === 2) {
                        user.userStatus = "offline";
                    } else if (user.chatUserKeyNavigation.PresenceStatus === 3) {
                        user.userStatus = "busy";
                    } else if (user.chatUserKeyNavigation.PresenceStatus === 4) {
                        user.userStatus = "in-call";
                    }
                });
                this.chatList = [];
                this.chatList = chatList;
            });
    }

    _getChannel(channelId: string) {
        this._apiCalls.getChannel(channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (data.body.type.toLowerCase() === 'live') {
                        this.channelInfo = data.body;
                        this.channelId = this.channelInfo.channelId;
                        // this.ownerCheck();
                        this.channelPermissionsCheck(false);
                    } else {
                        this.liveStreamNotFound = true;
                    }
                } else {
                    this.liveStreamNotFound = true;
                    console.log(data.status);
                    // alert(JSON.stringify(data.status));
                }
            });
    }

    _getChannelJustInfo() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.channelInfo = data.body;
                    // this.ownerCheck();
                    this._getMeeting();
                } else {
                    console.log(data.status);
                    // alert(JSON.stringify(data.status));
                }
            });
    }

    _getMeeting() {
        this._meetingService.getMeetingsByChannel(this.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    if (data.body.length > 0) {
                        this.meetingInfo = data.body[0];
                        this.meetingPreferences();
                    } else {
                        console.log("no meeting found");
                    }
                } else {
                    alert(JSON.stringify(data));
                }
            });
    }

    ownerCheck() {
        this.channelInfo.chatRoomChatUsers.forEach(user => {
            this.ownersList.push(user.chatUserKeyNavigation)
        });
        console.log(this.ownersList);
        if (this.ownersList.some(listUser => this._authService._user.profile.sub === listUser.channelUserId)) {
            this.isOwner = true;
        }
    }

    meetingPreferences() {
        if (this.meetingInfo.DisplayAttendance) {
            this.showAttendance = true;
        } else {
            if (this.isOwner) {
                this.showAttendance = true;
            }
        }
    }

    onMeetingUpdate() {
        this._getChannelJustInfo();
        this.showSettings = false;
    }

    // _getProject() {
    //     this._apiCalls.getProjectByName('LiveStreamingApp')
    //         .subscribe(data => {
    //             if (data.status === 200) {
    //                 // alert(JSON.stringify(data));
    //                 this.projectId = data.body[0].teamProjectId;
    //                 this.newChannelInit();
    //             } else {
    //                 alert(JSON.stringify(data));
    //             }
    //         });
    // }

    //temp files
    filesInit() {
        this.downloadFile = {
            "roomOwnerUserId": "",
            "channelId": "",
            "path": "",
            "rev": ""
        };
    }

    _downloadSharedFile(filePath: string, fileName: string) {
        this.downloadFile.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
        this.downloadFile.path = filePath;
        this.downloadFile.channelId = this.channelInfo.channelId;
        // alert(JSON.stringify(this.downloadFile));
        this._fileStorageService.fileStorageDownloadSharedFile(this.downloadFile)
            .subscribe(data => {
                if (data.status === 200) {
                    console.log(data);
                    // window.open(data.body, '_blank') //open
                    this.createDownloadElement(data.body, fileName);
                    this.filesInit();
                } else {
                    alert(JSON.stringify(data));
                }
            });
    }

    createDownloadElement(link, name) {
        // alert(link);
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', link);
        element.setAttribute('target', '_blank');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    _sharedFileList() {
        this.sharedFileListQuery.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
        this.sharedFileListQuery.projectName = "LiveStreamingApp";
        this.sharedFileListQuery.roomName = this.channelInfo.name;
        this._fileStorageService.listSharedFiles(this.sharedFileListQuery)
            .subscribe(data => {
                if (data.status === 200) {
                    this.fileList = data.body.entries;
                    // alert(JSON.stringify(data));
                } else {
                    alert(JSON.stringify(data));
                }
            });
    }

    _softDeleteFile(filePath: string) {
        this._fileStorageService.softDeleteFile(filePath, this.channelInfo.channelId)
            .subscribe(res => {
                // alert(JSON.stringify(res));
                this._sharedFileList()
            });
    }

    _deleteFile(filePath: string) {
        console.log(filePath);
        this._fileStorageService.deleteFile(filePath, this.channelInfo.channelId)
            .subscribe(res => {
                if (res.status === 200) {
                    // alert(JSON.stringify(res));
                    console.log(res);
                    //this._channelService.sharedFileDeleteComplete(this.channelId);
                } else {
                    alert(JSON.stringify(res));
                }
            });
    }

    displayChat() {
        this.showChat = (this.showChat) ? false : true;
    }

    displaySettings() {
        this.showSettings = (this.showSettings) ? false : true;
    }

    showModal() {
        this._showFiles = (this._showFiles) ? false : true;
    }
}
