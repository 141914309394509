<h3 class="mb-4 text-ellipsis title-thin">Invite to Join VIA3</h3>

<div class="invite-wrapper flex-col">
	<div class="email-add">
		<form
			class="borderless-input-group mb-2"
			[formGroup]="newEmail"
			(ngSubmit)="addEmailsToList()"
		>
			<input
				type="text"
				class="borderless-input"
				name="email"
				autocomplete="off"
				formControlName="email"
				placeholder="{{
					emailList.length < maxInvites ? 'Add Emails' : 'Max Recipients Reached'
				}}"
			/>
			<button
				type="button"
				class="input-button"
				(click)="addEmailsToList()"
				[disabled]="
					emailControl.invalid || isAdding || emailList.length >= maxInvites
				"
			>
				<i
					class="fas fa-plus"
					matTooltip="add emails"
					matTooltipClass="tooltip-default"
				></i>
			</button>
		</form>
	</div>

	<div>
		<div class="email-list scrollbar-new">
			<div class="list-title">
				<p>Recipients:</p>
			</div>
			<ng-container *ngFor="let item of emailList; let i = index">
				<div class="email-item" [class.warning]="item.warning">
					<i
						*ngIf="item.warning"
						class="fas fa-exclamation-circle warning-icon"
						[matTooltip]="item.message"
						matTooltipClass="tooltip-default"
					></i>
					<p class="email">{{ item.email }}</p>
					<i
						(click)="removeEmailFromList(i)"
						class="fas fa-user-minus remove-icon"
						matTooltip="remove {{ item.email }} from list"
						matTooltipClass="tooltip-default"
					></i>
				</div>
			</ng-container>
		</div>
		<div class="bottom-message">
			<p
				class="me-auto"
				[ngClass]="{
					'email-message': emailList.length < maxInvites,
					'email-warning': emailList.length >= maxInvites
				}"
			>
				Max {{ maxInvites }} Recipients At A Time
			</p>
			<p *ngIf="hasMaxUsersSub && (subscriptionPlan.remainingUsers >= 1)" class="email-warning me-auto">
				You can only add {{subscriptionPlan.remainingUsers}} more {{subscriptionPlan.remainingUsers > 1 ? 'users' : 'user'}} with this subscription plan
			</p>
			<p *ngIf="hasWarnings" class="email-clear" (click)="removeAllWarnings()">
				Clear All <span class="orange">Warnings</span> Before Inviting
			</p>
		</div>
	</div>
	<div class="email-send">
		<p class="loading-users" *ngIf="isSending">
			Sending Emails...
		</p>
		<div class="form-btns">
			<button
				title="Send Message"
				type="button"
				class="primary-button"
				(click)="sendEmails()"
				[disabled]="hasWarnings"
				*ngIf="emailList.length > 0 && !isSending"
			>
				Send Invites
			</button>
		</div>
	</div>
</div>
