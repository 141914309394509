import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ContactsService } from "src/app/services/contacts.service";
// import { FileStorageService } from "src/app/services/file-storage.service";
import { ToastNotificationService } from "src/app/services/toast-notification.service";
import { ChannelUser } from "../../interface/channel-user";
import { Contact } from "../../interface/contact";
import { consoleLogStyle } from '../../varaibles';



@Component({
    selector: "create-contact",
    templateUrl: "./create-contact.component.html",
    styleUrls: ["./create-contact.component.scss"],
})
export class CreateContactComponent implements OnInit, OnDestroy {

    @Input("isEditContact") isEditContact: boolean;
    @Input("contactToEdit") contactToEdit: Contact;

    @Input("userInfo") userInfo: ChannelUser;
    
    @Output("formTouchedEvent") formTouchedEvent = new EventEmitter();
    @Output("onCompleteEvent") onCompleteEvent = new EventEmitter();

    public contact: Contact;

    public newContact: FormGroup;
    public isFormTouched: boolean = false;
    public isCreatingContact: boolean = false;

    public showEmailConflictError: boolean = false;

    public isUpdatingContact: boolean = false;

    public isImageUploading: boolean = false;
    public isImageDefault: boolean; // to show/hide trash ... 
    public imageToPreview: File = null;
    public imageToSubmit: File = null;
    public newContactId: string = null;

    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder, 
        private _contactService: ContactsService,
        private _toastService: ToastNotificationService,
        // private _fileStorageService: FileStorageService,
    ) {}

    ngOnInit(): void {
        this.createForm();
        if(this.isEditContact) {
            let target = <HTMLImageElement>document.getElementById('contact-image');
            target.src = `https://profile-images.azureedge.us/${this.contactToEdit.hash}`;
            if(this.contactToEdit.hash === "1") {
                this.isImageDefault = true;
            } else{
                this.isImageDefault = false;
            }
        } else {
            let target = <HTMLImageElement>document.getElementById('contact-image');
            target.src = 'https://profile-images.azureedge.us/1';
            this.isImageDefault = true;
        }
        // 
        document.addEventListener('dragover', (e) => e.preventDefault());
        document.addEventListener('drop', (e) => e.preventDefault());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        // 
        document.removeEventListener('dragover', (e) => e.preventDefault());
        document.removeEventListener('drop', (e) => e.preventDefault());
    }

    createForm() {
        this.newContact = this.fb.group({
            firstName: ["", [Validators.required]], // 50 max set on input..
            lastName: ["", [Validators.required]], // 50 max set on input..
            email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]], // 50 max set on input..
            phone: ["", [Validators.pattern("^(\\+\\d{1,3}[- ]?)?\\d{10}$")]],
            company: ["", []],// 250 max set on input..
            position: ["", []],// 250 max set on input..
            address: ["", []],// 250 max set on input..
            notes: ["", []],//
        });
        // 🚧 if isEdit patch this.newContact val here with this.contactInfo ... 
        if(this.isEditContact) {
            console.log("%c contact to edit? ", consoleLogStyle, this.contactToEdit)
            this.newContact.setValue({
                firstName: this.contactToEdit.firstName,
                lastName: this.contactToEdit.lastName,
                email: this.contactToEdit.email,
                phone: this.contactToEdit.phone||"",
                company: this.contactToEdit.company||"",
                position: this.contactToEdit.position||"",
                address: this.contactToEdit.address||"",
                notes: this.contactToEdit.notes||"",
            });
        }
        this.subscriptions.push(this.newContact.valueChanges.subscribe(data => {
            // console.log('Form changes', data);
            if(!this.isFormTouched) {
                this.isFormTouched = true;
                this.formTouchedEvent.emit();
                // console.log('Form Touched Emiited', this.isFormTouched);
            }
        }));
    }

    get newContactControls() {
        return this.newContact.controls;
    }

    createContact() {
        this.isCreatingContact = true;
        console.log("creating contact")
        this.contact = {
            "channelUserId": this.userInfo.channelUserId, // to put in your contacts 
            "firstName": this.newContact.value.firstName,
            "lastName": this.newContact.value.lastName,
            "email": this.newContact.value.email,
            "phone": this.newContact.value.phone,
            "company": this.newContact.value.company,
            "position": this.newContact.value.position,
            "address": this.newContact.value.address,
            "notes": this.newContact.value.notes,
            "isCustom": true
        };
        this._contactService.createContact(this.contact)
            .subscribe(res => {
                if(res.status === 201) {
                    console.log("contact created", res.body)
                    this.contact = res.body;
                    // 💡 upload image to blob here ... sends back event on complete to close modal ... 
                    if(!!this.imageToPreview) {
                        this.newContactId = this.contact.contactId;
                        this.imageToSubmit = this.imageToPreview;
                    } else {
                        this.completeContactSubmit();
                    }
                } else if (res.status === 409) {
                    console.log("email conflict")
                    this.handleEmailConflict();
                } else {
                    console.log("error creating contact", res.status, res.body)
                    this.isCreatingContact = false;
                    this._toastService.error("There was an error creating new contact.");
                }
            });
    }

    handleCreateContactImageUploadError(contactToDeleteId: string) {
        if(!!contactToDeleteId) {
            this._contactService.deleteContact(contactToDeleteId)
            .subscribe(res => {
                if(res.status === 204) {
                    console.log("contact removed from image upload error", contactToDeleteId)
                } else {
                    console.error("%c error, cannot delete new contact... ", consoleLogStyle)
                }
            });
        } else {
            console.error("%c No contactId with imageupload error, cannot delete new contact... ", consoleLogStyle)
        }
    }


    handleEmailConflict() {
        let targetInput = <HTMLInputElement>document.getElementById('contact-email-input');
        this.isEditContact ? this.isUpdatingContact = false : this.isCreatingContact = false;
        this.showEmailConflictError = true;
        this.newContact.patchValue({
            email: ""
        });
        targetInput.focus();
    }

    handleEmailConflictBlur() {
        if(this.showEmailConflictError) {
            this.showEmailConflictError = false;
        }
    }

    // 💡 called on btn click or enter key, checks if image was changed before submit ... 
    //   if image was changed it sends that image to be uploaded and when complete sends back event to update the contact with the new HASH ... 
    //   if the image was NOT changed, it can just update ... 
    updateContactWithImage() { 
        this.isUpdatingContact = true;
        if(!!this.imageToPreview) {// this is picked up onChanges of profile-img-upload ... to get hash for update ... 
            this.newContactId = this.contactToEdit.contactId;
            this.imageToSubmit = this.imageToPreview;
            // this.isDeletingImg = false;//incase an image was removed before
            console.log("%c updating contact with image change ", consoleLogStyle)
        } else {
            this.updateContact(null);
            console.log("%c updating contact with NO image change ", consoleLogStyle)
        }
    }

    // 💡 an updated contact needs the new image HASH before it can update, creating does not ... 
    updateContact(newImageHash:string) {
        this.isUpdatingContact = true;
        console.log("updating contact", newImageHash)
        this.contact = {
            "channelUserId": this.userInfo.channelUserId, // to put in your contacts 
            "contactId": this.contactToEdit.contactId,
            "firstName": this.newContact.value.firstName,
            "lastName": this.newContact.value.lastName,
            "email": this.newContact.value.email,
            "phone": this.newContact.value.phone,
            "company": this.newContact.value.company,
            "position": this.newContact.value.position,
            "address": this.newContact.value.address,
            "notes": this.newContact.value.notes,
            "hash": !!newImageHash ? newImageHash : this.isImageDefault ? '1' : this.contactToEdit.hash,
            "isFavorite": this.contactToEdit.isFavorite,
            "isCustom": true
        };
        this._contactService.updateContact(this.contactToEdit.contactId, this.contact)
            .subscribe(res => {
                if(res.status === 204) {
                    console.log("contact updated")
                    // 🚧 upload image to blob here ...
                    // if (this.isDeletingImg) {
                    //     this.removeProfilePhoto();
                    // }
                    this.completeContactSubmit();
                } else if (res.status === 409) {
                    console.log("email conflict")
                    this.handleEmailConflict();
                } else {
                    console.log("error updating contact", res.status)
                    this.isUpdatingContact = false;
                    this._toastService.error("There was an error updating this contact.")
                }
            });
    }

    completeContactSubmit(newContactHash?:string) {
        if(this.isEditContact) {
            this.isUpdatingContact = false;
            this.onCompleteEvent.emit(this.contact);
        } else {
            if(newContactHash) {
                this.contact.hash = newContactHash;
            } else {
                this.contact.hash = '1';
            }
            this.isCreatingContact = false;
            this.onCompleteEvent.emit(this.contact);
        }
    }

    keydownEnterForm() {
        console.log("keydown enter!")
        if(this.isEditContact) {
            this.updateContactWithImage();
        } else if(!this.isEditContact) {
            this.createContact();
        }
    }

    stopProp(e: Event) {
        // console.log('oi')
        e.stopPropagation();
    }
    prevDef(e: Event) {
        e.preventDefault();
    }


    // image upload && preview functions ... 
    displayPhotoEditor() {
        let hiddenFileInput = <HTMLInputElement>document.getElementById('file-upload');
        hiddenFileInput.click();
    }

    // to preview image (after validation) ... 
    recieveImage(image: File) {
        console.log("image recieved from input", image)
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.addEventListener('load', (e) => {
            console.log(e.target.result) // file type & img src ... 
            let img = e.target.result.toString();
            let target = <HTMLImageElement>document.getElementById('contact-image');
            target.src = img;
            this.isImageDefault = false;
            this.isImageUploading = false; 
            this.imageToPreview = image; // to upload to blob when form submited ... 
        });
        if(!this.isFormTouched) {
            this.isFormTouched = true;
            this.formTouchedEvent.emit();
            // console.log('Form Touched Emiited', this.isFormTouched);
        }
    }

    // ❌ remove commented after testing ... 
    removeProfilePhoto() {
        // console.log("removed clicked!!!!!!!!!!!")
        this.isImageUploading = true;
        let target = <HTMLImageElement>document.getElementById('contact-image');
        target.src = 'https://profile-images.azureedge.us/1';
        this.isImageDefault = true;
        this.imageToPreview = null;
        this.isImageUploading = false;
        if(!this.isFormTouched) {
            this.isFormTouched = true;
            this.formTouchedEvent.emit();
            // console.log('Form Touched Emiited', this.isFormTouched);
        }
        // if(this.isEditContact) {
        //     if(this.imageToPreview) {
        //         let target = <HTMLImageElement>document.getElementById('contact-image');
        //         target.src = 'https://profile-images.azureedge.us/1';
        //         this.isImageDefault = true;
        //         this.imageToPreview = null;
        //         this.isImageUploading = false;
        //     } else {
        //         this._fileStorageService.fileStorageCustomContactProfileImageDelete(this.contactToEdit.contactId)
        //             .subscribe(res => {
        //                 if(res.status === 200) {
        //                     console.log("remove success",res);
        //                     let target = <HTMLImageElement>document.getElementById('contact-image');
        //                     target.src = 'https://profile-images.azureedge.us/1';
        //                     this.isImageDefault = true;
        //                     this.imageToPreview = null;
        //                     this.isImageUploading = false;
        //                     // this.updateContact(null);
        //                     // if(!this.isFormTouched) {
        //                     //     this.isFormTouched = true;
        //                     //     this.formTouchedEvent.emit();
        //                     //     // console.log('Form Touched Emiited', this.isFormTouched);
        //                     // }
        //                 } else {
        //                     console.log("remove error",res);
        //                     this.isImageUploading = false;
        //                     this._toastService.error('There was an error removing this photo.')
        //                 }
        //             });
        //     }
        // } else {
        //     let target = <HTMLImageElement>document.getElementById('contact-image');
        //     target.src = 'https://profile-images.azureedge.us/1';
        //     this.isImageDefault = true;
        //     this.imageToPreview = null;
        //     this.isImageUploading = false;
        //     if(!this.isFormTouched) {
        //         this.isFormTouched = true;
        //         this.formTouchedEvent.emit();
        //         // console.log('Form Touched Emiited', this.isFormTouched);
        //     }
        // }
    }

    contactImgChange(status: boolean) {
        this.isImageUploading = status;
        if(!this.isImageUploading) {
            if(this.isUpdatingContact) {
                this.isUpdatingContact = false; // if error from profile-image-upload, isImageUploading = false is emited, so if updating set to false
            } else {
                this.isCreatingContact = false;
            }
        }
    }

    // drag & drop images ... 
    imageDragEnter(e: Event) {
        let target = e.target as HTMLElement;
        target.classList.add('active');
    }

    imageDragLeave(e: Event) {
        let target = e.target as HTMLElement;
        target.classList.remove('active');
    }

    imgageDragOver(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        let target = e as DragEvent;
        target.dataTransfer.dropEffect = 'copy';
    }

    imageDrop(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        let target = e.target as HTMLElement;
        target.classList.remove('active');
        this.isImageUploading = true;
        let drop = e as DragEvent;
        let { files } = drop.dataTransfer;
        console.log('image droped', files)
        this.imageValidation(files);
    }

    imageValidation(files: FileList) {
        let file = files[0];
        let fileReader = new FileReader();
        fileReader.onloadend = (e: any) => {
            let arr = (new Uint8Array(<ArrayBuffer>e.target.result)).subarray(0, 2);
            // console.log("buffer array :::", arr)
            let hex = "";
            for (let i = 0; i < arr.length; i++) {
                hex += arr[i].toString(16);
            }
            // console.log("file hex :::", hex)
            // Check the file signature against known types
            let img = ['8950', 'FFD8', 'FF0A', '0A87'];
            
            if (img.includes(hex.toUpperCase())) {
                // console.log("is IMAGE :::", hex)
                this.recieveImage(file);
                return;
            } else {
                this.isImageUploading = false;
                this._toastService.error("Can only upload Image files :   .png, .jpg, .jpeg, .jfif, .jpegXL.");
                return;
            }
        };
        fileReader.readAsArrayBuffer(file);
    }

    handleBrokenImage(e) {
        console.info("%c broken image log::: ", consoleLogStyle, e);
        let target = <HTMLImageElement>document.getElementById('contact-image');
        target.src = 'https://profile-images.azureedge.us/1';
        this.isImageDefault = true;
        this.imageToPreview = null;
        this._toastService.error("The image uploaded is broken, please try another.");
    }
}
