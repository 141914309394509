import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ChannelService } from '../../services/channel-service';
import { AppIntakeService } from '../../../../src/app/services/app-intake.service';
import { VdjjVideoIntakeHomepageComponent } from '../vdjj-video-intake-homepage/vdjj-video-intake-homepage.component';
import { ApiCallsService } from '../../../../src/app/services/api-calls.service';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { Router } from '@angular/router';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ChannelFull } from 'src/app/shared/interface/channel-full';



@Component({
    selector: 'vdjj-video-intake-chat-ao',
    templateUrl: './vdjj-video-intake-chat-ao.component.html',
    styleUrls: ['./vdjj-video-intake-chat-ao.component.scss']
})
export class VdjjVideoIntakeChatAOComponent implements OnInit, OnDestroy {
    @Output() connectionStatus = new EventEmitter();
    private onPopulateMessagesEvent = new Subject<boolean>();
    onPopulateMessagesEvent$ = this.onPopulateMessagesEvent.asObservable();

    public sessionReady: boolean = false;
    public showPage: boolean = false;// Set to true after intakeSessionUSer is confirmed
    public sessionPrevent: boolean = false;//Temp to prevent session from being started too many times

    public _app: any = {};
    public _customizations: any = {};
    public _officer: any = {};
    public _officerSessionData: any = {};
    public _channels: any = {};
    public _intakeSession: any = {};
    public _tenant: any = '';

    public chatShow: boolean = true;
    public showText: string = 'Chat Message Box';
    public formShow: boolean = false;
    public formsButtonText: string = 'Forms';
    public notificationForm: any = {
        "SMSNumber": "",
        "email": ""
    };

    public officerList: any = [];//Intake Officer Schedule
    public chatList: any = [];

    public channelId: string;
    public teamProjectId: string;
    public videoType: string;

    public formSelected: boolean = false;

    //for Medical
    public isMedical = this._intakeGlobal.isMedical;

    //for Jitsi
    public videoPreventList: any = ['startVideo', 'record', 'user', 'chat'];

    //temp for files
    public _showFiles: boolean = false
    public channelInfo: ChannelFull;
    public onlineForms: any = []; // ❌ not in use ? ... 

    //for Iframe
    // public iframe: any = null;

    private subscriptions: Subscription[] = [];

    // ❌ moved this bool from *ngFor, see 'local variables' - for forms always false ... 
    // https://v2.angular.io/docs/ts/latest/api/common/index/NgFor-directive.html
    public show: boolean = false;


    constructor(
        private _channelService: ChannelService,
        private _apiCallsService: ApiCallsService,
        private _intakeService: AppIntakeService,
        private _chManageService: ProjectChannelService,
        private _intakeGlobal: VdjjVideoIntakeHomepageComponent,
        // private sanitizer: DomSanitizer,
        private router: Router,
        private _toastService: ToastNotificationService,
    ) {
        // new for files events ... 
        this.subscriptions.push(this._channelService.onSharedFileUploadCompletedEvent$.subscribe((fileUploadObj: any) => {
            if (fileUploadObj !== null) {
                // console.log(fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase());
                if(fileUploadObj.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    this._toastService.file(fileUploadObj.uploaderDisplayName + " has uploaded " + fileUploadObj.fileName);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onSharedFileDeleteCompletedEvent$.subscribe((fileDeleteObj: any) => {
            if (fileDeleteObj !== null) {
                if(fileDeleteObj.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    this._toastService.file(fileDeleteObj.displayName + " has deleted " + fileDeleteObj.fileName);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onUpdateSessionUsersEvent$.subscribe(() => {
            this._getIntakeSessionUserByIntakeUser();
            this._getChannelUsersInRoom();
        }));

        this.subscriptions.push(this._channelService.onIntakeSessionEndedEvent$.subscribe((channelId: any) => {
            this._channelService.hangUpCallEvent();
            this._getIntakeSessionUserByIntakeUser();
            this.sessionReady = false; //shows the begin session dialouge 
            this._showFiles = false;
        }));

        this.subscriptions.push(this._channelService.onUpdateUserStatusEvent$.subscribe((channelUserStatusList: any) => {
            this.chatList.forEach((officer) => {
                channelUserStatusList.forEach((channelUser) => {
                    if (officer.channelUserId === channelUser.channelUserId) {
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            officer.userStatus = "hidden";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                    }
                });
            });
        }));

        this.subscriptions.push(this._channelService.onJoinNotification$.subscribe((joinObj: any) => {
            //this._getAllIntakeSessionUsers();
            this._getChannelUsersInRoom();
            if (joinObj.showJoinNotification == true) {
                if (this.channelId.toLowerCase() === joinObj.channelId.toLowerCase()) {
                    this._toastService.enter(joinObj.displayname);
                }
            }
        }));

        this.subscriptions.push(this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            this._getChannelUsersInRoom();
            
            if (this.channelId.toLowerCase() === userObj.channelId.toLowerCase()) {
                this._toastService.leave(userObj.displayname);
            }
        }));

        this.subscriptions.push(this._channelService.onGetChannelUsersInRoomEvent$.subscribe(() => {
            this._getChannelUsersInRoom();
        }));

        this.subscriptions.push(this._channelService.onUpdateScheduleEvent$.subscribe(() => {
            this._getAllIntakeOfficerShiftLogs();
            if (this.channelId) {
                this._getChannelUsersInRoom();
            }
        }));

        document.addEventListener("visibilitychange", this.onVisibilityChange);
    }

    ngOnInit() {
        //console.log("url: " + this.router.url);
        this._customizations = {
            "officerSelectMessage": {
                // "value": "Please select an available intake officer, if none are available please call the office on call at 555-555-5555"
                "value": ""
            },
            "instructionList": [],
            "instructionListSubs": []
        };
        this._officer = this._intakeGlobal.officerData
        this._app = this._intakeGlobal._app
        this._intakeSession = this._intakeGlobal._intakeSession;
        this._tenant = this._intakeGlobal._tenant;
        this.channelInfo = this._intakeGlobal.channelInfo;
        this.channelId = this.channelInfo.channelId;
        this.teamProjectId = this._intakeGlobal._teamProjectId;
        this.videoType = this.channelInfo.videoType;
        if (this.videoType === null || this.videoType === 'kurento') {
            this.videoType = 'jitsi';
        }
        this._getIntakeSessionUserByIntakeUser();
        this._getAppCustomizations();
        this._getAllIntakeOfficerShiftLogs();
        // this._getChannelInfo(); // ? 
        this.notificationFormInit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        return this._channelService.leave(this.channelId);
    }

    notificationFormInit() {
        this.notificationForm = {
            "firstName": "",
            "lastName": "",
            "email": "",
            "SMSNumber": "",
            "displaySchedule": "",
            "location": "",
            "hasDownloadedForms": false,
            "hasFaxedForms": false,
            "videoIntakeComplete": false,
            "active": true
        };
    }

    _getAppCustomizations() {
        this._intakeService.getAppCustomizations(this._app.appId)
            .subscribe(data => {
                if(data.status === 200) {
                    let customs = data.body;
                    //console.log("🔷 customs?", customs)
                    customs.forEach(custom => {
                        if (custom.section === "OfficerSelectMessage") {
                            this._customizations.officerSelectMessage = custom;
                        } else if (custom.section === "InstructionList") {
                            this._customizations.instructionList.push(custom);
                            this._customizations.instructionListSubs.push([]);
                        }
                    });
                    customs.forEach(custom => {
                        if (custom.section === "InstructionListSub1") {
                            this._customizations.instructionListSubs[0].push(custom);
                        } else if (custom.section === "InstructionListSub2") {
                            this._customizations.instructionListSubs[1].push(custom);
                        } else if (custom.section === "InstructionListSub3") {
                            this._customizations.instructionListSubs[2].push(custom);
                        } else if (custom.section === "InstructionListSub4") {
                            this._customizations.instructionListSubs[3].push(custom);
                        } else if (custom.section === "InstructionListSub5") {
                            this._customizations.instructionListSubs[4].push(custom);
                        }
                    });
                } else {
                    console.error("error getting app customizations", data)
                }
            });
    }

    _getAllIntakeOfficerShiftLogs() {
        console.log("getting officer schedulals......")
        this._intakeService.getAllOnShiftIntakeOfficerShiftLogs(this._intakeSession.intakeSessionId)
            .subscribe(data => {
                if(data.status === 200) {
                    // console.log("officer schedulal?", data)
                    this.officerList = [];
                    let shiftLog = data.body;
                    shiftLog.forEach(log => {
                        log.startTime = new Date(log.startTime);
                        log.startTime.setMinutes(log.startTime.getMinutes() - log.startTime.getTimezoneOffset()); //Converts back to Local
                        log.endTime = new Date(log.endTime);
                        log.endTime.setMinutes(log.endTime.getMinutes() - log.endTime.getTimezoneOffset()); //Converts back to Local
                        this.officerList.push(log)
                    });
                    console.log("officer schedulal log set", this.officerList)
                    // this.officerList = data.body;
                } else {
                    console.error("error getting officer shift logs", data)
                }
            });
    }

    _getChannelUsersInRoom() {
        // console.log("")
        this._chManageService.getChannelUsersInRoomByChannelId(this.channelId)
            .subscribe(data => {
                if(data.status === 200) {
                    this.chatList = [];
                    let chatList = data.body;
                    let _officerId = this._officer.chatUserKeyNavigation.channelUserId;
                    chatList.sort(function (x, y) { return x.channelUserId == _officerId ? -1 : y.channelUserId == _officerId ? 1 : 0; });
                    this.chatList = chatList;
                    this.chatList.forEach(officer => { 
                        let channelUser = officer.chatUserKeyNavigation;
                        if (channelUser.selectedStatus === 0) {
                            if (channelUser.presenceStatus === 0) {
                                officer.userStatus = "offline";
                            } else if (channelUser.presenceStatus === 1) {
                                officer.userStatus = "away";
                            } else if (channelUser.presenceStatus === 2) {
                                officer.userStatus = "active";
                            } else if (channelUser.presenceStatus === 3) {
                                //Temp for incall
                                officer.userStatus = "active";
                            }
                        } else if (channelUser.selectedStatus === 1) {
                            officer.userStatus = "hidden";
                        } else if (channelUser.selectedStatus === 2) {
                            officer.userStatus = "dnd";
                        } else if (channelUser.selectedStatus === 3) {
                            officer.userStatus = "brb";
                        }
                        this.officerList.forEach(log => {
                            // console.log(officer.chatUserKeyNavigation.channelUserId);
                            // console.log(log.intakeUserKeyNavigation.chatUserKeyNavigation.channelUserId);
                            if (officer.chatUserKeyNavigation.channelUserId === log.intakeUserKeyNavigation.chatUserKeyNavigation.channelUserId) {
                                officer.startTime = log.startTime;
                                officer.endTime = log.endTime;
                            }
                        });
                    });
                    console.log("officers list from get users in room", this.chatList)
                } else {
                    console.error("error getting users in room", data)
                }
            });
    }

    _getIntakeSessionUserByIntakeUser() {
        this._intakeService.getIntakeSessionUserByIntakeUserAndActive(this._officer.intakeUserId, this._intakeSession.intakeSessionId)
            .subscribe(data => {
                // alert(JSON.stringify(data));
                if (data.status === 200) {
                    if (data.body.length > 0) {
                        this._officerSessionData = data.body[0];
                        this.startSession();
                        //Temp for Iframe
                        // this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(
                        //     "../../../assets/jitsi/meeting.html?username=" +
                        //         this._officerSessionData.displayName +
                        //         "&channelname=" +
                        //         this.channelId
                        // );
                    } else {
                        this._officerSessionData = null;
                    }
                } else {
                    console.error("error getting intake session", data);
                }
                this.showPage = true;
            });
    }

    // actual button for starting session
    _postIntakeUserSession() {
        this.notificationForm.intakeSessionId = this._intakeSession.intakeSessionId;
        this.notificationForm.chatRoomId = this.channelId;
        this.notificationForm.intakeUserId = this._officer.intakeUserId;
        if (this.notificationForm.firstName) {
            this.notificationForm.displayName = this.notificationForm.firstName;
            if (this.notificationForm.lastName) {
                this.notificationForm.displayName = this.notificationForm.displayName + " " + this.notificationForm.lastName
            }
        } else if (!this.notificationForm.firstName && this.notificationForm.lastName) {
            this.notificationForm.displayName = this.notificationForm.lastName;
        } else {
            this.notificationForm.displayName = this._officer.chatUserKeyNavigation.displayName;
        }
        //console.log(this.notificationForm);
        if (!this.sessionPrevent) {
            this.sessionPrevent = true;
            this._channelService.postIntakeSessionUser(this.notificationForm, this._app.appId, this.router.url);
        }
    }

    startSession() {
        this.sessionReady = true;
        this.sessionPrevent = false;
    }

    // show instructions //
    public switchChat() {
        if (this.chatShow === true) {
            this.chatShow = false;
            this.formShow = false;
            this.showText = 'Instruction List';
            this.formsButtonText = 'Forms';
        } else {
            this.chatShow = true;
            this.formShow = false;
            this.showText = 'Chat Message Box';
            this.formsButtonText = 'Forms';
        }
    }

    public switchChatForms() {
        if (this.formShow == true) {
            this.chatShow = false;
            this.formShow = false;
            this.showText = 'Instruction List';
            this.formsButtonText = 'Forms';
        } else {
            this.chatShow = false;
            this.formShow = true;
            this.formsButtonText = 'Chat Message Box';
            this.showText = 'Instruction List';
            this.formSelected = false;
            this.getOnlineForms();
        }
    }

    showFilesModal() {
        this._showFiles = (this._showFiles) ? false : true;
    }

    // x why ... 
    setOnlineForms(data){
        data.forEach((element) => {
            this.onlineForms.push(element.formTemplateKeyNavigation);
        });
    }

    getOnlineForms() {
        this._apiCallsService.getSpecificOnlineFormsForChannel(this.channelId)
            .subscribe(data => {
                this.onlineForms = [];
                this.setOnlineForms(data.body);
                //console.log(this.onlineForms);
            });
    }

    onVisibilityChange() {
        if(this.channelId) {
            if (document.hidden) {
                console.log("Hidden " + this.channelId);
                this._channelService.sendCommand("/leave", this.channelId);
            }
            else {
                console.log("SHOWN " + this.channelId);
                this._channelService.join(this.channelId);
            }
        }
    }

    // new file store 2.0 ...
    // to close all modals | add switch if needed to close milti ... 
    clickOffModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        // console.log("%c e.t, e.srcel, e.curtar ", consoleLogStyle, event.target, event.srcElement, event.currentTarget)
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
            // console.log("%c tar.id? ,val? ", consoleLogStyle, idAttr, value)
        }
        switch (value) {
            case 'intake-files-modal': {
                this.showFilesModal();
                break;
            }
            default: return;
        }
    }
}





// ❌  old file storage funcs ... 

    // uploadChange(isUploading:boolean) {
    //     this.fileIsUploading = isUploading;
    // }

    //temp files
    // filesInit() {
    //     this.downloadFile = {
    //         "roomOwnerUserId": "",
    //         "channelId": "",
    //         "path": "",
    //         "rev": ""
    //     };
    // }

    // selectForm(){
    //     this.formSelected = !this.formSelected;
    // }

    // _downloadSharedFile(filePath: string, fileName: string) {
    //     this.downloadFile.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
    //     this.downloadFile.path = filePath;
    //     this.downloadFile.channelId = this.channelInfo.channelId;
    //     this._fileStorageService.fileStorageDownloadSharedFile(this.downloadFile)
    //         .subscribe(data => {
    //             if (data.status === 200) {
    //                 console.log(data);
    //                 // window.open(data.body, '_blank') //open
    //                 this.createDownloadElement(data.body, fileName);
    //                 this.filesInit();
    //             } else {
    //                 console.error("error downloading file", data);
    //             }
    //         });
    // }

    // createDownloadElement(link: any, name:string) {
    //     console.log("downloading file: ", name)
    //     var element = document.createElement('a');
    //     element.setAttribute('href', link);
    //     element.setAttribute('download', link);
    //     element.setAttribute('target', '_blank');
    //     element.style.display = 'none';
    //     document.body.appendChild(element);
    //     element.click();
    //     document.body.removeChild(element);
    // }

    // _sharedFileList() {
    //     this.sharedFileListQuery.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
    //     // if (this.isMedical) {
    //     //     this.sharedFileListQuery.projectName = this._tenant.toUpperCase() + "EMS";
    //     // } else {
    //     //     this.sharedFileListQuery.projectName = this._tenant.toUpperCase() + "IntakeApp";
    //     // }
    //     this.sharedFileListQuery.projectName = this.channelInfo.teamProjectKeyNavigation.name;
    //     this.sharedFileListQuery.roomName = this.channelInfo.name;
    //     this._fileStorageService.listSharedFiles(this.sharedFileListQuery)
    //         .subscribe(data => {
    //             if (data.status === 200) {
    //                 this.fileList = data.body.entries;
    //                 // alert(JSON.stringify(data));
    //             } else {
    //                 console.error("error getting files list", data);
    //             }
    //         });
    // }

    // _softDeleteFile(filePath: string) {
    //     this._fileStorageService.softDeleteFile(filePath, this.channelInfo.channelId)
    //         .subscribe(res => {
    //             // alert(JSON.stringify(res));
    //             this._sharedFileList();
    //         });
    // }

    // _deleteFile(filePath: string) {
    //     console.log(filePath);
    //     this._fileStorageService.deleteFile(filePath, this.channelInfo.channelId)
    //         .subscribe(res => {
    //             if (res.status === 200) {
    //                 // alert(JSON.stringify(res));
    //                 console.log(res);
    //                 this._sharedFileList();
    //             } else {
    //                 console.error(JSON.stringify(res));
    //             }
    //         });
    // }



// ❌ other un-used funcs ... 

    //temp for Iframe 
    // iframeHeight() {
    //     let viewHeight = window.innerHeight;
    //     //console.log(viewHeight);
    //     //console.log(viewHeight - 120)
    //     return `${viewHeight - 120}px`;
    // }

    // emitConnectionStatus(status) {
    //     this.connectionStatus.emit(status);
    // }

        // _getIntakeSessionUserByIntakeUser() {
    //     this._intakeService.getIntakeSessionUserByIntakeUser(this._officer.intakeUserId)
    //         .subscribe(data => {
    //             // alert(JSON.stringify(data));
    //             if (data.status === 200) {
    //                 if (data.body.length > 0) {
    //                     var index;
    //                     let isActive = data.body.some((session, i) => {
    //                         index = i;
    //                         return session.active
    //                     });
    //                     if (isActive) {
    //                         this._officerSessionData = data.body[index];
    //                         this.startSession();
    //                     } else {
    //                         // this._officerSessionData = null;
    //                     }
    //                 } else {
    //                     // this._officerSessionData = null;
    //                 }
    //             } else {
    //                 alert(data.status);
    //             }
    //             this.showPage = true;
    //         });
    // }

        // dateCompare(dateCheck) {
    //     let endTime = new Date(dateCheck);
    //     endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset());
    //     let now = new Date();
    //     now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    //     // console.log(endTime.getTime());
    //     // console.log(now.getTime());
    //     if (endTime.getTime() > now.getTime()) {
    //         //console.log(true);
    //         return true;
    //     } else {
    //         //console.log(false);
    //         return false;
    //     }
    // }

    // _getChannelInfo() {
    //     let channelName = "PO_" + this._officer.chatUserKeyNavigation.channelUserId;
    //     this._apiCallsService.getChannelByNameAndProjectId(channelName, this.teamProjectId)
    //         .subscribe(data => {
    //             if(data.status === 200) {
    //                 let channels = data.body;
    //                 let selectedChannel = channels[0];
    //                 this.channelId = selectedChannel.channelId;
    //                 this.channelInfo = selectedChannel;
    //             } else {
    //                 console.error("error getting channel info", data)
    //             }
    //         });
    // }