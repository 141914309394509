import { Pipe, PipeTransform } from "@angular/core";

// this returns the users initilals in uppercase 

// 💡 if is custom contact username will be the firstName second arg is lastName ... 
// example tenant user [ {{user.username | defaultImageOverlay}} ]
// example custom contact [ {{user.firstName | defaultImageOverlay : user.lastName}} ]

@Pipe({
    name: "defaultImageOverlay",
})
export class DefaultImageOverlayPipe implements PipeTransform {
    transform(username: string, customContactLastName?: string): string {
        // console.info("username for pipe from message list", username, customContactLastName)
        if(!username) return;
        // if(username.includes('Guest.')) return 'G';
        if(customContactLastName) { 
            let result = username[0] + customContactLastName[0];
            return result.toLocaleUpperCase();
        
        } else { 
            if(username.includes('.')) {
                let splitName = username.split('.');
                let result = splitName[0][0] + splitName[1][0];
                return result.toLocaleUpperCase();
            
            } else {
                return username[0].toLocaleUpperCase() + username[1].toLocaleUpperCase();
            }
        }
    }
}

