import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ToastNotification, ToastInfoType, ToastNotificationType, ToastWarningType } from '../toast-notification/toast-notification.model';

@Injectable({
    providedIn: 'root'
})
export class ToastNotificationService {
    public subject = new Subject<ToastNotification>();
    public keepAfterRouteChange = true;

    private onToastResolveEvent = new Subject<any>();
    private onToastExamplesEvent = new Subject<any>();
    public onToastResolveEvent$ = this.onToastResolveEvent.asObservable();
    public onToastExamplesEvent$ = this.onToastExamplesEvent.asObservable();

    constructor(public router: Router) {  
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true  
        router.events.subscribe(event => {  
            if (event instanceof NavigationStart) {  
                if (this.keepAfterRouteChange) {  
                    // only keep for a single route change  
                    this.keepAfterRouteChange = false;  
                } else {  
                    // clear alert messages  
                    this.clear();  
                }  
            }  
        });  
    }
    
    getAlert(): Observable<any> {  
        return this.subject.asObservable();  
    }  

    success(message: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {  
        this.showNotification(ToastNotificationType.Success, message, disableSound, keepAfterRouteChange);  
    }  

    error(message: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {  
        this.showNotification(ToastNotificationType.Error, message, disableSound, keepAfterRouteChange);  
    }  

    info(message: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {  
        this.showNotificationInfo(ToastInfoType.Default, message, null, disableSound, keepAfterRouteChange);  
    }

    warn(message: string, disableSound: boolean = false,  keepAfterRouteChange: boolean = false) {  
        this.showNotificationWarning(ToastWarningType.Default, message, null, null, false, disableSound, keepAfterRouteChange);
    }

    continue(message: string, type: string, checkboxMessage: string = null, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        // let message = "You are about to leave the current page, would you like to continue";
        this.showNotificationWarning(ToastWarningType.Continue, message, type, checkboxMessage, true, disableSound, keepAfterRouteChange);
    }
    
    continueLink(message: string, url: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        // let message = "You are about to leave the current page, would you like to continue";
        this.showNotificationWarning(ToastWarningType.ContinueLink, message, url, null, true, disableSound, keepAfterRouteChange);
    }
      
    enter(name: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        let message = "has entered the channel.";  
        this.showNotificationInfo(ToastInfoType.Enter, message, name, disableSound, keepAfterRouteChange);  
    }

    leave(name: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        let message = "has left the channel.";  
        this.showNotificationInfo(ToastInfoType.Leave, message, name, disableSound, keepAfterRouteChange);  
    }

    user(name: string, message: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        this.showNotificationInfo(ToastInfoType.User, message, name, disableSound, keepAfterRouteChange);  
    }

    file(message: string, disableSound: boolean = false, keepAfterRouteChange: boolean = false) {
        this.showNotificationInfo(ToastInfoType.File, message, null, disableSound, keepAfterRouteChange);  
    }
     
    showNotification(type: ToastNotificationType, message: string,  disableSound: boolean = false, keepAfterRouteChange) {  
        this.keepAfterRouteChange = keepAfterRouteChange;  
        this.subject.next(
            <ToastNotification> { 
                type: type, 
                message: message,
                disableSound: disableSound  
            });  
    }

    showNotificationInfo(infoType: ToastInfoType, message: string, name: string = null, disableSound: boolean, keepAfterRouteChange: boolean) {  
        this.keepAfterRouteChange = keepAfterRouteChange;  
        this.subject.next(
            <ToastNotification> { 
                type: ToastNotificationType.Info,
                infoType: infoType,
                message: message,
                name: name, 
                disableSound: disableSound  
            });  
    }
    
    showNotificationWarning(warningType: ToastWarningType, message: string, data: string, checkboxMessage: string, disableTimeout: boolean, disableSound: boolean, keepAfterRouteChange: boolean) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(
            <ToastNotification>{
                type: ToastNotificationType.Warning,
                warningType: warningType,
                message: message,
                data: data,
                checkboxMessage: checkboxMessage,
                checkboxConfirm: false,
                disableTimeOut: disableTimeout,
                disableSound: disableSound
            });
    }

    clear() {  
        this.subject.next();  
    }

    public completeToastResolveEvent(type: string, checkboxConfirm: boolean = false) {
        this.onToastResolveEvent.next({type: type, checkboxConfirm: checkboxConfirm});
    }

    public showToastExamples() {
        this.onToastExamplesEvent.next();
    }
}
