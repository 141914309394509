import { Injectable } from "@angular/core";
import { Constants } from "../constants";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { of, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Alert, AlertSetting } from "../shared/interface/alert";



@Injectable({
    providedIn: "root",
})
export class AlertsService {

    public _http: string = Constants.apiRoot;

    private onRemoveAlertEvent = new Subject<any>();
    public onRemoveAlertEvent$ = this.onRemoveAlertEvent.asObservable();

    private onRemoveAllAlertsEvent = new Subject<any>();
    public onRemoveAllAlertsEvent$ = this.onRemoveAllAlertsEvent.asObservable();

    constructor(private httpClient: HttpClient) {}

    // get all alerts by user id 
    getUserAlerts(channelUserId: string): Observable<HttpResponse<Array<Alert>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'userNotifications/getNotifications/' + channelUserId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 
    deleteAlert(notificationId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}userNotifications/${notificationId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteAllAlerts(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}userNotifications/deleteAll/${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // used to update an alert ... 
    patchAlert(notificationId: string, alertObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let alertArr = [alertObj];
        return this.httpClient
            .patch(`${Constants.apiRoot}userNotifications/${notificationId}`, alertArr, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // alert SETTINGS ... 
    getUserAlertSettings(channelUserId: string): Observable<HttpResponse<Array<AlertSetting>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(this._http + 'userNotificationSettings/' + channelUserId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // used to update an alert setting ... 
    patchAlertSetting(alertId: string, alertSettingObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let alertSettingArr = [alertSettingObj];
        return this.httpClient
            .patch(`${Constants.apiRoot}userNotificationSettings/${alertId}`, alertSettingArr, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    removeAlert(notificationId:string, alertIdx: number) {
        let alertObj = {
            notificationId,
            alertIdx
        };
        // 
        this.onRemoveAlertEvent.next(alertObj);
    }

    removeAllAlerts() {
        // 
        this.onRemoveAllAlertsEvent.next();
    }
}
