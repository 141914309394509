import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { of, Observable} from 'rxjs';
import { catchError} from 'rxjs/operators';
import { MeetingEvent } from '../shared/interface/meeting-event';

@Injectable({
    providedIn: 'root'
})
export class MeetingService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getAllMeetings(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeeting(meetingId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting/` + meetingId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeetingsByChannel(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting?channelId=${channelId}&past=false&pageSize=8`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // only call if quick meeting / not channel meeting ...
    getQuickMeetingByChannelId(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting?channelId=${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeetingsByCreator(chatUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting?chatUserId=${chatUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeetingById(meetingId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting/${meetingId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
    

    postMeeting(meetingObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}meeting/`, meetingObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putRefreshMeetingToken(meetingId:any, meetingObj): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}meeting/refreshToken/` +  meetingId, meetingObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putMeeting(meetingId: string, meetingObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}meeting/` + meetingId, meetingObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteMeeting(meetingId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}meeting/` + meetingId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    cancelMeeting(meetingObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}meeting/cancelMeeting/` , meetingObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 
    removeMeeting(meetingObj: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}meeting/hideMeeting/` , meetingObj, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getUserMeetingAttendees(userId:string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meetingAttendees?chatUserId=${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 🚧 new events 
    getUserMeetingsFuture(userId: string): Observable<HttpResponse<Array<MeetingEvent>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting?chatUserId=${userId}&past=false&pageSize=8`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 🚧 for ndia (no paginaton (set high)) ... 
    getUserMeetingsFutureNoPagination(userId: string): Observable<HttpResponse<Array<MeetingEvent>>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting?chatUserId=${userId}&past=false&pageSize=1000`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeetingAttendeesByMeetingId(meetingId:string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meetingAttendees?meetingId=${meetingId}&pageSize=300`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getMeetingsByChannelId(channelId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meeting/channelMeetings/${channelId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    // 🚧
    getMeetingAttendeesToEditByMeetingId(meetingId:string, userId:string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}meetingAttendees/getAttendees/${meetingId}?userId=${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    sendInviteToken(inviteeObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.stsAuthority}account/LoginWithoutPassword`, inviteeObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postGuestMeetingAttendee(attendeeObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}meetingAttendees/guestattendees/`, attendeeObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postMeetingAttendee(attendeeObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}meetingAttendees/`, attendeeObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteMeetingAttendee(attendeeObject:any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let meetingId = attendeeObject.meetingId;
        let channelUserId = attendeeObject.channelUserId;
        return this.httpClient
            .delete(`${Constants.apiRoot}meetingAttendees/${meetingId}/${channelUserId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
