<div class="channel-info flex-col">
    <ng-container *ngIf="!isLoadingChannelInfo">
        <!-- title -->
        <div class="title-row mb-4">
            <div class="flex channel-title">
                <i class="fas fa-tv-retro me-3 channel-icon"></i>
                <i 
                    *ngIf="channelInfo.isFavorite"
                    matTooltip="favorite" 
                    matTooltipClass="tooltip-default"
                    class="fas fa-star is-fav">
                </i>
                <h3 
                    class="title-thin text-ellipsis two-line-clamp"
                    matTooltip="{{channelInfo.name}}" 
                    matTooltipClass="tooltip-default">
                    {{channelInfo.name}}
                </h3>
            </div>
        </div>
        <!-- project name -->
        <div class="project-name flex mb-2">
            <p class="me-2">Project: </p><strong class="text-ellipsis">{{ channelInfo.teamProjectKeyNavigation.name }}</strong>
        </div>
        <!-- topic -->
        <div class="channel-topic flex mb-2">
            <p class="me-2">Topic: </p><strong class="text-ellipsis">{{channelInfo.topic || 'none'}}</strong>
        </div>
        <!-- dates -->
        <div class="last-visited mb-4">
            <div class="dates flex-col">
                <div class="flex align-center">
                    <p class="me-2">Last visited: </p>
                    <strong *ngIf="!!channelInfo.lastActivity; else notVisited">
                        {{channelInfo.lastActivity + 'Z'|date: 'M/d/yyyy h:mm a'}}
                    </strong>
                    <ng-template #notVisited>
                        <small class="">not yet visited.</small>
                    </ng-template>
                </div>
            </div>
            <div class="dates flex-col">
                <div class="flex">
                    <p class="me-2">Created: </p><strong>{{channelInfo.dateCreated + 'Z'|date: 'M/d/yyyy'}}</strong>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="break mb-4"></div>
        <!-- creator/owners -->
        <div class="channel-users flex mb-3">
            <div class="channel-owners flex-col w-50">
                <p class="me-2 mb-2">Owners: &nbsp;&nbsp;( <small>{{channelInfo.chatRoomChatUsers.length}}</small> )</p>
                <div class="channel-owners-users flex-col scrollbar-new">
                    <div class="flex align-center mb-2 owner">
                        <div class="user-img me-2">
                            <img 
                                *ngIf="!!channelInfo.chatUserKeyNavigation.hash && channelInfo.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                                src="https://profile-images.azureedge.us/{{channelInfo.chatUserKeyNavigation.hash}}" alt="user"
                                (error)="handleBrokenImage()">
                            <ng-template #defaultImg>
                                <div 
                                    class="default-user-img circle flex-center-all every-third"
                                    matTooltip="{{channelInfo.chatUserKeyNavigation.displayName|titlecase}}" 
                                    matTooltipClass="tooltip-default">
                                    {{channelInfo.chatUserKeyNavigation.username|defaultImageOverlay}}
                                </div>
                            </ng-template>
                        </div>
                        <strong class="text-ellipsis creator">{{channelInfo.chatUserKeyNavigation.displayName}}</strong>
                        <small class="ms-2"> &bull; Creator</small>
                    </div>
                    <ng-container *ngFor="let user of channelInfo.chatRoomChatUsers;index as i">
                        <!-- creator check here -->
                        <div 
                            *ngIf="user.chatUserKeyNavigation.channelUserId !== channelInfo.chatUserKeyNavigation.channelUserId"
                            class="flex align-center mb-2 owner">
                            <div class="user-img me-2">
                                <img 
                                    *ngIf="!!user.chatUserKeyNavigation.hash && user.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                                    src="https://profile-images.azureedge.us/{{user.chatUserKeyNavigation.hash}}" alt="user"
                                    (error)="handleBrokenImage(true,i)">
                                <ng-template #defaultImg>
                                    <div 
                                        class="default-user-img circle flex-center-all"
                                        [class.every-first]="i % 4 === 0"
                                        [class.every-second]="i % 4 === 1"
                                        [class.every-third]="i % 4 === 2"
                                        [class.every-fourth]="i % 4 === 3"
                                        matTooltip="{{user.chatUserKeyNavigation.displayName|titlecase}}" 
                                        matTooltipClass="tooltip-default">
                                        {{user.chatUserKeyNavigation.username|defaultImageOverlay}}
                                    </div>
                                </ng-template>
                            </div>
                            <strong class="text-ellipsis">{{user.chatUserKeyNavigation.displayName}}</strong>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- members -->
            <div class="channel-members flex-col w-50">
                <p class="me-2 mb-2">Members: &nbsp;&nbsp;( <small>{{channelInfo.chatRoomChatUser1.length}}</small> )</p>
                <div class="channel-members-users flex-col scrollbar-new">
                    <ng-container *ngFor="let user of channelInfo.chatRoomChatUser1;index as i">
                        <div class="flex align-center mb-2 owner">
                            <div class="user-img me-2">
                                <img 
                                    *ngIf="!!user.chatUserKeyNavigation.hash && user.chatUserKeyNavigation.hash !== '1'; else defaultImg"
                                    src="https://profile-images.azureedge.us/{{user.chatUserKeyNavigation.hash}}" alt="user"
                                    (error)="handleBrokenImage(false,i)" >
                                <ng-template #defaultImg>
                                    <div 
                                        class="default-user-img circle flex-center-all"
                                        [class.every-first]="i % 4 === 0"
                                        [class.every-second]="i % 4 === 1"
                                        [class.every-third]="i % 4 === 2"
                                        [class.every-fourth]="i % 4 === 3"
                                        matTooltip="{{user.chatUserKeyNavigation.displayName|titlecase}}" 
                                        matTooltipClass="tooltip-default">
                                        {{user.chatUserKeyNavigation.username|defaultImageOverlay}}
                                    </div>
                                </ng-template>
                            </div>
                            <strong class="text-ellipsis">{{user.chatUserKeyNavigation.displayName}}</strong>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="break mb-4"></div>
        <!-- welcome message  ... -->
        <div class="channel-welcome-message mb-3">
            <p class="me-2 mb-1">Welcome message: </p>
            <div class="box-quote active-quote mt-1 mb-2">
                <i class="quote-icon fas fa-quote-right flex-center-all"></i>
                <div class="box-quote-text scrollbar-new">
                    {{!!channelInfo.welcome ? channelInfo.welcome : 
                        'Here is where users can see the welcome message for the channel, so if there is no welcome message set this generic message will be shown...'
                        }}
                </div>
            </div>
        </div>
        <!--  -->
        <div class="break mb-4"></div>
        <!--  -->
        <div class="flex">
            <button 
                [routerLink]="'/channel/' + channelInfo.channelId" 
                class="secondary-button mx-auto">
                Enter Channel
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoadingChannelInfo">
        <loading-home></loading-home>
    </ng-container>
</div>
