import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "channelOwnerCompare",
})
export class ChannelOwnerComparePipe implements PipeTransform {
    transform(userId: string, owners: any[]): boolean {
        return owners.some(owner => owner.chatUserKeyNavigation.channelUserId === userId);
    }
}
