import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FileStorageHomepageComponent } from './file-storage-homepage/file-storage-homepage.component';


// const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild([
    { path: "filestorage", component: FileStorageHomepageComponent }
  ])],
  exports: [RouterModule]
})
export class FileStorageAppRoutingModule { }
