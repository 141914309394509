import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FileStorageAppRoutingModule } from './file-storage-app-routing.module';
import { FileStorageHomepageComponent } from './file-storage-homepage/file-storage-homepage.component';
import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    FileStorageHomepageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FileStorageAppRoutingModule
  ]
})
export class FileStorageAppModule { }
