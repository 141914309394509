import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    // these subjects need to be .next()ed from modal child components ... 
    public modalFormTouchedSubject: Subject<void> = new Subject<void>();
    public modalFormCompleteSubject: Subject<void> = new Subject<void>();
}