import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AppComponent } from "../app.component";
import { MeetingService } from "../services/meeting.service";
import { 
    listAnimationWrap, 
    listAnimationItem, 
    listAnimationWrapLong, 
    listAnimationItemLong, } from "../animations/global-animations";
import { ToastNotificationService } from "../services/toast-notification.service";
import { Subscription } from "rxjs";
import { consoleLogStyle } from '../shared/varaibles';
import { MeetingEvent } from "../shared/interface/meeting-event";



interface NdiaSchedule {
    day: string,
    events: any[]
};

@Component({
    selector: "conference-full-schedule",
    templateUrl: "./conference-full-schedule.component.html",
    styleUrls: ["./conference-full-schedule.component.scss"],
    animations: [
        listAnimationWrap, 
        listAnimationItem, 
        listAnimationItemLong, 
        listAnimationWrapLong, 
    ],
})
export class ConferenceFullScheduleComponent implements OnInit, OnDestroy {

    public userInfo: any = this._appComp.channelUserInfo;
    
    public isLoadingNdiaEvents: boolean;
    public ndiaEvents: NdiaSchedule[] = [
        {day: 'Tuesday, February 15th', events: []},
        {day: 'Wednesday, February 16th', events: []},
        {day: 'Thursday, February 17th', events: []}
    ];

    public todaysCurrentDateTime: any = new Date();
    public setCurrentDateTimeInterval: any;
    public selectedMeeting: MeetingEvent;
    public displayMeetingInfoModal: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private _toast: ToastNotificationService,
        private _meetingService: MeetingService,
        private _appComp: AppComponent,
    ) {}

    ngOnInit(): void {
        this.isLoadingNdiaEvents = true;
        this._getMeetings();
        // interval is cleared onDestroy 
        this.setCurrentDateTimeInterval = setInterval(() => {
            this.todaysCurrentDateTime = new Date().toISOString(); //  so we can use math on date for comparison: todaysDate > meeting.start
            // console.log("%c Current time ", consoleLogStyle, this.todaysCurrentDateTime);
        }, 1000); // keep current time for to show the "meeting in progress badge"
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        clearInterval(this.setCurrentDateTimeInterval);
    }

    _getMeetings() {
        this._meetingService.getUserMeetingsFutureNoPagination(this.userInfo.channelUserId)
            .subscribe(data => {
                if (data.status === 200) {
                    data.body;
                    this.sortNdiaEvents(data.body);
                    // this.isLoadingNdiaEvents = false;
                } else if (data.status === 404) {
                    console.error(JSON.stringify(data));
                    this.isLoadingNdiaEvents = false;
                } else {
                    this._toast.error("There was an error getting NDIA events.");
                    console.error(JSON.stringify(data));
                    this.isLoadingNdiaEvents = false;
                }
            });
    }

    sortNdiaEvents(events:any[]) {
        // console.log("%c 🎟 NDIA events (un-sorted): ", consoleLogStyle, events)
        // compare againts the 3 days 
        const day1 = '02-15', day2 = '02-16', day3 = '02-17';
        // let day2 = '';
        // let day3 = '';
        events.forEach(meeting => {
            if (meeting.start.includes(day1)) {
                this.ndiaEvents[0].events.push(meeting);
            } else if (meeting.start.includes(day2)) {
                this.ndiaEvents[1].events.push(meeting);
            } else if (meeting.start.includes(day3)) {
                this.ndiaEvents[2].events.push(meeting);
            }
        });
        // console.log("%c 🎟 NDIA events [sorted]: ", consoleLogStyle, this.ndiaEvents)
        // console.table(this.ndiaEvents)
        this.isLoadingNdiaEvents = false;
    }

    prevDefStopProp(e: Event) {
        // console.log('oi')
        e.preventDefault();
        e.stopPropagation();
    }

    // meeting info modal
    showMeetingInfoModal(dayIdx: number, eventIdx: number) {
        this.selectedMeeting = this.ndiaEvents[dayIdx].events[eventIdx];
        this.displayMeetingInfoModal = true;
    }
    closeMeetingInfoModal() {
        this.displayMeetingInfoModal = false;
    }
    clickOffScheduleModalEvent(event: Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        switch (value) {
            case 'meeting-info-modal': {
                this.closeMeetingInfoModal();
                break;
            }
        }
    }

    // 🚧 Meeting options dropdown ... 
    // on (click) 
    showMeetingOptionsDropdown(dayIdx: number, eventIdx: number, e?: Event) {
        const options = <HTMLElement>document.getElementById(`meeting-options-dropdown-${dayIdx}-${eventIdx}`);
        options.classList.add('active');

        // also hide scrollbar ... 
        let scrollBox = <HTMLElement>document.getElementById('ndiaScrollbox');
        scrollBox.classList.add('hidden');
    }

    // on (mouseleave) 
    hideMeetingOptionsDropdown(dayIdx: number, eventIdx: number, e?: Event) {
        const options = <HTMLElement>document.getElementById(`meeting-options-dropdown-${dayIdx}-${eventIdx}`);
        options.classList.remove('active');

        let scrollBox = <HTMLElement>document.getElementById('ndiaScrollbox');
        scrollBox.classList.remove('hidden');
    }
}








    //For Join Call ... ❓ do we need this ... idts ... 
    // joinChannelCall(e: Event) {
    //     this.prevDefStopProp(e);
    //     this._channelService.jitsiSelfJoinEvent(this.incomingChannelObject.channelId);
    // }