// Main core modules 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';


// components (unused) ❌ needs review | removal ... 
// import { FormBuilderModule } from '../form-builder/form-builder.module';
// import { FormComponent } from '../form-builder/form/form.component';
// import { FormViewerHomeComponent } from '../form-builder/form-viewer-home/form-viewer-home.component';
// import { ViewerComponent } from '../form-builder/viewer/viewer.component';
// import { BuilderComponent } from '../form-builder/builder/builder.component';


import { KurentoVideoComponent } from './components/kurento-video/kurento-video.component';
import { MikeKurentoComponent } from './components/mike-kurento/mike-kurento.component';
import { TwilioVideoComponent } from './components/twilio-video/twilio-video.component';
import { AmpPlayerComponent } from './components/amp-player/amp-player.component';
import { RTCVideoComponent } from './components/rtc-video/rtc-video.component';
import { StreamErrorsComponent } from './components/rtc-video/stream-errors/stream-errors.component';// for rtc 
import { DeviceCheckComponent } from './components/device-check/device-check.component';// for rtc 


// components
import { ChatComponent } from './components/chat-component/chat.component';
import { JitsiVideoComponent } from './components/jitsi-video/jitsi-video.component';
import { LiveStreamingVideoComponent } from './components/live-streaming-video/live-streaming-video.component';
import { EmojiButtonComponent } from './components/emoji-button/emoji-button.component';
import { ProfileImgUploadComponent } from './components/profile-img-upload/profile-img-upload.component';
import { FileStorageComponent } from './components/file-storage/file-storage.component';
import { ConnectionOverlayComponent } from './components/connection-overlay/connection-overlay.component';

// ❌ possible memory leaks ... is in chat component ... 
import { TimeDifferenceComponent } from './components/time-difference/time-difference.component';
// ... 


// pipes
import { ByteConvertPipe } from '../pipes/byte-convert.pipe';
import { FolderNamePipe } from '../pipes/folder-name.pipe';
import { FileNamePipe } from '../pipes/file-name.pipe';
import { FaxFolderNamePipe } from '../pipes/fax-folder-name.pipe';
import { FormatPhoneNumberPipe } from '../pipes/format-phone-number.pipe';
import { MessageWithLinksPipe } from '../pipes/message-with-links';
import { MessageWithImagePipe } from '../pipes/message-with-image';
import { HighlightSearch } from '../pipes/highlight.pipe';
import { ChannelFolderPipe } from '../pipes/channel-folder.pipe';
import { ChannelCardAvitars } from '../pipes/channel-card-avatars.pipe';
import { MeetingDurationPipe } from '../pipes/meeting-duration.pipe';
import { ChannelOwnerComparePipe } from '../pipes/channel-owner-compare.pipe';
import { UserStatusNamePipe } from '../pipes/user-status-name.pipe';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { CheckInputLengthPipe } from '../pipes/check-input-length.pipe';
import { lastMessagePipe } from '../pipes/last-message.pipe';
import { StatusDisplayPipe } from '../pipes/status-display.pipe';
import { DefaultImageOverlayPipe } from '../pipes/default-image-overlay.pipe';
import { CompareStringsPipe } from '../pipes/compare-strings-pipe';
import { MediaWidthCheckPipe } from '../pipes/media-width-check.pipe';
import { QuickmeetingChannelNamePipe } from '../pipes/quickmeeting-channel-name.pipe';
import { TestListLengthPipe } from '../pipes/test-list-length.pipe';


// Angular Material @12.2.8 = https://material.angular.io/ 
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay'; // not used yet is for popovers...


// Angular Material Timepicker = https://agranom.github.io/ngx-material-timepicker/
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


// 🚧 new 10/4/2021 ...
import { EventsComponent } from './components/events/events.component';
import { ScheduleMeetingComponent } from './components/schedule-meeting/schedule-meeting.component';
import { MeetingInfoComponent } from './components/meeting-info/meeting-info.component';

import { ChannelsHistoryComponent } from './components/channels-history/channels-history.component';
import { CreateChannelComponent } from './components/create-channel/create-channel.component';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { ChannelInfoComponent } from './components/channel-info/channel-info.component';
import { ManageUsersComponent } from '../channel-app/manage-users/manage-users.component';

import { ContactsComponent } from './components/contacts/contacts.component';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';

import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertsSettingsComponent } from './components/alerts-settings/alerts-settings.component';

import { GuestSignOutComponent } from './components/guest-sign-out/guest-sign-out.component';

// new loading spinner for home columns 
import { LoadingHomeComponent } from './components/loading-home/loading-home.component';

import { NgxEditorModule } from 'ngx-editor';

import { AccountEmailInviteComponent } from './components/account-email-invite/account-email-invite.component';

import { ModalComponent } from './modal/modal.component';


export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 400,
  hideDelay: 100,
  touchGestures: 'off',
  touchendHideDelay: 0
};



@NgModule({
  declarations: [
    AmpPlayerComponent,
    ChatComponent,
    RTCVideoComponent,
    TimeDifferenceComponent,
    StreamErrorsComponent,
    DeviceCheckComponent,
    MikeKurentoComponent,
    TwilioVideoComponent,
    JitsiVideoComponent,
    LiveStreamingVideoComponent,
    KurentoVideoComponent,
    EmojiButtonComponent,
    ProfileImgUploadComponent,
    ByteConvertPipe,
    FolderNamePipe,
    FileNamePipe,
    lastMessagePipe,
    FaxFolderNamePipe,
    FormatPhoneNumberPipe,
    MessageWithLinksPipe,
    MessageWithImagePipe,
    ChannelFolderPipe,
    ConnectionOverlayComponent,
    HighlightSearch,
    FileStorageComponent,
    EventsComponent,
    ChannelsHistoryComponent,
    ContactsComponent,
    AlertsComponent,
    ScheduleMeetingComponent,
    ChannelCardAvitars,
    MeetingInfoComponent,
    ChannelInfoComponent,
    MeetingDurationPipe,
    ChannelOwnerComparePipe,
    UserStatusNamePipe,
    CreateChannelComponent,
    CreateContactComponent,
    ContactInfoComponent,
    AlertsSettingsComponent,
    TimeAgoPipe,
    CheckInputLengthPipe,
    LoadingHomeComponent,
    ManageUsersComponent,
    CreateProjectComponent,
    StatusDisplayPipe,
    DefaultImageOverlayPipe,
    CompareStringsPipe,
    MediaWidthCheckPipe,
    QuickmeetingChannelNamePipe,
    GuestSignOutComponent,
    AccountEmailInviteComponent,
    TestListLengthPipe,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatTooltipModule,
    MatRippleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
    NgxEditorModule,
    NgxMaterialTimepickerModule,
    MatExpansionModule,
    MatCheckboxModule,
    OverlayModule,
  ],
  exports: [
    AmpPlayerComponent,
    ChatComponent,
    RTCVideoComponent,
    CommonModule,
    TimeDifferenceComponent,
    DeviceCheckComponent,
    MikeKurentoComponent,
    TwilioVideoComponent,
    JitsiVideoComponent,
    LiveStreamingVideoComponent,
    KurentoVideoComponent,
    EmojiButtonComponent,
    ProfileImgUploadComponent,
    ByteConvertPipe,
    FolderNamePipe,
    FileNamePipe,
    lastMessagePipe,
    FaxFolderNamePipe,
    FormatPhoneNumberPipe,
    MessageWithLinksPipe,
    MessageWithImagePipe,
    CompareStringsPipe,
    FileStorageComponent,
    ChannelFolderPipe,
    ConnectionOverlayComponent,
    HighlightSearch,
    EventsComponent,
    ChannelsHistoryComponent,
    ContactsComponent,
    AlertsComponent,
    ScheduleMeetingComponent,
    ChannelCardAvitars,
    MeetingInfoComponent,
    ChannelInfoComponent,
    MeetingDurationPipe,
    ChannelOwnerComparePipe,
    UserStatusNamePipe,
    MatTooltipModule,
    MatRippleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgxEditorModule,
    ContactInfoComponent,
    TimeAgoPipe,
    MatSlideToggleModule,
    MatSelectModule,
    CheckInputLengthPipe,
    LoadingHomeComponent,
    ManageUsersComponent,
    CreateChannelComponent,
    CreateContactComponent,
    AlertsSettingsComponent,
    CreateProjectComponent,
    StatusDisplayPipe,
    DefaultImageOverlayPipe,
    MediaWidthCheckPipe,
    QuickmeetingChannelNamePipe,
    GuestSignOutComponent,
    MatExpansionModule,
    MatCheckboxModule,
    AccountEmailInviteComponent,
    OverlayModule,
    TestListLengthPipe,
    ModalComponent,
  ],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults},
    { provide: 'rippleColor', useValue: 'var(--mat-ripple-color)' }, 
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ]
})
export class SharedModule { }
