import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContactsService } from 'src/app/services/contacts.service';
import { SubscriptionPlan } from 'src/app/services/subcription.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ChannelUser } from '../../interface/channel-user';
import { consoleLogStyle, standardEmailRegex } from '../../varaibles';



@Component({
    selector: 'account-email-invite',
    templateUrl: './account-email-invite.component.html',
    styleUrls: ['./account-email-invite.component.scss']
})
export class AccountEmailInviteComponent implements OnInit, OnDestroy {
    @Input("userInfo") userInfo: ChannelUser;
    @Input("subscriptionPlan") subscriptionPlan: SubscriptionPlan;

    @Output("formTouchedEvent") formTouchedEvent = new EventEmitter();
    @Output("onCompleteEvent") onCompleteEvent = new EventEmitter();
    public formTouched: boolean = false;

    public emailList: any = [];
    public newEmail: FormGroup;

    public isAdding: boolean = false;
    public isSending: boolean = false;
    public hasWarnings: boolean = false;
    public hasMaxUsersSub: boolean = false;
    public maxInvites: number = 10;

    private subscriptions: Subscription[] = [];

    constructor(
        public _toastService: ToastNotificationService,
        public _contactsService: ContactsService,

    ) { }

    ngOnInit(): void {
        // console.info("sub plan?", this.subscriptionPlan)
        this.newEmail = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                // Validators.minLength(4),
                // Validators.maxLength(256),
                // Validators.email
            ]),
        });

        this.subscriptions.push(
            this.newEmail.valueChanges
                .subscribe(data => {
                    this.emitTouched();
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    get emailControl() { return this.newEmail.get('email'); }

    public addEmailsToList() {
        this.isAdding = true;
        let emailString = this.newEmail.value.email;
        emailString = emailString.split(' ').filter(email => email !== /\s+/ && email !== "");
        for (let email of emailString) {
            // console.log(email);
            if (this.emailList.length < this.maxInvites) {
                this.emailList.push(this.emailProperties(email));
            } else {
                break;
            }
        }
        this.checkForWarning();
        this.setEmailInputStatus();
        this.newEmail.reset();
        this.isAdding = false;
    }

    private emailProperties(email: string) {
        let emailObject = { email: email, warning: true, message: null };
        if (email.length > 256) {
            emailObject.message = "this email is too long";
        } else if (this.checkIfExists(email)) {
            emailObject.message = "this is a duplicate email";
        } else if (!standardEmailRegex.test(email)) {
            emailObject.message = "this email may not be valid";
        } else {
            emailObject.warning = false;
        }
        return emailObject;
    }

    public removeEmailFromList(i) {
        this.emailList.splice(i, 1);
        this.checkForWarning();
        this.setEmailInputStatus();
    }

    public removeAllWarnings() {
        // console.table("emailList", this.emailList);
        this.emailList = this.emailList.filter(emailObj => !emailObj.warning);
        this.checkForWarning();
        this.setEmailInputStatus();
    }

    public sendEmails() {
        this.isSending = true;
        let emails = this.emailList.map(emailObj => emailObj.email);
        this._contactsService.sendTenantInvitesByEmail(emails)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log(res);
                    this._toastService.success("Invites have been successfully sent out");
                } else {
                    // console.log(res);
                    this._toastService.error("A problem occured while sending emails");
                }
                this.emailList = [];
                this.emailControl.enable();
                this.hasWarnings = false;
                this.resetTouched();
                this.isSending = false;
            });
    }

    private checkIfExists(emailName: string) {
        return this.emailList.some(emailObj => emailObj.email === emailName);;
    }

    private checkForWarning() {
        // console.info("%c email list len | sub.remainingUsers ", consoleLogStyle, this.emailList.length, this.subscriptionPlan.remainingUsers)
        this.hasWarnings = this.emailList.some(emailObj => emailObj.warning === true);
        // add list len check based on plan.remainingUsers ...
        if(this.subscriptionPlan.chargifyProductHandle !== 'enterprise' && 
            this.subscriptionPlan.canAddUsers && 
            (this.subscriptionPlan.remainingUsers <= this.emailList.length)) {
            // console.info("%c email list len | sub.remainingUsers ", consoleLogStyle, this.emailList.length, this.subscriptionPlan.remainingUsers)
            // this.hasWarnings = true;
            this.hasMaxUsersSub = true;
        } else {
            this.hasWarnings = false;
            this.hasMaxUsersSub = false;
        }
    }

    private setEmailInputStatus() {
        if (this.emailList.length >= this.maxInvites) {
            this.emailControl.disable();
        } else {
            this.emailControl.enable();
        }
    }

    private emitTouched() {
        if (!this.formTouched) {
            this.formTouched = true;
            // console.log('Form Touched Emiited');
            this.formTouchedEvent.emit(true);
        }
    }

    private resetTouched() {
        this.formTouched = false;
        this.formTouchedEvent.emit(false);
    }
}
