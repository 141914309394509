import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { VdjjVideoIntakeAppRoutingModule } from './vdjj-video-intake-app-routing.module';
import { VdjjVideoIntakeHomepageComponent } from './vdjj-video-intake-homepage/vdjj-video-intake-homepage.component';
import { VdjjVideoIntakeChatAOComponent } from './vdjj-video-intake-chat-ao/vdjj-video-intake-chat-ao.component';
import { SharedModule } from '../shared/shared.module';
import { VdjjVideoIntakeChatIoComponent } from './vdjj-video-intake-chat-io/vdjj-video-intake-chat-io.component';


@NgModule({
  declarations: [
    VdjjVideoIntakeHomepageComponent,
    VdjjVideoIntakeChatAOComponent,
    VdjjVideoIntakeChatIoComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    VdjjVideoIntakeAppRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    VdjjVideoIntakeChatAOComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdjjVideoIntakeAppModule { }
