<!-- flex-row-wrap && flex-column-wrap are not global css -->

<h3 class="m-3 page-title">Buttons</h3>
<div class="mx-3 mb-4 flex-row-wrap">
    <button class="primary-button" type="button" [disabled]="disableButtons">
        Primary
    </button>
    <button class="secondary-button" type="button" [disabled]="disableButtons">
        Secondary
    </button>
    <button class="cancel-button" type="button" [disabled]="disableButtons">
        Cancel
    </button>
    <button class="outline-button" type="button" [disabled]="disableButtons">
        Outline
    </button>
</div>

<h3 class="m-3 page-title">Interactive Controllers</h3>
<div class="mx-3 mb-4 flex-row-wrap">
    <button
        type="button"
        (click)="toggleDisableButtons()"
        class="mb-3 primary-button"
    >
        Disable Buttons Toggle
    </button>
    <button (click)="showModal()" class="mb-3 primary-button" type="button">
        Modal toggle
    </button>
    <button
        class="mb-3 primary-button"
        type="button"
        matTooltip="Tool Tip Example"
        matTooltipClass="tooltip-default"
    >
        Tool Tip Hover
    </button>
    <button class="mb-3 primary-button" type="button" (click)="toastExamples()">
        Toast Examples
    </button>
</div>

<h3 class="m-3 page-title">Form Items</h3>
<div class="mx-3 mb-4 flex-column-wrap max-width-set">
    <!-- Basic Inputs -->
    <h4 class="mb-3 form-title">Inputs</h4>
    <input
        title="Borderless Input"
        type="text"
        name="borderlessInput"
        class="borderless-input"
        placeholder="Borderless Input"
        maxlength="50"
    />
    <input
        matInput
        title="Borderless Input"
        type="text"
        name="borderlessInput"
        class="borderless-input"
        placeholder="matInput"
        maxlength="50"
    />
    <!-- <mat-form-field class="clear-mat-form-field" title="Borderless Input">
        <input
            matInput
            type="text"
            name="borderlessInput"
            class="borderless-input"
            placeholder="matInput with mat-form-field"
            maxlength="50"
        />
    </mat-form-field> -->

    <!-- Date Inputs -->
    <h4 class="mt-4 mb-3 form-title">Date Inputs</h4>
    <!-- <label class="mb-3 outline-datepicker">
        <span> Date Label: </span>
        <input class="default-datepicker" title="Start Date" type="date" name="startDate" />
    </label> -->
    <label class="mb-3 outline-datepicker" title="Outline Datepicker">
        <span> Date Label: </span>
        <input
            matInput
            [(ngModel)]="todaysDate"
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="datepicker"
            placeholder="mm/ dd/ yyyy"
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
    </label>

    <!-- Time Inputs -->
    <h4 class="mt-4 mb-3 form-title">Time Inputs</h4>
    <label class="mb-3 outline-timepicker" title="Outline Datepicker">
        <span> Time Label: </span>
        <input
            aria-label="default time"
            [ngxTimepicker]="timepicker"
            placeholder="Time"
            [value]="'11:55 PM'"
            [format]="12"
            readonly
        />
        <ngx-material-timepicker #timepicker></ngx-material-timepicker>
    </label>

    <!-- Radio Inputs -->
    <h4 class="mt-4 mb-3 form-title">Radio Inputs</h4>
    <label class="mb-3 outline-radio">
        <input [(ngModel)]="radio" type="radio" name="radio1" value="radio1" />
        <p class="input-name">Outline Radio</p>
    </label>
    <label class="mb-3 circle-radio">
        <input [(ngModel)]="radio" type="radio" name="radio2" value="radio2" />
        <span class="input-icon">
            <i class="fas fa-plus"></i>
        </span>
        <p class="input-name">Circle Radio</p>
    </label>
    <!-- mat radio -->
    <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mb-3"
        [(ngModel)]="radio"
    >
        <mat-radio-button class="mb-3" value="radio3">
            Angular Material Radio
        </mat-radio-button>
    </mat-radio-group>

    <!-- Checkbox Inputs -->
    <h4 class="mt-4 mb-3 form-title">Checkboxes</h4>
    <label
        class="mb-3 outline-circle-checkbox"
        matTooltip="Outline Circle Checkbox"
        matTooltipClass="tooltip-default"
    >
        <input type="checkbox" name="checkbox1" />
        <i class="fas fa-skull input-icon"></i>
    </label>

    <!-- Input Group -->
    <h4 class="mt-4 mb-3 form-title">Input Group</h4>
    <div class="borderless-input-group">
        <input
            title="Input Group"
            type="text"
            name="inputGroup"
            class="borderless-input"
            placeholder="Borderless Input Group"
            maxlength="50"
        />
        <button type="button" class="input-button" (click)="showSelectModal()">
            <i class="fas fa-search"></i>
        </button>

        <div class="custom-select-list" *ngIf="this.displaySelectModal">
            <div class="list-item">Custom Select Item 1</div>
            <div class="list-item">Custom Select Item 2</div>
            <div class="list-item">Custom Select Item 3</div>
        </div>
    </div>

    <!-- Selects -->
    <h4 class="mt-4 mb-3 form-title">Selects</h4>
    <!-- <select class="mb-3 borderless-select" name="borderlessSelect">
        <option disabled selected value class="hidden-value">
            Borderless Select
        </option>
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
    </select> -->

    <mat-select
        class="mb-3 borderless-select"
        name="borderlessSelect"
        placeholder="Borderless Select"
    >
        <mat-option disabled selected value class="hidden-value">
            No Value Selected
        </mat-option>
        <mat-option value="option1"> Option 1 </mat-option>
        <mat-option value="option2"> Option 2 </mat-option>
        <mat-option value="option3"> Option 3 </mat-option>
    </mat-select>

    <!-- <label class="mb-3 outline-select" for="selectRound">
        <span for="selectRound"> Select Label: </span>
        <select name="selectRound">
            <option disabled selected value class="hidden-value">
                Outline Select
            </option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
        </select>
    </label> -->

    <label class="mb-3 outline-select" for="selectRound">
        <span for="selectRound"> Select Label: </span>
        <mat-select name="selectRound" placeholder="Outline Select">
            <mat-option disabled selected value class="hidden-value">
                No Value Selected
            </mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
        </mat-select>
    </label>

    <!-- TextArea Inputs -->
    <h4 class="mt-4 mb-3 form-title">Textareas</h4>
    <div class="mb-3 box-quote">
        <textarea
            matInput
            title="Box Quote Textarea"
            name="boxquote"
            class="box-quote-textarea scrollbar-new"
            placeholder="Box Quote Textarea"
        ></textarea>
        <i class="quote-icon fas fa-quote-right flex-center-all"></i>
    </div>
</div>

<div class="mx-3 mb-4 flex-column-wrap">
    <h3 class="mb-3 form-title">Form Example</h3>
    <form
        class="primary-form-layout max-width-set"
        [formGroup]="formGroupExample"
    >
        <!--Form Item Group Div Optional for seperating inputs into groups-->
        <div class="form-item-group">
            <input
                title="Input Text Example"
                type="text"
                name="inputTextExample"
                class="mb-3 borderless-input"
                placeholder="Input Text Example"
                maxlength="50"
                formControlName="inputTextExample"
            />

            <input
                title="Input Text Example Required"
                type="text"
                name="inputTextExampleRequired"
                class="mb-3 borderless-input required"
                placeholder="Input Text Example* (required field)"
                maxlength="50"
                minlength="2"
                formControlName="inputTextExampleRequired"
                required
            />

            <select
                class="mb-4 borderless-select"
                formControlName="selectExample"
                name="selectExample"
                title="Borderless Select"
                [class.pre-select]="!formControls.selectExample.value"
            >
                <option disabled selected value class="hidden-value">
                    Borderless Select
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
            </select>
        </div>
        <div class="mt-4 group-items-center">
            <button
                class="primary-button"
                type="button"
                (click)="formSubmitted()"
            >
                Submit
            </button>
        </div>
    </form>
</div>

<!-- Modal Example -->
<div
    *ngIf="displayModal"
    class="modal"
    id="modal-id"
    (click)="clickOffModalEvent($event)"
>
    <div class="modal-container">
        <!-- Form Example -->
        <h3 class="mb-4 form-title">Modal Title</h3>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In aliquet
            justo in justo euismod, vel varius sapien commodo. Aenean at justo
            fringilla, posuere eros vel, fringilla lorem. Curabitur ac fermentum
            massa, a euismod ex. Suspendisse nec libero vitae dolor condimentum
            vestibulum. Mauris non sapien ac mi iaculis malesuada. Suspendisse
            laoreet scelerisque ante, vel pellentesque risus aliquet vitae. Sed
            maximus euismod ipsum, quis viverra lacus finibus at. Nulla est
            mauris, malesuada ac mauris a, interdum sodales quam. Suspendisse
            condimentum aliquam faucibus. Maecenas at scelerisque felis.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Donec auctor consectetur elit, sed faucibus
            urna mollis quis. Morbi id metus massa. Vestibulum libero turpis,
            porttitor a odio sit amet, suscipit fermentum arcu. Morbi enim
            lectus, molestie sit amet velit quis, pulvinar fermentum sem.
        </p>
        <div class="mt-4 group-items-right">
            <button class="primary-button" type="button">test</button>
        </div>
    </div>
</div>

<!-- select modal backdrop -->
<ng-container *ngIf="displaySelectModal">
    <div (click)="closeSelectModal()" class="hidden-backdrop-modal"></div>
</ng-container>

<h3 class="m-3 page-title">Text Displays</h3>
<div class="mx-3 mb-4 flex-column-wrap max-width-set">
    <p class="me-2 mb-1">Box Quote Text:</p>
    <div class="box-quote active-quote mb-2">
        <i class="quote-icon fas fa-quote-right flex-center-all"></i>
        <div class="box-quote-text scrollbar-new">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In aliquet
            justo in justo euismod, vel varius sapien commodo. Aenean at justo
            fringilla, posueree eros vel, fringilla lorem. Curabitur ac
            fermentum massa, a euismod ex. Suspendisse nec libero vitae dolor
            condimentum vestibulum. Mauris non sapien ac mi iaculis malesuada.
        </div>
    </div>
</div>

<div class="mx-3 mb-4 flex-column-wrap max-width-set">
    <!-- <quill-editor class="content-editor" [placeholder]="''" [modules]="quillConfig"> </quill-editor> -->
</div>
