<div
    class="col fs-main-wrap"
    (window:resize)="handleScreenCheck()"
    (resize)="handleScreenCheck()"
>
    <div class="fs-view-wrap" [class.p-2]="!isSideBar">
        <!-- YOUR FAXES VIEW -->
        <div class="fs-view-your-files">
            <!-- title row -->
            <div class="row align-baseline">
                <!-- link to previous folder if in sub folder -->
                <button
                    *ngIf="currentFolderArray.length > 1"
                    (click)="goBack()"
                    @fadeIn
                    class="back-button"
                >
                    <span class="fs-button back-icon">
                        <i class="fas fa-arrow-left fs-icon"></i>
                    </span>
                </button>
                <div
                    *ngIf="currentFolderArray.length === 1"
                    class="back-button-placeholder"
                >
                    <span class="back-icon">
                        <i class="fas fa-arrow-left"></i>
                    </span>
                </div>
                <!-- displays current folder -->
                <div
                    title="{{
                        currentFolderArray[currentFolderArray.length - 1]
                            .urlPath
                    }}"
                    @fadeIn
                    class="current-folder-tag"
                >
                    {{
                        currentFolderArray[currentFolderArray.length - 1]
                            .fileName | faxFolderName
                    }}
                    <span
                        *ngIf="isFaxesDisplayed"
                        class="current-fax-folder-tag"
                    >
                        <span>/ </span>
                        {{ folderList[0].faxNumber | formatPhoneNumber }}
                    </span>
                </div>
                <div *ngIf="isDesktop" @fadeIn class="fs-time">
                    <i class="fas fa-calendar-day"></i>
                    {{ formattedCurrentDateTime }}
                </div>
                <!-- <div
                    *ngIf="!isDesktop"
                    class="bottom-buttons-group-right">
                    fax settings modal
                    <button
                        class="fs-button"
                        (click)="showFaxSettings()"
                        title="Open fax settings">
                        <i class="fas fa-cog fax-settings-btn fs-icon"></i>
                    </button>
                </div> -->
            </div>
            <!-- search row -->
            <div class="row search-row">
                <!-- re-fresh button -->
                <button
                    (click)="
                        isFaxesDisplayed
                            ? displayReceivedFaxesComponent
                                ? _callFileApi(false)
                                : _getSentFaxes()
                            : getFaxAccounts()
                    "
                    *ngIf="!isFilesLoading"
                    class="fax-tab-button fs-button"
                    matTooltip="Refresh Fax List"
                    matTooltipClass="tooltip-default"
                    [matTooltipShowDelay]="tooltipDelay"
                >
                    <i
                        [class.re-freshing]="isFilesLoading"
                        [class.spinner]="isFilesLoading"
                        class="fas fa-sync-alt sort-icon fs-icon"
                    ></i>
                </button>
                <button
                    *ngIf="isFilesLoading"
                    class="fax-tab-button fs-button loading-spinner"
                    title="refresh"
                >
                    <i
                        [class.re-freshing]="isFilesLoading"
                        [class.spinner]="isFilesLoading"
                        class="fas fa-sync-alt sort-icon fs-icon"
                    ></i>
                </button>
                <!-- Show Received Faxes button -->
                <button
                    (click)="
                        !displayReceivedFaxesComponent
                            ? showReceivedFaxes()
                            : ''
                    "
                    *ngIf="isFaxesDisplayed"
                    class="fax-tab-button fs-button"
                    [class.desktop]="isDesktop"
                    matTooltip="{{
                        displayReceivedFaxesComponent
                            ? 'Showing Received Faxes'
                            : 'Show Received Faxes'
                    }}"
                    matTooltipClass="tooltip-default"
                    [matTooltipShowDelay]="tooltipDelay"
                >
                    <i
                        class="fas fa-file-download fs-button"
                        [class.active-fax-tab]="displayReceivedFaxesComponent"
                    ></i>
                    <span
                        *ngIf="!isSideBar && isDesktop"
                        [class.active-fax-tab]="displayReceivedFaxesComponent"
                    >
                        RECEIVED
                    </span>
                </button>
                <!-- Show Sent Faxes button -->
                <button
                    (click)="
                        displayReceivedFaxesComponent ? showSentFaxes() : ''
                    "
                    *ngIf="isFaxesDisplayed"
                    class="fax-tab-button fs-button"
                    [class.desktop]="isDesktop"
                    matTooltip="{{
                        displayReceivedFaxesComponent
                            ? 'Show Sent Faxes'
                            : 'Showing Sent Faxes'
                    }}"
                    matTooltipClass="tooltip-default"
                    [matTooltipShowDelay]="tooltipDelay"
                >
                    <i
                        class="fas fa-file-upload fs-button"
                        [class.active-fax-tab]="!displayReceivedFaxesComponent"
                    ></i>
                    <span
                        *ngIf="!isSideBar && isDesktop"
                        [class.active-fax-tab]="!displayReceivedFaxesComponent"
                    >
                        SENT
                    </span>
                </button>
                <!-- -->
                <!-- Send a Fax Button -->
                <div class="send-fax-button-group">
                    <button
                        class="send-fax-label fs-button"
                        matTooltip="Send A Fax"
                        matTooltipClass="tooltip-default"
                        [matTooltipShowDelay]="tooltipDelay"
                        (click)="toggleSendFaxModal()"
                    >
                        SEND A FAX
                        <i @fadeIn class="fas fa-print send-fax-icon"> </i>
                    </button>
                </div>
            </div>
            <!-- search row -->
            <!-- <div class="row search-row">
                <div class="search-input-group">
                    <i
                        [class.search-icon-open]="displaySearchFilesInput"
                        (click)="showSearchFilesInput()"
                        @fadeIn
                        title="search"
                        class="fas fa-search search-icon fs-button">
                    </i>
                    <input
                        *ngIf="displaySearchFilesInput"
                        @fadeIn
                        class="search-input"
                        id="searchInput"
                        (input)="searchFiles($event)"
                        type="text"
                        maxlength="249"
                        placeholder="Search">
                    <i
                        id="searchClose"
                        *ngIf="displaySearchFilesInput && isSearching"
                        (click)="clearSearch()"
                        @rotateInOut
                        class="fas fa-search search-close">
                    </i>
                </div>
                <div
                    *ngIf="isDesktop"
                    class="bottom-buttons-group-right">
                    fax settings modal
                    <button
                        class="fs-button"
                        (click)="showFaxSettings()"
                        title="Open fax settings">
                        <i class="fas fa-cog fax-settings-btn fs-icon"></i>
                    </button>
                </div>
            </div> -->
            <!-- DISPLAY FOR CURRENT FILES -->
            <div
                class="row file-display-wrap"
                [class.desktop]="isDesktop"
                [class.is-sidebar]="isSideBar"
            >
                <!-- table view DESKTOP ONLY -->
                <div *ngIf="isDesktop" class="row fs-table-head">
                    <div
                        *ngIf="displayReceivedFaxesComponent"
                        class="fs-table-title fs-table-name"
                    >
                        Name
                    </div>
                    <div
                        @fadeIn
                        *ngIf="
                            isFaxesDisplayed && !displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-name"
                    >
                        Filename
                    </div>
                    <div
                        @fadeIn
                        *ngIf="!isFaxesDisplayed"
                        class="fs-table-title fs-table-owners"
                    >
                        Owners
                    </div>
                    <div
                        @fadeIn
                        *ngIf="
                            isFaxesDisplayed && !displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-owners"
                    >
                        Sent By
                    </div>
                    <div
                        @fadeIn
                        *ngIf="
                            isFaxesDisplayed && !displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-owners"
                    >
                        Sent To
                    </div>
                    <!-- Removal of Delivery Status column; using status dots too show ...
                        <div
                        @fadeIn
                         *ngIf="
                        isFaxesDisplayed && !displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-owners"
                    >
                        Delivery Status
                    </div>
                    -->
                    <div
                        @fadeIn
                        *ngIf="
                            isFaxesDisplayed && displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-owners"
                    >
                        Shared With
                    </div>
                    <div
                        @fadeIn
                        *ngIf="
                            isFaxesDisplayed && !displayReceivedFaxesComponent
                        "
                        class="fs-table-title fs-table-owners"
                    >
                        Sent Date
                    </div>
                    <div
                        *ngIf="displayReceivedFaxesComponent"
                        class="fs-table-title"
                    >
                        Last Modified
                    </div>
                    <div
                        *ngIf="displayReceivedFaxesComponent"
                        class="fs-table-title fs-table-size"
                    >
                        Pages
                    </div>
                    <div
                        *ngIf="!displayReceivedFaxesComponent"
                        class="fs-table-title fs-table-pages-sent"
                    >
                        Pages
                    </div>
                    <div class="fs-table-title">Actions</div>
                    <div class="fs-table-title fs-table-info"></div>
                </div>
                <div
                    (scroll)="scrollCheck()"
                    id="fileScrollbox"
                    class="col file-display scrollbar-new"
                    [@listAnimationWrap]="filesList.length"
                >
                    <!-- messages -->
                    <div
                        *ngIf="
                            !isFilesLoading &&
                            !isSearching &&
                            filesList.length === 0
                        "
                        @fadeInFast
                        class="file-display-message-warn"
                    >
                        You do not have any faxes.
                        <!-- <button class="fs-button" (click)="showFaxSettings()">
                            here
                        </button> -->
                    </div>
                    <div
                        *ngIf="
                            !isFilesLoading &&
                            isSearching &&
                            filesList.length === 0
                        "
                        @fadeInFast
                        class="file-display-message"
                    >
                        No match found in this folder.
                    </div>
                    <!-- files loading spinner -->
                    <div *ngIf="isFilesLoading" @fadeInFast class="m-auto">
                        <!-- 🚧 new loading -->
                        <loading-home></loading-home>
                    </div>
                    <!-- end messages -->
                    <!-- each file in result -->
                    <div
                        *ngFor="let file of filesList; index as i; last as last"
                        @listAnimationItem
                        (click)="selectFile(i)"
                        (dblclick)="
                            openFolder(file.isFile, file.chatRoomChatUsers)
                        "
                        class="row file-item selected-file"
                        [class.unread]="
                            file.created >= file.lastActivity && file.isFile
                        "
                        [class.desktop]="isDesktop"
                        [class.mobile]="
                            selectedFile === filesList[i] && !isDesktop
                        "
                    >
                        <!-- File Name column DESKTOP & MOBILE (Sent Faxes) -->
                        <div
                            *ngIf="!displayReceivedFaxesComponent"
                            class="file-name flex"
                            [ngClass]="{
                                'fs-table-info-col fs-table-filename': isDesktop
                            }"
                        >
                            <i
                                *ngIf="
                                    file.status !== 'Failed' &&
                                    file.status !== 'Sent'
                                "
                                class="fas fa-circle circle-icon-fax-processing"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                                matTooltip="{{ file.status }}"
                                matTooltipClass="tooltip-default"
                                [matTooltipShowDelay]="tooltipDelay"
                            >
                            </i>
                            <i
                                *ngIf="file.status === 'Failed'"
                                class="fas fa-circle circle-icon-fax-failed"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                                matTooltip="Failed: {{ file.errorCode }}"
                                matTooltipClass="tooltip-default"
                                [matTooltipShowDelay]="tooltipDelay"
                            >
                            </i>
                            <i
                                *ngIf="file.status === 'Sent'"
                                class="fas fa-circle circle-icon-fax-sent"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                                matTooltip="{{ file.status }}"
                                matTooltipClass="tooltip-default"
                                [matTooltipShowDelay]="tooltipDelay"
                            >
                            </i>
                            <!--
                            <i
                                *ngIf="file.created == file.lastActivity && file.isFile"
                                class="fas fa-circle circle-icon-unread"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                            >
                            </i>
                            <i
                                *ngIf="file.created < file.lastActivity || !file.isFile"
                                class="far fa-circle circle-icon-read"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                            ></i>-->
                            <i
                                *ngIf="
                                    file.contentType !== 'faxoutbox' &&
                                    file.isFile &&
                                    file.favoriteBlobFiles &&
                                    file.favoriteBlobFiles.length < 1
                                "
                                class="far fa-star star-icon-outline"
                                (click)="
                                    addFavorite(file.blobFileId, i, $event)
                                "
                                (dblclick)="preventFileDblClick($event)"
                            >
                            </i>
                            <i
                                *ngIf="
                                    file.contentType !== 'faxoutbox' &&
                                    file.isFile &&
                                    file.favoriteBlobFiles &&
                                    file.favoriteBlobFiles.length > 0
                                "
                                class="fas fa-star star-icon-full"
                                (click)="
                                    removeFavorite(file.blobFileId, i, $event)
                                "
                                (dblclick)="preventFileDblClick($event)"
                            >
                            </i>
                            <span
                                *ngIf="
                                    !file.isFile ||
                                    !displayReceivedFaxesComponent
                                "
                                class="star-icon-hidden"
                                [class.is-sidebar]="isSideBar"
                            >
                            </span>
                            <!--
                            <i *ngIf="file.sharedFilesWith && file.sharedFilesWith[0].isUnread" class="fas fa-circle circle-icon-unread"></i>
                            <i *ngIf="file.sharedFilesWith && !file.sharedFilesWith[0].isUnread" class="far fa-circle circle-icon-read"></i>
                            -->
                            <i
                                *ngIf="file.isFile"
                                class="fad fa-file-alt file-icon"
                            ></i>
                            <i
                                *ngIf="!file.isFile"
                                class="fas fa-folder folder-icon"
                            ></i>
                            &nbsp;
                            <div *ngIf="file.isFile" class="file-name">
                                {{ file.fileName }}
                            </div>
                            <div *ngIf="!file.isFile" class="file-name">
                                {{ file.fileName }}
                            </div>
                        </div>
                        <!-- File Name column DESKTOP & MOBILE -->
                        <div
                            *ngIf="displayReceivedFaxesComponent"
                            title="{{ file.urlPath }}"
                            class="file-name flex"
                            [ngClass]="{
                                'fs-table-info-col fs-table-name': isDesktop
                            }"
                        >
                            <!--<i
                                *ngIf="
                                    file.created == file.lastActivity &&
                                    file.isFile
                                "
                                class="fas fa-circle circle-icon-unread"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                            >
                            </i>
                            <i
                                *ngIf="
                                    file.created < file.lastActivity ||
                                    !file.isFile
                                "
                                class="far fa-circle circle-icon-read"
                                [class.is-sidebar]="isSideBar && !file.isFile"
                            ></i> -->
                            <i
                                *ngIf="
                                    file.contentType != 'faxoutbox' &&
                                    file.isFile &&
                                    file.favoriteBlobFiles.length < 1
                                "
                                class="far fa-star star-icon-outline"
                                (click)="
                                    addFavorite(file.blobFileId, i, $event)
                                "
                                (dblclick)="preventFileDblClick($event)"
                            >
                            </i>
                            <i
                                *ngIf="
                                    file.contentType != 'faxoutbox' &&
                                    (file.isFile &&
                                        file.favoriteBlobFiles.length) > 0
                                "
                                class="fas fa-star star-icon-full"
                                (click)="
                                    removeFavorite(file.blobFileId, i, $event)
                                "
                                (dblclick)="preventFileDblClick($event)"
                            >
                            </i>
                            <span
                                *ngIf="
                                    !file.isFile ||
                                    displayReceivedFaxesComponent
                                "
                                class="star-icon-hidden"
                                [class.is-sidebar]="isSideBar"
                            >
                            </span>
                            <!--
                            <i *ngIf="file.sharedFilesWith && file.sharedFilesWith[0].isUnread" class="fas fa-circle circle-icon-unread"></i>
                            <i *ngIf="file.sharedFilesWith && !file.sharedFilesWith[0].isUnread" class="far fa-circle circle-icon-read"></i>
                            -->
                            <i
                                *ngIf="file.isFile"
                                class="fad fa-file-alt file-icon"
                            ></i>
                            <i
                                *ngIf="!file.isFile"
                                class="fas fa-folder folder-icon"
                            ></i>
                            &nbsp;
                            <div *ngIf="file.isFile" class="file-name">
                                {{ file.fileName }}
                            </div>
                            <div *ngIf="!file.isFile" class="file-name">
                                {{ file.fileName }}
                            </div>
                        </div>
                        <!-- DESKTOP VIEW ONLY TABLE -->
                        <!-- Owners col -->
                        <div
                            *ngIf="isDesktop && !file.isFile"
                            class="fs-table-info-col owners-col"
                        >
                            <!-- owner AVITARS -->
                            <ng-container
                                *ngIf="
                                    file.chatRoomChatUsers &&
                                    file.chatRoomChatUsers.length > 0
                                "
                            >
                                <div
                                    class="owners-wrap"
                                    [class.selected-folder-img]="
                                        selectedFile === filesList[i]
                                    "
                                    (click)="toggleAddOwnerModal()"
                                    (dblclick)="preventFileDblClick($event)"
                                >
                                    <div
                                        class="owner-length"
                                        title="{{
                                            file.chatRoomChatUsers.length
                                        }} total owners"
                                    >
                                        {{ file.chatRoomChatUsers.length }}
                                    </div>
                                    <ng-container
                                        *ngFor="
                                            let user of file.chatRoomChatUsers;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="user-img"
                                            title="{{
                                                user.chatUserKeyNavigation
                                                    .displayName
                                            }}"
                                        >
                                            <!--  -->
                                            <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                                            <img
                                                src="https://profile-images.azureedge.us/{{
                                                    user.chatUserKeyNavigation
                                                        .hash
                                                }}"
                                                alt="user"
                                            />
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <!-- shared with col -->
                        <div
                            *ngIf="
                                displayReceivedFaxesComponent &&
                                isDesktop &&
                                file.isFile
                            "
                            title="Shared with"
                            class="fs-table-info-col shared-col"
                        >
                            <!-- shared with AVITARS -->
                            <ng-container
                                *ngIf="
                                    file.sharedFilesWith &&
                                    file.sharedFilesWith.length > 0
                                "
                            >
                                <div
                                    class="shared-wrap"
                                    [class.selected-folder-img]="
                                        selectedFile === filesList[i]
                                    "
                                    (click)="toggleShareFaxModal()"
                                    (dblclick)="preventFileDblClick($event)"
                                >
                                    <div
                                        class="owner-length"
                                        title="{{
                                            file.sharedFilesWith.length
                                        }} total owners"
                                    >
                                        {{ file.sharedFilesWith.length }}
                                    </div>
                                    <ng-container
                                        *ngFor="
                                            let user of file.sharedFilesWith;
                                            let i = index
                                        "
                                    >
                                        <div
                                            class="user-img"
                                            title="{{
                                                user.chatUserKeyNavigation
                                                    .displayName
                                            }}"
                                        >
                                            <!--  -->
                                            <!-- https://randomuser.me/api/portraits/thumb/men/{{i}}.jpg -->
                                            <img
                                                src="https://profile-images.azureedge.us/{{
                                                    user.chatUserKeyNavigation
                                                        .hash
                                                }}"
                                                alt="user"
                                            />
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Sent By -->
                        <div
                            *ngIf="
                                isDesktop &&
                                isFaxesDisplayed &&
                                !displayReceivedFaxesComponent
                            "
                            class="fs-table-info-col"
                        >
                            {{ file.chatUserKeyNavigation.displayName }}
                        </div>
                        <!-- Fax number / Sent To -->
                        <div
                            *ngIf="isDesktop && !displayReceivedFaxesComponent"
                            class="fs-table-info-col"
                        >
                            {{ file.faxNumber | formatPhoneNumber }}
                        </div>
                        <!-- Sent Fax Status; removed, using status dots too
                        <div *ngIf="isDesktop && !displayReceivedFaxesComponent && file.status !== 'Failed'"
                            class="fs-table-info-col">
                            {{ file.status }}
                        </div>
                        <div *ngIf="isDesktop && !displayReceivedFaxesComponent && file.status === 'Failed'"
                            class="fs-table-info-col" style="color:red"
                            matTooltip={{file.errorCode}}
                            matTooltipClass="tooltip-default"
                            [matTooltipShowDelay]="tooltipDelay">
                            {{ file.status }}
                        </div>
                        -->
                        <!-- Last modified col -->
                        <div
                            *ngIf="isFaxesDisplayed && isDesktop && displayReceivedFaxesComponent"
                            class="fs-table-info-col"
                        >
                            {{ file.lastUpdated + "Z" | date: "M/d/yy h:mm a" }}
                        </div>
                        <div 
                            *ngIf="!isFaxesDisplayed && isDesktop"
                            class="fs-table-info-col">
                            -- / -- / ---- --:--
                        </div>
                        <div
                            *ngIf="
                                isDesktop &&
                                file.isFile &&
                                !displayReceivedFaxesComponent &&
                                file.sentDate
                            "
                            class="fs-table-info-col"
                        >
                            <!-- was file.sentDate but time seems off, using created date for now -->
                            {{ file.created + 'Z' | date: "M/d/yy h:mm a" }}
                        </div>
                        <div
                            *ngIf="
                                isDesktop &&
                                file.isFile &&
                                !displayReceivedFaxesComponent &&
                                !file.sentDate
                            "
                            class="fs-table-info-col"
                        >
                            -- / -- / ---- --:--
                        </div>
                        <!-- size col -->
                        <div
                            *ngIf="isDesktop && file.isFile"
                            class="fs-table-info-col fs-table-size"
                        >
                            {{ file.pages }}
                        </div>
                        <div
                            *ngIf="isDesktop && !file.isFile"
                            class="fs-table-info-col fs-table-size"
                        >
                            &nbsp;&nbsp;&nbsp;-
                        </div>
                        <!-- options col -->
                        <div
                            *ngIf="isDesktop"
                            class="fs-table-info-col fs-table-options"
                        >
                            <!-- * these buttons will be conditionally rendered if item is selected * -->
                            <div
                                *ngIf="selectedFile === filesList[i]"
                                class="bottom-buttons-group-right"
                            >
                                <!-- add owners btn (!isFile)-->
                                <button
                                    *ngIf="
                                        !selectedFile.isFile &&
                                        selectedFile.fileName !==
                                            'Shared With Me' &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isSharingFax &&
                                        !isUnSharingFax &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm
                                    "
                                    (click)="toggleAddOwnerModal()"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="add owner"
                                >
                                    <i
                                        class="bottom-fax-button fas fa-user-plus fs-icon"
                                    ></i>
                                </button>
                                <span
                                    *ngIf="isAddingOwner"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading"
                                >
                                    <i class="fas fa-spinner spinner"></i>
                                    &nbsp;Adding owner...
                                </span>
                                <span
                                    *ngIf="isRemovingOwner"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading deleting"
                                >
                                    <i
                                        class="fas fa-spinner spinner deleting"
                                    ></i>
                                    &nbsp;Removing owner...
                                </span>
                                <!-- Resend Fax button -->
                                <button
                                    *ngIf="
                                        !displayReceivedFaxesComponent &&
                                        selectedFile.isFile &&
                                        selectedFile.status !== 'processing' &&
                                        selectedFile.status !== 'sending' &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isSharingFax &&
                                        !isUnSharingFax &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        faxChannelId
                                    "
                                    (click)="showReSendFaxConfirmation()"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="resend fax"
                                >
                                    <i class="fas fa-share-all fs-icon"></i>
                                </button>
                                <!-- share fax button -->
                                <button
                                    *ngIf="
                                        displayReceivedFaxesComponent &&
                                        selectedFile.isFile &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isSharingFax &&
                                        !isUnSharingFax &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        faxChannelId
                                    "
                                    (click)="toggleShareFaxModal()"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="share fax"
                                >
                                    <i class="fas fa-share-alt fs-icon"></i>
                                </button>
                                <span
                                    *ngIf="isSharingFax"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading"
                                >
                                    <i class="fas fa-spinner spinner"></i>
                                    &nbsp;Sharing fax...
                                </span>
                                <span
                                    *ngIf="isUnSharingFax"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading deleting"
                                >
                                    <i
                                        class="fas fa-spinner spinner deleting"
                                    ></i>
                                    &nbsp;Removing Shared...
                                </span>
                                <!--  -->
                                <button
                                    *ngIf="
                                        selectedFile.isFile &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        !isSharingFax &&
                                        !isUnSharingFax
                                    "
                                    (click)="downloadFile($event)"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="download"
                                >
                                    <i
                                        class="fas fa-cloud-download fs-icon"
                                    ></i>
                                </button>
                                <span
                                    *ngIf="isDownloading"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading"
                                >
                                    <i class="fas fa-spinner spinner"></i>
                                    &nbsp;Downloading...
                                </span>
                                <button
                                    *ngIf="
                                        !selectedFile.isFile &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        !isSharingFax &&
                                        !isUnSharingFax
                                    "
                                    (click)="
                                        openFolder(
                                            selectedFile.isFile,
                                            selectedFile.chatRoomChatUsers
                                        )
                                    "
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="open"
                                >
                                    <i
                                        class="bottom-fax-button fas fa-arrow-right fs-icon"
                                    ></i>
                                </button>
                                <!-- rename -->
                                <span
                                    *ngIf="
                                        displayReceivedFaxesComponent &&
                                        isRenameSubmitting
                                    "
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading"
                                >
                                    <i class="fas fa-spinner spinner"></i>
                                    &nbsp;Renaming...
                                </span>
                                <button
                                    *ngIf="
                                        displayReceivedFaxesComponent &&
                                        selectedFile.isFile &&
                                        faxChannelId &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        !isSharingFax &&
                                        !isUnSharingFax
                                    "
                                    (click)="showRenameFileInput($event)"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="rename"
                                >
                                    <i class="fas fa-file-edit fs-icon"></i>
                                </button>
                                <!-- rename form -->
                                <form
                                    *ngIf="
                                        displayReceivedFaxesComponent &&
                                        faxChannelId &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !isSharingFax &&
                                        !isUnSharingFax &&
                                        displayRenameFileForm
                                    "
                                    [formGroup]="renameFileForm"
                                    @fadeIn
                                    [class.desktop]="isDesktop"
                                    class="rename-form"
                                    
                                >
                                <!--  -->
                                    <input
                                        *ngIf="
                                            displayRenameFileForm &&
                                            selectedFile.isFile
                                        "
                                        (keyup.enter)="renameFile()"
                                        (blur)="showRenameFileInput()"
                                        (dblclick)="preventFileDblClick($event)"
                                        class="rename-input"
                                        [class.desktop]="isDesktop"
                                        id="renameInput"
                                        type="text"
                                        name="fName"
                                        formControlName="fName"
                                        value="{{
                                            selectedFile.fileName | fileName
                                        }}"
                                        maxlength="249"
                                        placeholder="Rename file"
                                    />
                                    <button
                                        *ngIf="
                                            displayRenameFileForm &&
                                            selectedFile.isFile
                                        "
                                        [disabled]="renameFileForm.invalid"
                                        (mousedown)="renameFile()"
                                        (dblclick)="preventFileDblClick($event)"
                                        title="rename file"
                                        class="rename-submit-button"
                                    >
                                        <i
                                            *ngIf="
                                                displayRenameFileForm &&
                                                selectedFile.isFile &&
                                                renameFileForm.valid
                                            "
                                            @rotateInOut
                                            class="far fa-check-circle rename-submit-icon"
                                        >
                                        </i>
                                    </button>
                                </form>
                                <!-- Delete Files -->
                                <button
                                    *ngIf="
                                        faxChannelId &&
                                        selectedFile.isFile &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        !isSharingFax &&
                                        !isUnSharingFax
                                    "
                                    (click)="_deleteFileFromAll($event)"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="delete"
                                >
                                    <i class="fas fa-trash-alt fs-icon-del"></i>
                                </button>
                                <button
                                    *ngIf="
                                        !faxChannelId &&
                                        selectedFile.isFile &&
                                        !isDeleting &&
                                        !isDownloading &&
                                        !isAddingOwner &&
                                        !isRemovingOwner &&
                                        !isRenameSubmitting &&
                                        !displayRenameFileForm &&
                                        !isSharingFax &&
                                        !isUnSharingFax
                                    "
                                    (click)="unShareFaxWithSelf()"
                                    (dblclick)="preventFileDblClick($event)"
                                    class="fs-button bottom-buttons"
                                    @fadeIn
                                    title="remove"
                                >
                                    <i class="fas fa-times fs-icon"></i>
                                </button>
                                <span
                                    *ngIf="isDeleting"
                                    @fadeInFast
                                    class="fs-loading fs-desktop-loading deleting"
                                >
                                    <i
                                        class="fas fa-spinner spinner deleting"
                                    ></i>
                                    &nbsp;Deleting...
                                </span>
                                <!--  -->
                            </div>
                        </div>
                        <!-- * END DESKTOP VIEW -->
                        <!-- 🎏🎏🎏 -->
                        <!-- * MOBILE VIEW -->
                        <!-- to display drop-down of file information MOBILE ONLY -->
                        <div
                            class="ms-auto file-info-wrap"
                            (click)="showFileInfo($event, i)"
                            (mouseleave)="closeFileInfo(i, $event)"
                            (dblclick)="preventFileDblClick($event)"
                        >
                            <!-- info icon -->
                            <i
                                class="far fa-info-circle file-info-icon"
                                [class.selected-row]="
                                    selectedFile === filesList[i]
                                "
                                [title]="
                                    file.isFile
                                        ? 'file information'
                                        : 'folder information'
                                "
                            ></i>
                            <!-- info dropdown for files -->
                            <div
                                *ngIf="file.isFile"
                                id="fax-info-{{ i }}"
                                class="file-info-display"
                                (click)="closeFileInfo(i, $event)"
                                (dblclick)="preventFileDblClick($event)"
                            >
                                <div
                                    title="{{ file.fileName }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">File:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.fileName }}
                                    </div>
                                </div>
                                <div
                                    title="{{ file.size | byteConvert }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Size:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.size | byteConvert }}
                                    </div>
                                </div>
                                <div
                                    title="{{ file.pages }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Pages:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.pages }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        file.status !== 'Failed' &&
                                        !displayReceivedFaxesComponent
                                    "
                                    title="{{ file.status }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Status:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.status }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        file.status === 'Failed' &&
                                        !displayReceivedFaxesComponent
                                    "
                                    title="{{ file.status }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Status:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.errorCode }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="displayReceivedFaxesComponent"
                                    title="{{ file.fromFaxNumber }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title"
                                        >Received From:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{
                                            file.fromFaxNumber
                                                | mask: "(000) 000-0000"
                                        }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="!displayReceivedFaxesComponent"
                                    title="{{ file.fromFaxNumber }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Sent To:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.faxNumber | formatPhoneNumber }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="file.chatUserKeyNavigation"
                                    title="{{
                                        file.chatUserKeyNavigation.displayName
                                    }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title"
                                        >Created By:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{
                                            file.chatUserKeyNavigation
                                                .displayName
                                        }}
                                    </div>
                                </div>
                                <div
                                    title="{{
                                        file.created + 'Z'
                                            | date: 'M/d/yy h:mm a'
                                    }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Created:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{
                                            file.created + "Z"
                                                | date: "M/d/yy h:mm a"
                                        }}
                                    </div>
                                </div>
                                <div
                                    title="{{
                                        file.lastUpdated + 'Z'
                                            | date: 'M/d/yy h:mm a'
                                    }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title"
                                        >Last Modified:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{
                                            file.lastUpdated + "Z"
                                                | date: "M/d/yy h:mm a"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <!-- info dropdown for folders -->
                            <div
                                *ngIf="!file.isFile"
                                id="fax-info-{{ i }}"
                                class="file-info-display"
                                (click)="closeFileInfo(i, $event)"
                                (dblclick)="preventFileDblClick($event)"
                            >
                                <div
                                    title="{{ file.fileName | faxFolderName }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Name:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{ file.fileName | faxFolderName }}
                                    </div>
                                </div>
                                <div
                                    title="{{
                                        file.created | date: 'M/d/yy h:mm a'
                                    }}"
                                    class="file-info-text"
                                >
                                    <span class="file-info-title">Created:</span
                                    >&nbsp;
                                    <div class="info-text">
                                        {{
                                            file.created | date: "M/d/yy h:mm a"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- case for pagination loading taking too long -->
                    <div
                        *ngIf="isGettingNextPage && !isFilesLoading"
                        class="file-display-message border-none"
                    >
                        <span class="fs-loading">
                            <i
                                class="fas fa-spinner spinner"
                                style="color: var(--vivid-sky-blue)"
                            ></i>
                            &nbsp;Loading more faxes...
                        </span>
                    </div>
                    <div
                        *ngIf="!isDesktop"
                        class="file-display-bottom-space-mobile"
                    >
                        &nbsp;
                    </div>
                </div>
            </div>
            <!-- BOTTOM BUTTONS ROW MOBILE -->
            <div *ngIf="!isDesktop" class="row bottom-row-mobile">
                <!-- * these buttons will be rendered if item is selected * -->
                <div *ngIf="selectedFile" class="bottom-buttons-group-right">
                    <!-- add owners btn (!isFile)-->
                    <button
                        *ngIf="
                            !selectedFile.isFile &&
                            selectedFile.fileName !== 'Shared With Me' &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isSharingFax &&
                            !isUnSharingFax &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm
                        "
                        (click)="toggleAddOwnerModal()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="add owner"
                    >
                        <i
                            class="bottom-fax-button fas fa-user-plus fs-icon"
                        ></i>
                    </button>
                    <span
                        *ngIf="isAddingOwner"
                        @fadeInFast
                        class="fs-loading fs-desktop-loading"
                    >
                        <i class="fas fa-spinner spinner"></i>
                        &nbsp;Adding owner...
                    </span>
                    <span
                        *ngIf="isRemovingOwner"
                        @fadeInFast
                        class="fs-loading fs-desktop-loading deleting"
                    >
                        <i class="fas fa-spinner spinner deleting"></i>
                        &nbsp;Removing owner...
                    </span>
                    <!-- Resend Fax (SideBar)-->
                    <!-- Resend Fax button -->
                    <button
                        *ngIf="
                            !displayReceivedFaxesComponent &&
                            selectedFile.isFile &&
                            selectedFile.status !== 'processing' &&
                            selectedFile.status !== 'sending' &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isSharingFax &&
                            !isUnSharingFax &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            faxChannelId
                        "
                        (click)="showReSendFaxConfirmation()"
                        (dblclick)="preventFileDblClick($event)"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="resend fax"
                    >
                        <i class="fas fa-share-all fs-icon"></i>
                    </button>
                    <!-- Share Fax (SideBar) -->
                    <button
                        *ngIf="
                            displayReceivedFaxesComponent &&
                            selectedFile.isFile &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isSharingFax &&
                            !isUnSharingFax &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            faxChannelId
                        "
                        (click)="toggleShareFaxModal()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="share fax"
                    >
                        <i class="fas fa-share-alt fs-icon"></i>
                    </button>
                    <span
                        *ngIf="isSharingFax"
                        @fadeInFast
                        class="fs-loading fs-desktop-loading"
                    >
                        <i class="fas fa-spinner spinner"></i>
                        &nbsp;Sharing fax...
                    </span>
                    <span
                        *ngIf="isUnSharingFax"
                        @fadeInFast
                        class="fs-loading fs-desktop-loading deleting"
                    >
                        <i class="fas fa-spinner spinner deleting"></i>
                        &nbsp;Removing Shared...
                    </span>
                    <button
                        *ngIf="
                            selectedFile.isFile &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            !isSharingFax &&
                            !isUnSharingFax
                        "
                        (click)="downloadFile()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="download"
                    >
                        <i class="fas fa-cloud-download fs-icon"></i>
                    </button>
                    <span
                        *ngIf="isDownloading"
                        @fadeInFast
                        class="fs-loading fs-mobile-loading-right"
                    >
                        <i class="fas fa-spinner spinner"></i>
                        &nbsp;Downloading...
                    </span>
                    <button
                        *ngIf="
                            !selectedFile.isFile &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            !isSharingFax &&
                            !isUnSharingFax
                        "
                        (click)="
                            openFolder(
                                selectedFile.isFile,
                                selectedFile.chatRoomChatUsers
                            )
                        "
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="open"
                    >
                        <i
                            class="bottom-fax-button fas fa-arrow-right fs-icon"
                        ></i>
                    </button>
                    <!-- rename -->
                    <span
                        *ngIf="isRenameSubmitting"
                        @fadeInFast
                        class="fs-loading fs-mobile-loading-right"
                    >
                        <i class="fas fa-spinner spinner"></i>
                        &nbsp;Renaming...
                    </span>
                    <button
                        *ngIf="
                            displayReceivedFaxesComponent &&
                            selectedFile.isFile &&
                            faxChannelId &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            !isSharingFax &&
                            !isUnSharingFax
                        "
                        (click)="showRenameFileInput()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="rename"
                    >
                        <i class="fas fa-file-edit fs-icon"></i>
                    </button>
                    <form
                        *ngIf="
                            faxChannelId &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isSharingFax &&
                            !isUnSharingFax &&
                            !isRenameSubmitting &&
                            displayRenameFileForm
                        "
                        [formGroup]="renameFileForm"
                        @fadeIn
                        [class.desktop]="isDesktop"
                        class="rename-form"
                    >
                        <input
                            *ngIf="displayRenameFileForm && selectedFile.isFile"
                            (keyup.enter)="renameFile()"
                            (blur)="showRenameFileInput()"
                            class="rename-input"
                            [class.desktop]="isDesktop"
                            id="renameInput"
                            type="text"
                            name="fName"
                            formControlName="fName"
                            value="{{ selectedFile.fileName | fileName }}"
                            maxlength="249"
                            placeholder="Rename file"
                        />
                        <button
                            *ngIf="displayRenameFileForm && selectedFile.isFile"
                            [disabled]="renameFileForm.invalid"
                            (mousedown)="renameFile()"
                            title="rename file"
                            class="rename-submit-button"
                        >
                            <i
                                *ngIf="
                                    displayRenameFileForm &&
                                    selectedFile.isFile &&
                                    renameFileForm.valid
                                "
                                @rotateInOut
                                class="far fa-check-circle rename-submit-icon"
                            >
                            </i>
                        </button>
                    </form>
                    <!--Delete Files-->
                    <button
                        *ngIf="
                            faxChannelId &&
                            selectedFile.isFile &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            !isSharingFax &&
                            !isUnSharingFax
                        "
                        (click)="_deleteFileFromAll()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="delete"
                    >
                        <i class="fas fa-trash-alt fs-icon-del"></i>
                    </button>
                    <!--  -->
                    <button
                        *ngIf="
                            !faxChannelId &&
                            selectedFile.isFile &&
                            !isDeleting &&
                            !isDownloading &&
                            !isAddingOwner &&
                            !isRemovingOwner &&
                            !isRenameSubmitting &&
                            !displayRenameFileForm &&
                            !isSharingFax &&
                            !isUnSharingFax
                        "
                        (click)="unShareFaxWithSelf()"
                        class="fs-button bottom-buttons"
                        @fadeIn
                        title="remove"
                    >
                        <i class="fas fa-times fs-icon"></i>
                    </button>
                    <span
                        *ngIf="isDeleting"
                        @fadeInFast
                        class="fs-loading fs-mobile-loading-right deleting"
                    >
                        <i class="fas fa-spinner spinner deleting"></i>
                        &nbsp;Deleting...
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end fax view -->
<!-- 🚧 ADD OWNERS MODAL -->
<div
    class="modal"
    *ngIf="displayAddOwnerModal"
    id="add-owner-modal"
    (click)="clickOffFaxModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="toggleAddOwnerModal()"
    >
        <button
            title="close"
            class="close-modal-x"
            (click)="toggleAddOwnerModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <!-- current owner list -->
        <h4 class="mb-2">Current Owners</h4>
        <div
            class="owner-list scrollbar-new mb-4"
            [class.scrollable]="selectedFile.chatRoomChatUsers.length > 3"
        >
            <ng-container
                *ngFor="
                    let user of selectedFile.chatRoomChatUsers;
                    let i = index
                "
            >
                <div class="chat-user">
                    <div class="user-img">
                        <!--  -->
                        <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                        <img
                            src="https://profile-images.azureedge.us/{{
                                user.chatUserKeyNavigation.hash
                            }}"
                            alt="user"
                        />
                    </div>
                    <div class="chat-names-wrap">
                        <p
                            class="chat-username"
                            title="{{ user.chatUserKeyNavigation.displayName }}"
                        >
                            {{ user.chatUserKeyNavigation.displayName }}
                        </p>
                        <p
                            class="chat-name"
                            title="{{ user.chatUserKeyNavigation.username }}"
                        >
                            {{ user.chatUserKeyNavigation.username }}
                        </p>
                    </div>
                    <!-- remove owner -->
                    <button
                        class="owner-btn remove"
                        *ngIf="
                            user.chatUserKeyNavigation.channelUserId !==
                            selectedFile.chatUserId
                        "
                        (click)="
                            removeOwner(
                                user.chatUserKeyNavigation.channelUserId,
                                user.chatUserKeyNavigation.displayName
                            )
                        "
                        title="Remove Owner"
                    >
                        <i class="far fa-times-circle"></i>
                    </button>
                </div>
            </ng-container>
        </div>
        <!-- search for users to add -->
        <h4>Add an Owner</h4>
        <form class="form-style-4 form-no-borders">
            <div class="form-inputs">
                <label class="label-full search">
                    <button (click)="clearSearch()">
                        <i
                            *ngIf="!searchCheckLength()"
                            class="fas fa-search search__helper"
                        ></i>
                        <i
                            *ngIf="searchCheckLength()"
                            class="fas fa-times"
                            title="clear"
                        ></i>
                    </button>
                    <!-- TODO: Use Debounce searching here.  Need to differenciate between this and shared fax users-->
                    <input
                        type="text"
                        id="user-search"
                        name="user-search"
                        value=""
                        (input)="triggerFaxUserSearch($event)"
                        placeholder="Search Users"
                    />
                </label>
            </div>
        </form>
        <!-- search users result list -->
        <div
            class="search-list scrollbar-new"
            [class.scrollable]="searchUsersList.length > 3"
        >
            <div
                *ngFor="let user of searchUsersList; let i = index"
                class="chat-user"
            >
                <div class="user-img">
                    <!--  -->
                    <!-- https://randomuser.me/api/portraits/thumb/women/{{i}}.jpg -->
                    <img
                        src="https://profile-images.azureedge.us/{{
                            user.hash
                        }}"
                        alt="user"
                    />
                </div>
                <div class="chat-names-wrap">
                    <p class="chat-username" title="{{ user.displayName }}">
                        {{ user.displayName }}
                    </p>
                    <p class="chat-name" title="{{ user.username }}">
                        {{ user.username }}
                    </p>
                </div>
                <!-- add owner -->
                <button
                    class="owner-btn"
                    (click)="addOwner(user.channelUserId, user.displayName)"
                    title="Add Owner"
                >
                    <i class="far fa-plus-circle"></i>
                </button>
            </div>
            <p
                class="empty-list"
                *ngIf="searchUsersList.length < 1 && searchCheckLength()"
            >
                No Users Found
            </p>
        </div>
    </div>
</div>
<!-- <div
    (click)="toggleAddOwnerModal()"
    *ngIf="displayAddOwnerModal"
    class="backdrop"
></div> -->
<!-- END add owner modal -->
<!-- 🚧 share Fax modal -->
<div
    class="modal"
    *ngIf="displayShareFaxModal"
    id="share-fax-modal"
    (click)="clickOffFaxModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="toggleShareFaxModal()"
    >
        <button
            title="close"
            class="close-modal-x"
            (click)="toggleShareFaxModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <!-- current shared with list -->
        <ng-container *ngIf="selectedFile?.sharedFilesWith.length > 0">
            <h4 class="mb-2">Shared with</h4>
            <div
                class="shared-with-list scrollbar-new mb-4"
                [class.scrollable]="selectedFile.sharedFilesWith.length > 3"
            >
                <ng-container
                    *ngFor="
                        let user of selectedFile.sharedFilesWith;
                        let i = index
                    "
                >
                    <!-- <ng-container
                        *ngIf="user.chatUserKeyNavigation.channelUserId !== userId"
                    > -->
                    <div class="chat-user">
                        <div class="user-img">
                            <img
                                src="https://profile-images.azureedge.us/{{
                                    user.chatUserKeyNavigation.hash
                                }}"
                                alt="user"
                            />
                        </div>
                        <div class="chat-names-wrap">
                            <p
                                class="chat-username"
                                title="{{
                                    user.chatUserKeyNavigation.displayName
                                }}"
                            >
                                {{ user.chatUserKeyNavigation.displayName }}
                            </p>
                            <p
                                class="chat-name"
                                title="{{
                                    user.chatUserKeyNavigation.username
                                }}"
                            >
                                {{ user.chatUserKeyNavigation.username }}
                            </p>
                        </div>
                        <!-- remove owner -->
                        <button
                            *ngIf="faxChannelId"
                            class="owner-btn remove"
                            (click)="
                                unShareFax(
                                    user.chatUserKeyNavigation.channelUserId,
                                    user.chatUserKeyNavigation.displayName
                                )
                            "
                            title="Remove User"
                        >
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    <!-- </ng-container> -->
                </ng-container>
            </div>
        </ng-container>
        <!-- search for members to share with -->
        <h4 *ngIf="faxChannelId" class="mt-4">Share Fax</h4>
        <form class="form-style-4 form-no-borders" *ngIf="faxChannelId">
            <div class="form-inputs">
                <label class="label-full search">
                    <button (click)="clearSearch()">
                        <i
                            *ngIf="!searchCheckLength()"
                            class="fas fa-search search__helper"
                        ></i>
                        <i
                            *ngIf="searchCheckLength()"
                            class="fas fa-times"
                            title="clear"
                        ></i>
                    </button>
                    <input
                        type="text"
                        id="user-search"
                        name="user-search"
                        value=""
                        (input)="triggerFaxShareSearch($event)"
                        placeholder="Search Users"
                    />
                </label>
            </div>
        </form>
        <!-- search users result list -->
        <div
            class="search-list scrollbar-new"
            [class.scrollable]="searchUsersList.length > 3"
        >
            <div
                *ngFor="let user of searchUsersList; let i = index"
                class="chat-user"
            >
                <div class="user-img">
                    <!--  -->
                    <!-- https://randomuser.me/api/portraits/thumb/men/{{i}}.jpg -->
                    <img
                        src="https://profile-images.azureedge.us/{{
                            user.hash
                        }}"
                        alt="user"
                    />
                </div>
                <div class="chat-names-wrap">
                    <p class="chat-username" title="{{ user.displayName }}">
                        {{ user.displayName }}
                    </p>
                    <p class="chat-name" title="{{ user.username }}">
                        {{ user.username }}
                    </p>
                </div>
                <!-- add owner -->
                <button
                    class="owner-btn"
                    (click)="shareFax(user.channelUserId, user.displayName)"
                    title="Share Fax"
                >
                    <i class="fas fa-plus-circle"></i>
                </button>
            </div>
            <p
                class="empty-list"
                *ngIf="searchUsersList.length < 1 && searchCheckLength()"
            >
                No Users Found
            </p>
        </div>
    </div>
</div>
<!-- <div
    (click)="toggleShareFaxModal()"
    *ngIf="displayShareFaxModal && faxChannelId"
    class="backdrop"
></div> -->
<!-- END share fax modal -->
<!--  -->
<!-- 🚧 SEND FAX POP-UP MODAL-->
<div
    class="modal"
    *ngIf="displaySendFaxModal"
    id="send-fax-modal"
    (click)="clickOffFaxModalEvent($event)"
>
    <div
        class="modal-container"
        (document:keydown.escape)="closeSendFaxModal()"
    >
        <button
            title="close"
            class="close-modal-x"
            (click)="closeSendFaxModal()"
        >
            <i class="fas fa-times"></i>
        </button>
        <div class="title-row mb-4">
            <div class="channel-title">
                <h3 class="title-thin flex align-center">Send A Fax</h3>
            </div>
        </div>
        <form
            [formGroup]="sendFaxForm"
            class="form-style-4 form-no-borders invite-form"
        >
            <div class="form-inputs">
                <!-- file upload start -->
                <span
                    *ngIf="isSendingFax && !isDuplicateFile"
                    @fadeInFast
                    class="fs-loading fs-mobile-loading-left"
                >
                    <i
                        class="fas fa-spinner spinner"
                        style="color: var(--vivid-sky-blue)"
                    ></i>
                    &nbsp;Uploading Fax...
                </span>
                <p class="send-fax-form-title">File To Send</p>
                <div
                    *ngIf="!isSendingFax && !isDuplicateFile"
                    class="file-upload-button borderless-input mb-3"
                    title="upload file"
                >
                    <!--  -->
                    <input
                        type="file"
                        name="fileToFax"
                        formControlName="fileToFax"
                        class="file-upload-input"
                        accept="image/*,application/pdf,application/txt, application/doc, application/docx, application/rtf, application/wri"
                        id="file-upload"
                        title="Upload Fax"
                        [disabled]="isFileUploading"
                        (click)="selectedFile = null"
                        (change)="fileName($event)"
                        required
                    />
                </div>
                <!-- file upload end -->
                <!-- Duplicate Fax File Prompt Start-->
                <div
                    *ngIf="isDuplicateFile"
                    @fadeInFast
                    [class.desktop]="isDesktop"
                    class="file-display-message-warn"
                >
                    <label class="label-full">
                        Resend same filename to this fax number again?
                    </label>
                    <div class="duplicate-fax-buttons">
                        <button (click)="handleDuplicateFile(true)">YES</button>
                        <button (click)="handleDuplicateFile(false)">
                            BACK
                        </button>
                    </div>
                </div>
                <!-- Duplicate Fax File Prompt End-->
            </div>
            <div class="form-inputs">
                <p class="send-fax-form-title">Send To Fax</p>
                <div>
                    <input
                        type="text"
                        name="faxNumber"
                        formControlName="faxNumber"
                        class="borderless-input mb-3"
                        placeholder="###-###-####"
                        required
                        #fax
                        mask="000-000-0000"
                        (change)="isFaxFormEdited = true"
                    />
                </div>
            </div>
            <div class="form-inputs">
                <p class="send-fax-form-title">Sending From</p>
                <!--<label *ngFor="
                        let folder of folderList;
                        index as i;
                        last as last
                    " class="label-full">
                    <span class="input-outline">
                        {{folder.fileName}} /
                        <span mask="000-000-0000">{{folder.faxNumber|formatPhoneNumber}}</span>
                    </span>
                </label> -->
                <label *ngIf="folderList[0]" class="label-full">
                    <span class="input-outline">
                        {{ folderList[0].fileName }} /
                        <span mask="000-000-0000">
                            {{ folderList[0].faxNumber | formatPhoneNumber }}
                        </span>
                    </span>
                </label>
            </div>
            <div class="flex justify-center mt-4">
                <button
                    class="primary-button"
                    [disabled]="!sendFaxForm.valid || isFaxSending"
                    (click)="submitFaxForm()"
                >
                    Send Fax
                </button>
            </div>
        </form>
    </div>
</div>
<!-- END SEND FAX POP-UP MODAL-->
