import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { ChannelService } from '../../../services/channel-service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { ApiCallsService } from '../../../services/api-calls.service';
import { AppComponent } from 'src/app/app.component';
import { Constants } from '../../../constants';
import { ScreenCheck } from '../../functions';
import { ChannelFull } from '../../interface/channel-full';
import { consoleLogStyle } from '../../varaibles';
import { Platform } from '@angular/cdk/platform';

declare var JitsiMeetExternalAPI: any;

interface Device {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
};

@Component({
    selector: 'jitsi-video',
    templateUrl: './jitsi-video.component.html',
    styleUrls: ['./jitsi-video.component.scss']
})
export class JitsiVideoComponent implements OnInit {
    @Input() isGuest: boolean;
    @Input() channelId: string;
    @Input() channelInfo: ChannelFull;
    @Input() userName: string;
    @Input() forMedicalEMT: boolean;
    @Input() preventList: string[]; // Features To be Prevented
    @Input() mainChannel: boolean;
    @Output() endCallEvent = new EventEmitter();
    @Output() startCallEvent = new EventEmitter();
    @Output() openChatEvent = new EventEmitter();
    @Output() openUsersEvent = new EventEmitter();
    @Output() openFilesEvent = new EventEmitter();
    @Output() participantsListOut = new EventEmitter();

    public apiObj: any = null;
    public dispName: string = null;
    public roomName: string = ""; // meeting_id
    public callerId: string = null; // users participant id
    public jitsiToken: string = "";

    // public showJoinModal: boolean = false; // not in use ... 
    public showSettingsModal: boolean = false;
    public showMoreOptionsModal: boolean = false;
    public showKickParticipantsModal: boolean = false;
    public joiningCall: boolean = false;
    public isTokenFound: boolean = false;

    public hideStartVideo: boolean = false; //for joining call outside of channel
    public inVideo: boolean = false;
    public isRecording: boolean = false;
    public isStreaming: boolean = false;
    public disableRecording: boolean = false;
    public disableStreaming: boolean = false;
    public isFullScreen: boolean = false;
    public inCall: boolean = false;
    public handsUp: boolean = false;
    public isModerator: boolean = false;
    public participantsInCall: any = [];

    public devicePreferences: any = {};

    //Feature List
    public preventStartVideo: boolean = false; //for police vdjj
    public featureList: any[] = [
        // {
        //     name: "chat",
        //     id: "btnToggleChat",
        //     icon: "-comment-text",
        //     title: "Toggle Chat",
        //     method: () => {
        //         this.openChat()
        //     }
        // },
        {
            name: "screenshare",
            id: "btnScreenShareCustom",
            icon: "fas fa-desktop",
            title: "screen share",
            method: () => {
                this.screenShareCustom()
            }
        },
        {
            name: "raisehand",
            id: "raiseHand",
            icon: "fas fa-hand-paper",
            title: "raise hand",
            method: () => {
                this.setHand()
            }
        },
        {
            name: "tileview",
            id: "btnCustomTileView",
            icon: "fas fa-th",
            title: "tile view",
            method: () => {
                this.customTileView()
            }
        },
        {
            name: "fullscreen",
            id: "btnSetFullScreen",
            icon: "fas fa-expand",
            title: "full screen",
            method: () => {
                this.setFullScreen()
            }
        },
        {
            name: "settings",
            id: "btnCustomSettings",
            icon: "fas fa-cog",
            title: "video settings",
            method: () => {
                this.showPreview()
            }
        },
        {
            name: "virtual background",
            id: "btnCustomVirtualBackgrounds",
            icon: "fas fa-image",
            title: "Virtual Backgrounds",
            method: () => {
                this.toggleVirtualBackgrounds()
            }
        }

        // {
        //     name: "users",
        //     id: "btnToggleUsers",
        //     icon: "-accounts",
        //     title: "Toggle Users",
        //     method: () => {
        //         this.openUsers()
        //     }
        // },
        // {
        //     name: "files",
        //     id: "btnToggleFiles",
        //     icon: "-folder",
        //     title: "Toggle Files",
        //     method: () => {
        //         this.openFiles()
        //     }
        // }
    ];

    //for forMedicalEMT
    public altHeight: boolean = false;

    public callId: any = null;
    public initiatorClientId = null;
    public initiatorConnectionId: any = null;
    public initiatorUserId: any = null;

    //for handling out site wide sidebar display
    public sidebarShowing: boolean;
    // public sidebarPinned: boolean;

    private subscriptions: Subscription[] = [];

    public jitsiMediaBreak: number = 480;
    public jitsiBreak: boolean;

    // 🚧 new device preview vars ...
    public showPreviewModal: boolean = false;
    
    public previewVideoInputs: Array<Device> = [];
    public previewAudioInputs: Array<Device> = [];
    public previewAudioOutputs: Array<Device> = [];
    
    public previewCurrentVideoInputId: string = null;
    public previewCurrentAudioInputId: string = null;
    public previewCurrentAudioOutputId: string = null; // 
    
    public previewVideoStream: MediaStream;
    public previewAudioStream: MediaStream;
    
    public videoPreviewOn: boolean;
    public audioPreviewOn: boolean = false;
    
    public currentMicrophoneStream: MediaStreamAudioSourceNode;
    public audioAnalyzer: AnalyserNode;
    // 💡❌ replace with new AudioWorklet ... 
    public audioScriptProcessor: ScriptProcessorNode;

    public devicesReady: any = false;

    public isSettingJitsiDevices: boolean;

    public matRippleDarkMode = '#ffffff1a';

    constructor(
        @Inject('rippleColor') public rippleColor: string,
        private _toastService: ToastNotificationService,
        public _channelService: ChannelService,
        private _authService: AuthService,
        private _apiCallsService: ApiCallsService,
        private _appComp: AppComponent,
        private _router: Router,
        public _platform: Platform,
    ) {
        if (!this.forMedicalEMT) {
            this.forMedicalEMT = false;
        }

        //inital set for sidebar
        this.sidebarShowing = this._appComp.showSidebar;
        // this.sidebarPinned = this._appComp.pinnedSideBar;

        this.subscriptions.push(this._channelService.onJoinedPageEvent$
            .subscribe((channelId: any) => {
                // console.log("JoinedPageEvent channelID: " + channelId);
                if (this.mainChannel) {
                    this.channelId = channelId;
                    this.getJitsiToken();
                }
            })
        );

        this.subscriptions.push(this._channelService.onSelectPoliceOfficerEvent$
            .subscribe((channelId: any) => {
                // console.log("JoinedPageEvent channelID: " + channelId);
                this.channelId = channelId;
                this.getJitsiToken();
            })
        );

        this.subscriptions.push(this._channelService.onJitsiCallCreatedEvent$.subscribe((callId: any) => {
            //console.log("jitsicallcreated sub");
            // console.log("callchannelevent sub");
            this.callId = callId;
            this.startUp();
        }));

        this.subscriptions.push(this._channelService.onJitsiCallPromptEvent$.subscribe((callObj: any) => {
            // console.warn("jitsicallprompt sub");
            // this.channelId = callObj.channelId;
            // this.roomName = callObj.channelId;
            this.initiatorClientId = callObj.initiatorClientId;
            this.initiatorConnectionId = callObj.initiatorConnectionId;
            this.initiatorUserId = callObj.initiatorUserId;

            // if (this.forMedicalEMT) {
            if (this.preventStartVideo) {
                if (false === this.inCall) {
                    this.callId = callObj.callId;
                    this.startUpHelper(false);
                }
            } else {
                //this.showJoinModal = true;
            }
        }));

        this.subscriptions.push(this._channelService.onJitsiCallAcceptedEvent$.subscribe(() => {
            this.startUp();
        }));

        this.subscriptions.push(this._channelService.onJitsiHangUpUserEvent$.subscribe(() => {
            this.hangUp();
        }));

        this.subscriptions.push(this._channelService.onJitsiHangUpLastUserEvent$.subscribe(() => {
            // if (this.forMedicalEMT) {
            if (this.preventStartVideo) {
                this.hangUp();
            }
        }));

        // this.subscriptions.push(this._channelService.onHandEvent$.subscribe((displayName: any) => {
        //     // if (this.handsUp === false) {
        //     //     this._toastService.(displayName + " raised their hand!");
        //     // } else {
        //     //     this._toastService.info(displayName + " lowered their hand!");
        //     // }
        //     //console.log("handevent");
        //     // if (this.handsUp === false) {
        //     //     this.handsUp = true;
        //     // } else {
        //     //     this.handsUp = false;
        //     // }
        // }));

        this.subscriptions.push(this._channelService.onJitsiMaxAttendeeLimitReachedEvent$.subscribe(() => {
            this._toastService.error("This call is full, no more attendees can join this call");
        }));

        this.subscriptions.push(this._channelService.onJitsiToggleRecordingEvent$.subscribe((disableRecording: any) => {
            if (disableRecording === "true" || disableRecording === true) {
                this.isRecording = true;
                this.disableRecording = true;
            } else {
                this.isRecording = false;
                this.disableRecording = false;
            }
            // console.log("isRecording: " + this.isRecording);
            // console.log("disableRecording: " + this.disableRecording);
        }));

        this.subscriptions.push(this._channelService.onJitsiSelfJoinEvent$.subscribe((joinChannelId: string) => {
            if (joinChannelId === this.channelId) {
                this.setPreference(true);
            }
        }));

        this.subscriptions.push(this._channelService.onSidebarDisplayEvent$.subscribe(show => {
            this.sidebarShowing = show;
        }));

        // this.subscriptions.push(this._channelService.onSidebarPinEvent$.subscribe(isPinned => {
        //     this.sidebarPinned = isPinned;
        // }));

        this.subscriptions.push(this._channelService.onHangUpCallEvent$.subscribe( ()=> {
            if (this.inCall) {
                this.hangUp();
            }
        }));

        this.jitsiBreak = ScreenCheck(this.jitsiMediaBreak);
    }

    ngOnInit() {
        if (!this.userName) {
            this.dispName = "New User";
        } else {
            this.dispName = this.userName;
        }
        // console.log("USER NAME On Init: " + this.dispName);
        this.getJitsiToken();
        //console.log("ngOnIt going to call getPreferences")
        this.getPreferences();
        if (this.preventList) {
            // alert(this.preventList);
            this.preventList.forEach(item => {
                if (item === 'startVideo') {
                    this.preventStartVideo = true;
                } else if (item === 'record') {
                    this.featureList = this.featureList.filter(feature => feature.name != "record")
                } else if (item === 'chat') {
                    this.featureList = this.featureList.filter(feature => feature.name != "chat")
                } else if (item === 'users') {
                    this.featureList = this.featureList.filter(feature => feature.name != "users")
                } else if (item === 'files') {
                    this.featureList = this.featureList.filter(feature => feature.name != "files")
                }
            });
        }
        this.featureList.forEach((item, i) => {
            if (i === 0) {
                item.switch = () => {
                    return true;
                };
            } else if (i === 1) {
                item.switch = () => {
                    return this.jitsiCheck(360);
                };
            } else if (i === 2) {
                item.switch = () => {
                    return this.jitsiCheck(420);
                };
            } else if (i === 3) {
                item.switch = () => {
                    return this.jitsiCheck(480);
                };
            } else if (i === 4) {
                item.switch = () => {
                    return this.jitsiCheck(540);
                };
            } else {
                item.switch = () => {
                    return false;
                };
            }
        });
        if (this._channelService.useManagerSettings) {
            if (!this.channelId) {
                this._toastService.error("Failed to join this call");
                this.joiningCall = false;
            } else {
                this.joiningCall = true;
                this.hideStartVideo = true;
                this.roomName = this.channelId;
                this._channelService.JitsiCallChannel(this.roomName);
            }
        }
        
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getJitsiToken() {
        // console.log("getJitsiToken channelId: " + this.channelId)
        let jitsiJwtObject =
        {
            "channelId": this.channelId,
            "userId": this._authService._user.profile.sub,
        };
        this._apiCallsService.postJitsiJwt(jitsiJwtObject)
            .subscribe(res => {
                if (res.status === 200) {
                    // console.log("Token Created");
                    this.jitsiToken = res.body.value;
                    // console.log("Token:" + this.jitsiToken);
                    // activate Join Conversation button now that the Jitsi token is found
                    this.isTokenFound = true;
                } else if (res.status === 401) {
                    // console.log("Unauthorized");
                    this._router.navigate(['/unauthorized']);
                } else {
                    // console.log(res);
                    // alert(JSON.stringify(res));
                }
            });
    }

    startUp() {
        this.inCall = true;
        // console.log("CHANNEL AT STARTUP: " + this.channelId);
        // console.log("startup");
        // this.showJoinModal = false;
        this.startMeeting(this.roomName, this.dispName, this.jitsiToken);
        //let joinMsg: any = document.querySelector('#joinMsg');
        //let btnStartup: any = document.querySelector("#btnStartup");
        //joinMsg.innerHTML = "Joining...";
        //if (btnStartup) {
        //    btnStartup.style.display = 'none';
        //}
    }

    startUpHelper(isCaller: any) {
        //this._channelService.sendJSON();
        // console.log("startup helper isCaller: " + isCaller);
        if (!this.channelId) {
            this._toastService.error("Failed to join this call");
            // console.log('meeting id is missing');
            this.joiningCall = false;
        } else {
            // there IS an element Type ... <HTMLElement> ... 
            // let joinMsg: HTMLElement = document.querySelector('#joinMsg');
            // let btnStartup: HTMLElement = document.querySelector("#btnStartup");
            let guest: HTMLElement = document.querySelector("#guestSignOut");
            // joinMsg.innerHTML = "Joining...";
            // if (btnStartup) {
            //     btnStartup.style.display = 'none';
            // }
            this.hideStartVideo = true;
            if (this.isGuest && guest) {
                guest.style.display = 'none';
            }
            this.roomName = this.channelId;
            if (isCaller) {
                // console.log("startuphelper channelId: " + this.channelId);
                // console.log("iscaller true");
                this._channelService.JitsiCallChannel(this.roomName);
            } else {
                this._channelService.JitsiAcceptCall(this.callId, this.roomName, this.initiatorClientId, this.initiatorConnectionId, this.initiatorUserId);
            }
        }
    }



    hangUp() {
        this.inCall = false;
        this.isModerator = false;
        if(this.isStreaming) {
            this.stopStream();
        }
        let toolbox: HTMLElement = document.querySelector('#toolbox');
        // let btnStartup: HTMLElement = document.querySelector("#btnStartup");
        let guest: HTMLElement = document.querySelector("#guestSignOut");
        this.apiObj.executeCommand('hangup');
        // if (btnStartup) {
        //     btnStartup.style.display = 'inline-block';
        // }
        this.hideStartVideo = false;
        if (this.isGuest && guest) {
            guest.style.display = 'block';
        }
        if (toolbox) {
            toolbox.style.display = 'none';
        }
        this.inVideo = false;
        // console.log("hanging up call")
        if (this.isFullScreen) {
            this.closeFullScreen();
        }
        if (this.preventStartVideo) {
            // console.log("hanging up emt");
            this.altHeight = false;
        }
        this.featureList.find(item => {
            if (item.name === "screenshare") {
                item.class = "";
                return true;
            }
        });
    }

    hangUpHelper() {
        if (!this.channelId) {
            this._toastService.error("Failed to leave this call");
            // console.log('meeting id is missing');
        } else {
            this.roomName = this.channelId;
        }
        this.hangUp();
        this.endCallEvent.emit();
        this._channelService.JitsiHangUpUser(this.callId, this.roomName);
    }

    customMic() {
        this.apiObj.executeCommand('toggleAudio');
        // this.updateLastActivity();
    }

    customCamera() {
        this.apiObj.executeCommand('toggleVideo');
        // this.updateLastActivity();
    }

    customTileView() {
        this.apiObj.executeCommand('toggleTileView');
        // this.updateLastActivity();
    }

    screenShareCustom() {
        this.apiObj.executeCommand('toggleShareScreen');
        // this.updateLastActivity();
    }

    // ❌❌❌ live streaming not in use here ... can we remove ... 
    startRecording() {
        // console.log("startrecording");
        this.isRecording = true;
        this.apiObj.executeCommand('startRecording', { mode: 'file' });
        this._channelService.onJitsiToggleRecordingButton(true, this.roomName);
    }

    stopRecording() {
        // console.log("stoprecording");
        this.isRecording = false;
        this.apiObj.executeCommand('stopRecording', 'file');
        this._channelService.onJitsiToggleRecordingButton(false, this.roomName);
    }

    startStream() {
        this.isStreaming = true;
        this.apiObj.executeCommand('startRecording', { mode: 'stream', youtubeStreamKey: this.channelInfo.liveStreamIngestUrl + "/a" });
    }

    stopStream() {
        this.isStreaming = false;
        this.apiObj.executeCommand('stopRecording', 'stream')
    }

    // NEW AUDIO/VIDEO PREVIEW HERE ... 
    showPreview() {
        this.showPreviewModal = true;
        setTimeout(() => {
            this.startVideoPreview();
        });
    }

    hidePreview() {
        this.destroyVideoPreview();
        this.destroyAudioPreview();
        this.showPreviewModal = false;
        this.audioPreviewOn = false;
        this.updateLastActivity();
    }

    startVideoPreview() {
        // 
        if(this.previewCurrentVideoInputId) {
            let constraints = { deviceId: { exact: this.previewCurrentVideoInputId } };
            // console.log("%c starting in call video preview w/ current input", consoleLogStyle ,this.previewCurrentVideoInputId)
            this.initPreCallVideoPreview({ video: constraints, audio: false }, false);
        } else {
            this.initPreCallVideoPreview({ video: true, audio: false }, true);
        }
    }

    initPreCallVideoPreview(config:any, isInitial:boolean) {
        let vidEl: HTMLVideoElement = <HTMLVideoElement>document.getElementById('videoPreviewEl-pre-join');
        // console.info("video preview element?", vidEl)
        let browser = <any>navigator;
        browser.getUserMedia = (browser.getUserMedia ||
        browser.webkitGetUserMedia ||
        browser.mozGetUserMedia ||
        browser.msGetUserMedia);
        // console.info("browser?", browser)
        browser.mediaDevices.getUserMedia(config)
            .then((stream) => {
                if(isInitial) {
                    this.getPreviewMediaDevices();
                }
                // console.info("user video stream?", stream)
                this.previewVideoStream = stream;
                vidEl.srcObject = stream;
                this.videoPreviewOn = true;
                this.devicePreferences.jitsi.startWithVideoMuted = false;
            }).catch(err => {
                console.error(err.name + ": " + err.message, err);
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (video preview)`);
            });
    }

    // gets users devices & populates select inputs ... 
    getPreviewMediaDevices() {
        let videoDevices = [];
        let audioInputDevices = [];
        let audioOutputDevices = [];
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device => {
                    // console.log("user devices enum: ",device);
                    let option: Device = {
                        "deviceId": device.deviceId,
                        "groupId": device.groupId,
                        "kind": device.kind,
                        "label": device.label
                    };
                    if (device.kind === 'audioinput') {
                        audioInputDevices.push(option);
                    } else if (device.kind === 'audiooutput') {
                        audioOutputDevices.push(option);
                    } else if (device.kind === 'videoinput') {
                        videoDevices.push(option);
                    } else {
                        console.log("Other Device: " + device.kind + ": " + device.label + " id = " + device.deviceId + " was found.");
                    }
                });
            }).catch(err => {
                // console.log(err.name + ": " + err.message, err)
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (get preview devices)`);
            }).finally(()=> {
                this.previewVideoInputs = videoDevices;
                if(this.previewVideoInputs.length > 0) {
                    if(!this.previewCurrentVideoInputId) {
                        this.previewCurrentVideoInputId = this.previewVideoInputs[0].deviceId; // SET TO CURRENT FOR SELECT ... 
                    }
                    // console.log("select val?", this.vidPreSelect)
                } else {
                    this._toastService.error('No camera found.')
                }
                // console.log("user devices video: ", this.previewVideoInputs);
                
                this.previewAudioInputs = audioInputDevices;
                if(this.previewAudioInputs.length > 0) {
                    if(!this.previewCurrentAudioInputId) {
                        this.previewCurrentAudioInputId = this.previewAudioInputs[0].deviceId;
                    }
                } else {
                    this._toastService.error('No audio input found.')
                }
                // console.log("user devices audio input: ", this.previewAudioInputs);
                
                this.previewAudioOutputs = audioOutputDevices;
                if(this.previewAudioOutputs.length > 0) {
                    if(!this.previewCurrentAudioOutputId) {
                        this.previewCurrentAudioOutputId = this.previewAudioOutputs[0].deviceId;
                    }
                } else {
                    // this._toastService.error('No audio output found.')
                    console.info("no audio output found.")
                }
                // console.log("user devices audio output: ", this.previewAudioOutputs);
            });
    }

    // video preview toggles
    videoPreviewToggle(hideVideoPreview: boolean) {
        // console.info("%c vid tog e : ", consoleLogStyle, hideVideoPreview)
        this.devicePreferences.jitsi.startWithVideoMuted = !hideVideoPreview;
        hideVideoPreview ? this.setVideoPreviewInput() : this.destroyVideoPreview();
    }

    setVideoPreviewInput() {
        this.destroyVideoPreview();
        // 
        let constraints = { deviceId: { exact: this.previewCurrentVideoInputId } };
        // console.log("%c starting in call preview current input", consoleLogStyle ,this.previewCurrentVideoInputId)
        this.initPreCallVideoPreview({video: constraints, audio: false}, false);
    }

    // cuts current VIDEO preview track ... 
    destroyVideoPreview() {
        let vidEl: HTMLVideoElement = <HTMLVideoElement>document.getElementById('videoPreviewEl-pre-join');
        vidEl.srcObject = null;
        if(this.previewVideoStream) {
            this.previewVideoStream.getTracks().forEach( (track) => {
                track.stop();
            });
        }
    }

    // audio preview toggles
    audioPreviewToggle(hideAudioPreview: boolean) {
        this.audioPreviewOn = hideAudioPreview;
        // console.info("%c audio tog e audio on? : ", consoleLogStyle, this.audioPreviewOn)
        this.devicePreferences.jitsi.startWithAudioMuted = !hideAudioPreview;
        hideAudioPreview ? this.setAudioPreviewInput() : this.destroyAudioPreview();
    }

    setAudioPreviewInput() {
        this.destroyAudioPreview();
        // 
        let browser = <any>navigator;
        browser.getUserMedia = (browser.getUserMedia ||
        browser.webkitGetUserMedia ||
        browser.mozGetUserMedia ||
        browser.msGetUserMedia);
        let constraints:any = true;
        if(this.previewCurrentAudioInputId) {
            constraints = { deviceId: { exact: this.previewCurrentAudioInputId } };
        }
        browser.mediaDevices.getUserMedia({ audio: constraints })
            .then((stream) => {
                
                this.previewAudioStream = stream;
                const audioContext = new AudioContext();
                // console.info("%c ", consoleLogStyle)
                
                this.currentMicrophoneStream = audioContext.createMediaStreamSource(stream);
                // console.info("%c mic to dissconect on hide? : ", consoleLogStyle, this.currentMicrophoneStream)
                
                this.audioAnalyzer = audioContext.createAnalyser();
                // console.info("%c analyzer? ", consoleLogStyle, this.audioAnalyzer)
                this.audioAnalyzer.smoothingTimeConstant = 0.8;
                this.audioAnalyzer.fftSize = 1024;
                
                this.currentMicrophoneStream.connect(this.audioAnalyzer);
                
                // 💡❌ replace with new AudioWorklet ... 
                this.audioScriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
                // console.info("%c audio script proccessor ", consoleLogStyle, this.audioScriptProcessor)
                
                this.audioAnalyzer.connect(this.audioScriptProcessor);
                this.audioScriptProcessor.connect(audioContext.destination);
                
                this.audioScriptProcessor.onaudioprocess = () => {
                    
                    const array = new Uint8Array(this.audioAnalyzer.frequencyBinCount);
                    this.audioAnalyzer.getByteFrequencyData(array);
                    
                    const arraySum = array.reduce((a, value) => a + value, 0);
                    const average = arraySum / array.length;
                    // console.info( "%c Volume level ", consoleLogStyle, Math.round(average));
                    
                    this.colorBars(average);
                };
                // console.info("%c ", consoleLogStyle)
            }).catch(err => {
                // console.error(err.name + ": " + err.message);
                this._toastService.error(`${err.name} : ${err.message}, you may need to reset your browser permissions. (audio preview)`);
            });
    }

    colorBars(vol:number) {
        const allBars = Array.from(document.getElementsByClassName('bar') as HTMLCollectionOf<HTMLElement>);
        const dot = <HTMLElement>document.getElementById('audio-dot');
        const shadow = <HTMLElement>document.getElementById('audio-bars');
        const numberOfBarsToColor = Math.round(vol / 10);
        // console.info("%c # of bars to color ", consoleLogStyle, numberOfBarsToColor)
        const barsToColor = allBars.slice(0, numberOfBarsToColor);
        for (const bar of allBars) {
            bar.style.opacity = '0';
        }
        for (const bar of barsToColor) {
            // console.log(bar[i]);
            bar.style.opacity = '1';
            if(numberOfBarsToColor > 9) {
                bar.style.backgroundColor = 'var(--new-status-red)';
                dot.style.backgroundColor = 'var(--new-status-red)';
                shadow.style.boxShadow = 'inset -0.1rem -0.1rem 0.4rem rgba(255, 0, 85, 0.8), inset 0.1rem 0.1rem 0.4rem rgba(255, 0, 85, 0.8)';
            } else {
                bar.style.backgroundColor = 'var(--caribbean-green)';
                dot.style.backgroundColor = 'var(--caribbean-green)';
                shadow.style.boxShadow = 'inset -0.1rem -0.1rem 0.4rem rgba(18, 202, 150, 0.8), inset 0.1rem 0.1rem 0.4rem rgba(18, 202, 150, 0.8)';
            }
        }
    }

    // cuts current AUDIO preview track & analyzers & processors ... 
    destroyAudioPreview() {
        if(this.previewAudioStream) {
            this.previewAudioStream.getTracks().forEach( (track) => {
                track.stop();
            });
        }
        if(this.currentMicrophoneStream) {
            this.currentMicrophoneStream.disconnect();
        }
        if(this.audioAnalyzer) {
            this.audioAnalyzer.disconnect();
        }
        if(this.audioScriptProcessor) {
            this.audioScriptProcessor.disconnect();
        }
    }

    speakerTest() {
        if(this._platform.FIREFOX || this._platform.IOS || this._platform.ANDROID) {
            // cant set device id on Speach ... 
            // let utterance = new SpeechSynthesisUtterance("Welcome to via three unity");
            // window.speechSynthesis.speak(utterance);
            // console.info("%c speaker test FIREFOX", consoleLogStyle)
            // 💡 audio output select is hidden on firefox, need to test safari ... 
            const audio:HTMLMediaElement = new Audio('../../../../assets/audio/test-tone.mp3');
            audio.play();
        } else {
            // console.info("%c speaker test not FIREFOX", consoleLogStyle, this.previewCurrentAudioOutputId)
            // 💡 type <any> is needed as .setSinkId() is experimental and only works on chrome ... 
            const audio:any = new Audio('../../../../assets/audio/test-tone.mp3');
            audio.setSinkId(this.previewCurrentAudioOutputId);
            audio.play();
        }
    }

    // 🚧 not done yet just for logging ...
    setAudioPreviewOutput() {
        // 
        console.log('%c current speakers: ', consoleLogStyle, this.previewCurrentAudioOutputId)
    }
    // END NEW PRE-CALL PREVIEW ...

    showMoreOptions() {
        this.showMoreOptionsModal = true;
        this.updateLastActivity();
    }

    hideMoreOptions() {
        this.showMoreOptionsModal = false;
        this.updateLastActivity();
    }

    displayMoreOptions() {
        this.showMoreOptionsModal = this.showMoreOptionsModal ? false : true;
        this.updateLastActivity();
    }

    muteEveryone() {
        this.apiObj.executeCommand('muteEveryone');
        this.updateLastActivity();
    }

    getParticipants() {
        let participants = this.apiObj.getParticipantsInfo();
        this.participantsInCall = participants;
        this.participantsListOut.emit(this.participantsListOut);
        // this.showKickParticipants();
        // console.log(participants);
    }

    kickParticipant(participantId: string) {
        // console.log(participantId);
        this.apiObj.executeCommand('kickParticipant', participantId);
        this.updateLastActivity();
    }

    showKickParticipants() {
        this.getParticipants()
        this.showKickParticipantsModal = true;
        this.hideMoreOptions();
    }

    hideKickParticipants() {
        this.showKickParticipantsModal = false;
    }

    setFullScreen() {
        let elem: any = document.querySelector("#video-frame");
        // let tooltips: any = document.querySelector(".cdk-overlay-container");
        if (this.isFullScreen) {
            this.closeFullScreen();
        } else {
            if (elem.requestFullscreen) {
                // tooltips.requestFullscreen();
                elem.requestFullscreen();
                this.isFullScreen = true;
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
                this.isFullScreen = true;
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
                this.isFullScreen = true;
            } else {
                this._toastService.error("Your browser doesn't support this feature.");
                this.isFullScreen = false;
            }
            this.featureList.find(item => {
                if (item.name === "fullscreen") {
                    if (this.isFullScreen) {
                        item.icon = "fas fa-compress";
                        item.title = "exit full screen"
                    } else {
                        item.icon = "fas fa-expand";
                        item.title = "full screen"
                    }
                    return true;
                }
            });
        }
        this.updateLastActivity();
    }

    closeFullScreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            this.isFullScreen = false;
        } else if (document['webkitExitFullscreen']) { /* Safari */
            document['webkitExitFullscreen']();
            this.isFullScreen = false;
        } else if (document['msExitFullscreen']) { /* IE11 */
            document['msExitFullscreen']();
            this.isFullScreen = false;
        } else {
            this._toastService.error("Your browser doesn't support this feature.");
            this.isFullScreen = false;
        }
        this.featureList.find(item => {
            if (item.name === "fullscreen") {
                item.icon = "fas fa-expand";
                item.title = "full screen"
                return true;
            }
        });
    }

    setHand() {
        this.apiObj.executeCommand('toggleRaiseHand');
        // this.handsUp = this.handsUp ? false : true;
        // this.featureList.find(item => {
        //     if (item.name === "raisehand") {
        //         if (this.handsUp) {
        //             item.class = "activated";
        //         } else {
        //             item.class = "";
        //         }
        //         return true;
        //     }
        // });
        this.updateLastActivity();
    }

    toggleVirtualBackgrounds() {
        this.apiObj.executeCommand('toggleVirtualBackgroundDialog');
        this.updateLastActivity();
    }

    openChat() {
        if (this.isFullScreen) {
            this.closeFullScreen();
        }
        this.openChatEvent.emit();
        this.updateLastActivity();
    }

    openUsers() {
        if (this.isFullScreen) {
            this.closeFullScreen();
        }
        this.openUsersEvent.emit();
        this.updateLastActivity();
    }

    openFiles() {
        if (this.isFullScreen) {
            this.closeFullScreen();
        }
        this.openFilesEvent.emit();
        this.updateLastActivity();
    }

    startMeeting(roomName: string, dispName: string, token: string) {
        let videoMuted = this.devicePreferences.jitsi.startWithVideoMuted;
        let audioMuted = this.devicePreferences.jitsi.startWithAudioMuted;
        // 🚧 add device labels from preview here ... 
        let videoDeviceLabel: string = '';
        let audioInputDeviceLabel: string = '';
        let audioOutputDeviceLabel: string = '';
        // if camera selected from preview ... 
        if(this.previewCurrentVideoInputId) {
            // i tried to do with selectedDevice = [].find() but came undefined ... 
            this.previewVideoInputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentVideoInputId)
                if(device.deviceId === this.previewCurrentVideoInputId) {
                    videoDeviceLabel = device.label;
                    // console.info('%c selected device camera? ', consoleLogStyle, videoDeviceLabel)
                }
            });
        }
        // if mic selected from input ... 
        if(this.previewCurrentAudioInputId) {
            this.previewAudioInputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentAudioInputId)
                if(device.deviceId === this.previewCurrentAudioInputId) {
                    audioInputDeviceLabel = device.label;
                    // console.info('%c selected device mic? ', consoleLogStyle, device)
                }
            });
        }
        // if speakers selected from input ... 
        if(this.previewCurrentAudioOutputId) {
            this.previewAudioOutputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentAudioOutputId)
                if(device.deviceId === this.previewCurrentAudioOutputId) {
                    audioOutputDeviceLabel = device.label;
                    // console.info('%c selected device speakers? ', consoleLogStyle, device)
                }
            });
        }
        let options = {
            devices: {
                audioInput: audioInputDeviceLabel,
                audioOutput: audioOutputDeviceLabel,
                videoInput: videoDeviceLabel
            },
            roomName: roomName,
            jwt: token,
            width: '100%',
            height: '100%',
            parentNode: document.querySelector('#jitsi-meet-conf-container'),
            userInfo: {
                displayName: dispName
            },
            configOverwrite: {
                doNotStoreRoom: true,
                startWithVideoMuted: videoMuted,
                startWithAudioMuted: audioMuted,
                enableWelcomePage: false,
                prejoinPageEnabled: false
                // disableRemoteMute: true,
                // remoteVideoMenu: {
                //     disableKick: true
                // },
            },
            interfaceConfigOverwrite: {
                SHOW_JITSI_WATERMARK: false,
                DEFAULT_REMOTE_DISPLAY_NAME: dispName,
                TOOLBAR_BUTTONS: [],
                // TOOLBAR_BUTTONS: ['settings', 'fullscreen'],
                SETTINGS_SECTIONS: ['devices'],
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                DEFAULT_BACKGROUND: '#474747'//Jitsi Default color
            },
            onload: () => {
                //alert('loaded');
                // let joinMsg: any = document.querySelector('#joinMsg');
                let container: any = document.querySelector('#container');
                let toolbox: any = document.querySelector('#toolbox');
                let conferencePage: any = document.querySelector('#videoconference_page');
                let btnCustomMic: any;
                let btnCustomCamera: any;
                // joinMsg.style.display = 'none';
                container.style.display = 'block';
                toolbox.style.display = 'flex';
                btnCustomMic = document.querySelector("#btnCustomMic");
                btnCustomCamera = document.querySelector("#btnCustomCamera");
                if (audioMuted) {
                    btnCustomMic.firstChild.classList.add("fa-microphone-slash");
                } else {
                    btnCustomMic.firstChild.classList.add("fa-microphone");
                }
                if (videoMuted) {
                    btnCustomCamera.firstChild.classList.add("fa-video-slash");
                } else {
                    btnCustomCamera.firstChild.classList.add("fa-video");
                }
                if (conferencePage) {
                    conferencePage.classList.remove('subject');
                }
            }
        };
        // console.log("Constants.jitsiDomain", Constants.jitsiDomain)
        this.apiObj = new JitsiMeetExternalAPI(Constants.jitsiDomain, options);
        // console.info("jitsi api obj >>>>>>>>>>>>>>>>>>>>", this.apiObj)
        this.apiObj.addEventListeners({
            readyToClose: () => {
                //alert('going to close');
                let jitsiMeetConfContainer: any = document.querySelector('#jitsi-meet-conf-container')
                // let joinMsg: any = document.querySelector('#joinMsg');
                let container: any = document.querySelector('#container');
                let toolbox: any = document.querySelector('#toolbox');
                while (jitsiMeetConfContainer.firstChild) {
                    jitsiMeetConfContainer.removeChild(jitsiMeetConfContainer.firstChild);
                }
                toolbox.style.display = 'none'
                container.style.display = 'none'
                // joinMsg.innerHTML = "";
                // joinMsg.style.display = 'block';
            },
            audioMuteStatusChanged: (data) => {
                let btnCustomMic: any = document.querySelector("#btnCustomMic");
                // console.log("audio Change Status", data);
                if (data.muted) {
                    btnCustomMic.firstChild.classList.remove("fa-microphone");
                    btnCustomMic.firstChild.classList.add("fa-microphone-slash");
                } else {
                    btnCustomMic.firstChild.classList.remove("fa-microphone-slash");
                    btnCustomMic.firstChild.classList.add("fa-microphone");
                }
                this.updateLastActivity();
            },
            videoMuteStatusChanged: (data) => {
                let btnCustomCamera: any = document.querySelector("#btnCustomCamera");
                // console.log("video Change Status", data);
                if (data.muted) {
                    btnCustomCamera.firstChild.classList.remove("fa-video");
                    btnCustomCamera.firstChild.classList.add("fa-video-slash");
                } else {
                    btnCustomCamera.firstChild.classList.remove("fa-video-slash");
                    btnCustomCamera.firstChild.classList.add("fa-video");
                }
                this.updateLastActivity();
            },
            // deviceListChanged: (data) => {
            //     console.log("deviceschanges", data);
            // },
            micError: (data) => {
                // console.log(data);
                this._toastService.error(`${data.type}: ${data.message} (microphone error)`);
            },
            audioError: (data) => {
                // console.log(data);
                this._toastService.error(`${data.type}: ${data.message} (audio error)`);
            },
            tileViewChanged: (data) => {
                this.featureList.find(item => {
                    if (item.name === "tileview") {
                        if (data.enabled) {
                            item.icon = "fa fa-grip-vertical";
                            item.title = "strip view"
                        } else {
                            item.icon = "fas fa-th";
                            item.title = "tile view"
                        }
                        return true;
                    }
                });
                this.updateLastActivity();
            },
            screenSharingStatusChanged: (data) => {
                this.featureList.find(item => {
                    if (item.name === "screenshare") {
                        if (data.on) {
                            item.class = "activated";
                            item.title = "stop screen share";
                        } else {
                            item.class = "";
                            item.title = "start screen share";
                        }
                        return true;
                    }
                });
                this.updateLastActivity();
            },
            participantJoined: (data) => {
                // console.log('participantJoined', data);
                // this.getParticipants();
                if (this.showKickParticipantsModal) {
                    this.getParticipants();
                }
            },
            participantLeft: (data) => {
                // console.log('participantLeft', data);
                // this.getParticipants();
                if (this.showKickParticipantsModal) {
                    this.getParticipants();
                }
            },
            participantKickedOut: (data) => {
                // console.log('participantKicked', data);
                if (this.callerId === data.id) {
                    this.hangUp();
                }
                // this.getParticipants();
                if (this.showKickParticipantsModal) {
                    this.getParticipants();
                }
            },
            participantRoleChanged: (data) => {
                // console.log("participantRoleChanged", data)
                this.callerId = data.id;
                this.getParticipants();
                if (data.role === 'moderator') {
                    this.isModerator = true;
                } else {
                    this.isModerator = false;
                }
            },
            raiseHandUpdated: (data) => {
                this.getParticipants();
                let user = this.getParticipantById(data.id);
                // console.log(user); // was undefined at end call added the !! check here ... 
                if(!!user) {
                    if (data.handRaised) {
                        this._toastService.user(user.formattedDisplayName, " Raised their hand!");
                        if (this.callerId === user.participantId) {
                            this.handsUp = true;
                        }
                    } else {
                        if (this.callerId === user.participantId) {
                            this.handsUp = false;
                            this._toastService.user("You", " Lowered your hand.");
                        }
                    }
                    this.featureList.find(item => {
                        if (item.name === "raisehand") {
                            if (this.handsUp) {
                                item.class = "activated";
                                item.title = "lower hand";
                            } else {
                                item.class = "";
                                item.title = "raise hand";
                            }
                            return true;
                        }
                    });
                }
            }
        });
        this.startCallEvent.emit();
        this.apiObj.executeCommand('subject', roomName);
        // this.apiObj.executeCommand('startRecording', { mode: 'stream', rtmpStreamKey: this.channelInfo.liveStreamIngestUrl + "/a" });
        this.inVideo = true;
        this.joiningCall = false;
        // this.getDevices(); // gets devices for settings from jitsi api ... ❌
        // if (this.forMedicalEMT) {
        if (this.preventStartVideo) {
            this.altHeight = true;
        }
        this._channelService.useManagerSettings = false;
        // 
        this.subscriptions.push(this._authService.displayNameBehaviorSubject.pipe(filter(n => n !== ''),tap((name) => {
            // name 
            this.apiObj.executeCommand('displayName', name);
        })).subscribe());
        this.subscriptions.push(this._authService.userHashBehaviorSubject.pipe(filter(h => h !== ''),tap((hash) => {
            // hash 
            this.apiObj.executeCommand('avatarUrl', `https://profile-images.azureedge.us/${hash}`);
        })).subscribe());
    }

    getPreferences() {
        // console.log("getPreferences called");
        this.devicePreferences = JSON.parse(localStorage.getItem("devicePreferences"));
        // console.log("devicePreferences: ");
        // console.log(this.devicePreferences);
        if (this.devicePreferences === null) {
            this.devicePreferences = {
                "videoId": "default",
                "audioId": "default",
                "videoRes": 480,
                "jitsi": {
                    "startWithVideoMuted": true,
                    "startWithAudioMuted": false
                }
            };
            localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        } else if (!this.devicePreferences.jitsi) {
            this.devicePreferences.jitsi = {
                "startWithVideoMuted": true,
                "startWithAudioMuted": false
            }
            localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        }
        this.devicesReady = true;
    }

    setPreference(isCaller: any) {
        // console.log("SetPreferences Called.  IsCaller:" + isCaller);
        this.joiningCall = true;
        this.startUpHelper(isCaller);
        localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
    }

    // 🚧 change devices from post-join here ... 
    setJitsiDevices() {
        this.isSettingJitsiDevices = true;
        // 🚧 add device labels from preview here ... 
        let videoDeviceLabel: string = '';
        let audioInputDeviceLabel: string = '';
        let audioOutputDeviceLabel: string = '';
        // if camera selected from preview ... 
        if(this.previewCurrentVideoInputId) {
            // i tried to do with selectedDevice = [].find() but came undefined ... 
            this.previewVideoInputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentVideoInputId)
                if(device.deviceId === this.previewCurrentVideoInputId) {
                    videoDeviceLabel = device.label;
                    // console.info('%c selected device camera? ', consoleLogStyle, videoDeviceLabel)
                    this.apiObj.setVideoInputDevice(videoDeviceLabel);
                }
            });
        }
        // if mic selected from input ... 
        if(this.previewCurrentAudioInputId) {
            this.previewAudioInputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentAudioInputId)
                if(device.deviceId === this.previewCurrentAudioInputId) {
                    audioInputDeviceLabel = device.label;
                    // console.info('%c selected device mic? ', consoleLogStyle, device)
                    this.apiObj.setAudioInputDevice(audioInputDeviceLabel);
                }
            });
        }
        // if speakers selected from input ... 
        if(this.previewCurrentAudioOutputId) {
            this.previewAudioOutputs.map((device:Device) => {
                // console.info('%c selected device ittr:::', consoleLogStyle, device.deviceId, '=?', this.previewCurrentAudioOutputId)
                if(device.deviceId === this.previewCurrentAudioOutputId) {
                    audioOutputDeviceLabel = device.label;
                    // console.info('%c selected device speakers? ', consoleLogStyle, device)
                    this.apiObj.setAudioOutputDevice(audioOutputDeviceLabel);
                }
            });
        }
        setTimeout(() => {
            this.isSettingJitsiDevices = false;
        });
    }

    getParticipantById(id:string) {
        return this.participantsInCall.find(user => user.participantId === id);
    }

    // ❌❌❌💡 this is being way over called from .switch() in template ... see log ... 
    // infinite loop ... 
    jitsiCheck(mediaWidth: number) {
        // console.log("%c jitsi screen check fired from *ngIf ", consoleLogStyle) // comment back to see 
        let targetWidth: any = document.getElementById("video-frame");
        if (targetWidth) {
            targetWidth = targetWidth.offsetWidth;
            if (targetWidth < mediaWidth) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    updateLastActivity() {
        if (this.channelId) {
            this._channelService.updateLastActivity(this.channelId);
        }
    }

    modalClickOff(event:Event) {
        let target = event.target as HTMLElement || event.currentTarget as HTMLElement || event.srcElement as HTMLElement;
        let value = null;
        if (target.id) {
            let idAttr = target.id;
            value = idAttr;
        }
        if (value == "participants-modal") {
            this.showKickParticipantsModal = false;
        } else if (value == "more-options-modal") {
            this.showMoreOptionsModal = false;
        } else if (value == "video-preview-modal") {
            this.hidePreview();
        }
        this.updateLastActivity();
    }
}
