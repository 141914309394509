import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from '../core/auth.service';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class AppIntakeService {
    public userId: string = this._authService._user.profile.sub;
    // public VDJJAppId: string = "cdbae327-32ed-4d65-9d11-dd8f9de86cbd";
    // public VDJJAppProjectId: string = "3F51BCB3-3127-433A-9BEA-209ECF358290";
    // public VDJJSessionId: string = "bdd46a6c-3d88-4d47-92ea-c62ad304c0bc";
    public _isSignedIn = false;

    constructor(
        private httpClient: HttpClient,
        private _authService: AuthService
    ) {
        this._authService.userChange$.subscribe(() => {
            this.userId = this._authService._user.profile.sub;
        });
    }

    getAllIntakeUsers(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeUser`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeUserByChannelUserId(appId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeUser?chatUserId=${this.userId}&appId=${appId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeUserByChannelUserId2(userId: string, appId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeUser?chatUserId=${userId}&appId=${appId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                return of(err);
            })
        );
    }

    // 🚧 returns if has medical | police ... [path will contain if (medical)] ... 
    getIntakeUserByChannelUserId3(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeUser?chatUserId=${userId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                return of(err);
            })
        );
    }

    getIntakeUser(userId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeUser/` + userId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeSession(intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
        .get(`${Constants.apiRoot}intakeSession/` + intakeSessionId, { headers: headersConfig, observe: "response" })
        .pipe(
            catchError(err => {
                return of(err);
            })
        );
    }

    getAllIntakeSessionUsers(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllIntakeSessionUsersBySession(intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser?intakeSessionId=${intakeSessionId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllActiveIntakeSessionUsers(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser?active=true`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllActiveIntakeSessionUsersBySession(intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser?intakeSessionId=${intakeSessionId}&active=true`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeSessionUserByIntakeUser(intakeId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser?intakeUserId=${intakeId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeSessionUserByIntakeUserAndActive(intakeId: string, intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSessionUser?intakeUserId=${intakeId}&active=true&intakeSessionId=${intakeSessionId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postIntakeSessionUser(userSessionObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}intakeSessionUser/`, userSessionObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putIntakeSessionUser(intakeId: string, userSessionObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}intakeSessionUser/`+ intakeId, userSessionObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteSessionIntakeUser(userSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}intakeSessionUser/`+ userSessionId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAppCustomizations(appId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}appCustomization?appId=${appId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllIntakeOfficerShiftLogs(intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeOfficerShiftLog?intakeSessionId=${intakeSessionId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getAllOnShiftIntakeOfficerShiftLogs(intakeSessionId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeOfficerShiftLog?isOnShift=true&intakeSessionId=${intakeSessionId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeOfficerShiftLog(shiftLogId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeOfficerShiftLog/` + shiftLogId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeOfficerShiftLogByIntakeUser(intakeUserId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeOfficerShiftLog?intakeUserId=${intakeUserId}&pageSize=5`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    postIntakeOfficerShiftLog(shiftLogObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .post(`${Constants.apiRoot}intakeOfficerShiftLog/`, shiftLogObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    putIntakeOfficerShiftLog(shiftLogId: string, shiftLogObject: any): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .put(`${Constants.apiRoot}intakeOfficerShiftLog/` + shiftLogId, shiftLogObject, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    deleteIntakeOfficerShiftLog(shiftLogId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .delete(`${Constants.apiRoot}intakeOfficerShiftLog/` + shiftLogId, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeSessionByAppId(appId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}intakeSession?appId=${appId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeApp(tenant: string, isMedical: boolean): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let chatName: string;
        if (isMedical) {
            // chatName = "EMS";
            chatName = "";
        } else {
            chatName = "Video Intake";
        }
        //console.log(tenant);
        return this.httpClient
            .get(`${Constants.apiRoot}app?name=${tenant.toUpperCase()} ${chatName}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getIntakeGroupChatChannel(teamProjectId: string, isMedical: boolean): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        let chatName: string;
        if (isMedical) {
            chatName = "Staff Group Chat";
        } else {
            chatName = "Intake Officer Group Chat";
        }
        return this.httpClient
            .get(`${Constants.apiRoot}channel?name=${chatName}&teamProjectId=${teamProjectId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getEMSGroupChatChannel(teamProjectId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channel?name=Staff Group Chat&teamProjectId=${teamProjectId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getVDJJApp(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}app?name=VDJJ Video Intake`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getVDJJAppCustomizations(appId: string): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}appCustomization?appId=${appId}`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getVDJJProject(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}teamProject?name=VDJJIntakeApp`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }

    getVDJJGroupChatChannel(): Observable<HttpResponse<any>> {
        var headersConfig = new HttpHeaders();
        return this.httpClient
            .get(`${Constants.apiRoot}channel?name=Intake Officer Group Chat`, { headers: headersConfig, observe: "response" })
            .pipe(
                catchError(err => {
                    return of(err);
                })
            );
    }
}
