import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ChannelService } from '../../../services/channel-service';
import { AuthService } from '../../../../../src/app/core/auth.service';
import { ApiCallsService } from '../../../services/api-calls.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar, toHTML } from 'ngx-editor';
import { ProjectChannelService } from 'src/app/services/project-channel.service';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
// import { ChannelUser } from '../../interface/channel-user';



@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']

})
export class ChatComponent implements OnInit, OnDestroy {
    @Input() currentIntakeUser: any;
    @Input() teamProjectId: any;
    @Input() incomingChannelId: string;
    @Input() isDirectPopUp: boolean;

    public isQuickMeeting: boolean;

    public user: any = {}; // IDP user object
    public messageClassName: string = '';
    public messages: any = []; //  <<<--- need type interface here ... 
    public message: string = ''; //  <<<--- need type interface here ... 

    public scrollBox: any; // for pagination ... 
    public scrollTopBlock: boolean = false; // for pagination ... 
    public preLoad: boolean = true;
    public hasAccess: boolean = false;
    public typing: boolean = false;
    public typers: any = [];
    public typingReset: boolean = false;
    public isPrivate: boolean = true;
    public privateCheck: boolean = false;

    public callerTyping: boolean = false;
    public mytime: any;

    public firstMessageDate: string = '';
    // public sub: any;
    // public today: any = new Date();
    public loadMoreMessagesAvailable: boolean = false;
    public pagination: any = {};

    public channelId: string;

    // public orderDateInterVal: any;
    // public orderTime = '2019-10-31 17:40:57.98';
    // public difference: any;
    // public orderDate: any;
    // public msgCount: any = 0;
    // public mytime1: any;
    // public firstMessageDateTime: any;

    // public officers: any = {};
    // public intakeOfficer: any = {};
    // public selectedOfficer: any;
    // public currentIntakeUser: any = {};

    public channelInfo: any;
    public channelDisplayName: string = "";

    public isShiftDown = false;

    private subscriptions: Subscription[] = [];

    //Message Input
    public newMessage: FormGroup = new FormGroup({
        content: new FormControl('', Validators.required()),
    });

    public editor: Editor = new Editor({
        features: {
            linkOnPaste: false//doesnt work
        }
    });
    public toolbar: Toolbar = [
        ['bold', 'italic', 'underline', 'strike', 'code']
    ];

    public logoIcon2: any = {
        "logoblack": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-black.png",
        "logobluewhite": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-blue-white.png",
        "logogreen": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-green.png",
        "logoblackblue": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-via%20black-blue.png",
        "logoviablack": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-via%20black.png",
        "logowhiteblue": "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Icon%202/logo-white-blue.png",
    };

    // not sure on the search ... maybe used for the @ user functionallity ... 
    public userSearchList: any[] = [];
    public userSearchString: string = '';
    public userSearchActivated: boolean = false;
    public userSearchCursor: any;

    public isViewer: boolean = false;

    constructor(
        private _chManageService: ProjectChannelService,
        public _toastService: ToastNotificationService,
        private _apiCallsService: ApiCallsService,
        private _channelService: ChannelService,
        public _authService: AuthService,
        private router: Router
    ) {
        if (this._channelService) {
            this.subscriptions.push(this._channelService.onPopulateMessagesEvent2$.subscribe((channelId: any) => { //triggered from io component
                // alert("populate message subscribe " + channelId);
                //console.log("populate messages " + channelId + " " + this.channelId);
                if (!this.isDirectPopUp) {
                    this.channelId = channelId;
                    this.preLoad = true;
                    this.messages = [];
                    this._getChannel(channelId);
                }
            }));

            this.subscriptions.push(this._channelService.onMessageToClientEvent$.subscribe((message: any) => {
                // console.log(message);
                // console.log(message.content);
                //alert("channelId: " + this.channelId + "message.ChannelId " + message.channelId);
                if (message.channelId.toLowerCase() === this.channelId.toLowerCase()) {
                    let messageId = message.channelUserKeyNavigation.channelUserId.toUpperCase();
                    let firstMessageId;
                    let firstMessageDate;
                    let firstTimeLength = 0;
                    let isFirstMessage: boolean = false;
                    if (this.messages.length === 0) {
                        firstMessageId = messageId;
                        firstMessageDate = new Date();
                        isFirstMessage = true;
                        // console.log("message.length is 0")
                    } else {
                        firstMessageId = this.messages[0].channelUserKeyNavigation.channelUserId.toUpperCase();
                        firstMessageDate = new Date(this.messages[0].when);
                        firstTimeLength = (firstMessageDate.getHours() * 60) + firstMessageDate.getMinutes();
                    }
                    //firstMessageId = this.messages[0].channelUserKeyNavigation.channelUserId.toUpperCase();
                    let messageDate = new Date(message.when);
                    let messageTimeLength = (messageDate.getHours() * 60) + messageDate.getMinutes();
                    //firstMessageDate = new Date(new Date(this.messages[0].when).toDateString());
                    if (firstMessageId === messageId) {
                        if (isFirstMessage) {
                            message.sameId = false;
                        } else {
                            message.sameId = true;
                        }
                    } else {
                        message.sameId = false;
                    }
                    if (messageDate.toDateString() === firstMessageDate.toDateString()) {
                        if (isFirstMessage) {
                            message.sameDate = false;
                        } else {
                            message.sameDate = true;
                            if (messageTimeLength - firstTimeLength < 10) {
                                message.closeTime = true;
                            }
                        }
                    } else {
                        message.sameDate = false;
                    }
                    // if (this.checkURL(message.content)) {
                    //     message.isURL = true;
                    //     // also checks if an image
                    //     if (this.checkImage(message.content))
                    //         message.isImage = true;
                    // } else {
                    //     message.isURL = false;
                    // }
                    this.messages.unshift(message);
                    // fix scroll on incoming message (works) //
                    this.fixScroll();
                }
                if (this.currentIntakeUser) {
                    if (this.currentIntakeUser.isIntakeOfficer && this.currentIntakeUser.chatUserKeyNavigation.channelUserId.toLowerCase() !== message.channelUserId.toLowerCase()) {
                        if (message.channelId.toLowerCase() !== this.channelId.toLowerCase()) {
                            this._updateUnreadMessageCount(message.channelId);
                        }
                    }
                }
                //mark message as read using SignalR or API request for the people in the channel at the time the message is sent
                //alert(JSON.stringify(this.intakeOfficer));
                //this.onUpdateUnreadMessageCountEvent.next(message.channelId);
                // if(message.channelUserKeyNavigation.channelUserId.toUpperCase() === this._authService._user.profile.sub.toUpperCase()) {
                //     setTimeout(() => {
                //         this.scrollToBottom();
                //     }, 1);this.scrollToBottom()
                // }
            }));

            //this._channelService.onPopulateMessagesEvent$.subscribe((message: any) => {
            //    console.log("PopulateMessagesEvent " + message.message);
            //    this.messages.push(message);
            //});

            this.subscriptions.push(this._channelService.isTypingEvent$.subscribe((message: any) => {
                if (this.channelId.toLowerCase() === message.channelId.toLowerCase()) { //temporary fix, typing is being sent to everyone
                    //alert(message.channelUserId); 
                    if (this.user.sub.toUpperCase() !== message.user.channelUserId.toUpperCase()) {// incase user has multiple browsers open
                        // if (this.typing === false) {
                        if (this.typers.some(user => user.channelUserId === message.user.channelUserId)) {
                            return;
                        } else {
                            this.typers.push(message.user);
                        }
                        this.typing = true;
                        // fix scroll on typing (works) //
                        this.fixScroll();
                        // console.log("subscribe ", this.typing);
                        this.mytime = setTimeout(() => {
                            let filterList = this.typers;
                            this.typers = filterList.filter(user => {
                                return user.channelUserId !== message.user.channelUserId
                            });
                            if (this.typers.length < 1) {
                                this.typing = false;
                            } else {
                                this.typing = true;
                            }
                            // console.log("subscribe ", this.typing);
                        }, 3000);
                        // }
                    }
                }
            }));

            this.subscriptions.push(this._channelService.onNudgeEvent$.subscribe(() => {
                _toastService.info("You have been Nudge")
            }));

            this.subscriptions.push(this._channelService.onHighFiveEvent$.subscribe((displayname: any) => {
                _toastService.user(displayname, "Gave a High Five!")
            }));

            // ❌ i think this was for testing ... may can remove ... 
            // this.subscriptions.push(this._channelService.onPerfTestEvent$.subscribe((perfObj: any) => {
            //     let theLoop: (i: number) => void = (i: number) => {
            //         setTimeout(() => {
            //             this._channelService.sendMessageToChannel("Perf Test: " + i, perfObj.fullChannelName);
            //             if (--i) {
            //                 theLoop(i);
            //             }
            //         }, 200);
            //     };
            //     theLoop(perfObj.repetitions);
            // }));

            this.subscriptions.push(this._channelService.onWhoCommandEvent$.subscribe((whoObj: any) => {
                alert("Display Name: " + whoObj.displayName + "\n" + "Status: " + whoObj.PresenceStatus + "\n" + "You are in the following channels: \n" + whoObj.channelsList.join('\n'))
            }));

            this.subscriptions.push(this._channelService.onJoinEvent$.subscribe(() => {
                //this gets called on all chat components when a new componet is started
                this.hasAccess = true;
                if (this.currentIntakeUser) {
                    this.messages = [];
                    this.preLoad = true;
                }
                // this._toastService.info(this.channelId);
                if (this.channelId) {
                    // this._toastService.info(this.channelId + " : " + this.preLoad);
                    // if (this.messages.length < 1) {
                    if (this.preLoad) {
                        // this._toastService.info("onJoinEvent: " + this.channelId);
                        // this._toastService.info(this.channelId + " : " + this.messages.length);
                        if (this.channelInfo) {
                            this.retrieveInitialMessages(this.channelId);
                        } else {
                            this._getChannel(this.channelId, true)
                        }
                    }
                }
            }));

            this.subscriptions.push(this._channelService.onJoinAccessDeniedEvent$.subscribe(() => {
                this.hasAccess = false;
                this.preLoad = false;
                console.error("onJoinAccessDeniedEvent: failed to join")
                // console.log("has access2 " + this.hasAccess);
            }));

            this.subscriptions.push(this._channelService.onSharedFileBeginUploadEvent$.subscribe((channelId: any) => {
                this._channelService.sendMessageToChannel("A file has began uploading", channelId);
            }));

            //this.subscriptions.push(this._channelService.onUserLeaveEvent$.subscribe((userObj: any) => {
            //    this._channelService.sendMessageToChannel(userObj.displayName + " has left the room.", userObj.channelId);
            //}));

            this.subscriptions.push(this._channelService.onUpdateChatComponentEvent$.subscribe((channelId: any) => {
                //console.log("Deleteall update");
                this.preLoad = true;
                this.messages = [];
                // this._toastService.info("onUpdateChatComponentEvent: " + channelId);
                if (this.channelInfo) {
                    this.retrieveInitialMessages(channelId);
                } else {
                    this._getChannel(channelId, true)
                }
            }));

            // ❌ i dont think any of the below events fire, dont see alerts ??? 
            this.subscriptions.push(this._channelService.onDeleteMessagesPermissionDeniedEvent$.subscribe(() => {
                alert("You do not have permission to delete messages in this channel!");
            }));

            this.subscriptions.push(this._channelService.onAddOwnerEvent$.subscribe((username: any) => {
                alert(username + " was added as an owner");
            }));

            this.subscriptions.push(this._channelService.onAddMemberEvent$.subscribe((username: any) => {
                alert(username + " was added as a member");
            }));

            this.subscriptions.push(this._channelService.onRemoveMemberEvent$.subscribe((username: any) => {
                alert(username + " was removed as a member");
            }));

            this.subscriptions.push(this._channelService.onRemoveOwnerEvent$.subscribe((username: any) => {
                alert(username + " was removed as an owner");
            }));

            this.subscriptions.push(this._channelService.onListOwnersOrMembersEvent$.subscribe((usernames: any) => {
                alert(usernames.join('\n'));
            }));

            // what ... ??? 
            this.subscriptions.push(this._channelService.onWhereEvent$.subscribe((channelsList: any) => {
                alert("You are in the following channels: \n" + channelsList.join('\n'));
            }));
        } else {
            this._toastService.error("Channel Service not initialized.");
        }
    }

    ngOnInit() {
        //console.log("incomingChannelId: " + this.incomingChannelId);
        //this.hasAccess = true;
        this.user = this._authService._user.profile;

        if(this.user.role) {
             this.isViewer = this.user.role === "Viewer"; // NDIS - Streaming
        }

        this._getChannelUser();
        // this._getIntakeUser();
        // console.log(this.currentIntakeUser);
        if (this.currentIntakeUser) {
            if (!this.currentIntakeUser.isIntakeOfficer) {
                this._getChannelByName(this.currentIntakeUser);
            }
        } else if (this.incomingChannelId) {
            //console.log("this.incomingChannelId: " + this.incomingChannelId);
            this.channelId = this.incomingChannelId;
            this._getChannel(this.incomingChannelId);
        }
        this.fixScroll();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.editor.destroy();
        // clearInterval(this.orderDateInterVal);
    }

    public figureOutTimeSincePost(when:any): number {
        // Convert the "when" into a usable date object, and remember it is a UTC date
        var t = when.replace('T', ' ').replace('+00:00', '').split(/[- :]/);
        var whenAsUsableDate = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        const date: number = moment(whenAsUsableDate).valueOf();
        // When getting the date, we need to get the UTC date, because we store UTC date in the db
        var timeDiff = Math.floor((new Date().getTime() - date) / 1);
        return Number(timeDiff);
    }

    public initiateSendMessage(): void {
        if (this.newMessage.valid && this.hasAccess) {
            // console.log("initial message", this.newMessage.value.content);
            // this.findUsersInMessage(this.newMessage.value.content);
            let message: string = this.newMessage.value.content;
            let users = this.userExtractor(message);
            let url = this.router.url;
            if (users.length > 0) {
                this._checkUserExists(users);
            } else {
                this.sendMessage({ "message": message, "atUsers": [], "url": url });
            }
        }
    }

    public sendMessage(messageContents:any): void {
        // console.log(messageContents);
        messageContents.message = this.addBlankTag(messageContents.message);
        // console.log(messageContents.message);
        this._channelService.sendMessageToChannelJSON(messageContents, this.channelId);
        this.clearSearch();
        this.editor.setContent('');
    }

    private addBlankTag(message: string) {
        let aTagObjects = this.aTagExtractor(message);
        let newMessage: any = message.split("");
        for (let i = aTagObjects.length - 1; i >= 0; i--) {
            let aTag = aTagObjects[i].string;
            let aTagArray = this.addTarget(aTag);;
            newMessage.splice(aTagObjects[i].first, aTagObjects[i].length, ...aTagArray);
        }
        // console.log(newMessage.join(""))
        return newMessage.join("");
    }

    private aTagExtractor(message:any) {
        let expression = /\<a[\s\S](.*?)\>/gi;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let aTagObjects = [];
        for (let match of result) {
            aTagObjects.push({
                first: match.index,
                last: match.index + match[0].length,
                length: match[0].length,
                string: match[0]
            });
        }
        // console.log(aTagObjects);
        return aTagObjects;
    }

    private addTarget(aTag: string) {
        let list = ['target="_self"', 'target="_parent"', 'target="_top"', 'target=""']
        let newTag: any = aTag;
        let blank: any = 'target="_blank"'
        if (
            list.some((sub) => {
                let check = newTag.includes(sub)
                if (check) {
                    newTag = newTag.replace(sub, blank)
                }
                return check;
            })
        ) {
            newTag = newTag.split("");
            return newTag
        } else if (aTag.includes(blank)) {
            newTag = newTag.split("");
            return newTag
        } else {
            blank = " " + blank;
            blank = blank.split("");
            newTag = newTag.split("");
            newTag.splice(2, 0, ...blank);
            return newTag;
        }
    }

    private typingEvent(): void {
        if (this.callerTyping === false) {
            this.callerTyping = true;
            this._channelService.typingEvent(this.channelId);
            setTimeout(() => {
                // console.log(this.callerTyping);
                this.callerTyping = false;
                // console.log(this.callerTyping);
            }, 7000);
        }
    }

    public onKeyPress(event: KeyboardEvent) {
        // console.log(event);
        switch (event.key) {
            case "Enter":
                // Create New Line if shift and enter are down; otherwise, send message
                // makes sure not to send an empty block; regex clears spaces
                let formattedMessage = this.newMessage.value.content.replace(/\s+/g, '');
                if (!this.isShiftDown && formattedMessage !== "<p></p><p></p>")
                    this.initiateSendMessage();
                //     event.target.form.dispatchEvent(new Event("submit", { cancelable: true }));
                //     event.preventDefault();
                //     let textarea = document.querySelector("#message" + this.channelId)
                //     // document.documentElement.style.setProperty('--chat-textarea-height', "4rem");
                break;
            case "Shift":
                this.isShiftDown = true;
                break;
            case "Control":
            case "Alt":
            case "Command":
            case "Unidentified":
            case "CapsLock":
            case "Escape":
            case "Insert":
            case "Home":
            case "End":
            case "OS":
            case "ArrowLeft":
            case "ArrowRight":
            case "ArrowDown":
            case "ArrowUp":
                this.typingEvent();
                break;
            case " ":
                if (this.userSearchActivated) {
                    this.clearSearch();
                }
                this.typingEvent();
                break;
            case "@":
                this.clearSearch();
                this.checkSetSearch(false);
                this.typingEvent();
                break;
            default:
                if (this.userSearchActivated) {
                    // console.log("activated");
                    this.setQueryString(event);
                }
                // console.log(this.userSearchString);
                this.typingEvent();
                break;
        }
    }

    public onKeyPressUp(event) {
        if (event.key === "Shift") {
            this.isShiftDown = false;
        }
    }

    private findCursor() {
        if (window.getSelection && window.getSelection().getRangeAt) {
            return window.getSelection().getRangeAt(0);
        } else {
            console.error("browser does not support range");
            return null;
        }
    }

    // unused input here 'byAddButton' ... 
    private setQueryString(event: KeyboardEvent, byAddButton: boolean = false) {
        if (!this.userSearchCursor) {
            return false;
        }
        let currentCursor = this.findCursor();
        // console.log("current cursor offset", currentCursor.startOffset);
        // console.log("search cursor offset", this.userSearchCursor.startOffset)
        let diff = currentCursor.startOffset - this.userSearchCursor.startOffset;
        // console.log("diff", diff);
        // console.log("string length + 1", this.userSearchString.length + 1);
        let splitIndex = diff - 1;
        let splitString = this.userSearchString.split('');
        switch (true) {
            case (diff == this.userSearchString.length + 1):
                if (event.keyCode == 8) {
                    splitString.splice(splitIndex - 1, 1)
                    this.userSearchString = splitString.join('');
                } else if (event.keyCode == 46) {
                    this.clearSearch();
                } else {
                    this.userSearchString += event.key;
                }
                if (this.userSearchString.length > 0) {
                    // console.log("going to search!!!")
                    this._getUsersFromChannelBySearchQuery(this.userSearchString);
                } else {
                    this.userSearchList = [];
                }
                break;
            case (diff < this.userSearchString.length + 1 && diff > 0):
                // console.log("new Diff: " + splitIndex);
                if (event.keyCode == 8) {
                    if (diff > 1) {
                        splitString.splice(splitIndex - 1, 1)
                        this.userSearchString = splitString.join('');
                    } else {
                        this.clearSearch();
                    }
                } else if (event.keyCode == 46) {
                    splitString.splice(splitIndex, 1)
                    this.userSearchString = splitString.join('');
                } else {
                    splitString.splice(splitIndex, 0, event.key)
                    this.userSearchString = splitString.join('');
                }
                if (this.userSearchString.length > 0) {
                    this._getUsersFromChannelBySearchQuery(this.userSearchString);
                } else {
                    this.userSearchList = [];
                }
                break;
            default:
                this.clearSearch();
                break;
        }
    }

    private clearSearch() {
        this.userSearchList = [];
        this.userSearchActivated = false;
        this.userSearchString = "";
        this.userSearchCursor = null;
    }

    private checkSetSearch(addFromButton: boolean = false) {
        // this.messageEditorElement = document.querySelector(`#message${this.channelId} .NgxEditor__Content`)
        // console.log(this.messageEditorElement);
        let nodeInfo: any = this.getNodeInfo();
        switch (nodeInfo.type) {
            case 1://Element
                if (this.checkPrevSpace(nodeInfo)) {
                    if (addFromButton) {
                        this.addTextAndCursor(nodeInfo, '@');
                        break;
                    }
                    this.userSearchActivated = true;
                    this.userSearchCursor = nodeInfo.cursor;
                    break;
                } else if (addFromButton) {
                    this.addTextAndCursor(nodeInfo, '@');
                }
            case 3://Text
                if (this.checkPrevSpace(nodeInfo)) {
                    if (addFromButton) {
                        this.addTextAndCursor(nodeInfo, '@');
                        break;
                    }
                    this.userSearchActivated = true;
                    this.userSearchCursor = nodeInfo.cursor;
                } else if (addFromButton) {
                    // console.log("first cursor");
                    this.addTextAndCursor(nodeInfo, ' @');
                }
                break;
            default:
                this._toastService.info("Found Node Type: " + nodeInfo.type)
                // console.log(nodeInfo.type)
                break;
        }
    }

    private addTextAndCursor(nodeInfo: any, text: string) {
        // console.log("addTextAndCursor cursor", nodeInfo.cursor)
        let cursor = nodeInfo.cursor;
        let newCaret = cursor.startOffset;
        this.addStringToMessage(text);
        // this.checkSetSearch(false);
        let msg: any = nodeInfo.cursor.startContainer;
        // let newCaret = msg.innerText ? msg.innerText.length : msg.data.length;
        let setpos = document.createRange();
        let set = window.getSelection();
        // console.log("newCaret location", newCaret + text.length);
        /// sets focus 1 offset to mimiick keypressdown
        setpos.setStart(msg.childNodes[0] ? msg.childNodes[0] : msg, newCaret + text.length);
        setpos.collapse(true);
        set.removeAllRanges();
        set.addRange(setpos);
        if (msg.childNodes[0]) {
            msg.focus()
        }
        this.userSearchCursor = this.getNodeInfo().cursor;
        //Real cursor location
        // setpos.setStart(msg.childNodes[0] ? msg.childNodes[0] : msg, newCaret + text.length);
        // setpos.collapse(true);
        // set.removeAllRanges();
        // set.addRange(setpos);
        // if (msg.childNodes[0]) {
        //     msg.focus()
        // }
        // console.log("addTextAndCursor cursor2", this.userSearchCursor)
        this.userSearchActivated = true;
    }

    private checkPrevSpace(node: any,) {
        //offset is increased for press down
        let data = node.data
        // console.log("checkPrevSpace cursor", node.cursor);
        // console.log(node.data);
        let prevChar: any, nextChar: any;
        if (data[node.cursor.startOffset - 1]) {
            prevChar = data[node.cursor.startOffset - 1];
            nextChar = data[node.cursor.startOffset];
            // console.log({ data: data, offset: node.cursor.startOffset, prevChar: prevChar, nextChar: nextChar })
            if (prevChar != ' ' || !prevChar) {
                // console.log("search cant start");
                return false;
            }
            if (!nextChar || nextChar == " ") {
                // console.log("start search");
                return true;
            } else {
                // console.log("search cant start");
                return false;
            }
        }
        // console.log("start search");
        return true;
    }

    public replaceTextFromSearch(text: string) {
        let parentNode = this.userSearchCursor.startContainer;
        let newString, newNode;
        switch (parentNode.nodeType) {
            case 1://Element
                newString = this.newMessageString(parentNode.innerText, text);
                newNode = document.createTextNode(newString)
                this.userSearchCursor.startContainer.innerText = '';
                this.userSearchCursor.insertNode(newNode);
                // console.log(newString);
                this.clearSearch();
                break;
            case 3://Text
                newString = this.newMessageString(parentNode.data, text);
                newNode = document.createTextNode(newString)
                this.userSearchCursor.startContainer.data = '';
                this.userSearchCursor.insertNode(newNode);
                // console.log(newString);
                this.clearSearch();
                break;
            default:
                this._toastService.info("Found Node Type: " + parentNode.nodType)
                // console.log(parentNode.nodType)
                break;
        }
    }

    public newMessageString(parentString: any, name: string) {
        let splitNodeString: any = parentString.split('');
        splitNodeString.splice(this.userSearchCursor.startOffset + 1, this.userSearchString.length, name);
        return splitNodeString.join('');
    }

    private getNodeInfo() {
        let cursor = this.findCursor();
        let parentNode: any = cursor.startContainer;
        // console.log(cursor);
        switch (parentNode.nodeType) {
            case 1://Elemet
                return { type: 1, data: parentNode.innerText, cursor: cursor }
            case 3://Text
                return { type: 3, data: parentNode.data, cursor: cursor }
        }
    }

    resizeTextarea() {
        let textarea: any = document.querySelector("#message" + this.channelId)
        // console.log(textarea.scrollHeight);
        // console.log(textarea.scrollTop);
        if (textarea.scrollHeight > 38 && (textarea.scrollTop > 0 || textarea.scrollTop > 31)) {
            document.documentElement.style.setProperty('--chat-textarea-height', "6rem");
        } else {
            document.documentElement.style.setProperty('--chat-textarea-height', "4rem");
        }
    }


    // todo: create a click handler method to handle the keypress event for the input box
    // todo: in the keypress event handler, detect pressing enter and send input contents as a message, then clear input
    // todo: if not pressing enter, then call a private typing event method 

    public sendMessageToClient(): void {
        const data = `${this.newMessage.value.content}`;
        var channelName = document.location.pathname;
        this._channelService.sendMessageToClient(data, channelName);
    }

    retrieveInitialMessages(channelId: any) {
        // alert(channelId)
        // console.log("ChannelId: " + channelId);
        this.scrollBox = document.querySelector("#messages" + this.channelId);
        this._apiCallsService.getInitialMessages(channelId)
            .subscribe(res => {
                if(res.status === 200) {
                    // console.log("initial messages?", res.body)
                    let messages: any = res.body;
                    messages.forEach((message, i) => {
                        let messageId = message.channelUserKeyNavigation.channelUserId.toUpperCase();
                        let previousMessageId = 1;
                        let messageDate = new Date(message.when);
                        let messageTimeLength = (messageDate.getHours() * 60) + messageDate.getMinutes();
                        let previousDate = new Date();
                        let previousTimeLength = 0;
                        // 
                        if(message.channelUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                            this.subscriptions.push(this._authService.displayNameBehaviorSubject.pipe(filter(n => n !== ''),tap((name) => {
                                message.channelUserKeyNavigation.displayName = name;
                                // for welcome message ... 
                                if(this.channelInfo.chatUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                                    this.channelInfo.chatUserKeyNavigation.displayName = name;
                                }
                            })).subscribe());
                            this.subscriptions.push(this._authService.userHashBehaviorSubject.pipe(filter(h => h !== ''),tap((hash) => {
                                message.channelUserKeyNavigation.hash = hash;
                                // for welcome message ... 
                                if(this.channelInfo.chatUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                                    this.channelInfo.chatUserKeyNavigation.hash = hash;
                                }
                            })).subscribe());
                        }
                        
                        if (i < messages.length - 1) {
                            previousMessageId = messages[i + 1].channelUserKeyNavigation.channelUserId.toUpperCase();
                            previousDate = new Date(messages[i + 1].when);
                            previousTimeLength = (previousDate.getHours() * 60) + previousDate.getMinutes();
                        };
                        if (messageId === previousMessageId) {
                            message.sameId = true;
                        } else {
                            message.sameId = false;
                        }
                        if (messageDate.toDateString() === previousDate.toDateString()) {
                            message.sameDate = true;
                            if (messageTimeLength - previousTimeLength < 10) {
                                message.closeTime = true;
                            }
                        } else {
                            message.sameDate = false;
                        }
                        // if (this.checkURL(message.content)) {
                        //     message.isURL = true;

                        //     if (this.checkImage(message.content))
                        //         message.isImage = true;

                        // } else {
                        //     message.isURL = false;
                        // }
                    });
                    this.messages = messages;
                    this.preLoad = false;

                    if (this.messages.length > 0) {

                        // 🚧 fix scroll on load (may have issue sometimes delayed on page load) //
                        this.fixScroll();

                        this.firstMessageDate = this.messages[0].when;
                        this.pagination = {};
                        this.pagination = JSON.parse(res.headers.get('X-Pagination'));
                        // console.log("pag", this.pagination);
                        this.checkForPagination();
                    } else {
                        this.firstMessageDate = new Date().getTime().toString();
                    }
                    if (this.loadMoreMessagesAvailable) {
                        this.scrollTopBlock = true;
                        this.retrieveMoreMessages();
                    } else if (this.channelInfo.welcome) {
                        this.messages.push(this.welcomeMessage());
                    } else {
                        this.messages.push(this.defaultNoMoreMessages());
                    }
                } else {
                    console.error("error getting inital messages", res);
                    this._toastService.error("Could not get messages, please refresh the page");
                }
            });
    }

    retrieveMoreMessages() {
        // this._apiCallsService.getGenericForPagination(this.pagination.nextPageLink + '&loadDate=' + moment(this.firstMessageDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'))
        this._apiCallsService.getGenericForPagination(this.pagination.nextPageLink)
            .subscribe(res => {
                if(res.status === 200) {
                    let messages: any = res.body;
                    messages.forEach((message, i) => {
                        let messageId = message.channelUserKeyNavigation.channelUserId.toUpperCase();
                        let previousMessageId = 1;
                        let messageDate = new Date(message.when);
                        let messageTimeLength = (messageDate.getHours() * 60) + messageDate.getMinutes();
                        let previousDate = new Date();
                        let previousTimeLength = 0;
                        // 
                        if(message.channelUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                            this.subscriptions.push(this._authService.displayNameBehaviorSubject.pipe(filter(n => n !== ''),tap((name) => {
                                message.channelUserKeyNavigation.displayName = name;
                                // for welcome message ... 
                                if(this.channelInfo.chatUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                                    this.channelInfo.chatUserKeyNavigation.displayName = name;
                                }
                            })).subscribe());
                            this.subscriptions.push(this._authService.userHashBehaviorSubject.pipe(filter(h => h !== ''),tap((hash) => {
                                message.channelUserKeyNavigation.hash = hash;
                                // for welcome message ... 
                                if(this.channelInfo.chatUserKeyNavigation.channelUserId === this._authService._userInfo.sub) {
                                    this.channelInfo.chatUserKeyNavigation.hash = hash;
                                }
                            })).subscribe());
                        }
                        
                        if (i === 0) {
                            let lastMessageId = this.messages[this.messages.length - 1].channelUserKeyNavigation.channelUserId.toUpperCase();
                            let lastMessageDate = new Date(this.messages[this.messages.length - 1].when);
                            let lastTimeLength = (lastMessageDate.getHours() * 60) + lastMessageDate.getMinutes();
                            if (lastMessageId === messageId) {
                                this.messages[this.messages.length - 1].sameId = true;
                            }
                            if (lastMessageDate.toDateString() === messageDate.toDateString()) {
                                this.messages[this.messages.length - 1].sameDate = true;
                                if (messageTimeLength - lastTimeLength < 10) {
                                    this.messages[this.messages.length - 1].closeTime = true;
                                }
                            }
                        }
                        if (i < messages.length - 1) {
                            previousMessageId = messages[i + 1].channelUserKeyNavigation.channelUserId.toUpperCase();
                            previousDate = new Date(messages[i + 1].when);
                            previousTimeLength = (previousDate.getHours() * 60) + previousDate.getMinutes();
                        }
                        if (messageId === previousMessageId) {
                            message.sameId = true;
                        } else {
                            message.sameId = false;
                        }
                        if (messageDate.toDateString() === previousDate.toDateString()) {
                            message.sameDate = true;
                            if (messageTimeLength - previousTimeLength < 10) {
                                message.closeTime = true;
                            }
                        } else {
                            message.sameDate = false;
                        }
                        // if (this.checkURL(message.content)) {
                        //     message.isURL = true;
                        //     if (this.checkImage(message.content))
                        //         message.isImage = true;
                        // } else {
                        //     message.isURL = false;
                        // }
                        this.messages.push(message);
                    })
                    // check for pagination	
                    this.pagination = JSON.parse(res.headers.get('X-Pagination'));
                    this.checkForPagination();
                    if (!this.loadMoreMessagesAvailable && this.channelInfo.welcome) {
                        this.messages.push(this.welcomeMessage());
                    } else if (!this.loadMoreMessagesAvailable) {
                        this.messages.push(this.defaultNoMoreMessages());
                    }
                } else {
                    console.error("error getting more messages", res)
                }
            });
    }

    checkForPagination() {
        this.loadMoreMessagesAvailable = false;
        if (Number(this.pagination.currentPage) < Number(this.pagination.totalPages)) {
            this.loadMoreMessagesAvailable = true;
        };
        this.scrollTopBlock = false;
    }

    // ... 
    fixScroll() {
        let scrollEl: any = document.getElementById(`messages${this.channelId}`);
        if (scrollEl) {
            setTimeout(() => {
                scrollEl.scrollTop = scrollEl.scrollHeight;
                // console.log('📜 el:', scrollEl,'top:', scrollEl.scrollTop, 'set to =>', 'height:', scrollEl.scrollHeight);
            }, 500);
        } else {
            return;
        }
    }

    // new // 
    scrollCheck() {
        if (!this.scrollBox) {
            this.scrollBox = document.querySelector("#messages" + this.channelId);
        };
        // ℹ your position //
        let boxPos = Math.ceil(this.scrollBox.scrollTop);
        //console.log("📜 scroll pos: " + boxPos);
        // if @top & more messages //
        if (boxPos < 1 && !this.scrollTopBlock) {
            if (this.loadMoreMessagesAvailable) {
                this.scrollTopBlock = true;
                this.retrieveMoreMessages();
                // jump down a bit to offset loading time ? 
                this.scrollBox.scrollTop = 20;
            }
        }
    }

    _getChannelByName(intakeUser: any) {
        let channelName = "PO_" + intakeUser.chatUserKeyNavigation.channelUserId;
        // this.channelDisplayName = intakeUser.chatUserKeyNavigation.displayName;
        this._apiCallsService.getChannelByNameAndProjectId(channelName, this.teamProjectId)
            .subscribe(data => {
                if (data.status === 200) {
                    let channel: any = data.body[0];
                    this.channelInfo = channel;
                    this.channelId = this.channelInfo.channelId;
                    // if (this.channelInfo.welcome) {
                    //     this.messages.push(this.welcomeMessage());
                    // }
                    this._channelService.join(this.channelId);
                    this.getChannelInfo();
                } 
                // else {
                //     // console.log(data);
                // }
            });
    }

    _getChannel(channelId: any, withMessages: boolean = false) {
        if (channelId === 'composeNewMessage') {
            return;
        }
        // this.channelDisplayName = intakeUser.chatUserKeyNavigation.displayName;
        this._apiCallsService.getChannel(channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    let channel: any = data.body;
                    // console.log("Got channel info", channel)
                    this.channelId = channelId;
                    this.channelInfo = channel;
                    // console.log("CHANNEL INFO!!!!!!!!", channel)
                    // if (this.channelInfo.welcome) {
                    //     this.messages.push(this.welcomeMessage());
                    // }
                    this.getChannelInfo();
                    if (withMessages) {
                        this.retrieveInitialMessages(channelId);
                    }
                } 
                // else {
                //     // console.log(data);
                // }
            });
    }

    getChannelInfo() {
        this.isPrivate = this.channelInfo.private;
        this.privateCheck = true;
        if (this.channelInfo.type === 'quick') {
            this.isQuickMeeting = true;
            // console.log("❔ is this a quick meeting? :::", this.isQuickMeeting)
        }
        //alert("Chat comp " + this.channelId);
        // this.retrieveInitialFiles();
        // this._sharedFileList();
    }

    _updateUnreadMessageCount(messageChannelId: any) {
        this._channelService.updateUnreadMessageCount(messageChannelId);
    }

    // _onError() {
    //     console.error("User not found");
    // }

    checkURL(message: string) {
        // let expression = /(?:(?:https?|ftp):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gmi;
        let expression = /(?:(?:https?|ftp):\/\/|www\.)([\w_-]+)([\w.,{}@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gmi;
        let regex = new RegExp(expression);
        if (message.match(regex)) {
            return true;
        } else {
            return false;
        }
    }

    checkImage(message: string) {
        // console.log('Check Image Called');
        let imgExpression = /\.(jpeg|jpg|gif|png)$/;
        let regex = new RegExp(imgExpression);
        if (message.match(regex)) {
            // console.log('IMAGE FOUND!');
            return true;
        } else {
            // console.log('IMAGE NOT FOUND!');
            return false;
        }
    };

    cleanURL(url: string) {
        let expression = /(http|ftp|https|):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/ig;
        let regex = new RegExp(expression);
        if (url.match(regex)) {
            return url;
        } else {
            return 'https://' + url;
        }
    }

    findUsersInMessage(users: any) {
        // let users = this.userExtractor(message);
        // let usernames = this.cleanUsernames(users)
        // this._checkUserExists(usernames);
        //Verify function to make sure users exist()
        let message = this.newMessage.value.content;
        let newValue: any = message.split("");
        for (let i = users.length - 1; i >= 0; i--) {
            let username = users[i].username;
            // cote added mat ... 
            let aTag = `<span class='chat-username-at' matTooltip='${username}' matTooltipClass='tooltip-default' [matTooltipShowDelay]='tooltipDelay' >${username}</span>`;
            let aTagArray = aTag.split("");
            newValue.splice(users[i].first, users[i].length, ...aTagArray);
            // console.log(newValue.join(""))
        }
        // console.log(users);
        // console.log(newValue.join(""));
        return newValue.join("");
    }

    userExtractor(message:any) {
        let expression = /^\<p>(@[a-zA-Z]+\.\w+)|\s(@[a-zA-Z]+\.\w+)/g;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let userObjects = [];
        for (let match of result) {
            // console.log(match)
            if (match[1]) {
                let diff = 3;//Accounts for <p>
                userObjects.push({
                    first: match.index + diff,
                    last: match.index + diff + match[1].length,
                    length: match[1].length,
                    username: match[1],
                });
            } else if (match[2]) {
                userObjects.push({
                    first: match.index,
                    last: match.index + match[0].length,
                    length: match[0].length,
                    username: match[0]
                });
            }
        }
        // console.log("extracted users", userObjects);
        return userObjects;
    }

    cleanUsernames(users:any) {
        let usernameList = [];
        users.forEach(user => {
            usernameList.push(user.username.replace(/\s|@/g, ''));
        });
        // console.log("cleanUsernames", usernameList)
        return usernameList;
    }

    _checkUserExists(users:any) {
        // console.log(users);
        let usernames = this.cleanUsernames(users)
        this._apiCallsService.checkUsersExistByUsername(usernames)
            .subscribe(res => {
                if (res.status === 200) {
                    let newUserNames = res.body;
                    // console.log("checkUsernames", newUserNames)
                    let newUsers = this.compareUsersList(users, newUserNames);
                    let newMessage = this.findUsersInMessage(newUsers);
                    let url = this.router.url;
                    // console.log("final List", newUsers)
                    this.sendMessage({ "message": newMessage, "atUsers": newUserNames, "url": url });
                } else {
                    // console.log(res)
                    this._toastService.error("Could not find @ Users");
                }
            });
    }

    private compareUsersList(userObjects: any[], usernames: any[]) {
        return userObjects.filter(user => usernames.find(username => username.userName.toLowerCase() === user.username.replace(/\s|@/g, '').toLowerCase()));
    }

    get mc() { return this.newMessage.controls; }

    _getChannelUser() {
        this._authService.getChannelUserInfo()
            .subscribe(data => {
                if(data.status === 200) {
                    let user: any = data.body;
                    this.user.hash = user.hash;
                } else {
                    console.error("error getting user image", data)
                }
            });
    }

    // uploadChange(isUploading:boolean) {
    //     this.fileIsUploading = isUploading;
    // }

    startAtUser() {
        this.clearSearch();
        this.checkSetSearch(true);
    }

    // 😀 for emoji button
    //new
    addStringToMessage(input:any) {
        this.editor.commands.insertText(input).exec();
    }

    //SEARCH MEMBERS/OWNERS OF A CHANNEL
    public _getUsersFromChannelBySearchQuery(searchString: string) {
        this._chManageService.searchForMembersAndOwnersByChannelId(this.channelId, searchString)
            .subscribe(data => {
                if (data.status === 200) {
                    let list = data.body;
                    this.userSearchList = list;
                } else {
                    // console.error(JSON.stringify(data));
                    this._toastService.error("Could not search Channel");
                }
            });
    }

    addUser(i:number) {
        let username = this.userSearchList[i].username.toLowerCase();
        this.replaceTextFromSearch(username + " ");
    }

    welcomeMessage() {
        return {
            content: this.channelInfo.welcome,
            when: null,
            welcomeMessage: true,
            channelUserKeyNavigation: this.channelInfo.chatUserKeyNavigation
        }
    }

    defaultNoMoreMessages() {
        let usernames = () => {
            let names: string = " with ";
            let owners = this.getOwnersFromChannelInfo();
            if (owners.length === 0) {
                return '';
            }
            owners.forEach((user, i) => {
                names += user.displayName;
                if (i == owners.length - 2) {
                    names += " & ";
                } else if (i < owners.length - 1) {
                    names += ", ";
                }
            })
            return names;
        };
        let name = (dmType: string) => {
            if (dmType === 'direct') {
                return usernames();
            } else if (dmType === 'app') {
                if (this.channelInfo.name.startsWith('PO_')) {
                    return " in the Intake App";
                } else {
                    return " in the App";
                }
            } else {
                return " in " + this.channelInfo.name;
            }
        }
        if (!this.channelInfo.chatUserKeyNavigation) {
            // this._toastService.error("check console");
            console.error("channel info for default message, missing chatUserKey, channelinfo:", this.channelInfo);
        }
        return {
            content: "This is the beginning of the conversation" + name(this.channelInfo.type) + ".",
            when: null,
            welcomeMessage: true,
            channelUserKeyNavigation: this.channelInfo.chatUserKeyNavigation
        }
    }

    getOwnersFromChannelInfo() {
        let owners = [];
        // console.log("channel info", this.channelInfo);
        // console.log("owners info", this.channelInfo.chatRoomChatUsers);
        if (this.channelInfo) {
            this.channelInfo.chatRoomChatUsers.forEach(info => {
                let user = info.chatUserKeyNavigation;
                if (user.channelUserId !== this.user.sub) {
                    owners.push(user);
                }
            });
        }
        // console.log("found owners", owners);
        return owners;
    }

    handleBrokenImage(idx:number): void {
        // console.info('yeeeeee')
        this.messages[idx].channelUserKeyNavigation.hash = '1';
    }
}
