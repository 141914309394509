import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BlobServiceClient, BlockBlobClient} from '@azure/storage-blob';
import { TokenCredential } from '@azure/core-http';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { ToastNotificationService } from 'src/app/services/toast-notification.service';
import { consoleLogStyle } from '../../varaibles';
import { AuthService } from 'src/app/core/auth.service';


@Component({
    selector: 'profile-img-upload',
    templateUrl: './profile-img-upload.component.html',
    styleUrls: ['./profile-img-upload.component.scss']
})
export class ProfileImgUploadComponent implements OnInit, OnChanges {

    @Input('userId') userId: string;
    @Output('uploadStatus') uploadStatus = new EventEmitter();
    @Output('createContactUploadError') createContactUploadError = new EventEmitter();

    // 🚧 
    @Input('isCustomContact') isCustomContact: boolean; // on init has val ... 
    @Output('image') image: EventEmitter<any> = new EventEmitter();
    @Input('imageToSubmit') imageToSubmit: File; // check for !! in changes ... 
    @Input('contactId') contactId: string; // check for !! in changes ... 
    @Output('complete') complete: EventEmitter<string> = new EventEmitter();

    @Input("isEditContact") isEditContact: boolean;
    @Output('updateComplete') updateComplete: EventEmitter<string> = new EventEmitter();

    public commitInfo: any = {};
    public uploadFile: any = {};

    public blobServiceClient: BlobServiceClient;
    public blockBlobClient: BlockBlobClient;
    public baseUrl: string = '';
    public sasToken: TokenCredential;

    public fileIsUploading: boolean = false;

    constructor(
        private _toastService: ToastNotificationService,
        private _fileStorageService: FileStorageService,
        private _authService: AuthService
    ) { }

    ngOnInit() {
        if(!this.isCustomContact) {
            this.fileInit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageToSubmit || changes.contactId) {
            if (!!this.imageToSubmit && !! this.contactId && this.isCustomContact) {
                // console.log("%c image to submit ", consoleLogStyle, this.imageToSubmit);
                // upload image
                this.fileInit();
            }
        }
    }

    fileInit() {
        if(this.isCustomContact) {
            this.commitInfo = {
                "userId": this.contactId,
                "autoRename": false,
                "mode": "add",
                "isCustomContact": true
            };
            this.uploadFile = this.imageToSubmit;
            this._fileUpload();
            // console.log('%c profile-img component initialized, is custom contact, commit info? ', consoleLogStyle, this.commitInfo)
        } else {
            // console.log('%c profile-img component initialized, is custom contact? ', consoleLogStyle, this.isCustomContact)
            this.commitInfo = {
                "userId": this.userId,
                "autoRename": false,
                "mode": "add",
                "isCustomContact": false
            };
        }
    }

    /*
        only accept { images } 
        
        .png = [ 8950 ]
        .jpg, .jpeg = [ FFD8 ]
        .jpegXL = [ FF0A, 0A87 ]
    */

    // called on input change //
    fileName(e: Event) {
        const target = e.target as HTMLInputElement; // ang12 ts fix
        const files = target.files as FileList; // ang12 ts fix
        this.fileIsUploading = true;
        this.uploadStatus.emit(this.fileIsUploading); // emit true //
        // console.log('loading...', this.fileIsUploading)
        this.uploadFile = files[0];
        // 
        let fileReader = new FileReader();
        fileReader.onloadend = (e: any) => {
            
            let arr = (new Uint8Array(<ArrayBuffer>e.target.result)).subarray(0, 2);
            // console.log("buffer array :::", arr)
            let hex = "";
            for (let i = 0; i < arr.length; i++) {
                hex += arr[i].toString(16);
            }
            // console.log("file hex :::", hex)
            // Check the file signature against known types
            let img = ['8950', 'FFD8', 'FF0A', '0A87'];
            
            if (img.includes(hex.toUpperCase())) {
                // console.log("is IMAGE :::", hex)
                if(this.isCustomContact) {
                    this.image.emit(this.uploadFile); // emit the image to Create Contact modal for preview ... 
                    this.fileIsUploading = false; 
                    this.uploadStatus.emit(this.fileIsUploading);
                    return;
                } else {
                    this._fileUpload();
                }
            } else {
                this.fileIsUploading = false;
                this.uploadStatus.emit(this.fileIsUploading);
                this._toastService.error("Can only upload Image files :   .png, .jpg, .jpeg, .jfif, .jpegXL.")
                return;
            }
        };
        fileReader.readAsArrayBuffer(this.uploadFile);
    }


    // 
    _fileUpload() {
        this._fileStorageService.fileStorageProfileUpload(this.commitInfo)
            .subscribe(res => {
                if(res.status === 200) {
                    // console.log("file upload res,", res)
                    let url = res.body.url;
                    let hash = res.body.hash; // needed before UPDATE submition ... 
                    // console.log("url: " + url);
                    let path = `/${hash}`;
                    let indexOfQueryStart = url.indexOf("?");
                    this.baseUrl = url.substring(0, indexOfQueryStart) + "/" + this.trimStart("/", path);
                    this.sasToken = url.substring(indexOfQueryStart);
                    this.blockBlobClient = new BlockBlobClient(this.baseUrl + this.sasToken);
                    this.uploadToBlobStorage(hash);
                } else {
                    this.fileIsUploading = false;
                    this.uploadStatus.emit(this.fileIsUploading); 
                    this.createContactUploadError.emit(this.contactId);
                    this._toastService.error("There was a problem uploading your photo.");
                }
            });
    }

    async uploadToBlobStorage(hash:string) {
        const content = this.uploadFile;
        
        // let uploadBlobResponse: any;
        try {
            let uploadBlobResponse: any;
            uploadBlobResponse = await this.blockBlobClient.upload(content, content.size);
            // console.log(uploadBlobResponse, 'loaded 1st ?')
        } catch (err) {
            this.fileIsUploading = false;
            this.uploadStatus.emit(this.fileIsUploading); 
            this.createContactUploadError.emit(this.contactId);
            this._toastService.error("There was a problem uploading your photo.");
        } finally {
            this.fileIsUploading = false;
            this.uploadStatus.emit(this.fileIsUploading);
            if(this.isEditContact) {
                this.updateComplete.emit(hash);
            } else {
                this.complete.emit(hash);
                this._authService.userHashBehaviorSubject.next(hash);
            }
        }
        // console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId)
        // console.log(uploadBlobResponse, 'loaded?')
    }


    trimStart(character: string, string: string) {
        var startIndex = 0;
        while (string[startIndex] === character) {
            startIndex++;
        }
        return string.substring(startIndex);
    }
}
