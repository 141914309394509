import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "messageWithImage",
})

export class MessageWithImagePipe implements PipeTransform {
    transform(value: string, args?: any): any {
        let urlObjects = this.urlExtractor(value);
        let newValue: any = value.split("");
        for (let i = urlObjects.length - 1; i >= 0; i--) {
            let url = urlObjects[i].string;
            let aTag = `<a href="${this.cleanURL(url)}" target="_blank"><img class="chat-image" src=${this.cleanURL(url)} /></a>`;
            let aTagArray = aTag.split("");
            newValue.splice(urlObjects[i].first, urlObjects[i].length, ...aTagArray);
        }
        return `<span>${newValue.join("")}</span>`;
    }

    urlExtractor(string) {
        let expression = /(?:(?:https?|ftp):\/\/|www\.)([\w_-]+)([\w.,{}@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gmi;
        let regex = new RegExp(expression);
        let result = string.matchAll(regex);
        let urlObjects = [];
        for (let match of result) {
            urlObjects.push({
                first: match.index, 
                last: match.index + match[0].length,
                length: match[0].length,
                string: match[0]
            });
        }
        return urlObjects;
    }

    cleanURL(url: string) {
        let expression = /(http|ftp|https|):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/ig;
        let regex = new RegExp(expression);

        if (url.match(regex)) {
            return url;
        } else {
            return 'https://' + url;
        }
    }
}
