<div 
	*ngIf="!isChannelBreak" 
	class="mobile-channel-nav flex-center-all"
	[class.modal-open]="!isDesktop">
	<div 
		*ngIf="!isDesktop" 
		class="mobile-nav-left">
		<!-- home -->
		<a 
			routerLink="/home" 
			class="nav-link flex-center-all py-3" 
			id="home-button">
			<i class="fas fa-home"></i>
		</a>
	</div>
	<div 
		class="mobile-nav-right" 
		[class.in-video]="inVideo">
		<div class="channel-title">
			<h3 class="text-ellipsis">
				{{ channelName }}
			</h3>
		</div>
		<div class="channel-buttons">
			<button
				(click)="toggleMobileVideo()"
				class="side-top-btn video-call-btn"
				[class.active-tab]="activeTab === 4"
				[class.in-video]="inVideo && activeTab !== 4"
				matTooltip="toggle video"
				matTooltipClass="tooltip-default"
			>
				<i class="fas fa-video"></i>
			</button>
			<button
				*ngIf="!isQuickMeeting && !isDirectMessage"
				(click)="toggleMobileEvents()"
				class="side-top-btn"
				[class.active-tab]="activeTab === 5"
				matTooltip="toggle events"
				matTooltipClass="tooltip-default"
			>
				<i class="fas fa-calendar-day"></i>
			</button>
			<button
				(click)="toggleChat()"
				class="side-top-btn"
				[class.active-tab]="activeTab === 1"
				matTooltip="toggle chat"
				matTooltipClass="tooltip-default"
			>
				<i class="fas fa-comment-alt"></i>
			</button>
			<button
				*ngIf="!isDirectMessage"
				(click)="toggleUsers()"
				class="side-top-btn"
				[class.active-tab]="activeTab === 2"
				matTooltip="toggle users"
				matTooltipClass="tooltip-default"
			>
				<i class="fas fa-user-friends"></i>
			</button>
			<button
				*ngIf="allowFiles"
				(click)="toggleFiles()"
				class="side-top-btn"
				[class.active-tab]="activeTab === 3"
				matTooltip="toggle files"
				matTooltipClass="tooltip-default"
			>
				<i class="fas fa-folder"></i>
			</button>
		</div>
	</div>
</div>
<!-- 🚧🚧🚧 -->
<div
	*ngIf="!meetingEnded && (!meetingNotStarted || !isGuest)"
	class="core-channel-layout"
	[class.with-schedule]="!isQuickMeeting && !isDirectMessage"
	
	(window:resize)="desktopCheck()"
	(resize)="desktopCheck()">
	<div class="channel-schedule" *ngIf="isChannelBreak && !inVideo">
		<div class="video-trigger"></div>
		<div class="schedule-box" *ngIf="!isQuickMeeting && !isDirectMessage">
			<events 
				[isViewer]="isViewer" 
				[userInfo]="userInfo" 
				[incomingChannelObject]="channelInfo">
			</events>
		</div>
	</div>
	<!-- 🚧 DEFAULT CHANNEL VIDEO -->
	<div
		*ngIf="!isViewer"
		class="channel-video"
		[ngClass]="{
			'side-hidden': !showSide,
			'video-open': inVideo,
			'hide-video-display': !showMobileVideo && !isChannelBreak
		}">
		<jitsi-video
			[isGuest]="isGuest"
			[channelId]="channelId"
			[channelInfo]="channelInfo"
			[userName]="username"
			[preventList]="jitsiPreventList"
			(openChatEvent)="toggleChat()"
			(openUsersEvent)="toggleUsers()"
			(openFilesEvent)="toggleFiles()"
			(startCallEvent)="videoStarted()"
			(endCallEvent)="videoEnded()"
			(participantsListOut)="getParticipantsInCall($event)">
		</jitsi-video>
		<!-- <div
            class="channel-bottom-overlay"
            *ngIf="!inVideo && isSafariOrOtherBrowser"
        >
            <p>
                For the best experience, VIA3 recommends Chrome, Firefox, or
                Edge
            </p>
        </div>  Put back / reformat in newer UI-->
		<!-- <div class="welcome-message" *ngIf="!inVideo">
            <h5 class="mb-3">Welcome Message</h5>
            <div class="box-quote active-quote">
                <i class="quote-icon fas fa-quote-right flex-center-all"></i>
                <div class="box-quote-text scrollbar-new">
                    {{
                        channelInfo.welcome
                            ? channelInfo.welcome
                            : "A Welcome Message has not yet been created."
                    }}
                </div>
            </div>
        </div> -->
	</div>
	<div
		class="channel-side"
		[ngClass]="{
			'hide-side':
				!showSide ||
				(!showUsers &&
					!showChat &&
					!showFiles &&
					!showMobileEvents &&
					!isChannelBreak),
			'video-off': !inVideo
		}">
		<div class="channel-side-Info" *ngIf="isChannelBreak">
			<h5
				class="mx-3 text-ellipsis"
				matTooltip="{{
					channelInfo.type === 'direct'
						? 'Direct Message with'
						: (channelInfo.teamProjectKeyNavigation.name | quickmeetingChannelName)
				}}"
				matTooltipClass="tooltip-default"
			>
				{{
					channelInfo.type === "direct"
						? "Direct Message with"
						: (channelInfo.teamProjectKeyNavigation.name | quickmeetingChannelName)
				}}
			</h5>
			<h3 
				*ngIf="!isQuickMeeting"
				class="mx-3 text-ellipsis"
				matTooltip="{{ channelName }}"
				matTooltipClass="tooltip-default"
			>
				{{ channelName }}
			</h3>
			<h3
				*ngIf="isQuickMeeting"
				class="mx-3 text-ellipsis"
				matTooltip="{{ meetingTitle }}"
				matTooltipClass="tooltip-default"
			>
				{{ meetingTitle }}
			</h3>
		</div>
		<div class="channel-side-bar" *ngIf="isChannelBreak">
			<div class="side-bar-left"></div>
			<div class="side-bar-right" [class.expanded-view]="!inVideo">
				<button
					*ngIf="!isQuickMeeting && !isDirectMessage && inVideo"
					(click)="toggleMobileEvents()"
					class="side-top-btn"
					[class.active-tab]="activeTab === 5"
					matTooltip="toggle events"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-calendar-day"></i>
				</button>
				<button
					(click)="toggleChat()"
					class="side-top-btn"
					[class.active-tab]="activeTab === 1"
					matTooltip="toggle chat"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-comment-alt"></i><span>Chat</span>
				</button>
				<button
					*ngIf="!isDirectMessage"
					(click)="toggleUsers()"
					class="side-top-btn"
					[class.active-tab]="activeTab === 2"
					matTooltip="toggle users"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-user-friends"></i><span>Users</span>
				</button>
				<button
					*ngIf="allowFiles"
					(click)="toggleFiles()"
					class="side-top-btn"
					[class.active-tab]="activeTab === 3"
					matTooltip="toggle files"
					matTooltipClass="tooltip-default"
				>
					<i class="fas fa-folder"></i><span>Files</span>
				</button>
			</div>
		</div>
		<app-chat
			[incomingChannelId]="channelId"
			[disableFiles]="true"
			[class.hide-side-display]="!showChat"
		></app-chat>
		<manage-users
			[class.hide-side-display]="!showUsers"
			[channelInfo]="channelInfo"
			[userInfo]="userInfo"
			[isQuickMeeting]="isQuickMeeting"
			[meetingId]="meetingId"
			[meetingAttendees]="meetingAttendees"
			[isMeetingCreator]="isMeetingCreator"
			[videoStarted]="inVideo"
			[isViewer]="isViewer"
			(channelUpdated)="onChannelUpdate()"
			(updateAttendeesList)="getMeetingAttendees()"
		></manage-users>
		<!--   -->
		<!-- *ngIf="showFiles && hasAccess && !isViewer" -->
		<file-storage
			[componentId]="'channel-primary'"
			[channelContainerName]="channelContainerName"
			[channelName]="channelName"
			[channelId]="channelId"
			[isPortable]="inVideo"
			[isGuest]="isGuest"
			[isOwner]="isOwner"
			[isCreator]="isCreator"
			[showFiles]="showFiles"
			[class.hide-side-display]="!showFiles || (!hasAccess && !isViewer)"
		>
		</file-storage>
		<ng-container
			*ngIf="
				(!isChannelBreak || (inVideo && isChannelBreak)) &&
				!isQuickMeeting &&
				!isDirectMessage
			">
			<events
				[userInfo]="userInfo"
				[incomingChannelObject]="channelInfo"
				[preventEventClick]="true"
				[class.hide-side-display]="!showMobileEvents"
			>
			</events>
		</ng-container>
	</div>

	<!-- 🚧 LIVE STREAMING VIDEO -->
	<div
		*ngIf="isViewer"
		class="channel-video"
		[ngClass]="{
			'side-hidden': !showSide,
			'video-open': inVideo
		}">
		<app-live-streaming-video
			[channelId]="channelId"
			[channelInfo]="channelInfo"
			[userName]="username"
			[preventList]="jitsiPreventList"
			(openChatEvent)="toggleChat()"
			(openUsersEvent)="toggleUsers()"
			(openFilesEvent)="toggleFiles()"
			(startCallEvent)="videoStarted()"
			(endCallEvent)="videoEnded()"
			(participantsListOut)="getParticipantsInCall($event)"
		>
		</app-live-streaming-video>
		<!-- <div
            class="channel-bottom-overlay"
            *ngIf="!inVideo && isSafariOrOtherBrowser"
        >
            <p>
                For the best experience, VIA3 recommends Chrome, Firefox, or
                Edge
            </p>
        </div>  Put back / reformat in newer UI-->
		<!-- <div class="welcome-message" *ngIf="!inVideo">
            <h5 class="mb-3">Welcome Message</h5>
            <div class="box-quote active-quote">
                <i class="quote-icon fas fa-quote-right flex-center-all"></i>
                <div class="box-quote-text scrollbar-new">
                    {{
                        channelInfo.welcome
                            ? channelInfo.welcome
                            : "A Welcome Message has not yet been created."
                    }}
                </div>
            </div>
        </div> -->
	</div>
</div>

<!-- 🚧 IF MEETING HAS NOT STARTED YET -->
<div class="modal" *ngIf="meetingNotStarted">
	<div class="modal-container">
		<button
			*ngIf="!isGuest"
			class="close-modal-x flex-center-all"
			(click)="closeMeetingNotStarted()"
		>
			<i class="fas fa-times"></i>
		</button>
		
		<h3 *ngIf="isGuest" class="mt-3 mb-2">Welcome to VIA3 Quick Meeting</h3>
		
		<h3 
			class="mb-2"
			[class.mt-3]="!isGuest">This meeting has not started yet.</h3>
		
		<i>meeting will begin {{meetingStart | date: 'M/d/yy h:mm a'}}</i>
		
		<p class="mt-2">
			<i *ngIf="isGuest"><i class="far fa-info-circle me-1 end-info"></i>you can enter in - {{meetingStartForPipe|timeAgo:true}}</i>
		</p>
		
		<div class="flex w-100 mt-4">
			<button 
				*ngIf="!isGuest"
				(click)="closeMeetingNotStarted()"
				class="primary-button mx-auto">
				Enter Now
			</button>
		</div>
		<div 
			*ngIf="isGuest"
			class="flex w-100 mt-4">
			<button 
				class="primary-button is-link mx-auto">
				<a href="{{accountSiteRegister}}" target="_blank" rel="noopener noreferrer">Register with VIA3</a>
			</button>
			<button 
				class="primary-button is-link mx-auto">
				<a href="https://www.via3.com" target="_blank" rel="noopener noreferrer">More about VIA3</a>
			</button>
		</div>
	</div>
</div>

<!-- 💡 this one shows if a user/guest enters a Quick Meeting that has ended | canceled -->
<div class="modal" *ngIf="meetingEnded">
	<div class="modal-container">
		<h3 class="mt-3 mb-2">This meeting has ended.</h3>
		<p 
			*ngIf="!meetingCanceled"
			class="mb-4 ms-2">
			<i><i class="far fa-info-circle me-1 end-info"></i>meeting ended {{meetingEnd | date: 'M/d/yy h:mm a'}}</i>
		</p>
		<p 
			*ngIf="meetingCanceled"
			class="mb-4 ms-2">
			<i><i class="far fa-info-circle me-1 end-info"></i>meeting was canceled</i>
		</p>
		<div 
			*ngIf="!isGuest"
			class="flex w-100">
			<button 
				class="primary-button mx-auto" 
				(click)="goToHomepage()">
				<i class="fas fa-home me-3"></i>Go Home
			</button>
		</div>
		<div 
			*ngIf="isGuest"
			class="flex w-100">
			<button 
				class="primary-button is-link mx-auto">
				<a href="{{accountSiteRegister}}" target="_blank" rel="noopener noreferrer">Register with VIA3</a>
			</button>
			<button 
				class="primary-button is-link mx-auto">
				<a href="https://www.via3.com" target="_blank" rel="noopener noreferrer">More about VIA3</a>
			</button>
		</div>
	</div>
</div>

<!-- GUEST LOGIN MODAL -->
<div
	class="modal guest-modal"
	*ngIf="isGuest && isQuickMeeting && displayGuestModal">
	<div class="modal-container">
		<div class="guest-title mt-4">
			<h3 class="title-thin mb-1">Hello Guest,</h3>
			<p class="ps-2">please enter your name</p>
		</div>
		<form
			class="guest-name-form"
			[formGroup]="newGuestName"
			(document:keydown.enter)="
				newGuestName.valid && !isSettingGuestName
					? setGuestUserNameAndEnterMeeting(newGuestName.value.displayName)
					: ''"
			>
			<div class="form-control mt-4 mb-1">
				<input
					id="guest-name-input"
					name="displayName"
					formControlName="displayName"
					type="text"
					maxlength="249"
					minlength="2"
					placeholder="Please enter your name"
				/>
			</div>
			<div *ngIf="gn.displayName.errors" class="invalid-feedback">
				<div
					class="errorMessage"
					*ngIf="
						gn.displayName.touched &&
						(gn.displayName.errors.required || gn.displayName.errors.minlength)
					"
				>
					Name must be at least 2 characters
				</div>
				<div class="errorMessage" *ngIf="gn.displayName.errors.pattern">
					Numbers and Special Characters aren't allowed except for - ' .
				</div>
			</div>
			<button
				*ngIf="newGuestName.valid"
				type="button"
				class="secondary-button mx-auto mt-4 mb-2"
				(click)="setGuestUserNameAndEnterMeeting(newGuestName.value.displayName)"
				[disabled]="isSettingGuestName"
			>
				<ng-container *ngIf="!isSettingGuestName">Enter Meeting</ng-container>
				<ng-container *ngIf="isSettingGuestName"
					><i class="fas fa-spinner spinner"></i>&nbsp;Entering
					meeting...</ng-container
				>
			</button>
		</form>
	</div>
</div>
