<!-- 💡 parent opens, child closes ... -->
<div
    class="modal"
    id="global-modal"
    (click)="closeConfirm()"
    (document:keydown.escape)="closeConfirm()">
    <div 
        (click)="$event.stopPropagation();$event.preventDefault()"
        class="modal-container">
        <button 
            class="close-modal-x flex-center-all"
            (click)="closeConfirm()">
            <i class="fas fa-times"></i>
        </button>
        <!-- child component will .next(close | form touched) behavior subjects from modal service ... -->
        <ng-content></ng-content>
    </div>
</div>
