import { Pipe, PipeTransform } from "@angular/core";
import * as SharedRegex from "../shared/varaibles";

@Pipe({
    name: "messageWithLinks",
})

export class MessageWithLinksPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        let urlObjects = this.urlExtractor(value);
        if (urlObjects.length > 0) {
            let codeTagObjects = this.codeTagExtractor(value);
            let imgTagObjects = this.imgTagExtractor(value);
            let aTagObjects = this.aTagExtractor(value);
            // console.log("linksPipe", [urlObjects, aTagObjects, imgTagObjects]);
            let newValue: any = value.split("");
            for (let i = urlObjects.length - 1; i >= 0; i--) {
                if (
                    !codeTagObjects.some(tag => this.rangeChecker(tag, urlObjects[i])) &&
                    !imgTagObjects.some(tag => this.rangeChecker(tag, urlObjects[i])) &&
                    !aTagObjects.some(tag => this.rangeChecker(tag, urlObjects[i]))
                ) {
                    let url = urlObjects[i].urlString;
                    let tag, tagArray;
                    if (urlObjects[i].isImage) {
                        tag = `<img class="chat-image" src="${this.cleanURL(url)}"/>`;
                        // console.log("img tag: ", url)
                    } else {
                        tag = `<a href="${this.cleanURL(url)}" target="_blank">${url}</a>`;
                        // console.log("a tag: ", url)
                    }
                    tagArray = tag.split("");
                    newValue.splice(urlObjects[i].first, urlObjects[i].length, ...tagArray);
                    // console.log("did not match existing tags");
                } else {
                    // console.log("matched existing tags");
                }
            }
            return newValue.join("");
        } else {
            // console.log("No URL Objects: ", value)
            return value;
        }
    }

    urlExtractor(message) {
        let expression = SharedRegex.urlRegex;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let urlObjects = [];
        for (let match of result) {
            urlObjects.push({
                first: match.index,
                last: match.index + match[0].length,
                length: match[0].length,
                urlString: match[0],
                isImage: this.checkImage(match[0])
            });
        }
        return urlObjects;
    }

    aTagExtractor(message) {
        let expression = SharedRegex.aTagFullRegex;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let aTagObjects = [];
        for (let match of result) {
            aTagObjects.push({
                first: match.index,
                last: match.index + match[0].length,
                length: match[0].length,
                aTagString: match[0],
            });
        }
        return aTagObjects;
    }

    imgTagExtractor(message) {
        let expression = SharedRegex.imgTagRegex;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let imgTagObjects = [];
        for (let match of result) {
            imgTagObjects.push({
                first: match.index,
                last: match.index + match[0].length,
                length: match[0].length,
                imgTagString: match[0],
            });
        }
        return imgTagObjects;
    }

    codeTagExtractor(message) {
        let expression = SharedRegex.codeTagRegex;
        let regex = new RegExp(expression);
        let result = message.matchAll(regex);
        let codeTagObjects = [];
        for (let match of result) {
            codeTagObjects.push({
                first: match.index,
                last: match.index + match[0].length,
                length: match[0].length,
                codeTagString: match[0],
            });
        }
        return codeTagObjects;
    }

    checkImage(message: string) {
        let imgExpression = /\.(jpeg|jpg|gif|png)$/;
        let regex = new RegExp(imgExpression);
        if (message.match(regex)) {
            // console.log('IMAGE FOUND!', message);
            return true;
        } else {
            // console.log('IMAGE NOT FOUND!', message);
            return false;
        }
    };

    cleanURL(url: string) {
        let expression = /(http|ftp|https|):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/ig;
        let regex = new RegExp(expression);

        if (url.match(regex)) {
            return url;
        } else {
            return 'https://' + url;
        }
    }

    rangeChecker(control, test): boolean {
        return control.first <= test.first && control.last >= test.last;
    }
}