import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-device-check',
    templateUrl: './device-check.component.html',
    styleUrls: ['./device-check.component.scss']
})
export class DeviceCheckComponent implements OnInit {
    public testVideo: any;
    public videoSelect: any;
    public resolutionSelect: any;
    public audioSelect: any;

    public audioDevices: any = [];
    public videoDevices: any = [];
    public devicePreferences: any = {}

    public audioPermissions: boolean = true;// False if Denied or not selected
    public videoPermissions: boolean = true;// False if Denied or not selected
    public permissionsError: boolean = false;

    public streamError: any = {
        "isError": false, // True if Stream Error
        "errorType": ""
    }

    constructor() { }

    ngOnInit() {
        if (document.querySelector("#testVideo")) {
            this.testVideo = document.querySelector("#testVideo");
        }
        this.initalPreferences();
    }

    initalPreferences() {
        this.devicePreferences = JSON.parse(localStorage.getItem("devicePreferences"));

        if (this.devicePreferences === null) {
            this.devicePreferences = {
                "videoId": "default",
                "audioId": "default",
                "videoRes": 480
            };
            localStorage.setItem("devicePreferences", JSON.stringify(this.devicePreferences));
        }

        console.log(this.devicePreferences);
        this.setMediaDevices();
    }

    public checkDevices(videoBool: boolean, audioBool: boolean) {
        const constraints = {
            video: videoBool,
            audio: audioBool
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(() => {
                this.setMediaDevices();
            })
            .catch(err => {
                console.log(err.name + ": " + err.message);
            });
    }

    private setMediaDevices() {
        let videoDevices = [];
        let audioDevices = [];
        let videoId = this.devicePreferences.videoId;
        let audioId = this.devicePreferences.audioId;
        this.videoPermissions = true;
        this.audioPermissions = true;
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device => {
                    console.log(device);
                    let option = {
                        "deviceId": device.deviceId,
                        "groupId": device.groupId,
                        "kind": device.kind,
                        "label": device.label
                    };
                    if (device.kind === 'audioinput') {
                        if (device.label == "") {
                            this.audioPermissions = false;
                        }
                        audioDevices.push(option);
                    } else if (device.kind === 'videoinput') {
                        if (device.label == "") {
                            this.videoPermissions = false;
                        }
                        videoDevices.push(option);
                    } else {
                        console.log("Other Device: " + device.kind + ": " + device.label +
                            " id = " + device.deviceId + " was found");
                    }
                });
                if (videoDevices.length > 0) {
                    if (this.streamError.errorType === "no-video") {
                        this.streamError.errorType = "";
                    } else if (this.streamError.errorType === "no-video-audio") {
                        this.streamError.errorType = "no-audio";
                    }
                    if (videoDevices.some(device => device.deviceId === videoId)) {
                        console.log("video is here");
                        videoDevices.sort((x, y) => { return x.deviceId == videoId ? -1 : y.deviceId == videoId ? 1 : 0; });
                    } else {
                        console.log("video is not here");
                        this.devicePreferences.videoId = videoDevices[0].deviceId;
                    }
                } else {
                    this.videoPermissions = false;
                    if (this.streamError.errorType === "") {
                        this.streamError.errorType = "no-video";
                    } else if (this.streamError.errorType === "no-audio") {
                        this.streamError.errorType = "no-video-audio";
                    }
                }
                if (audioDevices.length > 0) {
                    if (this.streamError.errorType === "no-audio") {
                        this.streamError.errorType = "";
                    } else if (this.streamError.errorType === "no-video-audio") {
                        this.streamError.errorType = "no-video";
                    }
                    if (audioDevices.some(device => device.deviceId === audioId)) {
                        console.log("audio is here");
                        audioDevices.sort((x, y) => { return x.deviceId == audioId ? -1 : y.deviceId == audioId ? 1 : 0; });
                    } else {
                        console.log("audio is not here");
                        this.devicePreferences.audioId = audioDevices[0].deviceId;
                    }
                } else {
                    this.audioPermissions = false;
                    if (this.streamError.errorType === "") {
                        this.streamError.errorType = "no-audio";
                    } else if (this.streamError.errorType === "no-video") {
                        this.streamError.errorType = "no-video-audio";
                    }
                }
                if (!this.videoPermissions || !this.audioPermissions) {
                    this.checkDevices(true, true);
                }
            })
            .catch(err => {
                console.log(err.name + ": " + err.message);
            });
        this.videoDevices = videoDevices;
        this.audioDevices = audioDevices;
    }
}
