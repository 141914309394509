import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { Constants } from '../../../constants';

@Component({
  selector: 'guest-sign-out',
  templateUrl: './guest-sign-out.component.html',
  styleUrls: ['./guest-sign-out.component.scss']
})
export class GuestSignOutComponent {
  public accountSite = Constants.accountSite;
  constructor(
    private _authService: AuthService
  ) { }
  signOutGuest() {
    this._authService.signOut();
  }
}
