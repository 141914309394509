import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "checkInputLength",
})
export class CheckInputLengthPipe implements PipeTransform {
    transform(inputElId: string): boolean {
        // console.log('checking input length on -', inputElId)
        let search: HTMLInputElement = <HTMLInputElement>document.getElementById(inputElId);
        if(!!search) {
            if (search.value.length > 0) {
                // console.log('checking input length on -', inputElId, search.value.length)
                return true;
            } else {
                // console.log('checking input length on -', inputElId, 'no val')
                return false;
            }
        } else {
            console.log('checking input length on -', inputElId, 'no input to check')
            return false;
        }
    }
}