// export transitions global 
import { trigger, transition, style, animate, group, animateChild, query, stagger, keyframes } from '@angular/animations';

/* 🌎 global anime: these are used globaly and are ment to be re-useable,
      please do not modify an animation for single use, create a new one ...  */


export const fadeIn = trigger('fadeIn', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(200, style({
            opacity: 1
        }))
    ])
]);

export const fadeInFast = trigger('fadeInFast', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(62, style({
            opacity: 1
        }))
    ])
]);

// fade in-out (:leave) GOOD *but with catch* - IF - ELEMENT IS CONDITIONALLY OCCUPYING SAME SPACE AS ANOTHER 
// YOU NEED TO DELAY OTHER ELEMENT FROM ENTERING DOM, THIS CAN BE DONE BY DECLARING A BOOL - 
// in .ts   - isAnimationRunning: bool; 
// in .html - (@someAnimation.start)="isAnimationRunning = true" 
//         && (@someAnimation.done)="isAnimationRunning = false" 
//   ON ELEMENT YOU WISH TO DELAY - 
// <someEl *ngIf="!isAnimationRunning && ect. "> </>  
// 
// ℹ ~ THIS APPLYS TO ANY ANIME WITH A :leave transition <-----<<<|) 
//
export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(200, style({
            opacity: 1
        }))
    ]),
    transition(':leave', [
        style({
            opacity: 1
        }),
        animate(150, style({
            opacity: 0
        }))
    ])
]);





// 

export const slideInLeft = trigger('slideInLeft', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateX(-1rem)'
        }),
        group([
            animate(300, style({
                opacity: 1
            })),
            animate(500, style({
                transform: 'translateX(0rem)'
            }))
        ])
    ])
]);

export const slideInRight = trigger('slideInRight', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateX(1rem)'
        }),
        group([
            animate(300, style({
                opacity: 1
            })),
            animate(500, style({
                transform: 'translateX(0rem)'
            }))
        ])
    ])
]);

export const slideInTop = trigger('slideInTop', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(1rem)'
        }),
        group([
            animate(300, style({
                opacity: 1
            })),
            animate(500, style({
                transform: 'translateY(0rem)'
            }))
        ])
    ])
]);

export const slideInBottom = trigger('slideInBottom', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(-1rem)'
        }),
        group([
            animate(300, style({
                opacity: 1
            })),
            animate(500, style({
                transform: 'translateY(0rem)'
            }))
        ])
    ])
]);





// set transform-origin on element for this one rotateInOut 

export const rotateInOut = trigger('rotateInOut', [
    transition(':enter', [
        style({
            transform: 'rotate(70deg)',
            opacity: 0
        }),
        animate(200, style({
            transform: 'rotate(0deg)',
            opacity: 1
        }))
    ]),
    transition(':leave', [
        style({
            transform: 'rotate(0deg)',
            opacity: 1
        }),
        animate(150, style({
            transform: 'rotate(45deg)',
            opacity: 0
        }))
    ])
]);





// 🔥 list stagger animation 

// decorate the element that WRAPS the *ngFor element with '@listAnimationWrap' set to variable if list is dynamic 
export const listAnimationWrap = trigger('listAnimationWrap', [
    transition('* => *', [
        query('@listAnimationItem', [
            stagger(32, [
                animateChild()
            ])
        ], { optional: true }),
        // query('@*', animateChild(), {optional: true}) // added to trigger *all nested animations when run ...
    ])
]);
// decorate the element that is also decorated with the *ngFor with '@listAnimationItem' 
export const listAnimationItem = trigger('listAnimationItem', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(62, style({
            opacity: 1
        })),
        query('@listAnimationWrapLong', animateChild(), {optional: true})
    ])
]);

// list stagger example 

// <div [@listAnimationWrap]="someList.length">
//     <div *ngFor="let i of someList" @listAnimationItem > this is the list item <div/>
// <div/>

// when the list is loaded the animation runs all items, if a new item is added that item is animated 
export const listAnimationWrapLong = trigger('listAnimationWrapLong', [
    transition('* => *', [
        query('@listAnimationItemLong', [
            stagger(132, [
                animateChild()
            ])
        ], { optional: true }),
        
    ])
]);
export const listAnimationItemLong = trigger('listAnimationItemLong', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(162, style({
            opacity: 1
        })),
        query('@listAnimationWrapAvatar', animateChild(), {optional: true}) // added to trigger nested avitar stagger animation when run ...
    ])
]);

// for AVATAR stack ... is triggered when listAnimationItemLong.done ... needs 'row-reverse' to work properly ... 
export const listAnimationWrapAvatar = trigger('listAnimationWrapAvatar', [
    transition('* => *', [
        query('@listAnimationItemAvatar', [
            stagger(-62, [
                animateChild()
            ])
        ], { optional: true }),
        query('@fadeIn', animateChild(), {optional: true}) // added to trigger nested fadeIn animations when run ... for +10 users 
    ])
]);
// decorate the element that is also decorated with the *ngFor with '@listAnimationItem' 
export const listAnimationItemAvatar = trigger('listAnimationItemAvatar', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(92, style({
            opacity: 1
        }))
    ])
]);
// 





// Toast notification Enter / Leave 
export const toastNotification = trigger('toastNotification', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'scale3d(0.8, 0.8, 0.8)'
        }),
        animate('300ms ease-in-out', keyframes([
            style({
                offset: .5,
                opacity: 0.9,
                transform: 'scale3d(1.1, 1.1, 1.1)'
            }),
            style({
                offset: 1,
                transform: 'scale3d(1, 1, 1)'
            })
        ]))
    ]),
    transition(':leave', [
        style({
            opacity: 1,
            transform: 'scale3d(1, 1, 1)'
        }),
        animate('300ms ease-in', style({
            opacity: 0,
            height: 0,
            transform: 'scale3d(0.4, 0.4, 0.4)'
        }))
    ])
]);





// 🗣 direct message popup 

export const messagePopup = trigger('messagePopup', [
    transition(':enter', [
        style({
            width: '0rem',
            transform: 'translateY(100%)'
        }),
        animate('300ms ease-out', style({
            width: '37.5rem',
            transform: 'translateY(0%)'
        }))
    ])
]);


// 💡 custom exit animation exported as function to use in .ts files ... 
// i use this for when an item is removed / deleted from list ... 

// @describe: slide-out removed el & slide up next el in array if !!el ...

// @params: 1: the element to be removed | 2: next element in array (if any) ... 

// @exampleInput: let element = <HTMLElement>document.getElementsByClassName('meetings-container')[meetingIdx];
//                let element2 = <HTMLElement>document.getElementsByClassName('meetings-container')[meetingIdx + 1] || null;
export function customExitTransition(element:HTMLElement, element2: HTMLElement): void {
    // let el = <HTMLElement>document.getElementById(elementId);
    element.animate([
        { opacity: 0.4, transform: 'translateX(0)' },
        { opacity: 0, transform: 'translateX(200%)' }
    ], {
        duration: 262,
        easing: 'ease-in'
    });
    if(!!element2) {
        setTimeout(() => {
            element2.animate([
                { transform: 'translateY(3.33rem)' },
                { transform: 'translateY(0rem)' }
            ], {
                duration: 162,
                easing: 'ease-in'
            });
        }, 262);
    } else return;
};


// ❌ not in use yet ... 
export function customExitAllTransition(elements): void {
    // 
    console.log("elements {}",elements)
    for(let el of elements) {
        console.log(el)
    }
}


// example js animate ... 
// info.animate([
//     { opacity: 0, transform: 'scale(0)' },
//     { opacity: 1, transform: 'scale(1)' }
// ], {
//     duration: 172,
//     easing: 'ease-out'
// });








// 👾 cote 2021 