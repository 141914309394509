import { Component, Inject, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Constants } from "../../app/constants";
import { ToastNotificationService } from "../services/toast-notification.service";

@Component({
    selector: "login-conference",
    templateUrl: "./login-conference.component.html",
    styleUrls: ["./login-conference.component.scss"],
})
export class LoginConferenceComponent implements OnInit {

    // public unitySite: string = Constants.unitySite;
    public accountSiteRegister: string = `${Constants.accountSite}register`;
    public loginForm: FormGroup;
    public isLoggingIn: boolean = false;
    public loginConfirmed: boolean = false;
    public loginError: boolean = false;
    public tenant: string = 'ndia';

    public accountSite = Constants.accountSite;

    public currentYear = new Date().getFullYear();

    public loginRippleColor: string = "rgba(255, 255, 255, 0.28)"; // login button feedback ...

    public logoIcon2: any = {
        logoblue:
            "https://via3filescdnstrg.blob.core.usgovcloudapi.net/web-images/Logo%20Partial/logo-blue.png",
    };

    constructor(
        private fb: FormBuilder,
        private _toast: ToastNotificationService,
        @Inject("tooltipDelay") public tooltipDelay: number
    ) {}

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.loginForm = this.fb.group({
            userName: ["", [Validators.required, Validators.minLength(2)]],
            email: [
                "",
                [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")],
            ],
        });
    }

    get loginFormControls() {
        return this.loginForm.controls;
    }

    // login args from api.cs = (loginArgs.Email, loginArgs.Password, loginArgs.RememberMe, lockoutOnFailure: false)

    // ❓ SUBSCRIPTION method does not work with right for some reason with redirectUrl ...
    // login() {
    //   const returnUrl = this.loginForm.value.returnUrl;
    //   const username = this.loginForm.value.userName;
    //   const password = this.loginForm.value.password;
    //   let userObj = {
    //     "returnUrl": returnUrl,
    //     "username": username,
    //     "password": password
    //   }
    //   this._authService.loginUser(userObj).subscribe(res => {
    //     console.log("login res?", res)
    //     if(res.status === 200) {
    //       this.loginConfirmed = true;
    //       console.log(" redirect url? ", res.body.redirectUrl)
    //       window.location = res.body.redirectUrl;
    //       // this._router.navigate([res.body.redirectUrl], { replaceUrl: true }); // this should work to nav now with replace url !
    //     } else {
    //       console.error("login fail invalid credentials");
    //       this.loginError = true;
    //     }
    //   });
    // }

    async login() {
        this.isLoggingIn = true;
        const tenant = this.tenant;
        const displayName = this.loginForm.value.userName;
        const email = this.loginForm.value.email;
        const response = await fetch(
            `${Constants.stsAuthority}account/PublicLogin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    displayName,
                    email,
                    tenant,
                }),
            }
        );
        const data = await response.json().catch((err) => {
            console.error("🟩 no data from login post", err);
            this.isLoggingIn = false;
            this.loginError = true;
        });
        if (data) {
            if (data.isOk) {
                // console.log("💙 login data", data);
                // console.log("💚 redirect url? ", data.redirectUrl);
                this.isLoggingIn = false;
                this.loginConfirmed = true;
                // this._toast.success('Welcome to NDIA 🎉')
                window.location = data.redirectUrl;
            } else {
                console.log("❤ login fail invalid credentials", data);
                // this.loginForm.reset();
                this.isLoggingIn = false;
                this.loginError = true;
            }
        } else {
            console.warn("🟧 no data from login post");
        }
    }
}
