import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "byteConvert",
})
export class ByteConvertPipe implements PipeTransform {
    transform(value: number): string {
        if (0 === value) {
            return "0 Bytes";
        } else if (value) {
            let decimal = 2;
            const newDecimal = 0 > decimal ? 0 : decimal;
            const newValue = Math.floor(Math.log(value) / Math.log(1024));
            return (
                parseFloat((value / Math.pow(1024, newValue)).toFixed(newDecimal)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][newValue]
            );
        } else {
            return null;
        }
    }
}
