import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsHomepageComponent } from './contacts-homepage/contacts-homepage.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'contacts', component: ContactsHomepageComponent }
  ])],
  exports: [RouterModule]
})
export class ContactsAppRoutingModule { }
