import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

import { EventsAppRoutingModule } from './events-app-routing.module';
import { EventsHomepageComponent } from './events-homepage/events-homepage.component';


@NgModule({
  declarations: [
    EventsHomepageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    EventsAppRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsAppModule { }
