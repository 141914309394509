import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { GuestService } from 'src/app/services/guest.service';
import { ChannelUser } from 'src/app/shared/interface/channel-user';

@Component({
    selector: 'app-events-homepage',
    templateUrl: './events-homepage.component.html',
    styleUrls: ['./events-homepage.component.scss']
})
export class EventsHomepageComponent implements OnInit, OnDestroy {
    public userInfo: ChannelUser = this._authService._channelUserInfo;

    // ad-hoc guest lock out 
    public isGuest: boolean;
    public isViewer: boolean; // NDIA

    public subscriptions: Subscription[] = [];

    constructor(
        private _guestService: GuestService,
        private _authService: AuthService,
    ) {
        this.subscriptions.push(
            this._authService.channelUserInfoChanged$.subscribe(() => {
                this.userInfo = this._authService._channelUserInfo;
                this.isGuest = this.userInfo.isGuest;
                if (this.isGuest) {
                    this._guestService.routeToChannelInvited();
                }
            }));
    }

    ngOnInit(): void {
        if (this.userInfo) {
            this.isGuest = this.userInfo.isGuest;
            if (this.isGuest) {
                this._guestService.routeToChannelInvited();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
