import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ApiCallsService } from '../../../services/api-calls.service';
import { DocumentToSign, User } from '../../../models/models';
import { ActivatedRoute } from '@angular/router';
import * as faker from 'faker'; //For testing
import 'webrtc-adapter';
import { ChannelService } from '../../../services/channel-service';
import { constants } from 'os';
import { JsonHubProtocol } from '@microsoft/signalr';
import { connect } from 'http2';
import { ConnectionManagerService } from '../../../services/connection-manager.service';
import { AppIntakeService } from '../../../../../src/app/services/app-intake.service';
import { stringify } from 'querystring';
import { SocketsService } from 'src/app/services/sockets.service';
import { FileStorageService } from '../../../../../src/app/services/file-storage.service';
import { AuthService } from '../../../../../src/app/core/auth.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../../constants';

@Component({
    selector: 'app-mike-kurento',
    templateUrl: './mike-kurento.component.html',
    styleUrls: ['./mike-kurento.component.scss']
})

export class MikeKurentoComponent implements OnInit, OnDestroy {
    // @Output() resetVideoCall = new EventEmitter();
    // @Output() promptVideoCall = new EventEmitter();
    // @Input() channelId: string;
    public channelInfo: any = {}
    //public channelId: string = "ODudJKxlS";
    public channelId: string = '';
    public beta1: boolean = false;
    public beta2: boolean = false;
    public beta3: boolean = false;
    public sub: any;
    public showPage: boolean = false;
    public username: any = '';
    public iframe: any;

    public socketServers: any = [];

    //temp for files
    public _showFiles: boolean = false
    public channelDisplayName: string = "";
    public downloadFile: any = {};
    public sharedFileListQuery: any = {};
    public fileList: any = [];
    

    constructor(
        private _route: ActivatedRoute,
        private _channelService: ChannelService,
        private _fileStorageService: FileStorageService,
        private _socketService: SocketsService,
        private _authService: AuthService,
        private _apiCalls: ApiCallsService,
        private sanitizer: DomSanitizer
    ) {
        this.getChannelId();
        this.getIsbeta();

        this._channelService.onSharedFileDeleteCompletedEvent$.subscribe(() => {
            this._sharedFileList();
        })

        this._channelService.onSharedFileUploadCompletedEvent$.subscribe(() => {
            this._sharedFileList();
        })

        this.username = this._authService._user.profile.preferred_username;




        if (this.beta1)
        {
          //const twilioPasscode = "5121582603";
          //this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://video-app-2603-dev.twil.io?passcode=" +
          //  twilioPasscode + "&name=" + this.username + "&roomname=" + this.channelId);

          const id_token = localStorage.getItem('oidc.user:' + Constants.stsAuthority + ':unity-web-client')
          const access_token = JSON.parse(id_token).access_token;
          //alert(access_token);

         //let tempVal = '';
         //for(let i=0; i < localStorage.length; i++){
         //
         //  let propertyName = localStorage.key(i);
         //
         //  propertyName = propertyName ? propertyName : '';
         //
         //  const propertyValue = localStorage.getItem(propertyName);
         //
         //  tempVal = tempVal + i + " : " + propertyName + " = " + propertyValue;
         //}
         //alert(tempVal);

          if (Constants.isProd) {

            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://video-app-2603-dev.twil.io?name=" +
              this.username + "&roomname=" + this.channelId + "&access_token=" + access_token);            

          } else {

            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://127.0.0.1:44303?name=" +
              this.username + "&roomname=" + this.channelId + "&access_token=" + access_token);
          }
        }
        else if (this.beta2) {
          if (Constants.isProd) {
         
            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("../../../../assets/jitsi/meeting.html?username=" +
              this.username + "&channelname=" + this.channelId);

          } else {

            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("../../../../assets/jitsi/meeting.html?username=" +
              this.username + "&channelname=" + this.channelId);
          }
        }
        else if (this.beta3) {
          
            this.iframe = sanitizer.bypassSecurityTrustResourceUrl("https://letsmeet.no/" + this.channelId);
        }
        else
        {
          this.iframe = sanitizer.bypassSecurityTrustResourceUrl("../../../../assets/KurentoVideo/Test2.html?username=" +
            this.username + "&channelname=" + this.channelId);          
        }
    }

    getIsbeta() {
        this.sub = this._route.params.subscribe(params => {
          if (params['beta'] === 'beta1') {
            this.beta1 = true;
          } else if (params['beta'] === 'beta2') {
              this.beta2 = true;
          } else if (params['beta'] === 'beta3') {
              this.beta3 = true;                          
          }          
        });
        //console.log("beta = " + params['beta']);                
    }

    getChannelId() {
        this.sub = this._route.params.subscribe(params => {
            this.channelId = params['channelId'];
        });
        console.log(this.channelId);
    }

    ngOnInit() {
        this.channelInitialize();
        this._getChannel();
        // this.promptVideoCall.emit();        
    }

    ngOnDestroy() {
        return this._channelService.leave(this.channelId);
    }

    _getChannel() {
        this._apiCalls.getChannel(this.channelId)
            .subscribe(data => {
                if (data.status === 200) {
                    this.channelInfo = data.body;
                    this._sharedFileList();
                    this.showPage = true;
                } else {
                    console.error(data.status);
                    // alert(JSON.stringify(data.status));
                }
            });
    }

    channelInitialize() {
        return this._channelService.join(this.channelId);
    }

    _getActiveSocketServers(): Promise<any> {
        return this._socketService.getActiveSocketServers()
            .toPromise()
            .then(data => {
                console.log("Getactivesocketservers Mike Kurento" + JSON.stringify(data.body));
                this.socketServers = data.body;
            })
            .catch(err => console.error(err));
    }

    //temp files
    filesInit() {
        this.downloadFile = {
            "roomOwnerUserId": "",
            "channelId": "",
            "path": "",
            "rev": ""
        };
    }

    _downloadSharedFile(filePath: string, fileName: string) {
        this.downloadFile.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
        this.downloadFile.path = filePath;
        this.downloadFile.channelId = this.channelInfo.channelId;
        // alert(JSON.stringify(this.downloadFile));
        this._fileStorageService.fileStorageDownloadSharedFile(this.downloadFile)
            .subscribe(data => {
                if (data.status === 200) {
                    console.log(data);
                    // window.open(data.body, '_blank') //open
                    this.createDownloadElement(data.body, fileName);
                    this.filesInit();
                } else {
                    alert(JSON.stringify(data));
                }
            });
    }

    createDownloadElement(link, name) {
        // alert(link);
        var element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', link);
        element.setAttribute('target', '_blank');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    _sharedFileList() {
        this.sharedFileListQuery.roomOwnerUserId = this.channelInfo.chatUserKeyNavigation.channelUserId;
        this.sharedFileListQuery.projectName = this.channelInfo.teamProjectKeyNavigation.name;
        this.sharedFileListQuery.roomName = this.channelInfo.name;
        this._fileStorageService.listSharedFiles(this.sharedFileListQuery)
            .subscribe(data => {
                if (data.status === 200) {
                    this.fileList = data.body.entries;
                    // alert(JSON.stringify(data));
                } else {
                    alert(JSON.stringify(data));
                }
            });
    }

    _softDeleteFile(filePath: string) {
        this._fileStorageService.softDeleteFile(filePath, this.channelInfo.channelId)
            .subscribe(res => {
                // alert(JSON.stringify(res));
                this._sharedFileList()
            });
    }

    _deleteFile(filePath: string) {
        console.log(filePath);
        this._fileStorageService.deleteFile(filePath, this.channelInfo.channelId)
            .subscribe(res => {
                if (res.status === 200) {
                    // alert(JSON.stringify(res));
                    console.log(res);
                    this._channelService.sharedFileDeleteComplete(this.channelId);
                } else {
                    alert(JSON.stringify(res));
                }
            });
    }

    showModal() {
        this._showFiles = (this._showFiles) ? false : true;
    }
}
